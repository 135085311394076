import axios from "axios";
import { API_URL } from "../../apiConstants";
import i18n from "i18next";

const axiosInstance = axios.create({
  baseURL: `${API_URL}`,
  headers: { Authorization: "Bearer " + localStorage.getItem("auth_token") },
  params: { lang: i18n.language },
});

export default axiosInstance;

export const getAxiosInstance = () => {
  return axios.create({
    baseURL: `${API_URL}`,
    headers: { Authorization: "Bearer " + localStorage.getItem("auth_token") },
    params: { lang: i18n.language },
  });
};
