import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { bathroomStore, checkoutStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { bathroomFlowModel } from "../../_redux/Models/bathroom.model";
import {
  calculateBathroomCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const BathroomSize = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;
  const bathroomId =
    props.match && props.match.params ? props.match.params.bathroomId : false;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.bathroomPayload
      ? state.buttonReducer.bathroomPayload
      : bathroomFlowModel
  );

  const bathroom = state.bathroomsDetails.find(
    (baths) => baths.id === bathroomId
  );

  if (!bathroom) {
    history.replace("/bathroom");
    window.location.reload();
  }

  const [price, setPrice] = useState(
    calculateBathroomCost(state.bathroomsDetails)
  ); // To keep the total price in state

  const [basementHeightOptions, setBasementHeightOptions] = useState([]);
  const [size, setSize] = useState("");

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails) {
      const basementHeightOptionsArr = priceDetails?.size?.map((op) => {
        return {
          text: t(`Bathroom.${op.key}`),
          value: op.key,
          price: op.price,
        };
      });
      setBasementHeightOptions(basementHeightOptionsArr || []);
    }
    return () => (mounted = false);
  }, [bathroom.size, checkoutModelState, t]);

  const onSave = (value, prc) => {
    bathroom.costFor.size = prc - calculateBathroomCost(state.bathroomsDetails);
    bathroom.size = value;
    state.bathroomsDetails.filter((bt) => bt.id !== bathroom.id).push(bathroom);
    dispatch(bathroomStore(state));
    checkoutModelState.totalCost = prc;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/bathroom/${bathroomId}/materials/3`
    );
  };

  const renovation = [
    {
      text1: t("Bathroom.renovation"),
    },
    {
      text: t("Bathroom.tiled"),
    },
    {
      text: t("Bathroom.cement"),
    },
    {
      text: t("Bathroom.plumbing"),
    },
    {
      text: t("Bathroom.drainage"),
    },
    {
      text: t("Bathroom.shower"),
    },
    {
      text: t("Bathroom.waterproofing"),
    },
    {
      text: t("Bathroom.cleanup"),
    },
    {
      text: t("Bathroom.comprehensive"),
    },
  ];

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("Bathroom.bathsize")}</label>
        </div>
        <div className="paint_flex_with_left ">
          <label className="paint_other_sub_header ">
            {t("Bathroom.plzselect")}{" "}
            <span
              style={{ fontWeight: "bold", color: "#2ccae8" }}
            >{`Bathroom # ${bathroom.number}`}</span>
          </label>
        </div>
        <div className="padding10"></div>
        {basementHeightOptions?.length > 0 ? (
          basementHeightOptions.map((item, index) => (
            <div key={index} className="mb-4">
              <div
                className={`floor-selection-radio ${
                  size === item?.value ? "selected" : ""
                }`}
                onClick={() => {
                  const total = calculateBathroomCost(state.bathroomsDetails);
                  setSize(item.value);
                  setPrice(total + item.price);
                  onSave(item?.value, total + item?.price);
                }}
              >
                <div
                  key={index}
                  className="width100"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    className="paintdiv_txt"
                    style={{
                      width: "65%",
                    }}
                  >
                    {item.text}
                  </label>
                  <div
                    className="paint_arrowFloat"
                    style={{
                      width: "35%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={IMAGES.other.green_plus} alt="" />
                      <label className="paintdiv_txt">
                        {`$${formatPrice(item.price)}`}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Please try again</p>
        )}

        {/* <Button
          disabled={!size}
          buttonLabel={t("paintingHomes.continue")}
          onClick={onSave}
        /> */}
      </div>
    </div>
  );
};
