import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const persistConfig = {
  key: "recoil-persist",
  storage: window.sessionStorage,
};
const { persistAtom } = recoilPersist(persistConfig);

export const stepperFormAtom = atom({
  key: "stepperFormAtom",
  default: {
    step: 0,
    values: null,
    options: null,
  },
  // effects_UNSTABLE: [persistAtom],
});
