import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';

import { getData } from "../../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE, NOTIFICATION_URL } from "../../../_configs/constants.config";
import "../../../_styles/localStyles/notifications.css";
import IMAGES from "../../../_assets/images";
import { getAllNotifications, updateNotifications } from '../../../services/api/notifications.api.services';
import Loader from '../../../_components/spinner/Loader';
import { parseJwt } from "../../../services/utils/utilFunctions";
import { getContractorProjectById } from "../../../services/api/contractor.service";
import {
    updateContractorProject,
    updateTenatativeDetails,
} from "../../../_redux/actions";
import './notification.css';
import moment from "moment";

function Notifications(props) {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const history = useHistory();
    const user = getData(LOCAL_STORAGE.user);
    const [notifications, setNotifications] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user && user._id) {
            getNotifications()
        } else {
            history.replace('/role')
        }
    }, [])

    const getNotifications = async () => {
        const notifications = await getAllNotifications();
        setNotifications(notifications.data)
    }

    const onNotificationClick = async (notification) => {
        if (!notification.is_read) {
            await updateNotifications(notification._id, { isRead: true });
            const newNotifications = notifications.filter((noti) => noti._id !== notification._id);
            setNotifications(newNotifications);

            const user = parseJwt(localStorage.getItem("auth_token"));
            if (!notification['receiverType']) return;

            if (notification['type'] !== 'newMessage' && notification['receiverType'] === 'contractor') {
                getProjectDetails(notification['routeParam'], user)
            } else {
                if (notification['routeParam'] === "") {
                    history.push(NOTIFICATION_URL['default'])
                } else {
                    history.push(NOTIFICATION_URL[notification['type']] + notification['routeParam'] ?? "")
                }
            }
        }

    }

    const getProjectDetails = (projectId, user) => {
        if (user && user._id) {
            projectId = projectId.slice(1);
            setIsLoading(true);
            getContractorProjectById(user._id, projectId)
                .then((data) => {
                    if (data.status === 200 && data?.data && data?.data[0]) {
                        setAndNavigate(data.data[0]);
                    }
                })
                .catch((e) => alert(e.toString()))
                .finally(() => setIsLoading(false));
        }
    }

    const setAndNavigate = (project) => {
        dispatch(updateContractorProject(project));
        dispatch(updateTenatativeDetails({
            startDate: project?.tentativeStartDate,
            endDate: project?.tentativeEndDate,
            time: {
                text: "",
                time: project?.tentativeTimeRange,
            },
        }));
        history.push(
            "/contractor-project-preview"
        );
    }

    const onClose = () => {
        history.goBack();
    }

    return (
        <div className="notificationsWrapper app-notification pcWidth">
            <Loader progress={(notifications === null || isLoading) ? true : false} />
            <div className="notificationsTitleWrapper" onClick={onClose}>
                <div className="notificationsTitle">
                    {t("notifications.notifications")}
                </div>
                <CloseIcon />
            </div>
            <div className="notificationsLists">
                {notifications ? notifications.map((notification, index) => {
                    return (
                        <div style={(!notification.is_read) ? { cursor: 'pointer' } : {}} className={`notificationsBlock ${notification.is_read ? 'read' : ''}`} key={notification._id + index} onClick={() => onNotificationClick(notification)} >
                            <div className="notificationsDotWrapper">
                                <div className="notificationsDot" />
                            </div>

                            <div className="notificationsMessage">
                                <span>{notification.message}</span>
                                {(notification.createdAt) ? <div className="notificationsDate">{moment(notification.createdAt).format('MMMM DD, YYYY [at] hh:mm a')}</div> : null}
                            </div>
                            <div className="notificationsImage">
                                <img src={IMAGES.other.bell} alt={notification.message} />
                            </div>
                        </div>
                    )
                }
                ) : null}
            </div>
            <hr className="line" />
        </div>
    )
}

export default Notifications;