import React, { useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import "./bootstrap-styles.scss";

let containerStyle = {
  width: "100%",
  height: "435px",
};

function MapComponent({ selected }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
  });

  const isMobile = window?.innerWidth < 768; // Check if screen width is less than 768px

  if (isMobile) {
    containerStyle = { ...containerStyle, height: "200px" }; // Adjust height for mobile screens
  }

  const [map, setMap] = React.useState(null);
  const [center, setCenter] = React.useState({
    lat: selected?.lat || 39.106667,
    lng: selected?.lon || -94.676392,
  });

  const [zoom, setZoom] = React.useState(10);
  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(zoom);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    setCenter({ lat: parseInt(selected?.lat), lng: parseInt(selected?.lon) });
  }, [selected]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      clickableIcons={false}
      zoom={zoom}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
      }}
      mapId={null}
      mapContainerClassName="hide-labels"
    >
      {/* Child components, such as markers, info windows, etc. */}
      <>
        <Marker
          icon={{
            path: window?.google?.maps.SymbolPath.CIRCLE,
            scale: 7,
            fillColor: "red",
            fillOpacity: 1,
            strokeColor: "white",
            strokeWeight: 1,
          }}
          position={center}
        />
      </>
    </GoogleMap>
  ) : (
    <>Loading !!!</>
  );
}

export default React.memo(MapComponent);
