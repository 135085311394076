import axios from "axios";
import { API_URL, userEndPoints } from "../apiConstants";
import { getAxiosInstance } from "./config/api.config";

/**
 * Returns the owner profile
 * @returns AxiosResponse
 */
export const ownerProfile = async (ownerId) => {
  try {
    const response = await axios.get(
      `${userEndPoints.profile}/${ownerId}/profile`
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Updates the owner profile
 * @returns AxiosResponse
 */
export const updateOwnerProfile = async (ownerId, postData) => {
  try {
    return getAxiosInstance().put(
      `${userEndPoints.profile}/${ownerId}/profile`,
      postData
    );
  } catch (error) {
    return error;
  }
};

/**
 * Returns the owner profile
 * @returns AxiosResponse
 */
export const ownerRegisteredAddresses = async (ownerId) => {
  try {
    const response = await axios.get(
      `${userEndPoints.profile}/${ownerId}/registered-addresses`
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Returns the owner profile
 * @returns AxiosResponse
 */
export const saveOwnerAddress = async (ownerId, data) => {
  try {
    const response = await axios.put(
      `${userEndPoints.profile}/${ownerId}/address`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Returns the owner profile
 * @returns AxiosResponse
 */
export const getOwnerAddresses = async (ownerId) => {
  try {
    const response = await axios.get(
      `${userEndPoints.owner}/${ownerId}/registered-addresses`
    );
    return response;
  } catch (error) {
    return error;
  }
};
/**
 * Places an Order
 * @returns AxiosResponse
 */
export const placeOrder = async (userId, postData, userType) => {
  try {
    const authToken = localStorage.getItem("auth_token");
    const config = {
      headers: { Authorization: `Bearer ${authToken}` },
    };
    let endPoint = userEndPoints.placeOrder;
    if (userType === "contractor")
      endPoint = userEndPoints.placeOrderContractor;
    return axios.post(`${endPoint}/${userId}/place-order`, postData, config);
  } catch (error) {
    return error;
  }
};

/**
 * Places an Order after success
 * @returns AxiosResponse
 */
export const placeOrderAfterSuccess = async (userId, postData, userType) => {
  try {
    let endPoint = userEndPoints.placeOrder;
    if (userType === "contractor")
      endPoint = userEndPoints.placeOrderContractor;
    return getAxiosInstance().post(
      `${endPoint}/${userId}/place-order-after-payment`,
      postData
    );
  } catch (error) {
    return error;
  }
};

/**
 * Returns the owner projects
 * @returns AxiosResponse
 */
export const getOwnerProjects = async (ownerId, status) => {
  try {
    const response = await axios.get(
      `${userEndPoints.owner}/${ownerId}/projects`,
      {
        params: {
          status,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Returns the owner project details
 * @returns AxiosResponse
 */
export const getOwnerProjectById = async (ownerId, projectId) => {
  try {
    const response = await axios.get(
      `${userEndPoints.owner}/${ownerId}/projects/${projectId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Returns the owner project updateProjectById
 * @returns AxiosResponse
 */
export const updateProjectById = async (ownerId, projectId, postData) => {
  try {
    const response = await axios.put(
      `${userEndPoints.owner}/${ownerId}/projects/${projectId}`,
      postData
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * postContactUs
 * @returns AxiosResponse
 */
export const postContactUs = async (postData) => {
  try {
    const response = await getAxiosInstance().post(
      `${userEndPoints.contactUs}`,
      postData
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * cancelProjectForUser
 * @returns AxiosResponse
 */
export const cancelProjectForUser = async (id, projectId) => {
  try {
    const response = await getAxiosInstance().put(
      `${userEndPoints.owner}/${id}/projects/${projectId}/cancel`
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * rateProject
 * @returns AxiosResponse
 */
export const rateProject = async (
  ratingFor,
  ownerId,
  projectId,
  contractorId,
  rating,
  review
) => {
  try {
    const response = await getAxiosInstance().post(`${userEndPoints.ratings}`, {
      identifier: ratingFor,
      rating: rating,
      review,
      project: projectId,
      owner: ownerId,
      contractor: contractorId,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadContractorPictureOfWork = async (
  ownerId,
  projectId,
  data
) => {
  try {
    return getAxiosInstance().put(
      `${userEndPoints.owner}/${ownerId}/projects/${projectId}/upload`,
      data
    );
  } catch (error) {
    return error.response.data.message;
  }
};
/**
 * create checkout session
 * @returns AxiosResponse
 */
export const createCheckoutSession = async (userId, postData) => {
  try {
    const response = await getAxiosInstance().post(
      `${userEndPoints.owner}/${userId}/create-checkout-session`,
      postData
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * fetch wishlisted contractors
 * @returns AxiosResponse
 */
export const fetchWishlist = async (id) => {
  if (id) {
    try {
      return getAxiosInstance().get(
        `${API_URL}/contractor-profile/fetch-wishlist?categoryId=${id}`
      );
    } catch (error) {
      return error;
    }
  } else {
    try {
      return getAxiosInstance().get(
        `${API_URL}/contractor-profile/fetch-wishlist`
      );
    } catch (error) {
      return error;
    }
  }
};

/**
 * Add contractor to wish list
 * @returns AxiosResponse
 */
export const addContractorToWishlist = async (id) => {
  try {
    return getAxiosInstance().post(
      `${API_URL}/contractor-profile/add-to-wishlist`,
      { contractorId: id }
    );
  } catch (error) {
    return error;
  }
};

/**
 * Remove contractor from wish list
 * @returns AxiosResponse
 */
export const removeContractFromToWishlist = async (id) => {
  try {
    return getAxiosInstance().post(
      `${API_URL}/contractor-profile/remove-from-wishlist`,
      { contractorId: id }
    );
  } catch (error) {
    return error;
  }
};
