import React from "react";
import "./svgallicon.css";
import IMAGES from "../../_assets/images/index";
export const Smileverybad = (props) => {
  return (
    <div className="allCenter">
      <div
        style={{
          padding: "10px",
          borderRadius: "50%",
          //width: "11%",
          height: "42px",
          position: "absolute",
          justifyContent: "space-between",
          background: props.selected ? "#A6F0FF" : "",
        }}
        className=""
      >
        <img src={IMAGES.other.smilefour} alt="" />{" "}
      </div>
      <div style={{ position: "absolute", marginTop: "90px" }} className="">
        <label className="smileTxt ">{"Very bad"}</label>
      </div>
    </div>
  );
};