import React, { useEffect, useRef, useState } from "react";
import BearCarousel, { BearSlideItem } from "bear-react-carousel";

import "../../../_styles/localStyles/floor.css";
import solidHardwoodImages from "../../../_components/Slider/SolidHardwood";
import engineeredImages from "../../../_components/Slider/EngineeredImages";
import carpetImages from "../../../_components/Slider/CarpetImages";
import tilesImages from "../../../_components/Slider/TilesImages";
import laminatedImages from "../../../_components/Slider/laminatedImages";
import epoxyImages from "../../../_components/Slider/EpoxyImages";
import otherImages from "../../../_components/Slider/OtherImages";

function SolidHardwoodIndex(props) {
  const [typeOfService, setTypeOfService] = useState();
  const [materialSelected, setMaterialSelected] = useState();
  const [material, setMaterial] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isHovered, setIsHovered] = useState("");

  const materialEl = useRef();
  const btnEl = useRef();
  const onChangeRadio = (val) => {
    setTypeOfService(val);
    setMaterial(true);
    setTimeout(function () {
      materialEl.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 500);
  };

  const onImageChangeRadio = (value) => {
    setTimeout(() => {
      props.onSuccess({
        materialSelected: materialSelected,
        typeOfService: typeOfService,
      });
    }, 500);
    setMaterialSelected(value);
  };

  const materials = [
    {
      name: "Solid hardwood",
      value: "SolidHardwood",
      images: solidHardwoodImages,
      showForRefinishing: true,
    },
    {
      name: "Engineered",
      value: "Engineered",
      images: engineeredImages,
      showForRefinishing: false,
    },
    {
      name: "Carpet",
      value: "Carpet",
      images: carpetImages,
      showForRefinishing: false,
    },
    {
      name: "Tiles",
      value: "Tiles",
      images: tilesImages,
      showForRefinishing: false,
    },
    {
      name: "Laminate",
      value: "Laminated",
      images: laminatedImages,
      showForRefinishing: false,
    },
    {
      name: "Epoxy",
      value: "Epoxy",
      images: epoxyImages,
      showForRefinishing: false,
    },
    {
      name: "Other",
      value: "Other",
      images: otherImages,
      showForRefinishing: true,
    },
  ];

  const services = ["Installation", "Refinishing", "Repair"];

  return (
    <div>
      <div className="floor-sub-title mb-3">Select a type of service</div>
      <div className="d-flex flex-column w-100">
        {services.map((service) => (
          <label
            className={`floor-selection-radio d-flex w-100 ${
              typeOfService === service ? "selected" : ""
            }`}
            style={{
              height: "60px",
              padding: "0px 30px",
              minHeight: "auto",
              marginBottom: "10px",
            }}
            onClick={() => onChangeRadio(service)}
          >
            <span className="d-flex">{service}</span>
          </label>
        ))}
      </div>

      {material ? (
        <div className="m-t-32 ">
          <div ref={materialEl} className="floor-sub-title">
            Select the material
          </div>
          <div className="m-t-32 col-12 d-flex flex-wrap">
            {materials.map((mat) => {
              if (typeOfService === "Refinishing" && !mat.showForRefinishing)
                return null;
              return (
                <div
                  onClick={() => onImageChangeRadio(mat.value)}
                  className="col-6 col-lg-4 mb-3"
                  onMouseEnter={() => setIsHovered(mat.value)}
                  onMouseLeave={() => setIsHovered("")}
                >
                  <label
                    className="w-100 px-2"
                    style={{ height: "124px", cursor: "pointer" }}
                  >
                    <img
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "15px",
                        border:
                          mat?.value === materialSelected ||
                          isHovered === mat?.value
                            ? "1.5px solid #2CCAE8"
                            : "1.5px solid transparent",
                      }}
                      src={mat?.images?.[0]?.urls}
                    />
                    <p
                      className=""
                      style={{
                        fontSize: "14px",
                        color:
                          mat?.value === materialSelected ||
                          isHovered === mat?.value
                            ? "#2CCAE8"
                            : "#000000",
                        margin: "10px 0px",
                        textAlign: "center",
                      }}
                    >
                      {mat.name}
                      <input
                        type="radio"
                        name="materialSelected"
                        value={mat.value}
                        checked={mat.value === materialSelected}
                        className="invisible"
                      />
                    </p>
                  </label>
                </div>
              );
            })}
          </div>
          {/* <button
            className="floor-button"
            disabled={btnDisabled}
            onClick={btnClick}
            ref={btnEl}
          >
            Start instant quote now
          </button> */}
        </div>
      ) : null}
    </div>
  );
}

export default SolidHardwoodIndex;
