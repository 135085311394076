import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FireBase } from "../../../services/firebase/firebase.service";
import { siginModel } from "../../../_redux/Models/auth.models";
import { useSnackbar } from "notistack";
import { isSupported } from "firebase/messaging";

import { Button } from "../../../_components/Button/Button";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { addFirebaseToken } from "../../../services/api/notifications.api.services";
import { userPhoneSignin } from "../../../services/api/signin.service";
import { continuetoNext } from "../../../_redux/actions";
import { parseJwt } from "../../../services/utils/utilFunctions";
import CustomPhoneInput from "../../../_components/PhoneInput/PhoneInput";

export default function PhoneLogin({ deleteToken, onPermission, ...props }) {
  const history = useHistory();

  const firebase = new FireBase();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isProgress, setIsProgress] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [signInState, setSignInState] = useState({
    mobile: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
  });

  const onChangeHandler = (event, key) => {
    let validatedState = validate(key, event.target.value, undefined);
    setSignInState(validatedState);
    const formValid = Object.values(validatedState).every(
      (val) => val.valid === true
    );
    if (formValid) {
      setIsButtonDisable(false);
    } else {
      setIsButtonDisable(true);
    }
  };

  const validate = (name, value, message) => {
    const newState = { ...signInState };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;
    var phoneRegex = /^(?:\+1)?\d{10}$/;
    switch (name) {
      case "mobile":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Phone number is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (!value.match(phoneRegex)) {
          errorMessage =
            errorMessage !== null
              ? errorMessage
              : "Please enter a valid Phone number";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value.match(phoneRegex)) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      default: {
        return newState;
      }
    }
  };
  const siginHandler = async () => {
    setIsButtonDisable(true);
    const user = {
      mobile: signInState.mobile.value,
    };
    const formValid = Object.values(signInState).every(
      (val) => val.valid === true
    );

    if (formValid) {
      if (user != undefined) {
        var res = await userPhoneSignin(user);
        if (res.status == 200) {
          history.push("/signin/verify-otp", {
            mobile: signInState.mobile.value,
          });
        } else {
          let errorMessage = "Something went wrong!";
          if (res.response && res.response.status > 200) {
            errorMessage = res.response.data.message;
          }
          setIsProgress(false);
          const variant = "error";
          enqueueSnackbar(errorMessage, { variant });
        }
        setIsProgress(false);
      }
    } else {
      setIsButtonDisable(false);
      Object.values(signInState).map((el, i) => {
        if (!el.valid) {
          let keyValue = Object.keys(signInState)[i];
          var errorState = validate(keyValue, el.value, undefined);
          setSignInState(errorState);
        }
      });
    }
  };
  const { t } = useTranslation();

  return (
    <div className="register_page_lable">
      <CustomPhoneInput
        valueState={signInState}
        setValueState={setSignInState}
        name="mobile"
        className="signup_input_border"
        placeholder={t("signIn.phone_placeholder")}
        label={t("signIn.phone_label")}
        setButtonDisabled={setIsButtonDisable}
      />
      <div className="padditopt10"></div>
      <div className="padditopt10"></div>

      <Button
        disabled={isButtonDisable}
        buttonLabel={t("signIn.continue")}
        onClick={siginHandler}
      />
    </div>
  );
}
