import React, { useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/paint.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import { t } from "i18next";
import { Button } from "../../../_components/Button/Button";
import { postContactUs } from "../../../services/api/owner.service";
import Loader from "../../../_components/spinner/Loader";
import { useSnackbar } from "notistack";
export const ContactUs = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    phoneNumber: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    fullAddress: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    serviceDetails: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    email: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
  });
  const validate = (name, value, message) => {
    const newState = { ...formData };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;
    var regexpEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    switch (name) {
      case "name":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Full Name is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "email":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Email is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (!value.match(regexpEmail)) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Please enter a valid Email";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value.match(regexpEmail)) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "phoneNumber":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "phone number is Required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "fullAddress":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Address is Required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;
      case "serviceDetails":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Query is Required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      default: {
        return newState;
      }
    }
  };
  const onChangeHandler = (event, key) => {
    let validatedState = validate(key, event.target.value, undefined);
    setFormData(validatedState);
  };

  /**
   * Calls when submitting a form
   */
  const onSubmitForm = () => {
    const formValid = Object.values(formData).every(
      (val) => val.valid === true
    );
    if (formValid) {
      setIsLoading(true);
      const postData = {
        fullName: formData.name.value,
        email: formData.email.value,
        phoneNumber: formData.phoneNumber.value,
        fullAddress: formData.fullAddress.value,
        serviceDetails: formData.serviceDetails.value,
      };
      postContactUs(postData).finally((_) => {
        const variant = "success";
        enqueueSnackbar("Thanks for your query! We will get back to you soon", {
          variant,
        });
        setTimeout(() => {
          history.push("/");
        }, 1000);
      });
    } else {
      Object.values(formData).forEach((el, i) => {
        if (!el.valid) {
          let keyValue = Object.keys(formData)[i];
          var errorState = validate(keyValue, el.value, undefined);
          setFormData(errorState);
        }
      });
    }
  };

  return (
    <div className="insideWrp">
      <HeaderMenu />
      <Loader progress={isLoading} />
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left ">
          <label className="paint_header">{t("paintingother.letstalk")}</label>
        </div>

        <div className="otherspadding25">
          <label className="paint_other_sub_header">
            {t("paintingother.letusknow")}
          </label>
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "name")}
            value={formData.name.value}
            error={formData.name.error}
            type={"text"}
            placeholder={t("paintingother.plzname")}
            label={t("personalInfo.name")}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "email")}
            value={formData.email.value}
            error={formData.email.error}
            placeholder={t("paintingother.plzemail")}
            label={t("paintingother.email")}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            type={"number"}
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "phoneNumber")}
            value={formData.phoneNumber.value}
            error={formData.phoneNumber.error}
            placeholder={t("paintingother.plzphone")}
            label={t("paintingother.phone")}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "fullAddress")}
            value={formData.fullAddress.value}
            error={formData.fullAddress.error}
            placeholder={t("personalInfo.pleaseaddress")}
            label={t("personalInfo.address")}
          />
        </div>
        <div className="otherspadding25"></div>
        <label className="summaryDivheader1">
          {t("paintingother.describe")}
        </label>
        <div className="commandspadding"></div>
        <textarea
          type="text"
          onChange={(event) => onChangeHandler(event, "serviceDetails")}
          value={formData.serviceDetails.value}
          error={formData.serviceDetails.error}
          placeholder={t("termsMenue.comm")}
          className="paintsummaryCommands message"
        />
        {formData.serviceDetails.error && (
          <span style={{ color: "red" }}>
            {formData.serviceDetails.error.message}
          </span>
        )}
        <div className="profilePadding30"></div>
        <Button buttonLabel={t("termsMenue.send")} onClick={onSubmitForm} />
      </div>

      <Footer />
    </div>
  );
};
