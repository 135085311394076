import IMAGES from '../../_assets/images/index'

const otherSliderItems = [
   {

      urls: IMAGES.home.others,
   },
   {

      urls: IMAGES.home.hdBathroom,
   },
   {

      urls: IMAGES.home.hdSofa,
   },
   {

      urls: IMAGES.home.hdkitchen,
   },
   {

      urls: IMAGES.home.hdBathroom,
   },
   {

      urls: IMAGES.home.newbasement,
   },
];
export default otherSliderItems