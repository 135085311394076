import React, { useEffect, useState, useContext } from "react";
import IMAGES from "../../../_assets/images";
import { TextInput } from "../../../_components/TextInput/TextInput";
import DialogNotification from "../../../_components/Dialog/DialogNotification";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from "./socialMediaSignup/googleLogin";
import FacebookLogin from "./socialMediaSignup/facebookLogin";
import "../../../_styles/localStyles/signupsignin.css";
import "../../../_styles/globalStyles/global.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  manuelRegister,
  manuelSignupService,
} from "../../../services/api/signup.service";
import { continuetoNext, RegisterAuthAction } from "../../../_redux/actions";
import { Button } from "../../../_components/Button/Button";
import AlertBox from "../../../_components/snackbar/alertBox";
import { signupModel } from "../../../_redux/Models/auth.models";
import Loader from "../../../_components/spinner/Loader";
import { useSnackbar } from "notistack";
import { Eyehide } from "../../../_components/Tickandwrongsvg/eyehideVector";
import { Eyeshow } from "../../../_components/Tickandwrongsvg/eyeshowVector";
import CustomPhoneInput from "../../../_components/PhoneInput/PhoneInput";
// import { useState } from "react";
function Register(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const eye = <FontAwesomeIcon icon={faEye} />;
  const user = useSelector((state) =>
    state.signupReducer ? state.signupReducer.user : null
  );
  if (!user) {
    history.push("/role");
  }
  const { buttonStatus } = useSelector((state) => state.buttonReducer);
  const selectedProp = localStorage.getItem("selectedProperty");

  const [signupState, setsignupState] = useState({
    fullName: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    email: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    mobileNumber: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    password: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    zipcode: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    address: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
  });
  const [isProgress, setIsProgress] = useState(false);

  const onChangeHandler = (event, key) => {
    let validatedState = validate(key, event.target.value, undefined);
    setsignupState(validatedState);
  };

  const validate = (name, value, message) => {
    const newState = { ...signupState };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;
    var regexpEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    var regexpPhone = /^(?:\+1)?\d{10}$/;

    switch (name) {
      case "fullName":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Full Name is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value.length < 3 || value.length > 50) {
          errorMessage =
            "Name should have at least 3 characters and maximum 50";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "email":
        if (!value || value == "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Email is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (!value.match(regexpEmail)) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Please enter a valid Email";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value.match(regexpEmail)) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "mobileNumber":
        if (!value || value == "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Phone number is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (!value.match(regexpPhone)) {
          errorMessage =
            errorMessage !== null
              ? errorMessage
              : "Please enter a valid Phone number";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value.match(regexpPhone)) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "password":
        if (!value || value == "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Password is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (
          !value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)
        ) {
          errorMessage =
            errorMessage !== null
              ? errorMessage
              : "Must contains special characters and numbers";
          // : " Must contains at least 8 characters and should contain special characters and numbers";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (
          value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)
        ) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "zipcode":
        if (!value || value == "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Zip code is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (
          (value && !value.match(/[0-9]/g)) ||
          ![5, 6].includes(value.length)
        ) {
          errorMessage =
            errorMessage !== null
              ? errorMessage
              : "Please enter a valid Zip Code";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value && value.match(/[0-9]/g)) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;
      case "address":
        if (!value || value == "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Address is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      default: {
        return newState;
      }
    }
  };

  const signInClick = () => {
    history.push("/signin");
  };

  const arrowClick = () => {
    if (user && user.role === "owner") {
      history.push("/property");
    } else {
      history.push("/role");
    }
  };

  const registerSubmit = async () => {
    const resObject = {
      name: signupState.fullName.value,
      typeOfProperty: user.property,
      email: signupState.email.value,
      mobileNumber: signupState.mobileNumber.value,
      password: signupState.password.value,
      address: signupState.address.value,
      zipCode: signupState.zipcode.value,
    };
    const formValid = Object.values(signupState).every(
      (val) => val.valid === true
    );
    if (formValid) {
      setIsProgress(true);
      if (user !== undefined) {
        var res = await manuelRegister(resObject, user.signuserType);
        if (res && res.status === 200) {
          signupModel.userVerified = { ...res.data };
          signupModel._id = { ...res.data };
          let userData = res.data;
          userData.originalStatus = userData.userType;
          localStorage.setItem("signupInfo", JSON.stringify(userData));
          dispatch(continuetoNext(signupModel));
          history.push("/validation");
          setIsProgress(false);
        } else {
          if (res && res.response.status >= 300) {
            setIsProgress(false);
            const variant = "error";
            const errorMessage = res.response.data.message;
            enqueueSnackbar(errorMessage, { variant });
          }
        }
        setIsProgress(false);
      }
    } else {
      Object.values(signupState).map((el, i) => {
        if (!el.valid) {
          let keyValue = Object.keys(signupState)[i];
          var errorState = validate(keyValue, el.value, undefined);
          setsignupState(errorState);
        }
      });
    }
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [show, setShow] = useState(false);

  return (
    <div className="pcWidth">
      <Loader progress={isProgress} />
      <div className="signup_center_align">
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  src={IMAGES.other.vectors}
                  alt=""
                  onClick={() => arrowClick()}
                />
              </td>
              <td>
                <label
                  className="signup_page_anyhnb_logo"
                  onClick={() => window.location.replace("/")}
                >
                  {t("signUp.mainHeading")}
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <label className="signup_header signup_flex_with_center">
        {t("signUp.signUpHeading")}
      </label>
      <div className="signup_flex_with_center">
        {" "}
        <label className="signup_sub_heading">{t("signUp.subHeading")}</label>
        <label className="signup_sign_linkbtn" onClick={() => signInClick()}>
          {t("signUp.signUp_link")}
        </label>{" "}
      </div>

      <div className="signup_section">
        <div className="register_page_lable">
          <TextInput
            className="signup_input_border"
            label={t("signIn.Full_name")}
            type="text"
            onChange={(event) => onChangeHandler(event, "fullName")}
            value={signupState.fullName.value}
            error={signupState.fullName.error}
            placeholder={t("signIn.Full_name")}
          />
        </div>
        <div className="register_page_lable">
          <TextInput
            className="signup_input_border"
            label={t("signIn.email_label")}
            type="text"
            onChange={(event) => onChangeHandler(event, "email")}
            value={signupState.email.value}
            error={signupState.email.error}
            placeholder={t("signIn.email_placeholder")}
          />
        </div>
        <div className="register_page_lable">
          <CustomPhoneInput
            valueState={signupState}
            setValueState={setsignupState}
            name="mobileNumber"
            className="signup_input_border"
            placeholder={t("signIn.phone_placeholder")}
            label={t("signIn.phone_label")}
          />
        </div>
        <div className="flex register_page_lable">
          <TextInput
            className="signup_input_border"
            onChange={(event) => onChangeHandler(event, "password")}
            value={signupState.password.value}
            error={signupState.password.error}
            type={passwordShown ? "text" : "password"}
            placeholder={t("signIn.password_placeholder")}
            label={t("signIn.password_label")}
          />

          {/* <i className="signup_eyeIcon" onClick={togglePasswordVisiblity}>{eye}</i>{" "} */}

          {/* {!passwordShown?():()} */}
          {(() => {
            if (signupState.password.error.flag == true) {
              return (
                <div onClick={() => setShow(!show)}>
                  {!show ? (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyehide fill="#d9083a" />
                    </div>
                  ) : (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyeshow fill="#d9083a" />
                    </div>
                  )}
                </div>
              );
            }
            if (signupState.password.error.flag == false) {
              return (
                <div onClick={() => setShow(!show)}>
                  {!show ? (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyehide fill="#5fca5d" />
                    </div>
                  ) : (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyeshow fill="#5fca5d" />
                    </div>
                  )}
                </div>
              );
            } else {
              return (
                <div onClick={() => setShow(!show)}>
                  {!show ? (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyehide />
                    </div>
                  ) : (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyeshow />
                    </div>
                  )}
                </div>
              );
            }
          })()}
        </div>

        {/* <EyeIcon/> */}

        <div className="register_page_lable">
          <TextInput
            className="signup_input_border"
            onChange={(event) => onChangeHandler(event, "zipcode")}
            value={signupState.zipcode.value}
            error={signupState.zipcode.error}
            type="text"
            placeholder={t("signIn.Zip_Code")}
            label={t("signIn.Zip_Code")}
          />
        </div>

        <div className="register_page_lable">
          <TextInput
            className="signup_input_border"
            onChange={(event) => onChangeHandler(event, "address")}
            value={signupState.address.value}
            error={signupState.address.error}
            type="text"
            placeholder={t("signUp.address")}
            label={t("signUp.address")}
          />
        </div>
      </div>

      <div className="signup_sub terms_and_codition terms_and_codition_color signup_flex_with_center">
        {t("signIn.terms_condition")}
      </div>

      <div className="signup_sub_head terms_and_codition signup_flex_with_center">
        <div className="signup_termsColor">{t("signIn.terms_con")}</div>{" "}
        <div className="terms_and_codition_gap">{t("signIn.and")} </div>{" "}
        <div className="signup_termsColor">{t("signIn.privacy")}</div>
      </div>

      <div>
        <div className="signup_button_padding">
          <Button
            disabled={buttonStatus}
            buttonLabel={t("signIn.continue")}
            onClick={() => registerSubmit()}
          />
          {/* <div
            className="signup_continue_button"
            onClick={() => registerSubmit()}
          >
            {t("signIn.continue")}
           
          </div> */}
        </div>

        <div className="signup_needHelp">
          <span> {t("signIn.needHelp_link")}</span>
        </div>
      </div>
    </div>
  );
}
export default Register;
