import React from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/profileCreate.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { withTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import { t } from "i18next";
import { Button } from "../../../_components/Button/Button";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { connect } from "react-redux";
import { continuetoNext } from "../../../_redux/actions";
import Loader from "../../../_components/spinner/Loader";
import {
  uploadContractorPictureOfWork,
  uploadProfileDocs,
} from "../../../services/api/signup.service";
import {
  getContractor,
  removePicOfWorkImage,
} from "../../../services/api/contractor.service";
import {
  downloadImgFrom,
  parseJwt,
} from "../../../services/utils/utilFunctions";
import Compressor from "compressorjs";
import { withSnackbar } from "notistack";

class ContractorProfileInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      stepValue:
        this.props.match && this.props.match.params
          ? this.props.match.params.stepValue
          : 0,
      user: null,
      formData: {
        businessName: "",
        companyHighlight: "",
        companyDescription: "",
        managerName: "",
        managerExperience: "",
        businessAddress: "",
        phoneNumber: "",
        profileImage: "",
        managerId: "",
        license: "",
        tradeLicense: "",
        picturesOfWork: [],
      },
      selectedServices: [],
      uploadedFiles: {
        tradeLicense: "",
        managerId: "",
        license: "",
        licenses: [],
      },
    };
    this.isRemoving = {};
  }

  componentDidMount = () => {
    this.getUserData();
  };

  getUserData = () => {
    console.log("called");
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (user) {
      this.setState({ isLoading: true });
      getContractor(user._id)
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.setContractor(response);
          }
        })
        .finally((_) => this.setState({ isLoading: false }));
    }
  };

  setContractor = (response) => {
    const user = response.data;
    let formData = this.state.formData;
    formData["businessName"] = user?.businessName
      ? user?.businessName
      : formData.businessName;
    formData["businessAddress"] = user?.businessAddress
      ? user?.businessAddress
      : formData.businessAddress;
    formData["companyDescription"] = user?.description
      ? user?.description
      : formData.companyDescription;
    formData["companyHighlight"] = user?.highlight
      ? user?.highlight
      : formData.companyHighlight;
    formData["managerName"] = user?.managerName
      ? user?.managerName
      : formData.managerName;
    formData["phoneNumber"] = user?.phoneNumber
      ? user?.phoneNumber
      : formData.phoneNumber;
    formData["managerExperience"] = user?.managerExperience
      ? user?.managerExperience
      : formData.managerExperience;
    let uploadedFiles = this.state.uploadedFiles;
    uploadedFiles.license = user?.licensePath ? user?.licensePath : "";
    uploadedFiles.licenses = user?.licenses ? user?.licenses : [];
    uploadedFiles.tradeLicense = user?.tradeLicensePath
      ? user?.tradeLicensePath
      : "";
    uploadedFiles.managerId = user?.managerIdPath ? user?.managerIdPath : "";

    this.setState({
      user: response.data,
      selectedServices: user?.services ? user?.services : [],
      formData: formData,
      uploadedFiles: uploadedFiles,
    });
  };

  /**
   *
   * @param {String} key key thats needs update
   * @param {String} value value to update
   */
  onChangeFormDataState = (key, value, callback) => {
    const formData = this.state.formData;
    formData[key] = value;
    this.setState({ formData }, callback ? callback : null);
  };

  handleFileUploadFor = (file, field) => {
    if (file.type === "image/jpeg") {
      new Compressor(file, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          this.fileToDataUri(compressedResult).then((dataUri) => {
            fetch(dataUri)
              .then((res) => res.blob())
              .then((res) => {
                this.uploadFilesToServer(res, field, () => {
                  this.onChangeFormDataState(field, res);
                  this.getUserData();
                });
              });
          });
        },
      });
    } else {
      this.uploadFilesToServer(file, field, () => {
        this.onChangeFormDataState(field, file);
        this.getUserData();
      });
    }
  };

  handleFileUploadForPicturesOfWork = (file, categoryId) => {
    new Compressor(file, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        this.fileToDataUri(compressedResult).then((dataUri) => {
          fetch(dataUri)
            .then((res) => res.blob())
            .then((res) => {
              const formData = this.state.formData;
              const picturesOfWork = formData["picturesOfWork"];
              picturesOfWork.push({
                categoryId: categoryId,
                image: res,
              });
              formData["picturesOfWork"] = picturesOfWork;
              this.uploadPictureOfWorkToServer(res, categoryId, () => {
                this.getUserData();
                this.setState({
                  formData: formData,
                });
              });
            });
        });
      },
    });
  };

  fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  uploadPictureOfWorkToServer = (file, categoryId, callback) => {
    const postData = new FormData();
    if (file && categoryId) {
      this.setState({ isLoading: true });
      postData.append("categoryId", categoryId);
      postData.append("file", file);
      uploadContractorPictureOfWork(postData)
        .then((data) => {
          if (data.status !== 200) {
            const variant = "error";
            this.props.enqueueSnackbar("Upload failed please try again", {
              variant,
            });
          } else if (callback) {
            const variant = "success";
            this.props.enqueueSnackbar("Uploaded successfully", { variant });
            callback();
          }
        })
        .catch((e) => {
          const variant = "error";
          this.props.enqueueSnackbar(e.toString(), { variant });
        })
        .finally((_) => {
          this.setState({ isLoading: false });
        });
    }
  };

  uploadFilesToServer = (file, field, callback) => {
    const postData = new FormData();
    if (file && field) {
      this.setState({ isLoading: true });
      postData.append(field, file);
      uploadProfileDocs(postData)
        .then((data) => {
          if (data.status !== 200) {
            const variant = "error";
            this.props.enqueueSnackbar("Upload failed please try again", {
              variant,
            });
          } else if (callback) {
            callback();
          }
        })
        .catch((e) => {
          const variant = "error";
          this.props.enqueueSnackbar(e.toString(), { variant });
        })
        .finally((_) => {
          this.setState({ isLoading: false });
        });
    }
  };

  submitForm = () => {
    const { formData } = this.state;
    this.setState({ isLoading: true });
    const postData = new FormData();
    postData.append("businessName", formData.businessName);
    postData.append("highlight", formData.companyHighlight);
    postData.append("description", formData.companyDescription);
    postData.append("managerName", formData.managerName);
    postData.append("managerExperience", formData.managerExperience);
    postData.append("businessAddress", formData.businessAddress);
    postData.append("phoneNumber", formData.phoneNumber);

    //postData.append("services", JSON.stringify(selectedServicesToSend));
    var resultMessage = "";
    var result = "";
    uploadProfileDocs(postData)
      .then((data) => {
        if (data.status !== 200) {
          result = "error";
          resultMessage = "Something went wrong please try again";
        } else {
          if (this.props.location.state?.redirectUrl) {
            this.props.history.push(this.props.location.state.redirectUrl);
          } else {
            this.props.history.push("/createProfile/profile-complete");
          }
        }
      })
      .catch((e) => {
        result = "error";
        resultMessage = e.toString();
      })
      .finally((_) => {
        this.setState({ isLoading: false }, () => {
          this.props.enqueueSnackbar(resultMessage, { variant: result });
        });
      });
  };

  render = () => {
    const { formData, isError, user } = this.state;
    return (
      <div className="insideWrp">
        <HeaderMenu />
        <Loader progress={this.state.isLoading} />
        <div style={{ padding: "0px 20px 20px 20px" }}>
          {/* profile picture */}
          <div>
            <div className="profilePadding30 personal_flex_with_center">
              <div className="contactor_profile_image stable-class">
                <div className="profile_image stable-class">
                  <label className="profile_visible_image">
                    <img
                      src={
                        formData.profileImage
                          ? URL.createObjectURL(formData.profileImage)
                          : user && user.profileImage
                          ? user.profileImage
                          : IMAGES.home.hdmanSlide
                      }
                      alt="profile-pic-contractor"
                    />
                    <label className="profile_edit_button">
                      <span></span>

                      <input
                        type="file"
                        accept="image/*"
                        name="file"
                        id="filefirst"
                        className="inputfile"
                        onChange={(e) =>
                          this.handleFileUploadFor(
                            e.target.files[0],
                            "profileImage"
                          )
                        }
                      />
                    </label>
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* End profile picture */}
          <div className="paint_flex_with_left profilePadding25">
            <label className="paint_header">
              {t("profileCreation.profinfo")}
            </label>
          </div>

          {/* BusinessName */}

          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(e) =>
                this.onChangeFormDataState("businessName", e.target.value)
              }
              value={formData.businessName}
              error={isError && !formData.businessName}
              placeholder={t("profileCreation.bussinessName")}
              label={t("profileCreation.busname")}
            />
          </div>
          {/* End BusinessName */}

          {/* HighlightOf company */}
          <div className="otherspadding25"></div>
          <label className="summaryDivheader1">
            {t("profileCreation.hilight")}
          </label>
          <label className="profileTextarea">{t("profileCreation.max")}</label>
          <div className="commandspadding"></div>
          <textarea
            onChange={(e) => {
              if (e.target.value.length <= 100) {
                this.onChangeFormDataState("companyHighlight", e.target.value);
              }
            }}
            value={formData.companyHighlight}
            type="text"
            placeholder={t("profileCreation.somthing")}
            className="paintsummaryCommands message"
          />
          {/* End HighlightOf company */}

          {/* Description of company */}
          <div className="otherspadding25"></div>
          <label className="summaryDivheader1">
            {t("profileCreation.describe")}
          </label>
          <label className="profileTextarea">
            {t("profileCreation.max1000")}
          </label>
          <div className="commandspadding"></div>
          <textarea
            onChange={(e) => {
              if (e.target.value.length <= 1000) {
                this.onChangeFormDataState(
                  "companyDescription",
                  e.target.value
                );
              }
            }}
            value={formData.companyDescription}
            type="text"
            placeholder={t("profileCreation.message")}
            className="paintsummaryCommands message"
          />
          {/* End Description company */}

          {/* manager name */}

          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(e) =>
                this.onChangeFormDataState("managerName", e.target.value)
              }
              value={formData.managerName}
              error={isError && !formData.managerName}
              placeholder={t("profileCreation.managerName")}
              label={t("profileCreation.managerName")}
            />
          </div>
          {/* End manager name */}

          {/* manager Phone and Experience */}

          <div
            className="paintinput_lable inline_input"
            style={{ gap: "15px" }}
          >
            <div>
              <TextInput
                className="paint_input_border"
                onChange={(e) =>
                  this.onChangeFormDataState(
                    "managerExperience",
                    e.target.value
                  )
                }
                type={"number"}
                value={formData.managerExperience}
                error={isError && !formData.managerExperience}
                placeholder={t("profileCreation.pleaseEnter")}
                label={t("profileCreation.yearsOfOperating")}
              />
            </div>

            <div>
              <TextInput
                className="paint_input_border"
                onChange={(e) =>
                  this.onChangeFormDataState("phoneNumber", e.target.value)
                }
                value={formData.phoneNumber}
                type={"number"}
                error={isError && !formData.phoneNumber}
                placeholder={t("profileCreation.phoneNumber")}
                label={t("profileCreation.phoneNumber")}
              />
            </div>
          </div>
          {/* End manager Phone and Experience */}

          {/* manager name */}

          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(e) =>
                this.onChangeFormDataState("businessAddress", e.target.value)
              }
              value={formData.businessAddress}
              error={isError && !formData.businessAddress}
              placeholder={t("profileCreation.businessAddress")}
              label={t("profileCreation.businessAddress")}
            />
          </div>
          {/* End manager name */}

          {/* license Id */}
          {this.createFileUploadSectionFor("managerId")}
          {this.createFileUploadSectionForlicenses()}
          {this.createFileUploadSectionFor("tradeLicense")}
          <div className="otherspadding25"></div>
          {this.createFileUploadSectionForPicturesOfWork()}
          {/* End certificate Or License */}

          <div className="profilePadding20"></div>
          <Button
            disabled={false}
            buttonLabel={t("paintingHomes.continue")}
            onClick={this.submitForm}
          />
        </div>
        <Footer />
      </div>
    );
  };

  generateFilePreviewFor = (file, componentName) => {
    const { uploadedFiles } = this.state;
    let extension = file.slice(((file.lastIndexOf(".") - 1) >>> 0) + 2).trim();
    if (
      extension.slice(-1) === "_" ||
      ["png", "jpg", "jpeg", "JPG"].includes(extension)
    ) {
      return (
        <div
          key={componentName + extension}
          id="imageWrapper"
          className={componentName}
        >
          <img
            className="profileDivmargin uploadwidthHeight"
            src={
              uploadedFiles[componentName]
                ? uploadedFiles[componentName]
                : uploadedFiles[componentName]
                ? URL.createObjectURL(this.state.formData[componentName])
                : ""
            }
            alt=""
          />
        </div>
      );
    } else {
      return (
        <div
          onClick={() => downloadImgFrom(file, componentName)}
          id="imageWrapper"
          className={componentName}
        >
          <img
            className="profileDivmargin uploadwidthHeight"
            src={IMAGES.other.downloadFile}
            alt=""
          />
        </div>
      );
    }
  };

  createFileUploadSectionFor = (componentName) => {
    const { uploadedFiles } = this.state;
    return (
      <div key={Math.random()}>
        <div className="otherspadding25"></div>
        <label className="profileUploadlabel">
          {t(`profileCreation.${componentName}`)}
        </label>
        <div className="paintPadding8"></div>
        <div className="personal_flex_with_center profilePadding10 details_wrp">
          {(this.state.formData[componentName] ||
            (uploadedFiles && uploadedFiles[componentName])) &&
            this.generateFilePreviewFor(
              uploadedFiles[componentName],
              componentName
            )}
          <label className="upload_image_sec ">
            <input
              type="file"
              name="file"
              accept={
                "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, image/*"
              }
              id="managerIdTemp"
              className="inputfile"
              onChange={(e) =>
                this.handleFileUploadFor(e.target.files[0], componentName)
              }
            />
            <div className="profileDocfont allCenterGrid ">
              <img src={IMAGES.home.profileVector} alt="" />
              <div className="profilePadding5"></div>
              <label>{t("profileCreation.doc")}</label>
            </div>
          </label>
          <div className="browseLbl_blk">
            <label className="browseLbl">
              {t("profileCreation.browse")}
              <input
                type="file"
                name="file"
                accept={
                  "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, image/*"
                }
                id="managerId"
                className="inputfile"
                onChange={(e) =>
                  this.handleFileUploadFor(e.target.files[0], componentName)
                }
              />
            </label>
          </div>
          <div className="browseLbl_blk">
            <label className="browseValidationlbl">
              {t("profileCreation.accept")}
            </label>
          </div>
        </div>
      </div>
    );
  };

  removeImage = (url, imageId) => {
    if (this.isRemoving[url]) return;
    this.isRemoving[url] = true;
    removePicOfWorkImage(imageId, { imageUrl: url })
      .then((data) => {
        if (data.status !== 200) {
          const variant = "error";
          this.props.enqueueSnackbar("Upload failed please try again", {
            variant,
          });
        } else {
          this.getUserData();
        }
      })
      .catch((e) => {
        const variant = "error";
        this.props.enqueueSnackbar(e.toString(), { variant });
      })
      .finally((_) => {
        this.setState({ isLoading: false });
        this.isRemoving[url] = false;
      });
  };

  createFileUploadSectionForlicenses = () => {
    const { uploadedFiles } = this.state;
    const licenses = [...uploadedFiles.licenses];
    // this.state.formData["license"] &&
    //   licenses.push(URL.createObjectURL(this.state.formData["license"]));
    return (
      <div key={Math.random()}>
        <div className="otherspadding25"></div>
        <label className="profileUploadlabel">
          {t(`profileCreation.license`)}
        </label>
        <div className="paintPadding8"></div>
        <div className="personal_flex_with_center profilePadding10 details_wrp">
          {licenses.length > 0
            ? licenses.map((license) =>
                this.generateFilePreviewFor(license, "license")
              )
            : null}
          <label className="upload_image_sec ">
            <input
              type="file"
              name="file"
              accept={
                "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, image/*"
              }
              id="tradeLicenseTmp"
              className="inputfile"
              onChange={(e) => {
                this.handleFileUploadFor(e.target.files[0], "license");
                this.setState({
                  uploadedFiles: { ...uploadedFiles, licenses },
                });
              }}
            />
            <div className="profileDocfont allCenterGrid ">
              <img src={IMAGES.home.profileVector} alt="" />
              <div className="profilePadding5"></div>
              <label>{t("profileCreation.doc")}</label>
            </div>
          </label>
          <div className="browseLbl_blk">
            <label className="browseLbl">
              {t("profileCreation.browse")}
              <input
                type="file"
                name="file"
                accept={
                  "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, image/*"
                }
                id="tradeLicense"
                className="inputfile"
                onChange={(e) => {
                  this.handleFileUploadFor(e.target.files[0], "license");
                  this.setState({
                    uploadedFiles: { ...uploadedFiles, licenses },
                  });
                }}
              />
            </label>
          </div>
          <div className="browseLbl_blk">
            <label className="browseValidationlbl">
              {t("profileCreation.accept")}
            </label>
          </div>
        </div>
      </div>
    );
  };

  createFileUploadSectionForPicturesOfWork = () => {
    const { selectedServices, formData, user } = this.state;
    return (
      <div key={Math.random()}>
        {selectedServices && selectedServices.length ? (
          <div className="YourWrk_wrp">
            <div className="your-work-pictures">
              <label className="profileUploadlabel">
                {t("profileCreation.yourwork")}
              </label>
            </div>
            {selectedServices.map((item, index) => {
              return (
                <div key={Math.random()}>
                  <div className="otherspadding25"></div>
                  <label className="">{item.category_name} pictures</label>
                  <div className="paintPadding8"></div>
                  <div className="personal_flex_with_center profilePadding10">
                    {/* {formData.picturesOfWork.length > 0
                      ? formData.picturesOfWork.map((el) => {
                        if (el.categoryId === item.categoryId) {
                          return (
                            <img
                              key={item.categoryId}
                              className="profileDivmargin uploadwidthHeight"
                              src={URL.createObjectURL(el.image)}
                              alt=""
                            />
                          );
                        } else {
                          return null;
                        }
                      })
                      : ""} */}
                    {user &&
                      user.picturesOfWork &&
                      user.picturesOfWork
                        .filter((el) => el.categoryId === item.categoryId?._id)
                        .map((pic) => {
                          return pic?.images.map((el) => {
                            if (el) {
                              return (
                                <div id="imageWrapper-pow">
                                  <div
                                    className={`gradient ${
                                      this.isRemoving[el] ? "removing" : ""
                                    }`}
                                    onClick={() =>
                                      this.removeImage(el, pic._id)
                                    }
                                  >
                                    <span>x</span>
                                  </div>
                                  <img
                                    key={Math.random()}
                                    className="profileDivmargin uploadwidthHeight"
                                    src={el}
                                    alt=""
                                  />
                                </div>
                              );
                            } else {
                              return null;
                            }
                          });
                        })}
                    {/* {user &&
                      user.picturesOfWork &&
                      user.picturesOfWork
                        .find((el) => el.categoryId === item.categoryId)
                        ?.images.map((el) => {
                          if (el) {
                            return (
                              <img
                                key={Math.random()}
                                className="profileDivmargin uploadwidthHeight"
                                src={el}
                                alt=""
                              />
                            );
                          } else {
                            return null;
                          }
                        })} */}
                    <label>
                      <div className="profileDocfont allCenterGrid">
                        <img src={IMAGES.home.profileVector} alt="" />
                        <div className="">
                          <label>{t("profileCreation.photo")}</label>
                        </div>
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        name="file"
                        id={index}
                        className="inputfile"
                        onChange={(e) =>
                          this.handleFileUploadForPicturesOfWork(
                            e.target.files[0],
                            item.categoryId?._id
                          )
                        }
                      />
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  signup: state.signupReducer,
});

const mapDispatchToProps = () => ({
  continuetoNext,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(withSnackbar(ContractorProfileInfo)));
