/**
 * @author Geethananth M
 * Model Object to hold the windowModel
 */

/**
 * Model For windowModel
 */
export const windowsModel = {
  flowName: "window-flow",
  serviceType: "",
  reasonForWindow: "",
  numberOfWindowsNeeded: "",
  haveOwnWindows: "",
  windowDetails: [],
  addOns: [],
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  costFor: {
    window: 0,
    material: 0,
    addOns: 0,
  },
};
