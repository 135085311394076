import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/home.css";
import "../../../_styles/localStyles/paint.css";
import "../../../_styles/localStyles/floor.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { exteriorModel } from "../../../_redux/Models/painting.models";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, paintingStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { getServicePriceDetails } from "../../../services/api/category.api.service";
import { Button } from "../../../_components/Button/Button";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
import { useHistory } from "react-router-dom";

export const Housesizeing = (props) => {
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 0;
  const { t } = useTranslation();
  const history = useHistory();
  const [colorDetails, setColorDetails] = useState({
    sqft: "",
  });
  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : exteriorModel
  );
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const price = calculateTotalCost(exteriorModel.costFor); // To keep the total price in state

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;

    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          console.log(response.data.details);
          checkoutModelState.priceDetails = response.data.details;
          checkoutModelState.totalCost = 0;
          dispatch(checkoutStore(checkoutModelState));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch]);

  const onSave = () => {
    state.size = colorDetails.sqft;
    state.costFor = exteriorModel.costFor;
    dispatch(paintingStore(state));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/painting/exterior/house-material/${3}`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">
            {t("paintingExterior.introduce")}
          </label>
        </div>

        <div>
          <label className="paint_other_sub_header">
            {t("paintingExterior.pleaseintroduce")}
          </label>
        </div>

        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border paintexterior border-bottom"
            onChange={(event) =>
              setColorDetails({ ...colorDetails, sqft: event.target.value })
            }
            value={colorDetails.sqft}
            type={"number"}
            placeholder={t("paintingExterior.enternumber")}
            label={t("paintingExterior.sizing")}
          />

          <div className="paint_placeholder_right1">
            <label>{t("paintingInterior.sqft")}</label>
          </div>
        </div>
        <div className="profilePadding40"></div>
        <Button
          disabled={!colorDetails.sqft}
          buttonLabel={t("basement.continueToNext")}
          onClick={onSave}
        />
      </div>
    </div>
  );
};
