import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorTilesModel } from "../../../_redux/Models/Floor.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const FloorSizeDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 3;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [engineeredFloorSize, setEngineeredFloorSize] = useState("");
  const [tileOwnership, setTileOwnership] = useState("");

  const floorState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorTilesModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(calculateTotalCost(floorState.costFor)); // To keep the total price in state
  const [pricingOptions, setPricingOptions] = useState([]);
  const [tileQuestions, setTileQuestions] = useState([
    {
      text: t("tiles.own"),
      value: "own",
      price: 0,
    },
    {
      text: t("tiles.provide"),
      value: "provide",
      price: 0,
    },
  ]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;

    if (mounted === true && priceDetails && priceDetails.size) {
      const optionsArr = priceDetails.size.map((op) => {
        return {
          text: t(`tiles.${op.key}`),
          value: op.key,
          servicePrice: op.servicePrice,
          materialPrice: op.materialPrice,
        };
      });
      setPricingOptions(optionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, floorState, t]);

  const setPricing = (selectedSize) => {
    const size = parseFloat(floorState.floorSize);
    const servicePrice = pricingOptions.find(
      (op) => op.value === selectedSize
    ).servicePrice;
    const materialPrice = pricingOptions.find(
      (op) => op.value === selectedSize
    ).materialPrice;

    const tileQuestions = [
      {
        text: t("tiles.own"),
        value: "own",
        price: size * servicePrice,
      },
      {
        text: t("tiles.provide"),
        value: "provide",
        price: (servicePrice + materialPrice) * size,
      },
    ];

    setTileQuestions(tileQuestions);
  };

  /**
   * Function to save Selected floor size and take user to next step
   * @returns {void}
   */
  const onSave = () => {
    floorState.tileSize = engineeredFloorSize;
    floorState.haveOwnTiles = tileOwnership;
    floorState.costFor.sizeDetails =
      price - calculateTotalCost(floorState.costFor);
    dispatch(floorStore(floorState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    var step = floorState.priorFloorRemovalRequired ? 4 : 3;
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/tiles/floor-addons/${step}`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("floor.SelectSizeDetails")}
            </label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("tiles.install")}
            </label>
          </div>
          <div className="padding10"></div>
          {pricingOptions.map((item, index) => (
            <div
              className={`floor-selection-radio ${
                engineeredFloorSize == item?.value ? "selected" : ""
              }`}
              key={index}
              onClick={() => {
                setEngineeredFloorSize(item.value);
                setPricing(item.value);
              }}
            >
              <div key={index} className="width100">
                <label className="paintdiv_txt"> {item.text} </label>
              </div>
            </div>
          ))}

          <div className="padding10"></div>
          <div className="padding10 allCenter">
            <label className="carpettxtinfo">{t("tiles.estimative")}</label>
          </div>
          <div className="paint_flex_with_left">
            <label className="paint_header">{t("tiles.tiles")}</label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("tiles.contractor")}
            </label>
          </div>
          <div className="padding10"></div>
          {tileQuestions.map((item, index) => (
            <div
              className={`floor-selection-radio ${
                tileOwnership === item?.value ? "selected" : ""
              }`}
              key={index}
              onClick={() => {
                const total = calculateTotalCost(floorState.costFor);
                setPrice(total + item.price);
                setTileOwnership(item.value);
              }}
            >
              <div
                key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="paintdiv_txt"
                  style={{
                    width: "175px",
                  }}
                >
                  {item.text}{" "}
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="padding10"></div>
          <Button
            disabled={engineeredFloorSize === "" || tileOwnership === ""}
            buttonLabel={t("paintingHomes.continue")}
            onClick={onSave}
          />
        </div>
      </div>
    </div>
  );
};
