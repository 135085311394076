export var bathroomFlowModel = {
  flowName: "bathroom",
  selectedStyle: "",
  numberOfBathrooms: 1,
  bathroomsDetails: [],
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
};
