import { useContext } from "react";
import ConfirmContext from "./Notification.context";
import { HIDE_CONFIRM, SHOW_CONFIRM } from "./store/reducer";

let resolveCallback;
function useNotify() {
  const [confirmState, dispatch] = useContext(ConfirmContext);
  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };
  const confirm = (className, msg, show) => {
    dispatch({
      type: SHOW_CONFIRM,
      payload: {
        show,
        msg,
        className,
      },
    });
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  const closeConfirm = () => {
    dispatch({
      type: HIDE_CONFIRM,
    });
  };

  return { confirm, onConfirm, onCancel, confirmState };
}

export default useNotify;
