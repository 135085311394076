import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import { TextInput } from "../../_components/TextInput/TextInput";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { wallOpeningModel } from "../../_redux/Models/wallOpening.model";
import { isInteger } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, wallOpeningStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const OpeningHeight = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 2;
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const wallOpeningState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.wallPayload
      ? state.buttonReducer.wallPayload
      : wallOpeningModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(
    calculateTotalCost(wallOpeningState.costFor)
  ); // To keep the total price in state

  const [value, setValue] = useState(""); // To keep the wall size in state
  const [heightPrice, setHeightPrice] = useState({ price: 0 }); // To keep the wall size in state

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails) {
      setHeightPrice(priceDetails.height);
    }
    return () => (mounted = false);
  }, [checkoutModelState]);

  const calculatePrice = () => {
    let size = wallOpeningState.size;
    let tPrice = checkoutModelState.totalCost;
    if (value > 8) {
      tPrice = tPrice + size * ((value - 8) * heightPrice.price);
    }
    setPrice(tPrice);
  };

  /**
   * Calls when submit button is clicked
   */
  const onSubmitVal = () => {
    wallOpeningState.height = value;
    wallOpeningState.costFor.height =
      price - calculateTotalCost(wallOpeningState.costFor);
    dispatch(wallOpeningStore(wallOpeningState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/wall_opening/type/3`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("wallOpening.whatIsOpeningHeight")}
            </label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("wallOpening.pleaseIntroduceHeight")}
            </label>
          </div>
          <div className="paintinput_lable mb-4">
            <TextInput
              className="paint_input_border"
              error={isInteger(value)}
              type="number"
              onChange={(e) => {
                if (e.target.value < 13) {
                  const val = e.target.value;
                  setValue(val);
                } else {
                  alert("Maximum 12 feet");
                }
              }}
              onKeyUp={calculatePrice}
              value={value}
              placeholder={t("Please enter feet")}
              label={t("wallOpening.linearFeet")}
            />
            <div className="paint_placeholder_right">
              <label>{t("wallOpening.inft")}</label>
            </div>
          </div>
          <Button
            disabled={!value}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSubmitVal}
          />
        </div>
      </div>
    </div>
  );
};
