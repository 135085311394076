export const SHOW_CONFIRM = "SHOW_CONFIRM";
export const HIDE_CONFIRM = "HIDE_CONFIRM";

export const initialState = {
  show: false,
  className: "",
  msg: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        show: true,
        className: action.payload.className,
        msg: action.payload.msg,
      };
    case HIDE_CONFIRM:
      return initialState;
    default:
      return initialState;
  }
};
