import { HavePaint } from "./HavePaint";
import { Decksize } from "./deck/deckSize";
import { DeckPersonalInfo } from "./deck/personalInfo";
import { Summarydeck } from "./deck/summary";
import { Typeoffinish } from "./deck/typeofFinsish";
import { ExteriorAddons } from "./exterior/addOns";
import { Housematerial } from "./exterior/houseMaterial";
import { Housesizeing } from "./exterior/houseSize";
import { ExteriorPersonalInfo } from "./exterior/personalInfo";
import { PickColorRoomExterior } from "./exterior/piccolorRoom";
import { SummaryExterior } from "./exterior/summary";
import { Fencesize } from "./fence/fenceSize";
import { FencePersonalInfo } from "./fence/personalInfo";
import { Summaryfence } from "./fence/summary";
import { Typeoffinishfence } from "./fence/typeofFinish";
import { InteriorAddons } from "./interior/addOn";
import { InteriorOrderSummary } from "./interior/orderSummary";
import { InteriorPersonalInfo } from "./interior/personalInfo";
import { PickHouseColorInterior } from "./interior/pickcolorHouse";
import { PickRoomColorInterior } from "./interior/pickcolorRoom";
import { InteriorRoomSize } from "./interior/roomSize";
import { InteriorPaintingRoomsAddOns } from "./interior/roomsAddOns";
import { InteriorTypeOfWork } from "./interior/typeofwork";
import { Personalinfo } from "./personalInfo";

export const paintingRoutes = [
  {
    path: "/contractor-portfolio/:id/painting/have-paint/:nextRoute",
    exact: true,
    component: HavePaint,
  },
  {
    path: "/contractor-portfolio/:id/painting/interior/type-of-work/:stepValue",
    exact: true,
    component: InteriorTypeOfWork,
  },
  {
    path: "/contractor-portfolio/:id/painting/interior/:roomId/room-size/:stepValue",
    exact: true,
    component: InteriorRoomSize,
  },
  {
    path: "/contractor-portfolio/:id/painting/interior/pick-color-house/:stepValue",
    exact: true,
    component: PickHouseColorInterior,
  },
  {
    path: "/contractor-portfolio/:id/painting/interior/add-ons/:stepValue",
    exact: true,
    component: InteriorAddons,
  },
  {
    path: "/contractor-portfolio/:id/painting/interior/:roomId/paint-details/:stepValue",
    exact: true,
    component: PickRoomColorInterior,
  },
  {
    path: "/contractor-portfolio/:id/painting/interior/:roomId/add-ons/:stepValue",
    exact: true,
    component: InteriorPaintingRoomsAddOns,
  },
  {
    path: "/contractor-portfolio/:id/painting/interior/personal-info/:stepValue",
    exact: true,
    component: InteriorPersonalInfo,
  },
  {
    path: "/contractor-portfolio/:id/painting/interior/summary/:stepValue",
    exact: true,
    component: InteriorOrderSummary,
  },
  {
    path: "/contractor-portfolio/:id/painting/exterior/house-material/:stepValue",
    exact: true,
    component: Housematerial,
  },
  {
    path: "/contractor-portfolio/:id/painting/exterior/house-sizing/:stepValue",
    exact: true,
    component: Housesizeing,
  },
  {
    path: "/contractor-portfolio/:id/painting/exterior/pick-color/:stepValue",
    exact: true,
    component: PickColorRoomExterior,
  },
  {
    path: "/contractor-portfolio/:id/painting/exterior/add-ons/:stepValue",
    exact: true,
    component: ExteriorAddons,
  },
  {
    path: "/contractor-portfolio/:id/painting/exterior/personal-info/:stepValue",
    exact: true,
    component: ExteriorPersonalInfo,
  },
  {
    path: "/contractor-portfolio/:id/painting/exterior/summary/:stepValue",
    exact: true,
    component: SummaryExterior,
  },
  {
    path: "/contractor-portfolio/:id/painting/deck/deck-size/:stepValue",
    exact: true,
    component: Decksize,
  },
  {
    path: "/contractor-portfolio/:id/painting/deck/type-of-finish",
    exact: true,
    component: Typeoffinish,
  },
  {
    path: "/contractor-portfolio/:id/painting/deck/personal-info/:stepValue",
    exact: true,
    component: DeckPersonalInfo,
  },
  {
    path: "/contractor-portfolio/:id/painting/deck/summary/:stepValue",
    exact: true,
    component: Summarydeck,
  },
  {
    path: "/contractor-portfolio/:id/painting/fence/fence-size/:stepValue",
    exact: true,
    component: Fencesize,
  },
  {
    path: "/contractor-portfolio/:id/painting/fence/type/:stepValue",
    exact: true,
    component: Typeoffinishfence,
  },
  {
    path: "/contractor-portfolio/:id/painting/fence/personal-info/:stepValue",
    exact: true,
    component: FencePersonalInfo,
  },
  {
    path: "/contractor-portfolio/:id/painting/fence/summary/:stepValue",
    exact: true,
    component: Summaryfence,
  },
  {
    path: "/contractor-portfolio/:id/painting/personal-info",
    component: Personalinfo,
  },
];
