import React from "react";
import { Footer } from "../../../_components/footer/Footer";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import moment from "moment";
import Modal from "@mui/material/Modal";

import IMAGES from "../../../_assets/images/index";
import { withTranslation } from "react-i18next";
import CheckBox from "../../../_components/CheckBox/checkBox";

import { connect } from "react-redux";
import { Button } from "../../../_components/Button/Button";
import axios from "axios";
import { API_URL } from "../../../services/apiConstants";
import { OrderSummaryForAllFlow } from "../../../_components/OrderSummaries/OrderSummaryForAllFlow";
import {
  formatOwnerFullAddress,
  formatPrice,
  parseJwt,
  viewMoreAddress,
} from "../../../services/utils/utilFunctions";
import {
  confirmProject,
  getContractor,
  rejectProject,
  submitTentativeDate,
} from "../../../services/api/contractor.service";
import {
  cardStatus,
  getprojectheader,
  updateNextProject,
} from "./contractorUtils";
import { createChannel } from "../../../services/api/chat.service";

class ContractorViewProject extends React.Component {
  constructor() {
    super();
    this.state = {
      viewAddress: false,
      propertyImages: "",
      readTerms: false,
      clientsComments: "",
      disableChat: false,
      index: -1,
      projectStatus: "",
    };
  }
  componentDidMount() {
    if (
      this.props.contractor &&
      Object.keys(this.props.contractor.projectDetails).length <= 0
    )
      this.props.history.push("/contractor-projects");
    else {
      this.setState({
        projectStatus: sessionStorage.getItem("presentStatus")
          ? sessionStorage.getItem("presentStatus")
          : this.props.contractor.projectDetails.status,
      });
      console.log(this.props.contractor.projectDetails);
    }
  }

  submitHandler = (contractor, status) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const contractorId = contractor.projectDetails.contractorId
      ? contractor.projectDetails.contractorId
      : user._id;
    let endPoint = "";
    if (status === "pending") endPoint = "confirm";
    else if (status === "check_in") endPoint = "complete";
    else if (status === "confirm") endPoint = "check-in";
    const tentativeReqBody = {
      tentativeStartDate: contractor.tentativeDetails.startDate,
      tentativeTimeRange: contractor.tentativeDetails.time.time,
      tentativeEndDate: contractor.tentativeDetails.endDate,
      serviceDetails: contractor.projectDetails.serviceDetails,
      price: contractor.projectDetails.price,
    };
    submitTentativeDate(
      contractorId,
      contractor.projectDetails._id,
      tentativeReqBody,
      status
    ).then(() =>
      confirmProject(contractorId, contractor.projectDetails._id, endPoint)
        .then((response) => {
          if (response.data.message)
            this.setState({
              projectStatus: updateNextProject(status),
              readTerms: false,
            });
        })
        .catch((e) => {
          alert("Error while uploading");
        })
    );
  };

  rejectProject = (contractor) => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    rejectProject(
      contractor.projectDetails.contractorId || user._id,
      contractor.projectDetails._id
    )
      .then((res) => {
        if (res.data) this.props.history.push("/contractor-projects");
      })
      .catch(() => {
        alert("Error while rejecting project");
      });
  };

  onChatClick = (contractor) => {
    this.setState({ disableChat: true });

    const user = JSON.parse(localStorage.getItem("user"));

    const data = {
      contractorID: contractor.projectDetails?.contractorId,
      ownerID: contractor.projectDetails.ownerId,
    };
    if (contractor.projectDetails.ownerId === user._id)
      data["userType"] = "owner";
    if (contractor.projectDetails?.contractorId === user._id)
      data["userType"] = "contractor";

    createChannel(data).then((response) => {
      if (response.status !== 200) {
        console.log("Something went wrong!");
        return;
      }
      this.setState({ disableChat: false });
      this.props.history.push({
        pathname: "/user/chat",
        search: `?sid=${response.data}`,
      });
    });
  };

  addAssistant = () => {
    this.props.history.push("/assistant-project", {
      contractorId: this.props.contractor.projectDetails.contractorId,
      projectId: this.props.contractor.projectDetails._id,
    });
  };

  tentativeDates = (tentativeDetails) => {
    let date = "";
    if (tentativeDetails.startDate)
      date = moment(tentativeDetails.startDate).format("dddd, MMMM DD");
    if (tentativeDetails.endDate && date)
      date =
        date + "-" + moment(tentativeDetails.endDate).format("dddd, MMMM DD");
    return date;
  };

  /**
   * Handles the index
   * @param {number} index
   */
  handleIndex = (index) => {
    this.setState({ index });
  };

  render = () => {
    const { t, contractor } = this.props;
    console.log(contractor);
    const name = contractor?.projectDetails?.serviceDetails?.address?.fullName;
    return (
      <div className="insideWrp">
        <HeaderMenu />
        {contractor && Object.keys(contractor.projectDetails).length > 0 && (
          <>
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <div className="contractor-project">
                {getprojectheader(
                  contractor.projectDetails.serviceDetails.data.flowName,
                  contractor.projectDetails.serviceDetails.data.serviceType
                ) && (
                  <p
                    className="approvalTxt project-view-header"
                    style={{
                      color: `${
                        contractor.projectDetails.status === "confirm"
                          ? "#5B75FF"
                          : "#5FCA5D"
                      }`,
                    }}
                  >
                    {getprojectheader(
                      contractor.projectDetails.serviceDetails.data.flowName,
                      contractor.projectDetails.serviceDetails.data.serviceType
                    )}
                  </p>
                )}

                <div className="paint_flex_with_left HeadWithRate paddinngtop10">
                  <label className={`approvalTxt ${this.state.projectStatus}`}>
                    {cardStatus(this.state.projectStatus).subHeader}
                  </label>
                </div>

                <div className="otherspadding25"></div>
                <p className="project-label">Client name</p>
                <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                  <div>
                    <img
                      width={60}
                      style={{ width: "60px" }}
                      src={IMAGES.other.person}
                      alt=""
                    />
                  </div>
                  <div className="grid">
                    <label
                      className="divtxtNormal"
                      style={{ fontWeight: "bold" }}
                    >
                      {contractor?.projectDetails?.serviceDetails?.data
                        .personalInfo?.name ?? "-"}
                    </label>
                  </div>
                  <div
                    onClick={() => this.props.history.push()}
                    className="paint_arrowFloat"
                  >
                    {/* <div className="editviewBlue">View</div> */}
                  </div>
                </div>
                <div className="otherspadding25"></div>
                <p className="project-label">Client address</p>
                <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                  <div>
                    <img
                      style={{ width: "60px" }}
                      src={IMAGES.other.miniHouse}
                      alt=""
                    />
                  </div>
                  <div className="grid">
                    <label
                      className="divtxtNormal"
                      style={{ fontWeight: "bold" }}
                    >
                      {contractor.projectDetails.ownerAddress
                        ? contractor.projectDetails.ownerAddress
                        : "address"}
                    </label>
                  </div>

                  <div className="paint_arrowFloat">
                    <div
                      onClick={() => {
                        this.setState({ viewAddress: !this.state.viewAddress });
                      }}
                      className="editviewBlue"
                    >
                      {this.state.viewAddress ? "Close" : "View"}
                    </div>
                  </div>
                </div>
                {this.state.viewAddress && (
                  <div className="paint_radio_div_first paintdiv_radiobtn_paddingright project-full-address">
                    <label
                      className="divtxtNormal"
                      style={{ fontWeight: "bold" }}
                    >
                      {contractor.projectDetails.serviceDetails.address
                        ? formatOwnerFullAddress(
                            contractor.projectDetails.serviceDetails.address
                          )
                        : ""}
                    </label>
                  </div>
                )}
                <div className="otherspadding25"></div>
                <p className="project-label">Client tentative date</p>
                <div className="">
                  <div
                    style={{
                      border: "0.5px solid #d8d8d8",
                      borderRadius: "8px",
                      placeItems: "center",
                    }}
                  >
                    <table className="paint_table100">
                      <tr className="checkoutCalsvg paintdiv_radiobtn_paddingright ">
                        <td>
                          <img src={IMAGES.other.calendarPay} alt="" />
                        </td>
                        <td className="grid">
                          {this.tentativeDates(contractor.tentativeDetails) && (
                            <div>
                              <label
                                className="divtxtNormal"
                                style={{ fontWeight: "bold" }}
                              >
                                {this.tentativeDates(
                                  contractor.tentativeDetails
                                )}
                              </label>
                              <br />
                              <label
                                style={{ margin: "0 10px" }}
                                className="dateTxt"
                              >
                                {contractor.tentativeDetails.time.text &&
                                  `${contractor.tentativeDetails.time.text}-`}
                                {contractor.tentativeDetails.time.time}
                              </label>
                            </div>
                          )}
                        </td>
                        <td
                          onClick={() => {
                            this.props.history.push(
                              `/contractor/${contractor.projectDetails?.contractorId}/project/${contractor.projectDetails?._id}/tentative-date`
                            );
                          }}
                          className="paint_arrowFloat"
                        >
                          <div className="editviewBlue">Edit</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div className="paint_flex_with_left paddinngtop20">
                <label className="paint_header ">Order details</label>
              </div>
              {this.props.contractor?.projectDetails?.serviceDetails?.data ? (
                <div className="contractor-project-details">
                  <OrderSummaryForAllFlow
                    project={this.props.contractor.projectDetails}
                  />
                </div>
              ) : null}

              <div className="otherspadding25"></div>
              <div className="allCenter">
                <div>
                  <label className="summaryDivheader1">
                    Client's property images
                  </label>
                  <div className="padding_top8">
                    <div className=" ">
                      <div style={{ display: "flex" }}>
                        {contractor.projectDetails.pictures.length > 0 ? (
                          contractor.projectDetails.pictures.map(
                            (imgSrc, index) => (
                              <div
                                className="fullPadding06"
                                onClick={() => this.handleIndex(index)}
                              >
                                <img
                                  className="profileDivmargin uploadwidthHeight"
                                  src={imgSrc}
                                  alt=""
                                />
                              </div>
                            )
                          )
                        ) : (
                          <div className="">
                            <span>No property Image</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="paintPadding8 ">
                <div className="paintinput_lable mb40  kitgrid">
                  <label className="summaryDivheader1">Client comments</label>
                  <div className="commandspadding"></div>
                  <textarea
                    disabled={true}
                    style={{ background: "#f8f8f8" }}
                    type="text"
                    value={
                      contractor.projectDetails.serviceDetails.data.comments
                    }
                    placeholder={"client comments"}
                    className="paintsummaryCommands message"
                    onChange={(e) =>
                      this.setState({ clientsComments: e.target.value })
                    }
                  />
                </div>
              </div>
              {contractor.projectDetails.contractorId ? (
                <div style={{ marginTop: "3px" }}>
                  <Button
                    disabled={false}
                    buttonLabel={t("+ Add an assistant")}
                    onClick={this.addAssistant}
                    className="btn-checkouttick"
                  />
                </div>
              ) : null}

              {contractor.projectDetails.assistants.map((assistant, index) => (
                <div className="assistantWrapper">
                  <div className="assistantTitle bold">
                    {`Assistant ${index + 1}`}
                  </div>
                  <div className="assistantDetails">
                    <div className="assistantName">{assistant.name}</div>
                    <div className="assistantDocument">
                      <img src={assistant.doc} alt="assistant document" />
                    </div>
                  </div>
                </div>
              ))}

              <div className="otherspadding25"></div>
              <div
                className="contactblue_btn"
                style={{
                  opacity: `${this.state.disableChat && "0.5"}`,
                }}
                onClick={() => this.onChatClick(contractor)}
              >
                <div>
                  <img src={IMAGES.other.group1} alt="" />
                </div>
                <div>
                  <table>
                    <tr>
                      <td>
                        <label
                          className="contactuslink"
                          style={{ textDecoration: "none" }}
                        >
                          {t("chats.chatWithClient")}
                        </label>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="paintPolygon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                >
                  <path
                    d="M13 6.76795C14.3333 7.53775 14.3333 9.46225 13 10.2321L3.25 15.8612C1.91667 16.631 0.25 15.6688 0.25 14.1292L0.25 2.87084C0.25 1.33123 1.91667 0.368984 3.25 1.13878L13 6.76795Z"
                    fill="#5b75ff"
                  />
                </svg>
              </div>
              <div className="otherspadding25"></div>
              <div className="otherspadding25"></div>
              <div className="otherspadding25"></div>
              <div className="otherspadding25"></div>
              {this.state.projectStatus !== "completed" && (
                <table className="flex foot_term_condition">
                  <tr>
                    <td className="chckbox">
                      <CheckBox
                        width={100}
                        value={this.state.readTerms}
                        checked={this.state.readTerms}
                        onClick={(e) => {
                          this.setState({
                            readTerms: !this.state.readTerms,
                          });
                        }}
                      />
                    </td>
                    <td className="paddingleft">
                      <div className="signup_sub terms_and_coditionpro terms_and_codition_color profilePadding20">
                        {t("profileCreation.ihave")}
                      </div>

                      <div className="signup_sub_head terms_and_coditionpro flex ">
                        <div
                          className="signup_termsColor"
                          onClick={() =>
                            this.props.history.push("/terms-and-condition")
                          }
                        >
                          {t("signIn.terms_con")}
                        </div>
                        <div className="terms_and_codition_gap">
                          {t("signIn.and")}{" "}
                        </div>
                        <div
                          className="signup_termsColor"
                          onClick={() =>
                            this.props.history.push("/terms-and-condition")
                          }
                        >
                          {t("signIn.privacy")}
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              )}

              <div className="profilePadding20"></div>

              <div className="otherspadding25"></div>
              <div className="otherspadding25"></div>

              {!["completed", "cancelled", "rejected"].includes(
                this.state.projectStatus
              ) ? (
                <>
                  <Button
                    disabled={!this.state.readTerms}
                    buttonLabel={
                      cardStatus(this.state.projectStatus).buttonText
                    }
                    onClick={() => {
                      this.submitHandler(contractor, this.state.projectStatus);
                    }}
                    className={`btn-primary ${
                      !this.state.readTerms &&
                      this.state.projectStatus !== "completed" &&
                      `disabled`
                    }`}
                  />
                  <div className="otherspadding25"></div>
                  <Button
                    disabled={false}
                    buttonLabel={t("I cannot do it")}
                    onClick={() => {
                      const promptMessage = prompt(
                        "Please enter a why you want to Cancel"
                      );
                      if (promptMessage) this.rejectProject(contractor);
                    }}
                    className="btn-checkouttick red-text"
                  />
                </>
              ) : (
                <Button
                  buttonLabel={
                    !isNaN(parseInt(contractor.projectDetails.clinteRating))
                      ? "Edit client rating"
                      : cardStatus(this.state.projectStatus).buttonText
                  }
                  onClick={() => {
                    this.props.history.push(
                      `/user/rate-project/${contractor.projectDetails.ownerId}/${contractor.projectDetails?.contractorId}?type=contractor`
                    );
                  }}
                  className={`btn-primary `}
                />
              )}
            </div>
            <Footer />
            <Modal
              keepMounted
              open={this.state.index > -1 ? true : false}
              onClose={() => this.handleIndex(-1)}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
              className={"modal-image"}
            >
              <div
                className="modalImageWrapper"
                onClick={() => this.handleIndex(-1)}
              >
                {this.state.index > -1 ? (
                  <img
                    src={contractor.projectDetails.pictures[this.state.index]}
                    alt="images"
                    onClick={(e) => e.stopPropagation()}
                  />
                ) : null}
              </div>
            </Modal>
          </>
        )}
      </div>
    );
  };
}
const mapStateToProps = (state) => {
  return {
    contractor: state.contractor,
  };
};

export default connect(mapStateToProps)(
  withTranslation()(ContractorViewProject)
);
