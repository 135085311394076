import React from "react";
import { useTranslation } from "react-i18next";

const TreeServicesWhatIsIncluded = () => {
  const { t } = useTranslation();
  return (
    <div>
      <label>
        <strong>{t("floor.whatsIncludedInPackage")}</strong>
      </label>
      <ul>
        <li>Customer support specialist</li>
        <li>Optional:Trash and debris removal from job site</li>
      </ul>
      <div className="profilePadding10"></div>
      <label>
        <strong>{t("floor.howLongTheProcessTakes")}</strong>
      </label>
      <ul>
        <li> Between 1 to 15 days*</li>
      </ul>
      <div className="profilePadding40"></div>
      <span>*Might differ according to complexity</span>
    </div>
  );
};

export default TreeServicesWhatIsIncluded;
