/**
 * @author Geethananth M
 * Model Object to hold the kitchen Categories
 */

/**
 * Model For kitchen
 */
export const kitchenModel = {
  flowName: "kitchen-flow",
  serviceType: "",
  size: "", // kitchen Size in SQFT
  demolitionRequired: "",
  needMaterial: "",
  typeOfCabinet: "",
  counterTop: "",
  shape: "",
  crownMolding: "",
  addOns: [],
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  costFor: {
    size: 0,
    demolition: 0,
    cabinet: 0,
    material: 0,
    counterTop: 0,
    crownMolding: 0,
    addOns: 0,
  },
};
