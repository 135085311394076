import React from "react";
import { useTranslation } from "react-i18next";

const BathroomWhatIsIncluded = () => {
  const { t } = useTranslation();

  return (
    <div>
      <label>
        <strong>{t("floor.whatsIncludedInPackage")}</strong>
      </label>
      <ul>
        <li>Customer support specialist </li>
        <li>Bathroom specialist interior designer</li>
        <li>Tiled shower (walls and floors)</li>
        <li>Cement backer board</li>
        <li>Plumbing for shower</li>
        <li>Drainage system replacement </li>
        <li>Built in custom shower niche</li>
        <li>Waterproofing system</li>
        <li>Cleanup work area and haul-away</li>
        <li>3 years comprehensive warranty </li>
        <li>Materials free delivery**</li>
      </ul>
      <div className="profilePadding10"></div>
      <label>
        <strong>{t("floor.howLongTheProcessTakes")}</strong>
      </label>
      <ul>
        <li> Between 4 days to 4 weeks*</li>
      </ul>
      <div className="profilePadding40"></div>
      <span>*Might differ according to size</span>
      <br />
      <span>**Only if acquired through Anyhnb</span>
    </div>
  );
};

export default BathroomWhatIsIncluded;
