import React, { useLayoutEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import "../../../_styles/globalStyles/global.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images";
import { Footer } from "../../../_components/footer/Footer";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { Button } from "../../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateCostFromArr,
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { paintingInteriorModel } from "../../../_redux/Models/painting.models";
import { paintingStore } from "../../../_redux/actions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const InteriorOrderSummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 7;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [orderSummary, setOrderSummary] = useState([]);
  const [comment, setComment] = useState("");

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const payload = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : paintingInteriorModel
  );
  const price =
    payload.typeOfWork === "fullHouse"
      ? calculateTotalCost(payload.costFor)
      : calculateCostFromArr(payload.roomDetails); // To keep the total price in state

  /**
   * On save Comment
   */
  const saveComment = () => {
    payload.comments = comment;
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(paintingStore(payload));
    const nextStepValue = parseInt(stepValue) + 1;
    window.location = `/contractor-portfolio/${selectedContractorId}/confirm`;
  };

  useLayoutEffect(() => {
    if (payload && payload !== undefined) {
      let summary = [];
      let sizeArr = [
        {
          key: "small_up_to_8ft",
          text: "Small room 7ft x 7ft, Up to 8ft high",
        },
        {
          key: "medium_up_to_8ft",
          text: "Medium room 12ft x 12ft, Up to 8ft high",
        },
        {
          key: "large_up_to_8ft",
          text: "Large room 16ft x 16ft, Up to 8ft high",
        },
        {
          key: "xlarge_up_to_8ft",
          text: "Larger room more than 16ft x 16ft, Up to 8ft high",
        },
        {
          key: "small_up_to_10ft",
          text: "Small room 7ft x 7ft, Up to 10ft high",
        },
        {
          key: "medium_up_to_10ft",
          text: "Medium room 12ft x 12ft, Up to 10ft high",
        },
        {
          key: "large_up_to_10ft",
          text: "Large room 16ft x 16ft, Up to 10ft high",
        },
        {
          key: "xlarge_up_to_10ft",
          text: "Larger room more than 16ft x 16ft, Up to 10ft high",
        },
        {
          key: "small_up_to_20ft",
          text: "Small room 7ft x 7ft, Up to 20ft high",
        },
        {
          key: "medium_up_to_20ft",
          text: "Medium room 12ft x 12ft, Up to 20ft high",
        },
        {
          key: "large_up_to_20ft",
          text: "Large room upto 16ft x 16ft, Up to 20ft high",
        },
        {
          key: "xlarge_up_to_20ft",
          text: "Larger room more than 16ft x 16ft, Up to 20ft high",
        },
      ];
      if (payload.typeOfWork === "fullHouse") {
        summary = [
          [
            {
              heading: "Type of service",
              text: "Paint a full house",
              img: IMAGES.other.green_plus,
              price: 0,
            },
            {
              heading: "House size",
              text: `${payload.size}`,
              img: IMAGES.other.green_plus,
              price: payload.costFor.size,
            },
            {
              heading: "Requires the paint?",
              text: `${payload.haveOwnPaint ? "No" : "Yes"}`,
              img: IMAGES.other.green_plus,
              price: 0,
            },
            {
              heading: "Paint brand and color",
              text: (
                <div>
                  {`${payload.paintDetails.brandName.brand || "N/A"}`}
                  <br></br>
                  {`${payload.paintDetails.colorName || ""}`}
                  <br />
                  {`${payload.paintDetails.paintCode}`}
                </div>
              ),
              img: IMAGES.other.green_plus,
              price: 0,
            },
            {
              heading: "Primer coat required?",
              text: `${payload.needPrimerCoat ? "Yes" : "No"}`,
              img: IMAGES.other.green_plus,
              price: payload.costFor.primer,
            },
            {
              heading: "Type of paint",
              text: `${
                payload.paintType.text !== undefined
                  ? payload.paintType.text
                  : ""
              }`,
              img: IMAGES.other.green_plus,
              price: payload.costFor.paint,
            },
            {
              heading: "Number of coats",
              text: `${payload.coats}`,
              img: IMAGES.other.green_plus,
              price: payload.costFor.coat,
            },
            {
              heading: "Add on´s",
              text: payload.addOns.map((addOn) => {
                const add = addOn.text;
                if (add !== "") {
                  return (
                    <span>
                      {`${add} ${addOn.count > 1 ? `(${addOn.count})` : ""}`}
                      <br />
                    </span>
                  );
                } else {
                  return null;
                }
              }),
              img: IMAGES.other.green_plus,
              price: payload.costFor.addOns,
            },
          ],
        ];
      } else if (payload.typeOfWork === "rooms") {
        const rooms = payload.roomDetails;
        summary = rooms.map((room) => {
          const roomSize = sizeArr.find(
            (size) => size.key === room.roomSize + "_up_to_" + room.roomHeight
          );
          return [
            {
              heading: "Room size",
              text: `${roomSize ? roomSize.text : ""}`,
              img: IMAGES.other.green_plus,
              price: 0,
            },
            {
              heading: "Requires the paint",
              text: `${payload.haveOwnPaint ? "No" : "Yes"}`,
              img: IMAGES.other.green_plus,
              price: 0,
            },
            {
              heading: "Paint brand and color",
              text: (
                <div>
                  {`${room.paintDetails.brandName.brand || "N/A"}`}
                  <br></br>
                  {`${room.paintDetails.colorName}`}
                  <br />
                  {`${room.paintDetails.paintCode}`}
                </div>
              ),
              img: IMAGES.other.green_plus,
              price: 0,
            },
            {
              heading: "Primer coat required?",
              text: `${room.needPrimerCoat ? "Yes" : "No"}`,
              img: IMAGES.other.green_plus,
              price: room.costFor.primer,
            },
            {
              heading: "Type of paint",
              text: `${
                room.paintType.text !== undefined ? room.paintType.text : ""
              }`,
              img: IMAGES.other.green_plus,
              price: room.costFor.paint,
            },
            {
              heading: "Number of coats",
              text: `${room.coats}`,
              img: IMAGES.other.green_plus,
              price: room.costFor.coat,
            },
            {
              heading: "Add on´s",
              text: room.addOns.map((addOn, i) => {
                const add = addOn.text;
                if (add !== "") {
                  return (
                    <span key={i}>
                      {`${add} ${addOn.count > 1 ? `(${addOn.count})` : ""}`}
                      <br />
                    </span>
                  );
                } else {
                  return null;
                }
              }),
              img: IMAGES.other.green_plus,
              price: room.costFor.addOns,
            },
          ];
        });
      }

      setOrderSummary(summary);
    }
  }, [payload, t]);

  return (
    <div className="service insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="sevice_title">{t("orderSummary.mainHeading")} </div>
        <label className="sub_heading" style={{ marginTop: "7px" }}>
          Interior painting estimation
        </label>
        <br />
        <div className="paintpaddingtop25"></div>
        {orderSummary.map((summary, index) => (
          <div>
            {orderSummary.length > 1 ? (
              <div className="padding10">
                <label className="paint_header">{`Room #${index + 1}`}</label>
                <label className="label-sub-price-text">{`Sub total: $${formatPrice(
                  calculateTotalCost(payload.roomDetails[index].costFor)
                )}`}</label>
              </div>
            ) : null}
            {summary.map((item, index) =>
              item.text.toString().length > 0 ? (
                <label
                  className="floor-selection-radio"
                  style={{
                    flexDirection: "column",
                    padding: "10px",
                    alignItems: "start",
                  }}
                >
                  <div className="bold">{item.heading}</div>
                  <div
                    className="flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: " 90%",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        textTransform: "initial",
                        color: "#858585",
                        fontSize: "13px",
                      }}
                    >
                      {item.text}
                    </div>

                    <div style={{ display: "flex", placeItems: "center" }}>
                      <div style={{ paddingTop: "3px" }}>
                        <img
                          width="15"
                          height="15"
                          src={IMAGES.other.green_plus}
                          alt=""
                        />
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {item.price < 0
                          ? `${formatPrice(item.price).replace("-", "-$")}`
                          : `$${formatPrice(item.price)}`}
                      </div>
                    </div>
                  </div>
                </label>
              ) : null
            )}
          </div>
        ))}
        <table className="mb-3">
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("paintingInterior.ordercommands")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message"
              />
            </td>
          </tr>
        </table>
        <Button
          disabled={false}
          buttonLabel={t("orderSummary.continue")}
          onClick={saveComment}
        />
        <div className="contactblue_btn mb-5 mt-4">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td onClick={() => (window.location = "/contact-us")}>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon mb-3">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>
    </div>
  );
};
