import Axios from "../../services/api/config/api.config";
import {
  manuelRegister,
  userVerification,
} from "../../services/api/signup.service";
import { buttonConstant, signupConstants } from "../constants";

function continuetoNext(state) {
  return {
    type: signupConstants.SIGNUP_NEXT_CLICK,
    payload: state,
  };
}

const RegisterAuthAction = (userState, history, confirm) => {
  return (dispatch) => {
    dispatch(request());
    manuelRegister(userState).then(
      (data) => {
        dispatch(success(data));
        dispatch(buttonStatus(true));
      },
      (error) => {
        dispatch(buttonStatus(false));
        dispatch(failure(error));
      }
    );
  };

  function buttonStatus(action) {
    return { type: buttonConstant.BUTTON_ENABLE, payload: action };
  }
  function request() {
    return { type: signupConstants.SIGNUP_REQUEST };
  }
  function success(payload) {
    confirm("success", "OTP has been sent to Registered Email", true);
    history.push("user/validation");
    return { type: signupConstants.SIGNUP_SUCCESS, payload };
  }
  function failure(error) {
    confirm("error", "Something went wrong", true);
    return { type: signupConstants.SIGNUP_FAILURE, error };
  }
};

const resendOTP = (userState, history, confirm) => {
  return (dispatch) => {
    dispatch(request());
    manuelRegister(userState).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: signupConstants.SIGNUP_VERIFY_REQUEST };
  }
  function success(payload) {
    return { type: signupConstants.SIGNUP_VERIFY_SUCCESS, payload };
  }
  function failure(error) {
    confirm("error", "OTP Expired", true);
    return { type: signupConstants.SIGNUP_VERIFY_FAILURE, error };
  }
};

export { RegisterAuthAction, continuetoNext, resendOTP };
