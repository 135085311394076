import React from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/home.css";
import "../../../_styles/localStyles/paint.css";
import "../../../_styles/localStyles/floor.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { t } from "i18next";
export const Typeoffinish = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 0;
  const typeoffinsihClick = () => {
    history.push("/painting/deck/add-ons");
  };
  return (
    <div className="insideWrp">
      <HeaderMenu />
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <label className="header_path_name_show">
          {t("paintingdeck.header_path")}
        </label>

        <div className="paint_main_buttons">
          <div className="paint_main_button_left">
            {/* <img src={IMAGES.home.stepnumbertwo} alt="" /> */}
            <ProgressBar progressValue={stepValue} />
            <div>
              <label className="paint_every_flow_title">
                {" "}
                {t("paintingdeck.typeoffinish")}
              </label>
            </div>
          </div>
          <div className="paint_main_button_right">
            <table>
              <tr>
                <td>
                  <img src={IMAGES.other.piggy} alt="" />
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <label className="paint_every_price_txt">
                    {t("typeOfService.price")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="paint_flex_with_left padding30">
          <label className="paint_header">{t("paintingdeck.whattype")}</label>
        </div>

        <div className="">
          <div>
            <table className="paint_table100">
              <tr className="paint_radio_div_first exteriorDivpadding ">
                <td>
                  {" "}
                  <label className="paintdiv_txt">
                    {t("paintingdeck.paint")}
                  </label>
                </td>
                <td className="paint_arrowFloat">
                  {" "}
                  <RadioButton />
                </td>
              </tr>
              <div className="paintPadding8">
                <tr className="paint_radio_div_first exteriorDivpadding  ">
                  <td className="">
                    {" "}
                    <label className="paintdiv_txt">
                      {t("paintingdeck.stain")}
                    </label>
                  </td>
                  <td className="paint_arrowFloat">
                    {" "}
                    <RadioButton />
                  </td>
                </tr>
              </div>
            </table>
          </div>
        </div>

        <label
          onClick={() => typeoffinsihClick()}
          className="paint_home_form_btn cursor_hand"
        >
          {t("paintingHomes.continue")}
        </label>
      </div>
      <Footer />
    </div>
  );
};
