import React, { useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

import "./Carousel.css";
import IMAGES from "../../../_assets/images";
import CarouselDots from "./CarouselDots";

export const CarouselItem = ({ children, width }) => {
  return (
    <div className="custom-carousel-item" style={{ width: width }}>
      {children}
    </div>
  );
};

const Carousel = ({
  children,
  currentActive,
  setCurrentActive,
  hideArrows,
  hasBorderRadius = true,
  showCount = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(currentActive || 0);
  const dotsRef = useRef(null);

  const handleSwipe = useSwipeable({
    onSwipedLeft: () => {
      if (activeIndex < React.Children.count(children) - 1) {
        setActiveIndex((prev) => prev + 1);
        if (setCurrentActive) setCurrentActive((prev) => prev + 1);
      }
    },
    onSwipedRight: () => {
      if (activeIndex > 0) {
        setActiveIndex((prev) => prev - 1);
        if (setCurrentActive) setCurrentActive((prev) => prev - 1);
      }
    },
  });

  const handleNextClick = (e) => {
    e.preventDefault();
    if (activeIndex > 1 && activeIndex < React.Children.count(children) - 2) {
      // dotsRef.current.scrollLeft += 20;
    }
    if (activeIndex < React.Children.count(children) - 1) {
      setActiveIndex((prev) => prev + 1);
      if (setCurrentActive) setCurrentActive((prev) => prev + 1);
    }
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    if (activeIndex > 0) {
      setActiveIndex((prev) => prev - 1);
      if (setCurrentActive) setCurrentActive((prev) => prev - 1);
    }
    if (activeIndex < React.Children.count(children) - 2 && activeIndex > 1) {
      // dotsRef.current.scrollLeft -= 20;
    }
  };

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
    if (setCurrentActive) setCurrentActive(newIndex);
  };

  const numDotsToShow = Math.min(4, React.Children.count(children));
  const startingIndex = Math.floor(activeIndex / numDotsToShow) * numDotsToShow;

  return (
    <div
      className="carousel"
      style={{ borderRadius: hasBorderRadius ? "30px" : "0" }}
    >
      {showCount ? (
        <div className="carousel-count">
          <span>{`${activeIndex + 1} / ${React.Children.count(
            children
          )}`}</span>
        </div>
      ) : (
        ""
      )}
      <div
        className="inner"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        {...handleSwipe}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div
        className="indicators"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {!hideArrows ? (
          <button
            className={`${
              activeIndex <= 0 ? "hide" : ""
            } custom-carousel-controls prev`}
            onClick={(e) => e.preventDefault()}
          >
            <img onClick={handlePrevClick} src={IMAGES.home.scrollLeft} />
          </button>
        ) : (
          ""
        )}
        {React.Children.count(children) >= 5 ? (
          <CarouselDots
            total={React.Children.count(children)}
            current={activeIndex}
            visible={5}
            size={8}
            onClick={(newIndex) => updateIndex(newIndex)}
          />
        ) : React.Children.count(children) > 1 ? (
          <div className="dots" ref={dotsRef}>
            {React.Children.map(children, (child, index) => {
              if (
                index < startingIndex ||
                index >= startingIndex + numDotsToShow
              ) {
                return null;
              }
              return (
                <button
                  className={`${index === activeIndex ? "active" : ""}`}
                  onClick={() => {
                    updateIndex(index);
                  }}
                ></button>
              );
            })}
          </div>
        ) : (
          ""
        )}

        {!hideArrows ? (
          <button
            onClick={(e) => handleNextClick(e)}
            className={`${
              activeIndex >= React.Children.count(children) - 1 ? "hide" : ""
            } custom-carousel-controls next`}
          >
            <img src={IMAGES.home.scrollRight} />
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Carousel;
