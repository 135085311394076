import IMAGES from '../../_assets/images/index'

const items = [
  {
    urls: IMAGES.home.floorSliderFirst,
  },
  {
    urls: IMAGES.home.floorSliderSecond,
  },
  {
    urls: IMAGES.home.floorSlider1,
  },
  {
    urls: IMAGES.home.floorSlider2,
  },
  {
    urls: IMAGES.home.floorSlider3,
  },
  {
    urls: IMAGES.home.floorSlider4,
  },
  {
    urls: IMAGES.home.floorSlider5,
  },
  {
    urls: IMAGES.home.floorSlider6,
  },
  {
    urls: IMAGES.home.floorSlider7,
  },
  {
    urls: IMAGES.home.floorSlider8,
  },
  {
    urls: IMAGES.home.floorSlider9,
  },
  {
    urls: IMAGES.home.floorSlider10,
  },
];
export default items