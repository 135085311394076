import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { treeModel } from "../../_redux/Models/tree.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { checkoutStore, treeStore } from "../../_redux/actions";

export const RootsRemoval = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const stepValue = 5;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.treePayload
      ? state.buttonReducer.treePayload
      : treeModel
  );

  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state

  const [stumpGrindingNeeded, setStumpGrindingNeeded] = useState("");
  const [debrisHaulNeeded, setDebrisHaulNeeded] = useState("");
  const [stumpGrindingPrice, setStumpGrindingPrice] = useState(0);
  const [debrisHaulPrice, setDebrisHaulPrice] = useState(0);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails && priceDetails.stumpGrinding) {
      const stumpGrindingPrices = priceDetails.stumpGrinding;
      const debrisHaulAwayPrices = priceDetails.debrisHaulAway;
      const treeDetails = state.treeDetails;

      let stumpGrindingPrice = 0;
      let debrisHaulAwayPrice = 0;
      for (let i = 0; i < treeDetails.length; i++) {
        const treeHeight = treeDetails[i].value;
        const treeCount = treeDetails[i].count;
        const sumpPriceForSelectedHeight = stumpGrindingPrices.find(
          (op) => op.key === treeHeight
        ).price;
        const debrisPriceForSelectedHeight = debrisHaulAwayPrices.find(
          (op) => op.key === treeHeight
        ).price;
        stumpGrindingPrice =
          stumpGrindingPrice + sumpPriceForSelectedHeight * treeCount;
        debrisHaulAwayPrice =
          debrisHaulAwayPrice + debrisPriceForSelectedHeight * treeCount;
      }
      setStumpGrindingPrice(stumpGrindingPrice);
      setDebrisHaulPrice(debrisHaulAwayPrice);
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state, t]);

  const onSave = () => {
    const total = calculateTotalCost(state.costFor);
    state.needStumpGrinding = stumpGrindingNeeded;
    state.needDebrisHaul = debrisHaulNeeded;
    const stumpGrindingCost =
      stumpGrindingNeeded === "yes" ? stumpGrindingPrice : 0;
    const debrisHaulCost = debrisHaulNeeded === "yes" ? debrisHaulPrice : 0;
    state.costFor.stumpGrinding = stumpGrindingCost;
    state.costFor.debrisHaul = debrisHaulCost;
    checkoutModelState.totalCost = total + stumpGrindingCost + debrisHaulCost;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(treeStore(state));
    props.history.push("/tree_services/machinery-details");
  };

  return (
    <div className="insideWrp">
      <HeaderMenu />

      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="service-banner-sec stable_class">
          <div class="banner-bg">
            <img src={IMAGES.home.treeServicesBanner} alt="" />
          </div>
          <div className="floor-title">
            <label
              className="header_path_name_show"
              style={{ textTransform: "capitalize" }}
            >
              {state.serviceType === "tree_remotion"
                ? "Tree removal"
                : `${state.serviceType.replace(/_/g, " ")}`}
            </label>
          </div>
          <div class="banner-title">
            <label>{t("treeService.headerLink")}</label>
          </div>
        </div>
        {/* <label
          className="header_path_name_show"
          style={{ textTransform: "capitalize" }}
        >
          {state.serviceType === "tree_remotion"
            ? "Tree removal"
            : `${state.serviceType.replace(/_/g, " ")}`}
        </label> */}

        <div className="paint_main_buttons">
          <div className="paint_main_button_left">
            <ProgressBar progressValue={stepValue} />
            {/* <img src={IMAGES.other.stepper1} alt="" /> */}
            <div>
              <label className="paint_every_flow_title">
                {t("treeService.rootsRemoval")}
              </label>
            </div>
          </div>
          <div className="paint_main_button_right">
            <table>
              <tr>
                <td>
                  <img src={IMAGES.other.piggy} alt="" />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <label className="paint_every_price_txt">
                    {price > 0 ? `$${formatPrice(price)}` : "--"}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div className="padding10"></div>
          <div className="paint_flex_with_left paddinngtop20">
            <label className="paint_header" style={{ fontSize: "19px" }}>
              {t("treeService.wantstumpGrinding")}
            </label>
          </div>
          <div className="padding10"></div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("treeService.wantstumpGrindingTxt")}
            </label>
          </div>
          <div className="padding10"></div>
          <div className="padding10"></div>
          <div
            onClick={() => {
              const total = calculateTotalCost(state.costFor);
              const debrisHaulPriceToAdd =
                debrisHaulNeeded === "yes" ? debrisHaulPrice : 0;
              setPrice(total + stumpGrindingPrice + debrisHaulPriceToAdd);
              setStumpGrindingNeeded("yes");
            }}
            className="paintPadding8 "
          >
            <div className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright ">
              <div
                // key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">Yes</label>
                <div
                  className="paint_arrowFloat flex"
                  style={{ paddingTop: "10px" }}
                >
                  <img
                    src={IMAGES.other.green_plus}
                    className="kitchenplusimg"
                    alt=""
                  />
                  <label
                    className="paintdiv_txt paddingleft8"
                    style={{ paddingLeft: "7px", paddingTop: "2px" }}
                  >
                    {`$${formatPrice(stumpGrindingPrice)}`}
                  </label>
                  <div
                    className="paint_arrowFloat"
                    style={{ paddingLeft: "7px" }}
                  >
                    <RadioButton
                      checked={stumpGrindingNeeded === "yes"}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="padding10"></div>

          <div
            onClick={() => {
              const total = calculateTotalCost(state.costFor);
              const debrisHaulPriceToAdd =
                debrisHaulNeeded === "yes" ? debrisHaulPrice : 0;
              setPrice(total + debrisHaulPriceToAdd);
              setStumpGrindingNeeded("no");
            }}
            className="paintPadding8 "
          >
            <div className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright ">
              <div
                // key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">No</label>
                <div
                  className="paint_arrowFloat flex"
                  style={{ paddingTop: "10px" }}
                >
                  <img
                    src={IMAGES.other.green_plus}
                    className="kitchenplusimg"
                    alt=""
                  />
                  <label
                    className="paintdiv_txt paddingleft8"
                    style={{ paddingLeft: "7px", paddingTop: "2px" }}
                  >
                    {`$0.00`}
                  </label>
                  <div
                    className="paint_arrowFloat"
                    style={{ paddingLeft: "7px" }}
                  >
                    <RadioButton
                      checked={stumpGrindingNeeded === "no"}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="padding10"></div>
          <div className="paint_flex_with_left paddinngtop20">
            <label className="paint_header" style={{ fontSize: "19px" }}>
              {t("treeService.debrisHaulAway")}
            </label>
          </div>
          <div className="padding10"></div>

          <div className="padding10"></div>

          <div
            onClick={() => {
              const total = calculateTotalCost(state.costFor);
              const stumpGrindingPriceToAdd =
                stumpGrindingNeeded === "yes" ? stumpGrindingPrice : 0;
              setPrice(total + debrisHaulPrice + stumpGrindingPriceToAdd);
              setDebrisHaulNeeded("yes");
            }}
            className="paintPadding8 "
          >
            <div className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright ">
              <div
                // key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">Yes</label>
                <div
                  className="paint_arrowFloat flex"
                  style={{ paddingTop: "10px" }}
                >
                  <img
                    src={IMAGES.other.green_plus}
                    className="kitchenplusimg"
                    alt=""
                  />
                  <label
                    className="paintdiv_txt paddingleft8"
                    style={{ paddingLeft: "7px", paddingTop: "2px" }}
                  >
                    {`$${formatPrice(debrisHaulPrice)}`}
                  </label>
                  <div
                    className="paint_arrowFloat"
                    style={{ paddingLeft: "7px" }}
                  >
                    <RadioButton
                      checked={debrisHaulNeeded === "yes"}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="padding10"></div>

          <div
            onClick={() => {
              const total = calculateTotalCost(state.costFor);
              const stumpGrindingPriceToAdd =
                stumpGrindingNeeded === "yes" ? stumpGrindingPrice : 0;
              setPrice(total + stumpGrindingPriceToAdd);
              setDebrisHaulNeeded("no");
            }}
            className="paintPadding8 "
          >
            <div className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright ">
              <div
                // key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">No</label>
                <div
                  className="paint_arrowFloat flex"
                  style={{ paddingTop: "10px" }}
                >
                  <img
                    src={IMAGES.other.green_plus}
                    className="kitchenplusimg"
                    alt=""
                  />
                  <label
                    className="paintdiv_txt paddingleft8"
                    style={{ paddingLeft: "7px", paddingTop: "2px" }}
                  >
                    {`$0.00`}
                  </label>
                  <div
                    className="paint_arrowFloat"
                    style={{ paddingLeft: "7px" }}
                  >
                    <RadioButton checked={debrisHaulNeeded === "no"} readOnly />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="profilePadding40"></div>
          <Button
            disabled={stumpGrindingNeeded === "" || debrisHaulNeeded === ""}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSave}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};
