import React, { useLayoutEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import "../../_styles/globalStyles/global.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, plumbingStore } from "../../_redux/actions";
import { basementModel } from "../../_redux/Models/basement.model";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { plumbingModel } from "../../_redux/Models/plumbing.model";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const PlumbingOrderSummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [orderSummary, setOrderSummary] = useState([]);

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const payload = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.plumbingPayload
      ? state.buttonReducer.plumbingPayload
      : plumbingModel
  );
  const price = calculateTotalCost(payload.costFor); // To keep the total price in state
  const [comment, setComment] = useState(payload.comments);
  /**
   * On save Comment
   */
  const saveComment = () => {
    payload.comments = comment;
    dispatch(plumbingStore(payload));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    window.location = `/contractor-portfolio/${selectedContractorId}/confirm`;
  };

  useLayoutEffect(() => {
    if (payload && payload !== undefined) {
      const summary = [
        {
          heading: "Type of service",
          text: `${payload.serviceType}`,
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading:
            payload.serviceType === "repair"
              ? "Needs replacement"
              : "What do you need to install?",
          text: payload.addOns.map((addOn, index) => {
            const add = addOn.text;
            if (add !== "") {
              return (
                <span key={index}>
                  {`${add.replaceAll("_", " ")} ${
                    addOn.selectedOption
                      ? `(${addOn.selectedOption.key.replaceAll("_", " ")})`
                      : ""
                  } (${addOn.count}) `}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
          img: IMAGES.other.green_plus,
          price: payload.costFor.addOns,
        },
      ];
      if (payload.serviceType === "emergency") {
        summary.push(
          {
            heading: "How urgent is it?",
            text: `${payload.howUrgent}`,
            img: IMAGES.other.green_plus,
            price: payload.costFor.service,
          },
          {
            heading: "Details/comments",
            text: `${payload.emergencyComments}`,
            img: IMAGES.other.green_plus,
            price: 0,
          }
        );
      }

      setOrderSummary(summary);
    }
  }, [payload, t]);

  return (
    <div className="service insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="sevice_title">{t("orderSummary.mainHeading")} </div>
        <label className="sub_heading" style={{ marginTop: "7px" }}>
          {payload.serviceType === "repairs"
            ? "Plumbing repair estimate"
            : payload.serviceType === "emergency"
            ? "Plumbing emergency estimate"
            : "Plumbing installation estimate"}
        </label>
        <br />
        <div className="paintpaddingtop25"></div>
        {orderSummary.map((item, index) =>
          item.text.length > 0 ? (
            <label
              className="floor-selection-radio"
              style={{
                height: "88px",
                flexDirection: "column",
                padding: "15px",
                alignItems: "start",
                marginBottom: "15px",
                overflow: "auto",
              }}
            >
              <div className="bold">{item.heading}</div>
              <div
                className="flex"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className="text_width"
                  style={{
                    textTransform: "initial",
                    width: "100%",
                    paddingRight: "25px",
                    textAlign: "left",
                  }}
                >
                  {item.text}
                </div>

                <div
                  style={{
                    display: "flex",
                    placeItems: "center",
                    width: "155px",
                  }}
                >
                  <div style={{ paddingTop: "3px" }}>
                    <img
                      width="15"
                      height="15"
                      src={IMAGES.other.green_plus}
                      alt=""
                    />
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    {`$${formatPrice(parseFloat(item.price))}`}
                  </div>
                </div>
              </div>
            </label>
          ) : null
        )}
        <table>
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("paintingInterior.ordercommands")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message plcehlder_align"
              />
            </td>
          </tr>
        </table>

        <div className="otherspadding25"></div>
        <Button
          disabled={false}
          buttonLabel={t("orderSummary.continue")}
          onClick={saveComment}
        />

        <div className="contactblue_btn mb-5 mt-3">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td onClick={() => (window.location = "/contact-us")}>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon mb-3">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>
    </div>
  );
};
