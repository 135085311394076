import React from "react";
import "../../_styles/localStyles/profileCreate.css";
import "../../_components/radioButton/radioButton.css";
import { Button } from "../../_components/Button/Button";
import { withTranslation } from "react-i18next";
import { checkoutStore } from "../../_redux/actions";
import { connect } from "react-redux";
import Threedmansvg from "../../_components/Tickandwrongsvg/threedMansvg";

class FormSubmitted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentToShow: "",
    };
  }

  componentDidMount = () => {
    const response =
      this.props.match && this.props.match.params
        ? this.props.match.params.response
        : "";
    this.setState({ componentToShow: response });
  };

  get orderReceivedComponent() {
    const { t } = this.props;
    return (
      <div className="profileConfirmationback">
        <div className="paint-home-section ">
          <div className="personal_flex_with_center">
            <table className="confirmationTitle">
              <tr className="allCenter">
                <td>
                  {/* <img src={IMAGES.home.profileGroupman} alt="" /> */}
                  <Threedmansvg
                    className="receivedmanOut"
                    value="receivedman"
                  />
                </td>
              </tr>
            </table>
            <div className="profilePadding10"></div>
            <table className="confirmationTitle1">
              <tr>
                <td>
                  <div className="confirmationTitle allCenterGrid">
                    <label>{t("Order received")}</label>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className="allCenterGrid">
                    <label>{t("We´ll notify you as soon as the")}</label>

                    <label>{t("contractor confirms your")}</label>
                    <label>{t("project")}</label>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div className="profilePadding30"></div>
          <Button
            disabled={false}
            buttonLabel={t("Go to My projects")}
            onClick={() => this.props.history.push("/user/my-projects")}
            className={"btn-white"}
          />
          <div className="profilePadding10"></div>
          <Button
            disabled={false}
            buttonLabel={t("Contact us")}
            onClick={() => (window.location = "/contact-us")}
            className={"btn-primary-border"}
          />
        </div>
      </div>
    );
  }

  /**
   * Returns the hangout a bit component
   */
  get hangABitComponent() {
    const { t } = this.props;
    return (
      <div className="profileConfirmationback">
        <div className="paint-home-section ">
          <div className="personal_flex_with_center">
            <table className="confirmationTitle">
              <tr className="allCenter">
                <td>
                  {/* <img src={IMAGES.home.profileGroupman} alt="" /> */}
                  <Threedmansvg className="hangonOut" value="hangon" />
                </td>
              </tr>
            </table>
            <div className="profilePadding10"></div>
            <table className="confirmationTitle1">
              <tr>
                <td>
                  <div className="confirmationTitle allCenterGrid">
                    <label>{t("Hang on")}</label>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className="allCenterGrid">
                    <label>{t("We´ll get in touch soon")}</label>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div className="profilePadding30"></div>
          <Button
            disabled={false}
            buttonLabel={t("Go to My projects")}
            onClick={() => this.props.history.push("/user/my-projects")}
            className={"btn-white"}
          />
          <div className="profilePadding10"></div>
          <Button
            disabled={false}
            buttonLabel={t("Contact us")}
            onClick={() => (window.location = "/contact-us")}
            className={"btn-primary-border"}
          />
          <div className="profilePadding30"></div>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.state.componentToShow === "success") {
      return this.orderReceivedComponent;
    } else {
      return this.hangABitComponent;
    }
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(FormSubmitted));
