import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { checkoutStore, treeStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import { treeModel } from "../../_redux/Models/tree.model";
import { TextInput } from "../../_components/TextInput/TextInput";
import { isInteger } from "lodash";
import { DropDown } from "../../_components/dropDown/dropDown";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const TreeStumpRemoval = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue = 2;

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.treePayload
      ? state.buttonReducer.treePayload
      : treeModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [eachCost, setEachCost] = useState(0);
  const [numberOfTrees, setNumberOfTrees] = useState(1);
  const [numberOfTreeDetails, setNumberOfTreeDetails] = useState([]);
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;
    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          console.log(response.data);
          checkoutModelState.priceDetails = response.data.details;
          setEachCost(response.data.details.typeOfService.price);
          dispatch(checkoutStore(checkoutModelState));
          const pricingOptions = [];
          for (let i = 0; i < 1; i++) {
            var option = {
              text: 0,
              count: 0,
            };
            pricingOptions.push(option);
          }

          setNumberOfTreeDetails(pricingOptions);
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, numberOfTrees, t]);

  function arrowHandler(params, e, index) {
    let updatedState = [...numberOfTreeDetails];
    if (params === "plus") {
      updatedState[index].count = updatedState[index].count + 1;
    } else if (updatedState[index].count > 1) {
      updatedState[index].count = updatedState[index].count - 1;
    }
    calculateTotal(updatedState);
    setNumberOfTreeDetails(updatedState);
  }

  const onChangeHandler = (count, index) => {
    let updatedState = [...numberOfTreeDetails];
    updatedState[index].count = count;
    calculateTotal(updatedState);
    setNumberOfTreeDetails(updatedState);
  };

  function inputHandler(e, index) {
    let updatedState = [...numberOfTreeDetails];
    updatedState[index].text = parseInt(e.target.value);
    calculateTotal(updatedState);
    setNumberOfTreeDetails(updatedState);
  }

  const calculateTotal = (addOns) => {
    const total = addOns.reduce((p, c) => p + eachCost * c.count * c.text, 0);
    setPrice(calculateTotalCost(state.costFor) + total);
  };

  const onSave = () => {
    state.numberOfTreesToStumpRemove = numberOfTrees;
    state.treeDimensionDetails = numberOfTreeDetails;
    state.costFor.stumpRemoval = price - calculateTotalCost(state.costFor);
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(treeStore(state));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/tree_services/order-summary`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {"How many stump removals do you need to do?"}
            </label>
          </div>
          <div className="paint_flex_with_left">
            <label className="paint_other_sub_header ">
              {
                "Please select the type of tree/s and quantity you want to remove"
              }
            </label>
          </div>
          <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
            <table className="paint_table100">
              <tr>
                <td>
                  <div
                    className="typeofTxt"
                    style={{ display: "grid", paddingTop: "0" }}
                  >
                    <DropDown
                      value={numberOfTrees || ""}
                      callback={(event) =>
                        setNumberOfTrees(parseInt(event.target.value))
                      }
                      length={50}
                    />
                    {/* {userState.number} */}
                  </div>
                  {/* <label className="paintdiv_txt">
                    </label>  */}
                </td>
              </tr>
            </table>
          </div>
          <div className="paint_flex_with_left paddinngtop20">
            <label className="paint_header">
              {
                "What are the dimensions of the stump/s that you need to remove?"
              }
            </label>
          </div>
          <div className="padding10"></div>
          <div className="padding10"></div>
          {numberOfTreeDetails.map((item, index) => (
            <div>
              <div className="paintinput_lable mb40">
                <TextInput
                  className="paint_input_border"
                  error={isInteger(item.text)}
                  type="number"
                  onChange={(e) => inputHandler(e, index)}
                  value={item.text > 0 ? item.text : ""}
                  placeholder={"Enter the number of inches"}
                  label={t("paintingExterior.sizing")}
                />
                <div className="paint_placeholder_right">
                  <label>{"Inches"}</label>
                </div>
              </div>
              <label className="label">{"How many have this size?"}</label>
              <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
                <table className="paint_table100">
                  <tr>
                    <td>
                      <div
                        className="typeofTxt"
                        style={{ display: "grid", paddingTop: "0" }}
                      >
                        <DropDown
                          value={item.count || ""}
                          callback={(event) =>
                            onChangeHandler(parseInt(event.target.value), index)
                          }
                          length={100}
                        />
                        {/* {userState.number} */}
                      </div>
                      {/* <label className="paintdiv_txt">
                    </label>  */}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          ))}
          <div className="padding10"></div>
          <div className="padding10"></div>
          <button
            onClick={() => {
              var t = {
                text: 0,
                count: 0,
              };
              setNumberOfTreeDetails([...numberOfTreeDetails, t]);
            }}
            buttonlabel="add another"
            class=""
            style={{
              backgroundColor: "#fff",
              color: "#2ccae8",
              border: "1px solid #2ccae8",
              width: "100%",
              borderRadius: "15px",
              padding: "14px 28px",
              marginBottom: "10px",
            }}
          >
            + Add another
          </button>
          <Button
            //disabled={}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSave}
          />
        </div>
      </div>
    </div>
  );
};
