import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, hvacStore } from "../../_redux/actions";
import {
  getServiceCategoriesFull,
  getServicePriceDetails,
} from "../../services/api/category.api.service";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { hvacModel } from "../../_redux/Models/hvac.model";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const HvacTypeofService = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [price, setPrice] = useState(calculateTotalCost(hvacModel.costFor)); // To keep the total price in state
  const [option, setOption] = useState("");
  const [repairCost, setRepairCost] = useState({ price: 0, key: "" });
  const [subCategories, setSubCategories] = useState([]);
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  useEffect(() => {
    let mounted = true;
    getServiceCategoriesFull().then((items) => {
      if (mounted && items.status === 200) {
        let filteredItem = items.data.find((item) => item.name === "HVAC");
        setSubCategories(filteredItem.subCategory);
        checkoutModelAs.selectedService = "HVAC";
        checkoutModelAs.totalCost = 0;
        dispatch(checkoutStore(checkoutModelAs));
        const repairCategory = filteredItem.subCategory.find(
          (op) => op.key === "send_someone"
        );
        getServicePriceDetails(
          repairCategory.categoryId,
          repairCategory._id
        ).then((response) => {
          if (mounted && response.status === 200 && response.data) {
            setRepairCost(response.data.details.sendSomeone);
          }
        });
      }
    });
    return () => (mounted = false);
  }, [checkoutModelAs, dispatch]);

  const onSave = (val) => {
    const hvacRawAs = { ...hvacModel };
    dispatch(hvacStore({}));
    checkoutModelAs.selectedServiceDetails = subCategories.find(
      (op) => op.key === val
    );
    hvacRawAs.serviceType = val;
    let link = "/";
    if (val === "replacement") {
      link = `/contractor-portfolio/${selectedContractorId}/hvac/replace-or-new/2`;
    } else if (val === "repair") {
      hvacRawAs.costFor.service = 0;
      checkoutModelAs.totalCost = repairCost.price;
      link = `/contractor-portfolio/${selectedContractorId}/hvac/replacement-options/2`;
    } else if (val === "send_someone") {
      hvacRawAs.costFor.service = repairCost.price;
      checkoutModelAs.totalCost = repairCost.price;
      link = `/contractor-portfolio/${selectedContractorId}/hvac/order-summary/2`;
    }
    dispatch(checkoutStore(checkoutModelAs));
    dispatch(hvacStore(hvacRawAs));
    history.push(link);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left mb-3">
            <label className="paint_header">{t("hvac.whatServiceNeed")}</label>
          </div>
          <div
            onClick={() => {
              setOption("replacement");
              setPrice(0);
              onSave("replacement");
            }}
            className={`floor-selection-radio ${
              option === "replacement" ? "selected" : ""
            }`}
          >
            <div
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">
                HVAC Replacement/ Installation
              </label>
              <div
                className="paint_arrowFloat"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">{`$0.00`}</label>
                  </div> */}
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setOption("repair");
              setPrice(0);
              onSave("repair");
            }}
            className={`floor-selection-radio ${
              option === "repair" ? "selected" : ""
            }`}
          >
            <div
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">HVAC Repair</label>

              <div
                className="paint_arrowFloat"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(repairCost.price)}`}
                    </label>
                  </div> */}
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setOption("send_someone");
              setPrice(repairCost.price);
              onSave("send_someone");
            }}
            className={`floor-selection-radio ${
              option === "send_someone" ? "selected" : ""
            }`}
          >
            <div
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">
                Please send someone to do a diagnose
              </label>

              <div
                className="paint_arrowFloat"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img src={IMAGES.other.green_plus} alt="" />
                  <label className="paintdiv_txt">
                    {`$${formatPrice(repairCost.price)}`}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
