import IMAGES from "../../_assets/images/index";

const items = [
  {
    urls: IMAGES.home.SolidHardwood1,
  },
  {
    urls: IMAGES.home.SolidHardwood2,
  },
  {
    urls: IMAGES.home.SolidHardwood3,
  },
  {
    urls: IMAGES.home.SolidHardwood4,
  },
  {
    urls: IMAGES.home.SolidHardwood5,
  },
  {
    urls: IMAGES.home.SolidHardwood6,
  },
  {
    urls: IMAGES.home.SolidHardwood7,
  },
  {
    urls: IMAGES.home.SolidHardwood8,
  },
  {
    urls: IMAGES.home.SolidHardwood9,
  },
  {
    urls: IMAGES.home.SolidHardwood10,
  },
  
];
export default items;
