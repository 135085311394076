/**
 * @author Geethananth M
 * Model Object to hold the electricModel
 */

/**
 * Model For electricModel
 */
export const electricModel = {
  flowName: "electric-flow",
  serviceType: "",
  existingOrNew: "",
  addOns: [],
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  costFor: {
    service: 0,
    diagnose: 0,
    material: 0,
    addOns: 0,
  },
};
