import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
//import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
//import { TextInput } from "../../../_components/TextInput/TextInput";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { roofingModel } from "../../_redux/Models/roofing.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { checkoutStore, roofingStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const MaterialOfRoof = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue = 5;

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.roofingPayload
      ? state.buttonReducer.roofingPayload
      : roofingModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [option, setOption] = useState("");
  const [options, setOptions] = useState([]);
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;

    if (mounted === true && priceDetails && priceDetails.materials) {
      const size = parseInt(state.size);
      const haveOwnRoof = state.haveOwnRoof;
      const optionsArr = priceDetails.materials.map((op) => {
        return {
          text: t(`roofing.${op.key}`),
          value: op.key,
          price: haveOwnRoof === "provide-roof" ? size * op.price : 0,
        };
      });
      setOptions(optionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state.haveOwnRoof, state.size, t]);

  const onSave = (val, prc) => {
    state.roofMaterial = val;
    state.costFor.roof = prc - calculateTotalCost(state.costFor);
    checkoutModelState.totalCost = prc;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(roofingStore(state));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/roofing/height-of-roof`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("roofing.materialForRoof")}
            </label>
          </div>
          <div class="paint_flex_with_left mb-3">
            <label class="paint_other_sub_header ">
              {t("roofing.materialForRoofTxt")}
            </label>
          </div>
          {options.map((item, index) => (
            <div
              className={`floor-selection-radio ${
                option === item?.text ? "selected" : ""
              }`}
              onClick={() => {
                const cost = parseFloat(calculateTotalCost(state.costFor));
                setOption(item.text);
                setPrice(cost + item.price);
                onSave(item?.text, cost + item?.price);
              }}
            >
              <div
                key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="paintdiv_txt"
                  style={{
                    width: "65%",
                  }}
                >
                  {item.text}
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "35%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
