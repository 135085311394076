import React, { useState, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import CheckBox from "../../_components/CheckBox/checkBox";
import { Button } from "../../_components/Button/Button";

import { bathroomStore, checkoutStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateBathroomCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { bathroomFlowModel } from "../../_redux/Models/bathroom.model";
import { DropDown } from "../../_components/dropDown/dropDown";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const BathroomAddOns = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;
  const bathroomId =
    props.match && props.match.params ? props.match.params.bathroomId : false;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.bathroomPayload
      ? state.buttonReducer.bathroomPayload
      : bathroomFlowModel
  );

  const bathroom = state.bathroomsDetails.find(
    (baths) => baths.id === bathroomId
  );

  if (!bathroom) {
    history.replace("/bathroom");
    window.location.reload();
  }

  const [price, setPrice] = useState(
    calculateBathroomCost(state.bathroomsDetails)
  ); // To keep the total price in state

  const [addOns, setAddOns] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails && priceDetails.addOns) {
      const size = bathroom.size;
      const haveOwnMaterial = bathroom.haveOwnMaterial;
      const singleItems = [
        "upgrade_tiles_to_marbel",
        "bench_square",
        "bench_corner",
        "bench_corner",
        "tiles_around_tub",
        "heated_floor_system",
      ];
      const optionsArr = priceDetails.addOns.map((op) => {
        let price = op.price;
        if (op.options) {
          const priceObj = op.options.find((o) => o.key === size);
          if (op.key !== "heated_floor_system") {
            const servicePrice = priceObj.servicePrice;
            const materialPrice = priceObj.materialPrice;
            price = haveOwnMaterial
              ? servicePrice
              : servicePrice + materialPrice;
          } else {
            price = priceObj.price;
          }
        }
        return {
          text: t(`Bathroom.${op.key}`),
          value: op.key,
          count: 1,
          isChecked: false,
          isSingle: singleItems.includes(op.key),
          price: price,
        };
      });
      setAddOns(optionsArr.sort((o, p) => (p.isSingle ? 1 : -1)));
    }
    return () => (mounted = false);
  }, [bathroom, checkoutModelState, dispatch, t]);

  const onSave = () => {
    bathroom.costFor.addOns =
      price - calculateBathroomCost(state.bathroomsDetails);
    bathroom.addOns = addOns.filter((i) => i.isChecked === true);
    state.bathroomsDetails.filter((bt) => bt.id !== bathroom.id).push(bathroom);
    dispatch(bathroomStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));

    const nextBathroom = state.bathroomsDetails.find(
      (bt) => bt.size === 0 && bt.requireBuildUpWalls === ""
    );
    let nextUrl = `/contractor-portfolio/${selectedContractorId}/bathroom/order-summary/8`;
    if (nextBathroom) {
      nextUrl = `/contractor-portfolio/${selectedContractorId}/bathroom/${nextBathroom.id}/size/1`;
    }
    history.push(nextUrl);
  };

  function arrowHandler(params, e, index) {
    let updatedState = [...addOns];
    if (params === "plus") {
      updatedState[index].count = updatedState[index].count + 1;
    } else if (updatedState[index].count > 1) {
      updatedState[index].count = updatedState[index].count - 1;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  }

  const calculateTotal = (addOns) => {
    const total = addOns
      .filter((v) => v.isChecked === true)
      .reduce((p, c) => p + c.price * c.count, 0);
    setPrice(calculateBathroomCost(state.bathroomsDetails) + total);
  };

  const toggleCheck = (event, index) => {
    let updatedState = [...addOns];
    if (!updatedState[index].isChecked) {
      updatedState[index].isChecked = event;
    } else {
      updatedState[index].isChecked = !event;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  const onAddOnHandler = (event, index) => {
    let updatedState = [...addOns];
    updatedState[index].count = parseInt(event.target.value);
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("kitchen.addon")}</label>
        </div>
        <div className="paint_flex_with_left">
          <label className="paint_other_sub_header ">
            {t("Bathroom.seladd2")}
            <span
              style={{ fontWeight: "bold", color: "#2ccae8" }}
            >{`Bathroom #${bathroom.number}`}</span>
          </label>
        </div>
        <div className="padding10"></div>
        {addOns &&
          addOns !== undefined &&
          addOns.map((item, index) => (
            <div className="my-4">
              <div
                className={`floor-selection-radio ${
                  item?.isChecked ? "selected" : ""
                }`}
                onClick={() => toggleCheck(true, index)}
              >
                <div
                  key={index}
                  className="width100 "
                  style={{ justifyContent: "space-between" }}
                >
                  <table>
                    <tr>
                      <td>
                        <label className="paintdiv_txt"> {item.text} </label>
                      </td>
                      <td>
                        <div
                          className="paint_arrowFloat flex"
                          style={{ paddingTop: "10px" }}
                        >
                          <img
                            src={IMAGES.other.green_plus}
                            className="kitchenplusimg"
                            alt=""
                          />
                          <label
                            className="paintdiv_txt paddingleft8"
                            style={{ paddingLeft: "7px", paddingTop: "2px" }}
                          >
                            {`$${formatPrice(item.count * item.price)}`}
                          </label>
                          <div
                            className="paint_arrowFloat"
                            style={{ paddingLeft: "7px" }}
                          >
                            <CheckBox checked={item.isChecked} readOnly />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="mt-2 mb-3">
                {item.isChecked && !item.isSingle ? (
                  <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
                    <table className="paint_table100">
                      <tr>
                        <DropDown
                          value={item.count}
                          callback={(event) => onAddOnHandler(event, index)}
                          length={50}
                        />
                      </tr>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        <Button
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => onSave()}
        />
      </div>
    </div>
  );
};
