import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/myProjects.css";
import "../../../_styles/localStyles/paint.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import { fenceModel } from "../../../_redux/Models/painting.models";
import { useDispatch } from "react-redux";
import { paintingStore } from "../../../_redux/actions";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { Button } from "../../../_components/Button/Button";
import { Smileverybad } from "../../../_components/Tickandwrongsvg/smileveryBad";
import { Smilebad } from "../../../_components/Tickandwrongsvg/smileBad";
import { Smilegood } from "../../../_components/Tickandwrongsvg/smileGood";
import { Smileverygood } from "../../../_components/Tickandwrongsvg/smileveryGood";
import { Smileexcellent } from "../../../_components/Tickandwrongsvg/smileExcellent";
import { parseJwt } from "../../../services/utils/utilFunctions";
import { rateProject } from "../../../services/api/owner.service";
import { useSnackbar } from "notistack";
import Loader from "../../../_components/spinner/Loader";

export const RateProject = (props) => {
  const projectId =
    props.match && props.match.params ? props.match.params.projectId : null;
  const contractorId =
    props.match && props.match.params ? props.match.params.contractorId : null;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const snackBar = useSnackbar();
  const { history } = props;
  const [rating, setRating] = useState("");
  const [review, setReview] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSave = () => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (user && user._id && projectId) {
      setIsLoading(true);
      snackBar.closeSnackbar();
      rateProject(
        "project_contractor",
        user._id,
        projectId,
        contractorId,
        rating,
        review
      )
        .then((data) => {
          if (data.response.data.error) {
            const variant = "error";
            snackBar.enqueueSnackbar(data.response.data.message, {
              variant,
            });
          } else {
            const variant = "success";
            snackBar.enqueueSnackbar("Thanks for your rating!", {
              variant,
            });
          }
          setTimeout(() => {
            history.goBack(-1);
          }, 500);
        })
        .catch((e) => {
          const variant = "error";
          snackBar.enqueueSnackbar(`${e.toString()}`, {
            variant,
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div className="insideWrp">
      <HeaderMenu />
      <Loader progress={isLoading} />
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left ">
          <label className="paint_header">{t("Rate your experience")}</label>
        </div>
        <div className="otherspadding25">
          <label className="divtxtNormal">
            {t("Tell us about the project")}
          </label>{" "}
        </div>
        <div className="otherspadding25"></div>
        <div className="otherspadding25"></div>
        <table style={{ justifyContent: "space-between", width: "100%" }}>
          <tr>
            <td onClick={() => setRating(1)}>
              <Smileverybad selected={rating === 1} />
            </td>
            <td onClick={() => setRating(2)}>
              <Smilebad selected={rating === 2} />
            </td>
            <td onClick={() => setRating(3)}>
              <Smilegood selected={rating === 3} />
            </td>
            <td onClick={() => setRating(4)}>
              <Smileverygood selected={rating === 4} />
            </td>
            <td onClick={() => setRating(5)}>
              <Smileexcellent selected={rating === 5} />
            </td>
          </tr>
        </table>
        <div className="paintpaddingtop30"></div>
        <div className="paintpaddingtop30"></div>
        <div className="paintpaddingtop30"></div>
        <table>
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("Add comments, if you want")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message"
                onChange={(e) => setReview(e.target.value)}
              />
            </td>
          </tr>
        </table>
        <div className="otherspadding25"></div>
        <Button
          disabled={false}
          buttonLabel={t("Confirm rate")}
          onClick={onSave}
        />
      </div>
      <Footer />
    </div>
  );
};
