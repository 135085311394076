import SolidHardwoodWhatIsIncluded from "../floorComponents/SolidHardwoodNew/whatIsIncluded";
import CarpetWhatIsIncluded from "../floorComponents/carpet/whatIsIncluded";
import EngineeredWhatIsIncluded from "../floorComponents/engineered/whatIsIncluded";
import EpoxyWhatIsIncluded from "../floorComponents/Epoxy/whatIsIncluded";
import TilesWhatIsIncluded from "../floorComponents/Tiles/whatIsIncluded";
import BathroomWhatIsIncluded from "../Bathroom/whatIsIncluded";
import InteriorWhatIsIncluded from "../paintingComponents/interior/whatIsIncluded";
import FenceWhatIsIncluded from "../paintingComponents/fence/whatIsIncluded";
import ExteriorWhatIsIncluded from "../paintingComponents/exterior/whatIsIncluded";
import DeckWhatIsIncluded from "../paintingComponents/deck/whatIsIncluded";
import KitchenWhatIsIncluded from "../kitchenComponents/whatIsIncluded";
import WallOpeningWhatIsIncluded from "../wallOpening/whatIsIncluded";
import ElectricWhatIsIncluded from "../Electric/whatIsIncluded";
import PlumbingWhatIsIncluded from "../Plumbing/whatIsIncluded";
import BasementWhatIsIncluded from "../basement/whatIsIncluded";
import TreeServicesWhatIsIncluded from "../TreeService/whatIsIncluded";
import LaminatedWhatIsIncluded from "../floorComponents/Laminated/whatIsIncluded";

export const getWhatIsIncludedComponent = (path) => {
  if (path?.includes("solid-hardwood")) {
    return <SolidHardwoodWhatIsIncluded />;
  } else if (path?.includes("carpet")) {
    return <CarpetWhatIsIncluded />;
  } else if (path?.includes("laminated")) {
    return <LaminatedWhatIsIncluded />;
  } else if (path?.includes("engineered")) {
    return <EngineeredWhatIsIncluded />;
  } else if (path?.includes("epoxy")) {
    return <EpoxyWhatIsIncluded />;
  } else if (path?.includes("tiles")) {
    return <TilesWhatIsIncluded />;
  } else if (path?.includes("bathroom")) {
    return <BathroomWhatIsIncluded />;
  } else if (path?.includes("interior")) {
    return <InteriorWhatIsIncluded />;
  } else if (path?.includes("fence")) {
    return <FenceWhatIsIncluded />;
  } else if (path?.includes("exterior")) {
    return <ExteriorWhatIsIncluded />;
  } else if (path?.includes("deck")) {
    return <DeckWhatIsIncluded />;
  } else if (path?.includes("kitchen")) {
    return <KitchenWhatIsIncluded />;
  } else if (path?.includes("wall_opening")) {
    return <WallOpeningWhatIsIncluded />;
  } else if (path?.includes("electric")) {
    return <ElectricWhatIsIncluded />;
  } else if (path?.includes("plumbing")) {
    return <PlumbingWhatIsIncluded />;
  } else if (path?.includes("basement")) {
    return <BasementWhatIsIncluded />;
  } else if (path?.includes("tree_services")) {
    return <TreeServicesWhatIsIncluded />;
  }
};
