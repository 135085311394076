import React, { useState, useEffect, useContext } from "react";
import IMAGES from "../../../_assets/images/index";
import { useTranslation } from "react-i18next";
import RadioButton from "../../../_components/radioButton/radioButton";
import { useDispatch, useSelector } from "react-redux";
import "../../../_styles/localStyles/signupsignin.css";
import { continuetoNext } from "../../../_redux/actions";
import { signupActions } from "../../../_redux/actions";
import { signupModel } from "../../../_redux/Models/auth.models";

function SignupProperty(props) {
  const { t } = useTranslation();
  const { history } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.signupReducer);
  const [radioOption, setRadioOption] = useState({
    selectedOption: user.property ? user.property : "",
  });
  const arrowClick = () => {
    history.push(`/role`);
  };

  function radioClick(event) {
    setRadioOption({ ...radioOption, selectedOption: event });
    signupModel.property = event;
    dispatch(continuetoNext(signupModel));
    history.push(`/signup`);
  }

  return (
    <div className="pcWidth">
      {/* <div>
          <img
            className="arrow_icon_left "
            src={IMAGES.other.vectors}
            alt=""
            onClick={() => arrowClick()}
          />
          <h2 onClick={() => window.location.replace("/")}>
            {t("signUpProperty.mainHeading")}
          </h2>
        </div> */}
      <div className="signup_center_align">
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  src={IMAGES.other.vectors}
                  alt=""
                  onClick={() => arrowClick()}
                />
              </td>
              <td>
                <label
                  className="signup_page_anyhnb_logo"
                  onClick={() => window.location.replace("/")}
                >
                  {t("signUp.mainHeading")}
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="signup_section">
        <label className="signup_property_headings signup_flex_with_center">
          {t("signUpProperty.kindOfPropertyHeading")}
        </label>

        <div className="signup_section">
          {/* <label className="signup_property_headings ">
          {t("signUpProperty.kindOfPropertyHeading")}
        </label> */}

          <div
            className="signup_property_section"
            onClick={() => radioClick("home")}
          >
            <div className="signup_home_section ">
              <div className="signup_property_div_Option">{"Home"}</div>

              <div>
                <RadioButton
                  onClick={(e) => radioClick(e)}
                  checked={radioOption.selectedOption === "home" ? true : false}
                />
              </div>
            </div>
          </div>

          <div onClick={(e) => radioClick("commercial")}>
            <div className="signup_home_section">
              <div
                className="signup_property_div_Option"
                onClick={(e) => radioClick(e)}
              >
                {"Commercial"}
              </div>
              <div>
                <RadioButton
                  onClick={(e) => radioClick(e)}
                  checked={
                    radioOption.selectedOption === "commercial" ? true : false
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignupProperty;
