const IMAGES = {
  home: {
    anyHnbLog: require("./home-images/Anyhnb.svg").default,
    atSymbol: require("./home-images/atSymbol.png").default,
    addressIcon: require("./home-images/AddressIcon.svg").default,
    backIcon: require("./home-images/backIcon.svg").default,
    bath: require("./home-images/Bathroom/bathroom-main-category.jpeg").default,
    btn: require("./home-images/btn.svg").default,
    hdBathroom: require("./home-images/hdBathroom.svg").default,
    basement: require("./home-images/basement-main-category.png").default,
    bedroom: require("./home-images/bedroom.png").default,
    newbasement: require("./home-images/newbasement.svg").default,
    contractor: require("./home-images/contractor.png").default,
    checkBlue: require("./home-images/checkBlue.png").default,
    contractorIcon: require("./other-images/contactorIcon.png").default,
    carpet: require("./home-images/carpet.svg").default,
    defaultUser: require("./home-images/defaultUser.svg").default,
    epoxy: require("./home-images/epoxy.png").default,
    elevatorIcon: require("./other-images/elevatorIcon.png").default,
    editIcon: require("./home-images/Edit.svg").default,
    tickIcon: require("./other-images/tickIcon.png").default,
    engineered: require("./home-images/engineered.png").default,
    hdContractor: require("./home-images/contractorSvgnew.svg").default,
    family: require("./home-images/family.png").default,
    fb: require("./home-images/fb.png").default,
    homeRoom: require("./home-images/homeRoom.png").default,
    heart: require("./home-images/Heart.png").default,
    heartFilled: require("./home-images/HeartFilled.svg").default,
    heartWhite: require("./home-images/HeartWhite.svg").default,
    heartOulined: require("./home-images/HeartOutlined.svg").default,
    redHeart: require("./home-images/RedHeart.svg").default,
    blueHeart: require("./home-images/BlueHeart.svg").default,
    globe: require("./home-images/Globe.svg").default,
    instagram: require("./home-images/instagram.png").default,
    whatsapp: require("./home-images/whtsp.svg").default,
    kitchen: require("./home-images/kitchen.png").default,
    hdkitchen: require("./home-images/hdKitchen.svg").default,
    kitchenn: require("./home-images/Kitchen/kitchen-main-category.jpeg")
      .default,
    man: require("./home-images/man.png").default,
    modalClose: require("./home-images/ModalClose.svg").default,
    hdman: require("./home-images/hdman.svg").default,
    menu: require("./home-images/menu.svg").default,
    menuBlue: require("./home-images/menu-blu.svg").default,
    menuBlack: require("./home-images/MenuBlack.svg").default,
    newArrow: require("./home-images/newArrow.png").default,
    laminated: require("./home-images/laminated.png").default,
    others: require("./home-images/others.png").default,
    painting: require("./home-images/painting.png").default,
    room: require("./home-images/room.png").default,
    resetIcon: require("./home-images/resetIcon.svg").default,
    removeIcon: require("./home-images/removeIcon.svg").default,
    searchIcon: require("./home-images/SearchIcon.svg").default,
    search: require("./home-images/search.png").default,
    shareIcon: require("./home-images/shareIcon.svg").default,
    favIconWhite: require("./home-images/favIconWhite.svg").default,
    searchBlack: require("./home-images/SearchBlack.svg").default,
    scrollLeft: require("./home-images/previousIcon.svg").default,
    scrollRight: require("./home-images/nextIcon.svg").default,
    starBlack: require("./home-images/StarBlack.svg").default,
    showMap: require("./home-images/ShowMap.svg").default,
    square: require("./home-images/square.png").default,
    sofa: require("./home-images/sofa.png").default,
    tiles: require("./home-images/tiles.png").default,
    hdSofa: require("./home-images/hdSofa.svg").default,
    user: require("./home-images/user.svg").default,
    userBlue: require("./home-images/user-blu.svg").default,
    wall: require("./home-images/wall.png").default,
    wallopen: require("./home-images/wallopen.png").default,
    wallpen: require("./home-images/wallpen.png").default,
    wallOpening: require("./home-images/wall-opening-main-category.jpeg")
      .default,
    wooden: require("./home-images/wooden.png").default,
    verified: require("./home-images/Verified.svg").default,
    verifiedSquare: require("./home-images/VerifiedSquare.svg").default,
    blueWhiteTick: require("./home-images/BlueWhiteTick.svg").default,
    selectArrow: require("./home-images/selectArrow.svg").default,
    locationIcon: require("./home-images/locationIcon.svg").default,
    closeIcon: require("./home-images/closeIcon.svg").default,
    smallCloseIcon: require("./home-images/smallCloseIcon.svg").default,
    homeIcon: require("./home-images/homeIcon.svg").default,
    calendarIcon: require("./home-images/CalendarIcon.svg").default,
    bankIcon: require("./home-images/BankIcon.svg").default,
    paymentReceived: require("./home-images/payment-recieved.svg").default,
    lightwood: require("./home-images/lightwood.png").default,
    solidoak: require("./home-images/solidoak.png").default,
    redoak: require("./home-images/red-oak.png").default,
    tiless: require("./home-images/tiless.png").default,
    woodtext: require("./home-images/woodtext.png").default,
    woodtxt: require("./home-images/woodtxt.png").default,
    piggy: require("./home-images/coins.jpg").default,
    step1: require("./home-images/step1.png").default,
    step2: require("./home-images/step2.png").default,
    step3: require("./home-images/step3.png").default,
    step4: require("./home-images/step4.png").default,
    step5: require("./home-images/step5.png").default,
    step6: require("./home-images/step6.png").default,
    step7: require("./home-images/step7.png").default,
    Contractor3d: require("./home-images/Contractor3d.png").default,
    sun: require("./home-images/sun.png").default,
    sun2: require("./home-images/sun2.svg").default,
    earlyNoon: require("./home-images/early-noon.jpg").default,
    lateNoon: require("./home-images/Late-noon.jpg").default,
    calendar: require("./home-images/calendar.png").default,
    dropdownArrow: require("./home-images/dropDownArrow.svg").default,
    downArrow: require("./home-images/downArrow.png").default,
    rightArrow: require("./home-images/rightArrow.png").default,

    Demolition_hauling: require("./home-images/Demolition_hauling.png").default,
    Tree_remotion: require("./home-images/tree-main-category.jpeg").default,
    Siding: require("./home-images/Siding.png").default,
    Deck: require("./home-images/deck.png").default,
    Windows: require("./home-images/windows-main-category.jpg").default,
    Pool: require("./home-images/Pool.png").default,
    Roofing: require("./home-images/roof-main-catgry.jpeg").default,
    hvac: require("./home-images/hvac-main-category.jpeg").default,
    wallOpeningBanner: require("./home-images/wallOpeningBanner.jpg").default,
    electricBanner: require("./home-images/electricBanner.jpg").default,
    windowsBanner: require("./home-images/windowsBanner.jpg").default,
    roofingBanner: require("./home-images/roofingBanner.jpg").default,
    plumbingBanner: require("./home-images/plumbingBanner.jpg").default,
    treeServicesBanner: require("./home-images/treeServicesBanner.jpg").default,
    Plumbing: require("./home-images/Plumbing.png").default,
    Electric: require("./home-images/Electric.png").default,

    interiorpaint: require("./home-images/interiorpaint.png").default,
    exteriorpaint: require("./home-images/exteriorpaint.png").default,
    deckpaint: require("./home-images/deckpaint.png").default,
    fencepaint: require("./home-images/fencepaint.png").default,
    otherpaint: require("./home-images/otherpaint.png").default,
    stepnumbertwo: require("./home-images/stepnumbertwo.png").default,
    stepnumberthree: require("./home-images/stepnumberthree.png").default,
    stepnumberfour: require("./home-images/stepnumberfour.png").default,
    stepnumberfive: require("./home-images/stepnumberfive.png").default,
    stepnumbersix: require("./home-images/stepnumbersix.png").default,
    stepnumberfivefull: require("./home-images/stepnumberfivefull.png").default,
    stepfourfull: require("./home-images/stepfourfull.png").default,

    profileGroupman: require("./home-images/profileGroupman.png").default,
    profileTick: require("./home-images/profileTick.png").default,
    profileWrong: require("./home-images/profileWrong.png").default,
    profileEllipse: require("./home-images/profileEllipse.png").default,
    profilebathPic2: require("./home-images/profilebathPic2.png").default,
    profilebathPic1: require("./home-images/profilebathPic1.png").default,
    profileVector: require("./home-images/profileVector.png").default,
    profileHandsome: require("./home-images/profileHandsome.png").default,
    profilePool: require("./home-images/profilePool.png").default,
    profileDeck: require("./home-images/profileDeck.png").default,
    profileOther: require("./home-images/profileOther.png").default,
    profileDemolt: require("./home-images/profileDemolt.png").default,
    profileWindows: require("./home-images/profileWindows.png").default,
    profileTrees: require("./home-images/profileTrees.png").default,
    profileSlide: require("./home-images/profileSlide.png").default,
    profileRoof: require("./home-images/profileRoof.png").default,
    profilePlumbing: require("./home-images/profilePlumbing.png").default,
    profileElectric: require("./home-images/profileElectric.png").default,
    profilePaint: require("./home-images/profilePaint.png").default,
    profileBathroom: require("./home-images/profileBathroom.png").default,
    profileWall: require("./home-images/profileWall.png").default,
    profilekitchen: require("./home-images/profilekitchen.png").default,
    profileFloor: require("./home-images/profileFloor.png").default,
    profileContractor3d: require("./home-images/profileContractor3d.png")
      .default,
    manPencil: require("./home-images/manPencil.png").default,
    bathroomCommon: require("./home-images/bathroomCommon.png").default,

    traditionalCabinets: require("./home-images/traditionalCabinets.png")
      .default,
    shakerCabinet: require("./home-images/shakerCabinet.png").default,
    modernCabinets: require("./home-images/modernCabinets.png").default,
    otherCabinets: require("./home-images/otherCabinets.png").default,
    hdmanSlide: require("./home-images/handsomenew.png").default,

    basementSlider1: require("./home-images/Basement/bp1.png").default,
    basementSlider2: require("./home-images/Basement/bp2.png").default,
    basementSlider3: require("./home-images/Basement/bp3.png").default,
    basementSlider4: require("./home-images/Basement/bp4.png").default,
    basementSlider5: require("./home-images/Basement/bp5.png").default,
    basementSlider6: require("./home-images/Basement/bp6.png").default,
    basementSlider7: require("./home-images/Basement/bp7.png").default,
    basementSlider8: require("./home-images/Basement/bp8.png").default,
    basementSlider9: require("./home-images/Basement/bp9.png").default,
    basementSlider10: require("./home-images/Basement/bp10.png").default,
    basementSlider11: require("./home-images/Basement/bp11.png").default,

    bathroomSlider0: require("./home-images/Bathroom/bp0.jpeg").default,
    bathroomSlider1: require("./home-images/Bathroom/bp1.png").default,
    bathroomSlider2: require("./home-images/Bathroom/bp2.png").default,
    bathroomSlider3: require("./home-images/Bathroom/bp3.png").default,
    bathroomSlider4: require("./home-images/Bathroom/bp4.png").default,
    bathroomSlider5: require("./home-images/Bathroom/bp5.png").default,
    bathroomSlider6: require("./home-images/Bathroom/bp6.png").default,
    bathroomSlider7: require("./home-images/Bathroom/bp7.png").default,
    bathroomSlider8: require("./home-images/Bathroom/bp8.png").default,
    bathroomSlider9: require("./home-images/Bathroom/bp9.png").default,
    bathroomSlider10: require("./home-images/Bathroom/bp10.png").default,
    bathroomSlider11: require("./home-images/Bathroom/bp11.png").default,
    bathroomSlider12: require("./home-images/Bathroom/bp12.jpeg").default,

    floorSliderFirst: require("./home-images/Floors/floorSliderFirst.jpeg")
      .default,
    floorSliderSecond: require("./home-images/Floors/floorSliderSecond.jpg")
      .default,
    floorSlider1: require("./home-images/Floors/fp0.png").default,
    floorSlider2: require("./home-images/Floors/fp1.png").default,
    floorSlider3: require("./home-images/Floors/fp2.png").default,
    floorSlider4: require("./home-images/Floors/fp3.png").default,
    floorSlider5: require("./home-images/Floors/fp4.png").default,
    floorSlider6: require("./home-images/Floors/fp5.png").default,
    floorSlider7: require("./home-images/Floors/fp6.png").default,
    floorSlider8: require("./home-images/Floors/fp7.png").default,
    floorSlider9: require("./home-images/Floors/fp8.png").default,
    floorSlider10: require("./home-images/Floors/fp9.png").default,

    kitchenSlider1: require("./home-images/Kitchen/kp1.png").default,
    kitchenSlider2: require("./home-images/Kitchen/kp2.png").default,
    kitchenSlider3: require("./home-images/Kitchen/kp3.png").default,
    kitchenSlider4: require("./home-images/Kitchen/kp4.png").default,
    kitchenSlider5: require("./home-images/Kitchen/kp5.png").default,
    kitchenSlider6: require("./home-images/Kitchen/kp6.png").default,
    kitchenSlider7: require("./home-images/Kitchen/kp7.png").default,
    kitchenSlider8: require("./home-images/Kitchen/kp8.png").default,
    kitchenSlider9: require("./home-images/Kitchen/kp9.png").default,

    Carpet1: require("./home-images/FloorsPictures/Carpet/Carpet.jpg").default,

    Engineered1: require("./home-images/FloorsPictures/Engineered/Ei1.jpg")
      .default,
    Engineered2: require("./home-images/FloorsPictures/Engineered/Ei2.jpg")
      .default,
    Engineered3: require("./home-images/FloorsPictures/Engineered/Ei3.jpg")
      .default,
    Engineered4: require("./home-images/FloorsPictures/Engineered/Ei4.jpg")
      .default,
    Engineered5: require("./home-images/FloorsPictures/Engineered/Ei5.jpg")
      .default,
    Engineered6: require("./home-images/FloorsPictures/Engineered/Ei6.jpg")
      .default,

    Laminated1: require("./home-images/FloorsPictures/Laminated/L0.png")
      .default,
    Laminated2: require("./home-images/FloorsPictures/Laminated/L1.png")
      .default,
    Laminated3: require("./home-images/FloorsPictures/Laminated/L2.png")
      .default,
    Laminated4: require("./home-images/FloorsPictures/Laminated/L3.png")
      .default,
    Laminated5: require("./home-images/FloorsPictures/Laminated/L4.png")
      .default,

    SolidHardwood1:
      require("./home-images/FloorsPictures/SolidHardwood/SH0.jpg").default,
    SolidHardwood2:
      require("./home-images/FloorsPictures/SolidHardwood/SH1.jpg").default,
    SolidHardwood3:
      require("./home-images/FloorsPictures/SolidHardwood/SH2.jpg").default,
    SolidHardwood4:
      require("./home-images/FloorsPictures/SolidHardwood/SH3.jpg").default,
    SolidHardwood5:
      require("./home-images/FloorsPictures/SolidHardwood/SH4.jpg").default,
    SolidHardwood6:
      require("./home-images/FloorsPictures/SolidHardwood/SH5.jpg").default,
    SolidHardwood7:
      require("./home-images/FloorsPictures/SolidHardwood/SH6.jpg").default,
    SolidHardwood8:
      require("./home-images/FloorsPictures/SolidHardwood/SH7.jpg").default,
    SolidHardwood9:
      require("./home-images/FloorsPictures/SolidHardwood/SH8.png").default,
    SolidHardwood10:
      require("./home-images/FloorsPictures/SolidHardwood/SH9.png").default,

    Tiles1: require("./home-images/FloorsPictures/Tiles/TI1.jpg").default,

    bathroomTraditional1: require("./home-images/TraditionalBathroom/cb1.jpg")
      .default,
    bathroomTraditional2: require("./home-images/TraditionalBathroom/cb2.jpeg")
      .default,
    bathroomTraditional3: require("./home-images/TraditionalBathroom/cb3.jpg")
      .default,
    bathroomTraditional4: require("./home-images/TraditionalBathroom/cb4.jpeg")
      .default,
    bathroomTraditional5: require("./home-images/TraditionalBathroom/cb5.jpg")
      .default,
    bathroomTraditional6: require("./home-images/TraditionalBathroom/cb6.png")
      .default,
    bathroomTraditional7: require("./home-images/TraditionalBathroom/cb7.jpeg")
      .default,
    bathroomTraditional8: require("./home-images/TraditionalBathroom/cb8.jpeg")
      .default,
    bathroomTraditional9: require("./home-images/TraditionalBathroom/cb9.jpeg")
      .default,
    bathroomTraditional10: require("./home-images/TraditionalBathroom/cb10.jpg")
      .default,
    bathroomTraditional11:
      require("./home-images/TraditionalBathroom/cb11.jpeg").default,

    bathroomRustic1: require("./home-images/RusticBathroom/rb1.jpg").default,
    bathroomRustic2: require("./home-images/RusticBathroom/rb2.jpg").default,
    bathroomRustic3: require("./home-images/RusticBathroom/rb3.jpg").default,

    bathroomOther1: require("./home-images/OtherBathroom/ob1.jpg").default,
    bathroomOther2: require("./home-images/OtherBathroom/ob2.png").default,

    bathroomOldFashioned1:
      require("./home-images/OldFashionedBathroom/ofb1.jpg").default,

    bathroomModern1: require("./home-images/ModernBathroom/mb1.jpg").default,
    bathroomModern2: require("./home-images/ModernBathroom/mb2.jpg").default,
    bathroomModern3: require("./home-images/ModernBathroom/mb3.jpg").default,
    bathroomModern4: require("./home-images/ModernBathroom/mb4.jpg").default,
    bathroomModern5: require("./home-images/ModernBathroom/mb5.jpg").default,
    bathroomModern6: require("./home-images/ModernBathroom/mb6.png").default,

    logoHome: require("./home-images/logohome.gif").default,
    logoInner: require("./home-images/logoinner.png").default,
    logo: require("./home-images/logo.jpg").default,

    basementBanner: require("./home-images/categoryBanner/basement.png")
      .default,
    bathroomBanner: require("./home-images/categoryBanner/bathroom.png")
      .default,
    floorBanner: require("./home-images/categoryBanner/floor.png").default,
    kitchenBanner: require("./home-images/categoryBanner/kitchen.png").default,
    paintingBanner: require("./home-images/categoryBanner/painting.png")
      .default,
    appStore: require("./home-images/appstore.jpg").default,
    playStore: require("./home-images/playstore.jpg").default,

    Bestpriceguaranteed:
      require("./home-images/dreammaker/Bestpriceguaranteed.png").default,
    Designandinnovation:
      require("./home-images/dreammaker/Designandinnovation.png").default,
    offBanner: require("./home-images/dreammaker/offBanner.png").default,
    Simplicity: require("./home-images/dreammaker/Simplicity.png").default,
    Topnotchquality: require("./home-images/dreammaker/Topnotchquality.png")
      .default,
    english: require("./home-images/us.png").default,
    espanol: require("./home-images/Español.png").default,
    portugese: require("./home-images/Português.svg").default,
    italian: require("./home-images/Italiano.png").default,
    french: require("./home-images/Français.png").default,
    carouselDefaultImage: require("./home-images/defaultCarouselImage.png")
      .default,
  },
  other: {
    apple: require("./other-images/apple.png").default,
    phone: require("./other-images/mobileImage.png").default,
    email: require("./other-images/emailImage.png").default,
    downloadFile: require("./other-images/download-file.png").default,
    arrow: require("./other-images/arrow.png").default,
    arrows: require("./other-images/arrows.png").default,
    black_vector: require("./other-images/black_vector.svg").default,
    eye_Vector: require("./other-images/eye_Vector.png").default,
    eye: require("./other-images/eye.png").default,
    eyeslash: require("./other-images/eyeslash_Vector.png").default,
    facebook: require("./other-images/facebook.png").default,
    google: require("./other-images/google.png").default,
    vectors: require("./other-images/vectors.svg").default,
    vectorsWhite: require("./other-images/vectors_white.svg").default,
    greenEye: require("./other-images/greenEye.png").default,
    redEye: require("./other-images/redEye.png").default,
    warning: require("./other-images/warning.png").default,
    tick: require("./other-images/tick.png").default,
    btn: require("./other-images/btn.png").default,
    group1: require("./other-images/group1.svg").default,
    green_plus: require("./other-images/green_plus.svg").default,
    piggy: require("./other-images/coins.jpg").default,
    hvacSizeDetails: require("./other-images/hvac-size-details.png").default,
    stepper1: require("./other-images/stepper1.png").default,
    stepper2: require("./other-images/stepper2.png").default,
    stepper3: require("./other-images/stepper3.png").default,
    stepper4: require("./other-images/stepper4.png").default,
    stepper5: require("./other-images/stepper5.png").default,
    line: require("./other-images/line.png").default,
    closeVector: require("./other-images/closeVector.svg").default,
    exclVector: require("./other-images/exclVector.png").default,
    exclamation: require("./other-images/exclamation.png").default,
    skipVector: require("./other-images/skipVector.svg").default,

    error_Vector: require("./other-images/error_Vector.png").default,
    success_Vector: require("./other-images/success_Vector.png").default,
    show_Vector: require("./other-images/show_Vector.png").default,
    suc_vis_Vector: require("./other-images/suc_vis_Vector.png").default,
    vis_error_Vector: require("./other-images/vis_error_Vector.png").default,
    vectorUP: require("./other-images/vectorUP.png").default,
    vectorDown: require("./other-images/vectorDown.png").default,
    bluePlus: require("./other-images/bluePlus.png").default,
    dropdownarrow: require("./other-images/dropdownarrow.png").default,
    personalvector: require("./other-images/personalvector.png").default,
    polygon: require("./other-images/polygon.png").default,
    manEllipse: require("./other-images/manEllipse.png").default,
    pencil: require("./other-images/polygon.png").default,
    elli: require("./other-images/elli.png").default,
    docImg: require("./other-images/docImg.png").default,
    photosImg: require("./other-images/photosImg.png").default,
    threedContractor: require("./other-images/3dContractor.svg").default,
    smallContractor: require("./other-images/smallContractor.svg").default,
    paperThreed: require("./other-images/paperThreed.svg").default,
    americanEx: require("./other-images/americanEx.svg").default,
    calendarPay: require("./other-images/calendarPay.svg").default,
    /* miniHouse: require("./other-images/miniHouse.svg").default, */
    miniHouse: require("./other-images/miniHouse.png").default,
    visa: require("./other-images/visa.svg").default,
    redpay: require("./other-images/redpay.svg").default,
    yellowpay: require("./other-images/yellowpay.svg").default,

    smileone: require("./other-images/smileone.svg").default,
    smiletwo: require("./other-images/smiletwo.svg").default,
    smilethree: require("./other-images/smilethree.svg").default,
    smilefour: require("./other-images/smilefour.svg").default,
    smilefive: require("./other-images/smilefive.svg").default,

    pencilEdit: require("./other-images/pencil.png").default,
    bubblePoint: require("./other-images/bubble_point.png").default,
    bubblePointBlue: require("./other-images/bubble_point_blue.png").default,
    sentMessage: require("./other-images/sent_message.svg").default,
    backArrow: require("./other-images/back_arrow.svg").default,
    logoutWhite: require("./other-images/logoutWhite.svg").default,
    money: require("./other-images/Money.png").default,
    house: require("./other-images/House-wht.png").default,
    person: require("./other-images/Person.png").default,
    bell: require("./other-images/bell.png").default,
    kitchenLCShapeInfo: require("./other-images/kitchen-LC-shape-info.png")
      .default,
    locationIcon: require("./other-images/location-icon.svg").default,
  },
};
export default IMAGES;
