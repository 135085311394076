import { Complexity } from "./Complexity";
import { DebrisHaulAway } from "./DebrisHaulAway";
import { TreeOrderSummary } from "./OrderSummary";
import { RootsRemoval } from "./RootsRemoval";
import { RootsRemovalMachine } from "./RootsRemovalMachine";
import { TreeDetailsNearElectric } from "./TreeDetailsNearElectric";
import { TreeDetailsNotNearElectrical } from "./TreeDetailsNotNearElectrical";
import { TreeHeightDetails } from "./TreeHeightDetails";
import { TreeSelection } from "./TreeSelection";
import { TreeStumpRemoval } from "./TreeStumpRemoval";
import { TreeTypeOfService } from "./TypeOfService";

export const treeServiceRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/service-type",
    component: TreeTypeOfService,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/complexity",
    component: Complexity,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/height-details",
    component: TreeHeightDetails,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/debris-haul-away",
    component: DebrisHaulAway,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/electric-nearby-tree-details",
    component: TreeDetailsNearElectric,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/electric-not-nearby-tree-details",
    component: TreeDetailsNotNearElectrical,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/tree-selection",
    component: TreeSelection,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/roots-removal",
    component: RootsRemoval,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/machinery-details",
    component: RootsRemovalMachine,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/stump-removal",
    component: TreeStumpRemoval,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/tree_services/order-summary",
    component: TreeOrderSummary,
  },
];
