import React from "react";

const ImageMapper = ({
  imageArray,
  handleViewMore,
  handleIndex,
  imageRefs,
}) => {
  let element = "";
  switch (imageArray?.length) {
    case 0:
      element = null;
    case 1:
      element = (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img
              style={{ borderRadius: "30px !important" }}
              src={imageArray[0]}
              onClick={() => handleIndex(0)}
              className="object-fill"
            />
          </div>
        </div>
      );
      break;
    case 2:
      element = (
        <div className="row d-flex">
          {imageArray?.map((image, index) => (
            <div className="col-6">
              <img
                style={{
                  borderRadius: "30px !important",
                  height: "440px",
                  width: "100%",
                }}
                className="img-fluid object-fill"
                src={image}
                onClick={() => handleIndex(index)}
              />
            </div>
          ))}
        </div>
      );
      break;
    case 3:
      element = (
        <div className="row d-flex flex-wrap">
          {imageArray?.map((image, index) => (
            <div className="col-4">
              <img
                style={{
                  borderRadius: "30px !important",
                  height: "330px",
                  width: "100%",
                }}
                className="img-fluid object-fill"
                src={image}
                onClick={() => handleIndex(index)}
              />
            </div>
          ))}
        </div>
      );
      break;
    case 4:
      element = (
        <div className="row d-flex flex-wrap">
          {imageArray?.map((image, index) => (
            <div className="col-6" style={{ padding: "7px" }}>
              <img
                style={{
                  borderRadius: "30px !important",
                  height: "300px",
                  width: "100%",
                }}
                className="img-fluid secondary-img object-fill"
                src={image}
                onClick={() => handleIndex(index)}
              />
            </div>
          ))}
        </div>
      );
      break;
    case 5:
      element = (
        <div className="row">
          <div className="col-lg-6 d-none d-lg-block p-0 pe-0">
            <img
              className="object-fill img-fluid primary-img"
              onClick={() => handleIndex(0)}
              src={imageArray[0]}
            />
          </div>
          <div
            className="d-none d-lg-block col-lg-6 s-img-container position-relative"
            style={{ padding: "0px 14px" }}
          >
            <div className="d-flex s-img-sub">
              {imageArray?.length > 1 ? (
                <div className="col-6" style={{ paddingRight: "7px" }}>
                  <img
                    src={imageArray?.[1]}
                    className={"object-fill img-fluid secondary-img"}
                    style={{ paddingBottom: "7px" }}
                    alt="contractor-imgs"
                    onClick={() => handleIndex(1)}
                  />
                </div>
              ) : (
                ""
              )}
              {imageArray?.length > 2 ? (
                <div className="col-6" style={{ paddingLeft: "7px" }}>
                  <img
                    src={imageArray?.[2]}
                    className={"object-fill img-fluid secondary-img"}
                    style={{ paddingBottom: "7px" }}
                    alt="contractor-imgs"
                    onClick={() => handleIndex(2)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex s-img-sub">
              {imageArray?.length > 3 ? (
                <div className="col-6" style={{ paddingRight: "7px" }}>
                  <img
                    src={imageArray?.[3]}
                    className="object-fill img-fluid secondary-img"
                    style={{ paddingTop: "7px" }}
                    alt="contractor-imgs"
                    onClick={() => handleIndex(3)}
                  />
                </div>
              ) : (
                ""
              )}
              {imageArray?.length > 4 ? (
                <div className="col-6" style={{ paddingLeft: "7px" }}>
                  <img
                    src={imageArray?.[4]}
                    className="object-fill img-fluid secondary-img"
                    style={{ paddingTop: "7px" }}
                    alt="contractor-imgs"
                    onClick={() => handleIndex(4)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
      break;
    default:
      element = (
        <div className="row">
          <div className="col-lg-6 d-none d-lg-block p-0 pe-0">
            <img
              className="object-fill img-fluid primary-img"
              onClick={() => handleIndex(0)}
              src={imageArray[0]}
            />
          </div>
          <div
            className="d-none d-lg-block col-lg-6 s-img-container position-relative"
            style={{ padding: "0px 14px" }}
          >
            <div className="d-flex s-img-sub">
              {imageArray?.length > 1 ? (
                <div className="col-6" style={{ paddingRight: "7px" }}>
                  <img
                    src={imageArray?.[1]}
                    className={"object-fill img-fluid secondary-img"}
                    style={{ paddingBottom: "7px" }}
                    alt="contractor-imgs"
                    onClick={() => handleIndex(1)}
                  />
                </div>
              ) : (
                ""
              )}
              {imageArray?.length > 2 ? (
                <div className="col-6" style={{ paddingLeft: "7px" }}>
                  <img
                    src={imageArray?.[2]}
                    className={"object-fill img-fluid secondary-img"}
                    style={{ paddingBottom: "7px" }}
                    alt="contractor-imgs"
                    onClick={() => handleIndex(2)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex s-img-sub">
              {imageArray?.length > 3 ? (
                <div className="col-6" style={{ paddingRight: "7px" }}>
                  <img
                    src={imageArray?.[3]}
                    className="object-fill img-fluid secondary-img"
                    style={{ paddingTop: "7px" }}
                    alt="contractor-imgs"
                    onClick={() => handleIndex(3)}
                  />
                </div>
              ) : (
                ""
              )}
              {imageArray?.length > 4 ? (
                <div className="col-6" style={{ paddingLeft: "7px" }}>
                  <img
                    src={imageArray?.[4]}
                    className="object-fill img-fluid secondary-img"
                    style={{ paddingTop: "7px" }}
                    alt="contractor-imgs"
                    onClick={() => handleIndex(4)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            {imageArray?.length > 5 ? (
              <div className="view-more" onClick={(e) => handleIndex(0)}>
                View {imageArray?.length - 5} more
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
      break;
  }
  return <>{element}</>;
};

export default ImageMapper;
