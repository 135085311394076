import React from "react";
import IMAGES from "./../../../_assets/images/index";
import { Footer } from "./../../../_components/footer/Footer";
import HeaderMenu from "./../../../_components/HeaderMenu/HeaderSection";
import "./../../../_styles/localStyles/profileCreate.css";
import "./../../../_components/radioButton/radioButton.css";
import ProgressBar from "./../../../_components/progressbar/ProgressBar";
import { Button } from "./../../../_components/Button/Button";
import { withTranslation } from "react-i18next";
import { checkoutStore } from "./../../../_redux/actions";
import { connect } from "react-redux";
import { TextInput } from "./../../../_components/TextInput/TextInput";

class CheckoutEditCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        cardName: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        cardNumber: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        expiryDate: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        securityCode: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
      },
    };
  }

  validate = (name, value, message) => {
    const newState = { ...this.state.formFields };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;

    switch (name) {
      case "cardName":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Card name is Required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;
      case "cardNumber":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Card number is Required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "expiryDate":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Card number is Required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;
      case "securityCode":
        if (!value || value === "" || value.length > 3) {
          errorMessage =
            errorMessage !== null
              ? errorMessage
              : "Please enter valid security code";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      default: {
        return newState;
      }
    }
  };
  onChangeHandler = (event, key) => {
    let validatedState = this.validate(key, event.target.value, undefined);
    this.setState({ formFields: validatedState });
  };

  /**
   * Calls when submitting a form
   */
  onSubmitForm = () => {
    const formValid = Object.values(this.state.formFields).every(
      (val) => val.valid === true
    );
    console.log(formValid);
    if (formValid) {
      const checkoutModel = this.props.checkout.payload;
      const cards = this.props.checkout.payload.cards;
      const cardTemp = {
        id: "address-" + new Date().getTime(),
        name: this.state.formFields.cardName.value,
        number: this.state.formFields.cardNumber.value,
        expiryDate: this.state.formFields.expiryDate.value,
        securityCode: this.state.formFields.securityCode.value,
      };

      cards.push(cardTemp);
      checkoutModel.cards = cards;
      this.props.checkoutStore(checkoutModel);
      this.props.history.goBack();
    } else {
      Object.values(this.state.formFields).forEach((el, i) => {
        if (!el.valid) {
          let keyValue = Object.keys(this.state.formFields)[i];
          var errorState = this.validate(keyValue, el.value, undefined);
          this.setState({ formFields: errorState });
          console.log(errorState);
        }
      });
    }
  };

  render = () => {
    const { t } = this.props;
    return (
      <div className="insideWrp">
        <HeaderMenu />
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className="paint_main_buttons">
            <div className="paint_main_button_left">
              <ProgressBar progressValue={6} />
              {/* <img src={IMAGES.other.stepper1} alt="" /> */}
              <div>
                <label className="paint_every_flow_title">
                  {"Step description "}
                  {/* {t("paintingInterior.Typeofwork")} */}
                </label>
              </div>
            </div>
            <div className="paint_main_button_right">
              <table>
                <tr>
                  <td>
                    <img src={IMAGES.other.piggy} alt="" />
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <label className="paint_every_price_txt">
                      {this.props.checkout.payload.totalCost
                        ? `$${this.props.checkout.payload.totalCost.toFixed(2)}`
                        : "--"}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="otherspadding25"></div>
          <div className="paintPersonalinfo_div paint_flex_with_center_personal">
            <table className=" ">
              <tr>
                <td>
                  <img src={IMAGES.other.personalvector} alt="" />
                </td>
              </tr>
              <tr>
                <td>
                  <label className="checkoutRatingstxtdetails">
                    {"You are protected!"}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="checkoutRatingstxtdetailssub">
                    {"Anyhnb will hold your funds and won't release to "}
                  </label>
                  <label className="checkoutRatingstxtdetailssub">
                    {" the contractor until your project is complete"}
                  </label>
                </td>
              </tr>
            </table>
          </div>

          <div>
            <div className="profilePadding30"></div>
            <div className="paint_flex_with_left otherspadding25">
              <label className="paint_header">{"Card details"}</label>
            </div>

            <div className="paintinput_lable">
              <TextInput
                className="paint_input_border"
                onChange={(event) => this.onChangeHandler(event, "cardName")}
                value={this.state.formFields.cardName.value}
                error={this.state.formFields.cardName.error}
                type={"text"}
                placeholder={t("John Doe")}
                label={t("Name on Card")}
              />
            </div>

            <div className="paintinput_lable">
              <TextInput
                className="paint_input_border"
                onChange={(event) => {
                  this.onChangeHandler(event, "cardNumber");
                }}
                value={this.state.formFields.cardNumber.value}
                error={this.state.formFields.cardNumber.error}
                type={"number"}
                placeholder={"XXXX XXXX XXXX XXXX"}
                label={"Card Number"}
              />
            </div>
            <div className="paintinput_lable">
              <TextInput
                className="paint_input_border"
                onChange={(event) => this.onChangeHandler(event, "expiryDate")}
                value={this.state.formFields.expiryDate.value}
                error={this.state.formFields.expiryDate.error}
                type={"text"}
                placeholder={t("XX/XX")}
                label={t("Expiration Date")}
              />
            </div>
            <div className="paintinput_lable">
              <TextInput
                className="paint_input_border"
                onChange={(event) =>
                  this.onChangeHandler(event, "securityCode")
                }
                value={this.state.formFields.securityCode.value}
                error={this.state.formFields.securityCode.error}
                type={"number"}
                placeholder={"123"}
                label={"Security Code"}
              />
            </div>
          </div>
          <div className="profilePadding30"></div>
          <Button
            onClick={this.onSubmitForm}
            disabled={false}
            buttonLabel={"Save"}
          />
          <div className="otherspadding25"></div>
          <div className="otherspadding25"></div>
          <div className="contactblue_btn ">
            <div>
              <img src={IMAGES.other.group1} alt="" />
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <label className="condactNeedhelp">
                      {t("paintingInterior.doneedhelp")}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td onClick={() => (window.location = "/contact-us")}>
                    {" "}
                    <label className="contactuslink">
                      {t("paintingInterior.contactus")}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="paintPolygon">
            <img src={IMAGES.other.polygon} alt="" />
          </div>
        </div>

        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(CheckoutEditCard));
