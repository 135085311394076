import IMAGES from "../../_assets/images/index";

const items = [
  {
    urls: IMAGES.home.bathroomSlider1,
  },
  {
    urls: IMAGES.home.bathroomSlider2,
  },
  {
    urls: IMAGES.home.bathroomSlider3,
  },
  {
    urls: IMAGES.home.bathroomSlider4,
  },
  {
    urls: IMAGES.home.bathroomSlider5,
  },
  {
    urls: IMAGES.home.bathroomSlider6,
  },
  {
    urls: IMAGES.home.bathroomSlider7,
  },
  {
    urls: IMAGES.home.bathroomSlider8,
  },
  {
    urls: IMAGES.home.bathroomSlider9,
  },
  {
    urls: IMAGES.home.bathroomSlider10,
  },
  {
    urls: IMAGES.home.bathroomSlider11,
  },
  {
    urls: IMAGES.home.bathroomSlider12,
  },
];
export default items;
