import { buttonConstant } from "../constants";

function checkoutStore(state) {
  return {
    type: buttonConstant.CHECKOUT_FORWARD,
    payload: state,
  };
}

export { checkoutStore };
