import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
//import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
//import { TextInput } from "../../../_components/TextInput/TextInput";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { TextInput } from "../../_components/TextInput/TextInput";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { checkoutStore, roofingStore } from "../../_redux/actions";
import { roofingModel } from "../../_redux/Models/roofing.model";
import { isInteger } from "lodash";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const NumberOfRepairsNeeded = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);
  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.roofingPayload
      ? state.buttonReducer.roofingPayload
      : roofingModel
  );

  const stepValue = state.serviceType === "repair" ? 2 : 3;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [option, setOption] = useState(""); // To keep the basement size in state
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state
  const [pricing, setPricing] = useState(0);

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;
    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          console.log(response.data);
          checkoutModelState.priceDetails = response.data.details;
          dispatch(checkoutStore(checkoutModelState));
          if (
            mounted === true &&
            response.data.details &&
            response.data.details.typeOfService
          ) {
            const serviceType = state.serviceType;
            const reasonForRoofing = state.reasonForRoofing;
            let pricing = 0;
            if (serviceType === "installation") {
              const pricingOptions =
                response.data.details.typeOfService[0].options;
              pricing = pricingOptions.find(
                (op) => op.key === reasonForRoofing
              ).price;
            } else {
              pricing = response.data.details.typeOfService.price;
            }
            console.log(pricing);
            setPricing(pricing);
          }
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state.reasonForRoofing, state.serviceType]);

  const onSave = () => {
    state.numberOfRepairsNeeded = option;
    state.costFor.repair = price;
    checkoutModelState.totalCost = price;
    let link = `/contractor-portfolio/${selectedContractorId}/roofing/removal-plywood`;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(roofingStore(state));
    history.push(link);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {"How many repairs do you need to do?"}
            </label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {"Please enter the quantity of repairs you need to do"}
            </label>
          </div>

          <div className="paintinput_lable mb-4">
            <TextInput
              className="paint_input_border"
              error={isInteger(option)}
              type="number"
              onChange={(e) => {
                const val = parseInt(e.target.value);
                setOption(val);
                setPrice(pricing * parseInt(val));
              }}
              placeholder={"Enter the number of repairs"}
              label={"Repairs"}
            />
            <div
              className="paint_placeholder_right"
              style={{ width: "105px", marginLeft: "-90px" }}
            >
              <label>{"repairs"}</label>
            </div>
          </div>

          <label className="paint_other_sub_header mb-2">
            {
              "Each repair should be of a maximum of 4 feet x 8 feet, if the dimensions of the repair exceed this measures, it will be considered as an additional repair."
            }
          </label>
          <Button
            disabled={!option}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSave}
          />
        </div>
      </div>{" "}
    </div>
  );
};
