import React from "react";
import "./Button.css";
import IMAGES from "../../_assets/images/index";

export const Buttonforward = (props) => {
  const { buttonLabel, disabled, onClick, className } = props;
  return (
    <div className="allCenterbtn arrow_gap">
      <button
        onClick={onClick}
        disabled={disabled}
        className={className || "btn-checkout"}
      >
        <span>{buttonLabel}</span>
        <img src={IMAGES.other.skipVector} alt="" />
      </button>
      
    </div>
  );
};
