import React, { useState } from "react";
import IMAGES from "../../../_assets/images/index";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from "../signUp/socialMediaSignup/googleLogin";
import FacebookLoginButton from "../signUp/socialMediaSignup/facebookLogin";
import "../../../_styles/localStyles/signupsignin.css";
import { Footer } from "../../../_components/footer/Footer";
import { Button } from "../../../_components/Button/Button";
function CheckMail(props) {
  const email = props.location.state.email;
  if (!email) {
    props.history.push(`/signin/recover-password`)
  }

  const [signInState, setSignInState] = useState({
    email: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },

  });
  const { t } = useTranslation();
  const buttonClick = () => {

    props.history.push(`/reset-password`);
  };
  const resendResetLink = () => {
    props.history.push(`/signin/recover-password`);
  };

  return (
    <div>
      <div className="pcWidth">
        <div className="signup_center_align">
          <table>
            <tr>
              <td>
                {/* <img
              src={IMAGES.other.vectors}
              alt=""
              onClick={() => arrowClick()}
            /> */}
              </td>
              <td>
                <label
                  className="signup_page_anyhnb_logo"
                  onClick={() => window.location.replace("/")}
                >
                  {t("signUp.mainHeading")}
                </label>
              </td>
            </tr>
          </table>
        </div>
        {/* <h3>Sign Up</h3>

      <div style={{ display: "flex", marginTop: "-32px" }}>
        {" "}
        <p>I already have an account</p>
        <p
          style={{ marginLeft: "6px", color: "#5B75FF", fontWeight: "600" }}
          onClick={() => signInClick()}
        >
          Sign In
        </p>{" "}
      </div> */}
        <label className="signup_header signup_flex_with_center">
          {t("checkMail.mailHeading")}
        </label>
        <table className="signincheckmail_table" >
          <tr>
            <td><label className="signin_checkmail_txt">{t("checkMail.subHeading1")}</label></td>
          </tr>
          <tr>
            <td  ><label className="signin_checkmail_bold">
              {`${email[0]}.....@${email.split("@")[1]}`}
            </label> <label className="signin_checkmail_txt">
                {t("checkMail.subHeading2")}
              </label></td>
          </tr>
          <tr>
            <td>
              <label className="signin_checkmail_txt">{t("checkMail.subHeading3")}</label>
            </td>
          </tr>
        </table>


        <div style={{ cursor: 'pointer' }} onClick={resendResetLink} className="signup_flex_with_center signup_validation_not_receive_link_padding">
          <span className="signup_validation_not_receive_link">
            {t("checkMail.notReceive")}
          </span>
        </div>
        {/* <div className="ssignin_rec_padding_top">
          <Button
            buttonLabel={t("signIn.continueNext")}
            onClick={buttonClick}
          />
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
export default CheckMail;
