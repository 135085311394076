import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { checkoutStore, treeStore } from "../../_redux/actions";
import { calculateTotalCost } from "../../services/utils/utilFunctions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { treeModel } from "../../_redux/Models/tree.model";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  getServiceCategoriesFull,
  getServicePriceDetails,
} from "../../services/api/category.api.service";
import Loader from "../../_components/spinner/Loader";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const TreeTypeOfService = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue = 1;

  const state = treeModel;

  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState("");
  const [subCategories, setSubCategories] = useState([]);

  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  useEffect(() => {
    let mounted = true;
    getServiceCategoriesFull().then((items) => {
      if (mounted && items.status === 200) {
        console.log(items.data);
        let filteredItem = items.data.find(
          (item) => item.name === "Tree services"
        );
        setSubCategories(filteredItem.subCategory);
        checkoutModelAs.selectedService = "Tree services";
        checkoutModelAs.totalCost = 0;
        dispatch(checkoutStore(checkoutModelAs));
      }
    });
    return () => (mounted = false);
  }, [checkoutModelAs, dispatch]);

  const onSave = (val) => {
    setIsLoading(true);
    checkoutModelAs.selectedServiceDetails = subCategories.find(
      (op) => op.key === val
    );
    const selectedService = checkoutModelAs.selectedServiceDetails;
    if (selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (response.status === 200 && response.data) {
          console.log(response.data);
          checkoutModelAs.priceDetails = response.data.details;
          state.serviceType = val;
          dispatch(treeStore(state));
          dispatch(checkoutStore(checkoutModelAs));
          if (val === "tree_remotion") {
            history.push(
              `/contractor-portfolio/${selectedContractorId}/tree_services/electric-nearby-tree-details`
            );
          } else if (val === "tree_trimming" || val === "fallen_tree_removal") {
            history.push(
              `/contractor-portfolio/${selectedContractorId}/tree_services/height-details`
            );
          } else if (val === "stump_removal") {
            history.push(
              `/contractor-portfolio/${selectedContractorId}/tree_services/stump-removal`
            );
          }
        }
      });
    }
  };
  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left mb-3">
            <label className="paint_header" style={{ fontSize: "19px" }}>
              {t("treeService.whatServiceNeed")}
            </label>
          </div>
          <div
            onClick={() => {
              setOption("tree_remotion");
              onSave("tree_remotion");
            }}
            className={`floor-selection-radio ${
              option === "tree_remotion" ? "selected" : ""
            }`}
          >
            <div
              // key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Tree removal</label>
            </div>
          </div>
          <div
            onClick={() => {
              setOption("tree_trimming");
              onSave("tree_trimming");
            }}
            className={`floor-selection-radio ${
              option === "tree_trimming" ? "selected" : ""
            }`}
          >
            <div
              // key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Tree trimming</label>
            </div>
          </div>

          <div
            onClick={() => {
              setOption("fallen_tree_removal");
              onSave("fallen_tree_removal");
            }}
            className={`floor-selection-radio ${
              option === "fallen_tree_removal" ? "selected" : ""
            }`}
          >
            <div
              // key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Fallen tree removal</label>
            </div>
          </div>

          <div
            // key={index}
            className="paintPadding8 "
          >
            <div
              onClick={() => {
                setOption("stump_removal");
                onSave("stump_removal");
              }}
              className={`floor-selection-radio ${
                option === "stump_removal" ? "selected" : ""
              }`}
            >
              <div
                // key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">Stump removal</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
