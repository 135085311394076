import { AddOn } from "./AddOn";
import { CurrentTypeOfFloor } from "./CurrentTypeOfFloor";
import { EpoxyOrderSummary } from "./EpoxyOrderSummary";
import { SizeOfFloor } from "./SizeOfFloor";
import { PersonalInfo } from "./personalInfo";

export const epoxyRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/epoxy/floor-size/:stepValue",
    component: SizeOfFloor,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/epoxy/current-floor-details/:stepValue",
    component: CurrentTypeOfFloor,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/epoxy/floor-addons/:stepValue",
    component: AddOn,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/epoxy/personal-info/:stepValue",
    component: PersonalInfo,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/epoxy/order-summary/:stepValue",
    component: EpoxyOrderSummary,
  },
];
