import React, { useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorCarpetModel } from "../../../_redux/Models/Floor.model";
import { removalOfFloorPopup } from "../Common/RemovalOfFloor.popup";
import { useDispatch, useSelector } from "react-redux";
import { floorStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const ServiceType = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [haveOwnCarpet, setHaveOwnCarpet] = useState("");
  const [needPaddingReplacement, setNeedPaddingReplacement] = useState("");
  const [showRemovalFloorPopup, setShowRemovalFloorPopup] = useState(false);

  const carpetState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorCarpetModel
  );

  const price = calculateTotalCost(carpetState.costFor); // To keep the total price in state

  // To show carpet service questions
  const carpetOptions = [
    {
      text: t("floor.owncarpet"),
      value: "yes",
    },
    {
      text: t("floor.provide"),
      value: "no",
    },
  ];

  // To show padding options
  const paddingOptions = [
    {
      text: t("floor.replacement"),
      value: "yes",
    },
    {
      text: t("floor.noneed"),
      value: "no",
    },
  ];

  /**
   * Function to save Selected Service Type and take user to next step
   * @returns {void}
   */
  const onSaveServiceType = () => {
    carpetState.needCarpet = haveOwnCarpet === "no";
    carpetState.needPaddingReplacement = needPaddingReplacement === "yes";
    dispatch(floorStore(carpetState));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/carpet/carpet-quality/3`
    );
    // if (carpetState.needCarpet) {
    //   props.history.push(`/contractor-portfolio/${selectedContractorId}/floors/carpet/carpet-quality/`");
    // } else if (carpetState.needPaddingReplacement) {
    //   // go to padding quality
    //   props.history.push(`/contractor-portfolio/${selectedContractorId}/floors/carpet/padding-quality`3");
    // } else {
    //   // show prior floor removal Popup
    //   setShowRemovalFloorPopup(true);
    // }
  };

  /**
   * On prior floor removal click yes
   * @returns {void}
   */
  const onPriorFloorRemovalYesClick = () => {
    floorCarpetModel.priorFloorRemovalRequired = true;
    dispatch(floorStore(floorCarpetModel));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/carpet/current-floor/3`
    );
  };

  /**
   * On prior floor removal click no
   * @returns {void}
   */
  const onPriorFloorRemovalNoClick = () => {
    setShowRemovalFloorPopup(false);
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/carpet/personal-info/6`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">{t("floor.carpet")}</label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("floor.letus")}
            </label>
          </div>
          {carpetOptions.map((item, index) => (
            <div
              className={`floor-selection-radio ${
                haveOwnCarpet === item?.value ? "selected" : ""
              }`}
              key={index}
              onClick={() => setHaveOwnCarpet(item.value)}
            >
              <div key={index} className="width100">
                <label className="paintdiv_txt"> {item.text} </label>
              </div>
            </div>
          ))}
          <div className="paint_flex_with_left mt-4 mb-3">
            <label className="paint_header">{t("floor.alsoneed")}</label>
          </div>
          {paddingOptions.map((item, index) => (
            <div
              className={`floor-selection-radio ${
                needPaddingReplacement === item?.value ? "selected" : ""
              }`}
              onClick={() => setNeedPaddingReplacement(item.value)}
            >
              <div key={index} className="width100">
                <label className="paintdiv_txt"> {item.text} </label>
              </div>
            </div>
          ))}
          <Button
            disabled={haveOwnCarpet === "" || needPaddingReplacement === ""}
            buttonLabel={t("paintingHomes.continue")}
            onClick={onSaveServiceType}
          />
        </div>
      </div>

      {removalOfFloorPopup({
        isOpen: showRemovalFloorPopup,
        onYesClick: onPriorFloorRemovalYesClick,
        onCloseAction: onPriorFloorRemovalNoClick,
        onNoClick: onPriorFloorRemovalNoClick,
      })}
    </div>
  );
};
