import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, electricStore } from "../../_redux/actions";
import { getServiceCategoriesFull } from "../../services/api/category.api.service";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { electricModel } from "../../_redux/Models/electric.model";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const ElectricTypeofService = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const price = calculateTotalCost(electricModel.costFor); // To keep the total price in state
  const [option, setOption] = useState("");
  const [subCategories, setSubCategories] = useState([]);

  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  useEffect(() => {
    let mounted = true;
    getServiceCategoriesFull().then((items) => {
      if (mounted && items.status === 200) {
        let filteredItem = items.data.find((item) => item.name === "Electric");
        setSubCategories(filteredItem.subCategory);
        checkoutModelAs.selectedService = "Electric";
        checkoutModelAs.totalCost = 0;
        dispatch(checkoutStore(checkoutModelAs));
      }
    });
    return () => (mounted = false);
  }, [checkoutModelAs, dispatch]);

  const onSave = (val) => {
    checkoutModelAs.selectedServiceDetails = subCategories.find(
      (op) => op.key === val
    );
    electricModel.serviceType = val;
    let link = "/";
    if (val === "installation") {
      link = `/contractor-portfolio/${selectedContractorId}/electric/replace-or-new/2`;
    } else if (val === "repairs") {
      link = `/contractor-portfolio/${selectedContractorId}/electric/select-items/2`;
    } else if (val === "diagnose") {
      electricModel.costFor.diagnose = 150;
      link = `/contractor-portfolio/${selectedContractorId}/electric/order-summary/2`;
    }
    dispatch(checkoutStore(checkoutModelAs));
    dispatch(electricStore(electricModel));
    history.push(link);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {"What type of electrical service do you need?"}
            </label>
          </div>
          <div
            // key={index}
            className="paintPadding8 "
          >
            <div
              onClick={() => {
                setOption("installation");
                onSave("installation");
              }}
              className={`floor-selection-radio ${
                option === "installation" ? "selected" : ""
              }`}
            >
              <div
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">
                  Installations/replacements
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setOption("repairs");
              onSave("repairs");
            }}
            className={`floor-selection-radio ${
              option === "repairs" ? "selected" : ""
            }`}
          >
            <div
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Repairs</label>

              <div
                className="paint_arrowFloat"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              ></div>
            </div>
          </div>
          <div
            onClick={() => {
              setOption("diagnose");
              onSave("diagnose");
            }}
            className={`floor-selection-radio ${
              option === "diagnose" ? "selected" : ""
            }`}
          >
            <div
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Diagnose</label>

              <div
                className="paint_arrowFloat"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="paintdiv_txt paddingleft8"
                  style={{ paddingLeft: "7px", paddingTop: "2px" }}
                >
                  {`$${formatPrice(150)}`}
                </label>
              </div>
            </div>
          </div>
          <div className="profilePadding10"></div>
          {option === "diagnose" ? (
            <span>
              Don't worry, we will deduct the price of the diagnose from the
              cost of the repair
            </span>
          ) : null}
        </div>
      </div>{" "}
    </div>
  );
};
