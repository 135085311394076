import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, hvacStore } from "../../_redux/actions";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { hvacModel } from "../../_redux/Models/hvac.model";
import { TextInput } from "../../_components/TextInput/TextInput";
import { CannotContinueModal } from "../floorComponents/Common/CannotContinue.modal";
import { DropDown } from "../../_components/dropDown/dropDown";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const HvacSizeDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const stepValue = 4;
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);
  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.hvacPayload
      ? state.buttonReducer.hvacPayload
      : hvacModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [size, setSize] = useState(1); // To keep the basement size in state
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state
  const [sizePricingOptions, setSizePricingOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails) {
      setSizePricingOptions(priceDetails.typeOfUnit);
    }
    return () => (mounted = false);
  }, [checkoutModelState, t]);

  const calculatePrice = (sizeAs) => {
    let sizeTemp = sizeAs || size;
    const total = calculateTotalCost(state.costFor);
    if (sizeTemp > 1) {
      const existingOrNew = state.existingOrNew;
      const priceForChosenSize = sizePricingOptions.find(
        (op) => op.key === `${sizeTemp}_tons`
      );
      let priceToAdd = 0;
      if (existingOrNew === "replace-existing") {
        priceToAdd = priceForChosenSize.replacementServicePrice;
      } else if (existingOrNew === "installing-new") {
        priceToAdd = priceForChosenSize.installationServicePrice;
      }

      setPrice(total + priceToAdd);
    } else {
      setPrice(total);
    }
  };

  const onSave = () => {
    const total = calculateTotalCost(state.costFor);
    state.existingSystemSize = size;
    state.costFor.service = price - total;
    dispatch(hvacStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    if (state.serviceType === "replacement") {
      history.push(
        `/contractor-portfolio/${selectedContractorId}/hvac/select-brand/6`
      );
    }
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left mb-3">
            <label className="paint_header" style={{ fontSize: "19px" }}>
              {"How many tons is your existing HVAC system?"}
            </label>
          </div>
          <div class="paint_flex_with_left mb-3">
            <label class="paint_other_sub_header ">
              {
                "Please introduce the tons of your HVAC system using “.” for decimals (eg. 2.5 tons)"
              }
            </label>
          </div>
          <div className="paintinput_lable mb-3 rltve_cls selectField">
            <div className="label">Sizing</div>
            <div
              className="typeofTxt"
              style={{ display: "grid", paddingTop: "0" }}
            >
              <select
                className={"dropDown"}
                placeholder="TONS"
                value={size}
                onChange={(e) => {
                  if (e.target.value) {
                    setSize(e.target.value);
                    calculatePrice(e.target.value);
                  }
                }}
              >
                {[1.5, 2, 2.5, 3, 3.5, 4, 5].map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            <i onClick={() => setIsOpen(true)}>i</i>
            <div className="paint_placeholder_right tons">
              <label>{"TONS"}</label>
            </div>
          </div>
          <label>{"Tons according to property size:"}</label>
          <ul className="mb-3">
            <li>1.5 tons = 600 - 1000 square feet</li>
            <li>2 tons = 1000 - 1300 square feet</li>
            <li>2.5 tons = 1300 - 1600 square feet</li>
            <li>3 tons = 1600 - 1900 square feet</li>
            <li>3.5 tons = 1900 - 2200 square feet</li>
            <li>4 tons = 2200 - 2600 square feet</li>
            <li>5 tons = 2600 - 3200 square feet</li>
          </ul>
          <Button
            disabled={size <= 1}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSave}
          />
        </div>
      </div>
      {CannotContinueModal({
        isOpen: isOpen,
        hideContractorIcon: false,
        reason: (
          <img
            //
            className="shapes-image"
            src={IMAGES.other.hvacSizeDetails}
            alt=""
          />
        ),
        onCloseAction: () => setIsOpen(false),
        onNoClick: () => setIsOpen(false),
      })}
    </div>
  );
};
