import React, { useRef } from "react";
import { Footer } from "../../../_components/footer/Footer";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import {
  getContractor,
  projectList,
} from "../../../services/api/contractor.service";
import IMAGES from "../../../_assets/images/index";
import { Button } from "../../../_components/Button/Button";
import { withTranslation } from "react-i18next";
import Calendar from "react-calendar";

import {
  checkoutStore,
  updateContractorProject,
  updateTenatativeDetails,
} from "../../../_redux/actions";
import { connect } from "react-redux";
import moment from "moment";
import Loader from "../../../_components/spinner/Loader";
import { cardStatus, getprojectheader, getStatusText } from "./contractorUtils";
import { getuserDetails } from "../../../services/apiConstants";

class ContractorProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "pending",
      isLoading: false,
      isError: false,
      projects: [],
      filteredProjects: [],
      selectedDates: [],
      contractor: {},
      userDetails: getuserDetails(),
      status: "pending",
    };
  }

  /**
   * Gets the contractor from api
   */
  getContractorDetails = async (id) => {
    return getContractor(id);
  };

  getProjectList = (status) => {
    this.setState({ status, projects: [] });

    this.setState({ isLoading: true });
    projectList(this.state.userDetails._id, status).then((response) => {
      if (response) {
        const projects = response.data;
        const selectedDates = [];
        projects.forEach((project) => {
          selectedDates.push(moment(project.tentativeStartDate).toDate());
        });
        console.log(selectedDates);
        this.setState({
          projects: projects,
          filteredProjects: projects,
          isLoading: false,
          selectedDates: selectedDates,
        });

        if (this.props.history.location.search) {
          const id = this.props.history.location.search.split("=")[1];
          const project = projects.find((project) => project._id === id);
          if (!project?._id) return;

          this.props.updateContractorProject(project);
          this.props.updateTenatativeDetails({
            startDate: project.tentativeStartDate,
            endDate: project.tentativeEndDate,
            time: {
              text: "",
              time: project.tentativeTimeRange,
            },
          });
          this.props.history.push("/contractor-project-preview");
        }
      }
    });
  };

  componentDidMount = () => {
    sessionStorage.removeItem("presentStatus");
    if (!this.state.userDetails) {
      this.props.history.push("/");
    } else {
      this.getProjectList(this.state.status);
    }
  };

  filterProjectsByDate = (date) => {
    let totalProjects = [...this.state.projects];
    if (date !== "all") {
      totalProjects = totalProjects.filter(
        (project) =>
          moment(project.tentativeStartDate).format("YYYY-MM-DD") ===
          moment(date).format("YYYY-MM-DD")
      );
    }
    this.setState({ filteredProjects: totalProjects });
  };

  render = () => {
    const { t } = this.props;
    return (
      <div className="insideWrp">
        <Loader progress={this.state.isLoading} />
        <HeaderMenu />
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className="allCenter">
            {/* Tabs start */}
            <div className="tabs contractor-tabs">
              <div className="tab-header">
                <div
                  onClick={() =>
                    this.setState(
                      { activeTab: "active" },
                      this.getProjectList("scheduled")
                    )
                  }
                  className={`${
                    this.state.activeTab === "active" ? "active" : ""
                  } menuheadertxt`}
                >
                  Scheduled{" "}
                  {this.state.projects.length > 0 &&
                    this.state.status === "scheduled" &&
                    `(${this.state.projects.length})`}
                </div>
                <div
                  onClick={() =>
                    this.setState(
                      { activeTab: "pending" },
                      this.getProjectList("pending")
                    )
                  }
                  className={`${
                    this.state.activeTab === "pending" ? "active" : ""
                  } menuheadertxt`}
                >
                  Pending{" "}
                  {this.state.projects.length > 0 &&
                    this.state.status === "pending" &&
                    `(${this.state.projects.length})`}
                </div>
                <div
                  onClick={() =>
                    this.setState(
                      { activeTab: "history" },
                      this.getProjectList("historical")
                    )
                  }
                  className={`${
                    this.state.activeTab === "history" ? "active" : ""
                  } menuheadertxt`}
                >
                  History
                  {this.state.projects.length > 0 &&
                    this.state.status === "historical" &&
                    `(${this.state.projects.length})`}
                </div>
                <div className="tab-indicator "></div>
              </div>

              <div className="tab-body">
                <div className="active"></div>
                <div></div>
                <div></div>
              </div>
            </div>
            {/* Tabs end */}
          </div>

          {/* Each project */}
          {this.state.isLoading ? (
            <div>LOADING PLEASE WAIT....</div>
          ) : this.state.isError ? (
            <div>ERROR</div>
          ) : (
            <div>
              {this.state.selectedDates &&
              this.state.activeTab !== "history" ? (
                <div className="project-list-date-list">
                  <Calendar
                    // ref={dateEl}
                    open={true}
                    keepmounted
                    // selectRange={true}
                    onChange={(val) => this.filterProjectsByDate(val)}
                    tileClassName={({ date, view }) => {
                      if (
                        this.state.selectedDates.find((x) => {
                          return (
                            moment(x).format("YYYY-MM-DD") ===
                            moment(date).format("YYYY-MM-DD")
                          );
                        })
                      ) {
                        return "date-selected-highlight";
                      }
                    }}
                  />
                  <span
                    style={{ color: "skyblue", padding: "15px" }}
                    onClick={() => this.filterProjectsByDate("all")}
                  >
                    See all projects
                  </span>
                </div>
              ) : null}
              {this.state.filteredProjects.length > 0 ? (
                this.state.filteredProjects.map((project) => {
                  return (
                    <>
                      <div>
                        <label
                          className="header_path_name_show grn-txt"
                          style={{
                            color: `${
                              project.status === "confirm"
                                ? "#5B75FF"
                                : "#5FCA5D"
                            }`,
                          }}
                        >
                          {/* <span>
                            Booked:{"   "}
                            {moment(project.createdAt).format("MM/DD/YYYY")}
                          </span> */}
                          {/* <br />
                          <span>
                            Start date:{"   "}
                            {moment(project.tentativeStartDate).format(
                              "MM/DD/YYYY"
                            )}
                          </span> */}
                          {this.state.status === "scheduled" && (
                            <label
                              className="approvalTxt"
                              style={{
                                fontSize: "14px",
                                margin: "0 0 0 7px",
                              }}
                            >
                              {cardStatus(project.status).header}
                            </label>
                          )}
                        </label>
                        <div className="padding10"></div>
                        <div className="divborderMyproject contractor-poject">
                          <div
                            className="myproject_radio_div_first1 exteriorDivpadding  "
                            style={{ padding: "0" }}
                          >
                            <table style={{ borderSpacing: "0" }}>
                              <tr>
                                <td>
                                  <div
                                    className="grid"
                                    style={{ padding: "20px" }}
                                  >
                                    <label className="picColorroom1_radioHeader">
                                      {project.serviceDetails.data.flowName &&
                                        getprojectheader(
                                          project.serviceDetails.data.flowName,
                                          project.serviceDetails.data
                                            .serviceType
                                        )}
                                    </label>
                                    <label className="paintdiv_txt three-lines-wrap">
                                      {project.ownerAddress}
                                    </label>
                                    <span className="header_path_name_show">
                                      Start date:{"   "}
                                      {moment(
                                        project.tentativeStartDate
                                      ).format("MM/DD/YYYY")}
                                    </span>
                                    <label className="myprojecttxtblue">
                                      <label
                                        className={`approvalTxt ${project.status}`}
                                      >
                                        {cardStatus(project.status).subHeader}
                                      </label>
                                    </label>
                                  </div>
                                </td>

                                <td
                                  className={`hoverColorChange ${
                                    project.status == "check_in" &&
                                    "active-home-icon"
                                  }`}
                                  width="90px"
                                  style={{ borderTopRightRadius: "16px" }}
                                >
                                  <div
                                    className="paint_arrowFloat "
                                    style={{
                                      float: "none",
                                      textAlign: "center",
                                    }}
                                  >
                                    <img
                                      className="check-home-icon"
                                      src={IMAGES.other.house}
                                      style={{ width: "35px" }}
                                      alt=""
                                    />
                                    <label>Check In</label>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div
                          className="divborderMyprojectbottom"
                          style={{ marginTop: "3px" }}
                        >
                          <Button
                            disabled={false}
                            buttonLabel={t("View project details")}
                            onClick={() => {
                              this.props.updateContractorProject(project);
                              this.props.updateTenatativeDetails({
                                startDate: project.tentativeStartDate,
                                endDate: project.tentativeEndDate,
                                time: {
                                  text: "",
                                  time: project.tentativeTimeRange,
                                },
                              });
                              this.props.history.push(
                                "/contractor-project-preview"
                              );
                            }}
                            className="btn-checkouttick"
                          />
                        </div>
                        <div className="paddinngtop30"></div>
                      </div>
                      <div></div>
                    </>
                  );
                })
              ) : (
                <span>No Project available</span>
              )}
            </div>
          )}

          {/* End each project */}
        </div>
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
  updateContractorProject,
  updateTenatativeDetails,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(ContractorProjectList));
