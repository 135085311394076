import IMAGES from "../../_assets/images/index";
const dreammakerSliderItems = [
  {
    urls: IMAGES.home.Bestpriceguaranteed,
    text: "Best price guaranteed",
  },
  {
    urls: IMAGES.home.Simplicity,
    text: "Simplicity ",
  },
  {
    urls: IMAGES.home.Designandinnovation,
    text: "Design and innovation",
  },
  {
    urls: IMAGES.home.Topnotchquality,
    text: "Top notch quality",
  },

];
export default dreammakerSliderItems