import React from "react";
import IMAGES from "../../_assets/images/index";
import { useHistory } from "react-router-dom";
import { signupModel } from "../../_redux/Models/auth.models";
import "./footer.css";
import { continuetoNext } from "../../_redux/actions";
import { useDispatch } from "react-redux";
export const Footer = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const homeClick = () => {
    history.push("/");
  };
  const profileClick = () => {
    history.push("/role");
  };
  const contractorClick = () => {
    signupModel.signuserType = "contractor";
    dispatch(continuetoNext(signupModel));

    history.push("/signup");
  };
  const termsconditionClick = () => {
    history.push("/terms-and-condition");
  };
  const contactClick = () => {
    window.location = "/contact-us";
  };
  return (
    <div className="MainFooter">
      <div className="flex" style={{ marginTop: "50px" }}>
        <div className="left_div">
          <div
            className="flex"
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              fontSize: "20px",
              lineHeight: "40px",
              color: "black",
            }}
          >
            <h2
              className="heading"
              style={{
                marginLeft: "116px",
                fontSize: "25px",
                marginBottom: "10px",
                marginRight: "42px",
              }}
            >
              Anyhnb
            </h2>
            <ul className="footer-list" style={{ margin: "0 auto" }}>
              <li
                style={{
                  display: "inline-block",
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={() => homeClick()}
              >
                Home
              </li>
              <li
                style={{
                  display: "inline-block",
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={() => profileClick()}
              >
                Create a profile
              </li>
              <li
                style={{
                  display: "inline-block",
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={() => contractorClick()}
              >
                Become a contractor
              </li>
              <li
                style={{
                  display: "inline-block",
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={() => termsconditionClick()}
              >
                Terms & conditions
              </li>
              <li
                style={{
                  display: "inline-block",
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={() => contactClick()}
              >
                Contact Us
              </li>
            </ul>
          </div>
          <br />
          <br />
          <br />
          <div
            className="copy-text"
            style={{
              marginTop: "15px",
              fontFamily: "Poppins",
              fontSize: "12px",
              position: "absolute",
              marginLeft: "71px",
              color: "#D8D8D8",
            }}
          >
            {" "}
            <span>All rights reserved - Anyhnb </span>
            <img
              style={{
                marginTop: "13px",
                top: "3px",
                position: "relative",
              }}
              src={IMAGES.home.atSymbol}
              alt=""
            />
          </div>
        </div>
        <div className="right_div margin-cls">
          <div style={{ margin: "0 auto", marginTop: "70px" }}>
            <img width="35" src={IMAGES.home.instagram} alt="" />
            <br />
            <br />
            <img
              width="23"
              style={{ marginLeft: "5px" }}
              src={IMAGES.home.fb}
              alt=""
            />
          </div>
        </div>
      </div>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://api.whatsapp.com/send?phone=13055080862"
      >
        <span className="whtsap-btn">
          <img width="80" src={IMAGES.home.whatsapp} alt="" />
        </span>
      </a>
    </div>
  );
};
