import IMAGES from "../../_assets/images/index";
 const rusticSliderItems = [
  {
    urls: IMAGES.home.bathroomRustic1,
  },
  {
    urls: IMAGES.home.bathroomRustic2,
  },
  {
    urls: IMAGES.home.bathroomRustic3,
  },  
];
export default rusticSliderItems