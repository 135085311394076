import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../../_components/HeaderMenu/HeaderSection";
import { TextInput } from "../../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../../_assets/images/index";
import { Footer } from "../../../../_components/footer/Footer";

import { parseJwt } from "../../../../services/utils/utilFunctions";
import {
  ownerProfile,
  updateOwnerProfile,
} from "../../../../services/api/owner.service";
import { getContractor } from "../../../../services/api/contractor.service";
import { uploadProfile } from "../../../../services/api/signup.service";
import { useSnackbar } from "notistack";
import Loader from "../../../../_components/spinner/Loader";
import CustomPhoneInput from "../../../../_components/PhoneInput/PhoneInput";

const getProfile = {
  owner: ownerProfile,
  contractor: getContractor,
};

const updateProfile = {
  owner: updateOwnerProfile,
  contractor: uploadProfile,
};

const formatContractor = (data) => {
  const {
    contractorId: { name = "", email = "", mobileNumber = "" },
    zipCode = "",
  } = data.data;
  return {
    name: name,
    email: email,
    mobileNumber: mobileNumber,
    zipCode: zipCode,
  };
};

const formatOwner = (data) => {
  const {
    userId: { name = "", email = "" },
    zipCode = "",
    mobileNumber = "",
  } = data.data;
  return {
    name: name,
    email: email,
    mobileNumber: mobileNumber,
    zipCode: zipCode,
  };
};
const getFormatFunction = {
  owner: formatOwner,
  contractor: formatContractor,
};

export const EditOwnerProfile = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const snackBar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultPhone, setDefaultPhone] = useState("");

  const [personalInfo, setPersonalInfo] = useState({
    name: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    email: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    mobileNumber: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    fullAddress: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    zipCode: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
  });

  useEffect(() => {
    let mounted = true;
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (mounted === true && user && user._id) {
      const getProfileAPI = getProfile[user.userType];
      setIsLoading(true);
      getProfileAPI(user._id).then((data) => {
        const { name, email, mobileNumber, zipCode } =
          getFormatFunction[user.userType](data);
        if (
          data.data &&
          ((user.userType === "owner" && data.data) ||
            (user.userType === "contractor" && data.data))
        ) {
          setDefaultPhone(mobileNumber);
          const stateObj = {
            name: {
              valid: name !== "",
              value: name,
              error: {
                flag: null,
                message: "",
              },
            },
            email: {
              valid: email !== "",
              value: email,
              error: {
                flag: null,
                message: "",
              },
            },
            mobileNumber: {
              valid: mobileNumber !== "",
              value: mobileNumber,
              error: {
                flag: null,
                message: "",
              },
            },
            zipCode: {
              valid: zipCode !== "",
              value: zipCode,
              error: {
                flag: null,
                message: "",
              },
            },
          };
          setPersonalInfo(stateObj);
          setIsLoading(false);
        }
      });
    }
    return () => (mounted = false);
  }, []);

  const validate = (name, value, message) => {
    const newState = { ...personalInfo };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;
    var regexpEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    switch (name) {
      case "name":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Full Name is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "email":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Email is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (!value.match(regexpEmail)) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Please enter a valid Email";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value.match(regexpEmail)) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "mobileNumber":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Phone number is Required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "fullAddress":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Address is Required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      default: {
        return newState;
      }
    }
  };
  const onChangeHandler = (event, key) => {
    let validatedState = validate(key, event.target.value, undefined);
    setPersonalInfo(validatedState);
  };

  /**
   * Calls when submitting a form
   */
  const onSubmitForm = () => {
    const formValid = Object.values(personalInfo).every(
      (val) => val.valid === true
    );
    if (formValid) {
      setIsLoading(true);
      const user = parseJwt(localStorage.getItem("auth_token"));
      let formData = {
        name: personalInfo.name.value,
        email: personalInfo.email.value,
        zipCode: personalInfo.zipCode.value,
      };
      if (user.userType === "owner")
        formData["mobileNumber"] = personalInfo.mobileNumber.value;
      if (user.userType === "contractor")
        formData["phoneNumber"] = personalInfo.mobileNumber.value;

      const updateUser = updateProfile[user.userType];

      updateUser(user._id, formData)
        .then((res) => {
          snackBar.closeSnackbar();
          const variant = "success";
          snackBar.enqueueSnackbar("Profile updated successfully!", {
            variant,
            transitionDuration: { exit: 10, appear: 100 },
          });
        })
        .catch((err) => {
          snackBar.closeSnackbar();
          const variant = "error";
          snackBar.enqueueSnackbar("Failed to update profile", {
            variant,
            transitionDuration: { exit: 10, appear: 100 },
          });
        })
        .finally((_) => {
          setTimeout(() => {
            history.goBack(-1);
          }, 1000);
        });
    } else {
      Object.values(personalInfo).forEach((el, i) => {
        if (!el.valid) {
          let keyValue = Object.keys(personalInfo)[i];
          var errorState = validate(keyValue, el.value, undefined);
          setPersonalInfo(errorState);
        }
      });
    }
  };

  return (
    <div className="insideWrp">
      <HeaderMenu />
      <Loader progress={isLoading} />
      <div
        style={{
          padding: "0px 20px 20px 20px",
          maxWidth: "535px",
          margin: "auto",
          marginTop: "30px",
        }}
      >
        <div className="paint_flex_with_left otherspadding25">
          <label className="paint_header">{t("personalInfo.personal")}</label>
        </div>

        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "name")}
            value={personalInfo.name.value}
            error={personalInfo.name.error}
            type={"text"}
            placeholder={t("paintingother.plzname")}
            label={t("personalInfo.name")}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "email")}
            value={personalInfo.email.value}
            error={personalInfo.email.error}
            placeholder={t("paintingother.plzemail")}
            label={t("paintingother.email")}
          />
        </div>
        <div className="paintinput_lable">
          <CustomPhoneInput
            valueState={personalInfo}
            setValueState={setPersonalInfo}
            name="mobileNumber"
            className="paint_input_border"
            placeholder={t("paintingother.plzphone")}
            label={t("paintingother.phone")}
            defaultValue={defaultPhone}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "zipCode")}
            value={personalInfo.zipCode.value}
            error={personalInfo.zipCode.error}
            placeholder={t("zipCode")}
            label={t("zipCode")}
          />
        </div>

        <label
          onClick={onSubmitForm}
          className="paint_home_form_btn cursor_hand"
          style={{
            cursor: "pointer",
          }}
        >
          {"update"}
        </label>
        <div className="d-flex w-100 justify-content-center">
          <a href="/delete-account" className="text-danger mx-auto text-center">
            Delete Account
          </a>
        </div>

        {/* <div className="contactblue_btn ">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td onClick={() => window.location="/contact-us"}>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div> */}
        {/* <div className="paintPolygon">
          <img src={IMAGES.other.polygon} alt="" />
        </div> */}
      </div>

      <Footer />
    </div>
  );
};
