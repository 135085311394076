import React, { useState, useContext } from "react";
import IMAGES from "../../../_assets/images/index";
import { useTranslation } from "react-i18next";
import "../../../_styles/localStyles/signupsignin.css";
import { useDispatch } from "react-redux";
import { continuetoNext } from "../../../_redux/actions";
import { signupActions } from "../../../_redux/actions";
import { Footer } from "../../../_components/footer/Footer";
import { signupModel } from "../../../_redux/Models/auth.models";
function SignupRoleSelection(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const items = [
    {
      itemID: "c",
      heading: t("signUp.contractor_signUp"),
      subHeading: t("signUp.contractor_signUp_subtext"),
    },
    {
      itemID: "o",
      heading: t("signUp.owner_signUp"),
      subHeading: t("signUp.owner_signUp_subtext"),
    },
  ];

  const [state, setstate] = useState(items);
  const arrowClick = (item) => {
    if (item.itemID === "c") {
      signupModel.signuserType = "contractor";
      dispatch(continuetoNext(signupModel));
      props.history.push(`/signup`);
    } else {
      signupModel.signuserType = "owner";
      dispatch(continuetoNext(signupModel));
      props.history.push(`/property`);
    }
  };
  const backClick = () => {
    props.history.push(`/`);
  };
  const signInClick = () => {
    props.history.push(`/signIn`);
  };

  return (
    <div className="pcWidth">
      <div className="signup_center_align">
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  src={IMAGES.other.vectors}
                  alt=""
                  onClick={() => backClick()}
                />
              </td>
              <td>
                <label
                  className="signup_page_anyhnb_logo"
                  onClick={() => window.location.replace("/")}
                >
                  {t("signUp.mainHeading")}
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <label className="signup_header signup_flex_with_center">
        {t("signUp.signUpHeading")}
      </label>

      <div className="signup_flex_with_center">
        {" "}
        <label className="signup_sub_heading">{t("signUp.subHeading")}</label>
        <label className="signup_sign_linkbtn" onClick={() => signInClick()}>
          {t("signUp.signUp_link")}
        </label>{" "}
      </div>

      <div>
        <div className="signup_section">
          {state.map((item, index) => (
            <div key={index}>
              <div
                className="signup_home_section"
                onClick={() => arrowClick(item)}
              >
                <div>
                  <div
                    className="signup_first_div_header"
                    onClick={() => arrowClick(item)}
                  >
                    {item.heading}
                  </div>
                  <div
                    className="signup_second_div_header"
                    onClick={() => arrowClick(item)}
                  >
                    {item.subHeading}
                  </div>
                </div>
                <a className="signup_editBtn">
                  <img src={IMAGES.other.black_vector} alt="" />
                </a>
                {/* <img src={IMAGES.other.black_vector} alt="" /> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    // </div>
  );
}
export default SignupRoleSelection;
