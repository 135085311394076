import { initializeApp, } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken, isSupported } from "firebase/messaging";
import { NOTIFICATION_TITLE } from '../../_configs/constants.config';
// import { parseJwt } from "../../services/utils/utilFunctions";

export class FireBase {

    async initializeFireBase() {
        const browserSupport = await isSupported()
        if (!browserSupport) return;

        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        };

        const app = initializeApp(firebaseConfig);

        const messaging = getMessaging(app);

        const token = await this.getFirebaseToken();
        const broadcast = new BroadcastChannel('count-channel');

        onMessage(messaging, async (payload) => {
            console.log(payload, '==================firebase service');
            let notificationTitle = '';
            let notificationBody = '';
            if (payload.data && payload.data.title) {
                notificationTitle = NOTIFICATION_TITLE[payload.data.type];
                notificationBody = payload.data.text
                broadcast.postMessage({
                    type: 'UPDATE_DOT'
                });
            } else if (payload.notification && payload.notification.title) {
                notificationTitle = payload.notification.title;
                notificationBody = payload.notification.body
                broadcast.postMessage({
                    type: 'UPDATE_DOT'
                });
            } else {
                notificationTitle = 'New message';
                notificationBody = payload.data.twi_body

                broadcast.postMessage({
                    type: 'NEW_MESSAGE'
                });
                window.conversationsClient.handlePushNotification(payload);
            }

            const notificationOptions = {
                body: notificationBody,
                icon: "https://via.placeholder.com/350x150"
            };
            navigator.serviceWorker.ready.then(() => {

                const noti = new Notification(notificationTitle, notificationOptions) //registration.showNotification(notificationTitle, notificationOptions)
                noti.onclick = () => {
                    broadcast.postMessage({
                        type: 'NOTIFICATION_CLICK',
                        payload: payload
                    });
                }
            });
        })
        return { token, messaging };
    }


    getFirebaseToken = () => {
        try {
            const messaging = getMessaging();
            const vapidKey = "BO-mqV8Kwfvx0_syyRhTkbVTqXvySiUI7LZJj-LHrSfe6qCbrs-BqFk0EOCNxzNPjQY0nFfJ2NUq6wAKW07eCVs"
            return getToken(messaging, { vapidKey: vapidKey });
        } catch (e) {
            console.error('firebase unsubscribe error', e)
        }
    }

    deleteToken = async () => {
        try {
            const messaging = getMessaging();
            if (messaging) await deleteToken(messaging);
        } catch (e) {
            console.log('there was an unexpected error', e)
        }
    }
}