import React, { useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorEngineeredModel } from "../../../_redux/Models/Floor.model";
import { useDispatch, useSelector } from "react-redux";
import { floorStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { removalOfFloorPopup } from "../Common/RemovalOfFloor.popup";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const NeedFloorInstall = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [showRemovalFloorPopup, setShowRemovalFloorPopup] = useState(false);

  const [needEngineeredFloorInstall, setNeedEngineeredFloorInstall] =
    useState("");
  const floorState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorEngineeredModel
  );

  const price = calculateTotalCost(floorState.costFor); // To keep the total price

  // To show carpet service questions
  const carpetOptions = [
    {
      text: t("floor.pleaseProvideFloor"),
      value: "yes",
    },
    {
      text: t("floor.iHaveMyOwnFloor"),
      value: "no",
    },
  ];

  /**
   * On prior floor removal click yes
   * @returns {void}
   */
  const onPriorFloorRemovalYesClick = () => {
    floorState.priorFloorRemovalRequired = true;
    dispatch(floorStore(floorState));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/engineered/current-floor-details/3`
    );
  };

  /**
   * On prior floor removal click no
   * @returns {void}
   */
  const onPriorFloorRemovalNoClick = () => {
    setShowRemovalFloorPopup(false);
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/engineered/floor-addons/4`
    );
  };

  /**
   * Function to save Selected Service Type and take user to next step
   * @returns {void}
   */
  const onSaveServiceType = (value) => {
    floorState.needEngineeredFloorInstall = value === "yes";
    dispatch(floorStore(floorState));
    if (value === "yes") {
      props.history.push(
        `/contractor-portfolio/${selectedContractorId}/floors/engineered/floor-size-details/3`
      );
    } else {
      setShowRemovalFloorPopup(true);
    }
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("floor.doYouHaveFloorToInstall")}
            </label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("floor.letUsKnowFloorToInstall")}
            </label>
          </div>
          <div className="padding10"></div>
          {carpetOptions.map((item, index) => (
            <div key={index} className="paintPadding8 ">
              <div
                className="floor-selection-radio"
                onClick={() => {
                  setNeedEngineeredFloorInstall(item.value);
                  onSaveServiceType(item?.value);
                }}
              >
                <div key={index} className="width100">
                  <label className=""> {item.text} </label>
                </div>
              </div>
            </div>
          ))}

          <div className="profilePadding40"></div>
        </div>
      </div>
      {removalOfFloorPopup({
        isOpen: showRemovalFloorPopup,
        onYesClick: onPriorFloorRemovalYesClick,
        onCloseAction: onPriorFloorRemovalNoClick,
        onNoClick: onPriorFloorRemovalNoClick,
      })}
    </div>
  );
};
