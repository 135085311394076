import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { bathroomStore, checkoutStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { bathroomFlowModel } from "../../_redux/Models/bathroom.model";
import {
  calculateBathroomCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const BathroomNeedShowerDoor = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;
  const bathroomId =
    props.match && props.match.params ? props.match.params.bathroomId : false;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.bathroomPayload
      ? state.buttonReducer.bathroomPayload
      : bathroomFlowModel
  );

  const bathroom = state.bathroomsDetails.find(
    (baths) => baths.id === bathroomId
  );

  if (!bathroom) {
    history.replace("/bathroom");
    window.location.reload();
  }

  const [price, setPrice] = useState(
    calculateBathroomCost(state.bathroomsDetails)
  ); // To keep the total price in state

  const [options, setOptions] = useState([]);
  const [doorOptions, setDoorOptions] = useState([]);
  const [option, setOption] = useState("");
  const [doorOption, setDoorOption] = useState("");

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const size = bathroom.size;
    const installationOnlyPrice = priceDetails.showerDoor.find(
      (m) => m.key === "installation_only"
    ).price;

    const doorOptionsArr = priceDetails.showerDoor.find(
      (m) => m.key === "provision_and_installation"
    ).options;
    const doorOptionsArrModified = doorOptionsArr.map((op) => {
      return {
        text: t(`Bathroom.${op.key}`),
        key: op.key,
        value: op.key,
        price: op.price,
      };
    });
    setDoorOptions(doorOptionsArrModified);
    if (mounted === true && priceDetails) {
      const optionArr = [
        {
          text: t("Bathroom.showerDoorProvideAndInstall"),
          key: "provideAndInstall",
          value: "provideAndInstall",
          price: "",
        },
        {
          text: t("Bathroom.showerDoorInstallOnly"),
          key: "installOnly",
          value: "installOnly",
          price: installationOnlyPrice,
        },
        {
          text: t("Bathroom.showerDoorNoNeed"),
          key: "noNeed",
          value: "noNeed",
          price: 0,
        },
      ];
      setOptions(optionArr);
    }
    return () => (mounted = false);
  }, [bathroom.size, checkoutModelState, t]);

  const onSave = () => {
    bathroom.costFor.showerDoor =
      price - calculateBathroomCost(state.bathroomsDetails);
    bathroom.showerDoorAvailability = option;
    bathroom.showerDoorTypeNeeded = doorOption;
    state.bathroomsDetails.filter((bt) => bt.id !== bathroom.id).push(bathroom);
    dispatch(bathroomStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/bathroom/${bathroomId}/add-ons/7`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">
            {t("Bathroom.requireShowerDoor")}
          </label>
        </div>
        <div className="paint_flex_with_left ">
          <label className="paint_other_sub_header ">
            {t("Bathroom.requireShowerDoorDetails")}
          </label>
        </div>
        <div className="padding10"></div>
        {options.map((item, index) => (
          <div
            className={`floor-selection-radio ${
              option === item?.value ? "selected" : ""
            }`}
            onClick={() => {
              const total = calculateBathroomCost(state.bathroomsDetails);
              setOption(item.value);
              setPrice(total + item.price);
            }}
          >
            <div
              key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label
                className="paintdiv_txt pc_txt"
                style={{
                  width: "150px",
                }}
              >
                {item.text}
              </label>
              <div
                className="paint_arrowFloat"
                style={{
                  width: "130px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  {item.price !== "" ? (
                    <div>
                      <img src={IMAGES.other.green_plus} alt="" />
                      <label className="paintdiv_txt">
                        {`$${formatPrice(item.price)}`}
                      </label>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="mb-4">
          <span>{t("Bathroom.showerDoorNoteOne")}</span>
        </div>

        {/* Sliding door details */}
        {option === "provideAndInstall" ? (
          <div>
            <div className="paint_flex_with_left">
              <label className="paint_header">
                {t("Bathroom.whatTypeOfDoor")}
              </label>
            </div>
            <div className="paint_flex_with_left ">
              <label className="paint_other_sub_header ">
                {t("Bathroom.whatTypeOfDoorDetails")}
              </label>
            </div>
            <div className="padding10"></div>
            {doorOptions.map((item, index) => (
              <div
                className={`floor-selection-radio ${
                  doorOption === item?.value ? "selected" : ""
                }`}
                onClick={() => {
                  const total = calculateBathroomCost(state.bathroomsDetails);
                  setDoorOption(item.value);
                  setPrice(total + item.price);
                }}
                style={{
                  height: "70px",
                }}
              >
                <div
                  key={index}
                  className="width100"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    className="paintdiv_txt"
                    style={{
                      width: "65%",
                    }}
                  >
                    {item.text}
                  </label>
                  <div
                    className="paint_arrowFloat"
                    style={{
                      width: "35%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    {item.price !== "" ? (
                      <div style={{ display: "flex" }}>
                        <img src={IMAGES.other.green_plus} alt="" />
                        <label className="paintdiv_txt">
                          {`$${formatPrice(item.price)}`}
                        </label>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
        {/* End Sliding door details */}
        <Button
          disabled={!option || (option === "provideAndInstall" && !doorOption)}
          buttonLabel={t("paintingHomes.continue")}
          onClick={onSave}
        />
      </div>
    </div>
  );
};
