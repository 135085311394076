import moment from "moment";
import { history } from "../auth/history";

export function goToURL(params) {
  history.push(params);
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const checkAuth = (props) => {
  const authToken = localStorage.getItem("auth_token");
  if (!authToken) {
    props.history.replace("/role");
  }
};

/**
 *
 * @param {Object} costFor
 * @returns
 */
export const calculateTotalCost = (costFor, except = []) => {
  var total = 0;
  for (const key in costFor) {
    if (Object.hasOwnProperty.call(costFor, key)) {
      const element = costFor[key];
      if (!except.includes(key)) {
        total = total + element;
      }
    }
  }
  return parseFloat(total) ? parseFloat(total) : 0;
};

/**
 *
 * @param {Object} costFor
 * @returns
 */
export const calculateBathroomCost = (numOfBathRooms) => {
  var total = 0;
  for (let i = 0; i < numOfBathRooms.length; i++) {
    const costForT = numOfBathRooms[i].costFor;
    for (const key in costForT) {
      if (Object.hasOwnProperty.call(costForT, key)) {
        const element = costForT[key];
        total = total + element;
      }
    }
  }

  return parseFloat(total) ? parseFloat(total) : 0;
};

/**
 *
 * @param {Object} costFor
 * @returns
 */
export const calculateCostFromArr = (costForArr) => {
  var total = 0;
  for (let i = 0; i < costForArr.length; i++) {
    const costForT = costForArr[i].costFor;
    for (const key in costForT) {
      if (Object.hasOwnProperty.call(costForT, key)) {
        const element = costForT[key];
        total = total + element;
      }
    }
  }

  return parseFloat(total) ? parseFloat(total) : 0;
};

// Generates a new array grouped by the key given
export const groupBy = (arr, key) => {
  return arr.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

/**
 * To get pictures of work as img urls only
 * @param {Array} picturesOfWork
 */
export const extractImageUrlsFromPicOfWork = (picturesOfWork) => {
  const imgUrls = [];
  for (let i = 0; i < picturesOfWork.length; i++) {
    const element = picturesOfWork[i];
    if (element.images) {
      for (let j = 0; j < element.images.length; j++) {
        const img = element.images[j];
        imgUrls.push(img);
      }
    }
  }
  return imgUrls;
};

/**
 *
 * @param {Float} price
 * @returns String
 */
export const formatPrice = (price) => {
  return parseFloat(price)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getChatUserDetails = (id, conversation) => {
  if (id === conversation.ownerID) {
    return [
      {
        id: conversation.contractorID,
        name: conversation.contractorName,
        image: conversation.contractorImage,
      },
      {
        id: conversation.ownerID,
        name: conversation.ownerName,
        image: conversation.ownerImage,
      },
    ];
  } else {
    return [
      {
        id: conversation.ownerID,
        name: conversation.ownerName,
        image: conversation.ownerImage,
      },
      {
        id: conversation.contractorID,
        name: conversation.contractorName,
        image: conversation.contractorImage,
      },
    ];
  }
};

export const formatOwnerFullAddress = (addressObj) => {
  return (
    <span>{`${addressObj?.fullAddress}
   
  \n ${addressObj?.city},
  \n ${addressObj?.state},
  \n ${addressObj?.zip}
  \n ${addressObj?.addressDetails ? `${addressObj?.addressDetails},` : ""}
  \n ${addressObj?.comments ? `${addressObj?.comments}` : ""}
  `}</span>
  );
};

export const getMaterialTaxFor = (servicePayload) => {
  const flowName = servicePayload?.flowName;
  const costFor = servicePayload?.costFor;
  let materialCost = 0;
  let taxPercentage = 0.085;
  switch (flowName) {
    case "floor-solidHardwood":
      materialCost = costFor.materialSize;
      break;

    case "floor-engineered":
      materialCost = costFor.materialQuality;
      break;

    case "floor-carpet":
      materialCost = costFor.materialQuality;
      break;

    case "exterior":
      materialCost = costFor.paint + costFor.primer + costFor.coat;
      break;

    case "window-flow":
      materialCost = costFor.material;
      break;

    case "roofing-flow":
      materialCost =
        servicePayload.serviceType === "repair"
          ? costFor.plywoodChange
          : costFor.roof;
      break;

    case "hvac-flow":
      materialCost =
        servicePayload.serviceType === "replacement"
          ? costFor.addOns
          : costFor.material + costFor.addOns;
      break;
    case "electric-flow":
      let electricAddOnsRecessedLight = servicePayload.addOns.find(
        (op) => op.value === "recessed_lights"
      );
      let electricRecessedLightCost = electricAddOnsRecessedLight
        ? electricAddOnsRecessedLight.price * electricAddOnsRecessedLight.count
        : 0;
      materialCost = electricRecessedLightCost * 0.5;
      break;

    case "painting-interior":
      materialCost =
        servicePayload.typeOfWork === "fullHouse"
          ? costFor.paint
          : servicePayload.roomDetails
              .map((room) => room.costFor.paint)
              .reduce((partialSum, a) => partialSum + a, 0);
      break;

    case "kitchen-flow":
      let materials = costFor.cabinet + costFor.counterTop;
      let addOnsTile = servicePayload.addOns.find(
        (op) => op.value === "tile_blacksplash_labor_and_materials"
      );
      let addOnsRecessedLight = servicePayload.addOns.find(
        (op) => op.value === "recessed_lights"
      );
      let addOnTileCost = addOnsTile ? addOnsTile.price * addOnsTile.count : 0;
      let recessedLightCost = addOnsRecessedLight
        ? addOnsRecessedLight.price * addOnsRecessedLight.count
        : 0;
      addOnTileCost = addOnTileCost * 0.5; // 50% is labour
      recessedLightCost = recessedLightCost * 0.5; // 50% is labour
      materialCost = materials + addOnTileCost + recessedLightCost;
      break;

    case "bathroom":
      const materialRequiredCost = servicePayload.bathroomsDetails
        .map((bathroom) => bathroom.costFor.material)
        .reduce((partialSum, a) => partialSum + a, 0);

      const slidingDoorPrice = servicePayload.bathroomsDetails
        .map((bathroom) => bathroom.costFor.showerDoor)
        .reduce((partialSum, a) => partialSum + a, 0);

      const fiftyPercentageAddonsArr = servicePayload.bathroomsDetails.map(
        (bathroom) => {
          let adds = bathroom.addOns.filter((add) =>
            [
              "new_lighting",
              "bench_square",
              "bench_corner",
              "new_exhaust_fan",
              "upgrade_tiles_to_marbel",
              "heated_floor_system",
              "install_sump_pump",
            ].includes(add.value)
          );
          return adds.map((d) => d);
        }
      );
      const fiftyPercentageAddons = [].concat
        .apply([], fiftyPercentageAddonsArr)
        .reduce((partialSum, el) => partialSum + el.price, 0);

      const thirtyPercentageAddonsArr = servicePayload.bathroomsDetails.map(
        (bathroom) => {
          let adds = bathroom.addOns.filter((add) =>
            [
              "plumbing_relocations",
              "tiles_around_tub",
              "electrical_fixes",
            ].includes(add.value)
          );
          return adds.map((d) => d);
        }
      );
      const thirtyPercentageAddons = [].concat
        .apply([], thirtyPercentageAddonsArr)
        .reduce((partialSum, el) => partialSum + el.price, 0);
      materialCost =
        materialRequiredCost +
        slidingDoorPrice * 0.5 +
        fiftyPercentageAddons * 0.5 +
        thirtyPercentageAddons * 0.3;
      break;

    default:
      materialCost = 0;
      break;
  }
  const finalTax = materialCost ? parseFloat(materialCost) * taxPercentage : 0;

  return finalTax;
};

export const getFullAddress = (address) => {
  let fullAddress = "";
  if (address?.fullAddress) fullAddress += address?.fullAddress;
  if (address?.city) fullAddress += `, ${address?.city}`;
  if (address?.state) fullAddress += `, ${address?.state}`;
  if (address?.zip) fullAddress += `, ${address?.zip}`;
  return fullAddress;
};

export const viewMoreAddress = (address) => {
  let fullAddress = "";
  if (address?.addressDetails) fullAddress += `${address.addressDetails}`;
  if (address?.comments) fullAddress += `, ${address.comments}`;
  return fullAddress;
};

/**
 * Creates a download link from the URL given
 * @param {String} url
 * @param {String} fileName
 * @returns void
 */
export const downloadImgFrom = (url, fileName = "image") => {
  fetch(url, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const urlAs = window.URL.createObjectURL(new Blob([buffer]));
        let extension = url
          .slice(((url.lastIndexOf(".") - 1) >>> 0) + 2)
          .trim();
        const link = document.createElement("a");
        link.href = urlAs;
        link.setAttribute("download", `${fileName}.${extension}`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
      window.open(url, "_blank").focus(); // if failed lets open the image in a new tab
    });
};

export const timestampToTime = (timestamp) => {
  const now = new Date().getTime();
  const nowDate = moment
    .unix(now.toString().length === 13 ? now / 1000 : now)
    .format("MM/DD");
  let date = moment
    .unix(timestamp.toString().length === 13 ? timestamp / 1000 : timestamp)
    .format("MM/DD");
  if (date === "Invalid date") {
    date = "";
  }
  return nowDate === date
    ? moment
        .unix(timestamp.toString().length === 13 ? timestamp / 1000 : timestamp)
        .format("HH:mm")
    : date;
};

export const handleEnterPress = (event, callback) => {
  if (event.key === "Enter") {
    callback();
  }
};
