import { signupConstants } from "../constants";
const signupReducer = (state = null, action) => {
  switch (action.type) {
    case signupConstants.SIGNUP_NEXT_CLICK:
      return { ...state, user: action.payload };
    case signupConstants.SIGNUP_NEXT_CLEAR:
      return { ...state, user: action.payload };
    case signupConstants.SIGNUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        userRegister: null,
      };
    case signupConstants.SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userRegister: action.payload,
      };
    case signupConstants.SIGNUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        userRegister: null,
      };
    default:
      return state;
  }
};

export default signupReducer;
