import React from "react";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/profileCreate.css";
import "../../_components/radioButton/radioButton.css";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { withTranslation } from "react-i18next";
import { checkoutStore } from "../../_redux/actions";
import { connect } from "react-redux";
import { TextInput } from "../../_components/TextInput/TextInput";
import { formatPrice, parseJwt } from "../../services/utils/utilFunctions";
import {
  ownerRegisteredAddresses,
  saveOwnerAddress,
} from "../../services/api/owner.service";
import { getData } from "../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../_configs/constants.config";
import Loader from "../../_components/spinner/Loader";
import {
  getContractorRegisteredAddresses,
  saveContractorAddress,
} from "../../services/api/contractor.service";

class CheckoutAddressDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        fullName: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        fullAddress: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        phoneNumber: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        zipCode: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        city: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        state: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        addressDetails: {
          valid: true,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        comments: {
          valid: true,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.getUserInfo();
    const auth_token = localStorage.getItem("auth_token");
  };

  /**
   * User info
   */
  getUserInfo = () => {
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);
    if (userDetails.userType === "owner") {
      this.getOwnerAddresses(userDetails);
    } else if (userDetails.userType === "contractor") {
      this.getContractorAddresses(userDetails);
    }

    // this.setState({ user: userDetails }, this.getAddresses);
  };

  getOwnerAddresses = (userDetails) => {
    ownerRegisteredAddresses(userDetails._id).then((data) => {
      this.processAddressData(data);
    });
  };
  getContractorAddresses = (userDetails) => {
    getContractorRegisteredAddresses(userDetails._id).then((data) => {
      this.processAddressData(data);
    });
  };

  /**
   * User info
   */
  processAddressData = (data) => {
    if (data.status === 200 && data.data && data.data?.address?.length > 0) {
      const address = data.data.address[0];
      const addressData = {
        fullName: {
          valid: typeof address.fullName !== "undefined",
          value: address.fullName,
          error: {
            flag: null,
            message: "",
          },
        },
        fullAddress: {
          valid: typeof address.fullAddress !== "undefined",
          value: address.fullAddress,
          error: {
            flag: null,
            message: "",
          },
        },
        phoneNumber: {
          valid: typeof address.phoneNumber !== "undefined",
          value: address.phoneNumber,
          error: {
            flag: null,
            message: "",
          },
        },
        zipCode: {
          valid: typeof address.zip !== "undefined",
          value: address.zip,
          error: {
            flag: null,
            message: "",
          },
        },
        city: {
          valid: typeof address.city !== "undefined",
          value: address.city,
          error: {
            flag: null,
            message: "",
          },
        },
        state: {
          valid: typeof address.state !== "undefined",
          value: address.state,
          error: {
            flag: null,
            message: "",
          },
        },
        addressDetails: {
          valid: typeof address.addressDetails !== "undefined",
          value: address.addressDetails,
          error: {
            flag: null,
            message: "",
          },
        },
        comments: {
          valid: typeof address.comments !== "undefined",
          value: address.comments,
          error: {
            flag: null,
            message: "",
          },
        },
      };
      this.setState({ formFields: addressData });
    }

    // this.setState({ user: userDetails }, this.getAddresses);
  };

  validate = (name, value, message) => {
    const newState = { ...this.state.formFields };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;

    switch (name) {
      case "fullName":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Full Name is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "state":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "State is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "city":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "City is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "phoneNumber":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "phone Number is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "zipCode":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Zip code is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "fullAddress":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Address is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;
      // case "cardName":
      //   if (!value || value === "") {
      //     errorMessage =
      //       errorMessage !== null ? errorMessage : "Card name is Required";
      //     newState[name].error.flag = true;
      //     newState[name].valid = false;
      //     newState[name].error.message = errorMessage;
      //   } else {
      //     errorMessage = errorMessage !== null ? errorMessage : "";
      //     newState[name].error.flag = false;
      //     newState[name].valid = true;
      //     newState[name].error.message = "";
      //   }
      //   return newState;
      // case "cardNumber":
      //   if (!value || value === "") {
      //     errorMessage =
      //       errorMessage !== null ? errorMessage : "Card number is Required";
      //     newState[name].error.flag = true;
      //     newState[name].valid = false;
      //     newState[name].error.message = errorMessage;
      //   } else {
      //     errorMessage = errorMessage !== null ? errorMessage : "";
      //     newState[name].error.flag = false;
      //     newState[name].valid = true;
      //     newState[name].error.message = "";
      //   }
      //   return newState;

      // case "expiryDate":
      //   if (!value || value === "") {
      //     errorMessage =
      //       errorMessage !== null ? errorMessage : "Card number is Required";
      //     newState[name].error.flag = true;
      //     newState[name].valid = false;
      //     newState[name].error.message = errorMessage;
      //   } else {
      //     errorMessage = errorMessage !== null ? errorMessage : "";
      //     newState[name].error.flag = false;
      //     newState[name].valid = true;
      //     newState[name].error.message = "";
      //   }
      //   return newState;
      // case "securityCode":
      //   if (!value || value === "" || value.length > 3) {
      //     errorMessage =
      //       errorMessage !== null
      //         ? errorMessage
      //         : "Please enter valid security code";
      //     newState[name].error.flag = true;
      //     newState[name].valid = false;
      //     newState[name].error.message = errorMessage;
      //   } else {
      //     errorMessage = errorMessage !== null ? errorMessage : "";
      //     newState[name].error.flag = false;
      //     newState[name].valid = true;
      //     newState[name].error.message = "";
      //   }
      //   return newState;

      default: {
        return newState;
      }
    }
  };
  onChangeHandler = (event, key) => {
    let validatedState = this.validate(key, event.target.value, undefined);
    this.setState({ formFields: validatedState });
  };

  /**
   * Calls when submitting a form
   */
  onSubmitForm = () => {
    const formValid = Object.values(this.state.formFields).every(
      (val) => val.valid === true
    );
    console.log(formValid);
    if (formValid) {
      const checkoutModel = this.props.checkout.payload;
      const addresses = this.props.checkout.payload.addresses;
      const user = getData(LOCAL_STORAGE.user);

      const temp = {
        id: "address-" + new Date().getTime(),
        fullName: this.state.formFields.fullName.value,
        fullAddress: this.state.formFields.fullAddress.value,
        phoneNumber: this.state.formFields.phoneNumber.value,
        zip: this.state.formFields.zipCode.value,
        city: this.state.formFields.city.value,
        state: this.state.formFields.state.value,
        addressDetails: this.state.formFields.addressDetails.value,
        comments: this.state.formFields.comments.value,
      };
      addresses.push(temp);

      checkoutModel.addresses = addresses;
      checkoutModel.ownerId = user._id;
      checkoutModel.ownerName = user.name;
      checkoutModel.ownerImage = IMAGES.home.hdmanSlide;
      // const cards = this.props.checkout.payload.cards;
      // const cardTemp = {
      //   id: "address-" + new Date().getTime(),
      //   name: this.state.formFields.cardName.value,
      //   number: this.state.formFields.cardNumber.value,
      //   expiryDate: this.state.formFields.expiryDate.value,
      //   securityCode: this.state.formFields.securityCode.value,
      // };

      // cards.push(cardTemp);
      // checkoutModel.cards = cards;
      this.props.checkoutStore(checkoutModel);
      this.saveUserAddress(temp);
    } else {
      Object.values(this.state.formFields).forEach((el, i) => {
        if (!el.valid) {
          let keyValue = Object.keys(this.state.formFields)[i];
          var errorState = this.validate(keyValue, el.value, undefined);
          this.setState({ formFields: errorState });
          console.log(errorState);
        }
      });
    }
  };

  saveUserAddress = (address) => {
    const payload = {
      address: address,
    };
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);
    console.log(userDetails);
    if (userDetails && userDetails._id) {
      if (userDetails.userType === "owner") {
        saveOwnerAddress(userDetails._id, payload).then((data) => {
          this.props.history.push(`/checkout/payment-method`);
        });
      } else if (userDetails.userType === "contractor") {
        saveContractorAddress(userDetails._id, payload).then((data) => {
          this.props.history.push(`/checkout/payment-method`);
        });
      }
    }
  };

  render = () => {
    const { t } = this.props;
    return (
      <div className="insideWrp">
        <HeaderMenu />

        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className="paint_main_buttons">
            <div className="paint_main_button_left">
              <ProgressBar progressValue={6} />
              {/* <img src={IMAGES.other.stepper1} alt="" /> */}
              <div>
                <label className="paint_every_flow_title">
                  {"Step description "}
                  {/* {t("paintingInterior.Typeofwork")} */}
                </label>
              </div>
            </div>
            <div className="paint_main_button_right">
              <table>
                <tr>
                  <td>
                    <img src={IMAGES.other.piggy} alt="" />
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <label className="paint_every_price_txt">
                      {this.props.checkout.payload.totalCost > 0
                        ? `$${formatPrice(
                            this.props.checkout.payload.totalCost
                          )}`
                        : "--"}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="otherspadding25"></div>
          <div className="paintPersonalinfo_div paint_flex_with_center_personal">
            <table className=" ">
              <tr>
                <td>
                  <img src={IMAGES.other.personalvector} alt="" />
                </td>
              </tr>
              <tr>
                <td>
                  <label className="checkoutRatingstxtdetails">
                    {"You are protected!"}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="checkoutRatingstxtdetailssub">
                    {"Anyhnb will hold your funds and won't release to "}
                  </label>
                  <label className="checkoutRatingstxtdetailssub">
                    {" the contractor until your project is complete"}
                  </label>
                </td>
              </tr>
            </table>
          </div>
          <div className="paint_flex_with_left otherspadding25">
            <label className="paint_header">{"Checkout"}</label>
          </div>

          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(event) => this.onChangeHandler(event, "fullName")}
              value={this.state.formFields.fullName.value}
              error={this.state.formFields.fullName.error}
              type={"text"}
              placeholder={t("paintingother.plzname")}
              label={t("paintingother.fullname")}
            />
          </div>
          <table className="paint_table100 profilePadding30">
            <tr>
              <td className="padding_8">
                <div className="">
                  <TextInput
                    className="paint_input_border"
                    type={"text"}
                    placeholder={"Enter Address"}
                    label={"Full Address*"}
                    onChange={(event) =>
                      this.onChangeHandler(event, "fullAddress")
                    }
                    value={this.state.formFields.fullAddress.value}
                    error={this.state.formFields.fullAddress.error}
                  />
                </div>
              </td>
              <td>
                <div className="">
                  <TextInput
                    className="paint_input_border"
                    type={"number"}
                    placeholder={"12345"}
                    label={"Zip Code*"}
                    onChange={(event) => this.onChangeHandler(event, "zipCode")}
                    value={this.state.formFields.zipCode.value}
                    error={this.state.formFields.zipCode.error}
                  />
                </div>
              </td>
            </tr>
          </table>
          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              type={"text"}
              onChange={(event) => this.onChangeHandler(event, "city")}
              value={this.state.formFields.city.value}
              error={this.state.formFields.city.error}
              placeholder={"Enter the city"}
              label={"City*"}
            />
          </div>
          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(event) => this.onChangeHandler(event, "phoneNumber")}
              value={this.state.formFields.phoneNumber.value}
              error={this.state.formFields.phoneNumber.error}
              type={"number"}
              placeholder={t("paintingother.plzphone")}
              label={t("paintingother.phone")}
            />
          </div>
          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(event) => this.onChangeHandler(event, "state")}
              value={this.state.formFields.state.value}
              error={this.state.formFields.state.error}
              type={"text"}
              placeholder={"Enter the state"}
              label={"State*"}
            />
          </div>
          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(event) =>
                this.onChangeHandler(event, "addressDetails")
              }
              value={this.state.formFields.addressDetails.value}
              error={this.state.formFields.addressDetails.error}
              type={"text"}
              placeholder={"Enter additional details"}
              label={"Address details (Apartment # or Building name)"}
            />
          </div>
          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(event) => this.onChangeHandler(event, "comments")}
              value={this.state.formFields.comments.value}
              error={this.state.formFields.comments.error}
              type={"text"}
              placeholder={"“Please do not let the dog out. He is friendly”"}
              label={"Comments about your address"}
            />
          </div>
          {/* <div>
            <div className="profilePadding30"></div>
            <div className="paint_flex_with_left otherspadding25">
              <label className="paint_header">{"Card details"}</label>
            </div>

            <div className="paintinput_lable">
              <TextInput
                className="paint_input_border"
                onChange={(event) => this.onChangeHandler(event, "cardName")}
                value={this.state.formFields.cardName.value}
                error={this.state.formFields.cardName.error}
                type={"text"}
                placeholder={t("John Doe")}
                label={t("Name on Card")}
              />
            </div>

            <div className="paintinput_lable">
              <TextInput
                className="paint_input_border"
                onChange={(event) => {
                  this.onChangeHandler(event, "cardNumber");
                }}
                value={this.state.formFields.cardNumber.value}
                error={this.state.formFields.cardNumber.error}
                type={"number"}
                placeholder={"XXXX XXXX XXXX XXXX"}
                label={"Card Number"}
              />
            </div>
            <div className="paintinput_lable">
              <TextInput
                className="paint_input_border"
                onChange={(event) => this.onChangeHandler(event, "expiryDate")}
                value={this.state.formFields.expiryDate.value}
                error={this.state.formFields.expiryDate.error}
                type={"text"}
                placeholder={t("XX/XX")}
                label={t("Expiration Date")}
              />
            </div>
            <div className="paintinput_lable">
              <TextInput
                className="paint_input_border"
                onChange={(event) =>
                  this.onChangeHandler(event, "securityCode")
                }
                value={this.state.formFields.securityCode.value}
                error={this.state.formFields.securityCode.error}
                type={"number"}
                placeholder={"123"}
                label={"Security Code"}
              />
            </div>
          </div> */}
          <div className="profilePadding30"></div>
          <Button
            onClick={this.onSubmitForm}
            disabled={false}
            buttonLabel={"Continue"}
          />
          <Button
            onClick={() => this.props.history.push("/")}
            disabled={false}
            buttonLabel={"Cancel Order"}
            className="btn-checkouttick"
          />
          <div className="otherspadding25"></div>
          <div className="otherspadding25"></div>
          <div className="contactblue_btn ">
            <div>
              <img src={IMAGES.other.group1} alt="" />
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <label className="condactNeedhelp">
                      {t("paintingInterior.doneedhelp")}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td onClick={() => (window.location = "/contact-us")}>
                    {" "}
                    <label className="contactuslink">
                      {t("paintingInterior.contactus")}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="paintPolygon">
            <img src={IMAGES.other.polygon} alt="" />
          </div>
        </div>

        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(CheckoutAddressDetails));
