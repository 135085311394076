/**
 * @author Geethananth M
 * Model Object to hold the WallOpening Categories
 */

/**
 * Model For WallOpening
 */
export const wallOpeningModel = {
  flowName: "wallOpening-flow",
  serviceType: "",
  size: 0, // basement Size in SQFT
  height: 0,
  isItLoadBearingWall: "",
  hasBasement: "",
  openingType: "",
  addOns: [],
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  costFor: {
    linear: 0,
    height: 0,
    loadBearing: 0,
    basementIncrement: 0,
    openingIncrement: 0,
    addOns: 0,
  },
  comments: "",
};
