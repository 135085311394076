import React from "react";

function CheckBox(props) {
  const { value, name, checked, onClick, className } = props;
  return (
    <div>
      <div className="roles">
        <input
          {...props}
          className={className || ""}
          onChange={onClick}
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          style={{
            cursor: "pointer",
            width: "21px",
            height: "21px",
            border: "2px solid #2CCAE8",
            borderRadius: "none",
            borderWidth: "1px",
          }}
        />
      </div>
    </div>
  );
}

export default CheckBox;
