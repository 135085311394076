import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorLaminatedModel } from "../../../_redux/Models/Floor.model";
import { removalOfFloorPopup } from "../Common/RemovalOfFloor.popup";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const ServiceType = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;

  const [haveOwnLaminate, setHaveOwnLaminate] = useState("");
  const [tileSize, setTileSize] = useState("");
  const [showRemovalFloorPopup, setShowRemovalFloorPopup] = useState(false);

  const floorState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorLaminatedModel
  );

  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [price, setPrice] = useState(calculateTotalCost(floorState.costFor)); // To keep the total price in state
  const [gradeOptions, setGradeOptions] = useState([]);
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelAs.priceDetails;
    const floorSize = floorState.floorSize;
    if (mounted === true && priceDetails) {
      const optionsArr = priceDetails.materialQualities.map((op) => {
        return {
          text: t(`floor.${op.key}`),
          value: op.key,
          price: op.materialPrice * floorSize,
        };
      });
      setGradeOptions(optionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelAs.priceDetails, floorState.floorSize, t]);

  // To show laminate service questions
  const laminateOptions = [
    {
      text: t("laminated.laminate"),
      value: "yes",
    },
    {
      text: t("laminated.provide"),
      value: "no",
    },
  ];

  /**
   * Function to save Selected Service Type and take user to next step
   * @returns {void}
   */
  const onSaveServiceType = () => {
    floorState.haveOwnLaminate = haveOwnLaminate === "yes";
    floorState.tileSize = haveOwnLaminate === "no" ? tileSize : "";
    floorState.costFor.laminated =
      haveOwnLaminate === "no"
        ? price - calculateTotalCost(floorState.costFor)
        : 0;
    dispatch(floorStore(floorState));
    checkoutModelAs.totalCost =
      haveOwnLaminate === "no" ? price : calculateTotalCost(floorState.costFor);
    dispatch(checkoutStore(checkoutModelAs));
    setShowRemovalFloorPopup(true);
  };

  /**
   * On prior floor removal click yes
   * @returns {void}
   */
  const onPriorFloorRemovalYesClick = () => {
    floorState.priorFloorRemovalRequired = true;
    dispatch(floorStore(floorState));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/laminated/current-floor/3`
    );
  };

  /**
   * On prior floor removal click no
   * @returns {void}
   */
  const onPriorFloorRemovalNoClick = () => {
    setShowRemovalFloorPopup(false);
    floorState.priorFloorRemovalRequired = true;
    dispatch(floorStore(floorState));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/laminated/floor-addons/3`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">{t("laminated.install")}</label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("laminated.let")}
            </label>
          </div>
          <div className="padding10"></div>
          {laminateOptions.map((item, index) => (
            <div
              key={index}
              className={`floor-selection-radio ${
                haveOwnLaminate === item?.value ? "selected" : ""
              }`}
              onClick={() => setHaveOwnLaminate(item.value)}
            >
              <div key={index} className="width100">
                <label className="paintdiv_txt"> {item.text} </label>
              </div>
            </div>
          ))}
          {haveOwnLaminate === "no" ? (
            <div>
              <div className="paint_flex_with_left padding30">
                <label className="paint_header">{t("laminated.details")}</label>
              </div>
              <div className="paint_flex_with_left ">
                <label className="paint_other_sub_header ">
                  {t("laminated.each")}
                </label>
              </div>
              <div className="padding10"></div>

              {gradeOptions.map((item, index) => (
                <div key={index} className="paintPadding8 ">
                  <div
                    className={`floor-selection-radio ${
                      tileSize === item?.value ? "selected" : ""
                    }`}
                    onClick={() => {
                      const total = calculateTotalCost(floorState.costFor);

                      setTileSize(item.value);
                      setPrice(total + item.price);
                    }}
                    style={{}}
                  >
                    <div
                      key={index}
                      className="width100"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="paintdiv_txt pc_txt_150">
                        {item.text}
                      </label>
                      <div
                        className="paint_arrowFloat"
                        style={{
                          width: "130px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img src={IMAGES.other.green_plus} alt="" />
                          <label className="paintdiv_txt">
                            {`$${formatPrice(item.price)}`}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          <Button
            disabled={
              haveOwnLaminate === "" ||
              (tileSize === "" && haveOwnLaminate === "no")
            }
            buttonLabel={t("paintingHomes.continue")}
            onClick={onSaveServiceType}
          />
        </div>
      </div>
      {removalOfFloorPopup({
        isOpen: showRemovalFloorPopup,
        onYesClick: onPriorFloorRemovalYesClick,
        onCloseAction: onPriorFloorRemovalNoClick,
        onNoClick: onPriorFloorRemovalNoClick,
      })}{" "}
    </div>
  );
};
