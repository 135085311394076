import { AddOn } from "./AddOn";
import { CurrentTypeOfFloor } from "./CurrentTypeOfFloor";
import { FloorSizeDetails } from "./FloorSizeDetails";
import { SizeOfFloor } from "./SizeOfFloor";
import { TileOrderSummary } from "./TileOrderSummary";
import { PersonalInfo } from "./personalInfo";

export const tilesRoutes = [
  {
    path: "/contractor-portfolio/:id/floors/tiles/floor-size/:stepValue",
    component: SizeOfFloor,
  },
  {
    path: "/contractor-portfolio/:id/floors/tiles/current-floor-details/:stepValue",
    component: CurrentTypeOfFloor,
  },
  {
    path: "/contractor-portfolio/:id/floors/tiles/floor-size-details/:stepValue",
    component: FloorSizeDetails,
  },
  {
    path: "/contractor-portfolio/:id/floors/tiles/floor-addons/:stepValue",
    component: AddOn,
  },
  {
    path: "/contractor-portfolio/:id/floors/tiles/personal-info/:stepValue",
    component: PersonalInfo,
  },
  {
    path: "/contractor-portfolio/:id/floors/tiles/order-summary/:stepValue",
    component: TileOrderSummary,
  },
];
