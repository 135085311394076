import "dotenv/config";
// export const API_URL = "https://anyhnb.meantr.online/api";
//export const API_URL = "http://13.59.48.186/api";
export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = "http://localhost:3000/api";
// export const API_URL = "https://67c7-202-83-58-151.ngrok.io/api";
// export const API_URL = "https://041c-202-83-58-151.ngrok.io/api";

export const signupEndPoint = {
  ownerSignup: "/owner/signup",
  contractorSignup: "/contractor/signup",
  getUserById: "/user/",
  userVerify: "/auth/verify",
  mobileVerify: "/auth/mobile/verify",
  mobileEmailVerify: "/auth/verify-email-mobile",
  contractorProfileUpload: "/contractor/profile",
  contractorPictureOfWorkUpload: "/contractor/profile/picturesOfWork",
  resendOTP: "/auth/resendOtp",
  mobileResendOTP: "/auth/mobile/resendOtp",
  forgotPassword: "/auth/forgotPassword",
  resetPassword: "/auth/resetPassword",
  activeService: "/service-categories/full-list",
};

export const authEndPoint = {
  googleLogin: "/auth/signIn/google",
  facebookLogin: "/auth/signIn/facebook",
  manuelSignin: "/auth/signIn",
  phoneSignin: "/auth/mobile/signin",
};

export const serviceCategoryEndPoints = {
  getServiceCategories: "/service-categories",
  getServiceCategoriesFull: "/service-categories/full-list",
};

export const contractorEndPoints = {
  getContractor: "/contractor",
  contractor: `${API_URL}/contractor`,
};

export const userEndPoints = {
  owner: `${API_URL}/owner`,
  placeOrder: `${API_URL}/owner`,
  profile: `${API_URL}/owner`,
  contactUs: `${API_URL}/contactUs/create`,
  placeOrderContractor: `${API_URL}/contractor`,
  ratings: `${API_URL}/v1/ratings`,
};

export const chatEndPoints = {
  getAllConversations: "/chat/conversations/participants/",
  createChannel: "/chat/createChannel",
  generatorToken: "/generatorToken",
  getMessages: "/messages",
  sentMessage: "/sendMessage",
};

export const notificationsEndPoints = {
  getAllNotifications: "/notifications",
  updateReadStatus: "/notifications/",
  updateFirebaseToken: "/notifications/token",
};

export const getuserDetails = () => {
  return JSON.parse(localStorage.getItem("user"));
};
