import React, { useState, useEffect } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/home.css";
import "../../../_styles/localStyles/paint.css";
import "../../../_styles/localStyles/floor.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { Button } from "../../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import {
  checkoutStore,
  paintingStore,
  resetRooms,
  setNoOfBathRooms,
  setOptions,
  updateNoOfRooms,
} from "../../../_redux/actions";
import { paintingInteriorModel } from "../../../_redux/Models/painting.models";
import { Svguparrow } from "../../../_components/Tickandwrongsvg/svgUparrow";
import { Svgdownarrow } from "../../../_components/Tickandwrongsvg/svgDownarrow";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import { getServicePriceDetails } from "../../../services/api/category.api.service";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const InteriorTypeOfWork = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [price, setPrice] = useState(
    calculateTotalCost(paintingInteriorModel.costFor)
  ); // To keep the total price in state

  const [typeOfWork, setTypeOfWork] = useState("");
  const [size, setSize] = useState("");
  const [numberOfRooms, setNumberOfRooms] = useState(0);

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : paintingInteriorModel
  );

  const [fullHouseCost, setFullHouseCost] = useState(0);

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;
    if (
      mounted === true &&
      selectedService?._id &&
      selectedService?.categoryId
    ) {
      getServicePriceDetails(
        selectedService?.categoryId,
        selectedService?._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          localStorage.setItem("options", JSON.stringify(response.data));
          setFullHouseCost(
            response.data.details.typeOfWork.find(
              (op) => op.key === "full_house"
            ).price
          );
          checkoutModelState.priceDetails = response.data.details;
          checkoutModelState.totalCost = 0;
          dispatch(checkoutStore(checkoutModelState));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch]);

  const onSave = () => {
    state.typeOfWork = typeOfWork;
    //dispatch(resetRooms());
    if (typeOfWork === "fullHouse") {
      state.size = size;
      state.costFor.size = price - calculateTotalCost(state.costFor);
      checkoutModelState.totalCost = price;
      dispatch(checkoutStore(checkoutModelState));
      dispatch(paintingStore(state));
      history.push(
        `/contractor-portfolio/${selectedContractorId}/painting/interior/pick-color-house/${3}`
      );
    } else if (typeOfWork === "rooms") {
      const roomDetails = [];
      for (let index = 0; index < numberOfRooms; index++) {
        var t = {
          id: Math.random().toString().replace(".", ""),
          number: index + 1,
          roomSize: 0,
          roomHeight: 0,
          addOns: [],
          costFor: {
            size: 0,
            typeOfWork: 0,
            coats: 0,
            addOns: 0,
            paint: 0,
            coat: 0,
            primer: 0,
          },
        };
        //dispatch(setNoOfBathRooms(numberOfRooms));
        roomDetails.push(t);
      }

      state.roomDetails = roomDetails;
      dispatch(paintingStore(state));
      const firstId = roomDetails[0].id;
      history.push(
        `/contractor-portfolio/${selectedContractorId}/painting/interior/${firstId}/room-size/3`
      );
    }
  };

  function arrowHandler(params) {
    let updatedState = numberOfRooms;
    if (params === "plus") {
      updatedState = parseInt(updatedState) + 1;
    } else if (updatedState > 1) {
      updatedState = parseInt(updatedState) - 1;
    }
    setNumberOfRooms(updatedState);
  }
  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">
            {t("paintingInterior.pleaseselect")}
          </label>
        </div>

        <div>
          <label className="paint_other_sub_header">
            {t("paintingInterior.pleasetitle")}
          </label>
        </div>
        <div
          className={`floor-selection-radio ${
            typeOfWork === "rooms" ? "selected" : ""
          }`}
          onClick={() => {
            setTypeOfWork("rooms");
            setNumberOfRooms(1);
          }}
        >
          <label className="paintdiv_txt">{t("paintingInterior.iwant")}</label>
        </div>
        {typeOfWork === "rooms" ? (
          <div
            className={`paint_radio_div_firstarrow paintdiv_arrow_padding mb-4`}
          >
            <table className="paint_table100">
              <tbody>
                <tr>
                  <td>
                    <select
                      className="dropDown"
                      defaultValue={"1"}
                      onChange={(e) => {
                        if (e.target.value) {
                          setNumberOfRooms(e.target.value);
                        }
                      }}
                    >
                      {Array.from({ length: 15 }, (_, i) => i + 1).map(
                        (key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        )
                      )}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
        <div
          className={`floor-selection-radio ${
            typeOfWork === "fullHouse" ? "selected" : ""
          }`}
          onClick={() => {
            setTypeOfWork("fullHouse");
            setNumberOfRooms(1);
          }}
        >
          <label className="paintdiv_txt">
            {t("paintingInterior.fullname")}
          </label>
        </div>

        {typeOfWork === "fullHouse" ? (
          <div className="paintinput_lable mb-4">
            <>
              <TextInput
                disabled={typeOfWork === "rooms"}
                className="paint_input_border"
                onChange={(event) => {
                  setSize(event.target.value);
                  setPrice(fullHouseCost * event.target.value);
                }}
                value={size}
                placeholder={t("paintingInterior.enterTheNumberOfSQFT")}
                label={t("paintingInterior.sizeOfYourHouse")}
              />

              <div className="paint_placeholder_right">
                <label>{t("paintingInterior.sqft")}</label>
              </div>
            </>
          </div>
        ) : (
          ""
        )}

        <Button
          disabled={
            typeOfWork === "" ||
            (typeOfWork === "rooms" && numberOfRooms < 1) ||
            (typeOfWork === "fullHouse" && size === "")
          }
          buttonLabel={t("paintingHomes.continue")}
          onClick={onSave}
        />
      </div>
    </div>
  );
};
