import { ServiceType } from "./ServiceType";
import { SizeOfFloor } from "./SizeOfFloor";
import { Summary } from "./Summary";
import { CarpetQuality } from "./carpetQuality";
import { CurrentCarpetFloor } from "./currentFloor";
import { PaddingQuality } from "./paddingQuality";
import { PersonalInfo } from "./personalInfo";

export const carpetRoutes = [
  {
    path: "/contractor-portfolio/:id/floors/carpet/floor-size/:stepValue",
    component: SizeOfFloor,
  },
  {
    path: "/contractor-portfolio/:id/floors/carpet/service-type/:stepValue",
    component: ServiceType,
  },
  {
    path: "/contractor-portfolio/:id/floors/carpet/carpet-quality/:stepValue",
    component: CarpetQuality,
  },
  {
    path: "/contractor-portfolio/:id/floors/carpet/padding-quality/:stepValue",
    component: PaddingQuality,
  },
  {
    path: "/contractor-portfolio/:id/floors/carpet/current-floor/:stepValue",
    component: CurrentCarpetFloor,
  },
  {
    path: "/contractor-portfolio/:id/floors/carpet/personal-info/:stepValue",
    component: PersonalInfo,
  },
  {
    path: "/contractor-portfolio/:id/floors/carpet/summary/:stepValue",
    component: Summary,
  },
];
