import IMAGES from "../../_assets/images/index";
 const traditionalSliderItems = [
  {
    urls: IMAGES.home.bathroomTraditional1,
  },
  {
    urls: IMAGES.home.bathroomTraditional2,
  },
  {
    urls: IMAGES.home.bathroomTraditional3,
  },
  {
    urls: IMAGES.home.bathroomTraditional4,
  },
  {
    urls: IMAGES.home.bathroomTraditional5,
  },
  {
    urls: IMAGES.home.bathroomTraditional6,
  },
  {
    urls: IMAGES.home.bathroomTraditional7,
  },
  {
    urls: IMAGES.home.bathroomTraditional8,
  },
  {
    urls: IMAGES.home.bathroomTraditional9,
  },
  {
    urls: IMAGES.home.bathroomTraditional10,
  },
  {
    urls: IMAGES.home.bathroomTraditional11,
  },

];
export default traditionalSliderItems