import React from "react";
import "./Button.css";

export const Buttontick = (props) => {
  const { buttonLabel, disabled, onClick, className } = props;
  return (
<div className="allCenterbtn" style={{position:"relative",}}>
          
          <button
            onClick={onClick}
            disabled={disabled}
            className={className || "btn-checkouttick"}
          >
            {buttonLabel}
          </button>
          <div style={{position:"absolute",right: "15%", top: "50%", padding: "0", marginTop: "-17px",}} className="allCenter">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
        <path d="M6.49999 10.172L15.692 0.979004L17.107 2.393L6.49999 13L0.135986 6.636L1.54999 5.222L6.49999 10.172Z" fill="#2CCAE8"/>
        </svg>
          </div>
          </div>
  );
};
