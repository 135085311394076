import { CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images";
import useNotify from "../../_components/Notification/UseNotify";
import "./style.scss";
import axiosInstance from "../../services/api/config/api.config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { API_URL } from "../../services/apiConstants";
import axios from "axios";
import "./OwnerProfile/owner.css";
import i18next from "i18next";

const BankDetailsForm = ({ signUp }) => {
  const { t } = useTranslation();
  const { confirm } = useNotify();
  const ref = useRef();
  const [bankDetails, setBankDetails] = useState(null);
  const [showPassword, setShowPassword] = useState(true);
  const history = useHistory();
  const [Loader, setLoader] = useState(false);
  const getBankDetails = async () => {
    try {
      var res = await axiosInstance.get(`/contractor/bank-details`);
      if (res && res.data?.code === 200) {
        let resp = res.data.bankDetails;

        setBankDetails(res.data.bankDetails);
        const updatedFormData = {
          ...formData,
          accountName: {
            value: resp.accountName,
            valid: true,
            error: {
              flag: false,
              message: "",
            },
          },
          accountNumber: {
            value: resp.accountNumber,
            valid: true,
            error: {
              flag: false,
              message: "",
            },
          },
          confirm: {
            value: resp.accountNumber,
            valid: true,
            error: {
              flag: false,
              message: "",
            },
          },
          routingNumber: {
            value: resp.routingNumber,
            valid: true,
            error: {
              flag: false,
              message: "",
            },
          },
          accountType: {
            value: resp.accountType,
            valid: true,
            error: {
              flag: false,
              message: "",
            },
          },
          confirmAccountNumber: {
            value: resp.confirmAccountNumber,
            valid: true,
            error: {
              flag: false,
              message: "",
            },
          },
        };

        // Update the formData state with the updatedFormData
        setFormData(updatedFormData);
      }
    } catch (err) {
      //not updated BankDetails
    } finally {
    }
  };

  useEffect(() => {
    if (!signUp) {
      getBankDetails();
    }
  }, []);
  const [formData, setFormData] = useState({
    accountName: {
      value: "",
      valid: false,
      error: {
        flag: false,
        message: "",
      },
    },
    accountNumber: {
      value: "",
      valid: false,
      error: {
        flag: false,
        message: "",
      },
    },
    confirm: {
      value: "",
      valid: false,
      error: {
        flag: false,
        message: "",
      },
    },
    routingNumber: {
      value: "",
      valid: false,
      error: {
        flag: false,
        message: "",
      },
    },
    accountType: {
      value: "",
      valid: false,
      error: {
        flag: false,
        message: "",
      },
    },
  });

  const handleHideAccountNumber = () => {
    if (formData?.accountNumber?.value?.length > 0) {
      setShowPassword(false);
    }
  };

  const validate = (name, value, message) => {
    const newState = { ...formData };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;

    switch (name) {
      case "confirm":
        if (!value) {
          errorMessage =
            errorMessage !== null
              ? errorMessage
              : "Confirm Account number is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        } else if (!/^\d+$/.test(value)) {
          errorMessage =
            errorMessage !== null
              ? errorMessage
              : "Confirm Account number should only contain numeric values";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        if (value !== newState.accountNumber.value) {
          errorMessage =
            errorMessage !== null
              ? errorMessage
              : "Account numbers do not match";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message =
            "Account number and Confirm account number do not match";
        }
        return newState;

      case "accountNumber":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Account number is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (!/^\d+$/.test(value)) {
          errorMessage =
            errorMessage !== null
              ? errorMessage
              : "Account number should only contain numeric values";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }

        if (value === newState.confirm.value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState["confirm"].error.flag = false;
          newState["confirm"].valid = true;
          newState["confirm"].error.message = "";
        }
        if (value !== newState.confirm.value && newState.confirm.value !== "") {
          errorMessage =
            errorMessage !== null
              ? errorMessage
              : "Account numbers do not match";
          newState["confirm"].error.flag = true;
          newState["confirm"].valid = false;
          newState["confirm"].error.message =
            "Account number and Confirm account number do not match";
        }
        return newState;

      case "routingNumber":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Routing Number is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "accountName":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Account name is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "accountType":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Account type is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      default: {
        return newState;
      }
    }
  };

  const handleInputChange = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;
    let a = validate(name, value);

    if (name === "accountType") {
      a = {
        ...a,
        accountType: {
          value: value,
          error: a.accountType.error,
        },
      };
    }

    setFormData(a);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = Object.values(formData).every(
      (val) => val.valid === true
    );

    if (formValid) {
      let payload = {
        accountNumber: formData?.accountNumber?.value,
        routingNumber: formData?.routingNumber?.value,
        accountType: formData?.accountType?.value,
        accountName: formData?.accountName?.value,
      };
      try {
        setLoader(true);
        var res = await axios.post(
          API_URL + "/contractor/bank-details",
          payload,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("auth_token"),
            },
            params: { lang: i18next.language },
          }
        );
        // Handle the response data

        if (res && res.status === 200) {
          setLoader(false);
          confirm("success", "Bank account updated successfully");
          if (signUp) {
            history.replace(`/create-profile/services/${3}`);
          } else {
            history.replace("/bank-details");
          }
        }
      } catch (err) {
        setLoader(false);
        confirm(
          "error",
          err?.response?.data?.message || "Something went wrong"
        );
      }
    } else {
      Object.values(formData).forEach((el, i) => {
        console.log(el);
        if (!el.valid) {
          let keyValue = Object.keys(formData)[i];
          var errorState = validate(keyValue, el.value, undefined);
          setFormData(errorState);
        }
      });
    }
  };

  useEffect(() => { }, []);

  return (
    <>
      <img src={IMAGES.home.bankIcon} height="50" width="50" />
      <h1 className="verify-head">{t("bankDetails.head")}</h1>
      <form className="add-address-form d-flex flex-column gap-3 position-relative w-100">
        <div className="d-flex flex-column">
          <label className="">{t("bankDetails.accountName")}</label>
          <input
            type="text"
            className={`${formData?.accountName?.error?.flag && "border-danger"
              }`}
            placeholder={t("bankDetails.accountName")}
            onChange={handleInputChange}
            name="accountName"
            value={formData?.accountName?.value}
          />
          <small className="error-message">
            {formData?.accountName?.error?.message}
          </small>
        </div>
        <div className="d-flex flex-column">
          <label className="">{t("bankDetails.accountNumber")}</label>

          <input
            type={showPassword ? "text" : "password"}
            className={`${formData?.accountNumber?.error?.flag && "border-danger "
              } cstmpwd`}
            placeholder={t("bankDetails.accountNumber")}
            onChange={handleInputChange}
            onBlur={handleHideAccountNumber}
            name="accountNumber"
            value={formData?.accountNumber?.value}
          />
          <small className="error-message">
            {formData?.accountNumber?.error?.message}
          </small>
        </div>
        <div className="d-flex flex-column">
          <label className="">{t("bankDetails.confirm")}</label>
          <input
            type="text"
            className={`${formData?.confirm?.error?.flag && "border-danger"}`}
            placeholder={t("bankDetails.confirm")}
            onChange={handleInputChange}
            name="confirm"
            value={formData?.confirm?.value}
          />
          <small className="error-message">
            {formData?.confirm?.error?.message}
          </small>
        </div>
        <div className="d-flex flex-column">
          <label className="">{t("bankDetails.routing")}</label>
          <input
            type="text"
            className={`${formData?.routingNumber?.error?.flag && "border-danger"
              }`}
            placeholder={t("bankDetails.routing")}
            onChange={handleInputChange}
            name="routingNumber"
            value={formData?.routingNumber?.value}
          />
          <small className="error-message">
            {formData?.routingNumber?.error?.message}
          </small>
        </div>
        <div className="d-flex flex-wrap gap-2">
          <div className="d-flex align-items-center">
            <input
              type="radio"
              className={`${formData?.accountType?.error?.flag && "border-danger"
                }`}
              placeholder={t("bankDetails.accountType")}
              onChange={handleInputChange}
              checked={
                formData?.accountType?.value === "personal" ? true : false
              }
              name="accountType" // Assign the same name for both radio inputs
              value="personal" // Assign a unique value for each radio input
            />
            <label htmlFor="accountType" className="ms-2 mb-0">
              {t("bankDetails.personal")}
            </label>
          </div>
          <div className="d-flex align-items-center">
            <input
              type="radio"
              className={`${formData?.accountType?.error?.flag && "border-danger"
                }`}
              checked={
                formData?.accountType?.value === "business" ? true : false
              }
              placeholder={t("bankDetails.accountType")}
              onChange={handleInputChange}
              name="accountType" // Assign the same name for both radio inputs
              value="business" // Assign a unique value for each radio input
            />
            <label htmlFor="accountType" className="ms-2 mb-0">
              {t("bankDetails.business")}
            </label>
          </div>
          <small className="error-message">
            {formData?.accountType?.error?.message}
          </small>
        </div>

        <button
          onClick={handleSubmit}
          className="verify-btn d-flex align-items-center justify-content-center"
          style={{ marginTop: "30px" }}
        >
          Save
          {Loader ? (
            <CircularProgress
              sx={{ color: "#fff", marginLeft: "10px" }}
              size={24}
            />
          ) : (
            ""
          )}
        </button>
      </form>
    </>
  );
};

export default BankDetailsForm;
