import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/home.css";
import "../../../_styles/localStyles/paint.css";
import "../../../_styles/localStyles/floor.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import { deckModel } from "../../../_redux/Models/painting.models";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, paintingStore } from "../../../_redux/actions";
import { Dialog } from "@material-ui/core";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { getServicePriceDetails } from "../../../services/api/category.api.service";
import { Button } from "../../../_components/Button/Button";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
import { useHistory } from "react-router-dom";
export const Decksize = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 0;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [deck, setDeck] = useState({
    sqft: "",
  });
  const [dialogAction, setDialogAction] = useState(false);
  const [typeOfFinish, setTypeOfFinish] = useState([]);
  const [coverRoof, setCoverRoof] = useState({ key: "", percentage: 0 });
  const [price, setPrice] = useState(calculateTotalCost(deckModel.costFor)); // To keep the total price in state
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : deckModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;

    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          checkoutModelState.priceDetails = response.data.details;
          setTypeOfFinish(response.data.details.typeOfFinish);
          setCoverRoof(response.data.details.coverRoof);
          dispatch(checkoutStore(checkoutModelState));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch]);

  const onSave = () => {
    state.size = deck.sqft;
    const paintPrice = typeOfFinish.find((op) => op.key === "paint").price;
    const total = parseInt(deck.sqft) * paintPrice;
    state.costFor.typeOfFinish = total;
    checkoutModelState.totalCost = total;
    setPrice(total);
    dispatch(checkoutStore(checkoutModelState));
    dispatch(paintingStore(state));
    setDialogAction(true);
  };

  const noClick = () => {
    history.push(
      `/contractor-portfolio/${selectedContractorId}/painting/deck/summary/2`
    );
  };

  const yesClick = () => {
    const coverRoofPercentage = coverRoof.percentage;
    const total = price + (coverRoofPercentage / 100) * price;
    state.deckCoverORroof = "Yes";
    state.costFor.deckOrRoof = total - price;
    checkoutModelState.totalCost = total;
    dispatch(paintingStore(state));
    dispatch(checkoutStore(checkoutModelState));
    setDialogAction(false);

    history.push(
      `/contractor-portfolio/${selectedContractorId}/painting/deck/summary/2`
    );
  };
  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <label className="header_path_name_show">
          {t("paintingdeck.header_path")}
        </label>

        <div className="paint_flex_with_left">
          <label className="paint_header">{t("paintingExterior.sizing")}</label>
        </div>

        <div>
          <label className="paint_other_sub_header">
            {t("paintingdeck.pleaseintroduced")}
          </label>
        </div>

        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border paintexterior border-bottom"
            onChange={(event) => {
              setDeck({ ...deck, sqft: event.target.value });
            }}
            onKeyUp={() => {
              const paintPrice = typeOfFinish.find(
                (op) => op.key === "paint"
              ).price;
              const total = parseInt(deck.sqft) * paintPrice;
              setPrice(total);
            }}
            value={deck.sqft}
            type={"number"}
            placeholder={t("paintingExterior.enternumber")}
            label={t("paintingExterior.sizing")}
          />

          <div className="paint_placeholder_right1">
            <label>{"Square feet"}</label>
          </div>
        </div>
        <div className="pb-4"></div>
        <Button
          disabled={!deck.sqft}
          buttonLabel={t("basement.continueToNext")}
          onClick={onSave}
        />
      </div>
      <Dialog onClose={() => setDialogAction(false)} open={dialogAction}>
        {/* <div className="dialog_section">
          <img src={IMAGES.home.contractorIcon} alt="" style={{width: '100px'}} />
          <div className="dialog_title1">Deck cover/roof </div>

          <div
            className="flex"
            style={{ color: "#2CCAE8", marginTop: "20px", gap: "65px" }}
          >
            <p onClick={() => noClick()}>No</p>
            <p onClick={() => yesClick()}>Yes</p>
          </div>
        </div> */}
        <div className="stepper-dialog">
          <img src={IMAGES.home.removeIcon} width="120px" alt="" />
          <div className="stepper-dialog-text">Deck cover/roof?</div>
          <div className="flex stepper-dialog-footer">
            <div onClick={() => noClick()}>No</div>
            <div onClick={() => yesClick()}>Yes</div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
