import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorSolidHardwoodModel } from "../../../_redux/Models/Floor.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const SHIsFinished = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const [isSolidHardwoodFinished, setIsSolidHardwoodFinished] = useState("");
  const [
    needContractorFinishSolidHardwood,
    setNeedContractorFinishSolidHardwood,
  ] = useState({ price: 0, value: "", text: "" });
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorSolidHardwoodModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [finishOptions, setFinishOptions] = useState([]);
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    let size = parseInt(state.floorSize);
    if (mounted === true && priceDetails) {
      const finishOptions = [
        {
          text: t("solidHardwood.yesPleaseFinish"),
          value: "yes",
          price:
            priceDetails.finishingService.find(
              (op) => op.key === "add_finishing_service"
            ).price * size,
        },
        {
          text: t("solidHardwood.noNoNeed"),
          value: "no",
          price: 0,
        },
      ];
      setFinishOptions(finishOptions);
    }
    return () => (mounted = false);
  }, [checkoutModelState.priceDetails, state.floorSize, t]);

  // To show carpet service questions
  const youLikeOption = [
    {
      text: t("solidHardwood.yesFinished"),
      value: "yes",
    },
    {
      text: t("solidHardwood.notFinished"),
      value: "no",
    },
  ];

  /**
   * Function to save Selected Service Type and take user to next step
   * @returns {void}
   */
  const onSaveServiceType = () => {
    state.isSolidHardwoodFinished = isSolidHardwoodFinished === "yes";

    state.costFor.finishing =
      isSolidHardwoodFinished === "no"
        ? needContractorFinishSolidHardwood.price
        : 0;
    state.needContractorFinishSolidHardwood = needContractorFinishSolidHardwood;
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(floorStore(state));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/solid-hardwood/type-of-installation/4`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("solidHardwood.IsSolidHardwoodFinished")}
            </label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("solidHardwood.letUsKnowSolidHardwoodFinished")}
            </label>
          </div>
          <div className="padding10"></div>
          {youLikeOption.map((item, index) => (
            <div
              className={`floor-selection-radio ${
                isSolidHardwoodFinished === item?.value ? "selected" : ""
              }`}
              onClick={() => setIsSolidHardwoodFinished(item.value)}
            >
              <div key={index} className="width100">
                <label className="paintdiv_txt"> {item.text} </label>
              </div>
            </div>
          ))}

          {isSolidHardwoodFinished === "no" && (
            <div>
              <div className="paint_flex_with_left padding30">
                <label className="paint_header">
                  {t("solidHardwood.wouldYouLikeContractorToFinish")}
                </label>
              </div>

              <div className="padding10"></div>
              {finishOptions.map((item, index) => (
                <div
                  className={`floor-selection-radio ${
                    needContractorFinishSolidHardwood?.value === item?.value
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => {
                    setNeedContractorFinishSolidHardwood(item);
                    const total = calculateTotalCost(state.costFor);
                    setPrice(total + item.price);
                  }}
                >
                  <label className="paintdiv_txt pc_txt_150">{item.text}</label>
                  <div
                    className="paint_arrowFloat"
                    style={{
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={IMAGES.other.green_plus} alt="" />
                      <label className="paintdiv_txt">
                        {`$${formatPrice(item.price)}`}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="profilePadding40"></div>
          <Button
            disabled={
              typeof needContractorFinishSolidHardwood === "boolean" &&
              isSolidHardwoodFinished === "no"
            }
            buttonLabel={t("paintingHomes.continue")}
            onClick={onSaveServiceType}
          />
        </div>
      </div>
    </div>
  );
};
