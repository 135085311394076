import React, { useState, useEffect } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/profileCreate.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { Button } from "../../../_components/Button/Button";
import { useDispatch } from "react-redux";
import { t } from "i18next";
export const Profileconfirmation = (props) => {
  const { t } = useTranslation();
  // const { typeofWorkPainting } = useSelector((state) => state.buttonReducer);
  const { history } = props;
  const dispatch = useDispatch();
  const [userState, setUserState] = useState({
    paintType: "",
    number: 0,
    houseSqft: "",
    btnDisable: true,
  });
  const buttonHandler = (type) => {
    if (type === "myProfile") {
      history.push("/");
    } else if (type === "backHome") {
      history.push("/");
    }
  };

  function arrowHandler(params) {
    let updatedState = { ...userState };
    if (params == "plus") {
      updatedState.number = parseInt(updatedState.number) + 1;
    } else {
      updatedState.number = parseInt(updatedState.number) - 1;
    }

    setUserState(updatedState);
  }
  return (
    <div className="profileConfirmationback">
      <div className="paint-home-section ">
        <div className="personal_flex_with_center">
          <table className="confirmationTitle">
            <tr>
              <td>
                <div className="allCenterGrid">
                  <label>{t("profileCreation.wegot")}</label>
                  <label>{t("profileCreation.back")}</label>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td>
                <img src={IMAGES.home.profileGroupman} alt="" />
              </td>
            </tr>
          </table>
          <table className="confirmationTitle1">
            <tr>
              <td>
                <div className="allCenterGrid">
                  <label>{t("profileCreation.requ")}</label>

                  <label>{t("profileCreation.reviw")}</label>
                  <label>{t("profileCreation.touchw")}</label>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className="padding100"></div>
        <Button
          disabled={false}
          buttonLabel={t("profileCreation.gotoprofile")}
          onClick={() => buttonHandler("myProfile")}
          className={"btn-white"}
        />
        <div className="profilePadding10"></div>
        <Button
          disabled={false}
          buttonLabel={t("profileCreation.gotoback")}
          onClick={() => buttonHandler("backHome")}
          className={"btn-primary-border"}
        />
      </div>
    </div>
  );
};
