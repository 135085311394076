import React, { useState, useEffect } from "react";
import SelectSearch from "react-select-search";

import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/paint.css";
import "../../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import CheckBox from "../../../_components/CheckBox/checkBox";
import { paintingInteriorModel } from "../../../_redux/Models/painting.models";
import { useDispatch, useSelector } from "react-redux";
import { paintingStore } from "../../../_redux/actions";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { getAvailablePaints } from "../../../services/api/category.api.service";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateCostFromArr,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import Loader from "../../../_components/spinner/Loader";
import { Button } from "../../../_components/Button/Button";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { DropDown } from "../../../_components/dropDown/dropDown";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
import { useHistory } from "react-router-dom";

export const PickRoomColorInterior = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 0;
  const roomId =
    props.match && props.match.params ? props.match.params.roomId : false;
  const dispatch = useDispatch();
  const [progressBar, setProgressBar] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : paintingInteriorModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const room = state.roomDetails.find((rooms) => rooms.id === roomId);

  if (!room) {
    history.push(`/contractor-portfolio/${selectedContractorId}/painting`);
    window.location.reload();
  }

  const paintAndPrimerGallonsNeeded = [
    {
      key: "small_up_to_8ft",
      first_coat: 1,
      second_coat: 2,
      primer_coat: 1,
    },
    {
      key: "medium_up_to_8ft",
      first_coat: 2,
      second_coat: 3,
      primer_coat: 2,
    },
    {
      key: "large_up_to_8ft",
      first_coat: 4,
      second_coat: 6,
      primer_coat: 4,
    },
    {
      key: "xlarge_up_to_8ft",
      first_coat: 6,
      second_coat: 10,
      primer_coat: 6,
    },
    {
      key: "small_up_to_10ft",
      first_coat: 1,
      second_coat: 2,
      primer_coat: 1,
    },
    {
      key: "medium_up_to_10ft",
      first_coat: 2,
      second_coat: 3,
      primer_coat: 2,
    },
    {
      key: "large_up_to_10ft",
      first_coat: 4,
      second_coat: 6,
      primer_coat: 4,
    },
    {
      key: "xlarge_up_to_10ft",
      first_coat: 6,
      second_coat: 10,
      primer_coat: 6,
    },
    {
      key: "small_up_to_20ft",
      first_coat: 2,
      second_coat: 4,
      primer_coat: 2,
    },
    {
      key: "medium_up_to_20ft",
      first_coat: 4,
      second_coat: 6,
      primer_coat: 4,
    },
    {
      key: "large_up_to_20ft",
      first_coat: 8,
      second_coat: 12,
      primer_coat: 8,
    },
    {
      key: "xlarge_up_to_20ft",
      first_coat: 12,
      second_coat: 20,
      primer_coat: 12,
    },
  ];

  const [price, setPrice] = useState(calculateCostFromArr(state.roomDetails)); // To keep the total price in state
  const [coatsPrice, setCoatsPrice] = useState();
  const [paintBrands, setPaintBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({
    _id: "",
    value: "",
    brand: "",
    colors: [],
  });
  const [selectedPaintColor, setSelectedPaintColor] = useState("");
  const [selectedPaintCode, setSelectedPaintCode] = useState("");
  const [needPrimer, setNeedPrimer] = useState(false);
  const [typesOfPaint, setTypesOfPaint] = useState([]);
  const [selectedPaintType, setSelectedPaintType] = useState({
    value: "",
    price: 0,
  });
  const [coat, setCoat] = useState(1);
  const [costFor, setCostFor] = useState({
    paint: 0,
    primer: 0,
    coat: 0,
  });
  const [notSureAboutPaint, setNotSureAboutPaint] = useState(false);
  const [totalGallons, setTotalGallons] = useState(0);
  const [primerCoatPrice, setPrimerCoatPrice] = useState(0);
  const [roomSizeHeightPrice, setRoomSizeHeightPrice] = useState(0);

  useEffect(() => {
    let mounted = true;
    setProgressBar(true);
    const priceDetails = checkoutModelState.priceDetails;
    const haveOwnPaint = state.haveOwnPaint;
    if (mounted === true && priceDetails && priceDetails.typeOfPaint) {
      const roomSizeHeight = `${room.roomSize}_up_to_${room.roomHeight}`;

      const paintGallonsPrice = paintAndPrimerGallonsNeeded.find(
        (op) => op.key === roomSizeHeight
      );

      const roomSizeHeightPrice = priceDetails.typeOfWork
        .find((op) => op.key === "rooms")
        .options.find((op) => op.key === roomSizeHeight)?.price;

      let gallonNeededDividend = paintGallonsPrice?.first_coat;
      if (coat === 2) {
        gallonNeededDividend = paintGallonsPrice?.second_coat;
      }
      if (needPrimer) {
        gallonNeededDividend =
          gallonNeededDividend + paintGallonsPrice?.primer_coat;
      }
      const totalGallons = gallonNeededDividend;
      const optionsArr = priceDetails.typeOfPaint?.map((op) => {
        return {
          text: t(`paintingExterior.${op.key}`),
          value: op.key,
          price: haveOwnPaint ? 0 : op.price * totalGallons,
        };
      });

      getAvailablePaints()
        .then(({ data }) => {
          const ops = data.map(({ _id, brand, colors }) => ({
            value: _id,
            name: brand,
            colors: colors,
          }));
          setPaintBrands(ops);
          setProgressBar(false);
        })
        .catch((e) => console.log(e));

      setTypesOfPaint(optionsArr);
      const totalServicePrice = haveOwnPaint
        ? calculateCostFromArr(state.roomDetails)
        : totalGallons * selectedPaintType.price;

      const secondCoatPrice =
        coat === 1
          ? 0
          : (priceDetails.coats.percentage / 100) * totalServicePrice;
      setCoatsPrice(secondCoatPrice);
      setTotalGallons(totalGallons);
      setPrimerCoatPrice(roomSizeHeightPrice * 0.5);
      setRoomSizeHeightPrice(roomSizeHeightPrice);
      console.log("roomSizeHeightPrice", roomSizeHeightPrice);
      setTimeout(() => {
        calculatePrice();
      }, 100);
    }
    return () => (mounted = false);
  }, [
    checkoutModelState,
    coat,
    needPrimer,
    room,
    selectedPaintType.price,
    state,
    t,
  ]);

  const onSave = () => {
    room.paintDetails = {
      brandName: selectedBrand,
      colorName: selectedPaintColor,
      paintCode: selectedPaintCode,
    };
    room.notSureAboutPaint = notSureAboutPaint;
    room.totalGallonsPaint = totalGallons;
    room.needPrimerCoat = needPrimer;
    room.paintType = selectedPaintType.value !== "" ? selectedPaintType : "";
    room.coats = coat;
    room.costFor.paint = selectedPaintType.price;
    room.costFor.primer = costFor.primer;
    room.costFor.coat = costFor.coat;
    state.roomDetails.filter((bt) => bt.id !== room.id).push(room);
    dispatch(paintingStore(state));
    const nextStepValue = parseInt(stepValue) + 1;
    history.push(
      `/contractor-portfolio/${selectedContractorId}/painting/interior/${roomId}/add-ons/${nextStepValue}`
    );
  };

  function arrowHandler(params) {
    let coatPrev = coat;
    if (params === "plus") {
      coatPrev = 2;
    } else {
      coatPrev = parseInt(coatPrev) > 1 ? parseInt(coatPrev) - 1 : 1;
    }
    calculatePrice(needPrimer, coatPrev, selectedPaintType.price);
    setCoat(coatPrev);
  }

  const updateCoat = (coatPrev) => {
    calculatePrice(needPrimer, coatPrev, selectedPaintType.price);
    setCoat(coatPrev);
  };

  const calculatePrice = (needPrimeCoatAs, coatAs, paintPrice) => {
    needPrimeCoatAs =
      needPrimeCoatAs !== undefined ? needPrimeCoatAs : needPrimer;
    paintPrice =
      paintPrice !== undefined ? paintPrice : selectedPaintType.price;
    coatAs = coatAs !== undefined ? coatAs : coat;
    let total = calculateCostFromArr(state.roomDetails);
    total = total + paintPrice;

    const roomSizeHeight = `${room.roomSize}_up_to_${room.roomHeight}`;

    const priceDetails = checkoutModelState.priceDetails;
    const roomSizeHeightPriceAs = priceDetails.typeOfWork
      .find((op) => op.key === "rooms")
      .options.find((op) => op.key === roomSizeHeight)?.price;

    console.log("Primer", needPrimeCoatAs);
    console.log("roomSizeHeightPrice", roomSizeHeightPriceAs);
    console.log("coatAs", coatAs);

    const primerPrice = needPrimeCoatAs ? roomSizeHeightPriceAs * 0.5 : 0;

    const coatPrice =
      coatAs === 1 ? roomSizeHeightPriceAs : roomSizeHeightPriceAs * 1.5;

    console.log(roomSizeHeightPriceAs);
    console.log(total + primerPrice + coatPrice);
    setPrice(total + primerPrice + coatPrice);
    setCostFor({
      paint: paintPrice,
      primer: primerPrice,
      coat: coatPrice,
    });
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        {!state.haveOwnPaint ? (
          <>
            <div className="paint_flex_with_left padding10">
              <label className="paint_header">
                {`Select paint details for room #${room?.number}`}
              </label>
            </div>

            <div className="my-2">
              <label className="paint_other_sub_header">
                {`Please select the details for painting your Room #${room?.number}`}
              </label>
            </div>

            <div>
              <label className="picColorroom1_radioHeader">
                {t("paintingInterior.brands")}
              </label>
            </div>
            <div className="paint_paddingtop">
              <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                <table className="paint_table100 ">
                  <tbody>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <TextInput
                          className="paint_input_border"
                          onChange={(event) => {
                            setSelectedBrand({
                              value: event.target.value,
                              brand: event.target.value,
                            });
                          }}
                          value={selectedBrand?.value}
                          placeholder={t("paintingInterior.brandname")}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table className="paint_table100 ">
              <tbody>
                <tr>
                  <td className="padding_8">
                    <div className="padding30">
                      <label className="picColorroom1_txtHeader">
                        {t("paintingInterior.colorname")}
                      </label>
                      <TextInput
                        className="paint_input_border"
                        onChange={(event) => {
                          setSelectedPaintColor(event.target.value);
                        }}
                        value={selectedPaintColor}
                        placeholder={t("paintingInterior.chooseAColor")}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="padding30">
                      <label className="picColorroom1_txtHeader">
                        {t("paintingInterior.paintcode")}
                      </label>

                      <TextInput
                        className="paint_input_border"
                        onChange={(event) => {
                          setSelectedPaintCode(event.target.value);
                        }}
                        value={selectedPaintCode}
                        placeholder={t("paintingInterior.chooseACode")}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="">
              <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                <table className="paint_table100">
                  <tbody>
                    <tr
                      onClick={() => setNotSureAboutPaint(!notSureAboutPaint)}
                    >
                      <td>
                        <label className="paintdiv_txt">
                          {t("paintingInterior.notsure1")}
                        </label>
                      </td>
                      <td className="paint_arrowFloat">
                        <CheckBox checked={notSureAboutPaint} readOnly />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : null}

        <div className="padding30 ">
          <label className="paint_primercoat">
            {t("paintingInterior.premier")}
          </label>
        </div>
        <div className="">
          <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
            <table className="paint_table100">
              <tbody>
                <tr
                  onClick={() => {
                    setNeedPrimer((state) => {
                      calculatePrice(!state, coat, selectedPaintType.price);
                      return !state;
                    });
                  }}
                >
                  <td>
                    <label className="paintdiv_txt">
                      {t("paintingInterior.ineed")}
                    </label>
                  </td>
                  <td>
                    {primerCoatPrice ? (
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <img src={IMAGES.other.green_plus} alt="" />
                        <label className="paintdiv_txt">
                          {`$${formatPrice(primerCoatPrice)}`}
                        </label>
                      </div>
                    ) : null}
                  </td>
                  <td className="paint_arrowFloat">
                    <CheckBox checked={needPrimer} readOnly />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="padding30 ">
          <label className="paint_primercoat">
            {t("paintingInterior.coats")}
            {/* <label style={{ fontWeight: "normal", fontSize: "16px" }}>
              (on top of the primer coat)
            </label> */}
          </label>
        </div>
        <div className=" ">
          <div className="paint_radio_div_firstarrow paintdiv_arrow_padding">
            <table className="paint_table100">
              <tbody>
                <tr>
                  <td>
                    <select
                      className={"dropDown"}
                      value={coat}
                      onChange={(e) => {
                        updateCoat(Number(e.target.value));
                      }}
                    >
                      <option key={1} selected value={1}>
                        1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        {`$${formatPrice(roomSizeHeightPrice * 1)}`}
                      </option>
                      <option key={2} value={2}>
                        2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {`$${formatPrice(roomSizeHeightPrice * 1.5)}`}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {!state.haveOwnPaint ? (
          <div>
            {" "}
            <div className="padding30 ">
              <label className="paint_primercoat">
                {t("paintingInterior.painttype")}
              </label>
            </div>
            {typesOfPaint.map((item, index) => (
              <div key={index} className="paintPadding8 ">
                <div
                  className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright "
                  onClick={() => {
                    setSelectedPaintType(item);
                    calculatePrice(needPrimer, coat, item.price);
                  }}
                >
                  <div
                    key={index}
                    className="width100"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      className="paintdiv_txt"
                      style={{
                        width: "150px",
                      }}
                    >
                      {item.text}
                    </label>
                    <div
                      className="paint_arrowFloat"
                      style={{
                        width: "150px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div style={{ display: "flex" }}>
                          <img src={IMAGES.other.green_plus} alt="" />
                          <label className="paintdiv_txt">
                            {`$${formatPrice(item.price)}`}
                          </label>
                        </div>
                      </div>
                      <RadioButton
                        checked={item.value === selectedPaintType.value}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        <div className="profilePadding40"></div>

        <Button
          disabled={false}
          buttonLabel={t("basement.continueToNext")}
          onClick={onSave}
        />
      </div>
    </div>
  );
};
