import React, { useEffect, useState } from "react";
import moment from "moment";

import { projectList } from "../../../services/api/contractor.service";
import { API_URL, getuserDetails } from "../../../services/apiConstants";
import IMAGES from "../../../_assets/images";
import { Link, useHistory } from "react-router-dom";
import { cardStatus, getprojectheader } from "./contractorUtils";
import { Button } from "../../../_components/Button/Button";
import { useTranslation } from "react-i18next";
import Header from "../../homeComponents/headerSection/Header";
import NewFooter from "../../homeComponents/footerSection/NewFooter";
import Loader from "../../../_components/spinner/Loader";
import axios from "axios";
import i18n from "i18next";

const ContractorView = () => {
  const [selected, setSelected] = useState("pending");
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [pendingActions, setPendingActions] = useState();

  const history = useHistory();
  const { t } = useTranslation();

  let user = getuserDetails();
  if (!user || user?.userType !== "contractor") {
    history.replace("/signin");
  }

  useEffect(() => {
    axios
      .get(API_URL + "/contractor/pending-actions", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
        params: { lang: i18n.language },
      })
      .then((res) => {
        setPendingActions(res?.data);
        let response = res?.data?.data;
        if (response && !response?.serviceLocationAdded) {
          history.push("/service-locations");
        } else if (response && !response?.bankDetailsAdded) {
          history.push("/bank-details/edit");
        }
      });
  }, []);
  const getProjectList = (status) => {
    setIsLoading(true);

    projectList(user?._id, status)
      .then((response) => {
        if (response) {
          const projects = response.data;
          const selectedDates = [];
          projects.forEach((project) => {
            selectedDates.push(moment(project.tentativeStartDate).toDate());
          });
          setProjects(projects);
          setFilteredProjects(projects);
          setIsLoading(false);

          // if (this.props.history.location.search) {
          //   const id = this.props.history.location.search.split("=")[1];
          //   const project = projects.find((project) => project._id === id);
          //   if (!project?._id) return;

          //   this.props.updateContractorProject(project);
          //   this.props.updateTenatativeDetails({
          //     startDate: project.tentativeStartDate,
          //     endDate: project.tentativeEndDate,
          //     time: {
          //       text: "",
          //       time: project.tentativeTimeRange,
          //     },
          //   });
          //   this.props.history.push("/contractor-project-preview");
          // }
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (user) getProjectList("pending");
  }, []);

  return (
    <>
      {/* <div
        className="custom-header d-flex align-items-center px-2 px-md-5"
        style={{ height: "90px" }}
      >
        <div className="profile-head w-100">
          <img
            onClick={() => history.goBack()}
            className="close-icon"
            src={IMAGES.home.backIcon}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div> */}
      <Header showSearch={false} />
      {/* <div className="insideWrp py-2">
        <HeaderMenu />
      </div> */}
      <div
        className="reservations verify-mobile-modal m-auto pt-5"
        style={{ maxWidth: "650px" }}
      >
        <h1 className="head mb-3">Reservations</h1>
        <div className="tabs-container">
          <div
            className="tab-item"
            onClick={() => {
              setSelected("scheduled");
              getProjectList("scheduled");
            }}
          >
            <p className={selected === "scheduled" ? "active" : ""}>
              Scheduled
            </p>
          </div>
          <div
            className="tab-item"
            onClick={() => {
              setSelected("pending");
              getProjectList("pending");
            }}
          >
            <p className={selected === "pending" ? "active" : ""}>Pending</p>
          </div>
          <div
            className="tab-item"
            onClick={() => {
              setSelected("history");
              getProjectList("historical");
            }}
          >
            <p className={selected === "history" ? "active" : ""}>History</p>
          </div>
        </div>
        {isLoading ? (
          <Loader progress={isLoading} />
        ) : (
          <>
            <div className="tab-contents">
              {filteredProjects?.length > 0 ? (
                filteredProjects.map((project) => {
                  return (
                    <>
                      <div>
                        <label
                          className="header_path_name_show grn-txt"
                          style={{
                            color: `${project.status === "confirm"
                              ? "#5B75FF"
                              : "#5FCA5D"
                              }`,
                          }}
                        >
                          {selected === "scheduled" && (
                            <label
                              className="approvalTxt"
                              style={{
                                fontSize: "14px",
                                margin: "0 0 0 7px",
                              }}
                            >
                              {cardStatus(project.status).header}
                            </label>
                          )}
                        </label>
                        <div className="padding10"></div>
                        <div className="divborderMyproject contractor-poject">
                          <div
                            className="myproject_radio_div_first1 exteriorDivpadding  "
                            style={{ padding: "0" }}
                          >
                            <table style={{ borderSpacing: "0" }}>
                              <tr>
                                <td>
                                  <div
                                    className="grid"
                                    style={{ padding: "20px" }}
                                  >
                                    <label className="picColorroom1_radioHeader">
                                      {project.serviceDetails.data.flowName &&
                                        getprojectheader(
                                          project.serviceDetails.data.flowName,
                                          project.serviceDetails.data
                                            .serviceType
                                        )}
                                    </label>
                                    <label className="paintdiv_txt three-lines-wrap">
                                      {project.ownerAddress}
                                    </label>
                                    <span className="header_path_name_show">
                                      Start date:{"   "}
                                      {moment(
                                        project.tentativeStartDate
                                      ).format("MM/DD/YYYY")}
                                    </span>
                                    <label className="myprojecttxtblue">
                                      <label
                                        className={`approvalTxt ${project.status}`}
                                      >
                                        {cardStatus(project.status).subHeader}
                                      </label>
                                    </label>
                                  </div>
                                </td>

                                <td
                                  className={`hoverColorChange ${project.status == "check_in" &&
                                    "active-home-icon"
                                    }`}
                                  width="90px"
                                  style={{ borderTopRightRadius: "16px" }}
                                >
                                  <div
                                    className="paint_arrowFloat "
                                    style={{
                                      float: "none",
                                      textAlign: "center",
                                    }}
                                  >
                                    <img
                                      className="check-home-icon"
                                      src={IMAGES.other.house}
                                      style={{ width: "35px" }}
                                      alt=""
                                    />
                                    <label>Check In</label>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div
                          className="divborderMyprojectbottom"
                          style={{ marginTop: "3px" }}
                        >
                          <Button
                            disabled={false}
                            buttonLabel={t("View project details")}
                            // onClick={() => {
                            //   this.props.updateContractorProject(project);
                            //   this.props.updateTenatativeDetails({
                            //     startDate: project.tentativeStartDate,
                            //     endDate: project.tentativeEndDate,
                            //     time: {
                            //       text: "",
                            //       time: project.tentativeTimeRange,
                            //     },
                            //   });
                            //   this.props.history.push(
                            //     "/contractor-project-preview"
                            //   );
                            // }}
                            className="btn-checkouttick"
                          />
                        </div>
                        <div className="paddinngtop30"></div>
                      </div>
                      <div></div>
                    </>
                  );
                })
              ) : (
                <div className="d-flex align-items-center mt-3">
                  <img
                    src={IMAGES.home.calendarIcon}
                    style={{ marginRight: "15px" }}
                  />
                  {selected === "scheduled" ? (
                    <div className="d-flex flex-column gap-2">
                      <p className="upcoming">
                        You don't have any upcoming reservations yet!
                      </p>
                      <p className="book">BOOK YOUR APPOINTMENT</p>
                    </div>
                  ) : selected === "pending" ? (
                    <div className="d-flex flex-column gap-2 mt-3">
                      <p className="upcoming">
                        You don't have any pending reservations!
                      </p>
                      <p className="book">BOOK YOUR APPOINTMENT</p>
                    </div>
                  ) : (
                    <div className="d-flex flex-column gap-2 mt-3">
                      <p className="upcoming">You don't have any projects!</p>
                      <p className="book">BOOK YOUR APPOINTMENT</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className="mobile-bottom-nav d-flex d-lg-none">
        <Link to="/favorites" className="d-flex flex-column">
          <img src={IMAGES.home.heartOulined} />
          <span>{t("footer.favorites")}</span>
        </Link>
        <Link
          to={user?.userType ? `${user?.userType}/my-profile` : "/signin"}
          className="d-flex flex-column"
        >
          <img src={IMAGES.home.defaultUser} />
          <span>{t("footer.profile")}</span>
        </Link>
      </div>
      <div className="fixed-footer">
        <NewFooter />
      </div>
    </>
  );
};

export default ContractorView;
