import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import { TextInput } from "../../../_components/TextInput/TextInput";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorEngineeredModel } from "../../../_redux/Models/Floor.model";
import { isInteger } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import { getServicePriceDetails } from "../../../services/api/category.api.service";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const SizeOfFloor = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedFloorName, updateSelectedService] = useState("");
  const stepValue = 1;
  const floorState = floorEngineeredModel;
  const [floorSize, setFloorSize] = useState(floorState.floorSize); // To keep the floor size in state
  const [floorSizePricing, setFloorSizePricing] = useState({ price: 0 }); // To keep the floor size in state
  const [price, setPrice] = useState(calculateTotalCost(floorState.costFor)); // To keep the total price in state
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;
    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        updateSelectedService(selectedService.name);
        if (mounted && response.status === 200 && response.data) {
          checkoutModelState.priceDetails = response.data.details;
          setFloorSizePricing(response.data.details.floorSize);
          dispatch(checkoutStore(checkoutModelState));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch]);

  /**
   * Calls when submit button is clicked
   */
  const onSubmitFloorSize = () => {
    floorState.costFor.size = price;
    checkoutModelState.totalCost = price;
    floorState.floorSize = floorSize;
    dispatch(floorStore(floorState));
    dispatch(checkoutStore(checkoutModelState));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/engineered/need-floor-install/2`
    );
  };

  return (
    <div className="insideWrp">
      <div className="px-3 mb-3">
        <div className="paint_flex_with_left">
          <label className="paint_header">
            {t("solidHardwood.introduceFloorSize")}
          </label>
        </div>
        <div className="paint_flex_with_left ">
          <label className="paint_other_sub_header">{t("floor.size")}</label>
        </div>
        <div className="paintinput_lable mb40">
          <TextInput
            className="paint_input_border"
            error={isInteger(floorSize)}
            type="number"
            onChange={(e) => {
              setPrice(floorSizePricing.price * e.target.value);
              setFloorSize(e.target.value);
            }}
            value={floorSize ? floorSize : ""}
            placeholder={t("paintingInterior.placeholder500")}
            label={t("floor.floorsize")}
          />
          <div className="paint_placeholder_right">
            <label>{t("paintingInterior.sqft")}</label>
          </div>
        </div>
        {floorSize > 0 && floorSize < 150 ? (
          <span style={{ fontSize: "10px", color: "red" }}>
            Minimum size is 150 square feet
          </span>
        ) : null}
        <Button
          disabled={floorSize < 150}
          buttonLabel={t("paintingHomes.continue")}
          onClick={onSubmitFloorSize}
        />
      </div>
    </div>
  );
};
