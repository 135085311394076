import React from "react";
import { useTranslation } from "react-i18next";

const WallOpeningWhatIsIncluded = () => {
  const { t } = useTranslation();
  return (
    <div>
      <label>
        <strong>{t("floor.whatsIncludedInPackage")}</strong>
      </label>

      <ul>
        <li>Customer support specialist</li>
        <li>Includes any structural work</li>
        <li>Walls framing material and installations</li>
        <li>Drywall materials and installations</li>
        <li>Square corners</li>
        <li>Flat ceilings</li>
        <li>Construction waste disposal & debris removal</li>
        <li>1 year comprehensive Warranty covering workmanship</li>
        <li>Materials free delivery**</li>
      </ul>
      <div className="profilePadding10"></div>
      <label>
        <strong>{t("floor.howLongTheProcessTakes")}</strong>
      </label>
      <ul>
        <li> Between 2 to 10 days*</li>
      </ul>
      <div className="profilePadding40"></div>
      <span>
        *Might differ according to size
        <br />
        **Only if acquired through Anyhnb
      </span>
    </div>
  );
};

export default WallOpeningWhatIsIncluded;
