import React from "react";
import IMAGES from "../../../_assets/images/index";
import GoogleLoginButton from "../signUp/socialMediaSignup/googleLogin";
import FacebookLogin from "../signUp/socialMediaSignup/facebookLogin";
import "../../../_styles/localStyles/signupsignin.css";
import { Footer } from "../../../_components/footer/Footer";
import { useTranslation } from "react-i18next";
function ChangePassword(props) {
  const { t } = useTranslation();
  const arrowClick = () => {
    props.history.push(`/signin/reset-password`);
  };
  return (
    <div>
      <div className="pcWidth">
        <div className="signup_center_align">
          <table>
            <tr>
              <td>
                {/* <img
              src={IMAGES.other.vectors}
              alt=""
              onClick={() => arrowClick()}
            /> */}
              </td>
              <td>
                <label
                  className="signup_page_anyhnb_logo"
                  onClick={() => window.location.replace("/")}
                >
                  {t("signUp.mainHeading")}
                </label>
              </td>
            </tr>
          </table>
        </div>
        {/* <h3>Sign Up</h3>

      <div style={{ display: "flex", marginTop: "-32px" }}>
        {" "}
        <p>I already have an account</p>
        <p
          style={{ marginLeft: "6px", color: "#5B75FF", fontWeight: "600" }}
          onClick={() => signInClick()}
        >
          Sign In
        </p>{" "}
      </div> */}
        <table className="passwordsuccess_table">
          <tr>
            <td>
              <label className="signup_header">
                {t("changePassword.mainHeading1")}

              </label>
            </td>
          </tr>
          <tr>
            <td>
              <label className="signup_header">

                {t("changePassword.mainHeading2")}
              </label>
            </td>
          </tr>

          <tr>
            <td className="passwordsuccess_btn_padd"><label className="signin_checkmail_txt ">{t("changePassword.subHeading1")}</label></td>
          </tr>

          <tr>
            <td>
              <label className="signin_checkmail_txt">{t("changePassword.subHeading2")}</label>
            </td>
          </tr>
        </table>



        <div className="passwordsuccess_btn_padd">
          <div className="signup_button_padding">
            <div className="signup_continue_button"
            >
              {t("changePassword.continue")}

            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}
export default ChangePassword;
