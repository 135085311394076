import React from "react";
import IMAGES from "./../../../../_assets/images/index";
import { Footer } from "./../../../../_components/footer/Footer";
import HeaderMenu from "./../../../../_components/HeaderMenu/HeaderSection";
import "./../../../../_styles/localStyles/profileCreate.css";
import "./../../../../_components/radioButton/radioButton.css";
import ProgressBar from "./../../../../_components/progressbar/ProgressBar";
import { Button } from "./../../../../_components/Button/Button";
import { withTranslation } from "react-i18next";
import { checkoutStore } from "./../../../../_redux/actions";
import { connect } from "react-redux";
import { TextInput } from "./../../../../_components/TextInput/TextInput";
import { parseJwt } from "../../../../services/utils/utilFunctions";
import {
  ownerRegisteredAddresses,
  saveOwnerAddress,
} from "../../../../services/api/owner.service";
import Loader from "../../../../_components/spinner/Loader";

class AddEditAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      addressId: "",
      addresses: [],
      formFields: {
        fullName: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        fullAddress: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        phoneNumber: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        zip: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        city: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        state: {
          valid: false,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        addressDetails: {
          valid: true,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
        comments: {
          valid: true,
          value: "",
          error: {
            flag: null,
            message: "",
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.getCurrentAddress();
  };

  getCurrentAddress = () => {
    const id = this.props.match.params.id;
    if (id) {
      const auth_token = localStorage.getItem("auth_token");
      const userDetails = parseJwt(auth_token);
      ownerRegisteredAddresses(userDetails._id).then((data) => {
        if (
          data.status === 200 &&
          data.data &&
          data.data?.address?.length > 0
        ) {
          var selectedAddress = data.data.address.find((add) => add._id === id);
          if (selectedAddress) {
            var newState = this.state.formFields;
            Object.values(this.state.formFields).forEach((el, i) => {
              let keyValue = Object.keys(this.state.formFields)[i];
              var t = this.state.formFields[keyValue];
              t.value = selectedAddress[keyValue]
                ? selectedAddress[keyValue]
                : "";
              t.valid = ["addressDetails", "comments"].includes(keyValue)
                ? true
                : selectedAddress[keyValue]
                ? true
                : false;
              newState[keyValue] = t;
            });
            this.setState({
              formFields: newState,
              addressId: id,
              addresses: data.data.address,
            });
          }
        }
      });
    }
  };

  validate = (name, value, message) => {
    const newState = { ...this.state.formFields };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;

    switch (name) {
      case "fullName":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Full Name is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "state":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "State is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "city":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "City is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "phoneNumber":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "phone Number is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "zip":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Zip code is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "fullAddress":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Address is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      default: {
        return newState;
      }
    }
  };
  onChangeHandler = (event, key) => {
    let validatedState = this.validate(key, event.target.value, undefined);
    this.setState({ formFields: validatedState });
  };

  /**
   * Calls when submitting a form
   */
  onSubmitForm = () => {
    const formValid = Object.values(this.state.formFields).every(
      (val) => val.valid === true
    );
    if (formValid) {
      this.setState({ isLoading: true });
      const checkoutModel = this.props.checkout.payload;
      let addresses = this.state.addresses;
      if (this.state.addressId !== "") {
        addresses = addresses.filter(
          (address) => address.id !== this.state.addressId
        );
      }

      let temp = {
        fullName: this.state.formFields.fullName.value,
        fullAddress: this.state.formFields.fullAddress.value,
        zip: this.state.formFields.zip.value,
        city: this.state.formFields.city.value,
        state: this.state.formFields.state.value,
        addressDetails: this.state.formFields.addressDetails.value,
        phoneNumber: this.state.formFields.phoneNumber.value,
        comments: this.state.formFields.comments.value,
      };
      if (this.state.addressId) {
        temp = { ...temp, _id: this.state.addressId };
      }
      addresses.push(temp);
      this.saveUserAddress(temp);
      checkoutModel.addresses = addresses;
      this.props.checkoutStore(checkoutModel);
      setTimeout(() => {
        this.props.history.goBack(-1);
      }, 1000);
    } else {
      Object.values(this.state.formFields).forEach((el, i) => {
        if (!el.valid) {
          let keyValue = Object.keys(this.state.formFields)[i];
          var errorState = this.validate(keyValue, el.value, undefined);
          this.setState({ formFields: errorState });
          console.log(errorState);
        }
      });
    }
  };

  saveUserAddress = (address) => {
    const payload = {
      address: address,
    };
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);
    if (userDetails && userDetails._id) {
      if (this.state.addressId) {
        saveOwnerAddress(userDetails._id, payload).then((data) => {
          console.log(data);
        });
      } else {
        saveOwnerAddress(userDetails._id, payload).then((data) => {
          console.log(data);
        });
      }
    }
  };

  render = () => {
    const { t } = this.props;
    return (
      <div className="insideWrp">
        <HeaderMenu />
        <Loader progress={this.state.isLoading} />
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className="otherspadding25"></div>

          <div className="paint_flex_with_left otherspadding25">
            <label className="paint_header">{"Address"}</label>
          </div>

          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(event) => this.onChangeHandler(event, "fullName")}
              value={this.state.formFields.fullName.value}
              error={this.state.formFields.fullName.error}
              type={"text"}
              placeholder={t("paintingother.plzname")}
              label={t("paintingother.fullname")}
            />
          </div>
          <table className="paint_table100 profilePadding30">
            <tr>
              <td className="padding_8">
                <div className="">
                  <TextInput
                    className="paint_input_border"
                    type={"text"}
                    placeholder={"Enter Address"}
                    label={"Full Address"}
                    onChange={(event) =>
                      this.onChangeHandler(event, "fullAddress")
                    }
                    value={this.state.formFields.fullAddress.value}
                    error={this.state.formFields.fullAddress.error}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="">
                  <TextInput
                    className="paint_input_border"
                    type={"number"}
                    placeholder={"12345"}
                    label={"Zip Code"}
                    onChange={(event) => this.onChangeHandler(event, "zip")}
                    value={this.state.formFields.zip.value}
                    error={this.state.formFields.zip.error}
                  />
                </div>
              </td>
            </tr>
          </table>
          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              type={"text"}
              onChange={(event) => this.onChangeHandler(event, "city")}
              value={this.state.formFields.city.value}
              error={this.state.formFields.city.error}
              placeholder={"Enter the city"}
              label={"City"}
            />
          </div>
          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(event) => this.onChangeHandler(event, "phoneNumber")}
              value={this.state.formFields.phoneNumber.value}
              error={this.state.formFields.phoneNumber.error}
              type={"number"}
              placeholder={t("paintingother.plzphone")}
              label={t("paintingother.phone")}
            />
          </div>
          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(event) => this.onChangeHandler(event, "state")}
              value={this.state.formFields.state.value}
              error={this.state.formFields.state.error}
              type={"text"}
              placeholder={"Enter the state"}
              label={"State"}
            />
          </div>
          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(event) =>
                this.onChangeHandler(event, "addressDetails")
              }
              value={this.state.formFields.addressDetails.value}
              error={this.state.formFields.addressDetails.error}
              type={"text"}
              placeholder={"Enter additional details"}
              label={"Address details (Apartment # or Building name)"}
            />
          </div>
          <div className="paintinput_lable">
            <TextInput
              className="paint_input_border"
              onChange={(event) => this.onChangeHandler(event, "comments")}
              value={this.state.formFields.comments.value}
              error={this.state.formFields.comments.error}
              type={"text"}
              placeholder={"“Please do not let the dog out. He is friendly”"}
              label={"Comments about your address"}
            />
          </div>

          <div className="profilePadding30"></div>
          <Button
            onClick={this.onSubmitForm}
            disabled={false}
            buttonLabel={"Save"}
          />

          <div className="otherspadding25"></div>
          <div className="otherspadding25"></div>
          <div className="contactblue_btn ">
            <div>
              <img src={IMAGES.other.group1} alt="" />
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <label className="condactNeedhelp">
                      {t("paintingInterior.doneedhelp")}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td onClick={() => (window.location = "/contact-us")}>
                    {" "}
                    <label className="contactuslink">
                      {t("paintingInterior.contactus")}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="paintPolygon">
            <img src={IMAGES.other.polygon} alt="" />
          </div>
        </div>

        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(AddEditAddress));
