import React from "react";
import { Route, Switch } from "react-router-dom";
import SolidHardwood from "../solidHardwood/solidHardwood";
import { SHSizeOfFloor } from "./SizeOfFloor";
import { SHHaveSolidHardwood } from "./haveSolidHardwood";
import { SHIsFinished } from "./IsFinished";
import { SHWoodDetails } from "./TypeOfWood";
import { SHTypeOfInstallation } from "./TypeOfInstallation";
import { SHPriorFloor } from "./currentFloor";
import { SHAddOn } from "./AddOn";
import { SHPersonalInfo } from "./personalInfo";
import { SHOrderSummary } from "./OrderSummary";
import { useTranslation } from "react-i18next";
import StartingDate from "../../projectinitiateComponents/startingDate";

const solidHardWoodRoutes = [
  // {
  //   path: "/contractor-portfolio/:id/floors/solidhardwood",
  //   component: SolidHardwood,
  // },
  {
    path: "/contractor-portfolio/:id/floors/solid-hardwood/floor-size/:stepValue",
    component: SHSizeOfFloor,
  },
  {
    path: "/contractor-portfolio/:id/floors/solid-hardwood/have-solid-hardwood/:stepValue",
    component: SHHaveSolidHardwood,
  },
  {
    path: "/contractor-portfolio/:id/floors/solid-hardwood/solid-hardwood-finished/:stepValue",
    component: SHIsFinished,
  },
  {
    path: "/contractor-portfolio/:id/floors/solid-hardwood/wood-details/:stepValue",
    component: SHWoodDetails,
  },
  {
    path: "/contractor-portfolio/:id/floors/solid-hardwood/type-of-installation/:stepValue",
    component: SHTypeOfInstallation,
  },
  {
    path: "/contractor-portfolio/:id/floors/solid-hardwood/current-floor/:stepValue",
    component: SHPriorFloor,
  },
  {
    path: "/contractor-portfolio/:id/floors/solid-hardwood/add-ons/:stepValue",
    component: SHAddOn,
  },
  {
    path: "/contractor-portfolio/:id/floors/solid-hardwood/personal-info/:stepValue",
    component: SHPersonalInfo,
  },
  {
    path: "/contractor-portfolio/:id/floors/solid-hardwood/order-summary/:stepValue",
    component: SHOrderSummary,
  },
];

export default solidHardWoodRoutes;
