import React, { useRef, useState } from "react";
import { extractImageUrlsFromPicOfWork } from "../../services/utils/utilFunctions";
import { useSelector } from "react-redux";

import IMAGES from "../../_assets/images";
import Carousel, { CarouselItem } from "./custom-carousel/CustomCarousel";
import "./bootstrap-styles.scss";
import { getContractors } from "../../services/api/contractor.service";
import {
  addContractorToWishlist,
  fetchWishlist,
  removeContractFromToWishlist,
} from "../../services/api/owner.service";
import { useRecoilState } from "recoil";
import {
  contractorsList,
  wishlistedContractorsAtom,
  wishlistedList,
} from "../../recoil/atoms/contractorAtom";
import useNotify from "../../_components/Notification/UseNotify";
import { selectedCategory } from "../../recoil/atoms/categoryAtom";
import { useHistory } from "react-router-dom";
import { getData } from "../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../_configs/constants.config";
import { useTranslation } from "react-i18next";

const ContractorCard = ({ contractor, isWishListed }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const { confirm } = useNotify();
  const history = useHistory();
  const reduxState = useSelector((state) => state?.buttonReducer);
  const selectedCategoryId = reduxState?.selectedCategory.selectedCategory;
  const [wishlisted, setWishlisted] = useRecoilState(wishlistedList);
  const [wishlistedContractors, setWishlistedContractors] = useRecoilState(
    wishlistedContractorsAtom
  );
  const [selected, setSelected] = useRecoilState(selectedCategory);
  const [isLoading, setIsLoading] = useState(true);

  const getSliderData = () => {
    if (selectedCategoryId) {
      let picOfWorks = contractor.picOfWork.filter(
        (picOfWorkAs) => picOfWorkAs.categoryId === selectedCategoryId
      );
      picOfWorks = extractImageUrlsFromPicOfWork(picOfWorks);
      if (picOfWorks?.length > 0) {
        return picOfWorks.map((img, index) => {
          return (
            <CarouselItem key={index}>
              <img className="car-img" src={img} />
            </CarouselItem>
          );
        });
      } else {
        return (
          <CarouselItem>
            <img className="car-img" src={IMAGES.home.carouselDefaultImage} />
          </CarouselItem>
        );
      }
    }
  };

  const {
    name,
    key,
    _id,
    emiDuration,
    estimatedPrice,
    estimatedPriceEmi,
    icon,
    jobDuration,
  } = selected;

  const getRatingCount = (rating) => {
    const count =
      parseInt(rating?.rating1 || 0) +
      parseInt(rating?.rating2 || 0) +
      parseInt(rating?.rating3 || 0) +
      parseInt(rating?.rating4 || 0) +
      parseInt(rating?.rating5 || 0);
    if (count == 0) {
      return t("contractor.new");
    }
    return `(${count})`;
  };

  const getWishList = () => {
    setIsLoading(true);
    fetchWishlist(reduxState?.selectedCategory?.selectedCategory)
      .then((result) => {
        setIsLoading(false);
        if (result && result?.data?.wishlists?.length > 0) {
          setWishlisted(result?.data?.wishlists);
          let arr = [];
          result?.data?.wishlists?.forEach((item) => {
            arr.push(item?.contractor?._id);
          });
          setWishlistedContractors(arr);
        } else {
          setWishlisted([]);
          setWishlistedContractors([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleWishListClick = async (e) => {
    e.preventDefault();
    const user = getData(LOCAL_STORAGE.user);
    if (user) {
      if (isWishListed) {
        try {
          const res = await removeContractFromToWishlist(contractor?.id);
          if (res.status == 200) {
            getWishList();
          } else {
            confirm("error", "Something went wrong !!!");
          }
        } catch (err) {
          confirm("error", "Failed to remove from wishlist");
        }
      } else {
        try {
          const res = await addContractorToWishlist(contractor?.id);
          if (res.status == 200 && res?.data?.status) {
            getWishList();
          } else {
            confirm("error", "Something went wrong !!!");
          }
        } catch (err) {
          confirm("error", "Failed to add to wishlist");
        }
      }
    } else {
      confirm("error", "Sign in as owner to continue");
      history.push("/signin");
    }
  };

  return (
    <div ref={ref} className="position-relative mb-5">
      <Carousel>{getSliderData()}</Carousel>

      <div className="favourite-icon">
        <img
          onClick={(e) => handleWishListClick(e)}
          src={isWishListed ? IMAGES.home.redHeart : IMAGES.home.heartWhite}
        />
      </div>
      <div className="contractor-info">
        <div className="first">
          <p className="name">{contractor?.name}</p>
          <p className="rating d-flex align-items-center">
            <img className="me-2" src={IMAGES.home.starBlack} />{" "}
            <span className="me-2">{contractor?.rating?.rating}</span>
            <span className="">
              {contractor?.rating ? getRatingCount(contractor?.rating) : 0}
            </span>
          </p>
        </div>
        <div className="second">
          {estimatedPrice && estimatedPriceEmi ? (
            <p>
              {t("contractor.from")} ${estimatedPrice} {t("contractor.or")} $
              {estimatedPriceEmi}
              /mo.
            </p>
          ) : (
            ""
          )}
          {emiDuration ? (
            <p>
              {t("contractor.for")} {emiDuration}/mo.{" "}
              {t("contractor.afterCredit")}
            </p>
          ) : (
            ""
          )}
          {jobDuration ? (
            <strong>
              {jobDuration} {t("contractor.handOver")}
            </strong>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ContractorCard;
