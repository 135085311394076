import { HvacOrderSummary } from "./OrderSummary";
import { HvacAddOns } from "./addOns";
import { RepairAgreement } from "./repairAgreement";
import { HvacRepairReplacement } from "./repairReplacements";
import { ReplaceOrNewHvac } from "./replaceOrNewHvac";
import { HvacSelectBrand } from "./selectBrand";
import { HvacSizeDetails } from "./sizeDetails";
import { TypeOfHvacUnit } from "./typeOfHvacUnit";
import { TypeOfInstallation } from "./typeOfInstallation";
import { HvacTypeofService } from "./typeOfService";

export const hvacRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/hvac/type-of-service",
    component: HvacTypeofService,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/hvac/replace-or-new/:stepValue",
    component: ReplaceOrNewHvac,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/hvac/type-of-installation/:stepValue",
    component: TypeOfInstallation,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/hvac/type-of-unit/:stepValue",
    component: TypeOfHvacUnit,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/hvac/size-details/:stepValue",
    component: HvacSizeDetails,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/hvac/select-brand/:stepValue",
    component: HvacSelectBrand,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/hvac/add-ons/:stepValue",
    component: HvacAddOns,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/hvac/order-summary/:stepValue",
    component: HvacOrderSummary,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/hvac/replacement-options/:stepValue",
    component: HvacRepairReplacement,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/hvac/agreement/:stepValue",
    component: RepairAgreement,
  },
];
