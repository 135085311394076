import React from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../homeComponents/footerSection/Footer.comp";

export const FloorOrderSummary = (props) => {
  return (
    <div className="service">
      <HeaderMenu />
      <div className="home-section">
        <div className="main_button">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={IMAGES.home.step7} width="75px" alt="" />
            <p>Summary</p>
            <br />
          </div>
          <div style={{ borderLeft: "0.5px solid #D8D8D8", height: "90px" }}>
            <img
              style={{ marginTop: "10px", marginLeft: "10px" }}
              src={IMAGES.home.piggy}
              width="40px"
              alt=""
            />
            <p style={{ marginTop: "-11px", marginLeft: "10px" }}>$0000</p>
          </div>
        </div>
        <br />

        <div className="sevic_title">Order summary</div>

        <p className="sub_hdg"> Tiles flooring estimation</p>
        <br />
        {items.map((item, index) => (
          <div key={index}>
            <div
              className="sub_btn"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                {" "}
                <div className="bold" style={{ fontSize: "12px" }}>
                  {" "}
                  {item.heading}
                </div>
                <div style={{ fontSize: "16px" }}>{item.text}</div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <img src={item.img} alt="" width="10px" />
                <p>{item.price}</p>
                <div className="blue_head">Edit</div>
              </div>
            </div>
          </div>
        ))}

        <br />
        <div className="blue_btn">
          <div>
            <img src={IMAGES.other.group1} height="60px" alt="" />
          </div>
          <div>
            <p>Do you need Help?</p>
            <p
              style={{
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              Contact us
            </p>
          </div>
        </div>

        <div
          className="button"
          style={{
            marginTop: "50px",
            padding: "20px 63px",
            backgroundColor: "#D8D8D8",
            color: "#ADADAD",
          }}
        >
          Continue to next step
        </div>
      </div>
      <Footer />
    </div>
  );
};

const items = [
  {
    heading: "Floor sizing",
    text: "500sqft",
    img: IMAGES.home.green_plus,
    price: "$0000.-",
  },
  {
    heading: "Current floor",
    text: "Glue down",
    img: IMAGES.home.green_plus,
    price: "$0000.-",
  },
  {
    heading: "Elevator requiered",
    text: "Yes",
    img: IMAGES.home.green_plus,
    price: "$0000.-",
  },
  {
    heading: "Add on´s",
    text: "Shoe molding baseboards, ....",
    img: IMAGES.home.green_plus,
    price: "$0000.-",
  },
];
