import React from "react";
import "./TextInput.css";
import IMAGES from "../../_assets/images/index";
import { Tick } from "../../_components/Tickandwrongsvg/tick";
import { Exclamation } from "../../_components/Tickandwrongsvg/exclamation";
const TextInput = (props) => {
  let classes = [props.className, ""];

  let labelClasses = props.required
    ? ["label", "required-asterisk-field"]
    : ["label"];

  if (props.error.flag === null) {
    classes.push("input-text");
  }

  if (props.error.flag === true) {
    classes.push("input-text is-not-valid");
  }
  if (props.error.flag === false) {
    classes.push("input-text is-valid");
  }

  return (
    // <div className={classes.join(" ")}>
    //   {props.label !== false && props.label !== "false" ? (
    //     <div className={labelClasses.join(" ")} dangerouslySetInnerHTML={{ __html: props.label }}></div>
    //   ) : null}
    //   <input
    //     {...props}
    //     className={classes}

    //     type={props.type}
    //     placeholder={props.placeholder}
    //    img={props.img}
    //     disabled={props.disabled}
    //     readOnly={props.readOnly}
    //     pattern={props.pattern}
    //     value={props.value}
    //   />
    //   {props.error.flag ? <div>{props.error.message || ""}</div> : null}

    // </div>

    <div className={classes.join(" ")}>
      {props.label !== false && props.label !== "false" ? (
        <div
          className={labelClasses.join(" ")}
          dangerouslySetInnerHTML={{ __html: props.label }}
        ></div>
      ) : null}
      <input
        {...props}
        select={props.select ? "true" : "false"}
        error={props.errorText ? "true" : "false"}
        className={classes}
        type={props.type}
        placeholder={props.placeholder}
        label={props.label}
        img={props.img}
        disabled={props.disabled}
        readOnly={props.readOnly}
        pattern={props.pattern}
        value={props.value}
        onWheel={props?.onWheel}
      />
      {props.error.flag ? (
        <div className="flex-justify-fix">
          <div className="signup_validation_password_icon  ">
            {/* <img
              src={IMAGES.other.warning}
              alt=""
              className="signup_validation_icon_img"
            /> */}
            <Exclamation
              width="40px"
              height="40px"
              fill="white"
              backgroundColor="red"
            />
          </div>
          <div>{props.error.message || ""}</div>
        </div>
      ) : null}
      {props.error.flag === false ? (
        <div className="flex-justify-fix">
          <div className="signup_tick_icon ">
            {/* <img
              src={IMAGES.other.tick}
              alt=""
              className="signup_validation_icon_img"
            /> */}
            <Tick width="30px" height="30px" />
          </div>
          <div style={{ color: "blue" }}>{props.error.message || ""}</div>
        </div>
      ) : null}
    </div>
  );
};

TextInput.defaultProps = {
  error: {
    flag: null,
    message: null,
  },
  type: "text",
  placeholder: "",
  label: undefined,
  className: "",
  readOnly: false,
};

export { TextInput };
