import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import NewFooter from "../homeComponents/footerSection/NewFooter";
import IMAGES from "../../_assets/images";
import ContractorCard from "./ContractorCard";
import Categories from "../homeComponents/headerSection/Categories";
import Header from "../homeComponents/headerSection/Header";
import { latLonState } from "../../recoil/atoms/headerAtom";
import { fetchWishlist } from "../../services/api/owner.service";
import { getContractorsUsingLocation } from "../../services/api/contractor.service";
import { extractImageUrlsFromPicOfWork } from "../../services/utils/utilFunctions";
import {
  wishlistedContractorsAtom,
  wishlistedList,
} from "../../recoil/atoms/contractorAtom";
import { getData } from "../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../_configs/constants.config";
import useNotify from "../../_components/Notification/UseNotify";

const Favourites = () => {
  const [contractors, setContractors] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();
  const { confirm } = useNotify();
  const [latLonStateValue, setLatLonState] = useRecoilState(latLonState);
  const reduxState = useSelector((state) => state?.buttonReducer);
  const selectedCategoryId = reduxState?.selectedCategory?.selectedCategory;
  const [wishlisted, setWishlisted] = useRecoilState(wishlistedList);
  const [wishlistedContractors, setWishlistedContractors] = useRecoilState(
    wishlistedContractorsAtom
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const user = getData(LOCAL_STORAGE.user);
    if (user) {
      setIsLoading(true);
      setIsLoggedIn(true);
      fetchWishlist(reduxState?.selectedCategory?.selectedCategory)
        .then((result) => {
          let contractorsArr = [];

          if (result && result?.data?.wishlists?.length > 0) {
            for (
              let index = 0;
              index < result?.data?.wishlists.length;
              index++
            ) {
              const con = result?.data?.wishlists[index]?.contractorProfile;
              if (con.contractorId) {
                contractorsArr.push({
                  id: con.contractorId,
                  name: result?.data?.wishlists[index]?.contractor?.name,
                  images: extractImageUrlsFromPicOfWork(con.picturesOfWork),
                  picOfWork: con.picturesOfWork,
                  highlight: con.highlight,
                  rating: con.ratingInfo ? con.ratingInfo : "N/A",
                  portfolioUrl: `/portfolio/${con.contractorId._id}`,
                });
              }
            }
            setContractors(contractorsArr);
            setIsLoading(false);
            setIsEmpty(false);
          } else {
            setIsLoading(false);
            setIsEmpty(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setIsLoggedIn(false);
      setIsLoading(false);
      // history.push("/signin");
      confirm("info", "Sign in as owner to view wishlist");
    }
  }, [
    reduxState?.selectedCategory?.selectedCategory,
    wishlisted,
    wishlistedContractors,
  ]);

  useEffect(() => {
    if (latLonStateValue?.lat && latLonStateValue?.lon) {
      setIsLoading(true);

      getContractorsUsingLocation(
        reduxState?.selectedCategory?.selectedCategory,
        latLonStateValue?.lat,
        latLonStateValue?.lon
      )
        .then((result) => {
          if (result && result?.length > 0) {
            setContractors(result);
          } else {
            setIsLoading(false);
            setIsEmpty(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [latLonStateValue]);

  return (
    <>
      <Header />
      <Categories />
      <div className="px-3 px-lg-5">
        <div className="mt-5">
          {isLoading ? (
            <p className="text-info">{t("home.loading")}</p>
          ) : !isLoggedIn ? (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ minHeight: "50vh" }}
            >
              <p className="text-info">{t("home.signinAsOwner")}</p>
              <div className="d-flex">
                <Link
                  className="btn btn-info text-white w-auto mx-2"
                  to="/signin"
                >
                  sign in
                </Link>
                <Link className="btn btn-info text-white w-auto" to="/role">
                  sign up
                </Link>
              </div>
            </div>
          ) : !isEmpty ? (
            <div className="contractors-container row px-0">
              {contractors?.map((contractor) => (
                <div className="contractor-item col-12 col-md-6 col-lg-4 col-xl-3">
                  <Link to={`/contractor-portfolio/${contractor?.id}`}>
                    <ContractorCard
                      isWishListed={true}
                      contractor={contractor}
                    />
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-info">{t("home.contractorsNotAvailable")}</p>
          )}
        </div>
      </div>
      <div className="mobile-bottom-nav d-flex d-lg-none">
        <Link to="/" className="d-flex flex-column">
          <img src={IMAGES.home.searchBlack} />
          <span>{t("footer.search")}</span>
        </Link>
        <Link to="/favorites" className="d-flex flex-column">
          <img src={IMAGES.home.heartOulined} />
          <span>{t("footer.favorites")}</span>
        </Link>
        <Link to="/" className="d-flex flex-column">
          <img src={IMAGES.home.defaultUser} />
          <span>{t("footer.profile")}</span>
        </Link>
      </div>
      <div className="fixed-footer">
        <NewFooter />
      </div>
    </>
  );
};

export default Favourites;
