import { useState, useCallback } from "react";

export const DebouncedInput = ({
  onInputChange,
  debounceTime,
  value,
  setValue,
  ...props
}) => {
  const handleInputChange = useCallback(
    (event) => {
      console.log(event);
      const newValue = event.target.value;
      setValue(newValue);

      setTimeout(() => {
        onInputChange(newValue);
      }, debounceTime);
    },
    [onInputChange, debounceTime]
  );

  return (
    <input
      style={{ fontFamily: "Poppins" }}
      value={value}
      onChange={handleInputChange}
      {...props}
      className={`search-input ${props?.className}`}
    />
  );
};
