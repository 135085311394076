import { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";

const ScrollAction = ({ children, location: { pathname } }) => {
  const loc = useLocation();
  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth",
    // });
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollAction);
