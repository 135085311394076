import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { NOTIFICATION_URL } from "../../_configs/constants.config";

export const NotificationsNavigations = () => {
  let history = useHistory();

  // const broadcast = new BroadcastChannel("count-channel");

  // useEffect(() => {
  //   broadcast.onmessage = (event) => {
  //     if (event.data.type === "NOTIFICATION_CLICK") {
  //       const { payload } = event.data;

  //       let url = NOTIFICATION_URL["default"];
  //       if (Boolean(payload?.data?.twi_body)) {
  //         history.push({
  //           pathname: "/user/chat",
  //           search: `sid=${""}`,
  //         });
  //       } else if (
  //         payload.data["routeParam"] &&
  //         payload.data["receiverType"] === "owner"
  //       ) {
  //         history.push("/user/view-project" + payload.data["routeParam"]);
  //       } else if (
  //         payload.data["routeParam"] &&
  //         payload.data["receiverType"] === "contractor"
  //       ) {
  //         url = "/contractor-projects";
  //         history.push({
  //           pathname: url,
  //           search: `id=${payload.data["routeParam"].split("/")[1]}`,
  //         });
  //       }
  //       history.push(url);
  //     }
  //   };
  // }, []);

  return <></>;
};
