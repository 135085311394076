import React, { forwardRef, useEffect, useRef, useState } from "react";
import IMAGES from "../../_assets/images";
import checked from "./images/checked.svg";
import Star from "./images/Star.svg";
import Ellipse from "./images/Ellipse.png";
import Chat from "./images/Chat.svg";
import Info from "./images/Info.png";
import InfoBlack from "./images/InfoBlack.svg";
import { Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectedCategory } from "../../recoil/atoms/categoryAtom";
import { useRecoilState } from "recoil";

const ContractorDetailCard = forwardRef(
  ({ contractorDetails, reviewsArray, onChatClick }, ref) => {
    const {
      ratingInfo,
      managerExperience,
      profileImage,
      highlight,
      services,
      contractorId,
    } = contractorDetails;

    const [selectedCat, setSelectedCat] = useRecoilState(selectedCategory);

    const {
      name,
      key,
      _id,
      emiDuration,
      estimatedPrice,
      estimatedPriceEmi,
      icon,
      jobDuration,
    } = selectedCat;

    const { t } = useTranslation();
    const [showFullText, setShowFullText] = useState(false);
    const [textOverflow, setTextOverflow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const textRef = useRef(null);
    const textClassName = showFullText ? "text full" : "text";

    const getRatingCount = (rating) => {
      const count =
        parseInt(rating?.rating1 || 0) +
        parseInt(rating?.rating2 || 0) +
        parseInt(rating?.rating3 || 0) +
        parseInt(rating?.rating4 || 0) +
        parseInt(rating?.rating5 || 0);
      return count;
    };

    const pairs = services.reduce((result, object, index) => {
      if (index % 2 === 0) {
        result.push([object]);
      } else {
        result[result.length - 1].push(object);
      }
      return result;
    }, []);

    const rows = pairs.map((pair, index) => (
      <div className="row service-item d-flex flex-wrap" key={index}>
        <div className="col-6 col-lg-5 d-flex align-items-center">
          <div className="">
            <img src={pair[0]?.["categoryId"]?.icon} />
          </div>

          <p className="service-name">{pair[0]?.["category_name"]}</p>
        </div>

        {pair[1] && (
          <div className="col-6 col-lg-5 d-flex align-items-center">
            <div className="">
              <img src={pair[1]?.["categoryId"]?.icon} />
            </div>
            <p className="service-name">{pair[1]?.["category_name"]}</p>
          </div>
        )}
      </div>
    ));

    const handleShowMoreClick = () => {
      setShowFullText(!showFullText);
    };

    const hideOrShowbtn = () => {
      if (textRef?.current)
        if (textRef?.current?.offsetHeight < textRef?.current?.scrollHeight) {
          setTextOverflow(true);
        } else {
          setTextOverflow(false);
        }
    };

    useEffect(() => {
      const handleResize = () => {
        // hideOrShowbtn();
      };

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
      hideOrShowbtn();
    }, [contractorDetails?.description, textRef?.current]);

    return (
      <>
        <div className="contractor-detail-card first" ref={ref}>
          <div className="contractor-info d-block d-lg-none">
            <div className="first">
              <p className="name">{contractorId?.name}</p>
              <p className="rating d-flex align-items-center">
                <img className="me-2" src={IMAGES.home.starBlack} />{" "}
                <span className="me-2">{ratingInfo?.rating}</span>
                <span className="">
                  ({ratingInfo ? getRatingCount(ratingInfo) : 0})
                </span>
              </p>
            </div>
            <div className="second">
              {estimatedPrice && estimatedPriceEmi ? (
                <p>
                  {t("contractor.from")} ${estimatedPrice} {t("contractor.or")}{" "}
                  ${estimatedPriceEmi}/mo.
                </p>
              ) : (
                ""
              )}
              {emiDuration ? (
                <p>
                  {t("contractor.for")} {emiDuration}/mo.{" "}
                  {t("contractor.afterCredit")}
                </p>
              ) : (
                ""
              )}
              {jobDuration ? (
                <strong>
                  {jobDuration} {t("contractor.handOver")}
                </strong>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="details-card-primary d-none d-lg-flex">
            <div className="details-card-left">
              <div className="d-flex">
                <div className="contractor-image-container">
                  <img
                    className="contractor-img"
                    src={profileImage ? profileImage : IMAGES.other.person}
                  />
                  <img className="checked-image" src={checked} alt="checked" />
                </div>
                <div className="d-flex flex-column ps-4 justify-content-center contractor-infos">
                  <p className="contractor-name mb-0">{contractorId?.name}</p>
                  <p className="d-flex align-items-center contractor-rating mb-0">
                    <img height={20} width={20} src={Star} /> &nbsp;
                    <span className="me-1">{ratingInfo?.rating || 0} </span>
                    <span className="mb-2 me-1">.</span>
                    <span className="">
                      {ratingInfo?.rating ? getRatingCount(ratingInfo) : 0}{" "}
                      {t("contractor.reviews")}
                    </span>
                  </p>
                  {contractorDetails?.contractorId?.isVerified ? (
                    <div className="d-flex w-100 justify-content-between align-items-center mt-4">
                      <img className="me-1" src={IMAGES.home.verified} />
                      <p className="verified">
                        {t("contractor.verified")}
                      </p>{" "}
                      <img src={Info} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div
              className={`d-flex details-card-right flex-column justify-content-center  ${
                contractorDetails?.contractorId?.isVerified
                  ? "mb-5 mt-2"
                  : "mt-4 mb-0"
              }`}
            >
              <p className="text-end mb-0">
                {ratingInfo?.rating ? ratingInfo?.rating?.toString() : 0}{" "}
                {t("contractor.hirings")}
              </p>

              <p className="text-end mt-2">
                {managerExperience} {t("contractor.experience")}
              </p>
            </div>
          </div>

          <div className="details-card-mobile d-flex d-lg-none">
            <div style={{ maxWidth: "40%" }}>
              <div className="contractor-image-container">
                <img
                  className="contractor-image"
                  src={profileImage ? profileImage : IMAGES.other.person}
                />
              </div>
            </div>
            <div className="d-flex ms-3 align-items-start flex-column">
              <p className="name">{contractorId?.name}</p>
              <p className="exp">
                {contractorDetails?.hirings || 0} {t("contractor.hirings")}{" "}
                <span className="">|</span> {managerExperience || 0}{" "}
                {t("contractor.experience")}
              </p>
              {contractorDetails?.contractorId?.isVerified ? (
                <div className="d-flex flex-wrap align-items-center w-100 align-self-center">
                  <img height={20} width={20} src={IMAGES.home.verified} />{" "}
                  <p className="verified">{t("contractor.verified")}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="d-block d-lg-none details-card-secondary">
            <h1 className="portfolio-sub-head">
              <span>Any</span>
              Protection:
            </h1>
            <p className="m-0 p-0">{t("contractor.protectionText")}</p>
            {!showModal ? (
              <p className="m-0 p-0 mt-2">
                <span onClick={() => setShowModal(true)}>
                  {t("contractor.learnMore")}
                </span>
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="description">
            <h1 className="portfolio-sub-head">{t("contractor.bio")}:</h1>

            <span className="d-flex flex-column">
              <span ref={textRef} className={textClassName}>
                {contractorDetails?.description}
              </span>
              {textOverflow && (
                <button onClick={handleShowMoreClick}>
                  {showFullText ? "Show Less" : "Show More"}
                  <img src={IMAGES?.home?.rightArrow} />
                </button>
              )}
            </span>
          </div>
          <div className="d-none d-lg-block details-card-secondary">
            <h1 className="portfolio-sub-head">
              <span>Any</span>
              Protection:
            </h1>
            <p className="m-0 p-0">{t("contractor.protectionText")}</p>
            {!showModal ? (
              <p className="m-0 p-0 mt-2">
                <span onClick={() => setShowModal(true)}>
                  {t("contractor.learnMore")}
                </span>
              </p>
            ) : (
              ""
            )}
          </div>

          <div className="services">
            <h1 className="portfolio-sub-head">{t("contractor.expertise")}:</h1>
            <div className="d-flex flex-column">{rows}</div>
          </div>
          <div className="d-none d-lg-block w-100 mt-5">
            <button
              onClick={() => onChatClick(contractorDetails)}
              className="chat-btn"
            >
              <img src={Chat} />
              {t("contractor.chat")}
            </button>
          </div>
        </div>
        <Modal
          classeName="modal-image"
          open={showModal}
          onClose={() => setShowModal(false)}
          style={{ overflowY: "auto" }}
        >
          <div className="protection-container" style={{ overflowY: "auto" }}>
            <div className="close-btn">
              <img
                onClick={() => setShowModal(false)}
                src={IMAGES.home.modalClose}
              />
            </div>
            <div
              style={{ overflow: "auto", width: "100%" }}
              className="protection-inner"
            >
              <div className="protection-head">
                <h1 className="main-head">
                  <span>Any</span>
                  Protection
                </h1>
                <h3 className="sub-head">
                  {t("contractor.protectionSubHead")}
                </h3>
              </div>
              <div className="protection-body">
                <h6>{t("contractor.verificationHead")}</h6>
                <p>{t("contractor.verificationBody")}</p>
                <h6>{t("contractor.protected")}</h6>
                <p>{t("contractor.protectedBody")}</p>
                <h6>{t("contractor.damage")}</h6>
                <p>{t("contractor.damageBody")}</p>
                <h6>{t("contractor.warranty")}</h6>
                <p>{t("contractor.warrantyBody")}</p>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
);

export default ContractorDetailCard;
