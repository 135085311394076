import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import signupReducer from "./signup.reducer";
import buttonReducer from "./button.reducer";
import checkoutReducer from "./checkout.reducer";
import contractorReducer from "./contractor.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "signupReducer",
    "buttonReducer",
    "checkout",
    "contractorReducer",
  ],
};

const rootReducer = combineReducers({
  signupReducer: signupReducer,
  buttonReducer: buttonReducer,
  checkout: checkoutReducer,
  contractor: contractorReducer
});

export default persistReducer(persistConfig, rootReducer);
