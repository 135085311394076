import React from "react";
import "./threedman.css";
import IMAGES from "../../_assets/images/index";
function Threedmansvg(props) {
    const { className,value } = props;
  return (
    <div className={`${className} allCenter`}>
    <div className={`${value}`}>
      <div className="allCenter">
      <img  height="220px"  src={IMAGES.other.threedContractor} alt="" />
         
      </div></div></div>
     

  );
}

export default Threedmansvg;
