import React, { useEffect, useState } from "react";

import "../../_styles/localStyles/typeOfService.css";

import { useTranslation } from "react-i18next";

import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";

import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { basementModel } from "../../_redux/Models/basement.model";
import { useDispatch, useSelector } from "react-redux";
import { basementStore, checkoutStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const AddOn = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 7;
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [selectedAddons, setSelectedAddons] = useState([]);

  const basementState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.basementPayload
      ? state.buttonReducer.basementPayload
      : basementModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(calculateTotalCost(basementState.costFor)); // To keep the total price in state
  const [addonOptions, setAddonOptions] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const basementSize = parseFloat(basementState.basementSize);
    if (mounted === true && priceDetails) {
      const costlyItems = ["shoe_molding", "quater_rounds", "baseboards"];
      const addOnsArr = priceDetails.addOns.map((op) => {
        return {
          text: t(`basement.${op.key}`),
          value: op.key,
          img: IMAGES.other.green_plus,
          checked: false,
          price: costlyItems.includes(op.key)
            ? basementSize * 0.3 * op.price
            : basementSize * op.price,
        };
      });
      setAddonOptions(addOnsArr);
    }
    return () => (mounted = false);
  }, [basementState.basementSize, checkoutModelState, t]);

  /**
   * Setting the total price of the selected services
   */
  const setTotalPrice = (selected) => {
    let totalPrice = calculateTotalCost(basementState.costFor);
    let total = selected.reduce((p, c) => p + c.price, 0);
    setPrice(total + totalPrice);
  };

  /**
   * Function to save Selected add ons and take user to next step
   * @returns {void}
   */
  const onSaveAddOns = () => {
    const _selectedAddons = addonOptions.filter((item) => item.checked);
    basementState.addOns = _selectedAddons;
    basementState.costFor.addOns =
      price - calculateTotalCost(basementState.costFor);
    dispatch(basementStore(basementState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/basement/order-summary/8`
    );
  };

  const updateSelectedAddon = (item, idx, isChecked) => {
    let _selectedAddons = [...selectedAddons];
    let _addons = [...addonOptions];

    const getQuaterIndex = _addons.findIndex(
      (item) => item.text === "Quarter rounds"
    );
    const getShoeIndex = _addons.findIndex(
      (item) => item.text === "Shoe molding"
    );

    _addons[idx].checked = isChecked;

    if (item.text === "Shoe molding" && getQuaterIndex >= 0) {
      _addons[getQuaterIndex].checked = false;
    } else if (item.text === "Quarter rounds" && getShoeIndex >= 0) {
      _addons[getShoeIndex].checked = false;
    }

    let duplicateIndex = _selectedAddons.findIndex((element) => {
      if (element.text && element.text === item.text) return element;
    });
    if (duplicateIndex >= 0) {
      _selectedAddons.splice(duplicateIndex, 1);
    } else _selectedAddons.push(item);
    setTotalPrice(_selectedAddons);
    setSelectedAddons(_selectedAddons);
  };

  return (
    <div className="service insideWrp">
      <div>
        <div className="paint-home-section pcWidth">
          <div className="sevice_title">{t("basement.addons")}</div>
          <label
            className="sub_heading"
            style={{ marginTop: "20px", fontSize: "14px" }}
          >
            {" "}
            {t("basement.selectAddons")}
          </label>
          <div className="m-t-32 height_auto">
            {addonOptions.map((item, index) => (
              <label
                key={index}
                className={`floor-selection-radio py-2 ${
                  selectedAddons.includes(item) ? "selected" : ""
                }`}
              >
                <div
                  style={{
                    maxWidth: "200px",
                    width: " 100%",
                    textAlign: "left",
                    lineHeight: "1.5",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span>
                    {item.text}
                    {[
                      "carpet_installation",
                      "laminated_floor",
                      "tile_floor",
                    ].includes(item.value)
                      ? "*"
                      : null}
                  </span>
                  {item.text === "Shoe molding" && (
                    <span style={{ fontSize: "10px", maxWidth: "300px" }}>
                      Smaller and thinner molding than quarter rounds for the
                      joints of your floors with the wall.
                    </span>
                  )}
                </div>
                <div style={{ display: "flex", placeItems: "center" }}>
                  <div style={{ paddingTop: "3px" }}>
                    <img
                      width="15"
                      height="15"
                      src={IMAGES.other.green_plus}
                      alt=""
                    />
                  </div>
                  <div className="d-flex justify-content-end">{`$${formatPrice(
                    item.price
                  )}`}</div>
                </div>
                <input
                  type="checkbox"
                  value={item.text}
                  name={index}
                  checked={selectedAddons.includes(item)}
                  onChange={() => {
                    setSelectedAddons((previousState) => {
                      let state;
                      if (previousState.includes(item)) {
                        state = previousState.filter((i) => i !== item);
                      } else {
                        state = [...previousState, item];
                      }
                      if (item.value === "shoe_molding") {
                        state = state.filter(
                          (s) => s.value !== "quater_rounds"
                        );
                      } else if (item.value === "quater_rounds") {
                        state = state.filter((s) => s.value !== "shoe_molding");
                      }
                      setTotalPrice(state);
                      return state;
                    });
                  }}
                />
              </label>
            ))}
            <label className="paint_every_price_txt py-3">
              {t("basement.installationOnly")}
            </label>
          </div>
          <Button
            disabled={false}
            buttonLabel={t("addOn.continue")}
            onClick={onSaveAddOns}
          />
        </div>
      </div>
    </div>
  );
};
