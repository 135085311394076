import React, {useState } from "react";
// import kitchenContent from "../../_container/kitchenComponents/kitchenContent";
import SliderContent from "../../_container/bathroomComponents/SliderContent";
import Dots from "../../_components/Slider/Dots";
import Arrows from "../../_components/Slider/Arrows";
import fashionedImages from "../../_container/bathroomComponents/fashionedImages";


import "../../_styles/localStyles/kitchen.css"


const len = fashionedImages.length - 1;

function Fashionedslider(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="kitchen-slider-container">
      <SliderContent activeIndex={activeIndex} sliderImage={fashionedImages} />
     
      <Dots
        activeIndex={activeIndex}
        sliderImage={fashionedImages}
        onclick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
}

export default Fashionedslider;