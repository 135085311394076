import { PlumbingOrderSummary } from "./OrderSummary";
import { PlumbingEmergencyComments } from "./emergencyComments";
import { PlumbingHowUrgentService } from "./howUrgent";
import { PlumbingSelectItems } from "./selectItems";
import { PlumbingTypeofService } from "./typeOfService";

export const plumbingRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/plumbing/type-of-service",
    component: PlumbingTypeofService,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/plumbing/how-urgent/:stepValue",
    component: PlumbingHowUrgentService,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/plumbing/comments/:stepValue",
    component: PlumbingEmergencyComments,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/plumbing/select-items/:stepValue",
    component: PlumbingSelectItems,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/plumbing/order-summary/:stepValue",
    component: PlumbingOrderSummary,
  },
];
