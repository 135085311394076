import React, { useState, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import CheckBox from "../../_components/CheckBox/checkBox";
import { Button } from "../../_components/Button/Button";

import {
  checkoutStore,
  electricStore,
  plumbingStore,
} from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import { plumbingModel } from "../../_redux/Models/plumbing.model";
import { DropDown } from "../../_components/dropDown/dropDown";
import { TextInput } from "../../_components/TextInput/TextInput";
import { isInteger } from "lodash";
import SelectSearch from "react-select-search";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const PlumbingSelectItems = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.plumbingPayload
      ? state.buttonReducer.plumbingPayload
      : plumbingModel
  );

  const [price, setPrice] = useState(
    calculateTotalCost(state.costFor, ["addOns"])
  ); // To keep the total price in state
  const [addOns, setAddOns] = useState([]);

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;
    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          checkoutModelState.priceDetails = response.data.details;
          console.log(response.data.details);
          dispatch(checkoutStore(checkoutModelState));
          const serviceType = state.serviceType;
          console.log(serviceType);
          let currentFlow = "whatNeedsInstallation";
          if (serviceType === "repairs") {
            currentFlow = "whatNeedsRepair";
          }
          const singleItems = [];
          let optionsArr = response.data.details[currentFlow];
          optionsArr = optionsArr
            .map((op) => {
              if (op.options && op.key !== "bathroomv") {
                return {
                  text: t(`plumbing.${op.key}`),
                  value: op.key,
                  count: 1,
                  isChecked: false,
                  isSingle: singleItems.includes(op.key),
                  showSelectInput: true,
                  showTextInput: op.key === "drainage" || op.key === "bathroom",
                  showRealTextInput: op.key === "pipes",
                  options: op.options,
                  price: 0,
                };
              } else if (op.key === "bathroomv") {
                return {
                  text: t(`plumbing.${op.key}`),
                  value: op.key,
                  count: 1,
                  isChecked: false,
                  isSingle: false,
                  showMultiCheckInput: true,
                  showTextInput: false,
                  showLinearTextInput: false,
                  options: op.options,
                  price: 0,
                };
              } else if (op.key === "gas_piping") {
                return {
                  text: t(`plumbing.${op.key}`),
                  value: op.key,
                  count: 1,
                  isChecked: false,
                  isSingle: false,
                  showSelectInput: false,
                  showTextInput: false,
                  showLinearTextInput: true,
                  price: 3.5,
                };
              } else {
                return {
                  text: t(`plumbing.${op.key}`),
                  value: op.key,
                  count: 1,
                  isChecked: false,
                  isSingle: singleItems.includes(op.key),
                  showTextInput: true,
                  showRealTextInput: false,
                  price: op.price,
                };
              }
            })
            .filter((op) => op !== false);
          setAddOns(optionsArr.sort((o, p) => (p.isSingle ? 1 : -1)));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state, t]);

  const onSave = () => {
    const total = calculateTotalCost(state.costFor);
    state.addOns = addOns.filter((i) => i.isChecked === true);
    state.costFor.addOns = price - total;
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(plumbingStore(state));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/plumbing/order-summary/4`
    );
  };

  function arrowHandler(params, e, index) {
    let updatedState = [...addOns];
    if (params === "plus") {
      updatedState[index].count = updatedState[index].count + 1;
    } else if (updatedState[index].count > 1) {
      updatedState[index].count = updatedState[index].count - 1;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  }

  const calculateTotal = (addOns) => {
    const total = addOns
      .filter((v) => v.isChecked === true)
      .reduce((p, c) => p + c.price * c.count, 0);
    setPrice(calculateTotalCost(state.costFor) + total);
  };

  const toggleCheck = (event, index) => {
    let updatedState = [...addOns];
    if (!updatedState[index].isChecked) {
      updatedState[index].isChecked = event;
    } else {
      updatedState[index].isChecked = !event;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  const onAddOnHandler = (event, index) => {
    let updatedState = [...addOns];
    updatedState[index].count = parseInt(event.target.value);
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  return (
    <div class="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">
            {state.serviceType === "repairs"
              ? "What requires repair?"
              : "What do you need to install?"}
          </label>
        </div>

        <div className="padding10"></div>
        {addOns &&
          addOns !== undefined &&
          addOns.map((item, index) => (
            <div key={index} className="paintPadding8 ">
              <div
                className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright "
                onClick={() => toggleCheck(true, index)}
              >
                <div
                  key={index}
                  className="width100 "
                  style={{ justifyContent: "space-between" }}
                >
                  <table>
                    <tr>
                      <td>
                        <label className="paintdiv_txt"> {item.text} </label>
                      </td>
                      <td>
                        <div
                          className="paint_arrowFloat flex"
                          style={{ paddingTop: "10px" }}
                        >
                          <img
                            src={IMAGES.other.green_plus}
                            className="kitchenplusimg"
                            alt=""
                          />
                          <label
                            className="paintdiv_txt paddingleft8"
                            style={{ paddingLeft: "7px", paddingTop: "2px" }}
                          >
                            {`$${formatPrice(item.count * item.price)}`}
                          </label>
                          <div
                            className="paint_arrowFloat"
                            style={{ paddingLeft: "7px" }}
                          >
                            <CheckBox checked={item.isChecked} readOnly />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="paintPadding8">
                {item.isChecked &&
                !item.isSingle &&
                item.showSelectInput === true ? (
                  <div
                    className="kitch_radio_div_firstarrow kitchdiv_arrow_padding optionElectric"
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                  >
                    <select
                      defaultValue={""}
                      onChange={(e) => {
                        console.log(e.target.value);
                        if (e.target.value) {
                          const selectedOption = item.options.find(
                            (op) => op.key === e.target.value
                          );
                          const modifiedAddons = addOns.map((op) => {
                            if (op.value === item.value) {
                              return {
                                ...op,
                                price: selectedOption.price,
                                selectedOption,
                              };
                            } else {
                              return op;
                            }
                          });
                          setAddOns(modifiedAddons);
                          calculateTotal(modifiedAddons);
                        }
                      }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {item.options.map((op, index) => (
                        <option key={index} value={op.key}>
                          {`${t(`plumbing.${op.key}`)} + $${formatPrice(
                            op.price
                          )}`}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  ""
                )}

                {item.isChecked &&
                !item.isSingle &&
                item.showTextInput === true ? (
                  <div
                    className="kitch_radio_div_firstarrow kitchdiv_arrow_padding"
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                  >
                    <table className="paint_table100">
                      <tr>
                        <td>
                          {" "}
                          <div
                            className="typeofTxt"
                            style={{ display: "grid", paddingTop: "0" }}
                          >
                            <DropDown
                              value={item.count || ""}
                              callback={(event) => onAddOnHandler(event, index)}
                              length={50}
                            />
                          </div>
                          {/* <label className="paintdiv_txt">
                    </label>  */}
                        </td>
                      </tr>
                    </table>
                    <div className="profilePadding8"></div>
                  </div>
                ) : null}
                {item.isChecked &&
                !item.isSingle &&
                item.showLinearTextInput === true ? (
                  <div
                    className=""
                    style={{ marginTop: "5px", marginBottom: "20px" }}
                  >
                    <div className="paintinput_lable custom-input-for-plumbing mt-1">
                      <TextInput
                        className="paint_input_border border"
                        type="number"
                        onChange={(e) => onAddOnHandler(e, index)}
                        placeholder={"Enter estimated linear feet"}
                      />
                      <div className="paint_placeholder_right">
                        <label>{t("paintingInterior.lnft")}</label>
                      </div>
                    </div>
                    {/* <div className="profilePadding8"></div> */}
                  </div>
                ) : null}

                {item.isChecked &&
                item.selectedOption &&
                item.selectedOption.key === "entire_home_pipes" &&
                !item.isSingle &&
                item.showRealTextInput === true ? (
                  <div
                    className="kitch_radio_div_firstarrow kitchdiv_arrow_padding"
                    style={{ marginTop: "15px" }}
                  >
                    <div
                      className="paintinput_lable mb40 custom-input-for-plumbing"
                      style={{ marginTop: "-4px" }}
                    >
                      <TextInput
                        className="paint_input_border"
                        type="number"
                        onChange={(e) => onAddOnHandler(e, index)}
                        placeholder={"Enter the number of SQFT"}
                      />
                      <div className="paint_placeholder_right">
                        <label>{t("paintingInterior.sqft")}</label>
                      </div>
                    </div>
                    {/* <div className="profilePadding8"></div> */}
                  </div>
                ) : null}
                {item.isChecked &&
                !item.isSingle &&
                item.showMultiCheckInput === true ? (
                  <div
                    className="kitch_radio_div_firstarrow kitchdiv_arrow_padding"
                    style={{ marginTop: "10px" }}
                  >
                    <SelectSearch
                      key={Math.random()}
                      multiple={true}
                      options={[].concat(item.options)}
                      // value={selectedBrand.value}
                      className="brand-name-dynamic-select"
                      onChange={(selectedVal) => {
                        console.log(selectedVal);
                      }}
                      search
                      placeholder="Please select"
                    />
                    <table className="paint_table100">
                      {item.options.map((option) => (
                        <tr onClick>
                          <td>
                            <div
                              className="typeofTxt"
                              style={{ display: "grid", paddingTop: "0" }}
                            >
                              {`${t(`plumbing.${option.key}`)} + $${formatPrice(
                                option.price
                              )}`}
                              <CheckBox checked={option.isChecked} readOnly />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </table>
                    <div className="profilePadding8"></div>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        <div className="profilePadding8"></div>
        <Button
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => onSave()}
        />
      </div>
    </div>
  );
};
