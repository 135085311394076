import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, paintingStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { fenceModel } from "../../../_redux/Models/painting.models";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
import { useHistory } from "react-router-dom";

export const Typeoffinishfence = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 2;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);
  const history = useHistory();

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : fenceModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state
  const [type, setType] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const size = parseFloat(state.size);
    if (mounted === true && priceDetails && priceDetails.typeOfFinish) {
      const optionsArr = priceDetails.typeOfFinish.map((op) => {
        return {
          text: t(`paintfence.${op.key}`),
          value: op.key,
          price: size * op.price,
        };
      });
      setOptions(optionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, state.size, t]);

  /**
   * Function to save Selected height size and take user to next step
   * @returns {void}
   */
  const onSave = (value) => {
    state.typeOfFinish = value;
    state.costFor.typeOfFinish = price - calculateTotalCost(state.costFor);
    dispatch(paintingStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/painting/fence/summary/3`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <label className="header_path_name_show">
          {t("paintfence.header_path")}
        </label>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">{t("paintingdeck.whattype")}</label>
          </div>
          <div className="padding10"></div>
          {options.map((item, index) => (
            <div
              key={index}
              className={`floor-selection-radio ${
                type === item?.value ? "selected" : ""
              }`}
              onClick={() => {
                const total = calculateTotalCost(state.costFor);
                setType(item.value);
                setPrice(total + item.price);
                onSave(item?.value);
              }}
            >
              <div
                key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="paintdiv_txt"
                  style={{
                    width: "65%",
                  }}
                >
                  {item.text}
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "35%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
