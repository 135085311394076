import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import useNotify from "../Notification/UseNotify";

export default function AlertBox({ className, msg, action }) {
  const { onCancel } = useNotify();

  const [userAction, setUserAction] = useState(action);

  const handleClose = () => {
    setUserAction(false);
    onCancel();
  };
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={userAction} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={className}
          sx={{ width: "100%" }}
        >
          {msg}
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}
    </Stack>
  );
}

export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
