import React from "react";

export const DropDown = (props) => {
    const {
      length = "10",
      value,
      callback,
      className = "dropDown",
      defaultValue = "1",
      showPlaceHolder = false,
    } = props;
    return (
      <select
        className={className}
        defaultValue={defaultValue}
        placeholder="Please select an option"
        value={value}
        onChange={(e) => {
          callback(e);
        }}
      >
        {showPlaceHolder && (
          <option value="0" selected>
            Please select an option
          </option>
        )}
        {defaultValue !== "1" && <option>{defaultValue}</option>}
        {Array.from({ length: length }, (_, i) => i + 1).map((key) => (
          <option key={key} value={key}>
            {key}
          </option>
        ))}
      </select>
    );
};
