import { buttonConstant } from "../constants";
const checkoutReducer = (state = false, action) => {
  switch (action.type) {
    case buttonConstant.CHECKOUT_FORWARD:
      return { ...state, payload: action.payload };
    default:
      return state;
  }
};

export default checkoutReducer;
