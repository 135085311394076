import React from "react";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import ContractorSlider from "./Slider/Slider";
import { Button } from "../../_components/Button/Button";
import { withTranslation } from "react-i18next";
import { checkoutStore } from "../../_redux/actions";
import { connect } from "react-redux";
import BearCarousel, { BearSlideItem } from "bear-react-carousel";
import "bear-react-carousel/dist/index.css";
import { API_URL } from "../../services/apiConstants";
import { getContractor } from "../../services/api/contractor.service";
import Loader from "../../_components/spinner/Loader";
import { createChannel } from "../../services/api/chat.service";
import { getData } from "../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../_configs/constants.config";
import { extractImageUrlsFromPicOfWork } from "../../services/utils/utilFunctions";

class PortfolioDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractor: null,
      isLoading: false,
      selectedContractorId: this.props.checkout.selectedContractorId,
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true }, () =>
        this.getContractorDetails(this.props.match.params.id)
      );
    }
  };

  /**
   * Gets the contractor from api
   */
  getContractorDetails = (id) => {
    getContractor(id)
      .then((contractor) => {
        if (contractor.data && contractor.data) {
          this.setState({ contractor: contractor.data }, () =>
            console.log(this.state.contractor)
          );
        }
      })
      .finally((_) => this.setState({ isLoading: false }));
  };

  getSliderImageData = (images) => {
    const imgUrls = extractImageUrlsFromPicOfWork(images);
    return imgUrls.map((image, index) => {
      return {
        key: index,
        children: <BearSlideItem imageUrl={image} />,
      };
    });
  };

  startChat = () => {
    this.setState({ isLoading: true });
    const user = getData(LOCAL_STORAGE.user);

    const data = {
      contractorID: this.state.contractor.contractorId._id,
      ownerID: user._id,
      userType: user.userType,
    };

    createChannel(data)
      .then((response) => {
        if (response.status !== 200) {
          console.log("Something went wrong!");
          return;
        }
        this.props.history.push({
          pathname: "/user/chat",
          search: `?sid=${response.data}`,
        });
      })
      .catch(() => {
        console.log("Something went wrong!");
      });
  };

  render = () => {
    const { t } = this.props;
    const { contractor } = this.state;
    return (
      <div className="insideWrp pc-767">
        {this.state.isLoading ? (
          <div>
            <Loader progress={this.state.isLoading} />
          </div>
        ) : this.state.contractor !== null ? (
          <div className="pcWidth">
            <div
              class="portfolio_detail_banner stable_class"
              style={{ height: "auto" }}
            >
              <BearCarousel
                className="BearSlider"
                key={Math.random()}
                data={this.getSliderImageData(contractor.picturesOfWork)}
                isEnableLoop
                isEnableNavButton
                //isEnablePagination
                aspectRatio={{ widthRatio: 16, heightRatio: 9 }}
              />
              <span
                onClick={() => this.props.history.goBack()}
                className="back_arrow_btn stable_class"
              >
                <img src={IMAGES.other.vectorsWhite} className="" alt="" />
              </span>
            </div>

            {contractor.picturesOfWork.length > 0 ? (
              <div className="portfolio_detail_wrp stable_class ">
                <div className="portfolio_image_grid stable_class">
                  {extractImageUrlsFromPicOfWork(contractor.picturesOfWork).map(
                    (image, index) => {
                      return (
                        <div key={index} className="eachImage_block">
                          <span className="">
                            <img src={image} className="" alt="" />
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}
            <div className="contact_detail-sec stable_class">
              <label className="paint_header">Company Description</label>
              <p className="review_discription">{contractor.description}</p>
            </div>

            <div style={{ padding: "0px 20px 20px 20px" }}>
              <div className="profilePadding30"></div>

              <div className="otherspadding25"></div>
              <div className="otherspadding25"></div>
              <div
                className="contactblue_btn"
                style={{ background: "#0abde1" }}
                onClick={this.startChat}
              >
                <div>
                  <img src={IMAGES.other.group1} alt="" />
                </div>
                <div>
                  <table>
                    <tr>
                      <td className="contactorBtnSec">
                        {" "}
                        <label className="contactuslink">
                          {t("Chat with contractor")}
                        </label>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="paintPolygon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                >
                  <path
                    d="M13 6.76795C14.3333 7.53775 14.3333 9.46225 13 10.2321L3.25 15.8612C1.91667 16.631 0.25 15.6688 0.25 14.1292L0.25 2.87084C0.25 1.33123 1.91667 0.368984 3.25 1.13878L13 6.76795Z"
                    fill="#0ABDE1"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : null}
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(PortfolioDetails));
