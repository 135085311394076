import React, { useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorSolidHardwoodModel } from "../../../_redux/Models/Floor.model";
import { useDispatch, useSelector } from "react-redux";
import { floorStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const SHHaveSolidHardwood = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorSolidHardwoodModel
  );

  const [haveSolidHardwood, setHaveSolidHardwood] = useState("");
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const price = calculateTotalCost(state.costFor); // To keep the total price
  // To show service questions
  const options = [
    {
      text: t("solidHardwood.pleaseProvideMaterials"),
      value: "no",
    },
    {
      text: t("solidHardwood.iHaveMyOwnMaterials"),
      value: "yes",
    },
  ];

  /**
   * Function to save Selected Service Type and take user to next step
   * @returns {void}
   */
  const onSaveServiceType = (value) => {
    state.haveSolidHardwood = value === "yes";
    dispatch(floorStore(state));
    if (value === "yes") {
      props.history.push(
        `/contractor-portfolio/${selectedContractorId}/floors/solid-hardwood/solid-hardwood-finished/3`
      );
    } else {
      props.history.push(
        `/contractor-portfolio/${selectedContractorId}/floors/solid-hardwood/wood-details/3`
      );
    }
  };

  return (
    <div className="insideWrp">
      {/* <CircularProgressBar/> */}
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left padding20">
            <label className="paint_header">
              {t("solidHardwood.haveSolidHardwoodYouWantToInstall")}
            </label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("solidHardwood.letUsKnowHaveSHToInstall")}
            </label>
          </div>
          <div className="padding10"></div>
          {options.map((item, index) => (
            <label
              key={index}
              onClick={() => {
                setHaveSolidHardwood(item?.value);
                onSaveServiceType(item?.value);
              }}
              className="floor-selection-radio"
            >
              {" "}
              {item.text}{" "}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};
