import React from "react";
import "./tick.css"
export const Exclamation = (props) => {
    const { width, height, paddingLeft, paddingRight, padding, paddingTop, paddingBottom, backgroundColor, fill } = props;
    return (
        <div style={{ width: `${width}`, height: `${height}`, paddingLeft: `${paddingLeft}`, paddingRight: `${paddingRight}`, padding: `${padding}`, paddingTop: `${paddingTop}`, paddingBottom: `${paddingBottom}` }}>


            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 130.2 130.2">


                <ellipse class="path line" cx="91.47" cy="103.854" fill={fill} stroke={backgroundColor} strokeWidth="2.765" rx="26.458" ry="26.08" />

                <path class="path line" fill={backgroundColor} d="M88.461 85.05h6.147v26.707h-6.147zm0 30.014h6.147v5.622h-6.147z" />
            </svg>



        </div>



    );
};