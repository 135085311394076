/**
 * @author Geethananth M
 * Model Object to hold the hvacModel
 */

/**
 * Model For hvacModel
 */
export const hvacModel = {
  flowName: "hvac-flow",
  serviceType: "",
  existingOrNew: "",
  typeOfInstallation: "",
  typeOfUnit: "",
  existingSystemSize: "", // In tons
  brandNeeded: "",
  agreementAccepted: "",
  addOns: [],
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  costFor: {
    service: 0,
    material: 0,
    addOns: 0,
  },
};
