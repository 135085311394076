import IMAGES from '../../_assets/images/index'


const items = [
  {
    urls: IMAGES.home.epoxy,
  },

];
export default items
