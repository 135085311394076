import { buttonConstant } from "../constants";
const buttonReducer = (state = false, action) => {
  switch (action.type) {
    case buttonConstant.BUTTON_ENABLE:
      return { ...state, buttonStatus: action.payload };
    case buttonConstant.SELECT_CATEGORY:
      return { ...state, selectedCategory: action.payload };
    case buttonConstant.PAINTING_FORWARD:
      return { ...state, paintingPayLoad: action.payload };
    case buttonConstant.KITCHEN_FORWARD:
      return { ...state, kitchenPayload: action.payload };
    case buttonConstant.BATHROOM_FORWARD:
      return { ...state, bathroomPayload: action.payload };
    case buttonConstant.FLOOR_FORWARD:
      return { ...state, floorPayload: action.payload };
    case buttonConstant.BASEMENT_FORWARD:
      return { ...state, basementPayload: action.payload };
    case buttonConstant.HVAC_FORWARD:
      return { ...state, hvacPayload: action.payload };
    case buttonConstant.WALL_FORWARD:
      return { ...state, wallPayload: action.payload };
    case buttonConstant.PLUMBING_FORWARD:
      return { ...state, plumbingPayload: action.payload };
    case buttonConstant.ELECTRIC_FORWARD:
      return { ...state, electricPayload: action.payload };

    case buttonConstant.ROOFING_FORWARD:
      return { ...state, roofingPayload: action.payload };

    case buttonConstant.WINDOW_FORWARD:
      return { ...state, windowsPayload: action.payload };

    case buttonConstant.TREE_FORWARD:
      return { ...state, treePayload: action.payload };

    case buttonConstant.CLEAR_STORE:
      return {};

    default:
      return state;
  }
};

export default buttonReducer;
