import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import { TextInput } from "../../../_components/TextInput/TextInput";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorEpoxyModel } from "../../../_redux/Models/Floor.model";
import { isInteger } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { removalOfFloorPopup } from "../Common/RemovalOfFloor.popup";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { getServicePriceDetails } from "../../../services/api/category.api.service";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const SizeOfFloor = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue = 1;
  const [selectedFloorName, updateSelectedService] = useState("");
  const [floorSize, setFloorSize] = useState(""); // To keep the floor size in state
  const [showRemovalFloorPopup, setShowRemovalFloorPopup] = useState(false);

  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(
    calculateTotalCost(floorEpoxyModel.costFor)
  ); // To keep the total price in state
  const [sizePricing, setSizePricing] = useState({ servicePrice: 0 });

  useEffect(() => {
    let mounted = true;
    if (
      mounted &&
      checkoutModelAs.selectedServiceDetails.categoryId &&
      checkoutModelAs.selectedServiceDetails._id
    ) {
      getServicePriceDetails(
        checkoutModelAs.selectedServiceDetails.categoryId,
        checkoutModelAs.selectedServiceDetails._id
      ).then((response) => {
        if (response.status === 200 && response.data) {
          updateSelectedService(checkoutModelAs.selectedServiceDetails.name);
          checkoutModelAs.priceDetails = response.data.details;
          setSizePricing(response.data.details.size[0]);
          dispatch(checkoutStore(checkoutModelAs));
        }
      });
    }

    return () => (mounted = false);
  }, [checkoutModelAs, dispatch]);

  /**
   * On prior floor removal click yes
   * @returns {void}
   */
  const onPriorFloorRemovalYesClick = () => {
    floorEpoxyModel.priorFloorRemovalRequired = true;
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/epoxy/current-floor-details/2`
    );
  };

  /**
   * On prior floor removal click no
   * @returns {void}
   */
  const onPriorFloorRemovalNoClick = () => {
    setShowRemovalFloorPopup(false);
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/epoxy/floor-addons/2`
    );
  };

  /**
   * Calls when submit button is clicked
   */
  const onSubmitFloorSize = () => {
    floorEpoxyModel.floorSize = floorSize;
    floorEpoxyModel.costFor.sizeDetails = price;
    dispatch(floorStore(floorEpoxyModel));
    checkoutModelAs.totalCost = 0;
    dispatch(checkoutStore(checkoutModelAs));
    setShowRemovalFloorPopup(true);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("epoxyCoating.numberOfSquareFeetEpoxy")}
            </label>
          </div>
          <div className="paintinput_lable mb-4">
            <TextInput
              className="paint_input_border"
              error={isInteger(floorSize)}
              type="number"
              onChange={(e) => {
                const sizeServicePrice = sizePricing.servicePrice;
                setPrice(e.target.value * sizeServicePrice);
                setFloorSize(e.target.value);
              }}
              value={floorSize}
              placeholder={t("paintingInterior.placeholder500")}
              label={t("paintingExterior.sizing")}
            />
            <div className="paint_placeholder_right">
              <label>{t("paintingInterior.sqft")}</label>
            </div>
          </div>
          {floorSize > 0 && floorSize < 150 ? (
            <span style={{ fontSize: "10px", color: "red" }}>
              Minimum size is 150 square feet
            </span>
          ) : null}
          <Button
            disabled={floorSize < 150}
            buttonLabel={t("paintingHomes.continue")}
            onClick={onSubmitFloorSize}
          />
        </div>
      </div>
      {removalOfFloorPopup({
        isOpen: showRemovalFloorPopup,
        onYesClick: onPriorFloorRemovalYesClick,
        onCloseAction: onPriorFloorRemovalNoClick,
        onNoClick: onPriorFloorRemovalNoClick,
      })}
    </div>
  );
};
