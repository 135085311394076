import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { basementModel } from "../../_redux/Models/basement.model";
import { useDispatch, useSelector } from "react-redux";
import { basementStore, checkoutStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { getServiceCategoriesFull } from "../../services/api/category.api.service";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const TypeOfService = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 1;
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [serviceType, setServiceType] = useState("");
  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  useEffect(() => {
    let mounted = true;
    getServiceCategoriesFull().then((items) => {
      if (mounted && items.status === 200) {
        let filteredItem = items.data.filter(
          (item) => item.name === "Basement"
        );
        filteredItem = filteredItem ? filteredItem[0] : null;

        checkoutModelAs.selectedServiceDetails = filteredItem.subCategory[0];
        checkoutModelAs.selectedService = "Basement";
        checkoutModelAs.totalCost = 0;
        dispatch(checkoutStore(checkoutModelAs));
      }
    });
    return () => (mounted = false);
  }, [checkoutModelAs, dispatch]);

  // To show basement service questions
  const serviceTypeOptions = [
    {
      text: t("basement.basementFinishing"),
      value: "finishing",
    },
  ];

  /**
   * Function to save Selected Service Type and take user to next step
   * @returns {void}
   */
  const onSaveServiceType = (val) => {
    // To save the flow on Checkout process
    basementModel.serviceType = val;
    dispatch(basementStore(basementModel));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/basement/size/2`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left mb-3">
            <label className="paint_header">
              {t("basement.selectTypeOfService")}
            </label>
          </div>
          <div className="padding10"></div>
          {serviceTypeOptions.map((item, index) => (
            <div
              key={index}
              className={`floor-selection-radio ${
                serviceType === item?.value ? "selected" : ""
              }`}
              onClick={() => {
                setServiceType(item.value);
                onSaveServiceType(item?.value);
              }}
            >
              <div key={index} className="width100">
                <label className="paintdiv_txt"> {item.text} </label>
                <div className="paint_arrowFloat"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
