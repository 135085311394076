import React, { useEffect, useState } from "react";
import IMAGES from "../../../_assets/images/index";
import "../../../_styles/localStyles/footerMenu.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import Loader from "../../../_components/spinner/Loader";
export const Termscondition = (props) => {
  let history = useHistory();
  const [content, setContent] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const termsClick = () => {
    history.push("/");
  };

  useEffect(async () => {
    try {
      setIsLoading(true);
      const resp = await fetch(
        `${process.env.REACT_APP_API_URL}/terms-and-condition`
      );
      if (resp && resp.status == 200) {
        const data = await resp.text();
        setIsError(false);
        setContent(data);
        setIsLoading(false);
      } else {
        setIsError(true);
        setContent(
          "<h4 class='mt-5'>Failed to fetch terms and conditions !!!</h4>"
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsError(true);
      setContent(
        "<h4 class='mt-5'>Failed to fetch terms and conditions !!!</h4>"
      );
      setIsLoading(false);
    }
  }, []);

  return (
    <div className="paint-home-section termsMainWrapper container d-block">
      <div className="allCenter">
        <label className="headany">{t("termsMenue.any")}</label>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className="paddinngtop10 grid"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      )}
      <div className="profilePadding40"></div>
      {isLoading || isError ? (
        ""
      ) : (
        <Button
          buttonLabel={t("termsMenue.accept")}
          onClick={() => termsClick()}
        />
      )}
    </div>
  );
};
