import React, { useEffect, useLayoutEffect, useRef } from "react";
import Drawer from "@mui/material/Drawer";

import IMAGES from "../../_assets/images";

const CustomDrawer = ({
  setDrawerOpen,
  drawerOpen,
  getImageGrid,
  contractorDetails,
  currentActive,
  imageRefs,
  imageGrid,
}) => {
  const drawerRef = useRef(null);
  setTimeout(() => {
    if (currentActive !== 0)
      imageRefs?.current?.[currentActive]?.scrollIntoView({
        behavior: "smooth",
      });
  }, 500);
  return (
    <>
      <Drawer
        ref={drawerRef}
        anchor="bottom"
        open={drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
      >
        <div className="gallery-container">
          <div className="gallery-header fixed-top w-100 bg-white">
            <img
              onClick={() => setDrawerOpen(false)}
              src={IMAGES.home.scrollLeft}
            />
          </div>
          <div className="image-gallery mt-5 px-1 px-lg-5">
            <div className="sub-container">{imageGrid}</div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
