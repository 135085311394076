import { SizeOfFloor } from "../engineered/SizeOfFloor";
import { CurrentTypeOfFloor } from "../engineered/CurrentTypeOfFloor";
import { NeedFloorInstall } from "../engineered/needFloorInstall";
import { FloorSizeDetails } from "../engineered/FloorSizeDetails";
import { AddOn } from "../engineered/AddOn";
import { PersonalInfo } from "../engineered/personalInfo";
import { OrderSummary } from "../engineered/OrderSummary";

const engineeredRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/engineered/introduce-floor-size/:stepValue",
    component: SizeOfFloor,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/engineered/current-floor-details/:stepValue",
    component: CurrentTypeOfFloor,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/engineered/need-floor-install/:stepValue",
    component: NeedFloorInstall,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/engineered/floor-size-details/:stepValue",
    component: FloorSizeDetails,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/engineered/floor-addons/:stepValue",
    component: AddOn,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/engineered/personal-info/:stepValue",
    component: PersonalInfo,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/floors/engineered/order-summary/:stepValue",
    component: OrderSummary,
  },
];

export default engineeredRoutes;
