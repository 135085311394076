import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, electricStore, hvacStore } from "../../_redux/actions";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { electricModel } from "../../_redux/Models/electric.model";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const ElectricTypeOfInstallation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue = 2;

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.electricPayload
      ? state.buttonReducer.electricPayload
      : electricModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [option, setOption] = useState(""); // To keep the basement size in state
  const price = calculateTotalCost(state.costFor); // To keep the total price in state

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;
    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          checkoutModelState.priceDetails = response.data.details;
          dispatch(checkoutStore(checkoutModelState));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, t]);

  const onSave = (val) => {
    state.existingOrNew = val;
    dispatch(electricStore(state));
    if (state.serviceType === "installation") {
      history.push(
        `/contractor-portfolio/${selectedContractorId}/electric/select-install-items/3`
      );
    }
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header" style={{ fontSize: "19px" }}>
              {"Is it a new installation or  a replacement?"}
            </label>
          </div>
          <div className="paint_flex_with_left paddinngtop20">
            <label className="paint_other_sub_header ">
              Select the type of electrical service you need
            </label>
          </div>
          <div
            onClick={() => {
              setOption("installation");
              onSave("installation");
            }}
            className={`floor-selection-radio ${
              option === "installation" ? "selected" : ""
            }`}
            style={{ height: "80px" }}
          >
            <div
              // key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">
                New installation with wiring
              </label>
              <div
                className="paint_arrowFloat"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              ></div>
            </div>
          </div>

          <div
            className={`floor-selection-radio ${
              option === "replacement" ? "selected" : ""
            }`}
            style={{ height: "80px" }}
            onClick={() => {
              setOption("replacement");
              onSave("replacement");
            }}
          >
            <div
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">
                Replacement of existing ones (no wiring required)
              </label>
              <div
                className="paint_arrowFloat"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
