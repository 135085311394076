import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IMAGES from "../../../_assets/images";
import Loader from "../../../_components/spinner/Loader";
import axiosInstance from "../../../services/api/config/api.config";
import moment from "moment";

const PaymentsReceived = () => {
  const history = useHistory();
  const [selected, setSelected] = useState("overall");
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(false);
  const [payments, setPayments] = useState([]);

  const data = {
    code: 200,
    message: "Completed projects transactions",
    projects: [
      {
        _id: "647f100f5bb75dffa6f5f3d7",
        contractorId: "63bf26dd7926e579767592a5",
        ownerId: {
          _id: "63bf26dd7926e579767592a5",
          name: "Andres Hernandez",
          email: "andreshcuevas@gmail.com",
          isVerified: true,
          status: "approve",
          address: "Atlanta GA",
        },
        contractorPrice: "9450",
        completedAt: "2023-06-06T10:53:48.542Z",
      },
      {
        _id: "63ed3a26c12004fd2ff74f42",
        contractorId: "63bf26dd7926e579767592a5",
        ownerId: {
          _id: "63bf26dd7926e579767592a5",
          name: "Andres Hernandez",
          email: "andreshcuevas@gmail.com",
          isVerified: true,
          status: "approve",
          address: "Atlanta GA",
        },
        contractorPrice: "1800",
        completedAt: "2023-03-23T13:26:02.996Z",
      },
      {
        _id: "63cf0ed47926e5797678a518",
        contractorId: "63bf26dd7926e579767592a5",
        ownerId: {
          _id: "63bf31a37926e579767592cb",
          name: "Santiago Alejo",
          email: "alejo.carnicero@gmail.com",
          isVerified: true,
          status: "approve",
          address: "100 austin, CA",
        },
        contractorPrice: "20484.3375",
        completedAt: "2023-01-24T01:15:57.125Z",
      },
    ],
    overallPrice: 31734.3375,
  };

  const getPaymentsReceived = (type) => {
    setLoading(true);
    axiosInstance
      .post("/contractor/project-transactions", {
        timeRange: type,
      })
      .then((res) => {
        const groupedTransactions = res?.data?.projects.reduce(
          (acc, project) => {
            setTotalAmount(res?.data?.overallPrice);
            const completedAt = new Date(project.completedAt);
            const monthYear = `${completedAt.toLocaleString("default", {
              month: "long",
            })} ${completedAt.getFullYear()}`;

            if (!acc[monthYear]) {
              acc[monthYear] = [];
            }

            const transaction = {
              ownerName: project.ownerId.name,
              amount: project.contractorPrice,
              date: project?.completedAt,
            };

            acc[monthYear].push(transaction);

            return acc;
          },
          {}
        );
        if (type === "overall") {
          setPayments(groupedTransactions);
        } else if (type === "monthly") {
          const filteredData = res?.data?.projects.filter((project) => {
            const completedAt = new Date(project.completedAt);
            const today = new Date();
            const monthAgo = new Date(
              today.getTime() - 30 * 24 * 60 * 60 * 1000
            ); // Calculate 30 days ago
            if (completedAt >= monthAgo && completedAt <= today) return project;
          });
          if (filteredData && filteredData?.length > 0) {
            setPayments({ "Last 30 days": filteredData });
          } else {
            setPayments({});
          }
        } else {
          const filteredData = res?.data?.projects.filter((project) => {
            const completedAt = new Date(project.completedAt);
            const today = new Date();
            const sevenDaysAgo = new Date(
              today.getTime() - 7 * 24 * 60 * 60 * 1000
            ); // Calculate 7 days ago

            if (completedAt >= sevenDaysAgo && completedAt <= today)
              return project;
          });
          if (filteredData && filteredData?.length > 0) {
            setPayments({ "Last 7 days": filteredData });
          } else {
            setPayments({});
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPaymentsReceived(selected);
  }, []);

  return (
    <>
      <div
        className="custom-header d-flex align-items-center px-2 px-md-5 "
        style={{ height: "90px" }}
      >
        <div className="profile-head w-100">
          <img
            onClick={() => history.goBack()}
            className="close-icon"
            src={IMAGES.home.backIcon}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div
        className="verify-mobile-modal p-0 px-3 px-md-5 pt-3 payments-received d-flex flex-column mx-auto"
        style={{ maxWidth: "800px" }}
      >
        <h1 className="head">Payments Received</h1>
        <div className="tab-heads mt-3">
          <p
            onClick={() => {
              setSelected("overall");
              getPaymentsReceived("overall");
            }}
            className={selected === "overall" ? "active" : ""}
          >
            Overall
          </p>
          <p
            onClick={() => {
              setSelected("monthly");
              getPaymentsReceived("monthly");
            }}
            className={selected === "monthly" ? "active" : ""}
          >
            Monthly
          </p>
          <p
            onClick={() => {
              setSelected("weekly");
              getPaymentsReceived("weekly");
            }}
            className={selected === "weekly" ? "active" : ""}
          >
            Weekly
          </p>
        </div>
        <div className="cstm-gradient">
          {" "}
          <div className="icon-container">
            <div className="background-circle"></div>
            <div className="dollar-symbol">$</div>
          </div>
          <div className="amountsection">
            <div>
              {selected
                ? selected?.charAt(0)?.toUpperCase() + selected?.slice(1)
                : ""}
            </div>
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              {totalAmount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </div>
          </div>
        </div>
        {loading ? (
          <Loader progress={loading} />
        ) : Object?.keys(payments)?.length > 0 ? (
          Object?.keys(payments)?.map((key) => (
            <div className="payments-container">
              <div className="head">
                <div className="left-line" />
                <p>{key}</p>
                <div className="right-line" />
              </div>
              {payments[key]?.map((payment) => (
                <div className="payment">
                  <div className="d-flex">
                    <img src={IMAGES.home.paymentReceived} />
                    <div className="label">
                      <p className="owner">{payment?.ownerName}</p>
                      <p className="date">
                        {moment(payment?.date).format("DD/MM/yyyy - hh a")}
                      </p>
                    </div>
                  </div>
                  <p className="amount">$ {payment?.amount}</p>
                </div>
              ))}
            </div>
          ))
        ) : (
          <h6 className="w-100 text-center p-4">
            {selected === "monthly"
              ? "No Payment received in last 30 days"
              : selected === "weekly"
              ? "No Payment received in last 7 days"
              : "No Payment received Yet"}
          </h6>
        )}
      </div>
    </>
  );
};

export default PaymentsReceived;
