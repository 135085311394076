export const getData = (item) => {
    return JSON.parse(localStorage.getItem(item))
}

export const setData = (item, data) => {
    localStorage.setItem(item, JSON.stringify(data))
}

export const removeData = (item) => {
    localStorage.removeItem(item);
}