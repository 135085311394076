import { buttonConstant } from "../constants";

function paintingStore(state) {
  return {
    type: buttonConstant.PAINTING_FORWARD,
    payload: state,
  };
}

/**
 *
 * @param {Object} state
 * @returns {Object} modified state
 */
function selectCategory(state) {
  return {
    type: buttonConstant.SELECT_CATEGORY,
    payload: state,
  };
}

function floorStore(state) {
  return {
    type: buttonConstant.FLOOR_FORWARD,
    payload: state,
  };
}
function kitchenStore(state) {
  return {
    type: buttonConstant.KITCHEN_FORWARD,
    payload: state,
  };
}
function bathroomStore(state) {
  return {
    type: buttonConstant.BATHROOM_FORWARD,
    payload: state,
  };
}
/**
 *
 * @param {Object} state
 * @returns {Object} modified state
 */
function basementStore(state) {
  return {
    type: buttonConstant.BASEMENT_FORWARD,
    payload: state,
  };
}

/**
 *
 * @param {Object} state
 * @returns {Object} modified state
 */
function wallOpeningStore(state) {
  return {
    type: buttonConstant.WALL_FORWARD,
    payload: state,
  };
}

/**
 *
 * @param {Object} state
 * @returns {Object} modified state
 */
function hvacStore(state) {
  return {
    type: buttonConstant.HVAC_FORWARD,
    payload: state,
  };
}
/**
 *
 * @param {Object} state
 * @returns {Object} modified state
 */
function electricStore(state) {
  return {
    type: buttonConstant.ELECTRIC_FORWARD,
    payload: state,
  };
}

/**
 *
 * @param {Object} state
 * @returns {Object} modified state
 */
function plumbingStore(state) {
  return {
    type: buttonConstant.PLUMBING_FORWARD,
    payload: state,
  };
}

/**
 *
 * @param {Object} state
 * @returns {Object} modified state
 */
function roofingStore(state) {
  return {
    type: buttonConstant.ROOFING_FORWARD,
    payload: state,
  };
}

/**
 *
 * @param {Object} state
 * @returns {Object} modified state
 */
function windowStore(state) {
  return {
    type: buttonConstant.WINDOW_FORWARD,
    payload: state,
  };
}

/**
 *
 * @param {Object} state
 * @returns {Object} modified state
 */
function treeStore(state) {
  return {
    type: buttonConstant.TREE_FORWARD,
    payload: state,
  };
}

/**
 *
 * @param {Object} state
 * @returns {Object} modified state
 */
function clearStore() {
  return {
    type: buttonConstant.CLEAR_STORE,
    payload: {},
  };
}

export {
  paintingStore,
  kitchenStore,
  bathroomStore,
  floorStore,
  selectCategory,
  basementStore,
  wallOpeningStore,
  hvacStore,
  electricStore,
  plumbingStore,
  roofingStore,
  windowStore,
  treeStore,
  clearStore,
};
