import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Compressor from "compressorjs";

import IMAGES from "../../../_assets/images";
import { parseJwt } from "../../../services/utils/utilFunctions";
import {
  ownerProfile,
  updateOwnerProfile,
} from "../../../services/api/owner.service";
import { useTranslation } from "react-i18next";
import useNotify from "../../../_components/Notification/UseNotify";
import Loader from "../../../_components/spinner/Loader";
import { FireBase } from "../../../services/firebase/firebase.service";
import { getContractor } from "../../../services/api/contractor.service";
import {
  getData,
  setData,
} from "../../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../../_configs/constants.config";
import { Modal } from "@mui/material";
import { languagesArray } from "../../homeComponents/headerSection/Header";

const MyProfile = () => {
  const [user, setUser] = useState(null);
  let userDetails = getData(LOCAL_STORAGE?.user);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(languagesArray[0]);
  const [confirmed, setConfirmed] = useState(languagesArray[0]);

  const [loading, setLoading] = useState(false);
  const [isContractor, toggleProfile] = useState(
    userDetails?.userType === "contractor"
  );

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { confirm } = useNotify();
  const firebase = new FireBase();

  const selectLanguage = () => {
    if (selectedLang) {
      i18n.changeLanguage(selectedLang?.shortName?.toLowerCase(), (e, t) => {
        if (e) {
          confirm("error", "Failed to Change language");
        } else {
          setConfirmed(selectedLang);
          window.location.reload();
        }
      });
    }
    setIsOpen(false);
  };

  const getUserInfo = () => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (user && user._id) {
      getContractor(user._id)
        .then((data) => {
          console.log(data);
          if (data.data) {
            const userObj = data.data;
            setUser(userObj);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const signout = () => {
    setLoading(true);
    if (firebase) firebase.deleteToken();
    localStorage.clear("user");
    setLoading(false);
    window.location = "/";
  };

  const handleFilePick = (event) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "*";
    input.onchange = (event) => {
      const file = event.target.files[0];
      handleFileUploadFor(file, "profileImage");
    };
    input.click();
  };

  const handleFileUploadFor = (file, field) => {
    new Compressor(file, {
      quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        fileToDataUri(compressedResult).then((dataUri) => {
          fetch(dataUri)
            .then((res) => res.blob())
            .then((res) => {
              uploadFilesToServer(res, field, () => getUserInfo());
            });
        });
      },
    });
  };

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const uploadFilesToServer = (file, field, callback) => {
    const postData = new FormData();
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (file && field) {
      setLoading(true);
      postData.append(field, file);
      updateOwnerProfile(user._id, postData)
        .then((data) => {
          if (data.status !== 200) {
            confirm("error", "Upload failed please try again");
          } else if (callback) {
            callback();
          }
        })
        .catch((e) => {
          const variant = "error";
          confirm("error", e.toString());
        })
        .finally((_) => {
          setLoading(false);
        });
    }
  };

  const handleProfileToggle = () => {
    let _userDetails = getData(LOCAL_STORAGE.user);
    if (_userDetails) {
      let _isContractor = isContractor;
      if (_userDetails.userType === "contractor") {
        _userDetails.userType = "owner";
        _isContractor = false;
      } else {
        if (_userDetails?.originalStatus === "contractor") {
          _userDetails.userType = "contractor";
          _isContractor = true;
          history.push("/contractor-projects");
        } else {
          history.push("/signup");
        }
      }
      setData("user", _userDetails);
      toggleProfile(_isContractor);
    } else {
      confirm("error", "Please login to continue");
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    const current = i18n.language;
    const language = languagesArray?.filter(
      (item) => item?.shortName?.toLowerCase() === current
    );
    if (language) {
      setConfirmed(language[0]);
      setSelectedLang(language[0]);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  return (
    <div className="owner-profile">
      <div className="profile-head d-none d-lg-flex w-100">
        <Loader progress={loading} />

        <img
          onClick={() => history.goBack()}
          className="close-icon"
          src={IMAGES.home.backIcon}
        />
      </div>
      <div className="profile-top d-flex justify-content-center">
        <img
          onClick={() => history.goBack()}
          className="close-icon d-flex d-lg-none"
          src={IMAGES.home.backIcon}
        />
        <div className="col-10 col-lg-9 position-relative d-flex">
          <Loader progress={loading} />
          <img
            src={user?.profileImage || IMAGES.home.defaultUser}
            className="profile-img"
          />
          <img
            onClick={handleFilePick}
            className="position-absolute edit-icon"
            src={IMAGES.home.editIcon}
          />
        </div>
      </div>
      <div className="profile-bottom d-flex justify-content-center">
        <div className="col-10 col-lg-9">
          <h4 className="">{user?.contractorId?.name}</h4>
          <Link to="/user/edit-profile" className="edit-profile">
            {t("ownerProfile.editProfile")}
          </Link>
          <div className="profile-headings d-flex flex-wrap w-100">
            <div className="col-10 col-lg-4">
              {t("ownerProfile.personalSettings")}
            </div>
            <div className="col-10 col-lg-4 d-none d-lg-block">
              {t("ownerProfile.businessSettings")}
            </div>{" "}
            <div className="col-10 col-lg-4 d-none d-lg-block">
              {t("ownerProfile.helpAndSupport")}
            </div>
          </div>
          <div className="profile-links d-flex flex-wrap w-100">
            <div className="col-10 col-lg-4 justify-content-start">
              <Link to="/user/edit-profile">
                <img src="" />
                <p>{t("ownerProfile.personalInfo")}</p>
              </Link>
              <Link to="/user/notifications">
                <img src="" />
                <p>{t("ownerProfile.notification")}</p>
              </Link>
              <span
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <img src="" />
                <p>{t("ownerProfile.languages")}</p>
              </span>
              <Link to="/bank-details">
                <img src="" />
                <p>{t("ownerProfile.bankDetails")}</p>
              </Link>
              <Link to="/">
                <img src="" />
                <p>{t("ownerProfile.loginAndPassword")}</p>
              </Link>
              <Link to="/favorites">
                <img src="" />
                <p>{t("ownerProfile.favorites")}</p>
              </Link>
            </div>
            <div className="profile-headings d-flex d-lg-none flex-wrap w-100">
              <div className="col-10 col-lg-4 ">
                {t("ownerProfile.businessSettings")}
              </div>
            </div>
            <div className="col-10 col-lg-4">
              <span onClick={handleProfileToggle}>
                <img src="" />
                <p>
                  {!isContractor
                    ? t("header.switchPersonal")
                    : t("header.switch")}
                </p>
              </span>
              <Link
                to={{
                  pathname: "/contractor/business-info",
                  state: { redirectUrl: "/contractor/profile" },
                }}
              >
                <img src="" />
                <p>{t("ownerProfile.businessInfo")}</p>
              </Link>
              <Link
                to={{
                  pathname: "/contractor/services-added",
                  state: { redirectUrl: "/contractor/profile" },
                }}
              >
                <img src="" />
                <p>{t("ownerProfile.serviceAdded")}</p>
              </Link>
              <Link to="/service-locations">
                <img src="" />
                <p>{t("ownerProfile.serviceLocation")}</p>
              </Link>
              <Link to="/">
                <img src="" />
                <p>{t("ownerProfile.payouts")}</p>
              </Link>
              <Link to="/payments-received">
                <img src="" />
                <p>{t("ownerProfile.paymentRecieved")}</p>
              </Link>
              <Link to="/">
                <img src="" />
                <p>{t("ownerProfile.taxes")}</p>
              </Link>
            </div>
            <div className="profile-headings d-flex d-lg-none flex-wrap w-100">
              <div className="col-10 col-lg-4">
                {t("ownerProfile.helpAndSupport")}
              </div>
            </div>
            <div className="col-10 col-lg-4">
              <Link to="/contact-us">
                <img src="" />
                <p>{t("ownerProfile.needHelp")}</p>
              </Link>
              <Link to="/">
                <img src="" />
                <p>{t("ownerProfile.assistant")}</p>
              </Link>
              <Link to="/">
                <img src="" />
                <p>{t("ownerProfile.feedback")}</p>
              </Link>
              <Link to="/terms-and-condition">
                <img src="" />
                <p>{t("ownerProfile.terms")}</p>
              </Link>
              <Link to="/" className="d-flex d-lg-none" onClick={signout}>
                <img src="" />
                <p>{t("homeDrawer.signout")}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className={"choose-language centered center"}
        // sx={{ position: "absolute", top: "20", right: "0", overflowY: "auto" }}
      >
        <div className="language-container">
          <div className="lang-head w-100">
            <h5>{t("header.selectLanguage")}</h5>
            <button
              onClick={() => setIsOpen(false)}
              className="border-0 p-0 bg-transparent"
            >
              <img src={IMAGES.home.modalClose} />
            </button>
          </div>
          <div className="languages">
            {languagesArray?.map((language) => (
              <div
                className={`language-btn ${
                  selectedLang?.name === language?.name ? "selected" : ""
                }`}
                onClick={() => setSelectedLang(language)}
              >
                {language?.name?.toUpperCase()}
              </div>
            ))}
          </div>
          <div className="save">
            <button onClick={() => selectLanguage()} className="save-changes">
              OK
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyProfile;
