import smiley_1 from "../../../_assets/images/other-images/svgicons/1.svg";
import smiley_2 from "../../../_assets/images/other-images/svgicons/2.svg";
import smiley_3 from "../../../_assets/images/other-images/svgicons/3.svg";
import smiley_4 from "../../../_assets/images/other-images/svgicons/4.svg";
import smiley_5 from "../../../_assets/images/other-images/svgicons/5.svg";
export const projectDetails = [
  {
    address: "12345 EverGreen AV, Atlanta, GA 1",
    date: "18/10/2021 •",
    status: "Checked In",
  },
  {
    address: "12345 EverGreen AV, Atlanta, GA 2",
    date: "18/10/2021 •",
    status: "Checked In",
  },
  {
    address: "12345 EverGreen AV, Atlanta, GA 3",
    date: "18/10/2021 •",
    status: "Checked In",
  },
];

export const updateNextProject = (status) => {
  let nextStatus = "";
  if (status === "pending") nextStatus = "confirm";
  else if (status === "confirm") nextStatus = "check_in";
  else if (status === "check_in") nextStatus = "completed";
  sessionStorage.setItem("presentStatus", nextStatus);
  return nextStatus;
};

export const cardStatus = (status) => {
  let text = {
    header: "",
    subHeader: "",
  };
  if (status === "pending")
    text = {
      header: "",
      subHeader: "Requires acceptance",
      buttonText: "Confirm project",
    };
  else if (status === "check_in")
    text = {
      header: "Checked in",
      subHeader: "Checked in",
      buttonText: "Project completed",
    };
  else if (status === "confirm")
    text = {
      header: "Confirmed",
      subHeader: "Confirmed",
      buttonText: "Check in",
    };
  else if (status === "completed")
    text = {
      header: "",
      subHeader: "Completed project",
      buttonText: "Rate the client",
    };
  else if (status === "cancelled")
    text = {
      header: "",
      subHeader: "Cancelled project",
      buttonText: "",
    };
  else if (status === "rejected")
    text = {
      header: "",
      subHeader: "Rejected project",
      buttonText: "",
    };
  return text;
};

export const getprojectheader = (flow, serviceType) => {
  let projectTitle = "";

  const typeOfFlow = flowTypes.filter(
    (item) => item.flow == flow || item.flow.includes(flow)
  );
  if (typeOfFlow.length > 0 && serviceType && flow !== "floor-carpet")
    projectTitle = `${typeOfFlow[0].text} - ${serviceType.charAt(0).toUpperCase() + serviceType.slice(1)
      }`;
  else if (typeOfFlow.length > 0 && !serviceType)
    projectTitle = `${typeOfFlow[0].text} `;
  else if (flow && !serviceType) {
    const splitName = flow?.split("-");
    splitName.forEach((name) => {
      if (name)
        projectTitle =
          projectTitle + name.charAt(0).toUpperCase() + name.slice(1) + " ";
    });
  } else if (flow === "floor-carpet") {
    projectTitle = `${typeOfFlow[0].text} Floor`;
  }
  return projectTitle.replace(/_/g, " ");
};

export const flowTypes = [
  {
    flow: "floor-engineered",
    text: "Floor",
  },
  {
    flow: "wallOpening-flow",
    text: "Wall Opening",
  },
  {
    flow: "kitchen-flow",
    text: "Kitchen",
  },
  {
    flow: "floor-solidHardwood",
    text: "Solid Hardwood",
  },
  {
    flow: "basement-flow",
    text: "Basement",
  },
  {
    flow: "floor-carpet",
    text: "Carpet",
  },
  {
    flow: "floor-tiles",
    text: "Floor Tiles",
  },
  {
    flow: "floor-laminated",
    text: "Floor laminate",
  },
  {
    flow: "floor-epoxy",
    text: "Floor Epoxy",
  },
  {
    flow: "deck",
    text: "Deck",
  },
  {
    flow: "fence",
    text: "Fence",
  },
  {
    flow: "exterior",
    text: "Exterior",
  },
  {
    flow: "bathroom",
    text: "Bathroom",
  },
  {
    flow: "hvac-flow",
    text: "HVAC",
  },
  {
    flow: "electric-flow",
    text: "Electricity",
  },
  {
    flow: "plumbing-flow",
    text: "Plumbing",
  },
];

export const ratingImages = [
  { img: smiley_1, text: "Very bad" },
  {
    img: smiley_3,
    text: "Bad",
  },
  {
    img: smiley_4,
    text: "Good",
  },
  {
    img: smiley_5,
    text: "Very good",
  },
  {
    img: smiley_2,
    text: "Excellent",
  },
];
