import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useRecoilState } from "recoil";
import { Drawer, Modal } from "@mui/material";
import { Link, useHistory } from "react-router-dom";

import IMAGES from "../../../_assets/images";
import { latLonState, searchState } from "../../../recoil/atoms/headerAtom";
import { Homedrawer } from "../../../_components/HeaderMenu/drawer";
import useNotify from "../../../_components/Notification/UseNotify";
import { DebouncedInput } from "../../../_components/DebouncedInput";
import { parseJwt } from "../../../services/utils/utilFunctions";
import { ownerProfile } from "../../../services/api/owner.service";
import {
  getData,
  setData,
} from "../../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../../_configs/constants.config";
import { getContractor } from "../../../services/api/contractor.service";
import VerifyMobileModal from "./VerifyMobileModal";
import AddServiceLocationsForm from "./AddServiceLocationsForm";
import axiosInstance from "../../../services/api/config/api.config";
import { isEmpty } from "lodash";
import axios from "axios";

export const languagesArray = [
  {
    name: "english",
    icon: IMAGES.home.english,
    shortName: "En",
  },
  {
    name: "português",
    icon: IMAGES.home.portugese,
    shortName: "Pt",
  },
  {
    name: "français",
    icon: IMAGES.home.french,
    shortName: "Fr",
  },
  {
    name: "español",
    icon: IMAGES.home.espanol,
    shortName: "Es",
  },
  {
    name: "italiano",
    icon: IMAGES.home.italian,
    shortName: "It",
  },
];
const Header = ({ parentClass, showSearch = true }) => {
  const { t, i18n } = useTranslation();
  let userDetails = getData(LOCAL_STORAGE?.user);
  const history = useHistory();
  const { confirm } = useNotify();
  const ref = useRef();
  const searchIconRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [mobileVerifyModal, setMobileVerifyModal] = useState(false);
  const [serviceLocationsModal, setServiceLocationsModal] = useState(false);
  const [selectedLang, setSelectedLang] = useState(languagesArray[0]);
  const [confirmed, setConfirmed] = useState(languagesArray[0]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [search, setSearch] = useRecoilState(searchState);
  const [, setLatLonState] = useRecoilState(latLonState);
  const [pendingActions, setPendingActions] = useState();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [done, setDone] = useState(false);
  const [isContractor, toggleProfile] = useState(
    userDetails?.userType === "contractor"
  );

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_MAP_API_KEY,
      options: { types: ["(regions)"] },
    });

  const selectLanguage = () => {
    if (selectedLang) {
      i18n.changeLanguage(selectedLang?.shortName?.toLowerCase(), (e, t) => {
        if (e) {
          confirm("error", "Failed to Change language");
        } else {
          setConfirmed(selectedLang);
          window.location.reload();
        }
      });
    }
    setIsOpen(false);
  };

  const capitalizeFirstLetter = (str) => {
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  };

  const openLanguageModal = () => {
    setSelectedLang(confirmed);
    setIsOpen(true);
  };

  const onPlaceSelected = (place) => {
    setSearch(place?.description);
    placesService?.getDetails(
      {
        placeId: place?.place_id,
      },
      (placeDetails) => {
        const { lat, lng } = placeDetails?.geometry?.location?.toJSON();
        setIsDropDownOpen(false);
        setLatLonState({ lat, lon: lng });
        if (history?.location?.pathname !== "/") {
          history.push("/");
        }
      }
    );
  };

  const handleSearchIconClick = (e) => {
    if (placePredictions && placePredictions?.length > 0) {
      const place = placePredictions[0];
      setSearch(place?.description);
      placesService?.getDetails(
        {
          placeId: place?.place_id,
        },
        (placeDetails) => {
          const { lat, lng } = placeDetails?.geometry?.location?.toJSON();
          setIsDropDownOpen(false);
          setLatLonState({ lat, lon: lng });
          if (history?.location?.pathname !== "/") {
            history.push("/");
          }
        }
      );
    }
  };

  const onEnterClick = (e) => {
    if (e.key === "Enter") {
      handleSearchIconClick();
    }
  };

  const getUserInfo = () => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (user?.userType === "owner") {
      if (user && user?._id) {
        ownerProfile(user?._id)
          .then((data) => {
            if (data.data && data.data) {
              const userObj = data.data;
              setUser(userObj);
            }
            if (!data?.data?.userId?.isMobileVerified) {
              console.log("hel");
              setMobileVerifyModal(true);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      if (user && user._id) {
        getContractor(user?._id)
          .then((data) => {
            if (data?.data && data?.data) {
              const userObj = data?.data;
              setUser(userObj);
            }
            if (!data?.data?.contractorId?.isMobileVerified) {
              setMobileVerifyModal(true);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };

  const getPendingActions = () => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (user?.userType === "owner") {
      axiosInstance.get("/owner/pending-actions").then((res) => {
        setPendingActions(res?.data?.data);
        if (!res?.data?.data?.isMobileAdded) {
          setMobileVerifyModal(true);
        }
      });
    } else if (user?.userType === "contractor") {
      axiosInstance.get("/contractor/pending-actions").then((res) => {
        setPendingActions(res?.data?.data);
        if (!res?.data?.data?.isMobileAdded) {
          setMobileVerifyModal(true);
        }
      });
    }
  };

  const handleProfileToggle = () => {
    let _userDetails = getData(LOCAL_STORAGE.user);
    if (_userDetails) {
      let _isContractor = isContractor;
      if (_userDetails.userType === "contractor") {
        _userDetails.userType = "owner";
        _isContractor = false;
      } else {
        if (_userDetails?.originalStatus === "contractor") {
          _userDetails.userType = "contractor";
          _isContractor = true;
          history.push("/contractor-projects");
        } else {
          history.push("/signup");
        }
      }
      setData("user", _userDetails);
      toggleProfile(_isContractor);
    } else {
      confirm("error", "Please login to continue");
    }
  };

  const handleClickOutside = (event) => {
    if (ref?.current && !ref?.current?.contains(event.target)) {
      setIsDropDownOpen(false);
    }
  };

  useEffect(() => {
    const current = i18n.language;
    const language = languagesArray?.filter(
      (item) => item?.shortName?.toLowerCase() === current
    );
    if (language) {
      setConfirmed(language[0]);
      setSelectedLang(language[0]);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (!serviceLocationsModal) {
      getPendingActions();
    }
  }, [serviceLocationsModal]);

  useEffect(() => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (!done) {
      const interval = setInterval(() => {
        if (user?.userType === "contractor" && !isEmpty(pendingActions)) {
          if (!pendingActions?.serviceLocationAdded) {
            setServiceLocationsModal(true);
          } else {
            setDone(true);
          }
        }
      }, 5 * 60 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  return (
    <>
      <div className={`${parentClass} header-container px-3 px-lg-5`}>
        <div className="d-none d-lg-flex justify-content-between align-items-center h-100">
          <div className="header-left d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <Link to="/">
                <img src={IMAGES.home.anyHnbLog} alt="anyhnb-logo" />
              </Link>
            </div>
            {showSearch ? (
              <div className="search-container position-relative ms-2">
                {/* <input
                className=""
                placeholder={t("header.searchPlaceHolder")}
                type="text"
                ref={ref}
                onChange={(e) => setSearch(e?.target?.value)}
                value={search}
              /> */}
                <DebouncedInput
                  placeholder={t("header.searchPlaceHolder")}
                  type="text"
                  className=""
                  onInputChange={(val) => {
                    getPlacePredictions({ input: val });
                    setIsDropDownOpen(true);
                  }}
                  debounceTime={100}
                  value={search}
                  setValue={setSearch}
                  onKeyDown={onEnterClick}
                />
                <img
                  ref={searchIconRef}
                  className="search-icon"
                  src={IMAGES.home.searchIcon}
                  onClick={handleSearchIconClick}
                />
                {placePredictions?.length > 0 && isDropDownOpen ? (
                  <div className="places-dropdown position-absolute d-flex flex-column bg-white w-100">
                    {placePredictions?.map((prediction) => (
                      <div
                        className="places-item d-flex w-100"
                        onClick={() => onPlaceSelected(prediction)}
                      >
                        <img
                          className="position-relative bg-secondary"
                          src={IMAGES.other.locationIcon}
                          height={24}
                        />
                        <span className="">{prediction?.description}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  " "
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="header-right d-flex align-items-center">
            <div
              className="switch me-1"
              onClick={() => {
                handleProfileToggle();
              }}
            >
              {!isContractor ? t("header.switchPersonal") : t("header.switch")}
            </div>
            <div onClick={() => setIsOpen(!isOpen)} className="language me-1">
              <img src={IMAGES.home.globe} alt="" />
              <span>{capitalizeFirstLetter(confirmed?.name)}</span>
            </div>
            <Link to="/favorites">
              <img
                className="me-1"
                src={
                  history?.location?.pathname === "/favorites"
                    ? IMAGES.home.blueHeart
                    : IMAGES.home.heart
                }
              />
            </Link>
            <div className="profile">
              <img
                className="me-1"
                src={IMAGES.home.menuBlack}
                onClick={() => setSidebarOpen(true)}
              />
              <div className="">
                <img
                  src={user?.profileImage || IMAGES.home.defaultUser}
                  className="profile-img"
                  onClick={() => {
                    const currentUser = parseJwt(
                      localStorage.getItem("auth_token")
                    );

                    if (currentUser && currentUser?.userType === "contractor") {
                      history.push(`/contractor/profile`);
                    } else if (
                      currentUser &&
                      currentUser?.userType === "owner"
                    ) {
                      history.push(`/owner/my-profile`);
                    } else {
                      history.push("/role");
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-header d-flex d-lg-none justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <Link to="/">
              <img
                className="any-logo"
                src={IMAGES.home.anyHnbLog}
                alt="anyhnb-logo"
              />
            </Link>
          </div>
          <div className="header-right d-flex align-items-center">
            <div
              className="switch me-2"
              onClick={() => {
                handleProfileToggle();
              }}
            >
              {userDetails?.userType === "contractor"
                ? t("header.switchPersonal")
                : t("header.switch")}
            </div>
            <div onClick={() => openLanguageModal()} className="language me-1">
              <img src={confirmed?.icon} className="me-1 flag" alt="" />
              <span className="me-1">{confirmed?.shortName}</span>
              <img src={IMAGES.home.dropdownArrow} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        className={"choose-language"}
        sx={{ position: "absolute", right: "0", overflowY: "auto" }}
      >
        <div className="language-container">
          <div className="lang-head w-100">
            <h5>{t("header.selectLanguage")}</h5>
            <button
              onClick={() => setIsOpen(false)}
              className="border-0 p-0 bg-transparent"
            >
              <img src={IMAGES.home.modalClose} />
            </button>
          </div>
          <div className="languages">
            {languagesArray?.map((language) => (
              <div
                className={`language-btn ${
                  selectedLang?.name === language?.name ? "selected" : ""
                }`}
                onClick={() => setSelectedLang(language)}
              >
                {language?.name?.toUpperCase()}
              </div>
            ))}
          </div>
          <div className="save">
            <button onClick={() => selectLanguage()} className="save-changes">
              OK
            </button>
          </div>
        </div>
      </Modal>

      <VerifyMobileModal
        setMobileVerifyModal={setMobileVerifyModal}
        mobileVerifyModal={mobileVerifyModal}
        getUserInfo={getUserInfo}
      />

      <Modal
        open={serviceLocationsModal}
        onClose={() => {
          setServiceLocationsModal(false);
        }}
        style={{ overflowY: "auto" }}
        sx={{
          outline: "none",
          margin: "auto",
        }}
      >
        <div
          className="mx-2 mx-sm-auto"
          style={{
            maxWidth: "650px",
            margin: "auto",
            marginTop: "100px",
            marginBottom: "100px",
          }}
          onClick={handleClickOutside}
        >
          <div
            className="d-flex flex-column verify-mobile-modal bg-white position-relative"
            style={{ padding: "10%" }}
          >
            <div
              className="position-absolute"
              style={{
                top: "20px",
                right: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setServiceLocationsModal(false);
              }}
            >
              <img src={IMAGES.home.closeIcon} />
            </div>
            <AddServiceLocationsForm
              setServiceLocationsModal={setServiceLocationsModal}
              dropDownRef={ref}
            />
          </div>
        </div>
      </Modal>
      <Drawer
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        anchor="right"
      >
        <Homedrawer closeHandler={() => setSidebarOpen(false)} />
      </Drawer>
    </>
  );
};

export default Header;
