import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import useNotify from "../../_components/Notification/UseNotify";
import { parseJwt } from "../../services/utils/utilFunctions";
import "./delete.css";
import axiosInstance from "../../services/api/config/api.config";
import { CircularProgress } from "@mui/material";

const DeleteAccount = () => {
  const { t } = useTranslation();
  const { confirm } = useNotify();
  const history = useHistory();
  const user = parseJwt(localStorage.getItem("auth_token"));
  const [isEmailSend, setISEmailSend] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [timer, setTimer] = useState(0);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState({ otp: "", input: "" });
  const [permanentText, setPermanentText] = useState("");
  const [intervalId, setIntervalId] = useState(null);

  const redirect = () => {
    if (user) {
      if (user?.userType === "owner") {
        history.push("/owner/my-profile");
      } else {
        history.push("/contractor/profile");
      }
    } else {
      history.push("/");
    }
  };

  const requestOtp = async () => {
    setEmailSending(true);
    setError({ input: "", otp: "" });
    clearTimeout(intervalId);
    try {
      await axiosInstance.delete("/user", { data: { email: user?.email } });
      setTimer(300);
      setEmailSending(false);
      setISEmailSend(true);
    } catch (err) {
      if (err?.response) {
        confirm("error", err?.response?.data?.message || "Failed to send OTP");
      } else if (err?.request) {
        confirm("error", "No response received from the server");
      } else {
        confirm("error", err?.message || "Failed to send OTP");
      }
      setEmailSending(false);
    }
  };

  const deleteAccount = async () => {
    if (!otp) {
      setError({ otp: "Please verify OTP" });
      return;
    } else if (permanentText !== t("delete.permanent")) {
      setError({ input: "Please fill the fields to continue" });
      return;
    } else {
      const payload = {
        email: user?.email,
        otp: otp,
      };
      setIsDeleting(true);
      try {
        await axiosInstance.post("/delete-user", { ...payload });
        localStorage.clear("user");
        window.location = "/";
      } catch (err) {
        if (err?.response) {
          confirm("error", err?.response?.data?.message || "Failed to delete");
        } else if (err?.request) {
          confirm("error", "No response received from the server");
        } else {
          confirm("error", err?.message || "Failed to delete");
        }
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (timer > 0) {
      const id = setTimeout(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      setIntervalId(id);
    } else {
      setISEmailSend(false);
    }
    return () => clearTimeout(intervalId);
  }, [timer]);

  useEffect(() => {
    if (permanentText === t("delete.permanent")) {
      setError({ input: "" });
    } else if (
      permanentText.length > 0 &&
      permanentText !== t("delete.permanent")
    ) {
      setError({
        input: `${t("delete.typeText")} '${t("delete.permanent")}' ${t(
          "delete.continue"
        )}`,
      });
    }
  }, [permanentText]);

  return (
    <div className="delete-account">
      <div className="delete-container">
        <div className="delete-info">
          <h1>{t("delete.header")}</h1>
          <p>{t("delete.info")}</p>
        </div>
        <div className="delete-form">
          <p className="delete-type-text">
            {t("delete.typeText")} <strong>{t("delete.permanent")}</strong>{" "}
            {t("delete.continue")}
          </p>
          <input
            onPaste={(e) => {
              e.preventDefault();
              confirm("error", "Copy paste not allowed");
            }}
            onChange={(e) => setPermanentText(e.target.value)}
            className="delete-input"
            placeholder={t("delete.permanent")}
          />
          <button
            onClick={requestOtp}
            className="delete-request-btn d-flex justify-content-center align-items-center"
            disabled={emailSending}
          >
            {isEmailSend ? "Resend OTP" : t("delete.requestBtn")}
            {emailSending ? (
              <CircularProgress
                style={{ marginLeft: "10px", color: "#2ccae8" }}
                size={24}
              />
            ) : (
              ""
            )}
          </button>
          {isEmailSend ? (
            <div className="w-100 d-flex justify-content-between align-items-center">
              <p className="delete-otp-text p-0">{t("delete.otpText")}</p>
              <span className="timer">{formatTime(timer)}</span>
            </div>
          ) : (
            <div className="my-4" />
          )}
          <input
            disabled={!isEmailSend}
            className="delete-input"
            placeholder={t("delete.otp")}
            onChange={(e) => setOtp(e.target.value)}
          />
          <small className="text-danger">{error.otp ? error.otp : ""}</small>

          <div className="d-flex">
            <button className="delete-cancel-btn" onClick={() => redirect()}>
              {t("delete.cancelBtn")}
            </button>
            <button
              disabled={permanentText !== t("delete.permanent")}
              onClick={deleteAccount}
              className="delete-btn d-flex justify-content-center align-items-center"
            >
              {t("delete.deleteBtn")}
              {isDeleting ? (
                <CircularProgress
                  style={{ marginLeft: "5px", color: "#ffffff" }}
                  size={24}
                />
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
        <p
          className="text-danger text-center mt-3 p-0 mb-0"
          style={{ fontSize: "12px" }}
        >
          {error.input ? error.input : ""}
        </p>
      </div>
    </div>
  );
};

export default DeleteAccount;
