import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/profileCreate.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import { t } from "i18next";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import CheckBox from "../../../_components/CheckBox/checkBox";
import { Button } from "../../../_components/Button/Button";
import {
  activeServices,
  uploadProfileDocs,
} from "../../../services/api/signup.service";
import Loader from "../../../_components/spinner/Loader";
import { signupModel } from "../../../_redux/Models/auth.models";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { continuetoNext } from "../../../_redux/actions";
import {
  checkAuth,
  groupBy,
  parseJwt,
} from "../../../services/utils/utilFunctions";
import { getContractor } from "../../../services/api/contractor.service";
import Toggle from "../../../_components/Toggle/Toggle";

export const Stepdescription = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { history } = props;
  // const signupModelState = useSelector((state) =>
  //   state.signupReducer ? state.signupReducer : signupModel
  // );
  const signupModelState = signupModel;
  const [isProgress, setIsProgress] = useState(false);
  const [serviceState, setServiceState] = useState([]);

  // Call the service to get full available list
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      checkAuth(props);
    }
    return () => (mounted = false);
  }, [props]);

  const serviceImages = [
    { name: "Floors", img: IMAGES.home.wooden },
    { name: "Kitchen", img: IMAGES.home.kitchenn },
    { name: "Bathroom", img: IMAGES.home.bath },
    { name: "Basement", img: IMAGES.home.basement },
    { name: "Painting", img: IMAGES.home.painting },
    { name: "Wall opening", img: IMAGES.home.wallOpening },
    { name: "Electric", img: IMAGES.home.profileElectric },
    { name: "Plumbing", img: IMAGES.home.profilePlumbing },
    { name: "Siding", img: IMAGES.home.profileFloor },
    { name: "Demolition & hauling", img: IMAGES.home.Demolition_hauling },
    { name: "Pool", img: IMAGES.home.profilePool },
    { name: "Tree services", img: IMAGES.home.Tree_remotion },
    { name: "Deck", img: IMAGES.home.profileDeck },
    { name: "Fences", img: IMAGES.home.fencepaint },
    { name: "Window", img: IMAGES.home.Windows },
    { name: "Roofing", img: IMAGES.home.Roofing },
    { name: "HVAC", img: IMAGES.home.hvac },
  ];

  useLayoutEffect(() => {
    setIsProgress(true);
    onInit();
  }, []);

  const onInit = async () => {
    var res = await activeServices();
    const user = parseJwt(localStorage.getItem("auth_token"));
    var userInfo = await getContractor(user?._id);
    console.log(userInfo.data?.services)
    const userSelectedServices = userInfo?.data?.services
      ? userInfo?.data?.services
      : [];

    if (res && res !== undefined && res.status === 200) {
      function serviceExists(name) {
        return serviceImages.some(function (el) {
          return el.name === name;
        });
      }

      var updatedObject = [];
      if (res && res.data && res.data.length)
        res.data.map((e, i) => {
          if (serviceExists(e.name)) {
            const service = serviceImages.find(
              (service) => service.name === e.name
            );
            updatedObject.push({
              ...e,
              isExpand: "close",
              img: service.img,
            });
          }
        });
      console.log(updatedObject)

      if (updatedObject && updatedObject.length) {
        updatedObject.map((el) =>
          el.subCategory && el.subCategory.length
            ? el.subCategory.map((subEl) => {
              // We need to iterate over user selected services and check if any service is selected
              let selectedSub = false;
              const category = userSelectedServices.find(
                (el) => subEl.categoryId === el.categoryId
              );
              if (category && category.subCategories) {
                selectedSub = category.subCategories.find(
                  (sub) => sub.subCategoryId === subEl._id
                );
              }
              return (subEl["isCheck"] =
                typeof selectedSub !== "boolean" &&
                typeof selectedSub !== "undefined");
            })
            : el
        );
      }
      setServiceState(updatedObject);
      setIsProgress(false);
    } else {
      if (res.response.status > 200) {
        setIsProgress(false);
        const variant = "error";
        const errorMessage = res.response.data.message;
        enqueueSnackbar(errorMessage, { variant });
      }
    }
    setIsProgress(false);
  };

  const stepdescClick = () => {
    setIsProgress(true);
    let selectedServices = [];
    serviceState.map((data, i) => {
      if (data && data.subCategory && data.subCategory.length) {
        data.subCategory.map((subData) => {
          if (subData.isCheck) {
            const index = selectedServices.findIndex(
              (object) => object.selectedItem === data.text
            );
            if (index === -1) {
              selectedServices.push({
                selectedItem: serviceState[i].text,
                uploadedDocs: [],
              });
            }
          }
        });
      }
    });

    //signupModelState.profileInfo.files.picturesOfWorks = selectedServices;
    signupModelState.services = serviceState;

    const filteredServiceArr = [];
    let authInfo = { _id: "" };
    if (localStorage.getItem("authInfo")) {
      authInfo = JSON.parse(localStorage.getItem("authInfo"));
    }

    console.log(serviceState);
    for (let i = 0; i < serviceState.length; i++) {
      const cat = serviceState[i];
      const subArr = cat.subCategory.filter((sub) => sub.isCheck === true);
      if (subArr.length > 0) {
        const selectedServices = subArr.map((sub) => {
          return {
            contractorId: authInfo._id,
            categoryId: cat._id,
            subCategoryId: sub._id,
            category_name: cat.name,
            subCategory_name: sub.name,
            subCategory_key: sub.key,
          };
        });
        var temp = {
          category: cat,
          subCategories: selectedServices,
        };
        filteredServiceArr.push(temp);
      }
    }
    signupModelState.selectedServices = filteredServiceArr;
    const servicesToSend = filteredServiceArr.map(
      (service) => service.subCategories
    );
    dispatch(continuetoNext(signupModelState));
    const selectedServicesToSend = [].concat.apply([], servicesToSend);
    const grouped = groupBy(selectedServicesToSend, "categoryId");
    const formattedServicesToSend = [];
    for (const categoryId in grouped) {
      if (Object.hasOwnProperty.call(grouped, categoryId)) {
        const subCategories = grouped[categoryId];
        const t = {
          categoryId: categoryId,
          category_name: subCategories[0].category_name,
          category_key: subCategories[0].category_key,
          subCategories: subCategories,
        };
        formattedServicesToSend.push(t);
      }
    }
    console.log(formattedServicesToSend);
    saveServicesToServer(JSON.stringify(formattedServicesToSend));
  };

  const goToNext = () => {
    if (props.location.state?.redirectUrl) {
      history.push(props.location.state.redirectUrl);
    } else {
      history.push(`/createProfile/profile-info/${2}`);
    }
  };

  const saveServicesToServer = (servicesToSend) => {
    const postData = new FormData();
    if (servicesToSend) {
      postData.append("services", servicesToSend);

      uploadProfileDocs(postData)
        .then((data) => {
          if (data.status !== 200) {
            const variant = "error";
            enqueueSnackbar("Services adding failed! please try again", {
              variant,
              transitionDuration: 300,
            });
          } else {
            goToNext();
          }
        })
        .catch((e) => {
          const variant = "error";
          enqueueSnackbar(e.toString(), { variant });
        })
        .finally((_) => {
          setIsProgress(false);
        });
    }
  };

  const ServiceList = ({ item }) => {
    const onActiveHandler = (event, item) => {
      let isActiveStates = [...serviceState];
      const selectedID = isActiveStates.findIndex((e) => e._id === item._id);
      isActiveStates[selectedID].isExpand =
        isActiveStates[selectedID].isExpand === "open" ? "close" : "open";
      setServiceState(isActiveStates);
      if (event !== null) event.stopPropagation();
    };
    const isServiceCheck = (bool, index, item) => {
      let isActiveStates = [...serviceState];
      const selectedID = isActiveStates.findIndex((e) => e._id === item._id);
      isActiveStates[selectedID].subCategory[index] = {
        ...isActiveStates[selectedID].subCategory[index],
        isCheck: bool,
      };
      setServiceState(isActiveStates);
    };
    return (
      <div>
        <table className="profileTablewidth" id="rtr">
          <tr className="profileFlex">
            <div
              className="profilediv"
              onClick={(e) => onActiveHandler(e, item)}
            >
              <td className="td1 ">
                <img className="profileDivmargin" src={item.img} alt="" />
              </td>
              <td className="td2 ">
                <div className="profileDivtxt profileDivmargin2">
                  {" "}
                  {item.name}
                </div>
              </td>
              {console.log(item)}
              <td className="td3">
                <div
                  className={`profileDivmargin3 ${item.isExpand === "open" ? "profileOpen" : "profileClose"
                    }`}
                >
                  <img src={IMAGES.other.dropdownarrow} alt="" />
                </div>
              </td>
            </div>
          </tr>
        </table>
        <table className="profileTablewidth " id="rtr">
          <tr className={`profileFlex  ${item.isExpand}`}>
            {item.subCategory && item.subCategory
              ? item.subCategory.map((subItem, index) => (
                <div
                  className=" profiledivSub"
                  onClick={() => {
                    isServiceCheck(!subItem.isCheck, index, item);
                  }}
                >
                  <td className="profilePadding15">
                    <div className="profileDivtxt"> {subItem.name}</div>
                  </td>
                  <td className="td3 profilePadding20">
                    <div className="profileDivmarginchk">
                      <Toggle
                        props={{
                          isChecked: subItem.isCheck || false,
                          onClick: () =>
                            isServiceCheck(!subItem.isCheck, index, item),
                        }}
                      />
                    </div>
                  </td>
                </div>
              ))
              : ""}
          </tr>
        </table>
      </div>
    );
  };

  return (
    <div className="insideWrp">
      <HeaderMenu />
      <Loader progress={isProgress} />
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_other_sub_header profilePadding10">
          <label>
            Create a profile with Anyhnb to start receving curated and high
            quality clients. The most effort you put to your profile, the more
            you will get picked for jobs!
          </label>
        </div>
        <div className="profilePadding10"></div>
        <div className="paint_flex_with_left profilePadding10">
          <label className="paint_header">{t("profileCreation.what")}</label>
        </div>

        <div className="profilePadding10">
          <label className="paint_other_sub_header">
            {t("profileCreation.active")}
          </label>
        </div>
        <div className="profilePadding30"></div>
        {serviceState && serviceState.length
          ? serviceState.map((element, index) => {
            return <ServiceList item={element} key={element._id + index} />;
          })
          : ""}

        <div className="profilePadding20"></div>
        <div className="profilePadding20"></div>
        <div className="profilePadding20"></div>
        <Button
          disabled={false}
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => stepdescClick()}
        />
      </div>
      <Footer />
    </div>
  );
};
