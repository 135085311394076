import { useState, useEffect, useRef } from "react";
import { v4 as uuid } from "uuid";
import SendbirdChat from "@sendbird/chat";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";

import Loader from "../../../_components/spinner/Loader";
import {
  GroupChannelHandler,
  GroupChannelModule,
} from "@sendbird/chat/groupChannel";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";

// import { SENDBIRD_INFO } from "../constants/constants";
import {
  timestampToTime,
  handleEnterPress,
} from "../../../services/utils/utilFunctions";
import "./chat.css";
let sb = {
  currentUser: {
    userId: "",
  },
};

const GroupChannelTypingIndicatorSample = (props) => {
  //   const SENDBIRD_INFO = process.env.REACT_APP_SENDBIRD_APP_ID;
  const user = JSON.parse(localStorage.getItem("user"));

  const [state, updateState] = useState({
    applicationUsers: [],
    groupChannelMembers: [],
    currentlyJoinedChannel: null,
    messages: [],
    channels: [],
    messageInputValue: "",
    userNameInputValue: user.name,
    userIdInputValue: user._id,
    channelNameUpdateValue: "",
    settingUpUser: true,
    file: null,
    messageToUpdate: null,
    typingMembers: [],
    loading: true,
    error: false,
  });
  const [isOpenList, setIsOpenList] = useState(true);

  //need to access state in message received callback
  const stateRef = useRef();
  stateRef.current = state;

  const channelRef = useRef();

  const scrollToBottom = (item, smooth) => {
    item?.scrollTo({
      top: item.scrollHeight,
      behavior: smooth,
    });
  };

  useEffect(() => {
    if (user && user._id) {
      setupUser();
    } else {
      props.history.replace("/role");
    }
  }, []);

  useEffect(() => {
    scrollToBottom(channelRef.current);
  }, [state.currentlyJoinedChannel]);

  useEffect(() => {
    scrollToBottom(channelRef.current, "smooth");
  }, [state.messages]);

  useEffect(() => {
    const splitArr = props.location.search.split("=");
    if (splitArr[splitArr.length - 1] && state.channels?.length > 0) {
      handleJoinChannel(splitArr[splitArr.length - 1]);
    }
  }, [JSON.stringify(state.channels)]);

  const onError = (error) => {
    updateState({ ...state, error: error.message });
  };

  const handleJoinChannel = async (channelUrl) => {
    if (state.currentlyJoinedChannel?.url === channelUrl) {
      return null;
    }
    const { channels } = state;
    updateState({ ...state, typingMembers: [], loading: true });
    const channel = channels.find((channel) => channel.url === channelUrl);
    const [messages, error] = await joinChannel(channel);
    if (error) {
      return onError(error);
    }
    // listen for incoming messages
    const channelHandler = new GroupChannelHandler();
    channelHandler.onUserJoined = () => {};
    channelHandler.onChannelChanged = () => {};
    channelHandler.onMessageUpdated = (channel, message) => {
      const messageIndex = stateRef.current.messages.findIndex(
        (item) => item.messageId == message.messageId
      );
      const updatedMessages = [...stateRef.current.messages];
      updatedMessages[messageIndex] = message;
      updateState({ ...stateRef.current, messages: updatedMessages });
    };

    channelHandler.onMessageReceived = (channel, message) => {
      const updatedMessages = [...stateRef.current.messages, message];
      updateState({ ...stateRef.current, messages: updatedMessages });
    };

    channelHandler.onTypingStatusUpdated = (groupChannel) => {
      if (groupChannel.url === channel.url) {
        const members = groupChannel.getTypingUsers();
        updateState({ ...stateRef.current, typingMembers: members });
      } else {
        const members = [];
        updateState({ ...stateRef.current, typingMembers: members });
      }
    };

    channelHandler.onMessageDeleted = (channel, message) => {
      const updatedMessages = stateRef.current.messages.filter(
        (messageObject) => {
          return messageObject.messageId !== message;
        }
      );
      updateState({ ...stateRef.current, messages: updatedMessages });
    };
    sb.groupChannel.addGroupChannelHandler(uuid(), channelHandler);
    updateState({
      ...state,
      currentlyJoinedChannel: channel,
      messages: messages,
      loading: false,
    });

    if (window.innerWidth < 768) setIsOpenList(false);
  };

  const handleLeaveChannel = async () => {
    const { currentlyJoinedChannel } = state;
    await currentlyJoinedChannel.leave();

    updateState({ ...state, currentlyJoinedChannel: null });
  };

  const handleCreateChannel = async (channelName = "testChannel") => {
    const [groupChannel, error] = await createChannel(
      channelName,
      state.groupChannelMembers
    );
    if (error) {
      return onError(error);
    }

    const updatedChannels = [groupChannel, ...state.channels];
    updateState({ ...state, channels: updatedChannels, applicationUsers: [] });
  };

  const handleUpdateChannelMembersList = async () => {
    const { currentlyJoinedChannel, groupChannelMembers } = state;
    await inviteUsersToChannel(currentlyJoinedChannel, groupChannelMembers);
    updateState({ ...state, applicationUsers: [] });
  };

  // const handleDeleteChannel = async (channelUrl) => {
  //   const [channel, error] = await deleteChannel(channelUrl);
  //   if (error) {
  //     return onError(error);
  //   }
  //   const updatedChannels = state.channels.filter((channel) => {
  //     return channel.url !== channelUrl;
  //   });
  //   updateState({ ...state, channels: updatedChannels });
  // };

  const handleMemberInvite = async () => {
    const [users, error] = await getAllApplicationUsers();
    if (error) {
      return onError(error);
    }
    updateState({ ...state, applicationUsers: users });
  };

  // const onUserNameInputChange = (e) => {
  //   const userNameInputValue = e.currentTarget.value;
  //   updateState({ ...state, userNameInputValue });
  // };

  // const onUserIdInputChange = (e) => {
  //   const userIdInputValue = e.currentTarget.value;
  //   updateState({ ...state, userIdInputValue });
  // };

  const onMessageInputChange = (e) => {
    const { currentlyJoinedChannel, messageToUpdate } = state;
    const messageInputValue = e.currentTarget.value;

    // Event should not be triggered if it is an update message in progress
    if (messageToUpdate === null) {
      messageInputValue !== ""
        ? currentlyJoinedChannel.startTyping()
        : currentlyJoinedChannel.endTyping();
    }

    updateState({ ...state, messageInputValue });
  };

  const sendMessage = async () => {
    const { messageToUpdate, currentlyJoinedChannel, messages } = state;
    if (messageToUpdate) {
      const userMessageUpdateParams = {};
      userMessageUpdateParams.message = state.messageInputValue;
      const updatedMessage = await currentlyJoinedChannel.updateUserMessage(
        messageToUpdate.messageId,
        userMessageUpdateParams
      );
      const messageIndex = messages.findIndex(
        (item) => item.messageId == messageToUpdate.messageId
      );
      messages[messageIndex] = updatedMessage;
      updateState({
        ...state,
        messages: messages,
        messageInputValue: "",
        messageToUpdate: null,
      });
    } else {
      const userMessageParams = {};
      userMessageParams.message = state.messageInputValue;
      currentlyJoinedChannel
        .sendUserMessage(userMessageParams)
        .onSucceeded((message) => {
          const updatedMessages = [...messages, message];
          currentlyJoinedChannel.endTyping();
          updateState({
            ...state,
            messages: updatedMessages,
            messageInputValue: "",
          });
        })
        .onFailed((error) => {
          console.log(error);
          console.log("failed");
        });
    }
  };

  const onFileInputChange = async (e) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const { currentlyJoinedChannel, messages } = state;
      const fileMessageParams = {};
      fileMessageParams.file = e.currentTarget.files[0];
      currentlyJoinedChannel
        .sendFileMessage(fileMessageParams)
        .onSucceeded((message) => {
          const updatedMessages = [...messages, message];
          updateState({
            ...state,
            messages: updatedMessages,
            messageInputValue: "",
            file: null,
          });
        })
        .onFailed((error) => {
          console.log(error);
          console.log("failed");
        });
    }
  };

  const handleDeleteMessage = async (messageToDelete) => {
    const { currentlyJoinedChannel } = state;
    await deleteMessage(currentlyJoinedChannel, messageToDelete); // Delete
  };

  const updateMessage = async (message) => {
    updateState({
      ...state,
      messageToUpdate: message,
      messageInputValue: message.message,
    });
  };

  const handleLoadMemberSelectionList = async () => {
    updateState({ ...state, currentlyJoinedChannel: null });
    const [users, error] = await getAllApplicationUsers();
    if (error) {
      return onError(error);
    }
    updateState({
      ...state,
      currentlyJoinedChannel: null,
      applicationUsers: users,
      groupChannelMembers: [sb.currentUser.userId],
    });
  };

  const addToChannelMembersList = (userId) => {
    const groupChannelMembers = [...state.groupChannelMembers, userId];
    updateState({ ...state, groupChannelMembers: groupChannelMembers });
  };

  const setupUser = async () => {
    const { userNameInputValue, userIdInputValue } = state;
    const sendbirdChat = await SendbirdChat.init({
      appId: process.env.REACT_APP_SENDBIRD_APP_ID,
      localCacheEnabled: false,
      modules: [new GroupChannelModule()],
    });

    await sendbirdChat.connect(userIdInputValue);
    await sendbirdChat.setChannelInvitationPreference(true);

    const userUpdateParams = {};
    userUpdateParams.nickname = userNameInputValue;
    userUpdateParams.userId = userIdInputValue;
    await sendbirdChat.updateCurrentUserInfo(userUpdateParams);

    sb = sendbirdChat;
    // updateState({ ...state, loading: true });
    const [channels, error] = await loadChannels();
    if (error) {
      return onError(error);
    }
    updateState({
      ...state,
      channels: channels,
      loading: false,
      settingUpUser: false,
    });
  };

  if (state.error) {
    return (
      <div className="error">
        {state.error} check console for more information.
      </div>
    );
  }

  const toggelList = () => {
    setIsOpenList(!isOpenList);
    // updateState((state) => ({ ...state, isOpenList: !state.isOpenList }));
  };

  return (
    <div className="chat-page-wrapper">
      <Loader progress={state.loading} />
      <HeaderMenu />
      {/* <CreateUserForm
        setupUser={setupUser}
        userNameInputValue={state.userNameInputValue}
        userIdInputValue={state.userIdInputValue}
        settingUpUser={state.settingUpUser}
        onUserIdInputChange={onUserIdInputChange}
        onUserNameInputChange={onUserNameInputChange}
      /> */}
      <div className="chat-wrapper">
        <ChannelList
          isLoading={state.loading}
          channels={state.channels}
          handleJoinChannel={handleJoinChannel}
          handleCreateChannel={handleLoadMemberSelectionList}
          handleToggelList={toggelList}
          isOpenList={isOpenList}
          // handleDeleteChannel={handleDeleteChannel}
          // handleLoadMemberSelectionList={handleLoadMemberSelectionList}
        />
        <MembersSelect
          applicationUsers={state.applicationUsers}
          groupChannelMembers={state.groupChannelMembers}
          currentlyJoinedChannel={state.currentlyJoinedChannel}
          addToChannelMembersList={addToChannelMembersList}
          handleCreateChannel={handleCreateChannel}
          handleUpdateChannelMembersList={handleUpdateChannelMembersList}
        />
        <Channel
          currentlyJoinedChannel={state.currentlyJoinedChannel}
          handleLeaveChannel={handleLeaveChannel}
          channelRef={channelRef}
        >
          <MessagesList
            messages={state.messages}
            handleDeleteMessage={handleDeleteMessage}
            updateMessage={updateMessage}
          />
          {state.typingMembers.length > 0 &&
            DisplayTypingIndicator(state.typingMembers)}
          <MessageInput
            value={state.messageInputValue}
            onChange={onMessageInputChange}
            sendMessage={sendMessage}
            fileSelected={state.file}
            onFileInputChange={onFileInputChange}
          />
        </Channel>
        {/* <MembersList
        channel={state.currentlyJoinedChannel}
        handleMemberInvite={handleMemberInvite}
      /> */}
      </div>
    </div>
  );
};

// Chat UI Components
const ChannelList = ({
  isLoading,
  channels,
  handleJoinChannel,
  handleToggelList,
  isOpenList,
  // handleDeleteChannel,
  // handleLoadMemberSelectionList,
}) => {
  return (
    <div className={`channel-list ${isOpenList ? "" : "hide"}`}>
      <div className="channel-type">
        <h1>Chats</h1>
        <ArrowBackIosIcon onClick={handleToggelList} />
        {/* <button
          className="channel-create-button"
          onClick={() => handleLoadMemberSelectionList()}
        >
          Create Channel
        </button> */}
      </div>
      <div
        className={`channel-wrapper ${!channels.length ? "no-channel" : ""}`}
      >
        {isLoading && <div className="loading-text">Loading...</div>}
        {!channels.length && !isLoading ? (
          <div className="no-channel-text">No channels</div>
        ) : null}
        {channels.map((channel) => {
          return (
            <div
              key={channel.url}
              className="channel-list-item"
              onClick={() => {
                handleJoinChannel(channel.url);
              }}
            >
              <div className="channel-list-item-name">
                <ChannelName members={channel.members} />
                <div className="last-message">
                  {channel.lastMessage?.message}
                </div>
              </div>
              {/* <div>
              <button
                className="control-button"
                onClick={() => handleDeleteChannel(channel.url)}
              >
                <img className="channel-icon" src="/icon_delete.png" />
              </button>
            </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ChannelName = ({ members }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const member = members.find((member) => member.userId !== user._id);
  if (!member?.userId) return null;
  return (
    <>
      <span key={member.userId}>{member.nickname} </span>
    </>
  );
};

const Channel = ({
  currentlyJoinedChannel,
  children,
  handleLeaveChannel,
  channelRef,
}) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (currentlyJoinedChannel) {
    const member = currentlyJoinedChannel.members.find(
      (member) => member.userId !== user._id
    );
    const senderName = member?.nickname;
    return (
      <div className="channel" ref={channelRef}>
        <ChannelHeader>
          {senderName || currentlyJoinedChannel.name}
        </ChannelHeader>
        {/* <div>
          <button className="leave-channel" onClick={handleLeaveChannel}>
            Leave Channel
          </button>
        </div> */}
        <div>{children}</div>
      </div>
    );
  }
  return <div className="channel"></div>;
};

const ChannelHeader = ({ children }) => {
  return (
    <div className="channel-header">
      {typeof children === "string" ? children.split(" ")[0] : children}
    </div>
  );
};

const MembersList = ({ channel, handleMemberInvite }) => {
  if (channel) {
    return (
      <div className="members-list">
        <button onClick={handleMemberInvite}>Invite</button>
        {channel.members.map((member) => (
          <div className="member-item" key={member.userId}>
            {member.nickname}
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
};

const MessagesList = ({ messages, handleDeleteMessage, updateMessage }) => {
  return (
    <div className="message-list">
      {messages.map((message) => {
        const messageSentByYou =
          message.sender.userId === sb.currentUser.userId;

        return (
          <div
            key={message.messageId}
            className={`message-item ${
              messageSentByYou ? "message-from-you" : ""
            }`}
          >
            <Message
              message={message}
              handleDeleteMessage={handleDeleteMessage}
              updateMessage={updateMessage}
              messageSentByYou={messageSentByYou}
            />
            <ProfileImage user={message.sender} />
          </div>
        );
      })}
    </div>
  );
};

const Message = ({
  message,
  updateMessage,
  handleDeleteMessage,
  messageSentByYou,
}) => {
  if (message.url) {
    return (
      <div
        className={`chat-message  ${
          messageSentByYou ? "message-from-you" : ""
        }`}
      >
        <div className="message-user-info">
          <div className="message-sender-name">{message.sender.nickname} </div>
          <div>{timestampToTime(message.createdAt)}</div>
        </div>
        {/\.(gif|jpe?g|tiff?|png|webp|bmp)/i.test(message.url) ? (
          <img src={message.url} />
        ) : (
          <DescriptionIcon onClick={() => window.open(message.url, "_blank")} />
        )}
      </div>
    );
  }
  const messageSentByCurrentUser =
    message.sender.userId === sb.currentUser.userId;

  return (
    <div
      className={`chat-message  ${messageSentByYou ? "message-from-you" : ""}`}
    >
      <div className="message-info">
        <div className="message-user-info">
          <div className="message-sender-name">{message.sender.nickname} </div>
          <div>{timestampToTime(message.createdAt)}</div>
        </div>
        {messageSentByCurrentUser && (
          <div className="option-wrapper">
            <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={() => updateMessage(message)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="delete"
              onClick={() => handleDeleteMessage(message)}
            >
              <DeleteIcon />
            </IconButton>
            {/* <button
              className="control-button"
              onClick={() => updateMessage(message)}
            >
              <img className="message-icon" src="/icon_edit.png" />
            </button>
            <button
              className="control-button"
              onClick={() => handleDeleteMessage(message)}
            >
              <img className="message-icon" src="/icon_delete.png" />
            </button> */}
          </div>
        )}
      </div>
      <div>{message.message}</div>
    </div>
  );
};

const ProfileImage = ({ user }) => {
  if (user.plainProfileUrl) {
    return <img className="profile-image" src={user.plainProfileUrl} />;
  } else {
    return (
      <div className="profile-image-fallback">{user.nickname.charAt(0)}</div>
    );
  }
};

const MessageInput = ({ value, onChange, sendMessage, onFileInputChange }) => {
  return (
    <div className="message-input">
      <input
        placeholder="write a message"
        value={value}
        onChange={onChange}
        onKeyDown={(event) => handleEnterPress(event, sendMessage)}
      />
      <div className="message-input-buttons">
        <label className="file-upload-label" htmlFor="upload">
          <AttachFileIcon />
        </label>
        <input
          id="upload"
          className="file-upload-button"
          type="file"
          hidden={true}
          onChange={onFileInputChange}
          onClick={() => {}}
        />
        <IconButton aria-label="sent" onClick={sendMessage}>
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};

const MembersSelect = ({
  applicationUsers,
  groupChannelMembers,
  currentlyJoinedChannel,
  addToChannelMembersList,
  handleCreateChannel,
  handleUpdateChannelMembersList,
}) => {
  if (applicationUsers.length > 0) {
    return (
      <div className="overlay">
        <div className="overlay-content">
          <button
            onClick={() => {
              if (currentlyJoinedChannel) {
                handleUpdateChannelMembersList();
              } else {
                handleCreateChannel();
              }
            }}
          >
            {currentlyJoinedChannel ? "Submit" : "Create"}
          </button>
          {applicationUsers.map((user) => {
            const userSelected = groupChannelMembers.some(
              (member) => member === user.userId
            );
            return (
              <div
                key={user.userId}
                className={`member-item ${
                  userSelected ? "member-selected" : ""
                }`}
                onClick={() => addToChannelMembersList(user.userId)}
              >
                <ProfileImage user={user} />
                <div className="member-item-name">{user.nickname}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};

// const CreateUserForm = ({
//   setupUser,
//   settingUpUser,
//   userNameInputValue,
//   userIdInputValue,
//   onUserNameInputChange,
//   onUserIdInputChange,
// }) => {
//   if (settingUpUser) {
//     return (
//       <div className="overlay">
//         <div
//           className="overlay-content"
//           onKeyDown={(event) => handleEnterPress(event, setupUser)}
//         >
//           <div>User ID</div>
//           <input
//             onChange={onUserIdInputChange}
//             className="form-input"
//             type="text"
//             value={userIdInputValue}
//           />

//           <div>User Nickname</div>
//           <input
//             onChange={onUserNameInputChange}
//             className="form-input"
//             type="text"
//             value={userNameInputValue}
//           />

//           <button className="user-submit-button" onClick={setupUser}>
//             Connect
//           </button>
//         </div>
//       </div>
//     );
//   } else {
//     return null;
//   }
// };

const DisplayTypingIndicator = (typingMembers) => {
  let typingIndicatorText = "";

  typingMembers.length === 1
    ? (typingIndicatorText = typingMembers[0].nickname + " is typing...")
    : typingMembers.length === 2
    ? (typingIndicatorText =
        typingMembers[0].nickname +
        ", " +
        typingMembers[1].nickname +
        " are typing...")
    : (typingIndicatorText =
        typingMembers[0].nickname +
        ", " +
        typingMembers[1].nickname +
        " and others are typing...");

  return <div className="typing-indicator">{typingIndicatorText}</div>;
};

// Helpful functions that call Sendbird
const loadChannels = async () => {
  try {
    const groupChannelQuery = sb.groupChannel.createMyGroupChannelListQuery({
      limit: 30,
      includeEmpty: true,
    });
    const channels = await groupChannelQuery.next();
    return [channels, null];
  } catch (error) {
    return [null, error];
  }
};

const joinChannel = async (channel) => {
  try {
    const messageListParams = {};
    messageListParams.nextResultSize = 20;
    const messages = await channel.getMessagesByTimestamp(0, messageListParams);
    return [messages, null];
  } catch (error) {
    return [null, error];
  }
};

const inviteUsersToChannel = async (channel, userIds) => {
  await channel.inviteWithUserIds(userIds);
};

const createChannel = async (channelName, userIdsToInvite) => {
  try {
    const groupChannelParams = {};
    groupChannelParams.addUserIds = userIdsToInvite;
    groupChannelParams.name = channelName;
    groupChannelParams.operatorUserIds = userIdsToInvite;
    const groupChannel = await sb.groupChannel.createChannel(
      groupChannelParams
    );
    return [groupChannel, null];
  } catch (error) {
    return [null, error];
  }
};

// const deleteChannel = async (channelUrl) => {
//   try {
//     const channel = await sb.groupChannel.getChannel(channelUrl);
//     await channel.delete();
//     return [channel, null];
//   } catch (error) {
//     return [null, error];
//   }
// };

const deleteMessage = async (currentlyJoinedChannel, messageToDelete) => {
  await currentlyJoinedChannel.deleteMessage(messageToDelete);
};

const getAllApplicationUsers = async () => {
  try {
    const userQuery = sb.createApplicationUserListQuery({ limit: 100 });
    const users = await userQuery.next();
    return [users, null];
  } catch (error) {
    return [null, error];
  }
};

export default GroupChannelTypingIndicatorSample;
