import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/kitchen.css";
import "../../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import CheckBox from "../../../_components/CheckBox/checkBox";
import { Button } from "../../../_components/Button/Button";
import { floorLaminatedModel } from "../../../_redux/Models/Floor.model";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const CurrentTypeOfFloor = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const floorState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorLaminatedModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [floorOptions, setFloorOptions] = useState([]);
  const [inputedSqfts, setInputedSqfts] = useState({
    glue_down: {
      value: 0,
      price: 0,
    },
    nail_down: {
      value: 0,
      price: 0,
    },
    tiles: {
      value: 0,
      price: 0,
    },
    vynil: {
      value: 0,
      price: 0,
    },
    concrete: {
      value: 0,
      price: 0,
    },
    carpet: {
      value: 0,
      price: 0,
    },
  });
  const [price, setPrice] = useState(calculateTotalCost(floorState.costFor)); // To keep the total price in state

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails) {
      const floorOptions = priceDetails.priorFloor.map((op) => {
        return {
          text: t(`floor.${op.key}`),
          inputLabel: t(`floor.${op.key}_sqft`),
          value: op.key,
          price: op.price,
        };
      });
      setFloorOptions(floorOptions);
    }
    return () => (mounted = false);
  }, [checkoutModelState, inputedSqfts, t]);

  const [selectedFloorObjects, setSelectedFloorObjects] = useState([]);
  const [totalSqftMismatch, setTotalSqftMismatch] = useState(false);
  const [totalSqft, setTotalSqft] = useState(0);

  /**
   * On sqft  input change
   * @param {HTMLInputElement} e
   */
  const onChangeSqftValue = (e, item) => {
    let newValue = { ...inputedSqfts };
    newValue[e.target.id]["value"] = parseInt(e.target.value);
    newValue[e.target.id]["price"] = parseInt(e.target.value) * item.price;
    setInputedSqfts(newValue);
    setTotalPrice();
    var floorSize = floorState.floorSize ? parseInt(floorState.floorSize) : 0;
    if (floorSize > 0) {
      var inputedSqftsTotal =
        parseInt(inputedSqfts.glue_down.value) +
        parseInt(inputedSqfts.nail_down.value) +
        parseInt(inputedSqfts.tiles.value) +
        parseInt(inputedSqfts.vynil.value) +
        parseInt(inputedSqfts.concrete.value) +
        parseInt(inputedSqfts.carpet.value);
      setTotalSqft(inputedSqftsTotal);
      setTotalSqftMismatch(inputedSqftsTotal > floorSize);
    }
  };

  /**
   * Setting the total price of the selected services
   */
  const setTotalPrice = (tempState) => {
    tempState = tempState ? tempState : selectedFloorObjects;
    let totalPrice = checkoutModelState.totalCost;
    Object.keys(inputedSqfts).forEach((key) => {
      if (tempState.includes(key)) {
        totalPrice = totalPrice + parseFloat(inputedSqfts[key]["price"]);
      } else {
        inputedSqfts[key]["price"] = 0;
      }
    });
    setInputedSqfts(inputedSqfts);
    setPrice(totalPrice ? totalPrice : calculateTotalCost(floorState.costFor));
  };

  /**
   * Save the Floor values to state and move to next step
   */
  const saveCurrentFloorDetails = () => {
    const floorSize = floorState.floorSize ? parseInt(floorState.floorSize) : 0;
    const formValid =
      parseInt(inputedSqfts.glue_down.value) +
      parseInt(inputedSqfts.nail_down.value) +
      parseInt(inputedSqfts.tiles.value) +
      parseInt(inputedSqfts.vynil.value) +
      parseInt(inputedSqfts.concrete.value) +
      parseInt(inputedSqfts.carpet.value);
    if (!formValid) return setTotalSqftMismatch(formValid > floorSize);
    setTotalSqft(formValid);

    floorState.isGlueDownSelected = selectedFloorObjects.includes("glue_down");
    floorState.isNailDownSelected = selectedFloorObjects.includes("nail_down");
    floorState.isTilesSelected = selectedFloorObjects.includes("tiles");
    floorState.isVinylSelected = selectedFloorObjects.includes("vynil");
    floorState.isConcreteSelected = selectedFloorObjects.includes("concrete");
    floorState.isCarpetSelected = selectedFloorObjects.includes("carpet");
    floorState.glueDownSQFT = inputedSqfts.glue_down.value;
    floorState.nailDownSQFT = inputedSqfts.nail_down.value;
    floorState.tilesSQFT = inputedSqfts.tiles.value;
    floorState.vinylSQFT = inputedSqfts.vynil.value;
    floorState.concreteSQFT = inputedSqfts.concrete.value;
    floorState.carpetSQFT = inputedSqfts.carpet.value;

    var currentFloor = [
      {
        key: "glue",
        val: inputedSqfts.glue_down.value,
        price: inputedSqfts.glue_down.price,
      },
      {
        key: "nail",
        val: inputedSqfts.nail_down.value,
        price: inputedSqfts.nail_down.price,
      },
      {
        key: "tile",
        val: inputedSqfts.tiles.value,
        price: inputedSqfts.tiles.price,
      },
      {
        key: "vinyl",
        val: inputedSqfts.vynil.value,
        price: inputedSqfts.vynil.price,
      },
      {
        key: "concrete",
        val: inputedSqfts.concrete.value,
        price: inputedSqfts.concrete.price,
      },
      {
        key: "carpet",
        val: inputedSqfts.carpet.value,
        price: inputedSqfts.carpet.price,
      },
    ];
    floorState.currentFloor = currentFloor;
    floorState.costFor.floorRemoval =
      price - calculateTotalCost(floorState.costFor);
    checkoutModelState.totalCost = price;
    dispatch(floorStore(floorState));
    dispatch(checkoutStore(checkoutModelState));
    var step = floorLaminatedModel.priorFloorRemovalRequired ? 5 : 4;
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/laminated/floor-addons/${step}`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("floor.curfloors")}</label>
        </div>
        <div className="paint_flex_with_left kitgrid">
          <label className="floor_other_sub_header ">{t("floor.letuss")}</label>
          <label className="sumhead1 ">{t("floor.one")}</label>
        </div>
        <div className="padding10"></div>
        {floorOptions &&
          floorOptions.map((item, index) => (
            <div key={index} className=" ">
              <div
                className={`floor-selection-radio ${
                  selectedFloorObjects?.includes(item?.value) ? "selected" : ""
                }`}
                onClick={() => {
                  setSelectedFloorObjects((previousState) => {
                    let tempState;
                    if (previousState.includes(item.value)) {
                      tempState = previousState.filter((i) => i !== item.value);
                    } else {
                      tempState = [...previousState, item.value];
                    }
                    setTotalPrice(tempState);
                    return tempState;
                  });
                }}
              >
                <div
                  key={index}
                  className="width100 "
                  style={{ justifyContent: "space-between" }}
                >
                  <table style={{ placeItems: "center" }}>
                    <tr>
                      <td>
                        <label className="paintdiv_txt"> {item.text} </label>
                      </td>
                      <td>
                        <div className="paint_arrowFloat flex">
                          <div style={{ paddingTop: "7px" }}>
                            <img
                              src={IMAGES.other.green_plus}
                              className="kitchenplusimg"
                              alt=""
                            />
                            <label
                              className="paintdiv_txt paddingleft8"
                              style={{ paddingLeft: "5px" }}
                            >
                              {inputedSqfts?.[item.value]?.["price"] > 0
                                ? `$${formatPrice(
                                    inputedSqfts[item.value]["price"]
                                  )}`
                                : `$${formatPrice(item.price)}/sqft`}
                            </label>
                            <div
                              className="paint_arrowFloat"
                              style={{ paddingLeft: "7px" }}
                            >
                              <CheckBox
                                checked={selectedFloorObjects.includes(
                                  item.value
                                )}
                                readOnly={true}
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="">
                {selectedFloorObjects.includes(item.value) ? (
                  <div className="paintinput_lable mb-4 kitgrid border-bottom">
                    <label className="summaryDivheader1">
                      {item.inputLabel}
                    </label>
                    <div className="commandspadding"></div>
                    <input
                      className="floor_input_border "
                      type={"number"}
                      onChange={(e) => onChangeSqftValue(e, item)}
                      id={item.value}
                      placeholder={"Enter the number of SQFT"}
                    />
                    <div className="floor_placeholder_right ">
                      <label>{t("paintingInterior.sqft")}</label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        {/* totalSqftMismatch info box */}
        {totalSqftMismatch ? (
          <div className="paintinput_lable mb40 floorExclamtxt allCenter ">
            <table>
              <tr>
                <td>
                  <img
                    src={IMAGES.other.exclamation}
                    alt=""
                    width="35"
                    height="35"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label className="">{t("floor.total")}</label>
                </td>
              </tr>
            </table>
          </div>
        ) : null}
        {/* End totalSqftMismatch info box */}
        <Button
          disabled={totalSqftMismatch || totalSqft === 0}
          buttonLabel={t("paintingHomes.continue")}
          onClick={saveCurrentFloorDetails}
        />
      </div>
    </div>
  );
};
