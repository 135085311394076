export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_TENTATIVE_DETAILS = "UPDATE_TENTATIVE_DETAILS";
export const UPDATE_ASSISTANT = "UPDATE_ASSISTANT";
export const UPDATE_PORTFOLIO = "UPDATE_PORTFOLIO";
export const UPDATE_CONTRACTOR = "UPDATE_CONTRACTOR";

const updateContractorProject = (projectDetails) => {
  return {
    type: UPDATE_PROJECT,
    payload: projectDetails,
  };
};

export const updateContractor = (contractor) => {
  return {
    type: UPDATE_CONTRACTOR,
    payload: contractor,
  };
};

const updateTenatativeDetails = (tentativeDetails) => {
  return {
    type: UPDATE_TENTATIVE_DETAILS,
    payload: tentativeDetails,
  };
};

const updateAssistantDetails = (assistant) => {
  return {
    type: UPDATE_ASSISTANT,
    payload: assistant,
  };
};

const updateContractorPortfolio = (contractorPortfolio) => {
  return {
    type: UPDATE_PORTFOLIO,
    payload: contractorPortfolio,
  };
};

export {
  updateContractorProject,
  updateTenatativeDetails,
  updateAssistantDetails,
  updateContractorPortfolio,
};
