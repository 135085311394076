import React from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import { Footer } from "../../homeComponents/footerSection/Footer.comp";
import IMAGES from "../../../_assets/images/index";
import RadioButton from "../../../_components/radioButton/radioButton";

function TypeWood(props) {
  const btnClick = () => {
    props.history.push(`/floors/solidhardwood/type-of-installation`);
  };
  return (
    <div>
      <HeaderMenu />
      <div className="home-section">
        <span
          style={{ fontFamily: "Poppins", color: "#5B75FF", fontSize: "12px" }}
        >
          Floors • Solid hardwood{" "}
        </span>
        <div className="main_button">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={IMAGES.home.step4} width="75px" alt="" />
            <p style={{ fontSize: "15px" }}>
              Type of wood floor/
              <br />
              Size details
            </p>
          </div>
          <div style={{ borderLeft: "0.5px solid #D8D8D8", height: "90px" }}>
            <img
              style={{ marginTop: "10px", marginLeft: "10px" }}
              src={IMAGES.home.piggy}
              width="40px"
              alt=""
            />
            <p style={{ marginTop: "-11px", marginLeft: "10px" }}>$0000</p>
          </div>
        </div>
        <br />
        <br />
        <span
          style={{
            fontFamily: "Poppins",
            color: "black",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Select type of wood
        </span>
        <br />
        {items.map((item, index) => (
          <div key={index} className="radio_div">
            <div style={{ display: "flex" }}>
              {" "}
              <img
                style={{ width: "35px", marginLeft: "-11px" }}
                src={item.src}
                alt=""
              />
              <span style={{ marginTop: "20px", marginLeft: "10px" }}>
                {item.text}
              </span>{" "}
            </div>
            <div
              style={{ display: "flex", flexDirextion: "column", gap: "7px" }}
            >
              <img
                style={{ height: "12px", width: "12px", marginTop: "6px" }}
                src={IMAGES.other.green_plus}
                alt=""
              />
              <span>{item.price}</span>
              <RadioButton />
            </div>
          </div>
        ))}

        <br />
        <br />
        <span
          style={{
            fontFamily: "Poppins",
            color: "black",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Select size details
        </span>
        <br />
        {flooritems.map((item, index) => (
          <div key={index} className="radio_div">
            <span>{item.text}</span>
            <div
              style={{ display: "flex", flexDirextion: "column", gap: "7px" }}
            >
              <img
                style={{ height: "12px", width: "12px", marginTop: "6px" }}
                src={IMAGES.other.green_plus}
                alt=""
              />
              <span>{item.price}</span>
              <RadioButton />
            </div>
          </div>
        ))}
        <br />
        <br />
        <div
          className="form_btn"
          style={{
            marginBottom: "0px",
            marginTop: "0px",
            backgroundColor: "#D8D8D8",
            height: "12px",
            width: "100%",
            fontSize: "15px",
            borderRadius: "15px",
          }}
          onClick={() => btnClick()}
        >
          <span className="message_span" style={{ color: "#ADADAD" }}>
            Continue to next
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TypeWood;

const items = [
  {
    text: "White oak",
    src: IMAGES.home.lightwood,
    price: "$0000.-",
  },
  {
    text: "Red oak",
    src: IMAGES.home.solidoak,
    price: "$0000.-",
  },
  {
    text: "Brazilian oak",
    src: IMAGES.home.tiless,
    price: "$0000.-",
  },
  {
    text: "Bamboo",
    src: IMAGES.home.woodtext,
    price: "$0000.-",
  },
  {
    text: "Other",
    src: IMAGES.home.woodtxt,
    price: "$0000.-",
  },
];
const flooritems = [
  {
    text: "2” in 1/4",
    price: "$0000.-",
  },
  {
    text: "3” in 1/4",
    price: "$0000.-",
  },
  {
    text: "4” in 1/4",
    price: "$0000.-",
  },
  {
    text: "5” in 1/4",
    price: "$0000.-",
  },
];
