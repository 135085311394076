import React, { useState, useEffect } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/kitchen.css";
import "../../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import CheckBox from "../../../_components/CheckBox/checkBox";
import { Button } from "../../../_components/Button/Button";

import {
  checkoutStore,
  kitchenStore,
  paintingStore,
} from "../../../_redux/actions";
import { exteriorModel } from "../../../_redux/Models/painting.models";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { DropDown } from "../../../_components/dropDown/dropDown";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const ExteriorAddons = (props) => {
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : exteriorModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state
  const [addOns, setAddOns] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails && priceDetails.addOns) {
      let total = calculateTotalCost(state.costFor);
      const size = state.size;
      const haveOwnPaint = state.haveOwnPaint;
      const paintType = state.paintType;
      let totalServicePrice = total - paintType.price;
      const optionsArr = priceDetails.addOns.map((op) => {
        if (op.key === "windows") {
          return {
            text: t(`paintingExterior.${op.key}`),
            value: op.key,
            count: 1,
            isChecked: false,
            isSingle: true,
            price: haveOwnPaint
              ? (op.options.find((el) => el.key === "include_paint")
                  .percentage /
                  100) *
                totalServicePrice
              : (op.options.find((el) => el.key === "dont_include_paint")
                  .percentage /
                  100) *
                totalServicePrice,
          };
        } else if (op.key === "gutters") {
          return {
            text: t(`paintingExterior.${op.key}`),
            value: op.key,
            count: 1,
            isChecked: false,
            isSingle: true,
            price: (op.percentage / 100) * totalServicePrice,
          };
        } else {
          return {
            text: t(`paintingExterior.${op.key}`),
            value: op.key,
            count: 1,
            isChecked: false,
            isSingle: false,
            price: op.options.find((o) => o.key === "painting").price,
          };
        }
      });
      setAddOns(optionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state, t]);

  const onSave = () => {
    state.addOns = addOns.filter((i) => i.isChecked === true);
    state.costFor.addOns = price - calculateTotalCost(state.costFor);
    dispatch(paintingStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/painting/exterior/summary/${6}`
    );
  };

  function arrowHandler(params, e, index) {
    let updatedState = [...addOns];
    if (params === "plus") {
      updatedState[index].count = updatedState[index].count + 1;
    } else if (updatedState[index].count > 1) {
      updatedState[index].count = updatedState[index].count - 1;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  }

  const calculateTotal = (addOns) => {
    const total = addOns
      .filter((v) => v.isChecked === true)
      .reduce((p, c) => p + c.price * c.count, 0);
    setPrice(calculateTotalCost(state.costFor) + total);
  };

  const toggleCheck = (event, index) => {
    let updatedState = [...addOns];
    if (!updatedState[index].isChecked) {
      updatedState[index].isChecked = event;
    } else {
      updatedState[index].isChecked = !event;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  const onAddOnHandler = (event, index) => {
    let updatedState = [...addOns];
    updatedState[index].count = parseInt(event.target.value);
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("kitchen.addon")}</label>
        </div>
        <div className="paint_flex_with_left">
          <label className="paint_other_sub_header ">
            {t("paintingExterior.addServicesToPackage")}
          </label>
        </div>
        <div className="padding10"></div>
        {addOns &&
          addOns !== undefined &&
          addOns.map((item, index) => (
            <>
              <div
                className={`floor-selection-radio ${
                  item?.isChecked ? "selected" : ""
                }`}
                onClick={() => toggleCheck(true, index)}
                key={index}
              >
                <div
                  key={index}
                  className="width100 "
                  style={{ justifyContent: "space-between" }}
                >
                  <table>
                    <tr>
                      <td>
                        <label className="paintdiv_txt"> {item.text} </label>
                      </td>
                      <td>
                        <div
                          className="paint_arrowFloat flex"
                          style={{ paddingTop: "10px" }}
                        >
                          <img
                            src={IMAGES.other.green_plus}
                            className="kitchenplusimg"
                            alt=""
                          />
                          {item.key === "doors" ? (
                            <label
                              className="paintdiv_txt paddingleft8"
                              style={{ paddingLeft: "7px", paddingTop: "2px" }}
                            >
                              {`$${formatPrice(item.count * item.price)}/door`}
                            </label>
                          ) : (
                            <label
                              className="paintdiv_txt paddingleft8"
                              style={{ paddingLeft: "7px", paddingTop: "2px" }}
                            >
                              {`$${formatPrice(item.count * item.price)}`}
                            </label>
                          )}
                          <div
                            className="paint_arrowFloat"
                            style={{ paddingLeft: "7px" }}
                          >
                            <CheckBox checked={item.isChecked} readOnly />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="paintPadding8 ">
                {item.isChecked && !item.isSingle ? (
                  <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
                    <table className="paint_table100">
                      <tr>
                        <td>
                          {" "}
                          <div className="typeofTxt">
                            <DropDown
                              value={addOns[index].count}
                              callback={(event) => onAddOnHandler(event, index)}
                              length={50}
                            />
                            {/* <input
              type="number"
              onChange={(e) =>
                e.target.value > 0
                  ? onAddOnHandler(e, index)
                  : null
              }
              value={item.count}
              className=" paintupDownheight"
            /> */}

                            {/* {userState.number} */}
                          </div>
                          {/* <label className="paintdiv_txt">
      </label>  */}
                        </td>
                        {/* <td
              className="paint_arrowFloat otherspadding15"
              style={{ width: "15px" }}
            >
              <div
                className="paint_arrowHeight cursor_hand"
                onClick={(e) => arrowHandler("plus", e, index)}
              >
                <img src={IMAGES.other.vectorUP} alt="" />
              </div>
              <div
                className="paint_arrowHeight cursor_hand"
                onClick={(e) => arrowHandler("minus", e, index)}
              >
                <img
                  className="paint_arrowpadd_bottom"
                  src={IMAGES.other.vectorDown}
                  alt=""
                />
              </div>
            </td> */}
                      </tr>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ))}

        <Button buttonLabel={t("paintingHomes.continue")} onClick={onSave} />
      </div>
    </div>
  );
};
