import React, { useState } from "react";
import SliderContent from "./SliderContent";
import Dots from "../../../_components/Slider/Dots";
import Arrows from "../../../_components/Slider/Arrows";
import "../../../_styles/localStyles/kitchen.css";

const len =  1;

function ContractorSlider(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div key={Math.random()} className="checkout-slider-container tranformplus slide_arrow">
      <SliderContent
        activeIndex={activeIndex}
      />
      <Arrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }
      />

      <Dots
        activeIndex={activeIndex}
        sliderImage={[]}
        onclick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
}

export default ContractorSlider;
