import React, { useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import { Footer } from "../../homeComponents/footerSection/Footer.comp";
import IMAGES from "../../../_assets/images/index";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { Button } from "../../../_components/Button/Button";
function FloorSize(props) {
  const [formState, setFormState] = useState({
    sqtFt: "",
  });
  const btnClick = () => {
    props.history.push(`/floors/solidhardwood/current-floor`);
  };

  function onChangeHandler(e) {
    setFormState({ ...formState, sqtFt: e.target.value });
  }

  return (
    <div>
      <HeaderMenu />
      <div className="home-section">
        <span
          style={{ fontFamily: "Poppins", color: "#5B75FF", fontSize: "12px" }}
        >
          Floors • Solid hardwood{" "}
        </span>
        <div className="main_button">
          <div style={{ display: "flex", alignItems: "center" }}>
            <ProgressBar progressValue={2} />
            <p>Floor size</p>
            <br />
          </div>
          <div style={{ borderLeft: "0.5px solid #D8D8D8", height: "90px" }}>
            <img
              style={{ marginTop: "10px", marginLeft: "10px" }}
              src={IMAGES.home.piggy}
              width="40px"
              alt=""
            />
            <p style={{ marginTop: "-11px", marginLeft: "10px" }}>$0000</p>
          </div>
        </div>
        <br />
        <br />
        <span
          style={{
            fontFamily: "Poppins",
            color: "black",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Introduce your floor size
        </span>
        <br />
        <span
          style={{ fontFamily: "Poppins", color: "black", fontSize: "16px" }}
        >
          Please introduce the total number of SQFT you need to install in your
          floor
        </span>
        <br />
        <br />

        <span
          style={{ fontFamily: "Poppins", color: "black", fontSize: "12px" }}
        >
          Sizing
        </span>
        <input
          type="text"
          placeholder="Enter the number of SQFT's      SQFT"
          className="form_text_left"
          value={formState.sqtFt}
          onChange={(e) => onChangeHandler(e)}
        ></input>
        <br />
        <br />
        <Button
          disabled={formState.sqtFt !== "" ? false : true}
          buttonLabel={" Continue to next step"}
          onClick={() => btnClick()}
        />
      </div>
      <Footer />
    </div>
  );
}

export default FloorSize;
