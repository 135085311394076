import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { bathroomStore, checkoutStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { bathroomFlowModel } from "../../_redux/Models/bathroom.model";
import {
  calculateBathroomCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const BathroomNeedBuildupWalls = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;
  const bathroomId =
    props.match && props.match.params ? props.match.params.bathroomId : false;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.bathroomPayload
      ? state.buttonReducer.bathroomPayload
      : bathroomFlowModel
  );

  const bathroom = state.bathroomsDetails.find(
    (baths) => baths.id === bathroomId
  );

  if (!bathroom) {
    history.push("/bathroom");
    window.location.reload();
  }

  const [price, setPrice] = useState(
    calculateBathroomCost(state.bathroomsDetails)
  ); // To keep the total price in state

  const [options, setOptions] = useState([]);
  const [option, setOption] = useState("");

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const size = bathroom.size;
    const materialPrice = priceDetails.buildUpWalls.find(
      (m) => m.key === size
    ).price;
    if (mounted === true && priceDetails) {
      const optionArr = [
        {
          text: t("Bathroom.noBuildUpWallNeeded"),
          key: "no",
          value: "No",
          price: 0,
        },
        {
          text: t("Bathroom.buildUpWallNeeded"),
          key: "yes",
          value: "Yes",
          price: materialPrice,
        },
      ];
      setOptions(optionArr);
    }
    return () => (mounted = false);
  }, [bathroom.size, checkoutModelState, t]);

  const onSave = (value) => {
    bathroom.costFor.buildUpWall =
      price - calculateBathroomCost(state.bathroomsDetails);
    bathroom.requireBuildUpWalls = value;
    state.bathroomsDetails.filter((bt) => bt.id !== bathroom.id).push(bathroom);
    dispatch(bathroomStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/bathroom/${bathroomId}/need-shower-door/6`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("Bathroom.buildwall")}</label>
        </div>
        <div className="paint_flex_with_left ">
          <label className="paint_other_sub_header ">
            {t("Bathroom.letus")}
          </label>
        </div>
        <div className="padding10"></div>
        {options.map((item, index) => (
          <div
            className={`floor-selection-radio ${
              option === item?.value ? "selected" : ""
            }`}
            onClick={() => {
              const total = calculateBathroomCost(state.bathroomsDetails);
              setOption(item.value);
              setPrice(total + item.price);
              onSave(item?.value);
            }}
          >
            <div
              key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label
                className="paintdiv_txt"
                style={{
                  width: "65%",
                }}
              >
                {item.text}
              </label>
              <div
                className="paint_arrowFloat"
                style={{
                  width: "35%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img src={IMAGES.other.green_plus} alt="" />
                  <label className="paintdiv_txt">
                    {`$${formatPrice(item.price)}`}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
