import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { useDispatch, useSelector } from "react-redux";
import { windowsModel } from "../../_redux/Models/window.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { checkoutStore, windowStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import { DropDown } from "../../_components/dropDown/dropDown";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const NeedOfWindows = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue = 2;

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.windowsPayload
      ? state.buttonReducer.windowsPayload
      : windowsModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [option, setOption] = useState("");
  const [numberOfWindows, setNumberOfWindows] = useState(1);
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;
    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          console.log(response.data);
          checkoutModelState.priceDetails = response.data.details;
          dispatch(checkoutStore(checkoutModelState));
          const pricingOptions =
            response.data.details.typeOfService[0].options.map((op) => {
              return {
                text: t(`windows.${op.key}`),
                value: op.key,
                price: numberOfWindows * op.price,
              };
            });

          setOptions(pricingOptions);
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, numberOfWindows, t]);

  const onSave = () => {
    state.reasonForWindow = option;
    state.numberOfWindowsNeeded = numberOfWindows;
    state.costFor.windows = price - calculateTotalCost(state.costFor);
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(windowStore(state));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/windows/have-own-windows`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left mb-3">
            <label className="paint_header">
              {"How many windows do you need to install/replace?"}
            </label>
          </div>
          <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
            <table className="paint_table100">
              <tr>
                <td>
                  <div
                    className="typeofTxt"
                    style={{ display: "grid", paddingTop: "0" }}
                  >
                    <DropDown
                      value={numberOfWindows}
                      callback={(event) =>
                        setNumberOfWindows(parseInt(event.target.value))
                      }
                      length={100}
                    />
                    {/* {userState.number} */}
                  </div>
                  {/* <label className="paintdiv_txt">
                    </label>  */}
                </td>
              </tr>
            </table>
          </div>
          <div className="paint_flex_with_left paddinngtop20">
            <label className="paint_header">
              {t("windows.whyYouNeedWindows")}
            </label>
          </div>
          <div className="padding10"></div>
          <div class="paint_flex_with_left ">
            <label class="paint_other_sub_header ">
              {t("windows.whyYouNeedWindowsTxt")}
            </label>
          </div>
          <div className="padding10"></div>
          {options.map((item, index) => (
            <div
              className={`floor-selection-radio ${
                option === item?.text ? "selected" : ""
              }`}
              onClick={() => {
                const cost = parseFloat(calculateTotalCost(state.costFor));
                setOption(item.text);
                setPrice(cost + item.price);
              }}
            >
              <div
                key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="paintdiv_txt"
                  style={{
                    width: "250px",
                  }}
                >
                  {item.text}
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="padding10"></div>
          <Button
            disabled={option === ""}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSave}
          />
        </div>
      </div>
    </div>
  );
};
