import React from "react";
import { Footer } from "../../../_components/footer/Footer";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import Modal from "@mui/material/Modal";

import IMAGES from "../../../_assets/images/index";
import { withTranslation } from "react-i18next";

import { checkoutStore } from "../../../_redux/actions";
import { connect } from "react-redux";
import { Button } from "../../../_components/Button/Button";
import moment from "moment";
import { getContractor } from "../../../services/api/contractor.service";
import { createChannel } from "../../../services/api/chat.service";
import Loader from "../../../_components/spinner/Loader";
import {
  formatOwnerFullAddress,
  formatPrice,
  parseJwt,
} from "../../../services/utils/utilFunctions";
import { getOwnerProjectById } from "../../../services/api/owner.service";
import { getData } from "../../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../../_configs/constants.config";
import { uploadContractorPictureOfWork } from "../../../services/api/owner.service";
import { getprojectheader } from "../../Contractor/MyProjects/contractorUtils";
import "./ownerProjects.css";
import UserCancelProjectButton from "./UserCancelProjectButton";

class ViewMyProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      project: null,
      uploadedDocs: [],
      index: -1,
    };
  }

  componentDidMount = () => {
    this.fetchActiveProjects();
  };

  updateDocuments = (el) => {
    this.uploadPlaceOfWork(el.target.files[0], this.setImages);
  };

  setImages = (data) => {
    this.setState({ uploadedDocs: data.data.pictures });
  };

  uploadPlaceOfWork = (file, callback) => {
    const projectId =
      this.props.match && this.props.match.params
        ? this.props.match.params.id
        : null;
    const user = parseJwt(localStorage.getItem("auth_token"));

    const postData = new FormData();
    if (file) {
      this.setState({ isLoading: true });
      postData.append("place_of_work", file);
      const variant = "error";
      uploadContractorPictureOfWork(user._id, projectId, postData)
        .then((data) => {
          if (data.status !== 200) {
            this.props.enqueueSnackbar("Upload failed please try again", {
              variant,
            });
          } else if (callback) {
            callback(data);
          }
        })
        .catch((e) => {
          this.props.enqueueSnackbar(e.toString(), { variant });
        })
        .finally((_) => {
          this.setState({ isLoading: false });
        });
    }
  };

  fetchActiveProjects = () => {
    this.setState({ isLoading: true, isError: false }, () => {
      const projectId =
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : null;
      const user = parseJwt(localStorage.getItem("auth_token"));

      if (user && user._id && projectId) {
        getOwnerProjectById(user._id, projectId)
          .then((data) => {
            if (data.status === 200 && data.data && data.data) {
              this.setState(
                {
                  project: data.data,
                  uploadedDocs: data.data.pictures,
                  isLoading: false,
                  isError: false,
                },
                () => console.log(this.state)
              );
            }
          })
          .catch((e) => alert(e.toString()))
          .finally((_) => {
            this.setState({
              isLoading: false,
            });
          });
      } else {
        this.setState(
          {
            project: null,
            isLoading: false,
            isError: true,
          },
          () => console.log(this.state)
        );
      }
    });
  };

  /**
   * Gets the contractor from api
   */
  getContractorDetails = async (id) => {
    return getContractor(id);
  };

  startChat = () => {
    const user = getData(LOCAL_STORAGE.user);
    const data = {
      contractorID: this.state.project.contractorId,
      ownerID: this.state.project.ownerId,
    };

    if (data["ownerID"] === user._id) data["userType"] = "owner";
    if (data["contractorID"] === user._id) data["userType"] = "contractor";

    createChannel(data).then((response) => {
      this.props.history.push({
        pathname: "/user/chat",
        search: `?sid=${response.data}`,
      });
    });
  };

  onChatClick = async () => {
    this.setState({ isLoading: true });
    this.startChat();
  };
  getStatusText = (status) => {
    let statusText = "pending";
    if (status === "pending") statusText = "Pending acceptance";
    else if (status === "checked_in") statusText = "Checked in";
    else if (status === "confirm") statusText = "Confirmed";
    else if (status === "rejected") statusText = "Rejected";
    else if (status === "cancelled") statusText = "Cancelled";
    else if (status === "completed") statusText = "Completed project";
    return statusText;
  };

  handleIndex = (index) => {
    this.setState({ index });
  };

  render = () => {
    const { t } = this.props;
    const { project } = this.state;
    console.log({ project });
    return (
      <div className="insideWrp">
        <Loader progress={this.state.isLoading} />
        <HeaderMenu />
        <div style={{ padding: "0px 20px 20px 20px" }}>
          {project !== null ? (
            <div>
              <div className="paint_flex_with_left ">
                {getprojectheader(
                  project.serviceDetails.data.flowName,
                  project.serviceDetails.data.serviceType
                ) && (
                  <p
                    className="approvalTxt project-view-header"
                    style={{
                      color: "#5B75FF",
                    }}
                  >
                    {getprojectheader(
                      project.serviceDetails.data.flowName,
                      project.serviceDetails.data.serviceType
                    )}
                  </p>
                )}
                {/* <label
                  className="projectdetailsHeader"
                  style={{ textTransform: "capitalize" }}
                >
                  {`${project.serviceDetails.data.flowName.replace(
                    "-flow",
                    ""
                  )} ${
                    project.serviceDetails.data.serviceType
                      ? project.serviceDetails.data.serviceType.replace(
                          "_",
                          " "
                        )
                      : ""
                  }`}
                </label> */}
              </div>
              <div className="paint_flex_with_left paddinngtop10">
                <label
                  className={`approvalTxt ${project.status}`}
                  style={{ color: "#D26C0D" }}
                >
                  {this.getStatusText(project.status)}
                </label>
              </div>
              {/* <div>
                <label style={{ color: "#ADADAD", fontSize: "15px" }}>
                  Total price{"    "}
                </label>
                <label>
                  ${project.price ? formatPrice(project.price) : ""}
                </label>
              </div> */}
              <div className="otherspadding25"></div>
              <div className="">
                <div
                  style={{
                    border: "0.5px solid #d8d8d8",
                    borderRadius: "8px",
                    placeItems: "center",
                  }}
                >
                  <table className="paint_table100">
                    <tr className="checkoutCalsvg paintdiv_radiobtn_paddingright ">
                      <td>
                        <img src={IMAGES.other.calendarPay} alt="" />
                      </td>
                      <td className="grid">
                        <div style={{ textAlign: "center" }}>
                          <label className="divtxtNormal">
                            {t("Tentative start date")}
                          </label>
                          <label className="dateTxt project_date">
                            {moment(
                              new Date(project.tentativeStartDate)
                            ).format("dddd, MMMM DD YYYY")}
                          </label>
                        </div>
                        {project.tentativeEndDate ? (
                          <div className="client-custom-date">
                            <label className="divtxtNormal">
                              {t("Tentative end date")}
                            </label>
                            <label className="dateTxt">
                              {moment(
                                new Date(project.tentativeEndDate)
                              ).format("dddd, MMMM DD YYYY")}
                            </label>
                          </div>
                        ) : null}
                        {project.tentativeTimeRange ? (
                          <div style={{ textAlign: "center" }}>
                            <label className="divtxtNormal">
                              {t("Tentative time range")}
                            </label>
                            <label className="dateTxt project_date">
                              {project.tentativeTimeRange}
                            </label>
                          </div>
                        ) : null}
                      </td>
                      <td></td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="otherspadding25"></div>
              {project.ownerAddress && (
                <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                  <div>
                    <img
                      style={{ width: "60px" }}
                      src={IMAGES.other.miniHouse}
                      alt=""
                    />
                  </div>
                  <div className="grid">
                    <label className="divtxtNormal">
                      {project.ownerAddress}
                    </label>
                  </div>
                  <div className="paint_arrowFloat">
                    {project.serviceDetails.data.selectedAddressId &&
                    !["completed", "rejected"].includes(project.status) ? (
                      <div
                        onClick={() => {
                          this.props.history.push(
                            `/checkout/edit-address/${project.serviceDetails.data.selectedAddressId}`
                          );
                        }}
                        className="editviewBlue"
                      >
                        Edit
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="otherspadding25"></div>

              <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                <div>
                  <img src={IMAGES.other.paperThreed} alt="" />
                </div>
                <div className="grid">
                  <label className="divtxtNormal">{t("Project details")}</label>
                </div>
                <div
                  onClick={() =>
                    this.props.history.push(
                      `/user/view-project-details/${project._id}`
                    )
                  }
                  className="paint_arrowFloat"
                >
                  <div className="editviewBlue">View</div>
                </div>
              </div>
              <div className="otherspadding25"></div>
              <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                <div>
                  <img src={IMAGES.other.smallContractor} alt="" />
                </div>
                <div className="grid">
                  <label className="divtxtNormal">
                    {t("Contractor profile")}
                  </label>
                </div>
                {project.contractorId ? (
                  <div
                    onClick={() => {
                      sessionStorage.setItem("whilePlacingOrder", false);
                      this.props.history.push(
                        `/contractor-portfolio/${project.contractorId}`
                      );
                    }}
                    className="paint_arrowFloat"
                  >
                    <div className="editviewBlue">View</div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              {!project.contractorId ? (
                <span style={{ fontSize: "13px" }}>
                  We will inform you as soon as a contractor has confirmed your
                  project. Any question{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "#5b75ff",
                      cursor: "pointer",
                    }}
                    onClick={() => (window.location = "/contact-us")}
                  >
                    {t("paintingInterior.contactus")}
                  </span>
                </span>
              ) : null}

              <div className="profilePadding30"></div>
            </div>
          ) : null}
          {project && !["completed", "rejected"].includes(project.status) ? (
            <div>
              <div className="grid">
                <label className="menuheader txt ">
                  {t("Pictures of your home")}
                </label>
                <div className="otherspadding25"></div>
                <label className="menuheaderSubtxt">
                  {t(
                    "Upload pictures of the area the contractor will perform the work"
                  )}
                </label>
              </div>
              <div className="otherspadding25"></div>
              <div className="allCenter">
                <div className="myprojectdivBorder ">
                  {this.state.uploadedDocs && this.state.uploadedDocs.length ? (
                    <div className="padding_top8">
                      <div className=" ">
                        <table style={{ display: "flex", overflow: "hidden" }}>
                          <tbody>
                            <tr style={{ overflow: "scroll" }}>
                              {this.state.uploadedDocs.map((el, i) => {
                                return (
                                  <td
                                    className="fullPadding06"
                                    onClick={() => this.handleIndex(i)}
                                  >
                                    <img
                                      className="profileDivmargin uploadwidthHeight"
                                      src={el}
                                      alt=""
                                    />
                                  </td>
                                );
                              })}{" "}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <label>
                    <div className="profileDocfont allCenterGrid">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="38"
                        height="34"
                        viewBox="0 0 38 34"
                        fill="none"
                      >
                        <path
                          d="M19 17.9767L26.0717 25.0467L23.7133 27.405L20.6667 24.3583V33.6667H17.3333V24.355L14.2867 27.405L11.9283 25.0467L19 17.9767ZM19 0.333328C21.8616 0.333465 24.6235 1.38518 26.7603 3.28853C28.8972 5.19187 30.2602 7.8141 30.59 10.6567C32.6638 11.2222 34.4729 12.4985 35.7011 14.2625C36.9293 16.0266 37.4986 18.1661 37.3094 20.3073C37.1202 22.4485 36.1845 24.4551 34.6659 25.9764C33.1474 27.4977 31.1425 28.4369 29.0017 28.63V25.2733C29.7686 25.1638 30.5061 24.9026 31.171 24.5049C31.8359 24.1073 32.4149 23.5812 32.8743 22.9573C33.3336 22.3335 33.6641 21.6244 33.8465 20.8714C34.0288 20.1184 34.0593 19.3367 33.9362 18.5718C33.8131 17.8069 33.5389 17.0742 33.1295 16.4164C32.7202 15.7587 32.1839 15.189 31.552 14.7408C30.9201 14.2925 30.2053 13.9746 29.4492 13.8057C28.6931 13.6367 27.9109 13.62 27.1483 13.7567C27.4094 12.5415 27.3952 11.2833 27.107 10.0743C26.8188 8.86522 26.2638 7.73597 25.4827 6.76921C24.7015 5.80245 23.714 5.02267 22.5925 4.48699C21.4709 3.9513 20.2437 3.67327 19.0008 3.67327C17.7579 3.67327 16.5308 3.9513 15.4092 4.48699C14.2877 5.02267 13.3002 5.80245 12.519 6.76921C11.7379 7.73597 11.1829 8.86522 10.8946 10.0743C10.6064 11.2833 10.5923 12.5415 10.8533 13.7567C9.33277 13.4711 7.76104 13.8013 6.48391 14.6746C5.20679 15.5479 4.32889 16.8928 4.04334 18.4133C3.75779 19.9339 4.08798 21.5056 4.96128 22.7828C5.83457 24.0599 7.17943 24.9378 8.70001 25.2233L9.00001 25.2733V28.63C6.85909 28.4372 4.85402 27.4982 3.33523 25.977C1.81644 24.4558 0.880552 22.4493 0.69113 20.308C0.501709 18.1668 1.07081 16.0271 2.29895 14.263C3.5271 12.4988 5.33618 11.2223 7.41001 10.6567C7.73952 7.81396 9.10235 5.19151 11.2393 3.2881C13.3763 1.38469 16.1383 0.3331 19 0.333328Z"
                          fill="#2CCAE8"
                        />
                      </svg>
                      <div className="profilePadding5"></div>
                      <label>{t("profileCreation.photo1")}</label>
                      <input
                        type="file"
                        name="file"
                        id="filefirst"
                        className="inputfile"
                        onChange={(e) => this.updateDocuments(e)}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          ) : null}

          {project && ["completed"].includes(project.status) ? (
            <Button
              disabled={false}
              buttonLabel={t("Rate Contractor")}
              onClick={() =>
                this.props.history.push(
                  `/contractor/rate-project/${project.contractorId}/${project._id}`
                )
              }
              className={"btn-white"}
            />
          ) : null}

          <div className="otherspadding25"></div>
          <div
            className="contactblue_btn "
            style={{ background: "#0ABDE1" }}
            onClick={this.onChatClick}
          >
            <div>
              <img src={IMAGES.other.group1} alt="" />
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <label className="condactNeedhelp">
                      {"Chat with contractor"}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="otherspadding25"></div>
          <div className="otherspadding25"></div>
          <div className="paintPolygon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
            >
              <path
                d="M13 6.76795C14.3333 7.53775 14.3333 9.46225 13 10.2321L3.25 15.8612C1.91667 16.631 0.25 15.6688 0.25 14.1292L0.25 2.87084C0.25 1.33123 1.91667 0.368984 3.25 1.13878L13 6.76795Z"
                fill="#0ABDE1"
              />
            </svg>
          </div>
          <div className="otherspadding25"></div>

          <div className="padding_top8"></div>
          <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
            <div className="grid">
              <label className="divtxtNormal">{t("Terms & conditions")}</label>
            </div>
            <div className="paint_arrowFloat">
              <div
                className="editviewBlue"
                onClick={() => this.props.history.push("/terms-and-condition")}
              >
                View
              </div>
            </div>
          </div>
          <div className="otherspadding25"></div>

          <Button
            disabled={false}
            buttonLabel={t("Back to My projects")}
            onClick={() => this.props.history.push("/user/my-projects")}
            className={"btn-white"}
          />
          <div className="otherspadding25"></div>
          {project &&
            !["cancelled", "completed", "rejected"].includes(
              project.status
            ) && <UserCancelProjectButton projectId={project._id} />}

          <div className="padding100"></div>
          <div className="contactblue_btn ">
            <div>
              <img src={IMAGES.other.group1} alt="" />
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <label className="condactNeedhelp">
                      {t("paintingInterior.doneedhelp")}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td onClick={() => (window.location = "/contact-us")}>
                    <label className="contactuslink">
                      {t("paintingInterior.contactus")}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="paintPolygon">
            <img src={IMAGES.other.polygon} alt="" />
          </div>
        </div>

        <Footer />
        <Modal
          open={this.state.index > -1 ? true : false}
          onClose={() => {
            this.handleIndex(-1);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          className={"modal-image"}
        >
          <div
            className="modalImageWrapper"
            onClick={() => this.handleIndex(-1)}
          >
            <img
              src={this.state.uploadedDocs[this.state.index]}
              alt="place of work"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </Modal>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(ViewMyProject));
