import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { cancelProjectForUser } from "../../../services/api/owner.service";
import { parseJwt } from "../../../services/utils/utilFunctions";
import { Button } from "../../../_components/Button/Button";
import { checkoutStore } from "../../../_redux/actions";
import { CannotContinueModal } from "../../floorComponents/Common/CannotContinue.modal";

class UserCancelProjectButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,

        }

    }

    /**
     * Cancels the user project
     */
    cancelProject = () => {
        this.setState({ isLoading: true, isError: false }, () => {
            const projectId = this.props.projectId;
            const user = parseJwt(localStorage.getItem("auth_token"));
            if (user && user._id && projectId) {
                cancelProjectForUser(user._id, projectId)
                    .then((data) => {
                        console.log(data)
                        if (data.status === 200) {

                        }
                    })
                    .catch((e) => alert(e.toString()))

            } else {

            }
        });
    };


    render = () => {
        return <div>
            <Button
                disabled={false}
                onClick={() => this.setState({ isOpen: true })}
                buttonLabel={"Cancel project"}
                className="btn-checkouttick btn-cancel-project"
            />
            {CannotContinueModal({
                isOpen: this.state.isOpen,
                hideContractorIcon: false,
                reason: (
                    <div>
                        <h3>Are you sure you want to cancel?</h3>
                        <span>A refund might not be possible, before confirming check the <a href="/terms-and-condition" target="_blank">terms and conditions</a> </span>
                    </div>
                ),
                onCloseAction: () => this.setState({ isOpen: false }),
                onNoClick: () => this.setState({ isOpen: false }),
                showYesButton: true,
                onYesClick: () => this.setState({ isOpen: false }, this.cancelProject),
                closeText: "No",
                okayText: "Yes"
            })}
        </div>
    }

}


const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

const mapDispatchToProps = () => ({
    checkoutStore,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps()
)(withTranslation()(UserCancelProjectButton));