import React, { useRef, useState } from "react";

function Step1SolidHardwood(props) {
  const btnEl = useRef();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [materialsReq, setMaterialsReq] = useState("");

  const onChangeRadio = (e) => {
    setBtnDisabled(false);
    setMaterialsReq(e.target.value)
    btnEl.current.scrollIntoView({ behavior: "smooth" });
  };
  const btnClick = () => {
    props.onSuccess({ materialsReq: materialsReq });
  };
  return (
    <div className="m-t-32" ref={btnEl}>
      <div className="floor-sub-title">
        Do you have the solid hardwood floor you want to install?
      </div>
      <div className="m-t-32">
        Let us know if you need the contractor to provide the solid hardwood for
        you
      </div>
      <label className="floor-selection-radio m-t-32">
        Please provide materials
        <input type="radio" name="selected" value="YES" onChange={onChangeRadio} />
      </label>
      <label className="floor-selection-radio">
        I have my own materials
        <input type="radio" name="selected" value="NO" onChange={onChangeRadio} />
      </label>
      <button
        className="m-t-32 floor-button"
        disabled={btnDisabled}
        onClick={btnClick}
      >
        Continue to next
      </button>
    </div>
  );
}

export default Step1SolidHardwood;
