import React, { useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import { Footer } from "../../homeComponents/footerSection/Footer.comp";
import IMAGES from "../../../_assets/images/index";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { Button } from "../../../_components/Button/Button";
import { useTranslation } from "react-i18next";
function TypeService(props) {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState({
    selectedService: "",
  });
  const btnClick = () => {
    props.history.push(`/floors/solidhardwood/floor-size`);
  };

  return (
    <div className="insideWrp">
      <HeaderMenu />
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <label className="header_path_name_show">
          {t("paintingHomes.header_path")}
        </label>

        <div className="paint_main_buttons">
          <div className="paint_main_button_left">
            <ProgressBar progressValue={1} />
            {/* <img src={IMAGES.other.stepper1} alt="" /> */}
            <div>
              <label className="paint_every_flow_title">
                {" "}
                {t("paintingInterior.Typeofwork")}
              </label>
            </div>
          </div>
          <div className="paint_main_button_right">
            <table>
              <tr>
                <td>
                  <img src={IMAGES.other.piggy} alt="" />
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <label className="paint_every_price_txt">
                    {t("typeOfService.price")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <span
          style={{
            fontFamily: "Poppins",
            color: "black",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Select a type of service
        </span>
        <br />

        <div
          className="radio_div"
          onClick={() =>
            setSelectedOption({
              ...selectedOption,
              selectedService: "Installation",
            })
          }
        >
          <span>Installation</span>

          <RadioButton
            value={"Installation"}
            name={"Installation"}
            checked={
              selectedOption.selectedService === "Installation" ? true : false
            }
          />
        </div>
        <div
          className="radio_div"
          onClick={() =>
            setSelectedOption({
              ...selectedOption,
              selectedService: "Refinishing",
            })
          }
        >
          <span>Refinishing</span>
          <RadioButton
            value={"Refinishing"}
            name={"Refinishing"}
            checked={
              selectedOption.selectedService === "Refinishing" ? true : false
            }
          />
        </div>
        <div
          className="radio_div"
          onClick={() =>
            setSelectedOption({ ...selectedOption, selectedService: "Repair" })
          }
        >
          <span>Repair</span>
          <RadioButton
            value={"Repair"}
            name={"Repair"}
            checked={selectedOption.selectedService === "Repair" ? true : false}
          />
        </div>
        <br />
        <br />
        <Button
          disabled={selectedOption.selectedService !== "" ? false : true}
          buttonLabel={" Continue to next step"}
          onClick={() => btnClick()}
        />
      </div>
      <Footer />
    </div>
  );
}

export default TypeService;
