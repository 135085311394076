import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/kitchen.css";
import "../../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { Button } from "../../../_components/Button/Button";
import { paintingStore } from "../../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateCostFromArr,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { paintingInteriorModel } from "../../../_redux/Models/painting.models";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const InteriorRoomSize = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [roomSize, setRoomSize] = useState("");
  const [roomHeight, setRoomHeight] = useState("");

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 3;
  const roomId =
    props.match && props.match.params ? props.match.params.roomId : false;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : paintingInteriorModel
  );

  const room = state.roomDetails.find((rooms) => rooms.id === roomId);

  if (!room) {
    history.push(`/contractor-portfolio/${selectedContractorId}/painting`);
    window.location.reload();
  }

  const price = calculateCostFromArr(state.roomDetails); // To keep the total price in state

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails) {
    }
    return () => (mounted = false);
  }, [checkoutModelState, t]);

  const onSave = () => {
    room.roomSize = roomSize;
    room.roomHeight = roomHeight;
    state.roomDetails.filter((bt) => bt.id !== room.id).push(room);
    dispatch(paintingStore(state));
    const nextStepValue = parseInt(stepValue) + 1;
    history.push(
      `/contractor-portfolio/${selectedContractorId}/painting/interior/${roomId}/paint-details/${nextStepValue}`
    );
  };

  const sizeArray = [
    {
      name: "small",
      text1: "Small room",
      text2: "Up to 7ft to 7ft",
    },
    {
      name: "medium",
      text1: "Medium room",
      text2: "Up to 12ft x 12ft",
    },
    {
      name: "large",
      text1: "Large room",
      text2: "Up to 16ft x 16ft",
    },
    {
      name: "larger",
      text1: "Larger",
      text2: "More than 16ft x 16ft",
    },
  ];

  const heightArray = [
    {
      name: "8ft",
      text1: "Standard",
      text2: "Up to 8ft",
    },
    {
      name: "10ft",
      text1: "High",
      text2: "Up to 10ft",
    },
    {
      name: "20ft",
      text1: "Very high",
      text2: "Up to 20ft",
    },
  ];

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left w-100 justify-content-between">
          <label className="paint_header">
            {t("paintingInterior.roomSize")}
          </label>
          <label className="paint_header2 d-flex flex-row">
            {t("paintingInterior.roomHead")}
            {` #${room.number}`}
          </label>
        </div>
        <div key={"index.toString()"} className="mb-4">
          <div className="mt-3">
            <label className="paintroomsize_tableheader mb-3">
              {t("paintingInterior.size")}
            </label>
            {sizeArray?.map((item) => (
              <div
                className={`floor-selection-radio flex-column align-items-start justify-content-center ${
                  roomSize === item?.name ? "selected" : ""
                }`}
                onClick={() => setRoomSize(item?.name)}
              >
                <strong>{item?.text1}</strong>
                <small style={{ color: "#858585", fontSize: "13px" }}>
                  {item?.text2}
                </small>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <label className="paintroomsize_tableheader mb-3">
              {t("paintingInterior.height")}
            </label>
            {heightArray?.map((item) => (
              <div
                className={`floor-selection-radio flex-column align-items-start justify-content-center ${
                  roomHeight === item?.name ? "selected" : ""
                }`}
                onClick={() => setRoomHeight(item?.name)}
              >
                <strong>{item?.text1}</strong>
                <small style={{ color: "#858585", fontSize: "13px" }}>
                  {item?.text2}
                </small>
              </div>
            ))}
          </div>
        </div>
        <Button
          disabled={!roomSize || !roomHeight}
          buttonLabel={t("paintingHomes.continue")}
          onClick={onSave}
        />
      </div>
    </div>
  );
};
