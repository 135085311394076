import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorCarpetModel } from "../../../_redux/Models/Floor.model";
import { removalOfFloorPopup } from "../Common/RemovalOfFloor.popup";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const CarpetQuality = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  // State variables initialization
  const [carpetQuality, setCarpetQuality] = useState({
    text: "",
    value: "",
    servicePrice: "",
    materialPrice: "",
    price: 0,
  });
  const [showRemovalFloorPopup, setShowRemovalFloorPopup] = useState(false);

  const floorCarpetModelState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorCarpetModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(
    calculateTotalCost(floorCarpetModelState.costFor)
  ); // To keep the total price in state
  const [carpetPricing, setCarpetPricing] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const size = parseFloat(floorCarpetModelState.floorSize);
    const needCarpet = floorCarpetModelState.needCarpet;
    if (mounted === true && priceDetails && priceDetails.materialQuality) {
      const optionsArr = priceDetails.materialQuality.map((op) => {
        return {
          text: t(`floor.${op.key}`),
          value: op.key,
          servicePrice: op.servicePrice * size,
          materialPrice: op.materialPrice * size,
          price: needCarpet
            ? (op.materialPrice + op.servicePrice) * size
            : op.servicePrice * size,
        };
      });
      setCarpetPricing(optionsArr);
    }
    return () => (mounted = false);
  }, [
    checkoutModelState,
    floorCarpetModelState.floorSize,
    floorCarpetModelState.needCarpet,
    t,
  ]);

  /**
   * Function to save CarpetQuality
   * @returns {void}
   */
  const onCarpetQualitySave = () => {
    floorCarpetModelState.carpetQuality = carpetQuality;
    floorCarpetModelState.costFor.carpetGrade = carpetQuality.materialPrice;
    floorCarpetModelState.costFor.carpetService = carpetQuality.servicePrice;
    dispatch(floorStore(floorCarpetModelState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    if (floorCarpetModelState.needPaddingReplacement) {
      props.history.push(
        `/contractor-portfolio/${selectedContractorId}/floors/carpet/padding-quality/4`
      );
    } else {
      // to show popup
      setShowRemovalFloorPopup(true);
    }
  };

  /**
   * On prior floor removal click yes
   * @returns {void}
   */
  const onPriorFloorRemovalYesClick = () => {
    floorCarpetModel.priorFloorRemovalRequired = true;
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/carpet/current-floor/5`
    );
  };

  /**
   * On prior floor removal click no
   * @returns {void}
   */
  const onPriorFloorRemovalNoClick = () => {
    setShowRemovalFloorPopup(false);
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/carpet/summary/6`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">{t("floor.carpetqua")}</label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("floor.grade")}
            </label>
          </div>
          {carpetPricing.map((item, index) => (
            <div
              key={Math.random()}
              className={`floor-selection-radio ${
                carpetQuality?.value === item?.value ? "selected" : ""
              }`}
              onClick={() => {
                const cost = parseFloat(
                  calculateTotalCost(floorCarpetModelState.costFor)
                );
                setPrice(cost + item.price);
                setCarpetQuality(item);
              }}
              style={{}}
            >
              <div
                key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt pc_txt_150">{item.text}</label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Button
            disabled={carpetQuality.value === ""}
            buttonLabel={t("paintingHomes.continue")}
            onClick={onCarpetQualitySave}
          />
        </div>
      </div>
      {removalOfFloorPopup({
        isOpen: showRemovalFloorPopup,
        onYesClick: onPriorFloorRemovalYesClick,
        onCloseAction: onPriorFloorRemovalNoClick,
        onNoClick: onPriorFloorRemovalNoClick,
      })}
    </div>
  );
};
