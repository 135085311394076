import IMAGES from "../../_assets/images/index";
 const beachSliderItems = [
  {
    urls: IMAGES.home.bathroomCommon,
  },
//   {
//     urls: IMAGES.home.hdSofa,
//   },
//   {
//     urls: IMAGES.home.hdkitchen,
//   },
//   {
//     urls: IMAGES.home.hdBathroom,
//   },
//   {
//     urls: IMAGES.home.hdkitchen,
//   },
//   {
//     urls: IMAGES.home.hdBathroom,
//   },
];
export default beachSliderItems