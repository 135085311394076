import React from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import { Footer } from "../../homeComponents/footerSection/Footer.comp";
import IMAGES from "../../../_assets/images/index";
import { useTranslation } from "react-i18next";
import CheckBox from "../../../_components/CheckBox/checkBox";

function AddsOn(props) {
  const { t } = useTranslation();
  const btnClick = () => {
    props.history.push(`/floors/solidhardwood/order-summary`);
  };
  return (
    <div>
      <HeaderMenu />
      <div className="home-section">
        <span
          style={{ fontFamily: "Poppins", color: "#5B75FF", fontSize: "12px" }}
        >
          {t("homePage.treatyourHomeSection.Floors")} •
          {t("homePage.ourProjectSection.Solid_hardwood")}{" "}
        </span>
        <div className="main_button">
          <div style={{ display: "flex", alignItems: "center" }} alt="">
            <img src={IMAGES.home.step6} width="75px" alt="" />
            <p>Add on´s</p>
            <br />
          </div>
          <div style={{ borderLeft: "0.5px solid #D8D8D8", height: "90px" }}>
            <img
              style={{ marginTop: "10px", marginLeft: "10px" }}
              src={IMAGES.home.piggy}
              width="40px"
              alt=""
            />
            <p style={{ marginTop: "-11px", marginLeft: "10px" }}>$0000</p>
          </div>
        </div>
        <br />
        <br />
        <span
          style={{
            fontFamily: "Poppins",
            color: "black",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Add on´s
        </span>
        <br />
        <span
          style={{ fontFamily: "Poppins", color: "black", fontSize: "16px" }}
        >
          Please select which other services you would like to include
        </span>
        {items.map((item, index) => (
          <div key={index} className="radio_div">
            <span>{item.text}</span>
            <div
              style={{ display: "flex", flexDirextion: "column", gap: "7px" }}
            >
              <img
                style={{ height: "12px", width: "12px", marginTop: "6px" }}
                src={IMAGES.other.green_plus}
                alt=""
              />
              <span>{item.price}</span>
              <CheckBox />
            </div>
          </div>
        ))}
        <br />
        <br />
        <div
          className="form_btn"
          style={{
            marginBottom: "0px",
            marginTop: "0px",
            backgroundColor: "#D8D8D8",
            height: "12px",
            width: "100%",
            fontSize: "15px",
            borderRadius: "15px",
          }}
          onClick={() => btnClick()}
        >
          <span className="message_span" style={{ color: "#ADADAD" }}>
            Continue to next
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AddsOn;

const items = [
  {
    text: "Shoe molding",
    price: "$0000.-",
  },
  {
    text: "Quarter rounds",
    price: "$0000.-",
  },
  {
    text: "Refinishing",
    price: "$0000.-",
  },
  {
    text: "Baseboards",
    price: "$0000.-",
  },
];
