import IMAGES from "../../_assets/images/index";

const items = [
  {
    urls: IMAGES.home.Engineered1,
  },
  {
    urls: IMAGES.home.Engineered2,
  },
  {
    urls: IMAGES.home.Engineered3,
  },
  {
    urls: IMAGES.home.Engineered4,
  },
  {
    urls: IMAGES.home.Engineered5,
  },
  {
    urls: IMAGES.home.Engineered6,
  },
];
export default items;
