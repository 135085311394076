import React, { useState } from "react";
import IMAGES from "../../_assets/images";

const ReviewCard = ({ review, getFormattedDate, width }) => {
  const { createdBy, createdAt, review: description } = review;
  const [showAll, setShowAll] = useState(false);

  const shortenedText =
    description?.length > 80
      ? showAll
        ? description
        : `${description.slice(0, 80)}...`
      : description;

  return (
    <div
      className="review-item my-1"
      style={{
        height: showAll ? "fit-content" : "auto",
        minWidth: width ? width : "30%",
      }}
    >
      <div class="review-item-top">
        <div className="review-img-container">
          <img
            src={createdBy?.profileImage || IMAGES.other.person}
            className="review-img"
            alt=""
          />
        </div>
        <div className="review-date">
          <p>{createdBy?.name}</p>
          <p>{getFormattedDate(createdAt)}</p>
        </div>
      </div>
      <p className="review-description">{shortenedText}</p>
      {description?.length > 80 ? (
        <button
          onClick={() => setShowAll(!showAll)}
          className="review-show-more"
        >
          {showAll ? "Show Less" : "Show more"}{" "}
          <img src={IMAGES?.home?.rightArrow} />
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReviewCard;
