import React, { useState } from "react";
import IMAGES from "../../../_assets/images/index";
import { TextInput } from "../../../_components/TextInput/TextInput";
import "../../../_styles/localStyles/signupsignin.css";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from "../signUp/socialMediaSignup/googleLogin";
import FacebookLogin from "../signUp/socialMediaSignup/facebookLogin";
import { Footer } from "../../../_components/footer/Footer";
import { forgotPasswordAPI } from "../../../services/api/signup.service";
import { Button } from "../../../_components/Button/Button";
import { useSnackbar } from "notistack";
import Loader from "../../../_components/spinner/Loader";

function RecoverPassword(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false)

  const [signInState, setSignInState] = useState({
    email: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
  });
  const onChangeHandler = (event, key) => {
    let validatedState = validate(key, event.target.value, undefined);
    setSignInState(validatedState);
  };

  const validate = (name, value, message) => {
    //
    const newState = { ...signInState };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;
    var regexpEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    switch (name) {
      case "email":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Email is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (!value.match(regexpEmail)) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Please enter a valid Email";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value.match(regexpEmail)) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      default: {
        return newState;
      }
    }
  };

  const buttonClick = () => {
    const email = signInState.email.value
    setIsLoading(true)
    forgotPasswordAPI({ email: email }).then((response) => {
      props.history.push(`/signin/check-mail`, { email: email });
    }).catch((e) => {
      const variant = "error";
      const errorMessage = "Email not found";
      enqueueSnackbar(errorMessage, { variant });

    }).finally(() => setIsLoading(false))

    ////g(signInState)
  };
  const arrowClick = () => {
    props.history.push(`/signin`);
  };
  return (
    <div>
      <div className="pcWidth">
        <Loader progress={isLoading} />
        <div className="signup_center_align">
          <table>
            <tr>
              <td>
                {/* <img
                src={IMAGES.other.vectors}
                alt=""
                onClick={() => arrowClick()}
              /> */}
              </td>
              <td>
                <label
                  className="signup_page_anyhnb_logo"
                  onClick={() => window.location.replace("/")}
                >
                  {t("signUp.mainHeading")}
                </label>
              </td>
            </tr>
          </table>
        </div>
        {/* <h3>Sign Up</h3>

        <div style={{ display: "flex", marginTop: "-32px" }}>
          {" "}
          <p>I already have an account</p>
          <p
            style={{ marginLeft: "6px", color: "#5B75FF", fontWeight: "600" }}
            onClick={() => signInClick()}
          >
            Sign In
          </p>{" "}
        </div> */}
        <label className="signup_header signup_flex_with_center">
          {t("recoverPassword.recoverPwdHeading")}
        </label>
        <div className="signup_flex_with_center">
          {" "}
          <label className="signup_sub_heading">{t("recoverPassword.subHeading")}</label>

        </div>
        <div className="ssignin_rec_padding">

          <div className="register_page_lable">
            <TextInput
              className="signup_input_border"
              label={t("signIn.email_label")}
              type="text"
              onChange={(event) => onChangeHandler(event, "email")}
              value={signInState.email.value}
              error={signInState.email.error}
              placeholder={t("signIn.email_placeholder")}
            />
          </div>
        </div>

        <div style={{ cursor: 'pointer' }} className="ssignin_rec_padding_top">
          <Button
            disabled={signInState.email.error.flag === true}
            buttonLabel={t("signIn.continueNext")}
            onClick={buttonClick}
          />



        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RecoverPassword;
