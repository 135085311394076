import React from "react";
import { useTranslation } from "react-i18next";

const BasementWhatIsIncluded = () => {
  const { t } = useTranslation();
  return (
    <div>
      <label>
        <strong>{t("floor.whatsIncludedInPackage")}</strong>
      </label>

      <ul>
        <li>Customer support specialist</li>
        <li>Includes any structural work</li>
        <li>Walls framing material and installations</li>
        <li>Electrical materials and installations</li>
        <li>Drywall materials and installations</li>
        <li>Square corners</li>
        <li>Flat ceilings</li>
        <li>Decora and switches</li>
        <li>Doors and installation (up to 5)</li>
        <li>3” can lights and installation (up to 12)</li>
        <li>Install builder grade baseboards</li>
        <li>Install customer-supplied new flooring</li>
        <li>Construction waste disposal & debris removal</li>
        <li>1 year comprehensive Warranty covering workmanship</li>
        <li>1 year warranty materials we have supplied and installed</li>
        <li>Materials free delivery</li>
      </ul>
      <div className="profilePadding10"></div>
      <label>
        <strong>{t("floor.howLongTheProcessTakes")}</strong>
      </label>
      <ul>
        <li> Between 2 to 12 weeks*</li>
      </ul>
      <div className="profilePadding40"></div>
      <span>*Might differ according to size</span>
    </div>
  );
};

export default BasementWhatIsIncluded;
