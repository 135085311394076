import React, { useEffect, useState } from "react";
import {
  getCountryCallingCode,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { PhoneNumberUtil } from "google-libphonenumber";

import PhoneInput from "react-phone-number-input";
import { Exclamation } from "../Tickandwrongsvg/exclamation";
import { Tick } from "../Tickandwrongsvg/tick";

import "./PhoneInput.css";

const CustomCountrySelect = (props) => {
  const {
    options,
    defaultCountry,
    showCountryName,
    selectClasses = "country-select",
  } = props;
  const placeholderOption = {
    label: "Country / Region",
    value: "",
    // disabled: true,
    selected: true,
  };

  return (
    <div>
      <select
        className={selectClasses}
        {...props}
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        key={defaultCountry}
      >
        {[placeholderOption, ...options].map(
          ({ value, label, disabled, selected }) => {
            let finalSelected = selected;
            if (defaultCountry === value) {
              finalSelected = true;
            } else {
              finalSelected = false;
            }
            return (
              <option
                key={value}
                value={value}
                disabled={disabled}
                selected={finalSelected}
              >
                {showCountryName ? label : ""}{" "}
                {value ? `(+${getCountryCallingCode(value)})` : ""}
              </option>
            );
          }
        )}
      </select>
    </div>
  );
};

const CustomPhoneInput = ({
  valueState,
  setValueState,
  name,
  setButtonDisabled,
  defaultValue,
  phoneInputClasses = "",
  showLabel = true,
  showCountryName = true,
  selectClasses,
  ...props
}) => {
  let classes = [props?.className];

  if (valueState?.[name].error.flag === null) {
    classes.push("input-text");
  }

  if (valueState?.[name].error.flag === true) {
    classes.push("input-text is-not-valid");
  }
  if (valueState?.[name].error.flag === false) {
    classes.push("input-text is-valid");
  }
  const [phone, setPhone] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("US");

  const handleChange = (newPhone) => {
    setPhone(newPhone || "");

    if (newPhone) {
      if (!isValidPhoneNumber(newPhone)) {
        setValueState((prev) => ({
          ...prev,
          [name]: {
            valid: false,
            value: newPhone,
            error: {
              flag: true,
              message: "Please enter a valid Phone number",
            },
          },
        }));
        setButtonDisabled && setButtonDisabled(true);
      } else {
        const phoneUtil = PhoneNumberUtil.getInstance();
        const parsedPhoneNumber = phoneUtil.parse(newPhone, "");
        const formattedPhoneNumber = `+${parsedPhoneNumber.getCountryCode()}-${parsedPhoneNumber.getNationalNumber()}`;
        setValueState((prev) => ({
          ...prev,
          [name]: {
            valid: true,
            value: formattedPhoneNumber || newPhone,
            error: {
              flag: false,
              message: "",
            },
          },
        }));
        setButtonDisabled && setButtonDisabled(false);
      }
    } else {
      setValueState((prev) => ({
        ...prev,
        [name]: {
          valid: false,
          value: newPhone,
          error: {
            flag: true,
            message: "Phone number is required",
          },
        },
      }));
      setButtonDisabled && setButtonDisabled(true);
    }
  };

  useEffect(() => {
    if (defaultValue && defaultValue?.toString()?.length > 4) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const parsed = phoneUtil.parse(`+${defaultValue}`, "");
      const country = phoneUtil.getRegionCodeForNumber(parsed); // Get the country short code
      if (country) {
        setDefaultCountry(country);
      }
      setPhone(`${defaultValue}`);
    }
  }, [defaultValue]);

  return (
    <div className={`custom-phone-field ${classes.join(" ")}`}>
      {showLabel ? <label>Phone Number *</label> : ""}
      <PhoneInput
        countrySelectComponent={(props) => (
          <CustomCountrySelect
            defaultCountry={defaultCountry}
            showCountryName={showCountryName}
            selectClasses={selectClasses}
            {...props}
          />
        )}
        value={phone}
        addInternationalOption={false}
        defaultCountry={defaultCountry}
        onChange={handleChange}
        placeholder="Phone Number"
        className={phoneInputClasses}
      />
      {valueState?.[name]?.error?.flag ? (
        <div className="flex-justify-fix">
          <div className="signup_validation_password_icon">
            <Exclamation
              width="40px"
              height="40px"
              fill="white"
              backgroundColor="red"
            />
          </div>
          <div className="input-text is-not-valid">
            {valueState?.[name]?.error?.message || ""}
          </div>
        </div>
      ) : null}
      {valueState?.[name]?.error.flag === false ? (
        <div className="flex-justify-fix">
          <div className="signup_tick_icon ">
            <Tick width="30px" height="30px" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const CustomPhoneInputForClassComponent = ({
  onChangeHandler,
  name,
  defaultValue,
  setValueState,
  valueState,
  setErrorState,
  ...props
}) => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState({
    flag: null,
    message: "",
  });
  const [defaultCountry, setDefaultCountry] = useState("US");

  let classes = [props?.className];

  if (error.flag === null) {
    classes.push("input-text");
  }

  if (error.flag === true) {
    classes.push("input-text is-not-valid");
  }
  if (error.flag === false) {
    classes.push("input-text is-valid");
  }

  const handleChange = (newPhone) => {
    setPhone(newPhone || "");

    if (newPhone) {
      const country = getCountryCallingCode(defaultCountry);
      const formattedPhone = newPhone
        .toString()
        .replace(`+${country}`, `+${country}-`);

      if (!isValidPhoneNumber(newPhone)) {
        setError({
          flag: true,
          message: "Invalid Phone number",
        });
        setErrorState(true);
      } else {
        setError({ flag: false, message: "", validated: true });
        onChangeHandler(name, formattedPhone);
        setErrorState(false);
      }
    } else {
      setError({
        flag: true,
        message: "Phone number is required",
      });
      setErrorState(true);
    }
  };

  useEffect(() => {
    if (defaultValue && defaultValue?.toString()?.length > 4) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const parsed = phoneUtil.parse(`+${defaultValue}`, "");
      const country = phoneUtil.getRegionCodeForNumber(parsed); // Get the country short code
      setDefaultCountry(country);
      setPhone(`${defaultValue}`);
    }
  }, [defaultValue]);

  return (
    <div className={`custom-phone-field ${classes.join(" ")}`}>
      <label>Phone Number *</label>
      <PhoneInput
        countrySelectComponent={(props) => (
          <CustomCountrySelect defaultCountry={defaultCountry} {...props} />
        )}
        value={phone}
        addInternationalOption={false}
        defaultCountry={defaultCountry}
        onChange={handleChange}
        placeholder="Phone Number"
      />
      {error?.flag === true ? (
        <div className="flex-justify-fix">
          <div className="signup_validation_password_icon">
            <Exclamation
              width="40px"
              height="40px"
              fill="white"
              backgroundColor="red"
            />
          </div>
          <div className="input-text is-not-valid">{error?.message || ""}</div>
        </div>
      ) : null}
      {error?.flag === false ? (
        <div className="flex-justify-fix">
          <div className="signup_tick_icon ">
            <Tick width="30px" height="30px" />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default CustomPhoneInput;
