import { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getBasementOrderSummary,
  getBathroomOrderSummary,
  getCarpetOrderSummary,
  getDeckOrderSummary,
  getElectricOrderSummary,
  getEngineeredOrderSummary,
  getEpoxyOrderSummary,
  getExteriorOrderSummary,
  getFenceOrderSummary,
  getHvacOrderSummary,
  getInteriorPaintingOrderSummary,
  getKitchenOrderSummary,
  getLaminatedOrderSummary,
  getRoofingOrderSummary,
  getSolidHardwoodOrderSummary,
  getTilesOrderSummary,
  getTreeOrderSummary,
  getWallOpeningOrderSummary,
  getWindowsOrderSummary,
} from ".";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import IMAGES from "../../_assets/images";

export const OrderSummaryForAllFlow = (props) => {
  const { project } = props;
  const { t } = useTranslation();
  const [summary, setSummary] = useState([]);
  const [currentService, setCurrentService] = useState("");

  useLayoutEffect(() => {
    if (project && project !== undefined) {
      let summary = [];
      let editProjectLink = "";
      let currentServiceAs = "";
      if (project && project.serviceDetails?.data?.flowName !== undefined) {
        const payload = project.serviceDetails?.data;
        switch (payload.flowName) {
          case "floor-engineered":
            summary = getEngineeredOrderSummary(payload);
            editProjectLink = "/floors/solidhardwood";
            currentServiceAs = "Engineered";
            break;
          case "floor-solidHardwood":
            summary = getSolidHardwoodOrderSummary(payload);
            editProjectLink = "/floors/solidhardwood";
            currentServiceAs = "Floor Solid hardwood";
            break;

          case "wallOpening-flow":
            summary = getWallOpeningOrderSummary(payload);
            editProjectLink = "/wall-opening/size";
            currentServiceAs = "Wall opening";
            break;
          case "kitchen-flow":
            summary = getKitchenOrderSummary(payload);
            editProjectLink = "/kitchen/type-of-work/1";
            currentServiceAs = "Kitchen";
            break;

          case "basement-flow":
            summary = getBasementOrderSummary(payload, t);
            editProjectLink = "/kitchen/type-of-work/1";
            currentServiceAs = "Basement";
            break;

          case "floor-carpet":
            summary = getCarpetOrderSummary(payload);
            editProjectLink = "/floors/solidhardwood";
            currentServiceAs = "Floor carpet";
            break;

          case "floor-tiles":
            summary = getTilesOrderSummary(payload);
            editProjectLink = "/floors/solidhardwood";
            currentServiceAs = "Floor tiles";
            break;

          case "floor-laminated":
            summary = getLaminatedOrderSummary(payload, t);
            editProjectLink = "/floors/solidhardwood";
            currentServiceAs = "Floor laminated";
            break;

          case "floor-epoxy":
            summary = getEpoxyOrderSummary(payload, t);
            editProjectLink = "/floors/solidhardwood";
            currentServiceAs = "Floor epoxy";
            break;

          case "deck":
            summary = getDeckOrderSummary(payload);
            editProjectLink = "/painting";
            currentServiceAs = "Deck";
            break;

          case "fence":
            summary = getFenceOrderSummary(payload);
            editProjectLink = "/painting";
            currentServiceAs = "Fence";
            break;

          case "exterior":
            summary = getExteriorOrderSummary(payload);
            editProjectLink = "/painting";
            currentServiceAs = "Painting exterior";
            break;

          case "bathroom":
            summary = getBathroomOrderSummary(payload, t);
            editProjectLink = "/bathroom";
            currentServiceAs = "Bathroom";
            break;

          case "hvac-flow":
            summary = getHvacOrderSummary(payload);
            editProjectLink = "/hvac/type-of-service";
            currentServiceAs = "HVAC";
            break;

          case "electric-flow":
            summary = getElectricOrderSummary(payload);
            editProjectLink = "/hvac/type-of-service";
            currentServiceAs = "Electric";
            break;

          case "plumbing-flow":
            summary = getElectricOrderSummary(payload);
            editProjectLink = "/hvac/type-of-service";
            currentServiceAs = "Plumbing";
            break;

          case "roofing-flow":
            summary = getRoofingOrderSummary(payload);
            editProjectLink = "/roofing/type-of-service";
            currentServiceAs = "Roofing";
            break;

          case "window-flow":
            summary = getWindowsOrderSummary(payload);
            editProjectLink = "/windows/type-of-service";
            currentServiceAs = "Windows";
            break;

          case "tree-flow":
            summary = getTreeOrderSummary(payload);
            editProjectLink = "/tree-service/service-type";
            currentServiceAs = "Tree services";
            break;

          case "painting-interior":
            summary = getInteriorPaintingOrderSummary(payload);
            editProjectLink = "/painting";
            currentServiceAs = "Painting interior";
            break;

          default:
            break;
        }
        setSummary(summary);
        setCurrentService(currentServiceAs);
      }
    }
  }, [project, t]);

  const getSummaryFromArray = () => {
    const heading = currentService === "Bathroom" ? "Bathroom" : "Room";

    return (
      <div>
        {summary.map((summary, index) => (
          <div key={index}>
            <div className="padding10">
              <label className="paint_header">{`${heading} #${
                index + 1
              }`}</label>
              {heading === "Bathroom" ? (
                <label className="label-sub-price-text">{`Sub total: $${formatPrice(
                  calculateTotalCost(
                    project.serviceDetails?.data?.bathroomsDetails[index]
                      .costFor
                  )
                )}`}</label>
              ) : null}
            </div>
            {summary.map((item, index) =>
              item.text.length > 0 ? (
                <div key={index}>
                  <label
                    className="floor-selected"
                    style={{
                      // height: "88px",
                      flexDirection: "column",
                      padding: "10px",
                      alignItems: "start",
                    }}
                  >
                    <div className="bold">{item.heading}</div>
                    <div
                      className="flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: " 90%",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          textAlign: "left",
                          textTransform: "initial",
                        }}
                      >
                        {item.text}
                      </div>

                      <div
                        className="price-wrp"
                        style={{ display: "flex", placeItems: "center" }}
                      >
                        <div style={{ paddingTop: "3px" }}>
                          <img
                            width="15"
                            height="15"
                            src={IMAGES.other.green_plus}
                            alt=""
                          />
                        </div>
                        <div style={{ paddingLeft: "10px" }}>
                          {item.price < 0
                            ? `${formatPrice(item.price).replace("-", "-$")}`
                            : `$${formatPrice(item.price)}`}
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              ) : null
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      {project !== null &&
      project.serviceDetails?.data?.flowName !== "bathroom" &&
      project.serviceDetails?.data?.flowName !== "painting-interior" ? (
        <div>
          {summary &&
            summary.map((item, index) =>
              item.text.length > 0 ? (
                <div key={index}>
                  <label
                    className="floor-selected"
                    style={{
                      // height: "88px",
                      flexDirection: "column",
                      padding: "10px",
                      alignItems: "start",
                    }}
                  >
                    <div className="bold">{t(item.heading)}</div>
                    <div
                      className="flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: " 90%",
                      }}
                    >
                      <div
                        className="pc_txt_150"
                        style={{
                          width: "100%",
                          textAlign: "left",
                          textTransform: "initial",
                        }}
                      >
                        {item.text}
                      </div>

                      {item.price ? (
                        <div
                          className="price-wrp"
                          style={{ display: "flex", placeItems: "center" }}
                        >
                          {item.price > 0 && (
                            <div style={{ paddingTop: "3px" }}>
                              <img
                                width="15"
                                height="15"
                                src={IMAGES.other.green_plus}
                                alt=""
                              />
                            </div>
                          )}
                          <div>
                            {item.price < 0
                              ? `${formatPrice(item.price).replace("-", "-$")}`
                              : `$${formatPrice(item.price)}`}
                          </div>
                        </div>
                      ) : (
                        <span className="price-wrp">$0.00</span>
                      )}
                    </div>
                  </label>
                </div>
              ) : null
            )}
        </div>
      ) : (
        getSummaryFromArray()
      )}
    </div>
  );
};
