import axios from "axios";
import { API_URL, serviceCategoryEndPoints } from "../apiConstants";
import i18n from "i18next";

/**
 * Returns the available service categories to show on home page
 * @returns AxiosResponse
 */
const getServiceCategories = async () => {
  try {
    const response = await axios.get(
      `${API_URL}${serviceCategoryEndPoints.getServiceCategories}`,
      { params: { lang: i18n.language } }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Gets the full available list of services with sub categories
 * @returns AxiosResponse
 */
const getServiceCategoriesFull = async () => {
  try {
    const response = await axios.get(
      `${API_URL}${serviceCategoryEndPoints.getServiceCategoriesFull}`,
      { params: { lang: i18n.language } }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Gets the full price details of services with sub category
 * @returns AxiosResponse
 */
const getServicePriceDetails = async (categoryId, subCategoryId) => {
  try {
    const response = await axios.get(
      `${API_URL}${serviceCategoryEndPoints.getServiceCategories}/${categoryId}/subcategories/${subCategoryId}/options`,
      { params: { lang: i18n.language } }
    );
    return response;
  } catch (error) {
    return error;
  }
};

/**
 * Gets the full price details of services with sub category
 * @returns AxiosResponse
 */
const getAvailablePaints = async () => {
  try {
    const response = await axios.get(
      `${API_URL}/service-categories/painting/colors`,
      { params: { lang: i18n.language } }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export {
  getServiceCategories,
  getServiceCategoriesFull,
  getServicePriceDetails,
  getAvailablePaints,
};
