import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./_redux/store/index";
import { NoficationContextProvider } from "./_components/Notification/store/NotificationProvider";
import App from "./App";
import NotifyDialog from "./_components/Notification/NotifyDialog";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { RecoilRoot } from "recoil";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ge", "es", "it", "fr", "pt"],
    fallbackLng: "en",
    debug: false,
    defaultNS: "en",
    // Options for language detector
    // detection: {
    //   order: ['path', 'cookie', 'htmlTag'],
    //   caches: ['cookie'],
    // },
    // react: { useSuspense: false },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
  });
const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>Loading...</h3>
  </div>
);

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <ReduxProvider store={store}>
      <RecoilRoot>
        <Router>
          <PersistGate persistor={persistor}>
            <NoficationContextProvider>
              <SnackbarProvider maxSnack={3}>
                <Elements stripe={stripePromise}>
                  <App />
                </Elements>
              </SnackbarProvider>
              <NotifyDialog />
            </NoficationContextProvider>
          </PersistGate>
        </Router>
      </RecoilRoot>
    </ReduxProvider>
  </Suspense>,
  document.getElementById("root")
);
