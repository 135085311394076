import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import { TextInput } from "../../../_components/TextInput/TextInput";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorSolidHardwoodModel } from "../../../_redux/Models/Floor.model";
import { isInteger } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import { getServicePriceDetails } from "../../../services/api/category.api.service";
import { formatPrice } from "../../../services/utils/utilFunctions";
import { el } from "date-fns/locale";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const SHSizeOfFloor = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 1;

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorSolidHardwoodModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [selectedContractorId, setSelectedContractorId] = useRecoilState(
    selectedContractorAtom
  );
  const [floorSize, setFloorSize] = useState(""); // To keep the floor size in state
  const [floorSizePricing, setFloorSizePricing] = useState([]); // To keep the floor size in state
  const [price, setPrice] = useState(0); // To keep the total price in state
  const [lessThanRequiredSize, setLessThanRequiredSize] = useState(false); // To keep the total price in state

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;
    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          checkoutModelState.priceDetails = response.data.details;
          checkoutModelState.totalCost = 0;
          setFloorSizePricing(response.data.details.typeOfService);
          dispatch(checkoutStore(checkoutModelState));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch]);

  /**
   * Calls when submit button is clicked
   */
  const onSubmitFloorSize = () => {
    state.costFor = {
      size: price,
      typeOfService: 0,
      materialSize: 0,
      finishing: 0,
      typeOfInstallation: 0,
      floorRemoval: 0,
      addOns: 0,
      elevator: 0,
    };
    state.floorSize = floorSize;
    dispatch(floorStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    if (state.serviceType === "refinishing") {
      props.history.push(
        `/contractor-portfolio/${selectedContractorId}/floors/solid-hardwood/order-summary/9`
      );
    } else {
      props.history.push(
        `/contractor-portfolio/${selectedContractorId}/floors/solid-hardwood/have-solid-hardwood/2`
      );
    }
  };
  return (
    <div className="">
      {/* <CircularProgressBar/> */}
      <div style={{ padding: "0px 20px 20px 20px" }}>
        {/* <div className="service-banner-sec stable_class">
          <div class="banner-bg floor-banner">
            <img src={IMAGES.home.floorBanner} alt="" />
          </div>
          <div className="floor-title">
            <label className="header_path_name_show">
              {t("solidHardwood.header")}
            </label>
          </div>

          <div class="banner-title">
            <label>Floors</label>
          </div>
        </div> */}
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("solidHardwood.introduceFloorSize")}
            </label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {state.serviceType === "refinishing"
                ? "Please introduce the total number of square feet of the solid hardwood floor you need to do refinishing"
                : t("solidHardwood.pleaseIntroduceFloorSize")}
            </label>
          </div>
          <div className="paintinput_lable" style={{ marginBottom: "10px" }}>
            <TextInput
              className="paint_input_border"
              error={isInteger(floorSize)}
              type="number"
              onChange={(e) => {
                setLessThanRequiredSize(e.target.value < 150);
                const type = state.serviceType;
                const priceForType = floorSizePricing.find(
                  (el) => el.key === type
                ).price;
                setPrice(priceForType * e.target.value);
                setFloorSize(e.target.value);
              }}
              value={floorSize ? floorSize : ""}
              placeholder={t("solidHardwood.pleaseEnterSQFTs")}
              label={t("solidHardwood.sizing")}
            />
            <div className="paint_placeholder_right">
              <label>{t("paintingInterior.sqft")}</label>
            </div>
          </div>
          {lessThanRequiredSize ? (
            <span style={{ fontSize: "10px", color: "red" }}>
              Minimum size is 150 square feet
            </span>
          ) : null}
          <Button
            disabled={lessThanRequiredSize || parseInt(floorSize || 0) < 150}
            buttonLabel={t("paintingHomes.continue")}
            onClick={onSubmitFloorSize}
          />
        </div>
      </div>
    </div>
  );
};
