import React, { useState, useEffect, useLayoutEffect } from "react";

import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/paint.css";
import "../../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import CheckBox from "../../../_components/CheckBox/checkBox";
import { exteriorModel } from "../../../_redux/Models/painting.models";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, paintingStore } from "../../../_redux/actions";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { getAvailablePaints } from "../../../services/api/category.api.service";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import { TextInput } from "../../../_components/TextInput/TextInput";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import Loader from "../../../_components/spinner/Loader";
import { Button } from "../../../_components/Button/Button";
import { DropDown } from "../../../_components/dropDown/dropDown";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";

export const PickColorRoomExterior = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 0;
  const dispatch = useDispatch();
  const [progressBar, setProgressBar] = useState(false);
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const { t } = useTranslation();
  const history = useHistory();

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : exteriorModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state
  const [paintBrands, setPaintBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({
    _id: "",
    value: "",
    brand: "",
    colors: [],
  });
  const [selectedPaintColor, setSelectedPaintColor] = useState("");
  const [selectedPaintCode, setSelectedPaintCode] = useState("");
  const [needPrimer, setNeedPrimer] = useState(false);
  const [typesOfPaint, setTypesOfPaint] = useState([]);
  const [selectedPaintType, setSelectedPaintType] = useState({
    value: "",
    price: 0,
  });
  const [coat, setCoat] = useState(1);
  const [costFor, setCostFor] = useState({
    paint: 0,
    primer: 0,
    coat: 0,
  });
  const [notSureAboutPaint, setNotSureAboutPaint] = useState(false);

  const [primerCoatPrice, setPrimerCoatPrice] = useState(0);
  const [coatsPrice, setCoatsPrice] = useState(0);
  const [secondCoatsPrice, setSecondCoatsPrice] = useState(0);

  useEffect(() => {
    let mounted = true;
    setProgressBar(true);
    const priceDetails = checkoutModelState.priceDetails;
    const size = parseFloat(state.size);
    const haveOwnPaint = parseFloat(state.haveOwnPaint);
    if (mounted === true && priceDetails && priceDetails.typeOfPaint) {
      let gallonNeededDividend = 250;
      if (coat === 2 || needPrimer) {
        gallonNeededDividend = 125;
      }
      if (coat === 2 && needPrimer) {
        gallonNeededDividend = 80;
      }
      const totalGallons = size / gallonNeededDividend;
      const exteriorMap = {
        flat: "StandardQuality",
        egg_shell: "BetterQuality",
        satin: "BestQuality",
      };
      const optionsArr = priceDetails.typeOfPaint.map((op) => {
        return {
          text: t(`paintingExterior.${exteriorMap[op.key]}`),
          value: exteriorMap[op.key],
          price: haveOwnPaint ? 0 : op.price * totalGallons,
        };
      });

      getAvailablePaints()
        .then(({ data }) => {
          const ops = data.map(({ _id, brand, colors }) => ({
            value: _id,
            name: brand,
            colors: colors,
          }));
          setPaintBrands(ops);
          setProgressBar(false);
        })
        .catch((e) => console.log(e));

      setTypesOfPaint(optionsArr);
      const primerPrice =
        (priceDetails.primerCoat.percentage / 100) *
        calculateTotalCost(state.costFor);
      setPrimerCoatPrice(primerPrice);

      const secondCoatPrice =
        (priceDetails.coats.percentage / 100) * state.costFor.exteriorMaterial;
      setCoatsPrice(secondCoatPrice);
    }
    return () => (mounted = false);
  }, [checkoutModelState, coat, needPrimer, state, t]);

  const onSave = () => {
    state.paintDetails = {
      brandName: selectedBrand,
      colorName: selectedPaintColor,
      paintCode: selectedPaintCode,
    };
    state.notSureAboutPaint = notSureAboutPaint;
    state.needPrimerCoat = needPrimer;
    state.paintType = selectedPaintType;
    state.coats = coat;
    state.costFor.paint = costFor.paint;
    state.costFor.primer = costFor.primer;
    state.costFor.coat = costFor.coat;
    dispatch(paintingStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/painting/exterior/add-ons/${5}`
    );
  };

  function arrowHandler(params) {
    let coatPrev = coat;
    if (params === "plus") {
      coatPrev = 2;
    } else {
      coatPrev = parseInt(coatPrev) > 1 ? parseInt(coatPrev) - 1 : 1;
    }
    calculatePrice(needPrimer, coatPrev, selectedPaintType.price);
    setCoat(coatPrev);
  }

  const onAddOnHandler = (coatPrev) => {
    console.log(coatPrev);
    calculatePrice(needPrimer, coatPrev, selectedPaintType.price);
    setCoat(coatPrev);
  };

  const calculatePrice = (needPrimeCoatAs, coatAs, paintPrice) => {
    needPrimeCoatAs =
      needPrimeCoatAs !== undefined ? needPrimeCoatAs : needPrimer;
    paintPrice =
      paintPrice !== undefined ? paintPrice : selectedPaintType.price;
    coatAs = coatAs !== undefined ? coatAs : coat;
    coatAs = parseInt(coatAs);
    console.log("coatAs", coatAs);
    let total = calculateTotalCost(state.costFor);
    total = total + paintPrice;

    const primerPrice = needPrimeCoatAs === true ? primerCoatPrice : 0;
    const priceDetails = checkoutModelState.priceDetails;
    const secondCoatPrice =
      (priceDetails.coats.percentage / 100) * state.costFor.exteriorMaterial;
    const coatPrice = coatAs == 2 ? secondCoatPrice : 0;
    console.log("secondCoatPrice", secondCoatPrice);
    console.log("coatsPrice", coatsPrice);
    console.log(primerPrice);
    console.log(coatPrice);
    setPrice(total + primerPrice + coatPrice);
    console.log("total", total + primerPrice + coatPrice);
    setCostFor({
      paint: paintPrice,
      primer: primerPrice,
      coat: coatPrice,
    });
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">
            {t("paintingInterior.pickColor")}
          </label>
        </div>

        <div className="">
          <label className="paint_other_sub_header">
            {t("paintingInterior.pleaseSelectColorYouLike")}
          </label>
        </div>
        {!state.haveOwnPaint ? (
          <>
            <div>
              <label className="picColorroom1_radioHeader">
                {t("paintingInterior.brands")}
              </label>
            </div>
            <div className="paint_paddingtop">
              <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                <table className="paint_table100 ">
                  <tbody>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <TextInput
                          className="paint_input_border"
                          onChange={(event) => {
                            setSelectedBrand({
                              value: event.target.value,
                              brand: event.target.value,
                            });
                          }}
                          value={selectedBrand.value}
                          placeholder={t("paintingInterior.brandname")}
                        />

                        {/* <SelectSearch
                      key={Math.random()}
                      options={[].concat(paintBrands)}
                      value={selectedBrand.value}
                      className="brand-name-dynamic-select"
                      onChange={(selectedVal) => {
                        const selectedBrandOp = paintBrands.find(
                          (op) => op.value === selectedVal
                        );
                        if (selectedBrandOp) {
                          setSelectedBrand(selectedBrandOp);
                        }
                      }}
                      search
                      placeholder="Brand name"
                    /> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <table className="paint_table100 ">
              <tbody>
                <tr>
                  <td className="padding_8">
                    <div className="padding30">
                      <label className="picColorroom1_txtHeader">
                        {t("paintingInterior.colorname")}
                      </label>

                      <TextInput
                        className="paint_input_border"
                        onChange={(event) => {
                          setSelectedPaintColor(event.target.value);
                        }}
                        value={selectedPaintColor}
                        placeholder={t("paintingInterior.chooseAColor")}
                      />

                      {/* <SelectSearch
                    search
                    value={selectedPaintColor}
                    onChange={(val) => {
                      setSelectedPaintColor(val);
                      const colorCode = selectedBrand.colors.find(
                        (c) => c.name === val
                      ).colorCode;
                      setSelectedPaintCode(colorCode);
                    }}
                    className="paint-color-dynamic-select"
                    options={[].concat(selectedBrand.colors).map((c) => {
                      return { value: c.name, name: c.name };
                    })}
                    placeholder="Choose a color"
                    emptyMessage="No paints found"
                  /> */}
                    </div>
                  </td>
                  <td>
                    <div className="padding30">
                      <label className="picColorroom1_txtHeader">
                        {t("paintingInterior.paintcode")}
                      </label>

                      <TextInput
                        className="paint_input_border"
                        onChange={(event) => {
                          setSelectedPaintCode(event.target.value);
                        }}
                        value={selectedPaintCode}
                        placeholder={t("paintingInterior.chooseACode")}
                      />
                      {/* 
                  <SelectSearch
                    key={Math.random()}
                    value={selectedPaintCode}
                    onChange={(val) => {
                      setSelectedPaintCode(val);
                      const color = selectedBrand.colors.find(
                        (c) => c.colorCode === val
                      ).name;
                      setSelectedPaintColor(color);
                    }}
                    className="paint-code-dynamic-select"
                    options={[].concat(selectedBrand.colors).map((c) => {
                      return { value: c.colorCode, name: c.colorCode };
                    })}
                    placeholder="Choose a code"
                    emptyMessage="No codes found"
                  /> */}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="">
              <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                <table className="paint_table100">
                  <tbody>
                    <tr
                      onClick={() => setNotSureAboutPaint(!notSureAboutPaint)}
                    >
                      <td>
                        <label className="paintdiv_txt">
                          {t("paintingInterior.notsure1")}
                        </label>
                      </td>
                      <td className="paint_arrowFloat">
                        <CheckBox checked={notSureAboutPaint} readOnly />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : null}

        <div className="mt-2 mb-3">
          <label className="paint_primercoat">
            {t("paintingInterior.premier")}
          </label>
        </div>
        <div className="">
          <div className="paint_radio_div_first paintdiv_radiobtn_paddingright  pic-color">
            <table className="paint_table100">
              <tbody>
                <tr
                  onClick={() => {
                    setNeedPrimer((state) => {
                      calculatePrice(!state, coat, selectedPaintType.price);
                      return !state;
                    });
                  }}
                >
                  <td className="title">
                    <label className="paintdiv_txt">
                      {t("paintingInterior.ineed")}
                    </label>
                  </td>
                  <td className="price" style={{ display: "flex" }}>
                    {primerCoatPrice ? (
                      <div>
                        <div style={{ display: "flex" }}>
                          <img src={IMAGES.other.green_plus} alt="" />
                          <label className="paintdiv_txt">
                            {`$${formatPrice(primerCoatPrice)}`}
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </td>
                  <td className="paint_arrowFloat check">
                    <CheckBox checked={needPrimer} readOnly />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="my-3">
          <label className="paint_primercoat">
            {t("paintingInterior.coats")}
          </label>
        </div>
        <div className=" ">
          <div className="paint_radio_div_firstarrow paintdiv_arrow_padding">
            <table className="paint_table100">
              <tbody>
                <tr>
                  <td>
                    <div
                      className="typeofTxt"
                      style={{ paddingTop: "0", display: "grid" }}
                    >
                      <DropDown
                        callback={(event) => onAddOnHandler(event.target.value)}
                        length={2}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="padding30 ">
          <label className="paint_primercoat">
            {t("paintingInterior.paintTypeExterior")}
          </label>
        </div>
        {typesOfPaint.map((item, index) => (
          <div
            className={`floor-selection-radio ${
              selectedPaintType.value === item?.value ? "selected" : ""
            }`}
            onClick={() => {
              setSelectedPaintType(item);
              calculatePrice(needPrimer, coat, item.price);
            }}
          >
            <div
              key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label
                className="paintdiv_txt"
                style={{
                  width: "65%",
                }}
              >
                {item.text}
              </label>
              <div
                className="paint_arrowFloat"
                style={{
                  width: "35%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img src={IMAGES.other.green_plus} alt="" />
                  <label className="paintdiv_txt">
                    {`$${formatPrice(item.price)}`}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}

        <Button
          disabled={
            selectedPaintType.value === "" ||
            (!state.haveOwnPaint &&
              !(
                (selectedBrand.value !== "" &&
                  selectedPaintColor !== "" &&
                  selectedPaintCode !== "") ||
                notSureAboutPaint
              ))
          }
          buttonLabel={t("basement.continueToNext")}
          onClick={onSave}
        />
      </div>
    </div>
  );
};
