import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import IMAGES from "../../../_assets/images";

const NewFooter = ({ isLoading }) => {
  const { t } = useTranslation();
  const [isPortfolioPage, setIsPortfoliopage] = useState(false);

  useEffect(() => {
    if (
      window?.location?.pathname
        ?.split("/")
        ?.includes("contractor-portfolio") &&
      !window?.location?.pathname?.split("/")?.includes("confirm")
    ) {
      setIsPortfoliopage(true);
    } else {
      setIsPortfoliopage(false);
    }
  }, [window?.location]);

  return (
    <>
      <div
        className="footer-container px-5 d-none d-lg-flex"
        style={{ width: isLoading ? "100%" : "auto" }}
      >
        <div className="links d-flex justify-content-between">
          <div className="d-flex">
            <Link to="/">{t("footer.home")}</Link>
            <Link to="/role">{t("footer.createProfile")}</Link>
            <Link to="/signup">{t("footer.becomeContractor")}</Link>
            <Link to="/terms-and-condition">{t("footer.terms")}</Link>
            <Link to="/contact-us">{t("footer.contactUs")}</Link>
          </div>
          <p>{t("footer.allRights")}</p>
        </div>
      </div>
      <div
        className="footer-container h-auto d-flex d-lg-none flex-column bg-white"
        style={{
          // paddingBottom: isPortfolioPage ? "200px" : "20px",
          paddingBottom: "20px",
          width: isLoading ? "100%" : "auto",
        }}
      >
        <div className="logo-container">
          <img src={IMAGES.home.anyHnbLog} />
        </div>
        <div className="links">
          <Link to="/">{t("footer.home")}</Link>
          <Link to="/role">{t("footer.createProfile")}</Link>
          <Link to="/signup">{t("footer.becomeContractor")}</Link>
          <Link to="/terms-and-condition">{t("footer.terms")}</Link>
          <Link to="/contact-us">{t("footer.contactUs")}</Link>
        </div>
      </div>
      <div
        className="d-flex d-lg-none rights w-100"
        style={{
          marginBottom: window.location.pathname?.includes(
            "contractor-portfolio"
          )
            ? "200px"
            : "80px",
        }}
      >
        <p className="w-100">{t("footer.allRights")}</p>
      </div>
    </>
  );
};

export default NewFooter;
