import React from "react";
import "./ProgressBar.css";
function ProgressBar(props) {
  const { progressValue } = props;
  return (
    <div
      role="progressbar"
      className="progressBar"
      aria-valuenow="65"
      aria-valuemin="0"
      aria-valuemax="100"
      style={{ "--value": `${progressValue}` }}
    ></div>
  );
}

export default ProgressBar;
