import React, { useState, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { checkoutStore, kitchenStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import { kitchenModel } from "../../_redux/Models/Kitchen.model";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
export const KitchenSize = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const kitchenPayload = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.kitchenPayload
      ? state.buttonReducer.kitchenPayload
      : kitchenModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [price, setPrice] = useState(
    calculateTotalCost(kitchenPayload.costFor)
  ); // To keep the total price in state
  const [sizePricing, setSizePricing] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;

    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (
          mounted &&
          response.status === 200 &&
          response.data &&
          response.data.details.size
        ) {
          checkoutModelState.priceDetails = response.data.details;
          const sizeArr = response.data.details.size.map((op) => {
            return {
              text: t(`kitchen.${op.key}`),
              value: op.key,
              price: op.price,
            };
          });
          setSizePricing(sizeArr);
          dispatch(checkoutStore(checkoutModelState));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, t]);

  /**
   * Function to save Selected size and take user to next step
   * @returns {void}
   */
  const onSaveSize = (val, prc) => {
    kitchenPayload.size = val;
    kitchenPayload.costFor.size =
      prc - calculateTotalCost(kitchenPayload.costFor);
    dispatch(kitchenStore(kitchenPayload));
    checkoutModelState.totalCost = prc;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/kitchen/requirement/${3}`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("kitchen.sizing")}</label>
        </div>
        <div className="paint_flex_with_left ">
          <label className="paint_other_sub_header ">
            {t("kitchen.plzsize")}
          </label>
        </div>
        <div className="padding10"></div>
        {sizePricing.map((item, index) => (
          <div className="paintPadding8 ">
            <div
              className={`floor-selection-radio ${
                value === item?.value ? "selected" : ""
              }`}
              onClick={() => {
                setValue(item?.value);
                setPrice(item?.price);
                onSaveSize(item?.value, item?.price);
              }}
              style={{ height: "80px" }}
            >
              <div
                key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="paintdiv_txt pc_txt"
                  style={{
                    width: "150px",
                  }}
                >
                  {" "}
                  {item.text}{" "}
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
