import {
  UPDATE_PROJECT,
  UPDATE_TENTATIVE_DETAILS,
  UPDATE_ASSISTANT,
  UPDATE_PORTFOLIO,
  UPDATE_CONTRACTOR,
} from "../actions/contractor.action";

const inititalState = {
  projectDetails: {},
  portfolio: {},
  contractor: "",
  tentativeDetails: {
    startDate: "",
    endDate: "",
    time: {
      text: "",
      time: "",
    },
  },
  assistantDetails: {
    fullName: "",
  },
};

const contractorReducer = (state = inititalState, action) => {
  switch (action.type) {
    case UPDATE_PROJECT:
      return { ...state, projectDetails: action.payload };
    case UPDATE_TENTATIVE_DETAILS:
      return { ...state, tentativeDetails: action.payload };
    case UPDATE_PORTFOLIO:
      return { ...state, portfolio: action.payload };
    case UPDATE_CONTRACTOR:
      return { ...state, contractor: action.payload };
    case UPDATE_ASSISTANT:
      return {
        ...state,
        assistantDetails: {
          fullName: action.payload,
        },
      };
    default:
      return state;
  }
};

export default contractorReducer;
