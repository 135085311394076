import { notificationsEndPoints } from "../apiConstants";
import { getAxiosInstance } from './config/api.config'

const getAllNotifications = async () => {
    try {
        return getAxiosInstance().get(`${notificationsEndPoints.getAllNotifications}`)
    } catch (error) {
        return error;
    }
}

const updateNotifications = async (notificationId, data) => {
    try {
        return getAxiosInstance().put(`${notificationsEndPoints.updateReadStatus}/${notificationId}`, data)
    } catch (error) {
        return error;
    }
}

const addFirebaseToken = async (token) => {
    try {
        const response = await getAxiosInstance().put(`${notificationsEndPoints.updateFirebaseToken}`, { token, type: 'FCM' });
        localStorage.setItem('tokenId', response.data._id)
    } catch (error) {
        console.error('firebase token is not added', error);
    }
}

const deleteFirebaseToken = async (tokenId) => {
    try {
        return getAxiosInstance().delete(`${notificationsEndPoints.updateFirebaseToken}/${tokenId}`)
    } catch (error) {
        console.error('firebase token is not added', error);
    }
}


export { getAllNotifications, updateNotifications, addFirebaseToken, deleteFirebaseToken };