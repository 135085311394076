export const LOCAL_STORAGE = {
    user: 'user'
}

export const CONNECTION_STATUS = {
    connected: 'connected',
    initialized: 'initialized'
}

export const SNACKBAR_VARIANT = {
    error: 'error'
}

export const NOTIFICATION_TITLE = {
    newProjectCreated: 'New Project',
    projectStatusChangeConfirmed: 'Project Confirmed',
    projectStatusChangeRejected: 'Project Rejected',
    projectStatusChangeCheckIn: 'Project Check-In',
    projectStatusChangeCompleted: 'Project Completed',
    projectStatusChangeCancelled: 'Project Cancelled',
    newProjectCreatedFirstAvailableConfirm: 'Project Confirmed',
    ratingReceived: 'New Rating Received',
    newProjectCreatedFirstAvailable: 'New Project Available'
}

export const NOTIFICATION_URL = {
    newProjectCreated: '/user/view-project',
    projectStatusChangeConfirmed: '/user/view-project',
    projectStatusChangeRejected: '/user/view-project',
    projectStatusChangeCheckIn: '/user/view-project',
    projectStatusChangeCompleted: '/user/view-project',
    projectStatusChangeCancelled: '/user/view-project',
    newProjectCreatedFirstAvailableConfirm: '/user/view-project',
    ratingReceived: '/user/view-project',
    newProjectCreatedFirstAvailable: '/user/view-project',
    newMessage: '/user/chat',
    default: '/user/my-projects'
}