import React, { useEffect, useState } from "react";
import IMAGES from "../../../_assets/images/index";
import "../../../_components/uiFix/uiFix.css";

import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getServiceCategories } from "../../../services/api/category.api.service";
import { clearStore, selectCategory } from "../../../_redux/actions";
import { useDispatch } from "react-redux";

const TreatHome = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [services, setServices] = useState([]);
  const [remainingServices, setRemainingServices] = useState([]);

  useEffect(() => {
    let mounted = true;
    clearRecentOrders();
    localStorage.removeItem("redirectUrl");
    getServiceCategories().then((items) => {
      if (mounted && items.status === 200) {
        const neededServices = [
          "floors",
          "kitchen",
          "bathroom",
          "painting",
          "wall_opening",
          "basement",
        ];
        const filteredItems = items.data.filter((item) =>
          neededServices.includes(item.key)
        );
        const remainingItems = items.data.filter(
          (item) => !neededServices.includes(item.key)
        );
        const neededServicesImgs = {
          floors: {
            img: IMAGES.home.wooden,
            url: "/floors/solidhardwood",
          },
          kitchen: {
            img: IMAGES.home.kitchenn,
            url: "/kitchen/type-of-work/1",
          },
          bathroom: {
            img: IMAGES.home.bath,
            url: "/bathroom",
          },
          painting: {
            img: IMAGES.home.painting,
            url: "/painting",
          },
          wall_opening: {
            img: IMAGES.home.wallOpening,
            url: "/wall-opening/size",
          },
          basement: {
            img: IMAGES.home.basement,
            url: "/basement/service-type",
          },
          electric: {
            img: IMAGES.home.Electric,
            url: "/electric/type-of-service",
          },
          plumbing: {
            img: IMAGES.home.Plumbing,
            url: "/plumbing/type-of-service",
          },
          siding: {
            img: IMAGES.home.Siding,
            url: "#",
          },
          pool: {
            img: IMAGES.home.Pool,
            url: "#",
            class: "displayNone",
          },
          tree_services: {
            img: IMAGES.home.Tree_remotion,
            url: "/tree-service/service-type",
          },
          deck: {
            img: IMAGES.home.Deck,
            url: "#",
            class: "displayNone",
          },
          fences: {
            img: IMAGES.home.fencepaint,
            url: "#",
          },
          windows: {
            img: IMAGES.home.Windows,
            url: "/windows/type-of-service",
          },
          demolition_and_hauling: {
            img: IMAGES.home.Demolition_hauling,
            url: "#",
          },
          hvac: {
            img: IMAGES.home.hvac,
            url: "/hvac/type-of-service",
          },
          roofing: {
            img: IMAGES.home.Roofing,
            url: "/roofing/type-of-service",
          },
        };

        const servicesArr = filteredItems.map((item) => {
          return { ...item, ...neededServicesImgs[item.key] };
        });

        const remainingItemsArr = remainingItems.map((item) => {
          return { ...item, ...neededServicesImgs[item.key] };
        });
        setServices(servicesArr.concat(remainingItemsArr));
        setRemainingServices(remainingItemsArr);
      }
    });
    return () => (mounted = false);
  }, []);

  const goToCategory = (service) => {
    const userDetailsParsed = JSON.parse(localStorage.getItem("user"));
    dispatch(selectCategory({ selectedCategory: service?.category }));
    if (userDetailsParsed && userDetailsParsed.userType) {
      props.history.push(service?.url);
    } else {
      localStorage.setItem("redirectUrl", service?.url);
      props.history.push("/role");
    }
  };

  /**
   * Clears the recent orders from store
   * @returns void
   */
  const clearRecentOrders = () => {
    dispatch(clearStore());
  };

  return (
    <div className="home-section" id="quartID" style={{ overflowX: "hidden" }}>
      <div>
        <label className="tyh">
          {t("homePage.treatyourHomeSection.Treat")}
        </label>
        <label className="tyh1">
          {t("homePage.treatyourHomeSection.your_home")}
        </label>
      </div>
      <div
        className={`tblwidth home_servce_grid paddinngtop20 stable_class ${
          show ? "show-all-services" : "show-few-services"
        }`}
      >
        {services.length > 0 ? (
          <div className="homeService_Wrp stable_class">
            {services.map((service, index) =>
              service._id === "61dc6b563494212c81696a89" ||
              service._id === "61e5b08a1a57b60793220fb9" ||
              service._id === "61e5b09c1a57b60793220fba" ? (
                <div key={service.url + index} className={service.class || ""}>
                  <span
                    onClick={() => goToCategory(service)}
                    alt={service.category}
                  >
                    <img src={service.img} className="treatimg" alt="" />
                    <div className="allCenter bold ">
                      <label>{service.category}</label>
                    </div>
                  </span>
                </div>
              ) : (
                <div key={service.url + index} className={service.class || ""}>
                  <span alt={service.category}>
                    <div style={{ position: "relative" }}>
                      <span
                        style={{
                          position: "absolute",
                          zIndex: "50",
                          top: "60px",
                          left: "19px",
                          fontSize: "21px",
                          color: "#000000",
                        }}
                      >
                        Coming soon
                      </span>
                      <img
                        src={service.img}
                        className="treatimg"
                        alt=""
                        style={{
                          filter:
                            "grayscale(100%) brightness(30%) opacity(50%)",
                        }}
                      />
                    </div>
                    <div className="allCenter bold ">
                      <label>{service.category}</label>
                    </div>
                  </span>
                </div>
              )
            )}
          </div>
        ) : null}
      </div>

      <div
        className="stable_class"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <label
          onClick={() =>
            setShow((prevState) => {
              // if (prevState === false) {
              //   setServices(services.concat(remainingServices));
              // } else {
              //   let difference = services.filter(
              //     (x) => !remainingServices.includes(x)
              //   );
              //   setServices(difference);
              // }
              return !show;
            })
          }
          className="show_more_service"
          style={{
            cursor: "pointer",
          }}
          title="Click here !."
        >
          {!show
            ? t("homePage.treatyourHomeSection.See_more_services")
            : t("homePage.treatyourHomeSection.hideServices")}
        </label>
      </div>
    </div>
  );
};
export default withRouter(TreatHome);
