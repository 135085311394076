import React, { useEffect, useState } from "react";

import "../../../_styles/localStyles/typeOfService.css";

import { useTranslation } from "react-i18next";

import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";

import CheckBox from "../../../_components/CheckBox/checkBox";
import { Dialog } from "@material-ui/core";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { Button } from "../../../_components/Button/Button";
import { floorTilesModel } from "../../../_redux/Models/Floor.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const AddOn = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 4;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [selectedAddons, setSelectedAddons] = useState([]);

  const floorState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorTilesModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(calculateTotalCost(floorState.costFor)); // To keep the total price in state

  const [addonOptions, setAddonOptions] = useState([]);
  const [elevatorPrice, setElevatorPrice] = useState([]);
  const [sizePrice, setSizePrice] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const size = parseFloat(floorState.floorSize);
    if (mounted === true && priceDetails) {
      const addOnsArr = priceDetails.addOns.map((op) => {
        return {
          text: t(`tiles.${op.key}`),
          value: op.key,
          price: op.price * 0.3 * size,
        };
      });
      setAddonOptions(addOnsArr);
      setElevatorPrice(priceDetails.elevator);
      setSizePrice(priceDetails.size);
    }
    return () => (mounted = false);
  }, [checkoutModelState, floorState.floorSize, price, t]);

  /**
   * Setting the total price of the selected services
   */
  const setTotalPrice = (selected) => {
    let totalPrice = calculateTotalCost(floorState.costFor);
    let total = selected.reduce((p, c) => p + c.price, 0);
    setPrice(total + totalPrice);
  };

  /**
   * On clicking Yes
   */
  const onYesClick = () => {
    const total = calculateTotalCost(floorState.costFor);
    const servicePrice =
      elevatorPrice.find((el) => el.key === "service").percentage / 100;
    const materialPrice =
      elevatorPrice.find((el) => el.key === "materials").percentage / 100;
    const tileSize = floorState.tileSize;
    const haveOwnTiles = floorState.haveOwnTiles;
    const materialQualityPrice =
      tileSize !== ""
        ? sizePrice.find((op) => op.key === tileSize).materialPrice
        : 0;
    const finalPrice =
      haveOwnTiles === "provide"
        ? total +
          (servicePrice * (total - materialQualityPrice) +
            materialPrice * materialQualityPrice)
        : total + servicePrice * total;

    floorState.needToUseElevator = true;
    floorState.costFor.elevator =
      finalPrice - calculateTotalCost(floorState.costFor);
    dispatch(floorStore(floorState));
    checkoutModelState.totalCost = finalPrice;
    dispatch(checkoutStore(checkoutModelState));
    var step = floorState.priorFloorRemovalRequired ? 5 : 6;
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/tiles/order-summary/${step}`
    );
  };

  const [open, setOpen] = useState(false);

  /**
   * On closing Popup
   */
  const onClose = () => {
    floorState.needToUseElevator = false;
    dispatch(floorStore(floorState));
    var step = floorState.priorFloorRemovalRequired ? 5 : 6;
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/tiles/order-summary/${step}`
    );
  };

  /**
   * Function to save Selected add ons and take user to next step
   * @returns {void}
   */
  const onSaveAddOns = () => {
    floorState.addOns = selectedAddons;
    floorState.costFor.addOns = price - calculateTotalCost(floorState.costFor);
    checkoutModelState.totalCost = price;
    dispatch(floorStore(floorState));
    dispatch(checkoutStore(checkoutModelState));
    setOpen(true);
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <div className="service insideWrp">
      <div>
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className="sevice_title">{t("addOn.mainHeading")}</div>
          <label
            className="sub_heading"
            style={{ marginTop: "20px", fontSize: "14px" }}
          >
            {" "}
            {t("addOn.subHeading1")}
            <label style={{ marginTop: "5px", fontSize: "14px" }}>
              {" "}
              {t("addOn.subHeading2")}
            </label>
          </label>
          <div className="m-t-32">
            {addonOptions.map((item, index) => (
              <label
                key={index}
                className={`floor-selection-radio ${
                  selectedAddons?.includes(item) ? "selected" : ""
                }`}
              >
                <div
                  style={{
                    maxWidth: "200px",
                    width: " 100%",
                    textAlign: "left",
                    lineHeight: "1.5",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span>{item.text}</span>
                  {item.text === "Shoe molding" && (
                    <span style={{ fontSize: "10px", maxWidth: "300px" }}>
                      Smaller and thinner molding than quarter rounds for the
                      joints of your floors with the wall.
                    </span>
                  )}
                </div>
                <div style={{ display: "flex", placeItems: "center" }}>
                  <div style={{ paddingTop: "3px" }}>
                    <img
                      width="15"
                      height="15"
                      src={IMAGES.other.green_plus}
                      alt=""
                    />
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    {`$${formatPrice(item.price)}`}
                  </div>
                </div>
                <input
                  type="checkbox"
                  name={index}
                  value={item.text}
                  checked={
                    selectedAddons.filter((it) => it.value === item.value)
                      .length > 0
                  }
                  onChange={() => {
                    setSelectedAddons((previousState) => {
                      let state;
                      if (
                        previousState.filter((it) => it.value === item.value)
                          .length > 0
                      ) {
                        state = previousState.filter(
                          (i) => i.value !== item.value
                        );
                      } else {
                        state = [...previousState, item];
                      }
                      if (item.value === "shoe_molding") {
                        state = state.filter(
                          (s) => s.value !== "quater_rounds"
                        );
                      } else if (item.value === "quater_rounds") {
                        state = state.filter((s) => s.value !== "shoe_molding");
                      }
                      setTotalPrice(state);
                      return state;
                    });
                  }}
                />
              </label>
            ))}
          </div>
          <Button
            disabled={false}
            buttonLabel={t("addOn.continue")}
            onClick={onSaveAddOns}
          />
        </div>
      </div>
      <Dialog
        onClose={handleClose}
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            borderRadius: "20px",
          },
        }}
      >
        <div className="stepper-dialog">
          <img src={IMAGES.home.elevatorIcon} width="120px" alt="" />
          <div className="stepper-dialog-text">
            Will we need to use an <br /> elevator to access the <br />
            property?
          </div>
          <div className="flex stepper-dialog-footer">
            <div onClick={handleClose}>No</div>
            <div onClick={onYesClick}>Yes</div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
