import React from "react";
import { useTranslation } from "react-i18next";

const SolidHardwoodWhatIsIncluded = () => {
  const { t } = useTranslation();
  return (
    <div>
      <label>
        <strong>{t("floor.whatsIncludedInPackage")}</strong>
      </label>
      <ul>
        <li>Customer support specialist</li>
        <li>Floor preparation</li>
        <li>Roll out underlayment paper</li>
        <li>Floor installation</li>
        <li>Trash and debris removal from job site</li>
        <li>
          1 year comprehensive warranty covering workmanship and materials*
        </li>
        <li>Materials free delivery*</li>
      </ul>
      <div className="profilePadding10"></div>
      <label>
        <strong>{t("floor.howLongTheProcessTakes")}</strong>
      </label>
      <ul>
        <li> Between 2 to 5 days**</li>
      </ul>
      <div className="profilePadding10"></div>
      <span>*Only if acquired through Anyhnb</span>
      <br />
      <span>**Might differ according to size</span>
      <div className="profilePadding10"></div>
    </div>
  );
};

export default SolidHardwoodWhatIsIncluded;
