import React, { useRef, useState, useEffect } from "react";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import IMAGES from "../../_assets/images";
import Calendar from "react-calendar";
import "../../_styles/localStyles/calendar.css";
import "../../_styles/localStyles/floor.css";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { formatPrice } from "../../services/utils/utilFunctions";
import moment from "moment";

function StartingDate(props) {
  const { t } = useTranslation();
  const items = [
    {
      text: "Early morning",
      time: "7am - 9am",
    },
    {
      text: "Late morning",
      time: "9am - 11am",
    },
    {
      text: "Noon",
      time: "12pm - 3pm",
    },
    {
      text: "Afternoon",
      time: "3pm - 6pm",
    },
  ];

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const dateEl = useRef();
  const dispatch = useDispatch();
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [convertedDate, setConvertedDate] = useState("");
  const [calendarOpen, setCalendarOpen] = useState(false);

  const selectDate = (val) => {
    setSelectedDate(val);
    convert(val);
    setCalendarOpen(false);
  };
  const onDatePickerClick = (val) => {
    setCalendarOpen(true);
    // dateEl.current.fo
  };
  function convert(str) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    setConvertedDate([month, day, date.getFullYear()].join("/"));
  }

  const downArrowClick = (val) => {
    setOpen(true);
    // props.onSuccess();
  };

  const saveDate = () => {
    checkoutModelState.projectStartDate = selectedDate;
    checkoutModelState.projectStartTime = selectedTime;
    dispatch(checkoutStore(checkoutModelState));
    // props.history.push("");
  };
  const tomorrow = () => {
    // Get today's date
    let today = new Date();
    // Change the date by adding 1 to it (today + 1 = tomorrow)
    today.setDate(today.getDate() + 1);
    // return yyyy-mm-dd format
    return new Date(today);
  };
  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="m-t-32">
          <div className="floor-sub-title">When is the ideal start date?</div>
          <div
            className="m-t-32"
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            The start date will be confirmed by the contractor
          </div>

          <TextField
            // ref={dateEl}
            style={{ fontStyle: "italic", marginTop: "8px" }}
            variant="standard"
            placeholder="mm/dd/yyyy"
            fullWidth
            type="text"
            value={convertedDate}
            onClick={onDatePickerClick}
            InputProps={{
              style: { padding: "16px" },
              shrink: true,
              startAdornment: (
                <InputAdornment position="start">
                  <img src={IMAGES.home.calendar} alt="" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <img
                    src={IMAGES.home.downArrow}
                    alt=""
                    onClick={downArrowClick}
                  />
                </InputAdornment>
              ),
            }}
          />
          {calendarOpen ? (
            <div
              className="m-t-32"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Calendar
                // ref={dateEl}
                open={open}
                keepmounted
                onChange={(val) => selectDate(val)}
                value={selectedDate}
                minDate={tomorrow()}
              />
            </div>
          ) : null}

          <div className="m-t-32">
            {items.map((item, index) => (
              <label
                key={index}
                className="floor-selection-radio dateSelection d-flex w-100"
                onClick={() => setSelectedTime(item.time)}
              >
                <img
                  className="sun-image"
                  src={
                    index < 1
                      ? IMAGES.home.sun
                      : index < 2
                      ? IMAGES.home.sun2
                      : index < 3
                      ? IMAGES.home.earlyNoon
                      : IMAGES.home.lateNoon
                  }
                  alt=""
                />
                <div className="wish-text">
                  <span>{item.text}</span>
                  <span className="wish-time">{item.time}</span>
                </div>
                <input
                  type="radio"
                  name="selectedWood"
                  value={item.text}
                  //   onChange={onChangeRadio}
                />
              </label>
            ))}
          </div>
          <div className="profilePadding20"></div>
          <Button
            disabled={!convertedDate}
            buttonLabel={t("paintingHomes.continue")}
            onClick={saveDate}
          />
        </div>
      </div>
    </div>
  );
}

export default StartingDate;
