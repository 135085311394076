import React from "react";
// import { Dialog } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Success from "./success";
import Error from "./error";
import "../../_styles/localStyles/signupsignin.css";
import "./DialogNotification.css";
function DialogNotification(props) {
  const { open, handleClose, className, message } = props;
  return (
    <div className="dialog-content-section">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <Typography>
            <h3>{className === "success" ? <Success /> : <Error />}</h3>
          </Typography>

          <DialogContentText id="alert-dialog-description">
            <p className={className}>
              {" "}
              {className === "success" ? "Success" : "Error"}
            </p>

            <em>
              <b>{message || ""}</b>
            </em>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogNotification;
