import React, { useState, useLayoutEffect } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/kitchen.css";
import "../../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { Button } from "../../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { floorCarpetModel } from "../../../_redux/Models/Floor.model";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const Summary = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [orderSummary, setOrderSummary] = useState([]);
  const [comment, setComment] = useState("");

  const floorState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorCarpetModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const price = calculateTotalCost(floorState.costFor); // To keep the total price in state

  useLayoutEffect(() => {
    if (floorState && floorState !== undefined) {
      var currentFloorArr = floorState.currentFloor
        ? floorState.currentFloor
        : [];
      const summary = [
        {
          heading: "Floor size",
          text: `${floorState.floorSize} sqft`,
          img: IMAGES.other.green_plus,
          price: floorState.costFor.carpetService,
        },
        {
          heading: "Current floor removal",
          text: currentFloorArr.map((t, i) => {
            if (t.val > 0) {
              return (
                <span key={i}>
                  {`${t.key} down`} : {t.val}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
          img: IMAGES.other.green_plus,
          price: floorState.costFor.floorRemoval,
        },
        {
          heading: "Provide carpet?",
          text: floorState.needCarpet ? "Yes" : "No",
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading: "Grade of carpet",
          text: floorState.carpetQuality.text,
          img: IMAGES.other.green_plus,
          price: floorState.costFor.carpetGrade,
        },
        {
          heading: "Padding replacement",
          text: floorState.needPaddingReplacement ? "Yes" : "No",
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading: "Grade of padding",
          text: floorState.paddingQuality.replace("-", " ").replace("_", " "),
          img: IMAGES.other.green_plus,
          price: floorState.costFor.padding,
        },
      ];

      setOrderSummary(summary);
    }
  }, [floorState]);

  /**
   * On save Comment
   */
  const saveComment = () => {
    floorState.comments = comment;
    dispatch(floorStore(floorState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    window.location = `/contractor-portfolio/${selectedContractorId}/confirm`;
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("floor.orderSummary")}</label>
        </div>
        <div className="paint_flex_with_left">
          <label className="paint_other_sub_header ">
            {t("floor.carpetEstimation")}
          </label>
        </div>
        <div className="padding10"></div>
        {orderSummary && orderSummary.length
          ? orderSummary.map((item, index) =>
              item.text.length > 0 ? (
                <label
                  key={index}
                  className={`floor-selection-radio`}
                  style={{
                    flexDirection: "column",
                    padding: "10px",
                    alignItems: "start",
                  }}
                >
                  <div className="bold">
                    <strong>{item.heading}</strong>
                  </div>
                  <div
                    className="flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: " 90%",
                    }}
                  >
                    <div
                      style={{
                        width: "150px",
                        textAlign: "left",
                        textTransform: "initial",
                      }}
                    >
                      {item.text}
                    </div>

                    <div style={{ display: "flex", placeItems: "center" }}>
                      <div style={{ paddingTop: "3px" }}>
                        <img
                          width="15"
                          height="15"
                          src={IMAGES.other.green_plus}
                          alt=""
                        />
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {item.price < 0
                          ? `${formatPrice(item.price).replace("-", "-$")}`
                          : `$${formatPrice(item.price)}`}
                      </div>
                    </div>
                  </div>
                </label>
              ) : null
            )
          : null}
        <div className="paintpaddingtop30"></div>
        <table>
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("paintingInterior.ordercommands")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message"
              />
            </td>
          </tr>
        </table>
        <div className="profilePadding40"></div>
        <Button
          buttonLabel={t("paintingHomes.continue")}
          onClick={saveComment}
        />
        <div className="otherspadding25"></div>
        <div className="contactblue_btn ">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td onClick={() => (window.location = "/contact-us")}>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon mb-5">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>
    </div>
  );
};
