import React from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import { Footer } from "../../homeComponents/footerSection/Footer.comp";
import IMAGES from "../../../_assets/images/index";
import RadioButton from "../../../_components/radioButton/radioButton";

function TypeInstallation(props) {
  const btnClick = () => {
    props.history.push(`/floors/solidhardwood/adds-on`);
  };
  return (
    <div>
      <div className="home-section">
        <span
          style={{ fontFamily: "Poppins", color: "#5B75FF", fontSize: "12px" }}
        >
          Floors • Solid hardwood{" "}
        </span>
        <div className="main_button">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={IMAGES.home.step5} width="75px" alt="" />
            <p style={{ fontSize: "15px" }}>
              Type of wood floor/
              <br />
              Size details
            </p>
          </div>
          <div style={{ borderLeft: "0.5px solid #D8D8D8", height: "90px" }}>
            <img
              style={{ marginTop: "10px", marginLeft: "10px" }}
              src={IMAGES.home.piggy}
              width="40px"
              alt=""
            />
            <p style={{ marginTop: "-11px", marginLeft: "10px" }}>$0000</p>
          </div>
        </div>
        <br />
        <br />
        <span
          style={{
            fontFamily: "Poppins",
            color: "black",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Type of installation
        </span>
        <br />
        <span
          style={{ fontFamily: "Poppins", color: "black", fontSize: "16px" }}
        >
          Let us know how you would like the installation
        </span>
        <div className="radio_div">
          <span>Nail down</span>
          <RadioButton />
        </div>
        <div className="radio_div">
          <span>Glue down</span>
          <RadioButton />
        </div>

        <br />
        <br />
        <div
          className="form_btn"
          style={{
            marginBottom: "0px",
            marginTop: "0px",
            backgroundColor: "#D8D8D8",
            height: "12px",
            width: "100%",
            fontSize: "15px",
            borderRadius: "15px",
          }}
          onClick={() => btnClick()}
        >
          <span className="message_span" style={{ color: "#ADADAD" }}>
            Continue to next
          </span>
        </div>
      </div>
    </div>
  );
}

export default TypeInstallation;
