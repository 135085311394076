import React, { useEffect, useState } from "react";

import "../../_styles/localStyles/home.css";
import { NavBar } from "./headerSection/NavBar.comp";
import Header from "./headerSection/Header.comp";
import TreatHome from "./treatHomeSection/TreatHome.comp";
import DreamMakers from "./dreamMakerSection/DreamMakers.comp";
import Projects from "./ourProjectSection/Projects.comp";
import ContactSection from "../homeComponents/contactSection/ContactSection.comp";
import ContactForm from "../homeComponents/contactSection/ContactForm.comp";
import { Footer } from "../../_components/footer/Footer";
import { parseJwt } from "../../services/utils/utilFunctions";
// import { ChatService } from "../../services/chat/chat.service";

function HomeComponent(props) {
  const userDetails = localStorage.getItem("user");
  const auth_token = localStorage.getItem("auth_token");
  const [user, setUser] = useState(null);

  // // Contractors should be redirect to their project section. Not on home page
  const userDetailsParsed = JSON.parse(localStorage.getItem("user"));
  if (userDetailsParsed && userDetailsParsed.userType === "contractor") {
    props.history.push("/contractor-projects");
  }

  useEffect(async () => {
    if (userDetails && userDetails != null) {
      let selectedUser = JSON.parse(userDetails);
      const userdetails = parseJwt(
        selectedUser.token ? selectedUser.token : auth_token
      );

      // const chatService = new ChatService();
      // await chatService.initConversationsClient();

      setUser(userdetails);
    } else {
      setUser(JSON.parse(userDetails));
    }
  }, [userDetails]);

  return (
    <div className="home_Wrapper">
      <NavBar userDetails={user} />
      <Header userDetails={user} />
      <TreatHome userDetails={user} />
      <DreamMakers userDetails={user} />
      <Projects userDetails={user} />
      <ContactSection userDetails={user} />
      <ContactForm userDetails={user} />
      <Footer userDetails={user} />
    </div>
  );
}

export default HomeComponent;
