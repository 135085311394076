import React, { useState, useEffect } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/kitchen.css";
import "../../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import CheckBox from "../../../_components/CheckBox/checkBox";
import { Button } from "../../../_components/Button/Button";

import { checkoutStore, paintingStore } from "../../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateCostFromArr,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { paintingInteriorModel } from "../../../_redux/Models/painting.models";
import { DropDown } from "../../../_components/dropDown/dropDown";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
import { useHistory } from "react-router-dom";

export const InteriorPaintingRoomsAddOns = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 0;
  const roomId =
    props.match && props.match.params ? props.match.params.roomId : false;
  const [progressBar, setProgressBar] = useState(false);

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : paintingInteriorModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const room = state.roomDetails.find((rooms) => rooms.id === roomId);

  if (!room) {
    history.push(`/contractor-portfolio/${selectedContractorId}/painting`);
    window.location.reload();
  }

  const [price, setPrice] = useState(calculateCostFromArr(state.roomDetails)); // To keep the total price in state

  const [addOns, setAddOns] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails && priceDetails.addOns) {
      const haveOwnPaint = state.haveOwnPaint;
      const paintType = room.paintType;
      let totalServicePrice = haveOwnPaint
        ? calculateCostFromArr(state.roomDetails)
        : room.totalGallonsPaint * paintType.price;
      const optionsArr = priceDetails.addOns.map((op) => {
        if (
          ["crow_molding", "baseboards", "windows", "celling"].includes(op.key)
        ) {
          return {
            text: t(`paintingInterior.${op.key}`),
            value: op.key,
            count: 1,
            isChecked: false,
            isSingle: true,
            price: haveOwnPaint
              ? (op.options.find((el) => el.key === "include_paint")
                  .percentage /
                  100) *
                totalServicePrice
              : (op.options.find((el) => el.key === "dont_include_paint")
                  .percentage /
                  100) *
                totalServicePrice,
          };
        } else if (op.key === "doors") {
          return {
            text: t(`paintingInterior.${op.key}`),
            value: op.key,
            count: 1,
            isChecked: false,
            isSingle: false,
            price: haveOwnPaint
              ? op.options.find((el) => el.key === "dont_include_paint").price
              : op.options.find((el) => el.key === "include_paint").price,
          };
        } else {
          return false;
        }
      });
      setAddOns(optionsArr.filter((op) => op !== false));
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state, t]);

  const onSave = () => {
    room.costFor.addOns = price - calculateCostFromArr(state.roomDetails);
    room.addOns = addOns.filter((i) => i.isChecked === true);
    state.roomDetails.filter((bt) => bt.id !== room.id).push(room);
    dispatch(paintingStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));

    const nextRoom = state.roomDetails.find(
      (bt) => bt.roomSize === 0 && bt.roomHeight === 0
    );
    const nextStepValue = parseInt(stepValue) + 1;
    let nextUrl = `/contractor-portfolio/${selectedContractorId}/painting/interior/summary/${nextStepValue}`;
    if (nextRoom) {
      nextUrl = `/contractor-portfolio/${selectedContractorId}/painting/interior/${nextRoom.id}/room-size/${nextStepValue}`;
    }
    history.push(nextUrl);
  };

  function arrowHandler(params, e, index) {
    let updatedState = [...addOns];
    if (params === "plus") {
      updatedState[index].count = updatedState[index].count + 1;
    } else if (updatedState[index].count > 1) {
      updatedState[index].count = updatedState[index].count - 1;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  }

  const calculateTotal = (addOns) => {
    const total = addOns
      .filter((v) => v.isChecked === true)
      .reduce((p, c) => p + c.price * c.count, 0);
    setPrice(calculateCostFromArr(state.roomDetails) + total);
  };

  const toggleCheck = (event, index) => {
    let updatedState = [...addOns];
    if (!updatedState[index].isChecked) {
      updatedState[index].isChecked = event;
    } else {
      updatedState[index].isChecked = !event;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  const onAddOnHandler = (event, index) => {
    let updatedState = [...addOns];
    updatedState[index].count = parseInt(event.target.value);
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("kitchen.addon")}</label>
        </div>
        <div className="paint_flex_with_left paddinngtop20">
          <label className="paint_other_sub_header ">
            {
              "Please select which other services you would like to include for room "
            }
            {`#${room.number}`}
          </label>
        </div>
        <div className="padding10"></div>
        {addOns &&
          addOns !== undefined &&
          addOns.map((item, index) => (
            <div className="paintPadding8 ">
              <div
                className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright "
                onClick={() => toggleCheck(true, index)}
              >
                <div
                  key={index}
                  className="width100 "
                  style={{ justifyContent: "space-between" }}
                >
                  <table>
                    <tr>
                      <td>
                        <label className="paintdiv_txt"> {item.text} </label>
                      </td>
                      <td>
                        <div
                          className="paint_arrowFloat flex"
                          style={{ paddingTop: "10px", alignItems: "center" }}
                        >
                          <img
                            src={IMAGES.other.green_plus}
                            className="kitchenplusimg"
                            alt=""
                          />
                          <label
                            className="paintdiv_txt paddingleft8"
                            style={{ paddingLeft: "7px", paddingTop: "2px" }}
                          >
                            {console.log(item)}
                            {`$${formatPrice(
                              parseInt(item?.count) * parseInt(item?.price)
                            )}`}
                          </label>
                          <div
                            className="paint_arrowFloat"
                            style={{ paddingLeft: "7px" }}
                          >
                            <CheckBox checked={item.isChecked} readOnly />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="paintPadding8 ">
                {item.isChecked && !item.isSingle ? (
                  <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
                    <table className="paint_table100">
                      <tr>
                        <td>
                          {" "}
                          <div className="typeofTxt">
                            <DropDown
                              callback={(event) => onAddOnHandler(event, index)}
                              length={50}
                            />
                            {/* <input
                              type="number"
                              onChange={(e) =>
                                e.target.value > 0
                                  ? onAddOnHandler(e, index)
                                  : null
                              }
                              value={item.count}
                              className=" paintupDownheight"
                            /> */}
                          </div>
                        </td>
                        {/* <td
                          className="paint_arrowFloat otherspadding15"
                          style={{ width: "15px" }}
                        >
                          <div
                            className="paint_arrowHeight cursor_hand"
                            onClick={(e) => arrowHandler("plus", e, index)}
                          >
                            <img src={IMAGES.other.vectorUP} alt="" />
                          </div>
                          <div
                            className="paint_arrowHeight cursor_hand"
                            onClick={(e) => arrowHandler("minus", e, index)}
                          >
                            <img
                              className="paint_arrowpadd_bottom"
                              src={IMAGES.other.vectorDown}
                              alt=""
                            />
                          </div>
                        </td> */}
                      </tr>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        <div className="profilePadding8"></div>
        <Button
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => onSave()}
        />
      </div>{" "}
    </div>
  );
};
