import React, { useEffect, useState } from "react";
import IMAGES from "../../../_assets/images";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { parseJwt } from "../../../services/utils/utilFunctions";
import axiosInstance from "../../../services/api/config/api.config";

const BankDetails = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [bankDetails, setBankDetails] = useState(null);

  const getBankDetails = async () => {
    try {
      var res = await axiosInstance.get(`/contractor/bank-details`);
      if (res && res.data?.code === 200) {
        console.log(res.data);
        setBankDetails(res.data.bankDetails);
      }
    } catch (err) {
      //not updated BankDetails
    }
  };
  useEffect(() => {
    getBankDetails();
  }, []);
  return (
    <>
      <div
        className="custom-header d-flex align-items-center px-2 px-md-5"
        style={{ height: "90px" }}
      >
        <div className="profile-head w-100">
          <img
            onClick={() => history.goBack()}
            className="close-icon"
            src={IMAGES.home.backIcon}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div
        className="verify-mobile-modal p-0 pt-5 px-3 px-md-5 payments-received d-flex flex-column mx-auto"
        style={{ maxWidth: "800px" }}
      >
        <img src={IMAGES.home.bankIcon} height="50" width="50" />
        <h1 className="verify-head">{t("bankDetails.head")}</h1>
        <p className="saved">{t("bankDetails.saved")}</p>
        <div className="bank-data">
          <div className="labels d-none d-md-block">
            <p>{t("bankDetails.accountName")}</p>
            <p>{t("bankDetails.accountNumber")}</p>
            <p>{t("bankDetails.routing")}</p>
          </div>
          <div className="values d-none d-md-block">
            <p>{bankDetails ? bankDetails.accountName : "Not Updated"}</p>
            <p>{bankDetails ? bankDetails.accountNumber : "Not Updated"}</p>
            <p>{bankDetails ? bankDetails.routingNumber : "Not Updated"}</p>
          </div>
          <div className="d-block d-md-none">
            <div className="alternate d-flex flex-column mb-3">
              <p>{t("bankDetails.accountName")}</p>
              <p>{t("bankDetails.accountName")}</p>
            </div>
            <div className="alternate d-flex flex-column mb-3">
              <p>{t("bankDetails.accountNumber")}</p>
              <p>{t("bankDetails.accountNumber")}</p>
            </div>
            <div className="alternate d-flex flex-column mb-3">
              <p>{t("bankDetails.routing")}</p>
              <p>{t("bankDetails.routing")}</p>
            </div>
          </div>
        </div>
        <button onClick={() => history.push("/bank-details/edit")}>
          {t("bankDetails.update")}
        </button>
      </div>
    </>
  );
};

export default BankDetails;
