import React from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/paint.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import { t } from "i18next";
export const Letstalkother = (props) => {
  const { t } = useTranslation();
  const { history } = props;

  const Pickcolorroom2Click = () => {
    // history.push("/painting/interior/Add-on");
  };

  return (
    <div>
      <div className="paint-home-section">
        <div className="paint_flex_with_left ">
          <label className="paint_header">{t("paintingother.letstalk")}</label>
        </div>

        <div className="otherspadding25">
          <label className="paint_other_sub_header">
            {t("paintingother.letusknow")}
          </label>
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            // onChange={(event) => onChangeHandler(event, "password")}
            // value={signupState.password.value}
            // error={signupState.password.error}
            // type={passwordShown ? "text" : "password"}
            placeholder={t("paintingother.plzname")}
            label={t("paintingother.fullname")}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            // onChange={(event) => onChangeHandler(event, "password")}
            // value={signupState.password.value}
            // error={signupState.password.error}
            // type={passwordShown ? "text" : "password"}
            placeholder={t("paintingother.plzemail")}
            label={t("paintingother.email")}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            // onChange={(event) => onChangeHandler(event, "password")}
            // value={signupState.password.value}
            // error={signupState.password.error}
            // type={passwordShown ? "text" : "password"}
            placeholder={t("paintingother.plzphone")}
            label={t("paintingother.phone")}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            // onChange={(event) => onChangeHandler(event, "password")}
            // value={signupState.password.value}
            // error={signupState.password.error}
            // type={passwordShown ? "text" : "password"}
            placeholder={t("other.plaadd")}
            label={t("other.address")}
          />
        </div>
        <div className="otherspadding25"></div>
        <label className="summaryDivheader1">
          {t("paintingother.describe")}
        </label>
        <div className="commandspadding"></div>
        <textarea
          type="text"
          placeholder={t("other.desc")}
          className="paintsummaryCommands message"
        />

        <label
          onClick={() => Pickcolorroom2Click()}
          className="paint_home_form_btn cursor_hand"
        >
          {t("paintingHomes.continue")}
        </label>
      </div>
    </div>
  );
};
