import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { wallOpeningModel } from "../../_redux/Models/wallOpening.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, wallOpeningStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const OpeningType = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 5;
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [value, setValue] = useState("");

  const wallOpeningState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.wallPayload
      ? state.buttonReducer.wallPayload
      : wallOpeningModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(
    calculateTotalCost(wallOpeningState.costFor)
  ); // To keep the total price in state

  const [loadBearingPrice, setLoadBearingPrice] = useState({ percentage: 0 }); // To keep the wall size in state

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails) {
      setLoadBearingPrice(priceDetails.loadBearingWall);
    }
    return () => (mounted = false);
  }, [checkoutModelState]);

  // To show available options
  let tPrice = calculateTotalCost(wallOpeningState.costFor);
  const availableOptions = [
    {
      text: t("wallOpening.exposeHeader"),
      value: "Expose header",
      price: 0,
    },
    {
      text: t("wallOpening.flashWithCeleing"),
      value: "Flash with the ceiling",
      price: (tPrice * loadBearingPrice.percentage) / 100,
    },
  ];

  const calculatePrice = (val) => {
    let tPrice = calculateTotalCost(wallOpeningState.costFor);
    if (val === "Flash with the ceiling") {
      tPrice = tPrice + (tPrice * loadBearingPrice.percentage) / 100;
    }
    setPrice(tPrice);
    return tPrice;
  };

  /**
   * Function to save Selected value and take user to next step
   * @returns {void}
   */
  const onSaveValue = () => {
    wallOpeningState.openingType = value;
    dispatch(wallOpeningStore(wallOpeningState));
    wallOpeningState.costFor.openingIncrement =
      price - calculateTotalCost(wallOpeningState.costFor);
    dispatch(wallOpeningStore(wallOpeningState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/wall_opening/addons/6`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("wallOpening.openingType")}
            </label>
          </div>
          <div className="padding10"></div>
          {availableOptions.map((item, index) => (
            <div
              className={`floor-selection-radio ${
                value === item?.value ? "selected" : ""
              }`}
              onClick={() => {
                setValue(item.value);
                const prc = calculatePrice(item.value);
                onSaveValue(item?.value, prc);
              }}
            >
              <div
                key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="paintdiv_txt"
                  style={{
                    width: "175px",
                  }}
                >
                  {item.text}{" "}
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
