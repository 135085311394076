import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, hvacStore } from "../../_redux/actions";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { hvacModel } from "../../_redux/Models/hvac.model";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const TypeOfHvacUnit = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const stepValue = 2;
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);
  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.hvacPayload
      ? state.buttonReducer.hvacPayload
      : hvacModel
  );

  const [option, setOption] = useState(""); // To keep the basement size in state
  const price = calculateTotalCost(state.costFor); // To keep the total price in state

  const onSave = (val) => {
    state.typeOfUnit = val;
    dispatch(hvacStore(state));
    if (state.serviceType === "replacement") {
      history.push(
        `/contractor-portfolio/${selectedContractorId}/hvac/size-details/5`
      );
    }
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left mb-3">
            <label className="paint_header" style={{ fontSize: "19px" }}>
              {"What type of unit is required?"}
            </label>
          </div>
          <div
            className={`floor-selection-radio ${
              option === "heat-pump" ? "selected" : ""
            }`}
          >
            <div
              onClick={() => {
                setOption("heat-pump");
                onSave("heat-pump");
              }}
              // key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Heat pump (all electric)</label>
            </div>
          </div>

          <div
            className={`floor-selection-radio ${
              option === "gas-furnace" ? "selected" : ""
            }`}
          >
            <div
              onClick={() => {
                setOption("gas-furnace");
                onSave("gas-furnace");
              }}
              // key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Gas furnace</label>
            </div>
          </div>

          <div
            className={`floor-selection-radio ${
              option === "not-sure" ? "selected" : ""
            }`}
          >
            <div
              onClick={() => {
                setOption("not-sure");
                onSave("not-sure");
              }}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">{"I’m not sure"}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
