import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import IMAGES from "../../_assets/images";
import { Button } from "../../_components/Button/Button";
import { Footer } from "../../_components/footer/Footer";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import { bathroomStore, checkoutStore } from "../../_redux/actions";
import { bathroomFlowModel } from "../../_redux/Models/bathroom.model";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const NumberOfBathrooms = (props) => {
  const stepValue = 1;
  const { t } = useTranslation();
  const { history } = props;
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;

    if (
      mounted === true &&
      selectedService?._id &&
      selectedService?.categoryId
    ) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          checkoutModelState.priceDetails = response.data.details;
          checkoutModelState.totalCost = 0;
          dispatch(checkoutStore(checkoutModelState));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch]);

  const [numberOfBathrooms, setNumberOfBathrooms] = useState(1);

  const onSave = () => {
    const bathroomsDetails = [];
    for (let index = 0; index < numberOfBathrooms; index++) {
      var t = {
        id: Math.random().toString().replace(".", ""),
        number: index + 1,
        size: 0,
        haveOwnMaterial: false,
        requireDemolition: "",
        requireBuildUpWalls: "",
        showerDoorAvailability: "",
        showerDoorTypeNeeded: "",
        addOns: [],
        costFor: {
          size: 0,
          material: 0,
          demolition: 0,
          buildUpWall: 0,
          showerDoor: 0,
          addOns: 0,
        },
      };
      bathroomsDetails.push(t);
    }

    bathroomFlowModel.bathroomsDetails = bathroomsDetails;
    dispatch(bathroomStore(bathroomFlowModel));
    const firstId = bathroomsDetails[0].id;
    history.push(
      `/contractor-portfolio/${selectedContractorId}/bathroom/${firstId}/size/2`
    );
  };
  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("Bathroom.bathrooms")}</label>
        </div>
        <div className="mt-3">
          <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding optionElectric">
            <select
              defaultValue={"1"}
              onChange={(e) => {
                if (e.target.value) {
                  setNumberOfBathrooms(e.target.value);
                }
              }}
              className="w-100"
            >
              <option value="" disabled>
                Select
              </option>
              <option key={"1"} value={1}>
                1
              </option>
              <option key={"2"} value={2}>
                2
              </option>
              <option key={"3"} value={3}>
                3
              </option>
              <option key={"4"} value={4}>
                4
              </option>
              <option key={"5"} value={5}>
                5
              </option>
              <option key={"6"} value={6}>
                6
              </option>
              <option key={"7"} value={7}>
                7
              </option>
              <option key={"8"} value={8}>
                8
              </option>
              <option key={"9"} value={9}>
                9
              </option>
              <option key={"10"} value={10}>
                10
              </option>
            </select>
          </div>
        </div>
        <div className="pb-3"></div>
        <Button
          disabled={!numberOfBathrooms}
          buttonLabel={t("paintingHomes.continue")}
          onClick={onSave}
        />
      </div>{" "}
    </div>
  );
};
