export var paintingInteriorModel = {
  flowName: "painting-interior",
  haveOwnPaint: false,
  typeOfWork: "",
  noOfRooms: 0,
  totalGallonsPaint: 0,
  size: "",
  roomDetails: [],
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  costFor: {
    size: 0,
    typeOfWork: 0,
    typeOfPaint: 0,
    coats: 0,
    addOns: 0,
    paint: 0,
    coat: 0,
    primer: 0,
  },
};
export var exteriorModel = {
  flowName: "exterior",
  serviceType: "exterior",
  size: "",
  haveOwnPaint: false,
  notSureAboutPaint: false,
  needPrimerCoat: false,
  paintType: null,
  coats: 1,
  houseMaterial: "",
  brickPercentage: "",
  sidingPercentage: "",
  paintDetails: {
    brandName: "",
    colorName: "",
    paintCode: "",
  },
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  addOns: [],
  costFor: {
    exteriorMaterial: 0,
    paint: 0,
    coat: 0,
    primer: 0,
    addOns: 0,
  },
};
export var deckModel = {
  flowName: "deck",
  haveOwnPaint: false,
  size: "",
  deckCoverORroof: "",
  paintDetails: {
    brandName: "",
    colorName: "",
    paintCode: "",
    notSureAboutPaint: false,
    needPrimercoat: false,
    typeofPaint: "",
    coats: "0",
  },
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  addons: {
    Molding: false,
    Quarterrounds: false,
    Refinishing: false,
    Baseboards: false,
  },
  costFor: {
    typeOfFinish: 0,
    deckOrRoof: 0,
  },
};
export var fenceModel = {
  flowName: "fence",
  haveOwnPaint: false,
  size: "",
  typeOfFinish: "",
  paintDetails: {
    brandName: "",
    colorName: "",
    paintCode: "",
    notSureAboutPaint: false,
    needPrimercoat: false,
    typeofPaint: "",
    coats: "0",
  },
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  addons: {
    Molding: false,
    Quarterrounds: false,
    Refinishing: false,
    Baseboards: false,
  },
  costFor: {
    typeOfFinish: 0,
  },
};
