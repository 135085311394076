import React, { useLayoutEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";

import { useDispatch, useSelector } from "react-redux";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { kitchenModel } from "../../_redux/Models/Kitchen.model";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { checkoutStore, kitchenStore } from "../../_redux/actions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useHistory } from "react-router-dom";
export const Kitchensummary = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const kitchenModelState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.kitchenPayload
      ? state.buttonReducer.kitchenPayload
      : kitchenModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const price = calculateTotalCost(kitchenModelState.costFor); // To keep the total price in state

  const [orderSummary, setOrderSummary] = useState([]);
  const [comment, setComment] = useState("");

  useLayoutEffect(() => {
    if (kitchenModelState && kitchenModelState !== undefined) {
      const summary = [
        {
          heading: t("kitchen.size"),
          text: `${
            kitchenModelState.size === "small"
              ? "Small (up to 70ft)"
              : kitchenModelState.size === "medium"
              ? "Medium (up to 140ft)"
              : "Large (more than 140ft)"
          }`,
          img: IMAGES.other.green_plus,
          price: kitchenModelState.costFor.size,
        },
        {
          heading: t("kitchen.demolitionRequired"),
          text: `${kitchenModelState.demolitionRequired.value}`,
          img: IMAGES.other.green_plus,
          price: kitchenModelState.costFor.demolition,
        },
        {
          heading: t("kitchen.typeOfCabinets"),
          text: `${kitchenModelState.typeOfCabinet}`,
          img: IMAGES.other.green_plus,
          price: kitchenModelState.costFor.cabinet,
        },

        {
          heading: t("kitchen.counterTops"),
          text: `${kitchenModelState.counterTop.text}`,
          img: IMAGES.other.green_plus,
          price: kitchenModelState.costFor.counterTop,
        },
        {
          heading: t("kitchen.counterTopShape"),
          text: `${kitchenModelState.shape}`,
          img: IMAGES.other.green_plus,
          price: 0.0,
        },
        {
          heading: t("kitchen.crownMolding"),
          text: `${kitchenModelState.crownMolding.value}`,
          img: IMAGES.other.green_plus,
          price: kitchenModelState.costFor.crownMolding,
        },
        {
          heading: "Add on´s",
          text: kitchenModelState.addOns.map((addOn, i) => {
            const add = addOn.text;
            if (add !== "") {
              return (
                <span key={i}>
                  {`${add} (${addOn.count})`}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
          img: IMAGES.other.green_plus,
          price: kitchenModelState.costFor.addOns,
        },
      ];

      setOrderSummary(summary);
    }
  }, [kitchenModelState, t]);

  /**
   * On save Comment
   */
  const onSave = () => {
    kitchenModelState.comments = comment;
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(kitchenStore(kitchenModelState));
    window.location = `/contractor-portfolio/${selectedContractorId}/confirm`;
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("kitchen.osummary")}</label>
        </div>
        <div className="paint_flex_with_left">
          <label className="paint_other_sub_header ">
            {t("kitchen.kitchen")}
          </label>
        </div>
        <div className="padding10"></div>
        {orderSummary.map((item, index) =>
          item.text.length > 0 ? (
            <div key={index}>
              <label
                className="floor-selection-radio"
                style={{
                  flexDirection: "column",
                  padding: "10px",
                  alignItems: "start",
                }}
              >
                <div className="bold">{item.heading}</div>
                <div
                  className="flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="pc_txt"
                    style={{
                      width: "150px",
                      textAlign: "left",
                      textTransform: "initial",
                    }}
                  >
                    {item.text}
                  </div>

                  <div style={{ display: "flex", placeItems: "center" }}>
                    <div style={{ paddingTop: "3px" }}>
                      <img
                        width="15"
                        height="15"
                        src={IMAGES.other.green_plus}
                        alt=""
                      />
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      {item.price < 0
                        ? `${formatPrice(item.price).replace("-", "-$")}`
                        : `$${formatPrice(item.price)}`}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          ) : null
        )}
        <div className="paintpaddingtop30"></div>
        <table>
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("paintingInterior.ordercommands")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message"
              />
            </td>
          </tr>
        </table>
        <div className="profilePadding40"></div>
        <Button
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => onSave()}
        />
        <div className="contactblue_btn mb-5 mt-3">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon mb-3">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>
    </div>
  );
};
