import React from "react";
import "./progressProfile.css";
function ProgressbarProfile(props) {
  const { progressValue } = props;
  return (
    
    <div className="divOutborder">
      <div 
role="progressProfile"
aria-valuenow="65"
aria-valuemin="0"
aria-valuemax="100"
style={{ "--value": `${progressValue}`,placeItems:"center",display:"grid"}}
>
      <label className="profileComtxt">Profile complete</label>
         
      </div></div>
     

  );
}

export default ProgressbarProfile;
