import React from "react";
import { Switch } from "@mui/material";

import "./toggle.css";

import toggleOn from "../../_assets/images/other-images/svgicons/toggle-on.svg";
import toggleOff from "../../_assets/images/other-images/svgicons/toggle-off.svg";

const Toggle = ({ props }) => {
  const { isChecked, onClick } = props;
  return (
    <>
      <label class="switch">
        <input type="checkbox" checked={isChecked} />
        <span class="slider round"></span>
      </label>
    </>
  );
};

export default Toggle;
