import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { bathroomStore, checkoutStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { bathroomFlowModel } from "../../_redux/Models/bathroom.model";
import {
  calculateBathroomCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const HaveBathroomMaterials = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;
  const bathroomId =
    props.match && props.match.params ? props.match.params.bathroomId : false;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.bathroomPayload
      ? state.buttonReducer.bathroomPayload
      : bathroomFlowModel
  );

  const bathroom = state.bathroomsDetails.find(
    (baths) => baths.id === bathroomId
  );

  if (!bathroom) {
    history.push("/bathroom");
    window.location.reload();
  }

  const [price, setPrice] = useState(
    calculateBathroomCost(state.bathroomsDetails)
  ); // To keep the total price in state

  const [materialsOptions, setMaterialsOptions] = useState([]);
  const [material, setMaterial] = useState("");

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const size = bathroom.size;
    const materialPrice = priceDetails.materials.find(
      (m) => m.key === size
    ).price;
    if (mounted === true && priceDetails) {
      const materials = [
        {
          text: t("Bathroom.own"),
          key: "own",
          price: 0,
        },
        {
          text: t("Bathroom.provide"),
          key: "provide",
          price: materialPrice,
        },
      ];

      const optionsArr = materials.map((op) => {
        return {
          text: t(`Bathroom.${op.key}`),
          value: op.key,
          price: op.price,
        };
      });
      setMaterialsOptions(optionsArr);
    }
    return () => (mounted = false);
  }, [bathroom.size, checkoutModelState, t]);

  const onSave = (value) => {
    bathroom.costFor.material =
      price - calculateBathroomCost(state.bathroomsDetails);
    bathroom.haveOwnMaterial = value === "own";
    state.bathroomsDetails.filter((bt) => bt.id !== bathroom.id).push(bathroom);
    dispatch(bathroomStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/bathroom/${bathroomId}/need-demolition/4`
    );
  };

  const renovation = [
    {
      text1: t("Bathroom.need"),
    },
    {
      text: t("Bathroom.tiles"),
    },
    {
      text: t("Bathroom.backerboards"),
    },
    {
      text: t("Bathroom.cements"),
    },
    {
      text: t("Bathroom.water"),
    },
    {
      text: t("Bathroom.fixtures"),
    },
    {
      text: t("Bathroom.floor"),
    },
    {
      text: t("Bathroom.grout"),
    },
  ];

  return (
    <div className="insideWrp overflow-auto mb-4">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("Bathroom.install")}</label>
        </div>
        <div className="paint_flex_with_left ">
          <label className="paint_other_sub_header ">
            {t("Bathroom.contractor")}
          </label>
        </div>
        <div className="padding10"></div>
        {materialsOptions.map((item, index) => (
          <div key={index} className="">
            <div
              className={`floor-selection-radio ${
                material === item?.value ? "selected" : ""
              }`}
              onClick={() => {
                const total = calculateBathroomCost(state.bathroomsDetails);
                setMaterial(item.value);
                setPrice(total + item.price);
                onSave(item?.value);
              }}
            >
              <div
                key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="paintdiv_txt"
                  style={{
                    width: "65%",
                  }}
                >
                  {item.text}
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "35%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="mb-4">
          {renovation.map((item, index) => (
            <div key={Math.random()} className="paintPadding8 ">
              <div>
                <div key={index} className="width100">
                  <label className="paintdiv_txt"> {item.text1} </label>
                  <label className="paintdiv_txt paddingleft8">
                    {item.text}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
