import { AddOn } from "./AddOn";
import { OrderSummary } from "./OrderSummary";
import { TypeOfService } from "./TypeOfService";
import { BasementElectrical } from "./basementElectrical";
import { BasementHeight } from "./basementHeight";
import { BasementPlumbing } from "./basementPlumbing";
import { BasementSize } from "./basementSize";
import { BasementSumpPump } from "./basementSumpPump";
import { PersonalInfo } from "./personalInfo";

export const basementRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/basement/service-type",
    component: TypeOfService,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/basement/size/:stepValue",
    component: BasementSize,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/basement/height/:stepValue",
    component: BasementHeight,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/basement/plumbing/:stepValue",
    component: BasementPlumbing,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/basement/sump-pump/:stepValue",
    component: BasementSumpPump,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/basement/electrical/:stepValue",
    component: BasementElectrical,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/basement/addons/:stepValue",
    component: AddOn,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/basement/personal-info/:stepValue",
    component: PersonalInfo,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/basement/order-summary/:stepValue",
    component: OrderSummary,
  },
];
