import React, { useLayoutEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import "../../_styles/globalStyles/global.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, wallOpeningStore } from "../../_redux/actions";
import { wallOpeningModel } from "../../_redux/Models/wallOpening.model";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const OrderSummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 8;
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [orderSummary, setOrderSummary] = useState([]);
  const [comment, setComment] = useState("");

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const payload = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.wallPayload
      ? state.buttonReducer.wallPayload
      : wallOpeningModel
  );
  const price = calculateTotalCost(payload.costFor); // To keep the total price in state

  /**
   * On save Comment
   */
  const saveComment = () => {
    payload.comments = comment;
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(wallOpeningStore(payload));
    window.location = `/contractor-portfolio/${selectedContractorId}/confirm`;
  };

  useLayoutEffect(() => {
    if (payload && payload !== undefined) {
      const summary = [
        {
          heading: t("wallOpening.openingSize"),
          text: `${payload.size} lnft`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.linear,
        },
        {
          heading: t("wallOpening.height"),
          text: `${payload.height} lnft`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.height,
        },
        {
          heading: t("wallOpening.isItLoadBearingWall"),
          text: `${payload.isItLoadBearingWall}`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.loadBearing,
        },

        {
          heading: t("wallOpening.hasBasement"),
          text: `${payload.hasBasement}`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.basementIncrement,
        },
        {
          heading: t("wallOpening.openingType"),
          text: `${payload.openingType}`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.openingIncrement,
        },
        {
          heading: "Add on´s",
          text: payload.addOns.map((addOn) => {
            const add = addOn.text;
            if (add !== "") {
              return (
                <span key={Math.random()}>
                  {add}
                  <br />
                </span>
              );
            } else {
              return false;
            }
          }),
          img: IMAGES.other.green_plus,
          price: payload.costFor.addOns,
        },
      ];

      setOrderSummary(summary);
    }
  }, [payload, t]);

  return (
    <div className="service insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="sevice_title">{t("orderSummary.mainHeading")} </div>
        <label className="sub_heading" style={{ marginTop: "7px" }}>
          {t("wallOpening.estimation")}
        </label>
        <br />
        <div className="pb-3"></div>
        {orderSummary.map((item, index) =>
          item.text.length > 0 ? (
            <div key={index}>
              <label
                className="floor-selection-radio"
                style={{
                  flexDirection: "column",
                  padding: "10px",
                  alignItems: "start",
                }}
              >
                <div className="bold">{item.heading}</div>
                <div
                  className="flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: " 100%",
                  }}
                >
                  <div
                    className="SummeryTitle"
                    style={{
                      width: "150px",
                      textAlign: "left",
                      textTransform: "initial",
                    }}
                  >
                    {item.text}
                  </div>

                  <div style={{ display: "flex", placeItems: "center" }}>
                    {item.price > 0 && (
                      <div style={{ paddingTop: "3px" }}>
                        <img
                          width="15"
                          height="15"
                          src={IMAGES.other.green_plus}
                          alt=""
                        />
                      </div>
                    )}
                    <div style={{ paddingLeft: "10px" }}>
                      {item.price < 0
                        ? `${formatPrice(item.price).replace("-", "-$")}`
                        : `$${formatPrice(item.price)}`}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          ) : null
        )}
        <table>
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("paintingInterior.ordercommands")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message  mb-3"
              />
            </td>
          </tr>
        </table>
        <Button
          disabled={false}
          buttonLabel={t("orderSummary.continue")}
          onClick={saveComment}
        />
        <div className="contactblue_btn mb-5 mt-3">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td onClick={() => (window.location = "/contact-us")}>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon mb-3">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>
    </div>
  );
};
