import { atom } from "recoil";

export const latLonState = atom({
  key: "latLonState",
  default: null,
});

export const searchState = atom({
  key: "searchState",
  default: "",
});

export const languageAtom = atom({
  key: "languageState",
  default: "en",
});
