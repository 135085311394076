import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import IMAGES from "../../_assets/images/index";
import "../../_styles/localStyles/home.css";
import "../../_styles/localStyles/drawer.css";
import { parseJwt } from "../../services/utils/utilFunctions";
import { signupModel } from "../../_redux/Models/auth.models";
import { continuetoNext } from "../../_redux/actions";
import { FireBase } from "../../services/firebase/firebase.service";
import { NavBar } from "../../_container/homeComponents/headerSection/NavBar.comp";

function HeaderMenu(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();

  const firebase = new FireBase();

  const drawer = [
    {
      text: t("homeDrawer.home"),
      path: "/",
    },
    {
      text: t("homeDrawer.profile"),
      path: "",
    },
    {
      text: t("homeDrawer.notifications"),
      path: "/user/notifications",
    },
    {
      text: t("homeDrawer.project"),
      path: "/user/my-projects",
    },
    {
      text: t("homeDrawer.chat"),
      path: "/user/chat",
    },
    {
      text: t("homeDrawer.wallet"),
      path: "/wallet",
    },
    {
      text: t("homeDrawer.contractor"),
      path: "/signup",
    },
    {
      text: t("homeDrawer.terms"),
      path: "/terms-and-condition",
    },
    {
      text: t("homeDrawer.contact"),
      path: "/contact-us",
    },
    {
      text: t("homeDrawer.signout"),
      path: "",
    },
  ];
  const navBarHandler = async (item) => {
    if (item.text === t("homeDrawer.profile")) {
      const user = parseJwt(localStorage.getItem("auth_token"));
      if (user && user.userType === "contractor") {
        history.push(`/contractor/profile`);
      } else if (user && user.userType === "owner") {
        history.push(`/owner/my-profile`);
      } else {
        history.push("/role");
      }
    } else if (item.text === t("homeDrawer.project")) {
      const userDetails = JSON.parse(localStorage.getItem("user"));
      if (userDetails.userType == "owner") {
        history.push("/user/my-projects");
      } else if (userDetails.userType == "contractor") {
        history.push("/contractor-projects");
      }
    } else if (item.text === "Signout") {
      await localStorage.clear("user");
      if (firebase) firebase.deleteToken();
      const element = document.getElementById("toggle");
      element.checked = false;
      history.push("/");
    } else if (item.path === "/wallet") {
      history.push("/wallet");
    } else if (item.path === "signup") {
      signupModel.signuserType = "contractor";
      dispatch(continuetoNext(signupModel));
      history.push("/signup");
    } else if (item.text === "Home") {
      history.push("/");
    } else {
      history.push(item.path);
    }
  };
  return <NavBar />;
  // return (
  //   <div>
  //     <div className="home-section">
  //       <div className="flex justify_between">
  //         <span className="all_header_txt">Anyhnb</span>
  //         <input id="toggle" type="checkbox"></input>
  //         <label htmlFor="toggle" className="header hamburger">
  //           <img
  //             className="gearbox_width"
  //             src={IMAGES.home.btn}
  //             alt="home img"
  //           />
  //           {showDot > 0 ? (
  //             <div className="header dot overHamburger"></div>
  //           ) : null}
  //         </label>
  //         <div className="nav">
  //           <div className="nav-wrapper">
  //             <nav>
  //               <div className="home-sectionpadd20 ">
  //                 <table style={{ width: "100%", placeItems: "center" }}>
  //                   <tbody>
  //                     <tr>
  //                       <td>
  //                         <label
  //                           className="signup_page_anyhnb_logo"
  //                           //   onClick={() => window.location.replace("/")}
  //                         >
  //                           {t("signUp.mainHeading")}
  //                         </label>
  //                       </td>
  //                       <td style={{ float: "right", paddingTop: "15px" }}>
  //                         <label htmlFor="toggle" className="hamburger">
  //                           <img
  //                             id="cl"
  //                             src={IMAGES.other.closeVector}
  //                             alt=""
  //                             width="30px"
  //                             height="30px"
  //                             style={{ cursor: "pointer" }}
  //                             //   onClick={() => backClick()}
  //                             // onClick={mobilecloseClick}
  //                           />
  //                         </label>
  //                       </td>
  //                     </tr>
  //                   </tbody>
  //                 </table>
  //                 <div className="home-sectionpadd20">
  //                   {drawer.map((item, index) => {
  //                     return (
  //                       <div key={index} className="dotPosition">
  //                         <div
  //                           onClick={() => navBarHandler(item)}
  //                           className="homeDrawertxt"
  //                         >
  //                           {item.path === "/user/chat" && showDot > 0 ? (
  //                             <div className="dot overHamburger"></div>
  //                           ) : null}
  //                           <Link to={item.path} style={{ color: "black" }}>
  //                             <label htmlFor="toggle" className="hamburger">
  //                               {" "}
  //                               {item.text}
  //                             </label>
  //                           </Link>
  //                         </div>
  //                       </div>
  //                     );
  //                   })}
  //                 </div>
  //               </div>
  //             </nav>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default HeaderMenu;
