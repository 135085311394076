import React from "react";
import { Footer } from "../../../_components/footer/Footer";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";

import IMAGES from "../../../_assets/images/index";
import { withTranslation } from "react-i18next";

import { checkoutStore } from "../../../_redux/actions";
import { connect } from "react-redux";
import { Button } from "../../../_components/Button/Button";
import { getData } from "../../../services/localStorage/localStorage.service";
import { createChannel } from "../../../services/api/chat.service";
import { LOCAL_STORAGE } from "../../../_configs/constants.config";

import {
  getBasementOrderSummary,
  getBathroomOrderSummary,
  getCarpetOrderSummary,
  getDeckOrderSummary,
  getEngineeredOrderSummary,
  getEpoxyOrderSummary,
  getExteriorOrderSummary,
  getFenceOrderSummary,
  getHvacOrderSummary,
  getKitchenOrderSummary,
  getLaminatedOrderSummary,
  getTilesOrderSummary,
  getWallOpeningOrderSummary,
} from "../../../_components/OrderSummaries";
import { formatPrice, parseJwt } from "../../../services/utils/utilFunctions";
import { getContractor } from "../../../services/api/contractor.service";
import { getSolidHardwoodOrderSummary } from "../../../_components/OrderSummaries/index";
import { getOwnerProjectById } from "../../../services/api/owner.service";
import { OrderSummaryForAllFlow } from "../../../_components/OrderSummaries/OrderSummaryForAllFlow";
import UserCancelProjectButton from "./UserCancelProjectButton";
import { getprojectheader } from "../../Contractor/MyProjects/contractorUtils";

class ProjectDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      project: null,
      orderSummary: [],
      editProjectLink: "",
    };
  }

  componentDidMount = () => {
    this.fetchActiveProjects();
  };

  updateDocuments = (el) => {
    const alreadyUpdated = this.state.uploadedDocs;
    alreadyUpdated.push(el.target.files[0]);
    this.setState({ updateDocuments: alreadyUpdated });
  };

  fetchActiveProjects = () => {
    this.setState({ isLoading: true, isError: false }, () => {
      const projectId =
        this.props.match && this.props.match.params
          ? this.props.match.params.id
          : null;
      const user = parseJwt(localStorage.getItem("auth_token"));

      if (user && user._id && projectId) {
        var summary = [];
        var editProjectLink = "/";
        getOwnerProjectById(user._id, projectId).then((data) => {
          if (data.status === 200 && data.data && data.data) {
            const project = data.data;
            if (
              project &&
              project.serviceDetails?.data?.flowName !== undefined
            ) {
              const payload = project.serviceDetails?.data;
              switch (payload.flowName) {
                case "floor-engineered":
                  editProjectLink = "/floors/solidhardwood";
                  break;
                case "floor-solidHardwood":
                  editProjectLink = "/floors/solidhardwood";
                  break;

                case "wallOpening-flow":
                  editProjectLink = "/wall-opening/size";
                  break;
                case "kitchen-flow":
                  editProjectLink = "/kitchen/type-of-work/1";
                  break;

                case "basement-flow":
                  editProjectLink = "/kitchen/type-of-work/1";
                  break;

                case "floor-carpet":
                  editProjectLink = "/floors/solidhardwood";
                  break;

                case "floor-tiles":
                  editProjectLink = "/floors/solidhardwood";
                  break;

                case "floor-laminated":
                  editProjectLink = "/floors/solidhardwood";
                  break;

                case "floor-epoxy":
                  editProjectLink = "/floors/solidhardwood";
                  break;

                case "deck":
                  editProjectLink = "/painting";
                  break;

                case "fence":
                  editProjectLink = "/painting";
                  break;

                case "exterior":
                  editProjectLink = "/painting";
                  break;

                case "bathroom":
                  editProjectLink = "/bathroom";
                  break;

                case "roofing-flow":
                  editProjectLink = "/roofing/type-of-service";
                  break;

                case "window-flow":
                  editProjectLink = "/windows/type-of-service";
                  break;

                case "hvac-flow":
                  editProjectLink = "/hvac/type-of-service";
                  break;
                case "plumbing-flow":
                  editProjectLink = "/plumbing/type-of-service";
                  break;

                default:
                  break;
              }
              this.setState(
                {
                  project: project,
                  editProjectLink: editProjectLink,
                  isLoading: false,
                  isError: false,
                }
                // () => console.log(this.state)
              );
            }
          }
        });
      } else {
        this.setState(
          {
            project: null,
            isLoading: false,
            isError: true,
          }
          // () => console.log(this.state)
        );
      }
    });
  };

  /**
   * Gets the contractor from api
   */
  getContractorDetails = async (id) => {
    return getContractor(id);
  };

  startChat = async () => {
    this.setState({ isLoading: true });

    const user = getData(LOCAL_STORAGE.user);
    const data = {
      contractorID: this.state.project.contractorId,
      ownerID: this.state.project.ownerId,
    };

    if (data["ownerID"] === user._id) data["userType"] = "owner";
    if (data["contractorID"] === user._id) data["userType"] = "contractor";

    createChannel(data)
      .then((response) => {
        if (response.status !== 200) {
          console.log("Something went wrong!");
          return;
        }
        this.props.history.push({
          pathname: "/user/chat",
          search: `?sid=${response.data}`,
        });
      })
      .catch(() => {
        console.log("Something went wrong!");
      });
  };

  render = () => {
    const { t } = this.props;
    const { project } = this.state;
    return (
      <div className="insideWrp">
        <HeaderMenu />
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className="project-details-header">
            <div>
              <div className="paint_flex_with_left ">
                <label className="paint_header">{t("Project details")}</label>
              </div>
              {project !== null ? (
                <div className="paint_flex_with_left paddinngtop10">
                  <label style={{ textTransform: "capitalize" }}>
                    {getprojectheader(
                      project.serviceDetails.data.flowName,
                      project.serviceDetails.data.serviceType
                    )}
                  </label>
                </div>
              ) : null}
            </div>
            <div>
              <div
                className="price-wrp"
                style={{ display: "flex", placeItems: "center" }}
              >
                <div style={{ paddingTop: "3px" }}>
                  <img
                    width="15"
                    height="15"
                    src={IMAGES.other.green_plus}
                    alt=""
                  />
                </div>
                <div style={{ paddingLeft: "10px" }}>
                  {project?.price < 0
                    ? `${formatPrice(project?.price).replace("-", "-$")}`
                    : `$${formatPrice(project?.price)}`}
                  <br />
                  Tax:{" "}
                  {`$${formatPrice(project?.serviceDetails?.data?.tax || 0)}`}
                </div>
              </div>
            </div>

            <div className="otherspadding25"></div>
          </div>
          {project ? <OrderSummaryForAllFlow project={project} /> : null}
          <div className="otherspadding25"></div>
          {/* <Button
            disabled={false}
            buttonLabel={t("Edit project details")}
            onClick={() => this.props.history.push(this.state.editProjectLink)}
            className={"btn-white"}
          /> */}
          <div className="otherspadding25"></div>
          <div
            className="contactblue_btn "
            style={{ background: "#0ABDE1" }}
            onClick={this.startChat}
          >
            <div>
              <img src={IMAGES.other.group1} alt="" />
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <label className="condactNeedhelp">
                      {"Chat with contractor"}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="paintPolygon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="17"
              viewBox="0 0 14 17"
              fill="none"
            >
              <path
                d="M13 6.76795C14.3333 7.53775 14.3333 9.46225 13 10.2321L3.25 15.8612C1.91667 16.631 0.25 15.6688 0.25 14.1292L0.25 2.87084C0.25 1.33123 1.91667 0.368984 3.25 1.13878L13 6.76795Z"
                fill="#0ABDE1"
              />
            </svg>
          </div>
          <div className="otherspadding25"></div>
          <div className="padding100"></div>

          <div className="padding_top8"></div>

          <div className="otherspadding25"></div>
          <Button
            disabled={false}
            buttonLabel={t("Back to project details")}
            onClick={() =>
              this.props.history.push(`/user/view-project/${project._id}`)
            }
            className={"btn-white"}
          />
          <div className="otherspadding25"></div>
          {project && <UserCancelProjectButton projectId={project._id} />}
          <div className="padding100"></div>
          <div className="contactblue_btn ">
            <div>
              <img src={IMAGES.other.group1} alt="" />
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <label className="condactNeedhelp">
                      {t("paintingInterior.doneedhelp")}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td onClick={() => (window.location = "/contact-us")}>
                    <label className="contactuslink">
                      {t("paintingInterior.contactus")}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="paintPolygon">
            <img src={IMAGES.other.polygon} alt="" />
          </div>
        </div>

        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(ProjectDetails));
