import React, { useEffect, useRef, useState } from "react";
import { getServiceCategories } from "../../../services/api/category.api.service";
import IMAGES from "../../../_assets/images";
import { useDispatch, useSelector } from "react-redux";
import { selectCategory } from "../../../_redux/actions";
import { searchState } from "../../../recoil/atoms/headerAtom";
import { selectedCategory } from "../../../recoil/atoms/categoryAtom";

import { useRecoilState } from "recoil";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useRecoilState(selectedCategory);
  const reduxState = useSelector((state) => state?.buttonReducer);
  const [, setSearch] = useRecoilState(searchState);

  const ref = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("redirectUrl");
    getServiceCategories().then((items) => {
      if (items.status === 200) {
        setCategories(items?.data);
        const {
          name,
          key,
          _id,
          emiDuration,
          estimatedPrice,
          estimatedPriceEmi,
          icon,
          jobDuration,
          iconSelected,
        } = items?.data?.[0];
        setSelected({
          name,
          key,
          id: _id,
          emiDuration,
          estimatedPrice,
          estimatedPriceEmi,
          icon,
          jobDuration,
          iconSelected,
        });
        dispatch(selectCategory({ selectedCategory: items?.data?.[0]?._id }));
      }
    });
  }, []);

  const scrollLeft = () => {
    if (ref.current) {
      ref.current.scrollLeft -= 100;
    }
  };

  const scrollRight = () => {
    if (ref.current) {
      ref.current.scrollLeft += 100;
    }
  };

  const chooseCategory = (category) => {
    const {
      name,
      key,
      _id,
      emiDuration,
      estimatedPrice,
      estimatedPriceEmi,
      icon,
      jobDuration,
      iconSelected,
    } = category;
    setSearch("");
    setSelected({
      name,
      key,
      id: _id,
      emiDuration,
      estimatedPrice,
      estimatedPriceEmi,
      icon,
      jobDuration,
      iconSelected,
    });
    dispatch(selectCategory({ selectedCategory: _id }));
  };

  return (
    <div className="d-flex align-items-center position-relative px-lg-5 categories">
      <img
        onClick={scrollLeft}
        className="d-none d-lg-block scroll-left-icon"
        src={IMAGES.home.scrollLeft}
      />
      <div className="categories-container" ref={ref}>
        {categories?.map((category) => (
          <div
            onClick={() => chooseCategory(category)}
            className="category-item"
            key={category?._id}
          >
            <img
              className={`${selected?.id === category?._id ? "selected" : ""}`}
              src={category?.iconSelected}
            />
            <span className={selected?.id === category?._id ? "selected" : ""}>
              {category?.category}
            </span>
          </div>
        ))}
      </div>
      <img
        onClick={scrollRight}
        className="d-none d-lg-block scroll-right-icon"
        src={IMAGES.home.scrollRight}
      />
    </div>
  );
};

export default Categories;
