/**
 * Model For Checkout process
 */
export const checkoutModel = {
  selectedService: "",
  totalCost: 0,
  priceDetails: [],
  selectedServiceDetails: "",
  flowName: "checkout",
  selectedContractorId: "",
  addresses: [],
  cards: [],
  selectedAddressId: "",
  selectedCardId: "",
  projectStartDate: "",
  projectStartTime: "",
  projects: [],
};
