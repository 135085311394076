import { HaveOwnRoof } from "./HaveOwnRoof";
import { HeightOfRoof } from "./HeightOfRoof";
import { MaterialOfRoof } from "./MaterialOfRoof";
import { NeedOfRoofing } from "./NeedofRoofing";
import { NumberOfRepairsNeeded } from "./NumberOfRepairsNeeded";
import { RoofingOrderSummary } from "./OrderSummary";
import { PitchOfRoof } from "./PitchOfRoof";
import { RemovalOfPlywood } from "./RemovalOfPlywood";
import { SizeOfRoofing } from "./SizeOfRoofing";
import { RoofingTypeofService } from "./typeOfService";

export const roofingRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/roofing/type-of-service",
    component: RoofingTypeofService,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/roofing/why-you-need",
    component: NeedOfRoofing,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/roofing/roofing-size",
    component: SizeOfRoofing,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/roofing/number-of-repairs",
    component: NumberOfRepairsNeeded,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/roofing/have-own-roof",
    component: HaveOwnRoof,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/roofing/roof-Material",
    component: MaterialOfRoof,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/roofing/height-of-roof",
    component: HeightOfRoof,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/roofing/pitch-of-roof",
    component: PitchOfRoof,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/roofing/removal-plywood",
    component: RemovalOfPlywood,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/roofing/order-summary",
    component: RoofingOrderSummary,
  },
];
