import axios from "axios";
import { API_URL, authEndPoint, signupEndPoint } from "../apiConstants";

const userSignin = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}${authEndPoint.manuelSignin}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

const userPhoneSignin = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}${authEndPoint.phoneSignin}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

const userPhoneVerify = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}${signupEndPoint.mobileVerify}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export { userSignin, userPhoneSignin, userPhoneVerify };
