import IMAGES from "../../_assets/images/index";
 const basementSliderItems = [
   {
     urls: IMAGES.home.basementSlider3,
   },
   {
     urls: IMAGES.home.basementSlider2,
   },
   {
     urls: IMAGES.home.basementSlider1,
   },
   {
     urls: IMAGES.home.basementSlider4,
   },
   {
     urls: IMAGES.home.basementSlider5,
   },
   {
     urls: IMAGES.home.basementSlider6,
   },
   {
     urls: IMAGES.home.basementSlider7,
   },
   {
     urls: IMAGES.home.basementSlider8,
   },
   {
     urls: IMAGES.home.basementSlider9,
   },
   {
     urls: IMAGES.home.basementSlider10,
   },
   {
     urls: IMAGES.home.basementSlider11,
   },
 ];
export default basementSliderItems