import React, { useLayoutEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";

import { useDispatch, useSelector } from "react-redux";
import {
  calculateBathroomCost,
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { kitchenModel } from "../../_redux/Models/Kitchen.model";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  bathroomStore,
  checkoutStore,
  kitchenStore,
} from "../../_redux/actions";
import { bathroomFlowModel } from "../../_redux/Models/bathroom.model";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
export const BathroomSummary = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { history } = props;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.bathroomPayload
      ? state.buttonReducer.bathroomPayload
      : bathroomFlowModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const price = calculateBathroomCost(state.bathroomsDetails); // To keep the total price in state

  const [orderSummary, setOrderSummary] = useState([]);
  const [comment, setComment] = useState("");

  useLayoutEffect(() => {
    if (state && state !== undefined && state.bathroomsDetails) {
      const bathrooms = state.bathroomsDetails;
      const bathroomSummary = bathrooms.map((bathroom) => {
        return [
          {
            heading: t("Bathroom.size"),
            text: `${
              bathroom.size === "small"
                ? "Small (up to 50 sqft)"
                : bathroom.size === "medium"
                ? "Medium (up to 110 sqft)"
                : bathroom.size === "large"
                ? "Large (up to 140 sqft)"
                : "Larger (more than 140 sqft)"
            }`,
            img: IMAGES.other.green_plus,
            price: bathroom.costFor.size,
          },
          {
            heading: t("Bathroom.hasMaterials"),
            text: `${
              bathroom.haveOwnMaterial ? "Has materials" : "Requires materials"
            }`,
            img: IMAGES.other.green_plus,
            price: bathroom.costFor.material,
          },
          {
            heading: t("Bathroom.requiresDemolition"),
            text: `${bathroom.requireDemolition}`,
            img: IMAGES.other.green_plus,
            price: bathroom.costFor.demolition,
          },
          {
            heading: t("Bathroom.needBuildupWalls"),
            text: `${bathroom.requireBuildUpWalls}`,
            img: IMAGES.other.green_plus,
            price: bathroom.costFor.buildUpWall,
          },
          {
            heading: t("Bathroom.showerDoor"),
            text: bathroom.showerDoorTypeNeeded
              ? t(`Bathroom.${bathroom.showerDoorTypeNeeded}`)
              : "",
            img: IMAGES.other.green_plus,
            price: bathroom.costFor.showerDoor,
          },
          {
            heading: "Add on´s",
            text: bathroom.addOns.map((addOn) => {
              const add = addOn.text;
              if (add !== "") {
                return (
                  <span>
                    {`${add} (${addOn.count})`}
                    <br />
                  </span>
                );
              } else {
                return null;
              }
            }),
            img: IMAGES.other.green_plus,
            price: bathroom.costFor.addOns,
          },
        ];
      });

      setOrderSummary(bathroomSummary);
    }
  }, [state, t]);

  /**
   * On save Comment
   */
  const onSave = () => {
    state.comments = comment;
    dispatch(bathroomStore(state));
    window.location = `/contractor-portfolio/${selectedContractorId}/confirm`;
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("kitchen.osummary")}</label>
        </div>
        <div className="paint_flex_with_left paddinngtop20">
          <label className="paint_other_sub_header ">
            {t("Bathroom.renovationEstimation")}
          </label>
        </div>
        <div className="padding10"></div>
        {orderSummary.map((summary, index) => (
          <div>
            <div className="padding10">
              <label className="paint_header">{`Bathroom #${
                index + 1
              } `}</label>
              <label className="label-sub-price-text">{`Sub total: $${formatPrice(
                calculateTotalCost(state.bathroomsDetails[index].costFor)
              )}`}</label>
            </div>
            {summary.map((item, index) =>
              item.text.length > 0 ? (
                <label
                  className="floor-selection-radio"
                  style={{
                    height: "88px",
                    flexDirection: "column",
                    padding: "10px",
                    alignItems: "start",
                  }}
                >
                  <div className="bold">{item.heading}</div>
                  <div
                    className="flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: " 90%",
                    }}
                  >
                    <div
                      className=""
                      style={{
                        width: "65%",
                        textAlign: "left",
                        textTransform: "initial",
                      }}
                    >
                      {item.text}
                    </div>

                    <div style={{ display: "flex", placeItems: "center" }}>
                      <div style={{ paddingTop: "3px" }}>
                        <img
                          width="15"
                          height="15"
                          src={IMAGES.other.green_plus}
                          alt=""
                        />
                      </div>
                      <div style={{ paddingLeft: "10px" }}>
                        {item.price < 0
                          ? `${formatPrice(item.price).replace("-", "-$")}`
                          : `$${formatPrice(item.price)}`}
                      </div>
                    </div>
                  </div>
                </label>
              ) : null
            )}
          </div>
        ))}
        <table className="mb-3">
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("paintingInterior.ordercommands")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message"
              />
            </td>
          </tr>
        </table>
        <Button
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => onSave()}
        />
        <div className="contactblue_btn mt-3 mb-4">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon mb-5">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>
    </div>
  );
};
