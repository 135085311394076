/**
 * @author Geethananth M
 * Model Object to hold the Floor Categories
 */

/**
 * Model For Floor solidHardwood
 */
export const floorSolidHardwoodModel = {
  flowName: "floor-solidHardwood",
  serviceType: "",
  floorSize: 0, // Floor Size in SQFT
  haveSolidHardwood: false,
  isSolidHardwoodFinished: false,
  needContractorFinishSolidHardwood: false,
  finishedOrUnfinished: "",
  typeOfWood: "",
  sizeOfWood: "",
  typeOfInstallation: "",
  priorFloorRemovalRequired: false,
  isGlueDownSelected: false,
  glueDownSQFT: 0,
  isNailDownSelected: false,
  nailDownSQFT: 0,
  isTilesSelected: false,
  tilesSQFT: 0,
  isVinylSelected: false,
  vinylSQFT: 0,
  isConcreteSelected: false,
  concreteSQFT: 0,
  currentFloor: [],
  addOns: [],
  needToUseElevator: false,
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  costFor: {
    size: 0,
    typeOfService: 0,
    materialSize: 0,
    finishing: 0,
    typeOfInstallation: 0,
    floorRemoval: 0,
    addOns: 0,
    elevator: 0,
  },
};

/**
 * Model For Floor Engineered
 */
export const floorEngineeredModel = {
  flowName: "floor-engineered",
  floorSize: 0, // Floor Size in SQFT
  needEngineeredFloorInstall: false,
  materialQuality: "",
  priorFloorRemovalRequired: false,
  isGlueDownSelected: false,
  glueDownSQFT: 0,
  isNailDownSelected: false,
  nailDownSQFT: 0,
  isTilesSelected: false,
  tilesSQFT: 0,
  isVinylSelected: false,
  vinylSQFT: 0,
  isConcreteSelected: false,
  concreteSQFT: 0,
  currentFloor: [],
  addOns: [],
  needToUseElevator: false,
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  serviceType: "floor-engineered",
  costFor: {
    size: 0,
    materialQuality: 0,
    floorRemoval: 0,
    addOns: 0,
    elevator: 0,
  },
};

/**
 * Model For Floor Carpet
 */
export const floorCarpetModel = {
  flowName: "floor-carpet",
  floorSize: 0, // Floor Size in SQFT
  needCarpet: false,
  needPaddingReplacement: false,
  carpetQuality: "", // contractors-grade, better, best
  paddingQuality: "", // contractors-grade, better, best
  priorFloorRemovalRequired: false,
  isGlueDownSelected: false,
  glueDownSQFT: 0,
  isNailDownSelected: false,
  nailDownSQFT: 0,
  isTilesSelected: false,
  tilesSQFT: 0,
  isVinylSelected: false,
  vinylSQFT: 0,
  isConcreteSelected: false,
  concreteSQFT: 0,
  currentFloor: [],
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  serviceType: "floor-carpet",
  costFor: {
    carpetGrade: 0,
    carpetService: 0,
    padding: 0,
    paddingService: 0,
    floorRemoval: 0,
  },
};

/**
 * Floor Tiles Model
 */
export const floorTilesModel = {
  flowName: "floor-tiles",
  floorSize: 0, // Floor Size in SQFT
  tileSize: "",
  priorFloorRemovalRequired: false,
  haveOwnTiles: "",
  isGlueDownSelected: false,
  glueDownSQFT: 0,
  isNailDownSelected: false,
  nailDownSQFT: 0,
  isTilesSelected: false,
  tilesSQFT: 0,
  isVinylSelected: false,
  vinylSQFT: 0,
  isConcreteSelected: false,
  concreteSQFT: 0,
  currentFloor: [],
  addOns: [],
  needToUseElevator: false,
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  costFor: {
    sizeDetails: 0,
    floorRemoval: 0,
    addOns: 0,
    elevator: 0,
  },
};

/**
 * Floor Epoxy Model
 */
export const floorEpoxyModel = {
  flowName: "floor-epoxy",
  floorSize: 0, // Floor Size in SQFT
  priorFloorRemovalRequired: false,
  isGlueDownSelected: false,
  glueDownSQFT: 0,
  isNailDownSelected: false,
  nailDownSQFT: 0,
  isTilesSelected: false,
  tilesSQFT: 0,
  isVinylSelected: false,
  vinylSQFT: 0,
  isConcreteSelected: false,
  concreteSQFT: 0,
  currentFloor: [],
  addOns: [],
  needToUseElevator: false,
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  costFor: {
    sizeDetails: 0,
    floorRemoval: 0,
    addOns: 0,
    elevator: 0,
  },
};

/**
 * Floor Laminated Model
 */
export const floorLaminatedModel = {
  flowName: "floor-laminated",
  floorSize: 0, // Floor Size in SQFT
  priorFloorRemovalRequired: false,
  haveOwnLaminate: false,
  tileSize: "",
  isGlueDownSelected: false,
  glueDownSQFT: 0,
  isNailDownSelected: false,
  nailDownSQFT: 0,
  isTilesSelected: false,
  tilesSQFT: 0,
  isVinylSelected: false,
  vinylSQFT: 0,
  isConcreteSelected: false,
  concreteSQFT: 0,
  currentFloor: [],
  addOns: [],
  needToUseElevator: false,
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  costFor: {
    sizeDetails: 0,
    laminated: 0,
    floorRemoval: 0,
    addOns: 0,
    elevator: 0,
  },
};
