import { AddOn } from "./AddOn";
import { CurrentTypeOfFloor } from "./CurrentTypeOfFloor";
import { LaminatedOrderSummary } from "./LaminatedOrderSummary";
import { ServiceType } from "./ServiceType";
import { SizeOfFloor } from "./SizeOfFloor";
import { PersonalInfo } from "./personalInfo";

export const laminatedRoutes = [
  {
    path: "/contractor-portfolio/:id/floors/laminated/floor-size/:stepValue",
    component: SizeOfFloor,
  },
  {
    path: "/contractor-portfolio/:id/floors/laminated/service-type/:stepValue",
    component: ServiceType,
  },
  {
    path: "/contractor-portfolio/:id/floors/laminated/current-floor/:stepValue",
    component: CurrentTypeOfFloor,
  },
  {
    path: "/contractor-portfolio/:id/floors/laminated/floor-addons/:stepValue",
    component: AddOn,
  },
  {
    path: "/contractor-portfolio/:id/floors/laminated/personal-info/:stepValue",
    component: PersonalInfo,
  },
  {
    path: "/contractor-portfolio/:id/floors/laminated/order-summary/:stepValue",
    component: LaminatedOrderSummary,
  },
];
