import React, { useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { useDispatch } from "react-redux";
import { formatPrice } from "../../services/utils/utilFunctions";
import { checkoutStore, paintingStore } from "../../_redux/actions";
import {
  deckModel,
  exteriorModel,
  fenceModel,
  paintingInteriorModel,
} from "../../_redux/Models/painting.models";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const HavePaint = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nextStep =
    props.match && props.match.params ? props.match.params.nextRoute : "other";

  const price = 0; // To keep the total price in state
  const [option, setOption] = useState("");
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const LandingClick = (value) => {
    var serviceType = "";
    var redirectTo = "";
    const alreadyHavePaint = value === "alreadyHave";
    switch (nextStep) {
      case "interior":
        redirectTo = `/contractor-portfolio/${selectedContractorId}/painting/interior/type-of-work/${2}`;
        serviceType = "interior";
        paintingInteriorModel.haveOwnPaint = alreadyHavePaint;
        dispatch(paintingStore(paintingInteriorModel));
        break;
      case "exterior":
        redirectTo = `/contractor-portfolio/${selectedContractorId}/painting/exterior/house-sizing/${2}`;
        serviceType = "exterior";
        exteriorModel.haveOwnPaint = alreadyHavePaint;
        exteriorModel.serviceType = serviceType;
        dispatch(paintingStore(exteriorModel));
        break;
      case "deck":
        redirectTo = `/contractor-portfolio/${selectedContractorId}/painting/deck/deck-size/${2}`;
        serviceType = "deck";
        deckModel.haveOwnPaint = alreadyHavePaint;
        dispatch(paintingStore(deckModel));
        break;
      case "fence":
        redirectTo = `/contractor-portfolio/${selectedContractorId}/painting/fence/fence-size/${2}`;
        serviceType = "fence";
        fenceModel.haveOwnPaint = alreadyHavePaint;
        dispatch(paintingStore(fenceModel));
        break;
      case "other":
        redirectTo = "/contact-us";
        serviceType = "other";
        break;
      default:
        break;
    }

    if (redirectTo == "/contact-us") {
      window.location = "/contact-us";
    } else {
      props.history.push(redirectTo);
    }
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_other_header">
              {t("paintingHomes.others_title")}
            </label>
          </div>
          <div className="padding20">
            <label className="paint_other_sub_header">
              {t("paintingHomes.others_sub_title")}
            </label>
          </div>

          <div
            // key={index}
            className="paintPadding8 "
          >
            <div
              onClick={() => {
                setOption("alreadyHave");
                LandingClick("alreadyHave");
              }}
              className={`floor-selection-radio ${
                option === "alreadyHave" ? "selected" : ""
              }`}
            >
              <div
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">
                  {t("paintingHomes.others_option1")}
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setOption("providePaint");
              LandingClick("providePaint");
            }}
            className=""
          >
            <div
              className={`floor-selection-radio ${
                option === "providePaint" ? "selected" : ""
              }`}
            >
              <div
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">
                  {t("paintingHomes.others_option2")}
                </label>

                <div
                  className="paint_arrowFloat"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
