import React from "react";
import { useTranslation } from "react-i18next";

const FenceWhatIsIncluded = () => {
  const { t } = useTranslation();

  return (
    <div>
      <label>
        <strong>{t("floor.whatsIncludedInService")}</strong>
      </label>
      <ul>
        <li>Customer support specialist </li>
        <li>Paint the fence</li>
        <li>Status check of the fence</li>
        <li>Cover lights and plants to protect them</li>
        <li>Expert support to choose your color </li>
        <li>Includes one coat of paint (you can add more)</li>
        <li>Clean up job site</li>
        <li>1 year Comprehensive warranty covering workmanship</li>
        <li>Paint free delivery</li>
      </ul>
      <div className="profilePadding10"></div>
      <label>
        <strong>{t("floor.howLongTheProcessTakes")}</strong>
      </label>
      <ul>
        <li> Between 1 to 2 days*</li>
      </ul>
      <div className="profilePadding40"></div>
      <span>*Might differ according to size</span>
    </div>
  );
};

export default FenceWhatIsIncluded;
