import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/paint.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import { floorStore } from "../../../_redux/actions";

import { useDispatch, useSelector } from "react-redux";
import { floorLaminatedModel } from "../../../_redux/Models/Floor.model";
import { parseJwt } from "../../../services/utils/utilFunctions";
import { ownerProfile } from "../../../services/api/owner.service";

export const PersonalInfo = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const dispatch = useDispatch();

  const floorState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorLaminatedModel
  );

  const [personalInfo, setPersonalInfo] = useState({
    name: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    phoneNumber: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    fullAddress: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    email: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
  });

  useEffect(() => {
    let mounted = true;
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (mounted === true && user && user._id) {
      ownerProfile(user._id).then((data) => {
        if (data.data && data.data[0]) {
          const userObj = data.data[0];
          let stateObj = {
            name: {
              valid:
                userObj.userId.name !== undefined && userObj.userId.name !== "",
              value: userObj.userId.name,
              error: {
                flag: null,
                message: "",
              },
            },
            phoneNumber: {
              valid: false,
              value: "",
              error: {
                flag: null,
                message: "",
              },
            },
            fullAddress: {
              valid: false,
              value: "",
              error: {
                flag: null,
                message: "",
              },
            },
            email: {
              valid:
                userObj.userId.email !== undefined &&
                userObj.userId.email !== "",
              value: userObj.userId.email,
              error: {
                flag: null,
                message: "",
              },
            },
          };
          setPersonalInfo(stateObj);
        }
      });
    }
    return () => (mounted = false);
  }, []);

  const validate = (name, value, message) => {
    const newState = { ...personalInfo };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;
    var regexpEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    switch (name) {
      case "name":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Full Name is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "email":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Email is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (!value.match(regexpEmail)) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Please enter a valid Email";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value.match(regexpEmail)) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "phoneNumber":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "phone Number is Required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "fullAddress":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Address is Required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      default: {
        return newState;
      }
    }
  };
  const onChangeHandler = (event, key) => {
    let validatedState = validate(key, event.target.value, undefined);
    setPersonalInfo(validatedState);
  };

  /**
   * Calls when submitting a form
   */
  const onSubmitForm = () => {
    const formValid = Object.values(personalInfo).every(
      (val) => val.valid === true
    );
    if (formValid) {
      floorState.personalInfo = { ...personalInfo };
      dispatch(floorStore(floorState));
      history.push(`/floors/laminated/order-summary/6`);
    } else {
      Object.values(personalInfo).forEach((el, i) => {
        if (!el.valid) {
          let keyValue = Object.keys(personalInfo)[i];
          var errorState = validate(keyValue, el.value, undefined);
          setPersonalInfo(errorState);
        }
      });
    }
  };

  return (
    <div>
      <HeaderMenu />
      <div className="paint-home-section">
        <div className="paintPersonalinfo_div paint_flex_with_center_personal">
          <table className=" ">
            <tr>
              <td>
                <img src={IMAGES.other.personalvector} alt="" />
              </td>
            </tr>
            <tr>
              <td>
                <label>{t("personalInfo.giveus")}</label>
              </td>
            </tr>
            <tr>
              <td>
                <label>{t("personalInfo.final")}</label>
              </td>
            </tr>
          </table>
        </div>
        <div className="paint_flex_with_left otherspadding25">
          <label className="paint_header">{t("personalInfo.personal")}</label>
        </div>

        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "name")}
            value={personalInfo.name.value}
            error={personalInfo.name.error}
            type={"text"}
            placeholder={t("paintingother.plzname")}
            label={t("personalInfo.name")}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "email")}
            value={personalInfo.email.value}
            error={personalInfo.email.error}
            placeholder={t("paintingother.plzemail")}
            label={t("paintingother.email")}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            type={"number"}
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "phoneNumber")}
            value={personalInfo.phoneNumber.value}
            error={personalInfo.phoneNumber.error}
            placeholder={t("paintingother.plzphone")}
            label={t("paintingother.phone")}
          />
        </div>
        <div className="paintinput_lable">
          <TextInput
            className="paint_input_border"
            onChange={(event) => onChangeHandler(event, "fullAddress")}
            value={personalInfo.fullAddress.value}
            error={personalInfo.fullAddress.error}
            placeholder={t("personalInfo.pleaseaddress")}
            label={t("personalInfo.address")}
          />
        </div>

        <label
          onClick={onSubmitForm}
          className="paint_home_form_btn cursor_hand"
        >
          {t("paintingHomes.continue")}
        </label>
        <div className="otherspadding25"></div>
        <div className="contactblue_btn ">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td onClick={() => (window.location = "/contact-us")}>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>

      <Footer />
    </div>
  );
};
