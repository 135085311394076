import IMAGES from "../../_assets/images/index";
 const mordenSliderItems = [
  {
    urls: IMAGES.home.bathroomModern1,
  },
  {
    urls: IMAGES.home.bathroomModern2,
  },
  {
    urls: IMAGES.home.bathroomModern3,
  },
  {
    urls: IMAGES.home.bathroomModern4,
  },
  {
    urls: IMAGES.home.bathroomModern5,
  },
  {
    urls: IMAGES.home.bathroomModern6,
  },

];
export default mordenSliderItems