import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Compressor from "compressorjs";

import IMAGES from "../../../_assets/images";
import { parseJwt } from "../../../services/utils/utilFunctions";
import {
  ownerProfile,
  updateOwnerProfile,
} from "../../../services/api/owner.service";
import {
  getData,
  setData,
} from "../../../services/localStorage/localStorage.service";
import { useTranslation } from "react-i18next";
import useNotify from "../../../_components/Notification/UseNotify";
import Loader from "../../../_components/spinner/Loader";
import { FireBase } from "../../../services/firebase/firebase.service";
import { LOCAL_STORAGE } from "../../../_configs/constants.config";

const MyProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  let userDetails = getData(LOCAL_STORAGE?.user);

  const history = useHistory();
  const { t } = useTranslation();
  const { confirm } = useNotify();
  const firebase = new FireBase();
  const [isContractor, toggleProfile] = useState(
    userDetails?.userType === "contractor"
  );

  const getUserInfo = () => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (user && user._id) {
      ownerProfile(user._id)
        .then((data) => {
          if (data.data && data.data) {
            const userObj = data.data;
            setUser(userObj);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const signout = () => {
    setLoading(true);
    if (firebase) firebase.deleteToken();
    localStorage.clear("user");
    setLoading(false);
    window.location = "/";
  };

  const handleFilePick = (event) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "*";
    input.onchange = (event) => {
      const file = event.target.files[0];
      handleFileUploadFor(file, "profileImage");
    };
    input.click();
  };

  const handleFileUploadFor = (file, field) => {
    new Compressor(file, {
      quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        fileToDataUri(compressedResult).then((dataUri) => {
          fetch(dataUri)
            .then((res) => res.blob())
            .then((res) => {
              uploadFilesToServer(res, field, () => getUserInfo());
            });
        });
      },
    });
  };

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const uploadFilesToServer = (file, field, callback) => {
    const postData = new FormData();
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (file && field) {
      setLoading(true);
      postData.append(field, file);
      updateOwnerProfile(user._id, postData)
        .then((data) => {
          if (data.status !== 200) {
            confirm("error", "Upload failed please try again");
          } else if (callback) {
            callback();
          }
        })
        .catch((e) => {
          const variant = "error";
          confirm("error", e.toString());
        })
        .finally((_) => {
          setLoading(false);
        });
    }
  };

  const handleProfileToggle = () => {
    let _userDetails = getData(LOCAL_STORAGE.user);
    if (_userDetails) {
      let _isContractor = isContractor;
      if (_userDetails.userType === "contractor") {
        _userDetails.userType = "owner";
        _isContractor = false;
      } else {
        if (_userDetails?.originalStatus === "contractor") {
          _userDetails.userType = "contractor";
          _isContractor = true;
          history.push("/contractor-projects");
        } else {
          history.push("/signup");
        }
      }
      setData("user", _userDetails);
      toggleProfile(_isContractor);
    } else {
      confirm("error", "Please login to continue");
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="owner-profile">
      <div className="profile-head d-none d-lg-flex w-100">
        <Loader progress={loading} />

        <img
          onClick={() => history.goBack()}
          className="close-icon"
          src={IMAGES.home.backIcon}
        />
      </div>
      <div className="profile-top d-flex justify-content-center">
        <img
          onClick={() => history.goBack()}
          className="close-icon d-flex d-lg-none"
          src={IMAGES.home.backIcon}
        />
        <div className="col-10 col-lg-9 position-relative d-flex">
          <Loader progress={loading} />
          <img
            src={user?.profileImage || IMAGES.home.defaultUser}
            className="profile-img"
          />
          <img
            onClick={handleFilePick}
            className="position-absolute edit-icon"
            src={IMAGES.home.editIcon}
          />
        </div>
      </div>
      <div className="profile-bottom d-flex justify-content-center">
        <div className="col-10 col-lg-9">
          <h4 className="">{user?.userId?.name}</h4>
          <Link to="/user/edit-profile" className="edit-profile">
            {t("ownerProfile.editProfile")}
          </Link>
          <div className="profile-headings d-flex flex-wrap w-100">
            <div className="col-10 col-lg-4">
              {t("ownerProfile.personalSettings")}
            </div>
            <div className="col-10 col-lg-4 d-none d-lg-block">
              {t("ownerProfile.businessSettings")}
            </div>{" "}
            <div className="col-10 col-lg-4 d-none d-lg-block">
              {t("ownerProfile.helpAndSupport")}
            </div>
          </div>
          <div className="profile-links d-flex flex-wrap w-100">
            <div className="col-10 col-lg-4 justify-content-start">
              <Link to="/owner/my-profile/personal-information">
                <i class="fa fa-user" aria-hidden="true"></i>
                <p>{t("ownerProfile.personalInfo")}</p>
              </Link>
              <Link to="/user/notifications">
                <img src="" />
                <p>{t("ownerProfile.notification")}</p>
              </Link>
              <Link to="/">
                <img src="" />
                <p>{t("ownerProfile.languages")}</p>
              </Link>

              <Link to="/">
                <img src="" />
                <p>{t("ownerProfile.loginAndPassword")}</p>
              </Link>
              <Link to="/favorites">
                <img src="" />
                <p>{t("ownerProfile.favorites")}</p>
              </Link>
            </div>
            <div className="profile-headings d-flex d-lg-none flex-wrap w-100">
              <div className="col-10 col-lg-4 ">
                {t("ownerProfile.businessSettings")}
              </div>
            </div>
            <div className="col-10 col-lg-4">
              <span onClick={handleProfileToggle}>
                <img src="" />
                <p>
                  {!isContractor
                    ? t("header.switchPersonal")
                    : t("header.switch")}
                </p>
              </span>
            </div>
            <div className="profile-headings d-flex d-lg-none flex-wrap w-100">
              <div className="col-10 col-lg-4">
                {t("ownerProfile.helpAndSupport")}
              </div>
            </div>
            <div className="col-10 col-lg-4">
              <Link to="/contact-us">
                <img src="" />
                <p>{t("ownerProfile.needHelp")}</p>
              </Link>
              <Link to="/">
                <img src="" />
                <p>{t("ownerProfile.assistant")}</p>
              </Link>
              <Link to="/">
                <img src="" />
                <p>{t("ownerProfile.feedback")}</p>
              </Link>
              <Link to="/terms-and-condition">
                <img src="" />
                <p>{t("ownerProfile.terms")}</p>
              </Link>
              <Link to="/" className="d-flex d-lg-none" onClick={signout}>
                <img src="" />
                <p>{t("homeDrawer.signout")}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
