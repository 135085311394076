import React, { useLayoutEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import "../../_styles/globalStyles/global.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { treeStore } from "../../_redux/actions";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { treeModel } from "../../_redux/Models/tree.model";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const TreeOrderSummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [orderSummary, setOrderSummary] = useState([]);

  const payload = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.treePayload
      ? state.buttonReducer.treePayload
      : treeModel
  );
  const stepValue =
    payload.serviceType === "tree_trimming"
      ? 5
      : payload.serviceType === "fallen_tree_removal"
      ? 4
      : 7;

  const price = calculateTotalCost(payload.costFor); // To keep the total price in state
  const [comment, setComment] = useState(payload.comments);
  /**
   * On save Comment
   */
  const saveComment = () => {
    payload.comments = comment;
    dispatch(treeStore(payload));
    window.location = `/contractor-portfolio/${selectedContractorId}/confirm`;
  };

  useLayoutEffect(() => {
    if (payload && payload !== undefined) {
      const summary = [
        {
          heading: "Type of service",
          text:
            payload.serviceType === "tree_remotion"
              ? "Tree removal"
              : `${payload.serviceType.replace(/_/g, " ")}`,
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading: "Nearby structure or electric installation?",
          text: payload.anyElectricInstallationsNear,
          img: IMAGES.other.green_plus,
          price: payload.costFor?.nearByStructure,
        },

        {
          heading: "Tree height details",
          text: payload.treeDetails.map((addOn, index) => {
            const add = addOn.text;
            if (add !== "") {
              return (
                <span key={index}>
                  {`${add.toString().replace("_", " ")} (${addOn.count}) `}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
          img: IMAGES.other.green_plus,
          price: payload.costFor.installation,
        },
        {
          heading: "Number of stump removals needed?",
          text: `${payload.numberOfTreesToStumpRemove}`,
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading: "Tree dimension details",
          text: payload.treeDimensionDetails.map((addOn, index) => {
            const add = addOn.text;
            if (add !== "") {
              return (
                <span key={index}>
                  {`${add} inch (${addOn.count}) `}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
          img: IMAGES.other.green_plus,
          price: payload.costFor.stumpRemoval,
        },
        {
          heading: "What type of tree/s to remove?",
          text: payload.typeOfTreesToRemove.map((addOn, index) => {
            const add = addOn.text;
            if (add !== "") {
              return (
                <span key={index}>
                  {`${add.replaceAll("_", " ")} (${addOn.count})`}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading: "Do you want your stump grinding?",
          text: payload.needStumpGrinding,
          img: IMAGES.other.green_plus,
          price: payload.costFor.stumpGrinding,
        },
        {
          heading: "Do you want the debris haul away?",
          text: payload.needDebrisHaul,
          img: IMAGES.other.green_plus,
          price: payload.costFor.debrisHaul,
        },
        {
          heading: "Any trees which cannot reach with machinery?",
          text: payload.anyTreesOutOfReach
            ? `${payload.numberOfTreesOutOfReach}`
            : "",
          img: IMAGES.other.green_plus,
          price: payload.costFor.machinery,
        },
      ];

      console.log(summary);
      setOrderSummary(summary);
    }
  }, [payload, t]);

  return (
    <div className="service insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="sevice_title">{t("orderSummary.mainHeading")} </div>
        <label
          className="sub_heading"
          style={{ marginTop: "7px", textTransform: "capitalize" }}
        >
          {payload.serviceType === "tree_remotion"
            ? "Tree removal estimate"
            : `${payload.serviceType.replace(/_/g, " ")} estimate`}
        </label>
        <div className="paintpaddingtop25"></div>
        {orderSummary.map((item, index) =>
          item.text.length > 0 ? (
            <div key={index}>
              <label
                className="floor-selection-radio"
                style={{
                  // height: "88px",
                  flexDirection: "column",
                  padding: "15px",
                  alignItems: "start",
                  marginBottom: "15px",
                }}
              >
                <div className="bold">{item.heading}</div>
                <div
                  className="flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="text_width"
                    style={{
                      textTransform: "initial",
                      width: "100%",
                      paddingRight: "25px",
                      textAlign: "left",
                    }}
                  >
                    {item.text}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      placeItems: "center",
                      width: "155px",
                    }}
                  >
                    <div style={{ paddingTop: "3px" }}>
                      <img
                        width="15"
                        height="15"
                        src={IMAGES.other.green_plus}
                        alt=""
                      />
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      {`$${formatPrice(parseFloat(item.price))}`}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          ) : null
        )}
        <table>
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("paintingInterior.ordercommands")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message plcehlder_align"
              />
            </td>
          </tr>
        </table>

        <div className="otherspadding25"></div>
        <Button
          disabled={false}
          buttonLabel={t("orderSummary.continue")}
          onClick={saveComment}
        />
        <div className="contactblue_btn mb-5 mt-4">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td onClick={() => (window.location = "/contact-us")}>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon mb-3">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>
    </div>
  );
};
