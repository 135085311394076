import React from "react";
import IMAGES from "../../../_assets/images/index";
// import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom';
import { signupModel } from '../../../_redux/Models/auth.models';
import { useDispatch } from 'react-redux';
import { continuetoNext } from "../../../_redux/actions";

function ContactSection(props) {
  // const { t } = useTranslation()
  const dispatch = useDispatch();
  return (
    <div className="home-section">
      {/* <div className="home-section-dream">
        <button>
          Let´s start your project{" "}
          <img src={IMAGES.home.newArrow} width="10" alt=""></img>
        </button>
      </div> */}
      <div className="grid home-grid" > 
        <label className="contract ">
          Are you a 
          </label>
          <label className="contract1">
          contractor?
        </label>
        
        <label className="work" onClick={() => {
          signupModel.signuserType = "contractor";
          dispatch(continuetoNext(signupModel));
          props.history.push(`/signup`);
        }}>
          Work with us <img src={IMAGES.other.black_vector} alt="" />
        </label>
        <img
          src={IMAGES.home.hdman}
          style={{ borderRadius: "10px" }}
          width="100%"
          alt=""
        />
      </div>
    </div>
  );
}

export default withRouter(ContactSection);
