import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";

import "./rating.css";
import { Footer } from "../../_components/footer/Footer";
import { useSelector } from "react-redux";
import { updateOwnerRating } from "../../services/api/contractor.service";
import { useHistory } from "react-router-dom";
import { ratingImages } from "../Contractor/MyProjects/contractorUtils";
import { useSnackbar } from "notistack";

const Rating = () => {
  const history = useHistory();
  const [ratingInfo, updateRateInfo] = useState({
    rating: "",
    feedback: "",
    userType: "",
    ownerId: "",
    contractorId: "",
    projectId: "",
  });

  const contractorDetails = useSelector((state) => state.contractor);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    let user = {};
    if (
      urlParams.get("type") === "contractor" &&
      Object.values(contractorDetails.projectDetails).length > 0
    ) {
      user["userType"] = urlParams.get("type");
      user["ownerId"] = contractorDetails.projectDetails.ownerId;
      user["contractorId"] = contractorDetails.projectDetails.contractorId;
      user["rating"] =
        !isNaN(parseInt(contractorDetails.projectDetails.clinteRating)) &&
        parseInt(contractorDetails.projectDetails.clinteRating);
      user["projectId"] = contractorDetails.projectDetails._id;
      user["feedback"] = "";
      updateRateInfo(user);
    } else {
      history.push("/contractor-projects");
    }
  }, []);

  const updateUserRating = () => {
    if (ratingInfo.userType === "contractor") {
      updateOwnerRating("project_owner", ratingInfo.ownerId, ratingInfo.projectId, ratingInfo.contractorId, ratingInfo.rating, ratingInfo.feedback)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            const variant = "success";
            enqueueSnackbar("Thanks for your rating", {
              variant,
            });
            history.push("/contractor-project-preview");
          }
        })
        .catch((e, d) => {
          console.log(d);
          const variant = "error";
          enqueueSnackbar("Project can only be rated within seven days", {
            variant,
          });
        });
    }
  };

  return (
    <div className="rating-container">
      <HeaderMenu />
      <div
        className="pcWidth"
        style={{ margin: "45px 0 0 0 ", padding: "0 18px 0 18px" }}
      >
        <span className="assistant-project-title">Rate your experience</span>
        <p className="subheading">
          Tell us about your experience with the client
        </p>
        <div className="rating-images">
          {ratingImages.map((item, idx) => {
            return (
              <div
                className="rating-img-ctn"
                onClick={() => {
                  updateRateInfo({ ...ratingInfo, rating: idx + 1 });
                }}
              >
                <div
                  className={`rating-border ${ratingInfo.rating === idx + 1 && "selected-rating"
                    }`}
                  key={idx}
                >
                  <img src={item.img} />
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    margin: "4px 0 0 0",
                  }}
                >
                  <span>{item.text}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <span
            className="assistant-project-title"
            style={{ fontSize: "12px" }}
          >
            Please describe your experience
          </span>
        </div>
        <textarea
          className="rating-textarea"
          placeholder="Tell us about it"
          value={ratingInfo.feedback}
          onChange={(e) => {
            updateRateInfo({ ...ratingInfo, feedback: e.target.value });
          }}
        >
          {ratingInfo.feedback}
        </textarea>
        <button
          className="confirm-rate"
          onClick={() => {
            if (isNaN(parseInt(ratingInfo.rating))) {
              alert("Please select rating");
            } else updateUserRating();
          }}
        >
          Confirm Rate
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default Rating;
