import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorCarpetModel } from "../../../_redux/Models/Floor.model";
import { removalOfFloorPopup } from "../Common/RemovalOfFloor.popup";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const PaddingQuality = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;

  // State variables initialization
  const [paddingQuality, setPaddingQuality] = useState("");
  const [showRemovalFloorPopup, setShowRemovalFloorPopup] = useState(false);

  const floorCarpetModelState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorCarpetModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(
    calculateTotalCost(floorCarpetModelState.costFor)
  ); // To keep the total price in state
  const [paddingPricing, setPaddingPricing] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const size = parseFloat(floorCarpetModelState.floorSize);
    const needPaddingReplacement = floorCarpetModelState.needPaddingReplacement;
    if (mounted === true && priceDetails && priceDetails.paddingQuality) {
      const optionsArr = priceDetails.paddingQuality.map((op) => {
        return {
          text: t(`floor.${op.key}`),
          value: op.key,
          price: needPaddingReplacement
            ? (op.materialPrice + op.servicePrice) * size
            : 0,
        };
      });
      setPaddingPricing(optionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, floorCarpetModelState, t]);

  /**
   * Function to save padding quality
   * @returns {void}
   */
  const onPaddingQualitySave = () => {
    floorCarpetModelState.paddingQuality = paddingQuality;
    floorCarpetModelState.costFor.padding =
      price - calculateTotalCost(floorCarpetModelState.costFor);
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(floorStore(floorCarpetModelState));
    setShowRemovalFloorPopup(true);
  };

  /**
   * On prior floor removal click yes
   * @returns {void}
   */
  const onPriorFloorRemovalYesClick = () => {
    floorCarpetModel.priorFloorRemovalRequired = true;
    setShowRemovalFloorPopup(false);
    dispatch(floorStore(floorCarpetModel));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/carpet/current-floor/5`
    );
  };

  /**
   * On prior floor removal click no
   * @returns {void}
   */
  const onPriorFloorRemovalNoClick = () => {
    setShowRemovalFloorPopup(false);
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/carpet/summary/5`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">{t("floor.padcar")}</label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("floor.grapad")}
            </label>
          </div>
          <div className="padding10"></div>
          {paddingPricing.map((item, index) => (
            <div
              key={index}
              className={`floor-selection-radio ${
                paddingQuality === item?.value ? "selected" : ""
              }`}
              onClick={() => {
                const cost = parseFloat(
                  calculateTotalCost(floorCarpetModelState.costFor)
                );
                setPrice(cost + item.price);
                setPaddingQuality(item.value);
              }}
              style={{ height: "80px" }}
            >
              <div
                key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt w-75">{item.text}</label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Button
            buttonLabel={t("paintingHomes.continue")}
            onClick={onPaddingQualitySave}
          />
        </div>
      </div>
      {removalOfFloorPopup({
        isOpen: showRemovalFloorPopup,
        onYesClick: onPriorFloorRemovalYesClick,
        onCloseAction: onPriorFloorRemovalNoClick,
        onNoClick: onPriorFloorRemovalNoClick,
      })}
    </div>
  );
};
