import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { treeModel } from "../../_redux/Models/tree.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { checkoutStore, treeStore } from "../../_redux/actions";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const DebrisHaulAway = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.treePayload
      ? state.buttonReducer.treePayload
      : treeModel
  );
  const stepValue = state.serviceType === "tree_trimming" ? 4 : 3;
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state

  const [debrisHaulNeeded, setDebrisHaulNeeded] = useState("");
  const [debrisHaulPrice, setDebrisHaulPrice] = useState(0);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails && priceDetails.debrisHaulAway) {
      const debrisHaulAwayPrices = priceDetails.debrisHaulAway;
      const treeDetails = state.treeDetails;

      let debrisHaulAwayPrice = 0;
      for (let i = 0; i < treeDetails.length; i++) {
        const treeHeight = treeDetails[i].value;
        const treeCount = treeDetails[i].count;
        const debrisPriceForSelectedHeight = debrisHaulAwayPrices.find(
          (op) => op.key === treeHeight
        ).price;
        debrisHaulAwayPrice =
          debrisHaulAwayPrice + debrisPriceForSelectedHeight * treeCount;
      }
      setDebrisHaulPrice(debrisHaulAwayPrice);
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state, t]);

  const onSave = (val, prc) => {
    const total = calculateTotalCost(state.costFor);
    state.needDebrisHaul = val;
    const debrisHaulCost = val === "yes" ? debrisHaulPrice : 0;
    state.costFor.debrisHaul = debrisHaulCost;
    checkoutModelState.totalCost = total + debrisHaulCost;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(treeStore(state));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/tree_services/order-summary`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header" style={{ fontSize: "19px" }}>
              {t("treeService.debrisHaulAway")}
            </label>
          </div>
          <div className="padding10"></div>
          <div
            onClick={() => {
              const total = calculateTotalCost(state.costFor);
              setPrice(total + debrisHaulPrice);
              setDebrisHaulNeeded("yes");
              onSave("yes", total + debrisHaulPrice);
            }}
            className={`floor-selection-radio ${
              debrisHaulNeeded === "yes" ? "selected" : ""
            }`}
          >
            <div
              // key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Yes</label>
              <div
                className="paint_arrowFloat flex"
                style={{ paddingTop: "10px" }}
              >
                <img
                  src={IMAGES.other.green_plus}
                  className="kitchenplusimg"
                  alt=""
                />
                <label
                  className="paintdiv_txt paddingleft8"
                  style={{ paddingLeft: "7px", paddingTop: "2px" }}
                >
                  {`$${formatPrice(debrisHaulPrice)}`}
                </label>
              </div>
            </div>
          </div>

          <div className="padding10"></div>

          <div
            onClick={() => {
              const total = calculateTotalCost(state.costFor);
              setPrice(total);
              setDebrisHaulNeeded("no");
              onSave("no", total);
            }}
            className={`floor-selection-radio ${
              debrisHaulNeeded === "no" ? "selected" : ""
            }`}
          >
            <div
              // key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">No</label>
              <div
                className="paint_arrowFloat flex"
                style={{ paddingTop: "10px" }}
              >
                <img
                  src={IMAGES.other.green_plus}
                  className="kitchenplusimg"
                  alt=""
                />
                <label
                  className="paintdiv_txt paddingleft8"
                  style={{ paddingLeft: "7px", paddingTop: "2px" }}
                >
                  {`$0.00`}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
