import React, { useState, useEffect, useRef } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/paint.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import IMAGES from "../../_assets/images";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import { t } from "i18next";
import "../../_styles/localStyles/headerFooter.css";
import "../../../src/index.css";
import { Button } from "../../_components/Button/Button";
import { getServiceCategoriesFull } from "../../services/api/category.api.service";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { checkoutStore, paintingStore } from "../../_redux/actions";
import {
  deckModel,
  exteriorModel,
  fenceModel,
  paintingInteriorModel,
} from "../../_redux/Models/painting.models";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const PaintLanding = (props) => {
  const { t } = useTranslation();
  const scrollPage = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [userState, setUserState] = useState({
    paintType: "",
  });
  const [paintAvailablity, setPaintAvailablity] = useState("");
  const [isHovered, setIsHovered] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const [category, setCategory] = React.useState({});
  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  // Call the service to get full available list
  useEffect(() => {
    let mounted = true;
    getServiceCategoriesFull().then((items) => {
      if (mounted && items.status === 200) {
        let filteredItem = items.data.filter(
          (item) => item.name === "Painting"
        );
        filteredItem = filteredItem ? filteredItem[0] : null;
        setCategory(filteredItem);
      }
    });
    return () => (mounted = false);
  }, []);

  const LandingClick = (paintType) => {
    var serviceType = "";
    var redirectTo = "";
    switch (paintType) {
      case "interiorpaint":
        redirectTo = `/contractor-portfolio/${selectedContractorId}/painting/interior/type-of-work/${1}`;
        serviceType = "interior";
        break;
      case "exteriorpaint":
        redirectTo = `/contractor-portfolio/${selectedContractorId}/painting/exterior/house-sizing/${1}`;
        serviceType = "exterior";
        break;
      case "deckpaint":
        serviceType = "deck";
        break;
      case "fencepaint":
        serviceType = "fence";
        break;
      case "otherpaint":
        serviceType = "other";
        break;
      default:
        break;
    }

    // Save the current flow to checkout
    let selectedServiceDetails = null;
    if (category && category.subCategory && serviceType) {
      selectedServiceDetails = category.subCategory.find(
        (sub) => sub.key === serviceType
      );
    }

    checkoutModelAs.selectedService = serviceType;
    checkoutModelAs.selectedServiceDetails = selectedServiceDetails;
    checkoutModelAs.totalCost = 0;
    checkoutModelAs.priceDetails = [];
    dispatch(checkoutStore(checkoutModelAs));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/painting/have-paint/${serviceType}`
    );
  };

  useEffect(() => {
    if (userState.paintType !== "" && btnDisable) {
      setBtnDisable(false);
    }
  }, [userState, paintAvailablity]);

  function onRadioClickHandler(eventName) {
    setUserState({
      ...userState,
      paintType: eventName,
    });
    LandingClick(eventName);
  }

  return (
    <div className="insideWrp">
      <div className="paint-home-section pcWidth paintPcWidth">
        <div className="paint_flex_with_left">
          <label className="paint_header mb-3">
            {t("paintingHomes.Painting_header")}
          </label>
        </div>
        <div className="col-12 d-flex flex-wrap">
          {items3.map((item, index) => (
            <div
              key={index}
              onClick={() => onRadioClickHandler(item?.name)}
              className="col-6 col-lg-4 mb-3"
              onMouseEnter={() => setIsHovered(item?.name)}
              onMouseLeave={() => setIsHovered("")}
            >
              <label
                className="w-100 px-2"
                style={{ height: "124px", cursor: "pointer" }}
              >
                <img
                  className="img-fluid"
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "15px",
                    border:
                      item?.name === userState?.paintType ||
                      isHovered === item?.name
                        ? "1.5px solid #2CCAE8"
                        : "1.5px solid transparent",
                  }}
                  src={item?.img}
                />
                <p
                  className=""
                  style={{
                    fontSize: "14px",
                    color:
                      item?.name === userState?.paintType ||
                      isHovered === item?.name
                        ? "#2CCAE8"
                        : "#000000",
                    margin: "10px 0px",
                    textAlign: "center",
                  }}
                >
                  <label className="paint_img_bottom_title_first">
                    {t(item.text)}
                  </label>
                  <label className="paint_img_bottom_title_last">
                    {t(item.text1)}
                  </label>
                  <input
                    type="radio"
                    name="paintType"
                    value={item?.name}
                    checked={item?.name === userState?.paintType}
                    className="invisible"
                  />
                </p>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const items3 = [
  {
    img: IMAGES.home.interiorpaint,
    text: "paintingHomes.Interior",
    text1: "paintingHomes.Painting",
    name: "interiorpaint",
  },
  {
    img: IMAGES.home.exteriorpaint,
    text: "paintingHomes.Exterior",
    text1: "paintingHomes.Painting",
    name: "exteriorpaint",
  },
  {
    img: IMAGES.home.deckpaint,
    text: "paintingHomes.Deck",
    text1: "paintingHomes.Painting",
    name: "deckpaint",
  },
  {
    img: IMAGES.home.fencepaint,
    text: "paintingHomes.Fence",
    text1: "paintingHomes.Painting",
    name: "fencepaint",
  },
  {
    img: IMAGES.home.otherpaint,
    text: "paintingHomes.Other",
    text1: "paintingHomes.Painting",
    name: "otherpaint",
  },
];
