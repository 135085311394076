import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { TextInput } from "../../_components/TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { treeModel } from "../../_redux/Models/tree.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { checkoutStore, treeStore } from "../../_redux/actions";

export const RootsRemovalMachine = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const stepValue = 6;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.treePayload
      ? state.buttonReducer.treePayload
      : treeModel
  );

  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state

  const [cannotReachWithMachinery, setCannotReachWithMachinery] = useState("");
  const [numberOfTreesOutOfReach, setNumberOfTreesOutOfReach] = useState(1);
  const [machineryPrice, setMachineryPrice] = useState(1);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (
      mounted === true &&
      priceDetails &&
      priceDetails.cannotReachWithMachinery
    ) {
      const cannotReachWithMachineryPrice =
        priceDetails.cannotReachWithMachinery.price;
      setMachineryPrice(
        cannotReachWithMachineryPrice * numberOfTreesOutOfReach
      );
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, numberOfTreesOutOfReach, state, t]);

  const calculatePrice = (selectedOption, selectedNumber) => {
    const cannotReachWithMachineryOp = selectedOption
      ? selectedOption
      : cannotReachWithMachinery;
    const cannotReachWithMachineryNumber = selectedNumber
      ? selectedNumber
      : numberOfTreesOutOfReach;
    const priceToAdd =
      cannotReachWithMachineryOp === "yes"
        ? machineryPrice * cannotReachWithMachineryNumber
        : 0;
    const total = calculateTotalCost(state.costFor);
    setPrice(total + priceToAdd);
  };

  const onSave = () => {
    const total = calculateTotalCost(state.costFor);
    state.anyTreesOutOfReach = cannotReachWithMachinery;
    state.numberOfTreesOutOfReach = numberOfTreesOutOfReach;
    const cost = cannotReachWithMachinery === "yes" ? machineryPrice : 0;
    state.costFor.machinery = cost;
    checkoutModelState.totalCost = total + cost;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(treeStore(state));
    props.history.push("/tree_services/order-summary");
  };

  return (
    <div className="insideWrp">
      <HeaderMenu />

      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="service-banner-sec stable_class">
          <div class="banner-bg">
            <img src={IMAGES.home.treeServicesBanner} alt="" />
          </div>
          <div className="floor-title">
            <label
              className="header_path_name_show"
              style={{ textTransform: "capitalize" }}
            >
              {state.serviceType === "tree_remotion"
                ? "Tree removal"
                : `${state.serviceType.replace(/_/g, " ")}`}
            </label>
          </div>
          <div class="banner-title">
            <label>{t("treeService.headerLink")}</label>
          </div>
        </div>

        {/* <label
          className="header_path_name_show"
          style={{ textTransform: "capitalize" }}
        >
          {state.serviceType === "tree_remotion"
            ? "Tree removal"
            : `${state.serviceType.replace(/_/g, " ")}`}
        </label> */}

        <div className="paint_main_buttons">
          <div className="paint_main_button_left">
            <ProgressBar progressValue={stepValue} />
            {/* <img src={IMAGES.other.stepper1} alt="" /> */}
            <div>
              <label className="paint_every_flow_title">
                {t("treeService.rootsRemoval")}
              </label>
            </div>
          </div>
          <div className="paint_main_button_right">
            <table>
              <tr>
                <td>
                  <img src={IMAGES.other.piggy} alt="" />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <label className="paint_every_price_txt">
                    {price > 0 ? `$${formatPrice(price)}` : "--"}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div className="padding10"></div>
          <div className="paint_flex_with_left paddinngtop20">
            <label className="paint_header" style={{ fontSize: "19px" }}>
              {t("treeService.CannotReachWithMachinery")}
              <span style={{ fontWeight: "normal" }}>
                (bobcat, skidder machines)
              </span>
              ?
            </label>
          </div>
          <div className="padding10"></div>
          <div className="padding10"></div>
          <div
            onClick={() => {
              setCannotReachWithMachinery("yes");
              calculatePrice("yes");
            }}
            className="paintPadding8 "
          >
            <div className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright ">
              <div
                // key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">Yes</label>
                <div
                  className="paint_arrowFloat flex"
                  style={{ paddingTop: "10px" }}
                >
                  <img
                    src={IMAGES.other.green_plus}
                    className="kitchenplusimg"
                    alt=""
                  />
                  <label
                    className="paintdiv_txt paddingleft8"
                    style={{ paddingLeft: "7px", paddingTop: "2px" }}
                  >
                    {`$${formatPrice(machineryPrice)}`}
                  </label>
                  <div
                    className="paint_arrowFloat"
                    style={{ paddingLeft: "7px" }}
                  >
                    <RadioButton
                      checked={cannotReachWithMachinery === "yes"}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="padding10"></div>

          <div
            onClick={() => {
              setCannotReachWithMachinery("no");
              calculatePrice("no");
            }}
            className="paintPadding8 "
          >
            <div className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright ">
              <div
                // key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">No</label>
                <div
                  className="paint_arrowFloat flex"
                  style={{ paddingTop: "10px" }}
                >
                  <img
                    src={IMAGES.other.green_plus}
                    className="kitchenplusimg"
                    alt=""
                  />
                  <label
                    className="paintdiv_txt paddingleft8"
                    style={{ paddingLeft: "7px", paddingTop: "2px" }}
                  >
                    {`$0.00`}
                  </label>
                  <div
                    className="paint_arrowFloat"
                    style={{ paddingLeft: "7px" }}
                  >
                    <RadioButton
                      checked={cannotReachWithMachinery === "no"}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="padding10"></div>
          {cannotReachWithMachinery === "yes" ? (
            <div>
              <div className="paint_flex_with_left paddinngtop20">
                <label className="paint_header" style={{ fontSize: "19px" }}>
                  {t("treeService.cantReachWithMachine")}
                </label>
              </div>
              <div className="padding10"></div>
              <div className="paint_flex_with_left ">
                <label className="paint_other_sub_header ">
                  {t("treeService.cantReachWithMachineTxt")}
                </label>
              </div>
              <div className="padding10"></div>

              <div className="padding10"></div>

              <div className="paintinput_lable mb40">
                <TextInput
                  className="paint_input_border"
                  onChange={(e) => {
                    if (e.target.value < 99) {
                      setNumberOfTreesOutOfReach(e.target.value);
                      calculatePrice(cannotReachWithMachinery, e.target.value);
                    }
                  }}
                  value={numberOfTreesOutOfReach}
                  type="number"
                  onKeyUp={() =>
                    calculatePrice(
                      cannotReachWithMachinery,
                      numberOfTreesOutOfReach
                    )
                  }
                  placeholder={t("Enter the number of trees")}
                  label={t("treeService.NumberOfTrees")}
                />
                <div className="paint_placeholder_right">
                  <label>{t("treeService.trees")}</label>
                </div>
              </div>
            </div>
          ) : null}

          <div className="profilePadding40"></div>
          <Button
            disabled={cannotReachWithMachinery === ""}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSave}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};
