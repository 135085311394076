export var signupModel = {
  flowName: "signup",
  _id: "",
  firstTimeuser: true,
  signuserType: "",
  property: "",
  registerForm: {
    fullName: "",
    email: "",
    password: "",
    zipCode: "",
    address: "",
  },
  userVerified: {},
  validationCode: "",
  services: [],
  selectedServices: [],
  listStatus: null,
  profileCompletion: 0,
  profileInfo: {
    profileSection: {
      ProfileInfo: {
        valid: false,
        value: "",
        error: {
          flag: null,
          message: "",
        },
      },

      companyHighlight: {
        valid: true,
        value: "",
        error: {
          flag: null,
          message: "",
        },
      },

      companydesc: {
        valid: true,
        value: "",
        error: {
          flag: null,
          message: "",
        },
      },

      managerName: {
        valid: false,
        value: "",
        error: {
          flag: null,
          message: "",
        },
      },

      yearsoperating: {
        valid: false,
        value: "",
        error: {
          flag: null,
          message: "",
        },
      },

      businessAddress: {
        valid: false,
        value: "",
        error: {
          flag: null,
          message: "",
        },
      },
      phoneNumber: {
        valid: false,
        value: "",
        error: {
          flag: null,
          message: "",
        },
      },
    },

    files: {
      profilePic: [],
      managerID: [],
      certificates: [],
      tradeLicencesDocs: [],
      picturesOfWorks: [],
    },
  },
};

export var siginModel = {
  firstTimeuser: false,
  userResponse: null,
};
