import React from "react";

const ConfirmPageImageGrid = ({
  imageArray,
  handleViewMore,
  handleIndex,
  imageRefs,
}) => {
  let element = "";
  switch (imageArray?.length) {
    case 0:
      element = null;
    case 1:
      element = (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img
              style={{ borderRadius: "20px", objectFit: "cover" }}
              src={imageArray?.[0]}
              onClick={() => handleIndex(0)}
            />
          </div>
        </div>
      );
      break;
    case 2:
      element = (
        <div className="row d-flex">
          {imageArray?.map((image, index) => (
            <div className="col-6">
              <img
                style={{
                  borderRadius: "20px",
                  height: "300px",
                  width: "100%",
                  objectFit: "cover",
                }}
                className="img-fluid m-1"
                src={image}
                onClick={() => handleIndex(index)}
              />
            </div>
          ))}
        </div>
      );
      break;
    case 3:
      element = (
        <div className="col-12 d-flex flex-wrap">
          <div className="col-6 pb-1 pe-1" style={{ height: "300px" }}>
            <img
              style={{ borderRadius: "20px", objectFit: "cover" }}
              className="img-fluid h-100 w-100"
              src={imageArray?.[0]}
              onClick={() => handleIndex(0)}
            />
          </div>
          <div
            className="col-6 pb-1 d-flex flex-column ps-1"
            style={{ height: "300px" }}
          >
            <div className="pb-1 w-100 h-50">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[1]}
                onClick={() => handleIndex(1)}
              />
            </div>
            <div className="pt-1 w-100 h-50">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100"
                src={imageArray?.[2]}
                onClick={() => handleIndex(2)}
              />
            </div>
          </div>
        </div>
      );
      break;
    case 4:
      element = (
        <div className="col-12 d-flex flex-wrap">
          {imageArray?.map((image, index) => (
            <div className="col-6" style={{ padding: "7px" }}>
              <img
                style={{
                  borderRadius: "10px",
                  height: "150px",
                  width: "100%",
                  objectFit: "cover",
                }}
                className="img-fluid secondary-img object-fill"
                src={image}
                onClick={() => handleIndex(index)}
              />
            </div>
          ))}
        </div>
      );
      break;
    case 5:
      element = (
        <div className="col-12 d-flex flex-wrap">
          <div className="col-6 pb-1 pe-1" style={{ height: "300px" }}>
            <img
              style={{ borderRadius: "20px", objectFit: "cover" }}
              className="img-fluid h-100 w-100 object-fill"
              src={imageArray?.[0]}
              onClick={() => handleIndex(0)}
            />
          </div>
          <div
            className="col-6 pb-1 d-flex flex-column ps-1"
            style={{ height: "300px" }}
          >
            <div className="pb-1 w-100 h-50">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[1]}
                onClick={() => handleIndex(1)}
              />
            </div>
            <div className="pt-1 w-100 h-50">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[2]}
                onClick={() => handleIndex(2)}
              />
            </div>
          </div>

          <div
            className="col-6 pt-1 pe-1 d-flex flex-column"
            style={{ height: "300px" }}
          >
            <div className="h-50 w-100 pb-1">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[3]}
                onClick={() => handleIndex(3)}
              />
            </div>
            <div className="h-50 w-100 pt-1">
              <img
                style={{
                  borderRadius: "20px",
                  height: "150px",
                  width: "100%",
                  objectFit: "cover",
                }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[4]}
                onClick={() => handleIndex(4)}
              />
            </div>
          </div>
        </div>
      );
      break;
    case 6:
      element = (
        <div className="col-12 d-flex flex-wrap">
          <div className="col-6 pb-1 pe-1" style={{ height: "300px" }}>
            <img
              style={{ borderRadius: "20px", objectFit: "cover" }}
              className="img-fluid h-100 w-100 object-fill"
              src={imageArray?.[0]}
              onClick={() => handleIndex(0)}
            />
          </div>
          <div
            className="col-6 pb-1 d-flex flex-column ps-1"
            style={{ height: "300px" }}
          >
            <div className="pb-1 w-100 h-50">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[1]}
                onClick={() => handleIndex(1)}
              />
            </div>
            <div className="pt-1 w-100 h-50">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[2]}
                onClick={() => handleIndex(2)}
              />
            </div>
          </div>

          <div
            className="col-6 pt-1 pe-1 d-flex flex-column"
            style={{ height: "300px" }}
          >
            <div className="h-50 w-100 pb-1">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[3]}
                onClick={() => handleIndex(3)}
              />
            </div>
            <div className="h-50 w-100 pt-1">
              <img
                style={{
                  borderRadius: "20px",
                  height: "150px",
                  width: "100%",
                  objectFit: "cover",
                }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[4]}
                onClick={() => handleIndex(4)}
              />
            </div>
          </div>
          <div className="col-6 pt-1 ps-1" style={{ height: "300px" }}>
            <img
              style={{ borderRadius: "20px", objectFit: "cover" }}
              className="img-fluid h-100 w-100 object-fill"
              src={imageArray?.[5]}
              onClick={() => handleIndex(5)}
            />
          </div>
        </div>
      );
      break;
    default:
      element = (
        <div className="col-12 d-flex flex-wrap position-relative">
          <div className="col-6 pb-1 pe-1" style={{ height: "300px" }}>
            <img
              style={{ borderRadius: "20px", objectFit: "cover" }}
              className="img-fluid h-100 w-100 object-fill"
              src={imageArray?.[0]}
              onClick={() => handleIndex(0)}
            />
          </div>
          <div
            className="col-6 pb-1 d-flex flex-column ps-1"
            style={{ height: "300px" }}
          >
            <div className="pb-1 w-100 h-50">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[1]}
                onClick={() => handleIndex(1)}
              />
            </div>
            <div className="pt-1 w-100 h-50">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[2]}
                onClick={() => handleIndex(2)}
              />
            </div>
          </div>

          <div
            className="col-6 pt-1 pe-1 d-flex flex-column"
            style={{ height: "300px" }}
          >
            <div className="h-50 w-100 pb-1">
              <img
                style={{ borderRadius: "20px", objectFit: "cover" }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[3]}
                onClick={() => handleIndex(3)}
              />
            </div>
            <div className="h-50 w-100 pt-1">
              <img
                style={{
                  borderRadius: "20px",
                  height: "150px",
                  width: "100%",
                  objectFit: "cover",
                }}
                className="img-fluid h-100 w-100 object-fill"
                src={imageArray?.[4]}
                onClick={() => handleIndex(4)}
              />
            </div>
          </div>
          <div className="col-6 pt-1 ps-1" style={{ height: "300px" }}>
            <img
              style={{ borderRadius: "20px", objectFit: "cover" }}
              className="img-fluid h-100 w-100 object-fill"
              src={imageArray?.[5]}
              onClick={() => handleIndex(5)}
            />
          </div>
          {imageArray?.length > 6 ? (
            <div className="view-more" onClick={(e) => handleIndex(0)}>
              View {imageArray?.length - 6} more
            </div>
          ) : (
            ""
          )}
        </div>
      );
  }
  return <>{element}</>;
};

export default ConfirmPageImageGrid;
