import { CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import IMAGES from "../../../_assets/images";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../services/api/config/api.config";
import useNotify from "../../../_components/Notification/UseNotify";
import { DebouncedInput } from "../../../_components/DebouncedInput";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { parseJwt } from "../../../services/utils/utilFunctions";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { API_URL } from "../../../services/apiConstants";
import i18next from "i18next";
import axios from "axios";
import Loader from "../../../_components/spinner/Loader";

const AddServiceLocationsForm = ({ dropDownRef }) => {
  const { t } = useTranslation();
  const { confirm } = useNotify();
  const inputRef = useRef();
  const [verifying, setVerifying] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { signUp } = location?.state || {};
  const history = useHistory();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedPlaces, setSelectedPlaces] = useState([]);

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_MAP_API_KEY,
      options: { types: ["(regions)"] },
    });

  useEffect(async () => {
    const user = parseJwt(localStorage.getItem("auth_token"));

    try {
      setLoading(true);
      var res = await axiosInstance.get(`/contractor/${user._id}/profile`);
      let address = res?.data?.address;
      const transformedData = address?.map((item) => ({
        postalCode: item.zip,
      }));

      // Set the transformed data to the state
      setSelectedPlaces(transformedData);
    } catch (err) {
      confirm("error", err?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, []);

  const onPlaceSelected = (place) => {
    geocodeByPlaceId(place?.place_id)
      .then((res) => {
        const postalCode = res[0]?.address_components.find((component) =>
          component?.types?.includes("postal_code")
        )?.long_name;
        if (postalCode) {
          let filtered = selectedPlaces?.filter(
            (item) =>
              item.postalCode === postalCode &&
              item?.place?.place_id === place?.place_id
          );
          if (filtered && filtered?.length > 0) {
            confirm("error", `This place is already selected`);
          } else {
            selectedPlaces?.push({ place, postalCode });
          }
        }
        setIsDropDownOpen(false);
        setSearch("");
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (item) => {
    setSelectedPlaces((prev) => [
      ...prev?.filter((place) => place?.postalCode !== item),
    ]);
  };

  const handleSubmit = async () => {
    let payload = {
      zipcodes: selectedPlaces.map((itm) => itm.postalCode),
    };
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (selectedPlaces?.length <= 0) {
      confirm("error", "Alteast one location must be added");
    } else {
      try {
        setVerifying(true);
        var res = await axiosInstance.put(
          `/contractor/${user._id}/multiple-service-locations`,
          payload
        );
        if (res && res.status === 200) {
          confirm("success", "Service location added successfully");
          if (signUp) {
            history.push(`/bank-details/edit`, { signUp: true });
          }
        }
      } catch (err) {
        confirm(
          "error",
          err?.response?.data?.message || "Something went wrong"
        );
      } finally {
        setVerifying(false);
      }
    }
  };

  return (
    <div className="" style={{}}>
      {loading ? (
        <Loader progress={loading} />
      ) : (
        <>
          <img src={IMAGES.home.locationIcon} height="50" width="50" />
          <h1 className="verify-head">{t("serviceLocations.head")}</h1>
          <div className="d-flex flex-column gap-4 position-relative w-100">
            <DebouncedInput
              placeholder={t("serviceLocations.head")}
              ref={inputRef}
              type="text"
              className="location-input"
              onInputChange={(val) => {
                getPlacePredictions({ input: val });
                setIsDropDownOpen(true);
              }}
              debounceTime={100}
              value={search}
              setValue={setSearch}
            />

            {placePredictions?.length > 0 && isDropDownOpen ? (
              <div
                ref={dropDownRef}
                className="location-dropdown position-relative d-flex flex-column bg-white w-100"
              >
                {placePredictions?.map((prediction) => (
                  <div
                    className="places-item d-flex w-100"
                    onClick={() => onPlaceSelected(prediction)}
                  >
                    <span className="">{prediction?.description}</span>
                  </div>
                ))}
              </div>
            ) : (
              " "
            )}
            <div className="d-flex flex-wrap w-full">
              {selectedPlaces?.map((item) => (
                <div className="selected-location">
                  <span>{item?.postalCode}</span>
                  <img
                    onClick={() => handleDelete(item?.postalCode)}
                    src={IMAGES.home.smallCloseIcon}
                    alt=""
                  />
                </div>
              ))}
            </div>
            <button
              onClick={handleSubmit}
              className="verify-btn d-flex align-items-center justify-content-center"
              style={{ marginTop: "30px" }}
            >
              Save
              {verifying ? (
                <CircularProgress
                  sx={{ color: "#fff", marginLeft: "10px" }}
                  size={24}
                />
              ) : (
                ""
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AddServiceLocationsForm;
