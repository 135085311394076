import IMAGES from '../../_assets/images/index'

const kitchenSliderItems=[
  {
   urls: IMAGES.home.kitchenSlider1,
  },
  {
   urls: IMAGES.home.kitchenSlider2,
  },
  { 
   urls: IMAGES.home.kitchenSlider3,
  },
  {
   urls: IMAGES.home.kitchenSlider4,
  },
  {
   urls: IMAGES.home.kitchenSlider5,
  },
  {
   urls: IMAGES.home.kitchenSlider6,
  },
  {
   urls: IMAGES.home.kitchenSlider7,
  },
  {
   urls: IMAGES.home.kitchenSlider8,
  },
  {
   urls: IMAGES.home.kitchenSlider9,
  },
];
export default kitchenSliderItems