import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { TextInput } from "../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import CheckBox from "../../_components/CheckBox/checkBox";
import { Button } from "../../_components/Button/Button";
import Traditionalslider from "../../_container/bathroomComponents/traditionalSlider";
import Fashionedslider from "../../_container/bathroomComponents/fashionedSlider";
import Beachslider from "../../_container/bathroomComponents/beachSlider";
import Rusticslider from "../../_container/bathroomComponents/rusticSlider";
import Mordenslider from "../../_container/bathroomComponents/modernSlider";
import Otherslider from "../../_container/bathroomComponents/otherSlider";
import { bathroomModel } from "../../_redux/Models/categories.model";
import { bathroomStore, checkoutStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getServiceCategoriesFull } from "../../services/api/category.api.service";
import { bathroomFlowModel } from "../../_redux/Models/bathroom.model";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import BearCarousel, { BearSlideItem } from "bear-react-carousel";
import traditionalImages from "../../_container/bathroomComponents/traditionalImages";
import modernImages from "../../_container/bathroomComponents/modernImages";
import fashionedImages from "../../_container/bathroomComponents/fashionedImages";
import rusticImages from "../../_container/bathroomComponents/rusticImages";
import beachImages from "../../_container/bathroomComponents/beachImages";
import otherImages from "../../_container/bathroomComponents/otherImages";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const Bathroomlanding = (props) => {
  const { bathroomPayload } = useSelector((state) => state.buttonReducer);
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const dispatch = useDispatch();
  const scrollPage = useRef();
  const { t } = useTranslation();
  const history = useHistory();
  const [styleState, setStyleState] = useState("");
  const [category, setCategory] = React.useState([]);
  const [isHovered, setIsHovered] = useState("");

  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const getSliderImageData = (images) => {
    return images.map((image, index) => {
      return {
        key: index,
        children: <BearSlideItem imageUrl={image.urls} />,
      };
    });
  };

  const materials = [
    {
      value: "traditional",
      name: "Traditional",
      images: traditionalImages[0]?.urls,
    },
    {
      value: "modern",
      name: "Modern",
      images: modernImages[0]?.urls,
    },
    {
      value: "fashioned",
      name: "Old fashioned",
      images: fashionedImages[0]?.urls,
    },
    {
      value: "rustic",
      name: "Rustic",
      images: rusticImages[0]?.urls,
    },
    {
      value: "handicap",
      name: "Beach style",
      images: beachImages[0]?.urls,
    },
    {
      value: "custom",
      name: "Custom",
      images: otherImages[0]?.urls,
    },
  ];

  const onClick = () => {
    // Save the current flow to checkout
    let selectedServiceDetails = null;
    let serviceType = "Bathroom renovation";
    if (category && category.subCategory && serviceType) {
      selectedServiceDetails = category.subCategory.find(
        (sub) => sub.key === "renovations"
      );
    }

    checkoutModelAs.selectedService = serviceType;
    checkoutModelAs.selectedServiceDetails = selectedServiceDetails;
    checkoutModelAs.totalCost = 0;
    checkoutModelAs.priceDetails = [];
    dispatch(checkoutStore(checkoutModelAs));

    bathroomFlowModel.selectedStyle = styleState;
    dispatch(bathroomStore(bathroomFlowModel));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/bathroom/how-many-bathrooms`
    );
  };

  // Call the service to get full available list
  useEffect(() => {
    let mounted = true;
    getServiceCategoriesFull().then((items) => {
      if (mounted && items.status === 200) {
        let filteredItem = items.data.filter(
          (item) => item.name === "Bathroom"
        );
        filteredItem = filteredItem ? filteredItem[0] : null;
        setCategory(filteredItem);
      }
    });
    return () => (mounted = false);
  }, []);

  useLayoutEffect(() => {
    if (bathroomPayload && bathroomPayload != undefined) {
      setStyleState(bathroomPayload.bathroomStyle);
    }
  }, [bathroomPayload]);

  function onRadioClickHandler(eventName) {
    // scrollPage.current.scrollIntoView({ behavior: "smooth" });
    setStyleState(eventName);
    onClick();
  }

  return (
    <div className="insideWrp pc-767">
      <div className="" style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header mb-3">
            {t("Bathroom.selectbathroom")}
          </label>
        </div>
        <div class="grid-slider padng-top6 d-flex flex-wrap">
          {materials?.map((material) => (
            <div
              className="col-6 col-lg-4 mb-3"
              onMouseEnter={() => setIsHovered(material.value)}
              onMouseLeave={() => setIsHovered("")}
              onClick={() => onRadioClickHandler(material?.value)}
            >
              <label
                className="w-100 px-2"
                style={{ height: "124px", cursor: "pointer" }}
              >
                <img
                  className="img-fluid"
                  style={{
                    height: "100%",
                    borderRadius: "15px",
                    width: "100%",
                    border:
                      material?.value === styleState ||
                      isHovered === material?.value
                        ? "1.5px solid #2CCAE8"
                        : "1.5px solid transparent",
                  }}
                  src={material?.images}
                />
                <p
                  className=""
                  style={{
                    fontSize: "14px",
                    color:
                      material?.value === styleState ||
                      isHovered === material?.value
                        ? "#2CCAE8"
                        : "#000000",
                    margin: "10px 0px",
                    textAlign: "center",
                  }}
                >
                  {material?.name}
                  <input
                    type="radio"
                    name="materialSelected"
                    value={material?.value}
                    checked={material?.value === styleState}
                    className="invisible"
                  />
                </p>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
