import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { roofingStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { roofingModel } from "../../_redux/Models/roofing.model";
import { DropDown } from "../../_components/dropDown/dropDown";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const RemovalOfPlywood = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);
  const stepValue = 3;

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.roofingPayload
      ? state.buttonReducer.roofingPayload
      : roofingModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [option, setOption] = useState("");
  const [numberOfPlywoods, setNumberOfPlywoods] = useState(1);
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state
  const [priceOfPlywood, setPriceOfPlywood] = useState(0);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails && priceDetails.plywood) {
      const priceOfPlywoodService = priceDetails.plywood.price;
      setPriceOfPlywood(priceOfPlywoodService);
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state, t]);

  const onSave = () => {
    state.needReplaceOfPlywoods = option;
    state.numberOfPlywoods = option === "yes" ? numberOfPlywoods : "";
    state.costFor.plywoodChange =
      option === "yes" ? price - calculateTotalCost(state.costFor) : 0;
    checkoutModelState.totalCost =
      option === "yes" ? price : calculateTotalCost(state.costFor);
    dispatch(roofingStore(state));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/roofing/order-summary`
    );
  };

  const changeNumberOfPlywoods = (number) => {
    const totalPrice = calculateTotalCost(state.costFor);
    setPrice(totalPrice + priceOfPlywood * number);
    setNumberOfPlywoods(number);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {"Do you need to replace any plywood?"}
            </label>
          </div>
          <div className="padding10"></div>
          <div class="paint_flex_with_left ">
            <label class="paint_other_sub_header ">
              {"Please enter the quantity of repairs you need to do"}
            </label>
          </div>

          <div
            className={`floor-selection-radio ${
              option === "yes" ? "selected" : ""
            }`}
            onClick={() => {
              setOption("yes");
              const totalPrice = calculateTotalCost(state.costFor);
              setPrice(totalPrice + priceOfPlywood * numberOfPlywoods);
            }}
          >
            <div
              // key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Yes</label>
              <div
                className="paint_arrowFloat"
                style={{
                  width: "130px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img src={IMAGES.other.green_plus} alt="" />
                  <label className="paintdiv_txt">
                    {`$${formatPrice(priceOfPlywood * numberOfPlywoods)}`}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setOption("no");
              const totalPrice = calculateTotalCost(state.costFor);
              setPrice(totalPrice);
            }}
            className={`floor-selection-radio mb-3 ${
              option === "no" ? "selected" : ""
            }`}
          >
            <div
              // key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">No</label>
              <div
                className="paint_arrowFloat"
                style={{
                  width: "130px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img src={IMAGES.other.green_plus} alt="" />
                  <label className="paintdiv_txt">{`$0.00`}</label>
                </div>
              </div>
            </div>
          </div>
          {option === "yes" ? (
            <div className="mb-3">
              <div className="paint_flex_with_left">
                <label className="paint_header">
                  {"How many plywoods do you need to replace?"}
                </label>
              </div>
              <div className="padding10"></div>
              <div className="padding10"></div>
              <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
                <table className="paint_table100">
                  <tr>
                    <td>
                      <div
                        className="typeofTxt"
                        style={{ display: "grid", paddingTop: "0" }}
                      >
                        <DropDown
                          value={numberOfPlywoods}
                          callback={(event) =>
                            changeNumberOfPlywoods(parseInt(event.target.value))
                          }
                          length={100}
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          ) : null}
          <Button
            disabled={option === ""}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSave}
          />
        </div>
      </div>
    </div>
  );
};
