import React, { useRef, useState } from "react";
import IMAGES from "../../../../_assets/images/index";

function Step4SolidHardwood(props) {
  const btnEl = useRef();
  const materialEl = useRef();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [material, setMaterial] = useState(false);
  const [selectedWood, setSelectedWood] = useState();
  const [selectedSize, setSelectedSize] = useState();

  const [radioCheck, setRadioCheck] = useState({
    selectType: "",
    selectSize: "",
  });
  const onChangeRadio = (e) => {
    setSelectedWood(e.target.value);
    setMaterial(true);
    setTimeout(function () {
      materialEl.current.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };
  const onChangeSize = (e) => {
    setSelectedSize(e.target.value);
    setBtnDisabled(false);
    setTimeout(function () {
      //   btnEl.current.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const btnClick = () => {
    props.onSuccess({ wood: selectedWood, size: selectedSize });
  };
  return (
    <div className="m-t-32">
      <div className="floor-sub-title">Select type of wood</div>
      <div className="m-t-32">
        {items.map((item, index) => (
          <label key={index} className="floor-selection-radio">
            <img
              style={{ height: "78px", width: "42px", marginLeft: "-34px" }}
              src={item.src}
              alt=""
            />
            <div style={{ width: "100px", textAlign: "left" }}>{item.text}</div>
            <div style={{display:"flex",placeItems:"center"}}><div style={{paddingTop:"3px"}}><img width="15" height="15" src={IMAGES.other.green_plus} alt="" /></div><div style={{paddingLeft:"10px"}}>{item.price}</div></div>
            <input
              type="radio"
              name="selectedWood"
              value={item.text}
              onChange={onChangeRadio}
            />
          </label>
        ))}
      </div>

      {material ? (
        <div className="m-t-32">
          <div ref={materialEl} className="floor-sub-title">
            Select size details
          </div>
          <div className="m-t-32">
            {flooritems.map((item, index) => (
              <label key={index} className="floor-selection-radio">
                <div style={{ width: "100px", textAlign: "left" }}>
                  {item.size}
                </div>
                <div style={{display:"flex",placeItems:"center"}}><div style={{paddingTop:"3px"}}><img width="15" height="15" src={IMAGES.other.green_plus} alt="" /></div><div style={{paddingLeft:"10px"}}>{item.price}</div></div>
                <input
                  type="radio"
                  name="selectedSize"
                  value={item.size}
                  onChange={onChangeSize}
                />
              </label>
            ))}
          </div>
          <button
            // ref={btnEl}
            className="m-t-32 floor-button"
            disabled={btnDisabled}
            onClick={btnClick}
          >
            Continue to next
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default Step4SolidHardwood;

const items = [
  {
    text: "White oak",
    src: IMAGES.home.lightwood,
    price: "$0000.-",
  },
  {
    text: "Red oak",
    src: IMAGES.home.solidoak,
    price: "$0000.-",
  },
  {
    text: "Brazilian oak",
    src: IMAGES.home.tiless,
    price: "$0000.-",
  },
  {
    text: "Bamboo",
    src: IMAGES.home.woodtext,
    price: "$0000.-",
  },
  {
    text: "Other",
    src: IMAGES.home.woodtxt,
    price: "$0000.-",
  },
];
const flooritems = [
  {
    size: "2” in 1/4",
    price: "$0000.-",
  },
  {
    size: "3” in 1/4",
    price: "$0000.-",
  },
  {
    size: "4” in 1/4",
    price: "$0000.-",
  },
  {
    size: "5” in 1/4",
    price: "$0000.-",
  },
];
