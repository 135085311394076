import { CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images";
import useNotify from "../../_components/Notification/UseNotify";
import "./style.scss";
import { parseJwt } from "../../services/utils/utilFunctions";
import axiosInstance from "../../services/api/config/api.config";

const AddAddress = ({ setAddaddressModal, addressDetails }) => {
  const { t } = useTranslation();
  const { confirm } = useNotify();
  const ref = useRef();

  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    fullAddress: {
      value: "",
      valid: false,
      error: {
        flag: false,
        message: "",
      },
    },
    city: {
      value: "",
      valid: false,
      error: {
        flag: false,
        message: "",
      },
    },
    state: {
      value: "",
      valid: false,
      error: {
        flag: false,
        message: "",
      },
    },
    zip: {
      value: "",
      valid: false,
      error: {
        flag: false,
        message: "",
      },
    },
    landmark: {
      value: "",
      valid: false,
      error: {
        flag: false,
        message: "",
      },
    },
  });

  useEffect(() => {
    console.log(addressDetails);
    if (addressDetails) {
      setFormData({
        fullAddress: {
          value: addressDetails.fullAddress,
          valid: false,
          error: {
            flag: false,
            message: "",
          },
        },
        city: {
          value: addressDetails.city,
          valid: false,
          error: {
            flag: false,
            message: "",
          },
        },
        state: {
          value: addressDetails.state,
          valid: false,
          error: {
            flag: false,
            message: "",
          },
        },
        zip: {
          value: addressDetails.zip,
          valid: false,
          error: {
            flag: false,
            message: "",
          },
        },
        landmark: {
          value: addressDetails.addressDetails,
          valid: false,
          error: {
            flag: false,
            message: "",
          },
        },
      });
    }
  }, [addressDetails]);
  const validate = (name, value, message) => {
    const newState = { ...formData };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;

    switch (name) {
      case "state":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "State is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "city":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "City is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "zip":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Zip code is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "fullAddress":
        if (!value || value === "") {
          errorMessage =
            errorMessage !== null ? errorMessage : "Address is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;
      case "landmark":
        errorMessage = errorMessage !== null ? errorMessage : "";
        newState[name].error.flag = false;
        newState[name].valid = true;
        newState[name].error.message = "";
        return newState;

      // // case "landmark":
      //   if (!value) {
      //     errorMessage =
      //       errorMessage !== null ? errorMessage : "Landmark is required";
      //     newState[name].error.flag = true;
      //     newState[name].valid = false;
      //     newState[name].error.message = errorMessage;
      //   } else if (value) {
      //     errorMessage = errorMessage !== null ? errorMessage : "";
      //     newState[name].error.flag = false;
      //     newState[name].valid = true;
      //     newState[name].error.message = "";
      //   }
      //   return newState;

      default: {
        return newState;
      }
    }
  };

  const formValidation = (data) => {
    let isValid = false;
    ["fullAddress", "state", "city", "zip"].forEach((item) => {
      if (data[item]?.value !== "") {
        isValid = true;
      } else {
        isValid = false;
        return;
      }
    });
    return isValid;
  };

  const handleInputChange = (e) => {
    let name = e?.target?.name;
    let a = validate(name, e?.target?.value);
    setFormData(a);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = parseJwt(localStorage.getItem("auth_token"));
    const formValid = formValidation(formData);
    let payload = {
      address: {
        fullAddress: formData?.fullAddress?.value,
        zip: formData?.zip?.value,
        city: formData?.city?.value,
        state: formData?.state?.value,
        addressDetails: formData?.landmark?.value,
      },
    };
    let payloadEdit = {
      address: {
        fullAddress: formData?.fullAddress?.value,
        zip: formData?.zip?.value,
        city: formData?.city?.value,
        state: formData?.state?.value,
        addressDetails: formData?.landmark?.value,
        _id: addressDetails?._id,
      },
    };
    if (formValid) {
      setLoader(true);

      try {
        if (user && user.userType === "contractor") {
          var res = await axiosInstance.put(
            `/contractor/${user._id}/address`,
            addressDetails ? payloadEdit : payload
          );
          if (res && res.status === 200) {
            confirm("success", "Address added successfully");
          }
        } else if (user && user.userType === "owner") {
          var res = await axiosInstance.put(
            `/owner/${user._id}/address`,
            addressDetails ? payloadEdit : payload
          );
          if (res && res.statusdata?.code === 200) {
            confirm("success", "Address added successfully");
          }
          setLoader(false);
          setAddaddressModal(false);
        }
      } catch (err) {
        confirm(
          "error",
          err?.response?.data?.message || "Something went wrong"
        );
      }
    } else {
      Object.values(formData).forEach((el, i) => {
        if (!el.valid) {
          let keyValue = Object.keys(formData)[i];
          var errorState = validate(keyValue, el.value, undefined);
          setFormData(errorState);
        }
      });
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <img src={IMAGES.home.homeIcon} height="50" width="50" />

      <h1 className="verify-head">
        {!addressDetails ? t("addAddress.head") : t("addAddress.editHead")}
      </h1>
      <form
        className="add-address-form d-flex flex-column gap-4 position-relative w-100"
        onSubmit={handleSubmit}
      >
        <p className="mb-0">{t("addAddress.fullAddress")}</p>
        <div className="d-flex flex-column">
          <input
            type="text"
            className={`${
              formData?.fullAddress?.error?.flag && "border-danger"
            } input-large`}
            placeholder={t("addAddress.sampleAddress")}
            onChange={handleInputChange}
            name="fullAddress"
            value={formData?.fullAddress?.value}
          />
          <small className="error-message">
            {formData?.fullAddress?.error?.message}
          </small>
        </div>
        <div className="d-flex flex-wrap flex-md-nowrap justify-content-between w-100 gap-2">
          <div className="d-flex flex-column">
            <input
              type="text"
              className={`${formData?.city?.error?.flag && "border-danger"}`}
              placeholder={t("addAddress.city")}
              onChange={handleInputChange}
              name="city"
              value={formData?.city?.value}
            />
            <small className="error-message">
              {formData?.city?.error?.message}
            </small>
          </div>
          <div className="d-flex flex-column">
            <input
              type="text"
              className={`${formData?.state?.error?.flag && "border-danger"}`}
              placeholder={t("addAddress.state")}
              onChange={handleInputChange}
              name="state"
              value={formData?.state?.value}
            />
            <small className="error-message">
              {formData?.state?.error?.message}
            </small>
          </div>
        </div>
        <div className="d-flex flex-column">
          <input
            type="text"
            className={`${formData?.zip?.error?.flag && "border-danger"}`}
            placeholder={t("addAddress.zip")}
            onChange={handleInputChange}
            name="zip"
            value={formData?.zip?.value}
          />
          <small className="error-message">
            {formData?.zip?.error?.message}
          </small>
        </div>
        <div className="d-flex flex-column">
          <input
            type="text"
            className={`${
              formData?.landmark?.error?.flag && "border-danger"
            } input-large`}
            placeholder={t("addAddress.landmark")}
            onChange={handleInputChange}
            name="landmark"
            value={formData?.landmark?.value}
          />
          <small className="error-message">
            {formData?.landmark?.error?.message}
          </small>
        </div>
        <button
          type="submit"
          className="verify-btn d-flex align-items-center justify-content-center"
          style={{ marginTop: "30px" }}
        >
          {t("addAddress.save")}
          {loader ? (
            <CircularProgress
              sx={{ color: "#fff", marginLeft: "10px" }}
              size={24}
            />
          ) : (
            ""
          )}
        </button>
      </form>
    </>
  );
};

export default AddAddress;
