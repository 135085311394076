import React, { useLayoutEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import "../../_styles/globalStyles/global.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { roofingStore, windowStore } from "../../_redux/actions";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { windowsModel } from "../../_redux/Models/window.model";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const WindowsOrderSummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 7;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [orderSummary, setOrderSummary] = useState([]);

  const payload = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.windowsPayload
      ? state.buttonReducer.windowsPayload
      : windowsModel
  );
  const price = calculateTotalCost(payload.costFor); // To keep the total price in state
  const [comment, setComment] = useState(payload.comments);
  /**
   * On save Comment
   */
  const saveComment = () => {
    payload.comments = comment;
    dispatch(windowStore(payload));
    window.location = `/contractor-portfolio/${selectedContractorId}/confirm`;
  };

  useLayoutEffect(() => {
    if (payload && payload !== undefined) {
      const summary = [
        {
          heading: "Type of work",
          text: `${payload.serviceType}`,
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading: "Reason to install windows",
          text: `${payload.reasonForWindow} (${payload.numberOfWindowsNeeded})`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.windows,
        },
        {
          heading: "Do you have the windows?",
          text: `${payload.haveOwnWindows}`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.material,
        },
        {
          heading: "Windows",
          text: payload.windowDetails.map((addOn, i) => {
            const add = addOn.name;
            if (add !== "") {
              return (
                <span key={i}>
                  {`${add}(${addOn.width}x${addOn.height}) (${addOn.count})`}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading: "Add on´s",
          text: payload.addOns.map((addOn, i) => {
            const add = addOn.text;
            if (add !== "") {
              return (
                <span key={i}>
                  {`${add} (${addOn.count})`}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
          img: IMAGES.other.green_plus,
          price: payload.costFor.addOns,
        },
      ];

      setOrderSummary(summary);
    }
  }, [payload, t]);

  return (
    <div className="service insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="sevice_title">{t("orderSummary.mainHeading")} </div>
        <label className="sub_heading" style={{ marginTop: "7px" }}>
          {payload.serviceType === "repair"
            ? "Windows repair estimate"
            : "Windows installation estimation"}
        </label>
        <br />
        <div className="paintpaddingtop25"></div>
        {orderSummary.map((item, index) =>
          item.text.length > 0 ? (
            <div key={index}>
              <label
                className="floor-selection-radio"
                style={{
                  // height: "88px",
                  flexDirection: "column",
                  padding: "15px",
                  alignItems: "start",
                  marginBottom: "15px",
                }}
              >
                <div className="bold">{item.heading}</div>
                <div
                  className="flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="text_width"
                    style={{
                      textTransform: "capitalize",
                      width: "100%",
                      paddingRight: "25px",
                      textAlign: "left",
                    }}
                  >
                    {item.text}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      placeItems: "center",
                      width: "155px",
                    }}
                  >
                    <div style={{ paddingTop: "3px" }}>
                      <img
                        width="15"
                        height="15"
                        src={IMAGES.other.green_plus}
                        alt=""
                      />
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      {`$${formatPrice(parseFloat(item.price))}`}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          ) : null
        )}
        {payload.serviceType === "repair" && (
          <label className="sub_heading" style={{ marginTop: "7px" }}>
            {t("roofing.infoAboutOneLayerRemoval")}
          </label>
        )}
        <table>
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("paintingInterior.ordercommands")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message plcehlder_align"
              />
            </td>
          </tr>
        </table>
        <Button
          disabled={false}
          buttonLabel={t("orderSummary.continue")}
          onClick={saveComment}
        />
        <div className="contactblue_btn mb-5 mt-4">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td onClick={() => (window.location = "/contact-us")}>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon mb-3">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>
    </div>
  );
};
