import React, {useState } from "react";
// import kitchenContent from "../../_container/kitchenComponents/kitchenContent";
import SliderContent from "../../_container/bathroomComponents/SliderContent";
import Dots from "../../_components/Slider/Dots";
import Arrows from "../../_components/Slider/Arrows";
import otherImages from "../../_container/bathroomComponents/otherImages";
import "../../_styles/localStyles/kitchen.css"


const len = otherImages.length - 1;

function Otherslider(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="kitchen-slider-container">
      <SliderContent activeIndex={activeIndex} sliderImage={otherImages} />
     
      <Dots
        activeIndex={activeIndex}
        sliderImage={otherImages}
        onclick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
}

export default Otherslider;