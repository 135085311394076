import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IMAGES from "./../../../_assets/images";
import SolidHardwoodIndex from "./index";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import { Footer } from "../../../_components/footer/Footer";
import TypeInstallation from "./typeInstallation";
import StepperSolidHardwood from "./stepperSolidHardWood";
import PersonalInfo from "./stepperSolidHardWood/personalInfo";
import StepperLastSolidHardwood from "./stepperSolidHardWood/lastStep";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import { getServiceCategoriesFull } from "../../../services/api/category.api.service";
import { floorSolidHardwoodModel } from "../../../_redux/Models/Floor.model";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

export default function SolidHardwood(props) {
  const divEl = useRef();
  const dispatch = useDispatch();
  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [selectedContractorId, setSelectedContractorId] = useRecoilState(
    selectedContractorAtom
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const [value, setValue] = React.useState({});
  const [category, setCategory] = React.useState({});
  let step = 1;
  const history = useHistory();

  // Call the service to get full available list
  useEffect(() => {
    let mounted = true;
    getServiceCategoriesFull().then((items) => {
      if (mounted && items.status === 200) {
        let filteredItem = items.data.filter((item) => item.name === "Floors");
        filteredItem = filteredItem ? filteredItem[0] : null;
        setCategory(filteredItem);
      }
    });
    return () => (mounted = false);
  }, []);

  const handleNext = (val) => {
    if (val.typeOfService === "Repair") {
      window.location = "/contact-us";
      return;
    }

    var selectedVal = val.materialSelected;
    var url = "";
    var selectedServiceType = "";
    switch (selectedVal) {
      case "SolidHardwood":
        setValue(val);
        setActiveStep(activeStep + 1);
        divEl?.current?.scrollIntoView({ behavior: "smooth" });
        floorSolidHardwoodModel.serviceType = val.typeOfService.toLowerCase();
        dispatch(floorStore(floorSolidHardwoodModel));
        url = `/contractor-portfolio/${selectedContractorId}/floors/solid-hardwood/floor-size/1`;
        selectedServiceType = "solid_hardwood";
        break;
      case "Engineered":
        url = `/contractor-portfolio/${selectedContractorId}/floors/engineered/introduce-floor-size/1`;
        selectedServiceType = "engineered";
        break;
      case "Carpet":
        url = `/contractor-portfolio/${selectedContractorId}/floors/carpet/floor-size/1`;
        selectedServiceType = "carpet";
        break;
      case "Tiles":
        url = `/contractor-portfolio/${selectedContractorId}/floors/tiles/floor-size/1`;
        selectedServiceType = "tiles";
        break;
      case "Epoxy":
        url = `/contractor-portfolio/${selectedContractorId}/floors/epoxy/floor-size/1`;
        selectedServiceType = "epoxy_coating";
        break;
      case "Laminated":
        url = `/contractor-portfolio/${selectedContractorId}/floors/laminated/floor-size/1`;
        selectedServiceType = "laminated";
        break;
      case "Other":
        url = "/contact-us";
        break;
      default:
        break;
    }

    // Save the current flow to checkout
    let selectedServiceDetails = null;
    if (category && category.subCategory && selectedServiceType) {
      selectedServiceDetails = category.subCategory.filter(
        (sub) => sub.key === selectedServiceType
      );
      selectedServiceDetails = selectedServiceDetails
        ? selectedServiceDetails[0]
        : "";
    }

    checkoutModelAs.selectedService = selectedVal;
    checkoutModelAs.selectedServiceDetails = selectedServiceDetails;
    dispatch(checkoutStore(checkoutModelAs));
    if (url === "/contact-us") {
      window.location = "/contact-us";
    } else if (url !== "") {
      history.push(url);
    }
  };
  const handleLast = () => {
    step = 7;
    setActiveStep(activeStep + 1);
    divEl.current.scrollIntoView({ behavior: "smooth" });
  };

  // useEffect(() => {
  //   divEl.current.scrollIntoView({ behavior: "smooth" });
  // }, []);

  return (
    <div className="" ref={divEl}>
      <div className="">
        {activeStep === 0 ? (
          <SolidHardwoodIndex onSuccess={(val) => handleNext(val)} />
        ) : null}
        {/* {activeStep === 1 ? (
          <StepperSolidHardwood
            step={1}
            val={value}
            onSuccess={(val) => handleNext(val)}
            progressValue={(val) => setProgress(val)}
          />
        ) : null}
        {activeStep === 2 ? (
          <PersonalInfo onSuccess={(val) => handleNext(val)} val={value} />
        ) : null}
        {activeStep === 3 ? (
          <StepperSolidHardwood
            step={7}
            val={value}
            onSuccess={handleNext}
            progressValue={progress}
          />
        ) : null} */}
      </div>
    </div>
  );
}
