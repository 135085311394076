import React from "react";
import { useTranslation } from "react-i18next";

const InteriorWhatIsIncluded = () => {
  const { t } = useTranslation();
  return (
    <div>
      <label>
        <strong>{t("floor.whatsIncludedInService")}</strong>
      </label>
      <ul>
        <li>Customer support specialist</li>
        <li>Cover floor to protect it</li>
        <li>Removal of outlet wall plates</li>
        <li>Removal of light switch plates</li>
        <li>Cover light fixtures</li>
        <li>Light sanding to walls</li>
        <li>Includes one coat of paint (you can add more)</li>
        <li>Paint around edges with paintbrush</li>
        <li>Install back outlet wall plates </li>
        <li>Install back light switch plates </li>
        <li>Clean up job site</li>
        <li>Optional: painting ceilings or trims</li>

        <li>1 year Comprehensive warranty covering workmanship</li>
        <li>Paint free delivery</li>
      </ul>
      <div className="profilePadding10"></div>
      <label>
        <strong>{t("floor.howLongTheProcessTakes")}</strong>
      </label>
      <ul>
        <li> Between 1 to 10 days*</li>
      </ul>
      <div className="profilePadding40"></div>
      <span>*Might differ according to size</span>
    </div>
  );
};

export default InteriorWhatIsIncluded;
