import React from "react";
import "./tick.css"
export const Wrong = (props) => {
    const { width, height, paddingLeft, paddingRight, padding, paddingTop, paddingBottom } = props;

    return (
        <div style={{ width: `${width}`, height: `${height}`, paddingLeft: `${paddingLeft}`, paddingRight: `${paddingRight}`, padding: `${padding}`, paddingTop: `${paddingTop}`, paddingBottom: `${paddingBottom}` }}>

            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                {/* <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/> */}
                <line class="path line" fill="none" stroke="#EE9F55" strokeWidth="11" strokeLinecap="" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                <line class="path line" fill="none" stroke="#EE9F55" strokeWidth="11" strokeLinecap="" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
        </div>
    );
};