import React, { useState } from "react";
import IMAGES from "../../../_assets/images/index";
import { PasswordInput } from "../../../_components/passwordInput/PasswordInput";
import { TextInput } from "../../../_components/TextInput/TextInput";
import "../../../_styles/localStyles/signupsignin.css";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from "../signUp/socialMediaSignup/googleLogin";
import FacebookLogin from "../signUp/socialMediaSignup/facebookLogin";
import { Footer } from "../../../_components/footer/Footer";
import { Eyehide } from "../../../_components/Tickandwrongsvg/eyehideVector"
import { Eyeshow } from "../../../_components/Tickandwrongsvg/eyeshowVector"
import { Button } from "../../../_components/Button/Button";
import { useSnackbar } from "notistack";
import Loader from "../../../_components/spinner/Loader";
import { resetPasswordAPI } from "../../../services/api/signup.service";
function GeneratePassword(props) {

  const token = props.location.search.replace("?token=", "")
  if (!token) {
    props.history.push(`/signin/recover-password`)
  }

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [passwordState, setPasswordState] = useState({

    password: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },

    confirmPassword: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
  });

  const onChangeHandler = (event, key) => {
    let validatedState = validate(key, event.target.value, undefined);
    setPasswordState(validatedState);
  };
  const validate = (name, value, message) => {
    const newState = { ...passwordState };
    newState[name].value = value;
    switch (name) {
      case "password":
        newState["password"].valid = value && value.length > 8 && value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/);
        newState["password"].error.flag = !value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/);
        newState["password"].error.message = value.length === "" ? "Please enter a password" : (value.length < 8) ?
          "Must contains at least 8 characters" : (!value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)) ? "Password should contain number and special character" : "";

        break
      case "confirmPassword":
        newState["confirmPassword"].valid = (value !== "") && value !== newState["password"].value;
        newState["confirmPassword"].error.flag = (value === "") || value !== newState["password"].value;
        newState["confirmPassword"].error.message = (value === "" || value !== newState["password"].value) ? "Passwords mismatch" : "";


    }
    return newState
  };

  const { t } = useTranslation();
  const buttonClick = () => {
    setIsLoading(true)
    let variant = "",
      message = "", redirectUrl = "";
    resetPasswordAPI({ token: token, password: passwordState.password.value }).then((response) => {
      variant = "success";
      message = "Password changed successfully!";
      redirectUrl = "/signin";
    }).catch((e) => {
      variant = "error";
      message = "Token Expired. Please try again";
      redirectUrl = "/signin/recover-password";
    }).finally(() => {
      setIsLoading(false);
      enqueueSnackbar(message, { variant: variant });
      props.history.push(redirectUrl);
    })

  };
  const arrowClick = () => {
    props.history.push(`/signin/check-mail`);
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const [cPasswordShown, setCPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleCPasswordVisiblity = () => {
    setCPasswordShown(!cPasswordShown);
  };
  const [show, setShow] = useState(false);
  return (
    <div>
      <div className="pcWidth">
        <Loader progress={isLoading} />
        <div className="signup_center_align">
          <table>
            <tr>
              <td>
                {/* <img
              src={IMAGES.other.vectors}
              alt=""
              onClick={() => arrowClick()}
            /> */}
              </td>
              <td>
                <label
                  className="signup_page_anyhnb_logo"
                  onClick={() => window.location.replace("/")}
                >
                  {t("signUp.mainHeading")}
                </label>
              </td>
            </tr>
          </table>
        </div>
        {/* <h3>Sign Up</h3>

      <div style={{ display: "flex", marginTop: "-32px" }}>
        {" "}
        <p>I already have an account</p>
        <p
          style={{ marginLeft: "6px", color: "#5B75FF", fontWeight: "600" }}
          onClick={() => signInClick()}
        >
          Sign In
        </p>{" "}
      </div> */}
        <label className="signup_header signup_flex_with_center">
          {t("generatePassword.generateHeading")}
        </label>


        <div className="signup_section">

          <div className="flex register_page_lable">
            <TextInput
              className="signup_input_border"
              label={t('generatePassword.password_label')}
              type={passwordShown ? "text" : "password"}
              onChange={(event) => onChangeHandler(event, "password")}
              value={passwordState.password.value}
              error={passwordState.password.error}
              placeholder={t('generatePassword.password_placeholder')}
            />
            {(() => {
              if (passwordState.password.error.flag == true) {
                return (
                  <div onClick={() => setShow(!show)}>
                    {!show ? (
                      <div onClick={togglePasswordVisiblity} className="signup_eyeIcon">
                        <Eyehide fill="#d9083a" /></div>

                    ) : (
                      <div onClick={togglePasswordVisiblity} className="signup_eyeIcon">
                        <Eyeshow fill="#d9083a" /></div>
                    )}
                  </div>
                )
              } if (passwordState.password.error.flag == false) {
                return (
                  <div onClick={() => setShow(!show)}>
                    {!show ? (
                      <div onClick={togglePasswordVisiblity} className="signup_eyeIcon">
                        <Eyehide fill="#5fca5d" /></div>
                    ) : (

                      <div onClick={togglePasswordVisiblity} className="signup_eyeIcon">
                        <Eyeshow fill="#5fca5d" /></div>
                    )}
                  </div>
                )
              } else {
                return (
                  <div onClick={() => setShow(!show)}>
                    {!show ? (
                      <div onClick={togglePasswordVisiblity} className="signup_eyeIcon">
                        <Eyehide fill="#d9083a" /></div>
                    ) : (
                      <div onClick={togglePasswordVisiblity} className="signup_eyeIcon">
                        <Eyeshow fill="#d9083a" /></div>
                    )}
                  </div>
                )
              }
            })()}
          </div>

          <div className="flex register_page_lable">
            <TextInput
              disabled={!passwordState.password.valid}
              className="signup_input_border"
              onChange={(event) => onChangeHandler(event, "confirmPassword")}
              value={passwordState.confirmPassword.value}
              error={passwordState.confirmPassword.error}
              type={cPasswordShown ? "text" : "password"}
              placeholder={t("generatePassword.confirmPassword_placeholder")}
              label={t('signIn.Confirm_password')}

            />

            {/* <i className="signup_eyeIcon" onClick={togglePasswordVisiblity}>{eye}</i>{" "} */}

            {/* {!passwordShown?():()} */}
            {(() => {
              if (passwordState.confirmPassword.error.flag == true) {
                return (
                  <div onClick={() => toggleCPasswordVisiblity()}>
                    {!cPasswordShown ? (
                      <div onClick={toggleCPasswordVisiblity} className="signup_eyeIcon">
                        <Eyehide fill="#d9083a" /></div>
                    ) : (
                      <div onClick={toggleCPasswordVisiblity} className="signup_eyeIcon">
                        <Eyeshow fill="#d9083a" /></div>
                    )}
                  </div>
                )
              } if (passwordState.confirmPassword.error.flag == false) {
                return (
                  <div onClick={() => toggleCPasswordVisiblity()}>
                    {!cPasswordShown ? (
                      <div onClick={toggleCPasswordVisiblity} className="signup_eyeIcon">
                        <Eyehide fill="#5fca5d" /></div>
                    ) : (
                      <div onClick={toggleCPasswordVisiblity} className="signup_eyeIcon"><Eyeshow fill="#5fca5d" /></div>
                    )}
                  </div>
                )
              } else {
                return (
                  <div onClick={() => toggleCPasswordVisiblity()}>
                    {!cPasswordShown ? (
                      <div onClick={toggleCPasswordVisiblity} className="signup_eyeIcon"><Eyehide fill="#d9083a" /></div>
                    ) : (
                      <div onClick={toggleCPasswordVisiblity} className="signup_eyeIcon"><Eyeshow fill="#d9083a" /></div>
                    )}
                  </div>
                )
              }
            })()}


          </div>

          {/* <EyeIcon/> */}


        </div>

        <div>
          <div className="signup_button_padding generate_pass_btn_padd">
            <Button
              disabled={passwordState.password.value.length < 8 || passwordState.confirmPassword.value.length < 8}
              buttonLabel={t("signIn.continueNext")}
              onClick={buttonClick}
            />
          </div>


        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GeneratePassword;
