/**
 * @author Geethananth M
 * Model Object to hold the roofingModel
 */

/**
 * Model For roofingModel
 */
export const roofingModel = {
  flowName: "roofing-flow",
  serviceType: "",
  reasonForRoofing: "",
  size: "",
  numberOfRepairsNeeded: "",
  needReplaceOfPlywoods: "",
  numberOfPlywoods: "",
  haveOwnRoof: "",
  roofMaterial: "",
  heightNeeded: "",
  pitchNeeded: "",
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  costFor: {
    size: 0,
    repair: 0,
    plywoodChange: 0,
    roof: 0,
    height: 0,
    pitch: 0,
  },
};
