import React, { forwardRef, useEffect, useRef, useState } from "react";
import Ellipse from "./images/Ellipse.png";
import IMAGES from "../../_assets/images";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";
import { Calendar } from "react-calendar";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { checkoutStore } from "../../_redux/actions";
import { useTranslation } from "react-i18next";
import { stepperFormAtom } from "../../recoil/atoms/stepperFormAtom";
import { useRecoilState } from "recoil";
import { mappings } from "../stepper-form/formMapping";
import FormFlow from "../stepper-form/formflow";
import {
  BrowserRouter,
  Link,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import SolidHardwoodIndex from "../floorComponents/solidHardwood";
import SolidHardWoodRoutes from "../floorComponents/SolidHardwoodNew/solidHardWoodRoutes";
import SolidHardwood from "../floorComponents/solidHardwood/solidHardwood";
import { BorderTop } from "@material-ui/icons";
import { SHSizeOfFloor } from "../floorComponents/SolidHardwoodNew/SizeOfFloor";
import { SHHaveSolidHardwood } from "../floorComponents/SolidHardwoodNew/haveSolidHardwood";
import solidHardWoodRoutes from "../floorComponents/SolidHardwoodNew/solidHardWoodRoutes";
import engineeredRoutes from "../floorComponents/engineered/engineeredRoutes";
import { epoxyRoutes } from "../floorComponents/Epoxy/epoxyRoutes";
import { laminatedRoutes } from "../floorComponents/Laminated/laminatedRoutes";
import { tilesRoutes } from "../floorComponents/Tiles/tilesRoutes";
import { carpetRoutes } from "../floorComponents/carpet/carpetRoutes";
import { Bathroomlanding } from "../bathroomComponents/landing";
import { bathroomRoutes } from "../Bathroom/bathroomRoutes";
import { PaintLanding } from "../paintingComponents/landing";
import { paintingRoutes } from "../paintingComponents/paintingRoutes";
import { kitchenRoutes } from "../kitchenComponents/kitchenRoutes";
import { OpeningSize } from "../wallOpening/openingSize";
import { wallOpeningRoutes } from "../wallOpening/wallOpeningRoutes";
import { electricRoutes } from "../Electric/electricRoutes";
import { ElectricTypeofService } from "../Electric/typeOfService";
import { PlumbingTypeofService } from "../Plumbing/typeOfService";
import { plumbingRoutes } from "../Plumbing/plumbingRoutes";
import LaminatedWhatIsIncluded from "../floorComponents/Laminated/whatIsIncluded";
import { TypeOfService } from "../basement/TypeOfService";
import { basementRoutes } from "../basement/basementRoutes";
import { TreeTypeOfService } from "../TreeService/TypeOfService";
import { treeServiceRoutes } from "../TreeService/treeServiceRoutes";

import { WindowTypeofService } from "../windows/typeOfService";
import { windowsRoutes } from "../windows/windowsRoutes";
import { RoofingTypeofService } from "../Roofing/typeOfService";
import { roofingRoutes } from "../Roofing/roofingRoutes";
import { HvacTypeofService } from "../HVACcomponents/typeOfService";
import { hvacRoutes } from "../HVACcomponents/hvacRoutes";
import { selectedCategory } from "../../recoil/atoms/categoryAtom";
import useNotify from "../../_components/Notification/UseNotify";
import { getWhatIsIncludedComponent } from "./whatIsIncludedMapping";
import { Dialog } from "@material-ui/core";

const SelectContractorCard = forwardRef(
  (
    {
      contractorDetails,
      reviewsArray,
      onSelectContractor,
      contractorRealId,
      secondPosition,
      showOnMobile,
      className,
      isClicked,
    },
    ref
  ) => {
    const history = useHistory();
    const { confirm } = useNotify();

    const { ratingInfo } = contractorDetails;

    const checkoutModelState = useSelector((state) =>
      state?.checkout && state?.checkout?.payload
        ? state?.checkout?.payload
        : checkoutModel
    );
    const [selectedDate, setSelectedDate] = useState(
      checkoutModelState?.projectStartDate
        ? new Date(checkoutModelState?.projectStartDate)
        : null
    );
    const [selectedCategoryState, setSelectedCategoryState] =
      useRecoilState(selectedCategory);
    const [selectedTime, setSelectedTime] = useState(
      checkoutModelState?.projectStartTime || null
    );
    const [stepperFormState, setStepperFormState] =
      useRecoilState(stepperFormAtom);
    const [convertedDate, setConvertedDate] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);

    const [calendarOpen, setCalendarOpen] = useState(false);
    const [timePickerOpen, setTimePickerOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [resetModal, setResetModal] = useState(false);

    const dispatch = useDispatch();
    const calendarRef = useRef(null);
    const { t } = useTranslation();
    const items = [
      {
        text: t("contractor.earlyMorning"),
        time: "7am - 9am",
        icon: IMAGES?.home?.sun,
      },
      {
        text: t("contractor.lateMorning"),
        time: "9am - 11am",
        icon: IMAGES?.home?.sun2,
      },
      {
        text: t("contractor.noon"),
        time: "12pm - 3pm",
        icon: IMAGES?.home?.earlyNoon,
      },
      {
        text: t("contractor.afterNoon"),
        time: "3pm - 6pm",
        icon: IMAGES?.home?.lateNoon,
      },
    ];

    const handleClickOutside = (event) => {
      if (
        calendarRef?.current &&
        !calendarRef?.current?.contains(event.target)
      ) {
        setCalendarOpen(false);
      }
    };

    const getFormattedDate = (date) => {
      const dateObj = new Date(date);
      const day =
        dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate();
      const month =
        dateObj.getMonth() + 1 < 10
          ? `0${dateObj.getMonth() + 1}`
          : dateObj.getMonth() + 1;
      const year = dateObj.getFullYear();
      return `${day}/${month}/${year}`;
    };

    function convert(str) {
      var date = new Date(str),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      setConvertedDate([month, day, date.getFullYear()].join("/"));
    }
    const selectDate = (val) => {
      setSelectedDate(val);
      convert(val);
      setCalendarOpen(false);
      saveDate(val);
    };
    const onDatePickerClick = (val) => {
      setCalendarOpen(true);
    };

    const downArrowClick = (val) => {
      // setOpen(true);
      // props.onSuccess();
    };

    const saveDate = (val) => {
      checkoutModelState.projectStartDate = val;
      dispatch(checkoutStore(checkoutModelState));
    };

    const confirmTime = (time) => {
      setSelectedTime(time);
      checkoutModelState.projectStartTime = time;
      dispatch(checkoutStore(checkoutModelState));
      setTimePickerOpen(false);
    };

    const tomorrow = () => {
      // Get today's date
      let today = new Date();
      // Change the date by adding 1 to it (today + 1 = tomorrow)
      today.setDate(today.getDate() + 1);
      // return yyyy-mm-dd format
      return new Date(today);
    };

    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    useEffect(() => {
      handleResize();
    }, []);

    // const getDetailsCo = (path) => {
    //   if (path?.includes("solid-hardwood")) {
    //     return <SolidHardwoodWhatIsIncluded />;
    //   } else {
    //     return <CarpetWhatIsIncluded />;
    //   }

    // <Switch>
    // <Route
    //   path="/contractor-portfolio/:id/floors/solid-hardwood/*"
    //   component={SolidHardwoodWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/floors/carpet/*"
    //   component={CarpetWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/floors/laminated/*"
    //   component={LaminatedWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/floors/engineered/*"
    //   component={EngineeredWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/floors/epoxy/*"
    //   component={EpoxyWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/floors/tiles/*"
    //   component={TilesWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/bathroom/*"
    //   component={BathroomWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/painting/*/interior/*"
    //   component={InteriorWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/painting/*/fence/*"
    //   component={FenceWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/painting/*/exterior/*"
    //   component={ExteriorWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/painting/*/deck/*"
    //   component={DeckWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/kitchen/*"
    //   component={KitchenWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/wall_opening/*"
    //   component={WallOpeningWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/electric/*"
    //   component={ElectricWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/plumbing/*"
    //   component={PlumbingWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/basement/*"
    //   component={BasementWhatIsIncluded}
    // />
    // <Route
    //   path="/contractor-portfolio/:id/tree_services/*"
    //   component={TreeServicesWhatIsIncluded}
    // />
    // </Switch>;
    // };
    return (
      <div style={{ width: isMobile ? "100%" : "50%" }}>
        <div
          className={`${
            showOnMobile ? "select-contractor-mobile" : "select-contractor"
          } ${className}`}
          style={{
            ...(!isMobile && { top: secondPosition }),
            position: isClicked ? "relative" : "sticky",
            height: "fit-content",
            width: "100%",
            border: isClicked ? "" : "1px solid #eaeaea",
            // borderRadius: isMobile ? "0" : "30px",
          }}
          ref={ref}
          id="selectContractorCard"
        >
          <div
            className={`${
              showOnMobile ? "" : "d-none d-lg-flex"
            } wrapper w-100`}
            style={{ borderRadius: "30px" }}
          >
            {stepperFormState?.step < 1 ||
            showOnMobile ||
            window?.location?.pathname?.split("/")?.length < 4 ? (
              <div className="w-100 p-3 p-lg-4">
                <div className="d-flex justify-content-between">
                  <p>{t("contractor.projectDate")}:</p>
                  {/* <p className="d-flex align-items-center contractor-rating p-1">
              <img height={20} width={20} src={Star} /> &nbsp;
              <span>{ratingInfo?.rating}</span> &nbsp;
              <img
                className="d-none d-lg-block"
                src={Ellipse}
                height={3}
                width={3}
              />{" "}
              <span className="underline w-auto d-none d-lg-block">
                &nbsp;{reviewsArray?.length || 0} {t("contractor.reviews")}
              </span>
            </p> */}
                </div>
                <div className="d-flex w-100 justify-content-between">
                  <div className="date" onClick={onDatePickerClick}>
                    <img className="" src={IMAGES.home.calendar} />
                    <span className="">
                      {selectedDate
                        ? getFormattedDate(selectedDate)
                        : "dd/mm/yy"}
                    </span>
                  </div>
                  <div className="time" onClick={() => setTimePickerOpen(true)}>
                    <img
                      src={
                        checkoutModelState?.projectStartTime
                          ? items?.filter(
                              (item) =>
                                item.time ===
                                checkoutModelState?.projectStartTime
                            )[0]?.icon
                          : items[0]?.icon
                      }
                      className=""
                    />
                    <div className="d-flex flex-column">
                      <p>
                        {checkoutModelState?.projectStartTime
                          ? items?.filter(
                              (item) =>
                                item.time ===
                                checkoutModelState?.projectStartTime
                            )[0]?.text
                          : "choose a time"}
                      </p>
                      <small>
                        {checkoutModelState?.projectStartTime || ""}
                      </small>
                    </div>
                  </div>
                </div>
                {errorMessage ? (
                  <small className="text-danger mt-1 ms-1">
                    Please choose the project start date and time
                  </small>
                ) : (
                  ""
                )}
                <div className="">
                  <button
                    onClick={() => {
                      if (!selectedCategoryState?.key) {
                        history.push("/");
                        confirm("info", "Please choose a category first");
                      } else if (!selectedDate || !selectedTime) {
                        setErrorMessage(true);
                      } else {
                        setErrorMessage(false);
                        onSelectContractor(contractorRealId);
                      }
                    }}
                    className="select-btn"
                  >
                    {t("contractor.select")}
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="select-contractor-inner"
                style={{
                  border: isClicked ? "1px solid #eaeaea" : "",
                  borderRadius: isClicked ? "30px" : "",
                  maxHeight: "calc(100vh - 150px)",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                <div className="pb-5" style={{ padding: "20px" }}>
                  <BrowserRouter>
                    <Switch>
                      {solidHardWoodRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {engineeredRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {epoxyRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {laminatedRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {tilesRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {carpetRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {bathroomRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {paintingRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {kitchenRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {wallOpeningRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {electricRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {plumbingRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {basementRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {treeServiceRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {windowsRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {roofingRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      {hvacRoutes?.map((route) => (
                        <Route
                          path={route?.path}
                          component={route?.component}
                        />
                      ))}
                      <Route path="/contractor-portfolio/:id/floors">
                        <SolidHardwood />
                      </Route>
                      <Route path="/contractor-portfolio/:id/bathroom">
                        <Bathroomlanding />
                      </Route>
                      <Route path="/contractor-portfolio/:id/painting">
                        <PaintLanding />
                      </Route>
                      <Route path="/contractor-portfolio/:id/wall_opening">
                        <OpeningSize />
                      </Route>
                      <Route path="/contractor-portfolio/:id/electric">
                        <ElectricTypeofService />
                      </Route>
                      <Route path="/contractor-portfolio/:id/plumbing">
                        <PlumbingTypeofService />
                      </Route>
                      <Route path="/contractor-portfolio/:id/basement">
                        <TypeOfService />
                      </Route>
                      <Route path="/contractor-portfolio/:id/tree_services">
                        <TreeTypeOfService />
                      </Route>
                      <Route path="/contractor-portfolio/:id/windows">
                        <WindowTypeofService />
                      </Route>
                      <Route path="/contractor-portfolio/:id/roofing">
                        <RoofingTypeofService />
                      </Route>
                      <Route path="/contractor-portfolio/:id/hvac">
                        <HvacTypeofService />
                      </Route>
                    </Switch>
                  </BrowserRouter>
                </div>
                <button
                  className="w-100 position-absolute text-center"
                  style={{
                    bottom: "0",
                    outline: "none",
                    left: 0,
                    padding: "10px",
                    color: "#2CCAE8",
                    backgroundColor: "#fff",
                    border: "1px solid #e5e5e5",
                    borderBottomLeftRadius: "30px",
                    borderBottomRightRadius: "30px",
                    position: "absolute !important",
                  }}
                  onClick={() => {
                    setResetModal(true);
                  }}
                >
                  {t("contractor.resetQuote")}
                </button>
              </div>
            )}
            <Modal open={calendarOpen} onClose={() => setCalendarOpen(false)}>
              <>
                {calendarOpen ? (
                  <div
                    className="m-t-32"
                    style={{
                      display: "grid",
                      placeItems: "center",
                    }}
                    onClick={handleClickOutside}
                  >
                    <div
                      className=""
                      style={{ display: "flex", width: "fit-content" }}
                      ref={calendarRef}
                    >
                      <Calendar
                        open={calendarOpen}
                        keepmounted
                        onChange={(val) => selectDate(val)}
                        value={selectedDate}
                        minDate={tomorrow()}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            </Modal>
            <Modal
              sx={{
                width: "30%",
                alignSelf: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "50px",
                overflowY: "scroll",
                scrollbarWidth: "none",
                msOverflowY: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "@media (max-width: 768px)": {
                  width: "90%",
                },
              }}
              open={timePickerOpen}
              onClose={() => setTimePickerOpen(false)}
            >
              <div className="time-picker m-t-32">
                {items.map((item, index) => (
                  <label
                    key={index}
                    className={`d-flex align-items-center ${
                      item.time === selectedTime ? "selected" : ""
                    }`}
                    onClick={() => confirmTime(item?.time)}
                  >
                    <input
                      type="radio"
                      name=""
                      value={item.text}
                      checked={item.time === selectedTime}
                    />
                    <img
                      className=""
                      width={24}
                      height={24}
                      src={
                        index < 1
                          ? IMAGES.home.sun
                          : index < 2
                          ? IMAGES.home.sun2
                          : index < 3
                          ? IMAGES.home.earlyNoon
                          : IMAGES.home.lateNoon
                      }
                      alt=""
                    />
                    <div className="wish-text">
                      <span>{item.text}</span>
                      <span className="wish-time">{item.time}</span>
                    </div>
                  </label>
                ))}
              </div>
            </Modal>
            <Dialog
              onClose={() => setResetModal(false)}
              open={resetModal}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
              PaperProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
            >
              <div className="stepper-dialog">
                <img src={IMAGES.home.resetIcon} width="120px" alt="" />
                <div className="stepper-dialog-text">
                  Are you sure want to <br /> reset quote ?
                </div>
                <div className="flex stepper-dialog-footer">
                  <div
                    onClick={() => {
                      setResetModal(false);
                    }}
                  >
                    No
                  </div>
                  <div
                    onClick={() => {
                      window.location = `/contractor-portfolio/${contractorRealId}`;
                    }}
                  >
                    Yes
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
        <div
          className={`mt-5 px-3 ${
            showOnMobile ? "d-none" : "d-none d-lg-block"
          }`}
        >
          {getWhatIsIncludedComponent(`${window?.location?.pathname}`)}
        </div>
      </div>
    );
  }
);

export default SelectContractorCard;
