import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, hvacStore } from "../../_redux/actions";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { hvacModel } from "../../_redux/Models/hvac.model";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const HvacSelectBrand = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);
  const stepValue = 6;

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.hvacPayload
      ? state.buttonReducer.hvacPayload
      : hvacModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [option, setOption] = useState("");
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state
  const [brandOptions, setBrandOptions] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const existingSystemSize = state.existingSystemSize;
    const typeOfInstallation = state.typeOfInstallation;
    if (mounted === true && priceDetails && priceDetails.typeOfUnit) {
      const priceForChosenSize = priceDetails.typeOfUnit.find(
        (op) => op.key === `${existingSystemSize}_tons`
      );
      const brandOptionsArr = priceForChosenSize.brandUnitCost.map((op) => {
        return {
          text: op.key,
          value: op.key,
          price: typeOfInstallation === "unit-and-installation" ? op.price : 0,
        };
      });
      setBrandOptions(brandOptionsArr);
    }
    return () => (mounted = false);
  }, [
    checkoutModelState,
    state.existingSystemSize,
    state.typeOfInstallation,
    t,
  ]);

  const onSave = (val, prc) => {
    const total = calculateTotalCost(state.costFor);
    state.brandNeeded = val;
    state.costFor.material = prc - total;
    checkoutModelState.totalCost = prc;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(hvacStore(state));
    if (state.serviceType === "replacement") {
      history.push(
        `/contractor-portfolio/${selectedContractorId}/hvac/add-ons/7`
      );
    }
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left mb-3">
            <label className="paint_header" style={{ fontSize: "19px" }}>
              {"Which brand should be your HVAC system?"}
            </label>
          </div>
          {brandOptions.map((item, index) => (
            <div
              className={`floor-selection-radio ${
                option === item?.value ? "selected" : ""
              }`}
              onClick={() => {
                const total = calculateTotalCost(state.costFor);
                setOption(item.value);
                setPrice(total + item.price);
                onSave(item?.value.total + item?.price);
              }}
              key={index}
            >
              <div
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="paintdiv_txt captlize-txt"
                  style={{
                    width: "175px",
                    textTransform: "initial",
                  }}
                >
                  {item.text}
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "130px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
