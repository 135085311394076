import { Dialog } from "@material-ui/core";
import IMAGES from "../../../_assets/images/index";

export const removalOfFloorPopup = ({
  onCloseAction,
  isOpen,
  onNoClick,
  onYesClick,
}) => {
  return (
    <Dialog
      onClose={onCloseAction}
      open={isOpen}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          borderRadius: "20px",
        },
      }}
    >
      <div className="stepper-dialog">
        <img src={IMAGES.home.removeIcon} width="120px" alt="" />
        <div className="stepper-dialog-text">
          Do you need us to <br /> remove your old floor?
        </div>
        <div className="flex stepper-dialog-footer">
          <div onClick={onNoClick}>No</div>
          <div onClick={onYesClick}>Yes</div>
        </div>
      </div>
    </Dialog>
  );
};
