import React from "react";
import "./tick.css"
export const Eyeshow = (props) => {
    const { width, height,paddingLeft,paddingRight,padding,paddingTop,paddingBottom,fill   } = props;
    return (
<div  style={{width:`${width}`,height:`${height}`,paddingLeft:`${paddingLeft}`,paddingRight:`${paddingRight}`,padding:`${padding}`,paddingTop:`${paddingTop}`,paddingBottom:`${paddingBottom}` }}>
<svg xmlns="http://www.w3.org/2000/svg" fill={fill}  width="24" height="24" viewBox="0 0 24 24">
    <path class="path line" d="M14 12c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zm10-.449s-4.252 7.449-11.985 7.449c-7.18 0-12.015-7.449-12.015-7.449s4.446-6.551 12.015-6.551c7.694 0 11.985 6.551 11.985 6.551zm-8 .449c0-2.208-1.791-4-4-4-2.208 0-4 1.792-4 4 0 2.209 1.792 4 4 4 2.209 0 4-1.791 4-4z"/></svg>

   

</div>
    );
};