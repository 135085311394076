export const buttonConstant = {
  BUTTON_ENABLE: "BUTTON_ENABLE",
  SELECT_CATEGORY: "SELECT_CATEGORY",
  PAINTING_FORWARD: "PAINTING_FORWARD",
  FLOOR_FORWARD: "FLOOR_FORWARD",
  KITCHEN_FORWARD: "KITCHEN_FORWARD",
  BATHROOM_FORWARD: "BATHROOM_FORWARD",
  BASEMENT_FORWARD: "BASEMENT_FORWARD",
  WALL_FORWARD: "WALL_FORWARD",
  HVAC_FORWARD: "HVAC_FORWARD",
  ELECTRIC_FORWARD: "ELECTRIC_FORWARD",
  PLUMBING_FORWARD: "PLUMBING_FORWARD",
  ROOFING_FORWARD: "ROOFING_FORWARD",
  WINDOW_FORWARD: "WINDOW_FORWARD",
  TREE_FORWARD: "TREE_FORWARD",
  CHECKOUT_FORWARD: "CHECKOUT_FORWARD",
  CLEAR_STORE: "CLEAR_STORE",
};
