import React, { useLayoutEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import "../../../_styles/globalStyles/global.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images";
import { Footer } from "../../../_components/footer/Footer";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { Button } from "../../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { floorEngineeredModel } from "../../../_redux/Models/Floor.model";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const OrderSummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 7;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [orderSummary, setOrderSummary] = useState([]);
  const [comment, setComment] = useState("");
  const floorState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorEngineeredModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const price = calculateTotalCost(floorState.costFor); // To keep the total price in state

  /**
   * On save Comment
   */
  const saveComment = () => {
    floorState.comments = comment;
    dispatch(floorStore(floorState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    var step = floorEngineeredModel.priorFloorRemovalRequired ? 7 : 6;
    window.location = `/contractor-portfolio/${selectedContractorId}/confirm`;
  };

  useLayoutEffect(() => {
    if (floorState && floorState !== undefined) {
      var currentFloorArr = floorState.currentFloor
        ? floorState.currentFloor
        : [];
      const summary = [
        {
          heading: "Floor size",
          text: `${floorState.floorSize} sqft`,
          img: IMAGES.other.green_plus,
          price: floorState.costFor.size,
        },
        {
          heading: "Material grade",
          text: floorState.materialQuality
            ? floorState.materialQuality.replace("_", " ")
            : "NA",
          img: IMAGES.other.green_plus,
          price: floorState.costFor.materialQuality,
        },
        {
          heading: "Current floor removal",
          text: currentFloorArr.map((t) => {
            if (t.val > 0) {
              return (
                <span>
                  {t.key} : {t.val}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
          img: IMAGES.other.green_plus,
          price: floorState.costFor.floorRemoval,
        },
        {
          heading: "Add on´s",
          text: floorState.addOns.map((add) => (
            <span>
              {add.text}
              <br />
            </span>
          )),
          //text: `${floorPayload.addOns.toString()}`,
          img: IMAGES.other.green_plus,
          price: floorState.costFor.addOns,
        },
        {
          heading: "Elevator",
          text: `${floorState.needToUseElevator ? "Yes" : "No"}`,
          img: IMAGES.other.green_plus,
          price: floorState.costFor.elevator,
        },
      ];

      setOrderSummary(summary);
    }
  }, [floorState]);

  return (
    <div className="service insideWrp">
      <div className="paint-home-section pcWidth">
        <div className="sevice_title">{t("orderSummary.mainHeading")} </div>
        <label className="sub_heading" style={{ marginTop: "7px" }}>
          {" "}
          {t("orderSummary.subHeading1")}
        </label>
        <div className="paintpaddingtop25"></div>
        {orderSummary && orderSummary.length
          ? orderSummary.map((item, index) =>
              item.text.length > 0 ? (
                <div key={index}>
                  <label
                    className="floor-selection-radio"
                    style={{
                      flexDirection: "column",
                      padding: "10px",
                      alignItems: "start",
                    }}
                  >
                    <div className="bold">
                      <strong>{item.heading}</strong>
                    </div>
                    <div
                      className="flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: " 90%",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          textAlign: "left",
                          color: "#858585",
                          fontSize: "13px",
                        }}
                      >
                        {item.text}
                      </div>

                      <div style={{ display: "flex", placeItems: "center" }}>
                        <div style={{ paddingTop: "3px" }}>
                          <img
                            width="15"
                            height="15"
                            src={IMAGES.other.green_plus}
                            alt=""
                          />
                        </div>
                        <div style={{ paddingLeft: "10px" }}>
                          {item.price < 0
                            ? `${formatPrice(item.price).replace("-", "-$")}`
                            : `$${formatPrice(item.price)}`}
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              ) : null
            )
          : null}
        <table>
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("paintingInterior.ordercommands")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message"
              />
            </td>
          </tr>
        </table>
        <br />
        <div className="otherspadding25"></div>
        <Button
          disabled={false}
          buttonLabel={t("orderSummary.continue")}
          onClick={saveComment}
        />
        <div className="contactblue_btn mb-5 mt-4">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td onClick={() => (window.location = "/contact-us")}>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon mb-3">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>
    </div>
  );
};
