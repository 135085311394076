/**
 * @author Geethananth M
 * Model Object to hold the treeModel
 */

/**
 * Model For treeModel
 */
export const treeModel = {
  flowName: "tree-flow",
  serviceType: "",
  anyElectricInstallationsNear: "",
  treeDetails: [],
  numberOfTreesToStumpRemove: "", // For stump removal flow
  treeDimensionDetails: [], // For stump removal flow
  // treeDetailsNearElectric: [],
  // treeDetailsNotNearElectric: [],
  typeOfTreesToRemove: [],
  needStumpGrinding: "",
  needDebrisHaul: "",
  anyTreesOutOfReach: "",
  numberOfTreesOutOfReach: "",
  personalInfo: {
    name: "",
    email: "",
    phoneNumber: "",
    fullAddress: "",
  },
  comments: "",
  costFor: {
    installation: 0,
    // installationNearElectric: 0,
    // installationNotNearElectric: 0,
    stumpGrinding: 0,
    debrisHaul: 0,
    machinery: 0,
    stumpRemoval: 0,
    nearByStructure: 0,
  },
};
