import React, { useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { electricStore } from "../../_redux/actions";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { electricModel } from "../../_redux/Models/electric.model";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const ElectricRepairComments = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.electricPayload
      ? state.buttonReducer.electricPayload
      : electricModel
  );
  const price = calculateTotalCost(state.costFor); // To keep the total price in state
  const [option, setOption] = useState("");

  const onSave = () => {
    state.comments = option;
    dispatch(electricStore(state));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/electric/order-summary/4`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {state.addOns &&
              state.addOns[0].value === "fuse_box_circuit_breaker"
                ? "Describe your problem"
                : "Anything else you need?"}
            </label>
          </div>
          <div className="paint_flex_with_left">
            <label className="paint_other_sub_header ">
              {state.addOns &&
              state.addOns[0].value === "fuse_box_circuit_breaker"
                ? "Please provide full detail about the problem"
                : " Please provide any other detail about the problem"}
            </label>
          </div>
          <div className="padding10"></div>

          <table className="mb-3">
            <tr>
              <td>
                <label className="summaryDivheader1">
                  {t("paintingInterior.ordercommands")}
                </label>
                <div className="commandspadding"></div>
                <textarea
                  type="text"
                  value={option}
                  onChange={(e) => setOption(e.target.value)}
                  placeholder={t("paintingInterior.typemessage")}
                  className="paintsummaryCommands message"
                />
              </td>
            </tr>
          </table>
          <Button
            disabled={!option}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSave}
          />
        </div>
      </div>
    </div>
  );
};
