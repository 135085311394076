import React from "react";
import { createPortal } from "react-dom";
import DialogNotification from "../Dialog/DialogNotification";
import AlertBox from "../snackbar/alertBox";
import useNotify from "./UseNotify";
const NotifyDialog = () => {
  const { onConfirm, onCancel, confirmState } = useNotify();
  const portalElement = document.getElementById("dialog-root");
  const component =
    confirmState && confirmState != undefined && confirmState.show ? (
      <AlertBox
        className={confirmState.className || ""}
        msg={confirmState.msg || ""}
        action={confirmState.show}
      />
    ) : // <DialogNotification
    //   open={confirmState.show}
    //   handleClose={onCancel}
    //   className={confirmState.className || ""}
    //   message={confirmState.msg || ""}
    // />
    null;

  return createPortal(component, portalElement);
};
export default NotifyDialog;
