import { CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import IMAGES from "../../../_assets/images";
import CustomPhoneInput from "../../../_components/PhoneInput/PhoneInput";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../services/api/config/api.config";
import useNotify from "../../../_components/Notification/UseNotify";

const VerifyMobileModal = ({
  mobileVerifyModal,
  setMobileVerifyModal,
  getUserInfo,
}) => {
  const { t } = useTranslation();
  const { confirm } = useNotify();

  const [isOtpSend, setIsOtpSend] = useState(false);
  const [sending, setSending] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [otpError, setOtpError] = useState("");
  const [valueState, setValueState] = useState({
    mobileNumber: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
  });

  const handleOtpSend = async () => {
    if (
      !valueState?.mobileNumber?.value ||
      valueState?.mobileNumber?.value?.length <= 0
    ) {
      setValueState((prev) => ({
        ...prev,
        mobileNumber: {
          ...valueState?.mobileNumber,
          error: {
            flag: true,
            message: "Phone number is required",
          },
        },
      }));
    } else {
      if (valueState?.mobileNumber?.valid) {
        clearTimeout(intervalId);
        try {
          setSending(true);

          const res = await axiosInstance.post("/user/integrate-mobile", {
            mobileNumber: valueState?.mobileNumber?.value.replace("-", ""),
          });
          if (res && res.status == 200) {
            setIsOtpSend(true);
            setTimer(300);
            confirm("success", "OTP is sent to respective mobile number");
          }
        } catch (err) {
          confirm(
            "error",
            err?.response?.data?.message || "Something went wrong"
          );
        } finally {
          setSending(false);
        }
      }
    }
  };

  const verifyOtp = async () => {
    if (isOtpSend) {
      if (otp?.length > 0) {
        var payload = {
          mobileNumber: valueState?.mobileNumber?.value.replace("-", ""),
          otp,
        };
        try {
          setVerifying(true);
          var res = await axiosInstance.post("/user/update-mobile", payload);
          if (res && res.data?.code === 200) {
            confirm("success", "Mobile number verified successfully");
            setMobileVerifyModal(false);
            getUserInfo();
          }
        } catch (err) {
          confirm(
            "error",
            err?.response?.data?.message || "Something went wrong"
          );
        } finally {
          setVerifying(false);
        }
      } else {
        setOtpError("Please enter the OTP");
      }
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (timer > 0) {
      const id = setTimeout(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      setIntervalId(id);
    } else {
      setIsOtpSend(false);
    }
    return () => clearTimeout(intervalId);
  }, [timer]);

  useEffect(() => {
    if (otp.length > 0) {
      setOtpError("");
    }
  }, [otp]);

  return (
    <Modal
      open={mobileVerifyModal}
      onClose={() => {
        setMobileVerifyModal(false);
      }}
      style={{ overflowY: "auto" }}
      sx={{
        outline: "none",
        margin: "auto",
      }}
      BackdropProps={{
        onClick: () => {},
      }}
    >
      <div
        className="mx-2 mx-sm-auto"
        style={{
          maxWidth: "650px",
          margin: "auto",
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <div
          className="d-flex flex-column verify-mobile-modal bg-white"
          style={{ padding: "10%" }}
        >
          <img src={IMAGES.home.blueWhiteTick} height="65" width="65" />
          <h1 className="verify-head mb-5">{t("verifyMobile.verify")}</h1>
          {/* <p className="verify-text">{t("verifyMobile.verifyText")}</p> */}
          <div className="d-flex flex-column gap-4">
            <CustomPhoneInput
              valueState={valueState}
              setValueState={setValueState}
              name="mobileNumber"
              className="signup_input_border"
              placeholder={t("signIn.phone_placeholder")}
              phoneInputClasses="d-flex align-items-center"
              showLabel={false}
              showCountryName={false}
            />
            <button
              disabled={isOtpSend || timer > 0 || sending}
              className="req-btn d-flex align-items-center justify-content-center"
              onClick={handleOtpSend}
            >
              Request OTP
              {sending ? (
                <CircularProgress
                  color="info"
                  sx={{ marginLeft: "10px" }}
                  size={24}
                />
              ) : (
                ""
              )}
            </button>
            {isOtpSend ? (
              <div className="d-flex w-100 justify-content-between align-items-center">
                <p className="enter-otp text-start">
                  {t("verifyMobile.enterOtp")}
                </p>
                <p className="timer">{formatTime(timer)}</p>
              </div>
            ) : (
              ""
            )}
            <input
              className="otp-input"
              onChange={(e) => setOtp(e.target.value)}
              type="text"
              value={otp}
            />
            <button
              disabled={!isOtpSend}
              onClick={verifyOtp}
              className="verify-btn d-flex align-items-center justify-content-center"
            >
              Verify
              {verifying ? (
                <CircularProgress
                  sx={{ color: "#fff", marginLeft: "10px" }}
                  size={24}
                />
              ) : (
                ""
              )}
            </button>
            <small className="text-danger">{otpError}</small>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyMobileModal;
