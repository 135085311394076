import { Dialog } from "@material-ui/core";
import IMAGES from "../../../_assets/images/index";

export const CannotContinueModal = ({
  hideContractorIcon,
  reason,
  onCloseAction,
  isOpen,
  onNoClick,
  showYesButton,
  onYesClick,
  okayText,
  closeText,
  contentClassName,
}) => {
  return (
    <Dialog
      onClose={onCloseAction}
      open={isOpen}
      keepMounted
      Class={contentClassName}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          borderRadius: "20px",
        },
      }}
    >
      <div className="stepper-dialog">
        {hideContractorIcon !== true && (
          <img src={IMAGES.home.removeIcon} width="120px" alt="" />
        )}
        <div
          className="stepper-dialog-text"
          style={{ fontSize: "16px", padding: "10px" }}
        >
          {reason}
        </div>
        <div className="flex stepper-dialog-footer">
          <div onClick={onNoClick}>{closeText || "Close"}</div>
          {showYesButton ? (
            <div onClick={onYesClick}>{okayText || "Okay"}</div>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
};
