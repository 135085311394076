import React, { useState, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import CheckBox from "../../_components/CheckBox/checkBox";
import { Button } from "../../_components/Button/Button";

import { checkoutStore, electricStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import { electricModel } from "../../_redux/Models/electric.model";
import { DropDown } from "../../_components/dropDown/dropDown";
import RadioButton from "../../_components/radioButton/radioButton";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useHistory } from "react-router-dom";
export const ElectricSelectInstallItems = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.electricPayload
      ? state.buttonReducer.electricPayload
      : electricModel
  );

  const [price, setPrice] = useState(
    calculateTotalCost(state.costFor, ["addOns"])
  ); // To keep the total price in state
  const [addOns, setAddOns] = useState([]);

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;
    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          checkoutModelState.priceDetails = response.data.details;
          console.log(response.data.details);
          dispatch(checkoutStore(checkoutModelState));
          const existingOrNew = state.existingOrNew;
          const serviceType = state.serviceType;
          let currentFlow = existingOrNew;
          if (serviceType === "repairs") {
            currentFlow = "whatNeedsRepair";
          }
          const singleItems = ["zoning_system"];
          const typeOfInstallation = state.typeOfInstallation;
          let optionsArr = response.data.details[currentFlow];
          if (existingOrNew === "installation") {
            optionsArr = optionsArr
              .map((op) => {
                if (op.key === "outlet_switch") {
                  return {
                    text: t(`electric.${op.key}`),
                    value: op.key,
                    count: 1,
                    isChecked: false,
                    isSingle: singleItems.includes(op.key),
                    showSelectInput: true,
                    options: op.options,
                    price: 0,
                  };
                } else if (op.key === "220v_wire_line") {
                  return {
                    text: t(`electric.${op.key}`),
                    value: op.key,
                    count: 1,
                    isChecked: false,
                    isSingle: singleItems.includes(op.key),
                    showSelectInput: true,
                    options: op.options,
                    price: 0,
                  };
                } else if (op.key === "recessed_lights") {
                  return {
                    text: t(`electric.${op.key}`),
                    value: op.key,
                    count: 1,
                    isChecked: false,
                    isSingle: singleItems.includes(op.key),
                    showSelectInput: true,
                    options: op.options.map((option, i) => {
                      return {
                        key: option.key,
                        price:
                          option.servicePrice + option.materialPrice * (i + 1),
                      };
                    }),
                    optionsArr: op.options.map((op) => {
                      return {
                        ...op,
                        materialPrice: op.materialPrice,
                        servicePrice: op.servicePrice,
                      };
                    }),
                    price:
                      op.options[0].servicePrice +
                      op.options[0].materialPrice * 1,
                  };
                } else if (currentFlow !== "whatNeedsRepair") {
                  return {
                    text: t(`electric.${op.key}`),
                    value: op.key,
                    count: 1,
                    isChecked: false,
                    isSingle: singleItems.includes(op.key),
                    showTextInput: true,
                    wiringInstallationPrice: op.wiringInstallationPrice,
                    installationPrice: op.installationPrice,
                    price: 0,
                  };
                } else {
                  return {
                    text: t(`electric.${op.key}`),
                    value: op.key,
                    count: 1,
                    isChecked: false,
                    isSingle: true,
                    showTextInput: true,
                    price: op.price,
                  };
                }
              })
              .filter((op) => op !== false);
          } else if (existingOrNew === "replacement") {
            optionsArr = optionsArr
              .map((op) => {
                if (op.key === "recessed_lights") {
                  return {
                    text: t(`electric.${op.key}`),
                    value: op.key,
                    count: 1,
                    isChecked: false,
                    isSingle: singleItems.includes(op.key),
                    showSelectInput: true,
                    options: op.options.map((option, i) => {
                      return {
                        key: option.key,
                        price: option.price * (i + 1),
                      };
                    }),
                    optionsArr: op.options.map((op) => {
                      return {
                        ...op,
                        materialPrice: op.price,
                        servicePrice: 0,
                      };
                    }),
                    price: op.options[0].price * 1,
                  };
                } else {
                  return {
                    text: t(`electric.${op.key}`),
                    value: op.key,
                    count: 1,
                    isChecked: false,
                    isSingle: false,
                    showTextInput: true,
                    price: op.price,
                    wiringInstallationPrice: 0,
                    installationPrice: op.price,
                  };
                }
              })
              .filter((op) => op !== false);
          } else if (serviceType === "repairs") {
            optionsArr = optionsArr
              .map((op) => {
                if (
                  ["outlet_switch", "emergency", "lighting"].includes(op.key)
                ) {
                  return {
                    text: t(`electric.${op.key}`),
                    value: op.key,
                    count: 1,
                    isChecked: false,
                    isSingle: singleItems.includes(op.key),
                    showSelectInput: true,
                    inputPlaceholder:
                      op.key === "outlet_switch"
                        ? "How many outlets/switches need repair?"
                        : op.key === "lighting"
                        ? "Select the number of lights"
                        : "How urgent is your emergency?",
                    options: op.options,
                    price: 0,
                  };
                } else {
                  return {
                    text: t(`electric.${op.key}`),
                    value: op.key,
                    count: 1,
                    isChecked: false,
                    isSingle: false,
                    showTextInput: ["wiring_rewiring", "lighting"].includes(
                      op.key
                    ),
                    inputPlaceholder:
                      op.key === "wiring_rewiring"
                        ? "How many rooms require wiring?"
                        : op.key === "lighting"
                        ? "Number of lights"
                        : op.key === "outlet_switch"
                        ? "How many outlets/switches need repair?"
                        : "",
                    price: op.price,
                    wiringInstallationPrice: 0,
                    installationPrice: op.price,
                  };
                }
              })
              .filter((op) => op !== false);
          }

          if (currentFlow !== "whatNeedsRepair") {
            optionsArr.push({
              text: t(`electric.sub_panel`),
              value: "sub_panel",
              count: 1,
              isChecked: false,
              isSingle: false,
              showSelectInput: true,
              options: response.data.details.subPanel,
              price: 0,
            });
          }
          setAddOns(
            optionsArr
              .sort((o, p) => (p.isSingle ? -1 : 1))
              .sort((o, p) => (p.value === "emergency" ? -1 : 1))
          );
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state, t]);

  const onSave = () => {
    const total = calculateTotalCost(state.costFor);
    state.addOns = addOns.filter((i) => i.isChecked === true);
    state.costFor.addOns = price - total;
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(electricStore(state));
    if (
      state.serviceType === "repairs" &&
      ["outlet_switch", "fuse_box_circuit_breaker"].includes(
        state.addOns[0].value
      )
    ) {
      history.push(
        `/contractor-portfolio/${selectedContractorId}/electric/comment-details/3`
      );
    } else {
      history.push(
        `/contractor-portfolio/${selectedContractorId}/electric/order-summary/3`
      );
    }
  };

  function arrowHandler(params, e, index) {
    let updatedState = [...addOns];
    if (params === "plus") {
      updatedState[index].count = updatedState[index].count + 1;
    } else if (updatedState[index].count > 1) {
      updatedState[index].count = updatedState[index].count - 1;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  }

  const calculateTotal = (addOns) => {
    const total = addOns
      .filter((v) => v.isChecked === true)
      .reduce((p, c) => {
        if (["breaker", "regular_light", "switches", "fan"].includes(c.value)) {
          return (
            p + (c.wiringInstallationPrice + c.installationPrice * c.count)
          );
        } else {
          return p + c.price * c.count;
        }
      }, 0);
    setPrice(calculateTotalCost(state.costFor) + total);
    console.log("ff", addOns);
  };

  const toggleCheck = (event, index) => {
    let updatedState = [...addOns];
    if (!updatedState[index].isChecked) {
      updatedState[index].isChecked = event;
    } else {
      updatedState[index].isChecked = !event;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  const onAddOnHandler = (event, index) => {
    let updatedState = [...addOns];
    updatedState[index].count = parseInt(event.target.value);
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">
            {"What do you need to install or replace?"}
          </label>
        </div>

        <div className="padding10"></div>
        {addOns &&
          addOns !== undefined &&
          addOns.map((item, index) => (
            <div key={index} className="paintPadding8 ">
              <div
                className={`floor-selection-radio ${
                  item?.isChecked ? "selected" : ""
                }`}
                onClick={() => toggleCheck(true, index)}
              >
                <div
                  key={index}
                  className="width100 "
                  style={{ justifyContent: "space-between" }}
                >
                  <table>
                    <tr>
                      <td>
                        <label className="paintdiv_txt"> {item.text} </label>
                      </td>
                      <td>
                        <div
                          className="paint_arrowFloat flex"
                          style={{ paddingTop: "10px" }}
                        >
                          <img
                            src={IMAGES.other.green_plus}
                            className="kitchenplusimg"
                            alt=""
                          />
                          <label
                            className="paintdiv_txt paddingleft8"
                            style={{ paddingLeft: "7px", paddingTop: "2px" }}
                          >
                            {[
                              "breaker",
                              "regular_light",
                              "switches",
                              "fan",
                            ].includes(item.value)
                              ? `$${formatPrice(
                                  item.wiringInstallationPrice +
                                    item.installationPrice * item.count
                                )}`
                              : `$${formatPrice(item.count * item.price)}`}
                          </label>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="mb-3">
                {item.isChecked &&
                !item.isSingle &&
                item.showTextInput === true ? (
                  <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
                    <table className="paint_table100">
                      <tr>
                        {item.inputPlaceholder ? (
                          <td>
                            <label>{item.inputPlaceholder || ""}</label>
                          </td>
                        ) : (
                          ""
                        )}
                        <td>
                          <div
                            className="typeofTxt"
                            style={{ display: "grid", paddingTop: "0" }}
                          >
                            <DropDown
                              value={item.count}
                              callback={(event) => onAddOnHandler(event, index)}
                              length={100}
                            />
                            {/* <input
                              type="number"
                              onChange={(e) =>
                                e.target.value > 0
                                  ? onAddOnHandler(e, index)
                                  : null
                              }
                              value={item.count}
                              className=" paintupDownheight"
                            /> */}

                            {/* {userState.number} */}
                          </div>
                          {/* <label className="paintdiv_txt">
                    </label>  */}
                        </td>
                        {/* <td
                          className="paint_arrowFloat otherspadding15"
                          style={{ width: "15px" }}
                        >
                          <div
                            className="paint_arrowHeight cursor_hand"
                            onClick={(e) => arrowHandler("plus", e, index)}
                          >
                            <img src={IMAGES.other.vectorUP} alt="" />
                          </div>
                          <div
                            className="paint_arrowHeight cursor_hand"
                            onClick={(e) => arrowHandler("minus", e, index)}
                          >
                            <img
                              className="paint_arrowpadd_bottom"
                              src={IMAGES.other.vectorDown}
                              alt=""
                            />
                          </div>
                        </td> */}
                      </tr>
                    </table>
                  </div>
                ) : item.isChecked &&
                  !item.isSingle &&
                  item.showSelectInput === true ? (
                  <div>
                    {item.inputPlaceholder ? (
                      <label>{item.inputPlaceholder || ""}</label>
                    ) : (
                      ""
                    )}

                    <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding optionElectric">
                      <select
                        onChange={(e) => {
                          const selectedOption = item.options.find(
                            (op) => op.key === e.target.value
                          );
                          console.log(selectedOption);
                          const modifiedAddons = addOns.map((op) => {
                            if (op.value === item.value) {
                              return {
                                ...op,
                                price: selectedOption.price,
                                selectedOption,
                              };
                            } else {
                              return op;
                            }
                          });
                          setAddOns(modifiedAddons);
                          calculateTotal(modifiedAddons);
                        }}
                      >
                        {item.options.map((op) => (
                          <option value={op.key}>
                            {`${t(`electric.${op.key}`)} + $${formatPrice(
                              op.price
                            )}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {item.value === "emergency" && item.isChecked ? (
                <label className="profilePadding8">
                  This price is exclusively for the electrician to show up and
                  diagnose the problem. Depending on the problem there might be
                  additional charges.
                </label>
              ) : null}
            </div>
          ))}
        <Button
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => onSave()}
        />
      </div>
    </div>
  );
};
