import React, { useEffect } from "react";
import BankDetailsForm from "../../User/BankDetailsForm";
import IMAGES from "../../../_assets/images";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../services/api/config/api.config";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AddBankDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const { signUp } = location?.state || {};

  return (
    <>
      <div
        className="custom-header d-flex align-items-center px-5 "
        style={{ height: "90px" }}
      >
        <div className="profile-head w-100">
          <img
            onClick={() => history.goBack()}
            className="close-icon"
            src={IMAGES.home.backIcon}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div
        className="verify-mobile-modal m-auto mt-5"
        style={{ maxWidth: "650px" }}
      >
        <BankDetailsForm signUp={signUp} />
      </div>
    </>
  );
};

export default AddBankDetails;
