import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import { TextInput } from "../../_components/TextInput/TextInput";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { wallOpeningModel } from "../../_redux/Models/wallOpening.model";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { isInteger } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, wallOpeningStore } from "../../_redux/actions";
import {
  getServiceCategoriesFull,
  getServicePriceDetails,
} from "../../services/api/category.api.service";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useHistory } from "react-router-dom";

export const OpeningSize = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 1;
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [value, setValue] = useState(""); // To keep the wall size in state

  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(
    calculateTotalCost(wallOpeningModel.costFor)
  ); // To keep the total price in state
  const [feetPricing, setFeetPricing] = useState({ price: 0 });

  useEffect(() => {
    let mounted = true;
    getServiceCategoriesFull().then((items) => {
      if (mounted && items.status === 200) {
        let filteredItem = items.data.find(
          (item) => item.name === "Wall opening"
        );

        checkoutModelAs.selectedServiceDetails = filteredItem.subCategory[0];
        checkoutModelAs.selectedService = "Wall opening";
        checkoutModelAs.totalCost = 0;
        dispatch(checkoutStore(checkoutModelAs));
        getServicePriceDetails(
          checkoutModelAs.selectedServiceDetails.categoryId,
          checkoutModelAs.selectedServiceDetails._id
        ).then((response) => {
          if (response.status === 200 && response.data) {
            checkoutModelAs.priceDetails = response.data.details;
            setFeetPricing(response.data.details.linearFeet);
            dispatch(checkoutStore(checkoutModelAs));
          }
        });
      }
    });
    return () => (mounted = false);
  }, [checkoutModelAs, dispatch]);

  /**
   * Calls when submit button is clicked
   */
  const onSubmitVal = () => {
    wallOpeningModel.size = value;
    wallOpeningModel.costFor.linear = price;
    dispatch(wallOpeningStore(wallOpeningModel));
    checkoutModelAs.totalCost = price;
    dispatch(checkoutStore(checkoutModelAs));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/wall_opening/height/2`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("wallOpening.howManyFeetNeed")}
            </label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("wallOpening.pleaseIntroduceLinear")}
            </label>
          </div>
          <div className="paintinput_lable mb-4">
            <TextInput
              className="paint_input_border"
              error={isInteger(value)}
              type="number"
              onChange={(e) => {
                if (e.target.value < 17) {
                  const val = e.target.value;
                  setValue(val);
                  setPrice(val * feetPricing.price);
                } else {
                  alert("Maximum 16 feet");
                }
              }}
              value={value}
              placeholder={t("Please enter feet")}
              label={t("wallOpening.linearFeet")}
            />
            <div className="paint_placeholder_right">
              <label>{t("wallOpening.inft")}</label>
            </div>
          </div>
          <Button
            disabled={!value}
            buttonLabel={t("other.instantQuote")}
            onClick={onSubmitVal}
          />
        </div>
      </div>
    </div>
  );
};
