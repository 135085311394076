import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import "../../_styles/localStyles/home.scss";
import Header from "./headerSection/Header";
import Categories from "./headerSection/Categories";
import {
  getContractors,
  getContractorsUsingLocation,
} from "../../services/api/contractor.service";
import ContractorCard from "../Contractor/ContractorCard";
import IMAGES from "../../_assets/images";
import NewFooter from "./footerSection/NewFooter";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { latLonState, searchState } from "../../recoil/atoms/headerAtom";
import { fetchWishlist } from "../../services/api/owner.service";
import {
  contractorsList,
  selectedContractorAtom,
  wishlistedContractorsAtom,
  wishlistedList,
} from "../../recoil/atoms/contractorAtom";
import { getData } from "../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../_configs/constants.config";
import { SwipeableDrawer } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import { DebouncedInput } from "../../_components/DebouncedInput";

const Home = () => {
  const [contractors, setContractors] = useRecoilState(contractorsList);
  const [wishlisted, setWishlisted] = useRecoilState(wishlistedList);
  const [wishlistedContractors, setWishlistedContractors] = useRecoilState(
    wishlistedContractorsAtom
  );
  const [selectedContractorState, setSelectedContractorState] = useRecoilState(
    selectedContractorAtom
  );
  const userDetails = getData(LOCAL_STORAGE.user);

  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const drawerRef = useRef();
  const searchIconRef = useRef(null);
  const [latLonStateValue, setLatLonState] = useRecoilState(latLonState);
  const [search, setSearch] = useRecoilState(searchState);
  const reduxState = useSelector((state) => state?.buttonReducer);
  const selectedCategoryId = reduxState?.selectedCategory?.selectedCategory;
  const drawerBleeding = 56;

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: process.env.REACT_APP_MAP_API_KEY,
      options: { types: ["(regions)"] },
    });

  const onPlaceSelected = (place) => {
    setSearch(place?.description);
    placesService?.getDetails(
      {
        placeId: place?.place_id,
      },
      (placeDetails) => {
        console.log(placeDetails);

        const { lat, lng } = placeDetails?.geometry?.location?.toJSON();
        setIsDropDownOpen(false);
        setLatLonState({ lat, lon: lng });
        setDrawerOpen(false);
        if (history?.location?.pathname !== "/") {
          history.push("/");
        }
      }
    );
  };

  useEffect(() => {
    setIsLoading(true);
    getContractors(reduxState?.selectedCategory?.selectedCategory)
      .then((result) => {
        if (result && result?.length > 0) {
          setContractors(result);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }
        const user = getData(LOCAL_STORAGE.user);
        if (user) {
          fetchWishlist(reduxState?.selectedCategory?.selectedCategory)
            .then((result) => {
              setIsLoading(false);
              if (result && result?.data?.wishlists?.length > 0) {
                setWishlisted(result?.data?.wishlists);
                let arr = [];
                result?.data?.wishlists?.forEach((item) => {
                  arr.push(item?.contractor?._id);
                });
                setWishlistedContractors(arr);
              }
            })
            .catch((err) => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [reduxState?.selectedCategory?.selectedCategory]);

  useEffect(() => {
    if (latLonStateValue?.lat && latLonStateValue?.lon) {
      setIsLoading(true);

      getContractorsUsingLocation(
        reduxState?.selectedCategory?.selectedCategory,
        latLonStateValue?.lat,
        latLonStateValue?.lon
      )
        .then((result) => {
          if (result && result?.length > 0) {
            setContractors(result);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setIsEmpty(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [latLonStateValue]);

  useEffect(() => {
    const userDetailsParsed = JSON.parse(localStorage.getItem("user"));
    if (userDetailsParsed && userDetailsParsed.userType === "contractor") {
      history.push("/contractor-projects");
    }
  }, []);

  return (
    <>
      <Header />
      <Categories />
      <div className="px-3 px-lg-5">
        <div className="mt-5">
          {isLoading ? (
            <p className="text-info">{t("home.loading")}</p>
          ) : !isEmpty ? (
            <div className="contractors-container row px-0">
              {contractors?.map((contractor) => (
                <div className="contractor-item col-12 col-md-6 col-lg-4 col-xl-3">
                  <Link
                    onClick={() => setSelectedContractorState(contractor?.id)}
                    to={`/contractor-portfolio/${contractor?.id}`}
                  >
                    <ContractorCard
                      contractor={contractor}
                      isWishListed={wishlistedContractors?.includes(
                        contractor?.id
                      )}
                    />
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-info">{t("home.contractorsNotAvailable")}</p>
          )}
        </div>
      </div>
      <div className="mobile-bottom-nav d-flex d-lg-none">
        <Link
          to="#"
          onClick={() => setDrawerOpen(true)}
          className="d-flex flex-column"
        >
          <img src={IMAGES.home.searchBlack} />
          <span>{t("footer.search")}</span>
        </Link>
        <Link to="/favorites" className="d-flex flex-column">
          <img src={IMAGES.home.heartOulined} />
          <span>{t("footer.favorites")}</span>
        </Link>
        <Link
          to={
            userDetails?.userType
              ? `${userDetails?.userType}/my-profile`
              : "/signin"
          }
          className="d-flex flex-column"
        >
          <img src={IMAGES.home.defaultUser} />
          <span>{t("footer.profile")}</span>
        </Link>
      </div>
      <div className="fixed-footer">
        <NewFooter />
      </div>
      <SwipeableDrawer
        ref={drawerRef}
        anchor="bottom"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        disableEnforceFocus
        sx={{}}
        PaperProps={{
          style: {
            top: "100px",
            borderRadius: "30px",
            overflowY: "auto",
            height: "100%",
            paddingBottom: "100px",
          },
        }}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
      >
        <div className="" style={{ height: "100%", padding: "0px 20px" }}>
          <div
            style={{ height: "50px", backgroundColor: "#fff" }}
            className="position-sticky top-0 d-flex justify-content-end p-2"
          >
            <div className="d-flex w-100 justify-content-between pt-4 px-3 align-items-center">
              <h1
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
                className="mb-0"
              >
                {t("footer.search")}
              </h1>
              <button
                onClick={() => setDrawerOpen(!drawerOpen)}
                className="btn p-0"
              >
                <img src={IMAGES.home.modalClose} />
              </button>
            </div>
          </div>
          <div className="search-container position-relative mt-5">
            <DebouncedInput
              placeholder={t("header.searchPlaceHolder")}
              type="text"
              className="form-control"
              onInputChange={(val) => {
                getPlacePredictions({ input: val });
                setIsDropDownOpen(true);
              }}
              debounceTime={100}
              value={search}
              setValue={setSearch}
            />
            {placePredictions?.length > 0 && isDropDownOpen ? (
              <div className="places-dropdown position-absolute d-flex flex-column bg-white w-100">
                {placePredictions?.map((prediction) => (
                  <div
                    className="places-item d-flex w-100"
                    onClick={() => onPlaceSelected(prediction)}
                  >
                    <img
                      className="position-relative bg-secondary"
                      src={IMAGES.other.locationIcon}
                      height={24}
                    />
                    <span className="">
                      {prediction?.description}
                      {console.log(prediction)}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              " "
            )}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default Home;
