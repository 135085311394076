import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import { TextInput } from "../../_components/TextInput/TextInput";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { basementModel } from "../../_redux/Models/basement.model";
import { isInteger } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { basementStore, checkoutStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { getServicePriceDetails } from "../../services/api/category.api.service";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const BasementSize = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 2;
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);
  const [basementSize, setBasementSize] = useState(""); // To keep the basement size in state
  const [price, setPrice] = useState(calculateTotalCost(basementModel.costFor)); // To keep the total price in state
  const [sizePricing, setSizePricing] = useState([]);

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;

    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          checkoutModelState.priceDetails = response.data.details;
          setSizePricing(response.data.details.size);
          dispatch(checkoutStore(checkoutModelState));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch]);

  /**
   * Calls when submit button is clicked
   */
  const onSubmitBasementSize = () => {
    basementModel.basementSize = basementSize;
    basementModel.costFor.size = price;
    dispatch(basementStore(basementModel));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/basement/height/3`
    );
  };

  const calculatePrice = () => {
    var basementSizeFloat = parseFloat(basementSize);
    var price = 0;
    if (!isNaN(basementSizeFloat) && sizePricing) {
      var selectedPrice = sizePricing.find((t) =>
        t.minReferenceValue <= basementSizeFloat &&
        t.maxReferenceValue >= basementSizeFloat
          ? t
          : null
      );
      if (selectedPrice) {
        price = basementSizeFloat * selectedPrice.price;
      }
    }
    setPrice(price);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("basement.introduceBasementSize")}
            </label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("basement.pleaseIntroduceBasementSize")}
            </label>
          </div>
          <div className="paintinput_lable mb-4">
            <TextInput
              className="paint_input_border"
              error={isInteger(basementSize)}
              type="number"
              onChange={(e) => {
                if (e.target.value < 10001) {
                  setBasementSize(e.target.value);
                }
              }}
              value={basementSize}
              onKeyUp={() => calculatePrice()}
              placeholder={t("paintingInterior.placeholder500")}
              label={t("basement.sizing")}
            />
            <div className="paint_placeholder_right">
              <label>{t("paintingInterior.sqft")}</label>
            </div>
          </div>
          <Button
            disabled={!basementSize}
            buttonLabel={t("basement.continueToNext")}
            onClick={onSubmitBasementSize}
          />
        </div>
      </div>
    </div>
  );
};
