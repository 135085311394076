import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/home.css";
import "../../../_styles/localStyles/paint.css";
import "../../../_styles/localStyles/floor.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import { t } from "i18next";
import { exteriorModel } from "../../../_redux/Models/painting.models";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, paintingStore } from "../../../_redux/actions";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { Button } from "../../../_components/Button/Button";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";
export const Housematerial = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 0;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history } = props;

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.paintingPayLoad
      ? state.buttonReducer.paintingPayLoad
      : exteriorModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state
  const [exteriorMaterialOptions, setExteriorMaterialOptions] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState({
    value: "",
    price: 0,
  });
  const [brickSizePercentage, setBrickSizePercentage] = useState(0);
  const [sidingPercentage, setSidingPercentage] = useState(0);
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const size = parseFloat(state.size);
    if (mounted === true && priceDetails && priceDetails.exteriorMaterial) {
      const optionsArr = priceDetails.exteriorMaterial.map((op) => {
        return {
          text: t(`paintingExterior.${op.key}`),
          value: op.key,
          price: op.key !== "brick_and_siding" ? size * op.price : op.price,
          displayPrice: op.key !== "brick_and_siding" ? size * op.price : 0,
        };
      });
      setExteriorMaterialOptions(optionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, state.size, t]);

  const calculatePrice = (item) => {
    item = item ? item : selectedMaterial;
    const total = calculateTotalCost(state.costFor);
    const size = state.size;
    let sum = 0;
    if (item.value !== "brick_and_siding") {
      sum = total + item.price;
    } else {
      const brick_SidingPrice = exteriorMaterialOptions.find(
        (op) => op.value === "brick_and_siding"
      ).price;
      const brickCost =
        brick_SidingPrice * ((parseInt(brickSizePercentage) * size) / 100);
      const sidingCost =
        brick_SidingPrice * ((parseInt(sidingPercentage) * size) / 100);
      sum = total + brickCost + sidingCost;
    }

    setPrice(sum);
  };

  const onSave = () => {
    state.houseMaterial = selectedMaterial;
    state.brickPercentage = brickSizePercentage;
    state.sidingPercentage = sidingPercentage;
    state.costFor.exteriorMaterial = price - calculateTotalCost(state.costFor);
    dispatch(paintingStore(state));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/painting/exterior/pick-color/${4}`
    );
  };
  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left mb-3">
          <label className="paint_header">{t("paintingExterior.whatis")}</label>
        </div>

        {exteriorMaterialOptions.map((item, index) => (
          <div
            className={`floor-selection-radio ${
              selectedMaterial?.value === item?.value ? "selected" : ""
            }`}
            onClick={() => {
              setSelectedMaterial(item);
              calculatePrice(item);
            }}
          >
            <div
              key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label
                className="paintdiv_txt"
                style={{
                  width: "65%",
                }}
              >
                {item.text}
              </label>
              <div
                className="paint_arrowFloat"
                style={{
                  width: "35%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img src={IMAGES.other.green_plus} alt="" />
                  <label className="paintdiv_txt">
                    {`$${formatPrice(item.displayPrice)}`}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}
        {selectedMaterial.value === "brick_and_siding" ? (
          <div>
            <div className="paintinput_lable">
              <TextInput
                className="paint_input_border"
                onChange={(event) => setBrickSizePercentage(event.target.value)}
                value={brickSizePercentage ? brickSizePercentage : ""}
                onKeyUp={() => calculatePrice(selectedMaterial)}
                type={"number"}
                max={100}
                min={1}
                placeholder={t("paintingExterior.60")}
                label={t("paintingExterior.brick")}
              />

              <div className="paint_placeholder_right">
                <label>{t("paintingExterior.presentage")}</label>
              </div>
            </div>
            <div className="paintinput_lable mb-4">
              <TextInput
                className="paint_input_border"
                onChange={(event) => setSidingPercentage(event.target.value)}
                value={sidingPercentage ? sidingPercentage : ""}
                onKeyUp={() => calculatePrice(selectedMaterial)}
                type={"number"}
                max={100}
                min={1}
                placeholder={t("paintingExterior.40")}
                label={t("paintingExterior.siding")}
              />

              <div className="paint_placeholder_right">
                <label>{t("paintingExterior.presentage")}</label>
              </div>
            </div>
          </div>
        ) : null}
        <Button
          disabled={
            selectedMaterial.value === "" ||
            (selectedMaterial.value === "brick_and_siding" &&
              parseFloat(brickSizePercentage || 0) +
                parseFloat(sidingPercentage || 0) !==
                100)
          }
          buttonLabel={t("paintingHomes.continue")}
          onClick={onSave}
        />
      </div>
    </div>
  );
};
