import axios from "axios";
import { API_URL, authEndPoint, signupEndPoint } from "../apiConstants";

const authToken = localStorage.getItem("auth_token");
const config = {
  headers: { Authorization: `Bearer ${authToken}` },
};

const googleSignup = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}${authEndPoint.googleLogin}`,
      data
    );
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};

const fbSignup = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}${authEndPoint.facebookLogin}`,
      data
    );
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};
const activeServices = async () => {
  try {
    const response = await axios.get(
      `${API_URL}${signupEndPoint.activeService}`
    );
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};
const userVerification = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}${signupEndPoint.userVerify}`,
      data
    );
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};

const mobileEmailUserVerification = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}${signupEndPoint.mobileEmailVerify}`,
      data
    );
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};
const mobileVerification = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}${signupEndPoint.mobileVerify}`,
      data
    );
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};

/**
 *
 * @param {Object} data data to pass
 * @returns Response
 */
const uploadProfileDocs = async (data) => {
  try {
    const authToken = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data; boundary='profile update'",
      },
    };
    const response = await axios.put(
      `${API_URL}${signupEndPoint.contractorProfileUpload}`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};

const uploadProfile = async (_id, data) => {
  try {
    return axios.put(
      `${API_URL}${signupEndPoint.contractorProfileUpload}`,
      data,
      config
    );
  } catch (error) {
    return error.response.data.message;
  }
};

/**
 *
 * @param {Object} data data to pass
 * @returns Response
 */
const uploadContractorPictureOfWork = async (data) => {
  try {
    const authToken = localStorage.getItem("auth_token");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
    };
    const response = await axios.put(
      `${API_URL}${signupEndPoint.contractorPictureOfWorkUpload}`,
      data,
      config
    );
    return response;
  } catch (error) {
    return error.response.data.message;
  }
};

const manuelRegister = async (data, userType) => {
  try {
    if (userType === "contractor") {
      let contractorObj = {
        name: data.name,
        email: data.email,
        password: data.password,
        address: data.address,
        zipCode: data.zipCode,
        mobileNumber: data.mobileNumber,
      };
      var response = await axios.post(
        `${API_URL}${signupEndPoint.contractorSignup}`,
        contractorObj
      );
    } else if (userType === "owner") {
      response = await axios.post(
        `${API_URL}${signupEndPoint.ownerSignup}`,
        data
      );
    }
    return response;
  } catch (error) {
    return error;
  }
};

async function resendOTP(data) {
  return await axios
    .post(`${API_URL}${signupEndPoint.resendOTP}`, data)
    .then((res) => {
      let response = res.data;
      return response;
    });
}

async function mobileResendOTP(data) {
  return await axios
    .post(`${API_URL}${signupEndPoint.mobileResendOTP}`, data)
    .then((res) => {
      let response = res.data;
      return response;
    });
}

async function forgotPasswordAPI(data) {
  return await axios
    .post(`${API_URL}${signupEndPoint.forgotPassword}`, data)
    .then((res) => {
      let response = res.data;
      return response;
    });
}

async function resetPasswordAPI(data) {
  try {
    return axios.put(`${API_URL}${signupEndPoint.resetPassword}`, data);
  } catch (e) {
    return e;
  }
}

export {
  manuelRegister,
  activeServices,
  userVerification,
  mobileEmailUserVerification,
  mobileResendOTP,
  mobileVerification,
  googleSignup,
  fbSignup,
  uploadProfileDocs,
  resendOTP,
  forgotPasswordAPI,
  resetPasswordAPI,
  uploadContractorPictureOfWork,
  uploadProfile,
};
