import React, { useEffect, useState } from "react";
import IMAGES from "../../_assets/images/index";
import "../../_styles/localStyles/drawer.css";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
  getData,
  setData,
} from "../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../_configs/constants.config";
import { FireBase } from "../../services/firebase/firebase.service";
import { getAllNotifications } from "../../services/api/notifications.api.services";
import Toggle from "../../_components/Toggle/Toggle";
import { signupModel } from "../../_redux/Models/auth.models";
import { useDispatch } from "react-redux";
import SendbirdChat from "@sendbird/chat";
import { GroupChannelModule } from "@sendbird/chat/groupChannel";
import { parseJwt } from "../../services/utils/utilFunctions";
import { continuetoNext } from "../../_redux/actions";

export const Homedrawer = ({ closeHandler }) => {
  const { t } = useTranslation();
  const userDetails = getData(LOCAL_STORAGE.user);
  const history = useHistory();
  const [isContractor, toggleProfile] = useState(
    userDetails?.userType === "contractor"
  );
  const [load, setLoad] = useState(false);
  const [chatDot, setChatDot] = useState(0);
  const [notificationDot, setNotificationDot] = useState(0);
  const [firebase, setFireBase] = useState(null);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);

  const dispatch = useDispatch();
  let initialChatCount = "not-set";

  useEffect(() => {
    handleBackClick();
    const firebase = new FireBase();
    setFireBase(firebase);
    const userData = getData(LOCAL_STORAGE.user);
    if (userData?._id) {
      setDot();
      setNotificaitonDots();
      setupUser();
    }

    const broadcast = new BroadcastChannel("count-channel");
    if (broadcast) {
      broadcast.onmessage = (event) => {
        if (
          event.data.type === "BACKGROUND_MESSAGE" ||
          event.data.type === "NEW_MESSAGE"
        ) {
          setDot(1);
        } else if (event.data.type === "UPDATE_DOT") {
          setNotificaitonDots(1);
        } else if (event.data.type === "NAVIGATE") {
          if (event.data.payload.data["routeParam"])
            history.push(event.data.payload.data["routeParam"]);
        }
      };
    }
    return () => {
      window.removeEventListener("popstate", handleBackClick);
    };
  }, []);

  const drawer = [
    {
      text: t("homeDrawer.profile"),
      path: "",
      index: 1,
    },

    {
      text: t("homeDrawer.terms"),
      path: "/terms-and-condition",
      index: 8,
    },
    {
      text: t("homeDrawer.contact"),
      path: "/contact-us",
      index: 7,
    },
  ];

  if (userDetails !== null) {
    drawer.push(
      {
        text: t("homeDrawer.notifications"),
        path: "/user/notifications",
        index: 3,
      },
      {
        text: t("homeDrawer.chat"),
        path: "/user/chat",
        index: 4,
      },
      {
        text: t("homeDrawer.signout"),
        index: 10,
      }
    );
  }
  if (userDetails !== null && userDetails.userType === "owner") {
    drawer.push(
      {
        text: t("homeDrawer.home"),
        path: "/",
        index: 0,
      },
      {
        text: t("homeDrawer.project"),
        path: "/user/my-projects",
        index: 5,
      },
      {
        text: t("homeDrawer.contractor"),
        path: "/signup",
        index: 6,
      }
    );
  }
  if (userDetails !== null && userDetails.originalStatus === "contractor") {
    drawer.push({
      text: `${isContractor ? "Change to owner" : "Change to contractor"}`,
      path: "/toggle",
      index: 2,
    });
  }
  if (userDetails !== null && userDetails.userType === "contractor") {
    drawer.push({
      text: t("homeDrawer.project"),
      path: "/contractor-projects",
      index: 5,
    });
  }

  const handleProfileToggle = () => {
    let _userDetails = getData(LOCAL_STORAGE.user);
    let _isContractor = isContractor;
    if (_userDetails.userType === "contractor") {
      _userDetails.userType = "owner";
      _isContractor = false;
    } else {
      _userDetails.userType = "contractor";
      _isContractor = true;
      history.push("/contractor-projects");
    }
    setData("user", _userDetails);
    toggleProfile(_isContractor);
  };

  const navBarHandler = async (item) => {
    if (item.text === "Home") {
      history.push("/");
      closeHandler();
    } else if (item.path === "/toggle") {
      handleProfileToggle();
    } else if (item.path === "/wallet") {
      history.push("/wallet");
    } else if (item.text === t("homeDrawer.signout")) {
      setLoad(true);
      // chatService.removeConversationClient();
      if (firebase) firebase.deleteToken();
      localStorage.clear("user");
      setLoad(false);
      setChatDot(0);
      setNotificationDot(0);
      window.location = "/";
    } else if (item.path === "/signup") {
      signupModel.signuserType = "contractor";
      dispatch(continuetoNext(signupModel));
      history.push("/signup");
    } else if (item.text === t("homeDrawer.profile")) {
      const user = parseJwt(localStorage.getItem("auth_token"));
      console.log(user);
      if (user && user.userType === "contractor") {
        history.push(`/contractor/profile`);
      } else if (user && user.userType === "owner") {
        history.push(`/owner/my-profile`);
      } else {
        history.push("/role");
      }
    } else {
      history.push(item.path);
    }
  };

  const handleBackClick = () => {
    let mounted = true;
    if (mounted) {
    }

    return () => (mounted = false);
  };

  const setupUser = async () => {
    //   const SENDBIRD_INFO = process.env.REACT_APP_SENDBIRD_APP_ID;
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user._id) {
      let userIdInputValue = user._id;

      const sendbirdChat = await SendbirdChat.init({
        appId: process.env.REACT_APP_SENDBIRD_APP_ID,
        localCacheEnabled: false,
        modules: [new GroupChannelModule()],
      });

      await sendbirdChat.connect(userIdInputValue);
      const chatCount =
        await sendbirdChat.groupChannel.getTotalUnreadMessageCount();
      initialChatCount = chatCount;
    }
  };

  const setDot = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let userNameInputValue = user.name;
      let userIdInputValue = user._id;

      const sendbirdChat = await SendbirdChat.init({
        appId: process.env.REACT_APP_SENDBIRD_APP_ID,
        localCacheEnabled: false,
        modules: [new GroupChannelModule()],
      });

      await sendbirdChat.connect(userIdInputValue);
      const chatCount =
        await sendbirdChat.groupChannel.getTotalUnreadMessageCount();
      setTimeout(() => {
        setDot();
      }, 60000);
      if (chatCount > initialChatCount) {
        setChatDot(chatCount);
      }
    }
  };

  const setNotificaitonDots = async () => {
    const notifications = await getAllNotifications();
    setNotificationDot(notifications.data.length);
  };

  const signUp = () => {
    history.push("/role");
  };
  return (
    <div className="home-sectionpadd20 ">
      <table style={{ width: "100%", placeItems: "center" }}>
        <tr>
          <td>
            <label
              className="signup_page_anyhnb_logo"
              //   onClick={() => window.location.replace("/")}
            >
              {t("signUp.mainHeading")}
            </label>
          </td>
          <td
            style={{ float: "right", paddingTop: "15px", cursor: "pointer" }}
            onClick={() => closeHandler()}
          >
            <img
              src={IMAGES.other.closeVector}
              alt=""
              width="30px"
              height="30px"
            />
          </td>
        </tr>
      </table>
      {/* <table className="profileTablewidth " id="rtr">
          <tr className={`profileFlex  ${item.isExpand}`}>
            
              drawer.map((index) => (
                  
                      <td className="profilePadding15">
                        <div className="profileDivtxt"> {subItem.name}</div>
                      </td>
              
          </tr>
        </table> */}
      <div className="home-sectionpadd20">
        {drawer
          .sort((a, b) => (b.index < a.index ? 1 : -1))
          .map((item, index) => (
            <div onClick={() => navBarHandler(item)} key={index}>
              {/* <Link to={item.path}>
                {" "} */}
              <div className="homeDrawertxt"> {item.text}</div>
              {/* </Link> */}
            </div>
          ))}
      </div>
    </div>
  );
};
