import React, { useState, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import CheckBox from "../../_components/CheckBox/checkBox";
import { Button } from "../../_components/Button/Button";

import { checkoutStore, treeStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { treeModel } from "../../_redux/Models/tree.model";
import { CannotContinueModal } from "../floorComponents/Common/CannotContinue.modal";
import { DropDown } from "../../_components/dropDown/dropDown";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const TreeDetailsNearElectric = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue = 3;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.treePayload
      ? state.buttonReducer.treePayload
      : treeModel
  );

  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state

  const [addOns, setAddOns] = useState([]);
  const [modalReason, setModalReason] = useState("");

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (
      mounted === true &&
      priceDetails &&
      priceDetails.nearbyInstallationTreesHeights
    ) {
      //const anyElectricInstallationsNear = state.anyElectricInstallationsNear;
      // const optionsFromServer =
      //   anyElectricInstallationsNear === "yes"
      //     ? priceDetails.nearbyInstallationTreesHeights
      //     : priceDetails.notNearbyInstallationTreesHeights;
      const optionsFromServer = priceDetails.nearbyInstallationTreesHeights;

      const singleItems = ["zoning_system"];

      const optionsArr = optionsFromServer.map((op) => {
        let price = op.price;
        const electricInstallationPrices = [
          {
            key: "10_to_16",
            price: 250,
          },
          {
            key: "16_to_26",
            price: 500,
          },
          {
            key: "26_to_36",
            price: 750,
          },
          {
            key: "36_to_56",
            price: 1000,
          },
          {
            key: "more_than_56",
            price: 1250,
          },
        ];
        return {
          text: t(`treeService.${op.key}`),
          value: op.key,
          count: 1,
          stumpCount: 0,
          debrisCount: 0,
          machineryCount: 0,
          structuresNearByCount: 0,
          isChecked: false,
          isSingle: singleItems.includes(op.key),
          priceForCount: price,
          priceForStump: priceDetails.stumpGrinding.find(
            (o) => o.key === op.key
          ).price,
          priceForDebrisHaul: priceDetails.debrisHaulAway.find(
            (o) => o.key === op.key
          ).price,
          priceForStructuresNearBy: electricInstallationPrices.find(
            (o) => o.key === op.key
          ).price,
          priceForMachinery: priceDetails.cannotReachWithMachinery.price,
        };
      });
      setAddOns(optionsArr);
      console.log(optionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state.anyElectricInstallationsNear, t]);

  const onSave = () => {
    const total = calculateTotalCost(state.costFor);
    const addOnsSelected = addOns.filter((i) => i.isChecked === true);
    state.treeDetails = addOnsSelected;
    state.costFor.installation = addOnsSelected.reduce(
      (p, c) => p + c.priceForCount * c.count,
      0
    );
    state.costFor.stumpGrinding = addOnsSelected.reduce(
      (p, c) => p + c.priceForStump * c.stumpCount,
      0
    );
    state.costFor.debrisHaul = addOnsSelected.reduce(
      (p, c) => p + c.priceForDebrisHaul * c.debrisCount,
      0
    );
    state.costFor.machinery = addOnsSelected.reduce(
      (p, c) => p + c.priceForMachinery * c.machineryCount,
      0
    );
    state.costFor.nearByStructure = addOnsSelected.reduce(
      (p, c) => p + c.priceForStructuresNearBy * c.structuresNearByCount,
      0
    );
    state.numberOfTreesOutOfReach = addOnsSelected
      .reduce((p, c) => p + c.machineryCount, 0)
      .toString();
    state.anyElectricInstallationsNear = addOnsSelected
      .reduce((p, c) => p + c.structuresNearByCount, 0)
      .toString();
    state.needStumpGrinding = addOnsSelected
      .reduce((p, c) => p + c.stumpCount, 0)
      .toString();
    state.needDebrisHaul = addOnsSelected
      .reduce((p, c) => p + c.debrisCount, 0)
      .toString();
    state.anyTreesOutOfReach = parseInt(state.numberOfTreesOutOfReach) > 0;
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(treeStore(state));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/tree_services/tree-selection`
    );
  };

  function arrowHandler(params, e, index, keyToIncrease) {
    let updatedState = [...addOns];
    const maxPossibleCount =
      keyToIncrease === "count" ? 1000 : updatedState[index]["count"];
    // let actualCount =
    //   updatedState[index]["stumpCount"] +
    //   updatedState[index]["debrisCount"] +
    //   updatedState[index]["machineryCount"] +
    //   updatedState[index]["structuresNearByCount"];
    // actualCount = actualCount > 0 ? actualCount : 0;
    let actualCount = updatedState[index][keyToIncrease];

    console.log("maxPossibleCount", maxPossibleCount);
    console.log(actualCount, "actualCount");
    if (params === "plus" && maxPossibleCount > actualCount) {
      updatedState[index][keyToIncrease] =
        updatedState[index][keyToIncrease] + 1;
    } else if (params === "minus" && updatedState[index][keyToIncrease] > 0) {
      updatedState[index][keyToIncrease] =
        updatedState[index][keyToIncrease] - 1;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  }
  function onChangeInput(e, index, keyToIncrease) {
    let updatedState = [...addOns];
    const maxPossibleCount =
      keyToIncrease === "count" ? 1000 : updatedState[index]["count"];
    // let actualCount =
    //   updatedState[index]["stumpCount"] +
    //   updatedState[index]["debrisCount"] +
    //   updatedState[index]["machineryCount"] +
    //   updatedState[index]["structuresNearByCount"];
    // actualCount = actualCount > 0 ? actualCount : 0;
    let actualCount = updatedState[index][keyToIncrease];

    console.log("maxPossibleCount", maxPossibleCount);
    console.log(actualCount, "actualCount");
    if (maxPossibleCount > actualCount) {
      updatedState[index][keyToIncrease] = e.target.value;
      calculateTotal(updatedState);
      setAddOns(updatedState);
    }
  }

  const calculateTotal = (addOns) => {
    const total = addOns
      .filter((v) => v.isChecked === true)
      .reduce((p, c) => {
        return (
          p +
          (c.priceForCount * c.count +
            c.priceForDebrisHaul * c.debrisCount +
            c.priceForMachinery * c.machineryCount +
            c.priceForStructuresNearBy * c.structuresNearByCount +
            c.stumpCount * c.priceForStump)
        );
      }, 0);
    setPrice(calculateTotalCost(state.costFor) + total);
    return total;
  };

  const toggleCheck = (event, index) => {
    let updatedState = [...addOns];
    if (!updatedState[index].isChecked) {
      updatedState[index].isChecked = event;
    } else {
      updatedState[index].isChecked = !event;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  const onAddOnHandler = (event, index, keyToIncrease) => {
    let updatedState = [...addOns];
    updatedState[index][keyToIncrease] = parseInt(event.target.value);
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left paddinngtop40">
          <label className="paint_header">
            {t("treeService.treeHeightHead1")}
          </label>
        </div>
        <div className="paint_flex_with_left paddinngtop20">
          <label className="paint_other_sub_header ">
            {t("treeService.treeHeightTxt1")}
          </label>
        </div>
        <div className="padding10"></div>
        {addOns &&
          addOns !== undefined &&
          addOns.map((item, index) => (
            <div className="paintPadding8 ">
              <div
                className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright "
                onClick={() => toggleCheck(true, index)}
              >
                <div
                  key={index}
                  className="width100 "
                  style={{ justifyContent: "space-between" }}
                >
                  <table>
                    <tr>
                      <td>
                        <label className="paintdiv_txt"> {item.text} </label>
                      </td>
                      <td>
                        <div
                          className="paint_arrowFloat flex"
                          style={{ paddingTop: "10px" }}
                        >
                          <img
                            src={IMAGES.other.green_plus}
                            className="kitchenplusimg"
                            alt=""
                          />
                          <label
                            className="paintdiv_txt paddingleft8"
                            style={{ paddingLeft: "7px", paddingTop: "2px" }}
                          >
                            {`$${formatPrice(
                              item.priceForCount * item.count +
                                item.priceForDebrisHaul * item.debrisCount +
                                item.priceForMachinery * item.machineryCount +
                                item.priceForStructuresNearBy *
                                  item.structuresNearByCount +
                                item.stumpCount * item.priceForStump
                            )}`}
                          </label>
                          <div
                            className="paint_arrowFloat"
                            style={{ paddingLeft: "7px" }}
                          >
                            <CheckBox checked={item.isChecked} readOnly />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="paintPadding8 ">
                {item.isChecked && !item.isSingle ? (
                  <div>
                    <div>
                      <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding tree_service_arrow_padding">
                        <table className="paint_table100">
                          <tr>
                            <td>
                              <div
                                className="typeofTxt"
                                style={{ display: "grid", paddingTop: "0px" }}
                              >
                                <DropDown
                                  showPlaceHolder={true}
                                  value={item.count}
                                  callback={(event) =>
                                    onAddOnHandler(event, index, "count")
                                  }
                                  length={100}
                                />
                              </div>
                              {/* <label className="paintdiv_txt">
                    </label>  */}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    {/* Stump grinding option */}
                    <div>
                      <span
                        onClick={() =>
                          setModalReason(
                            t("treeService.reasonForStumpGrinding")
                          )
                        }
                      >
                        <strong>
                          {t("treeService.howManyNeedStumpGrinding")}
                          <span className="info-i">i</span>
                        </strong>
                      </span>
                      <div className="kitch_radio_div_firstarrow tree_service_arrow_padding">
                        <table className="paint_table100">
                          <tr>
                            <td>
                              <div
                                className="typeofTxt tree-service-input"
                                style={{ display: "grid", paddingTop: "0px" }}
                              >
                                <DropDown
                                  showPlaceHolder={true}
                                  value={item.stumpCount ? item.stumpCount : ""}
                                  callback={(event) =>
                                    onAddOnHandler(event, index, "stumpCount")
                                  }
                                  length={100}
                                />

                                {/* {userState.number} */}
                              </div>
                              {/* <label className="paintdiv_txt">
                    </label>  */}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    {/* End option */}

                    {/* Debris haul option */}
                    <div>
                      <span className="otherspadding15">
                        <strong>
                          {t("treeService.howManyDebrisHaulAway")}
                        </strong>
                      </span>
                      <div className="kitch_radio_div_firstarrow tree_service_arrow_padding">
                        <table className="paint_table100">
                          <tr>
                            <td>
                              <div
                                className="typeofTxt tree-service-input"
                                style={{ display: "grid", paddingTop: "0px" }}
                              >
                                <DropDown
                                  showPlaceHolder={true}
                                  value={
                                    item.debrisCount ? item.debrisCount : ""
                                  }
                                  callback={(event) =>
                                    onAddOnHandler(event, index, "debrisCount")
                                  }
                                  length={100}
                                />

                                {/* {userState.number} */}
                              </div>
                              {/* <label className="paintdiv_txt">
                    </label>  */}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    {/* End option */}

                    {/* howManyNearByStructure option */}

                    <div>
                      <span
                        onClick={() =>
                          setModalReason(
                            t("treeService.reasonForNoMachinaryReach")
                          )
                        }
                        className="otherspadding15"
                      >
                        <strong>
                          {t("treeService.howManyMachineNotReach")}
                          <span className="info-i">i</span>
                        </strong>
                      </span>
                      <div className="kitch_radio_div_firstarrow tree_service_arrow_padding">
                        <table className="paint_table100">
                          <tr>
                            <td>
                              <div
                                className="typeofTxt tree-service-input"
                                style={{ display: "grid", paddingTop: "0px" }}
                              >
                                <DropDown
                                  showPlaceHolder={true}
                                  value={item.structuresNearByCount || ""}
                                  callback={(event) =>
                                    onAddOnHandler(
                                      event,
                                      index,
                                      "structuresNearByCount"
                                    )
                                  }
                                  length={100}
                                />
                                {/* {userState.number} */}
                              </div>
                              {/* <label className="paintdiv_txt">
                    </label>  */}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    {/* End option */}
                    {/* howManyMachineNotReach option */}
                    <div>
                      <span
                        onClick={() =>
                          setModalReason(
                            t("treeService.reasonForElectricStructureNearby")
                          )
                        }
                        className="otherspadding15"
                      >
                        <strong>
                          {t("treeService.howManyNearByStructure")}
                          <span className="info-i">i</span>
                        </strong>
                      </span>
                      <div className="kitch_radio_div_firstarrow tree_service_arrow_padding">
                        <table className="paint_table100">
                          <tr>
                            <td>
                              <div
                                className="typeofTxt tree-service-input"
                                style={{ display: "grid", paddingTop: "0px" }}
                              >
                                <DropDown
                                  showPlaceHolder={true}
                                  value={item.machineryCount || ""}
                                  callback={(event) =>
                                    onAddOnHandler(
                                      event,
                                      index,
                                      "machineryCount"
                                    )
                                  }
                                  length={100}
                                />
                                {/* {userState.number} */}
                              </div>
                              {/* <label className="paintdiv_txt">
                    </label>  */}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    {/* End option */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        <div className="profilePadding8"></div>
        <Button
          disabled={addOns.filter((i) => i.isChecked === true).length === 0}
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => onSave()}
        />
      </div>
      {CannotContinueModal({
        isOpen: modalReason !== "",
        hideContractorIcon: false,
        reason: modalReason,
        onCloseAction: () => setModalReason(""),
        onNoClick: () => setModalReason(""),
      })}
    </div>
  );
};
