import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/profileCreate.css";
import "../../../_components/radioButton/radioButton.css";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import { t } from "i18next";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import ProgressbarProfile from "../../../_components/progressbar/progressProfile";
import CheckBox from "../../../_components/CheckBox/checkBox";
import { Button } from "../../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Wrong } from "../../../_components/Tickandwrongsvg/wrong";
import { Tick } from "../../../_components/Tickandwrongsvg/tick";
import { getContractor } from "../../../services/api/contractor.service";
import { parseJwt } from "../../../services/utils/utilFunctions";
import { useSnackbar } from "notistack";
import { uploadProfileDocs } from "../../../services/api/signup.service";
import Loader from "../../../_components/spinner/Loader";
export const Profilecomplete = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const { user } = useSelector((state) => state.signupReducer);
  const dispatch = useDispatch();
  const [chkValue, setChkValue] = useState(false);
  const [profileStatus, setProfileStatus] = useState(null);
  const [isProgress, setIsProgress] = useState(false);
  const [profileCompletionPercentage, setProfileCompletionPercentage] =
    useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const goToNext = () => {
    let redirectUrl = localStorage.getItem("redirectUrl");
    redirectUrl = redirectUrl ? redirectUrl : "/contractor-projects";
    localStorage.removeItem("redirectUrl");
    history.push(redirectUrl);
  };

  const saveTermsAndConditionToServer = () => {
    const postData = new FormData();
    if (chkValue) {
      setIsProgress(true);
      postData.append("termsAndConditionAccepted", chkValue);
      uploadProfileDocs(postData)
        .then((data) => {
          if (data.status !== 200) {
            const variant = "error";
            enqueueSnackbar("Services update failed! please try again", {
              variant,
            });
          } else {
            goToNext();
          }
        })
        .catch((e) => {
          const variant = "error";
          enqueueSnackbar(e.toString(), { variant });
        })
        .finally((_) => {
          setIsProgress(false);
        });
    }
  };

  useEffect(() => {
    let mounted = true;
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (mounted === true && user) {
      setIsProgress(true);
      getContractor(user._id).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          const profileDetails = response.data;
          console.log(profileDetails);
          const listStatus = {
            profileImage: {
              value: "Profile Picture",
              status:
                profileDetails.profileImage &&
                profileDetails.profileImage !== "",
            },
            profileinfo: {
              value: "Profile info",
              status:
                profileDetails.businessAddress &&
                profileDetails.businessAddress !== "",
            },
            certificates: {
              value: "Certificates/licences",
              status:
                profileDetails.licensePath && profileDetails.licensePath !== "",
            },
            trade: {
              value: "General liability insurance / workers compensation",
              status:
                profileDetails.tradeLicensePath &&
                profileDetails.tradeLicensePath !== "",
            },
            workpic: {
              value: "Pictures of your work",
              status:
                profileDetails.picturesOfWork &&
                profileDetails.picturesOfWork.length > 0,
            },
          };
          let completed = 0;
          for (const key in listStatus) {
            if (Object.hasOwnProperty.call(listStatus, key)) {
              const element = listStatus[key];
              if (element.status === true) {
                completed += 20;
              }
            }
          }
          setProfileStatus(listStatus);
          setChkValue(profileDetails.termsAndConditionAccepted);
          setProfileCompletionPercentage(completed);
          setIsProgress(false);
        }
      });
    }
    return () => (mounted = false);
  }, []);

  const statusHandler = () => {
    history.push(`/createProfile/profile-info/${2}`);
  };

  const StatusList = ({ element }) => {
    return (
      <>
        <div className="profilePadding8"></div>
        <div
          className="profilediv profile_complete_box flex"
          onClick={statusHandler}
        >
          <td className="padding10per ">
            {element.status ? (
              <Tick width="50px" height="50px" />
            ) : (
              <Wrong width="50px" height="50px" />
            )}
          </td>
          <td>
            <label className="procomTxt1 ">{element.value || ""}</label>
          </td>
        </div>
      </>
    );
  };

  return (
    <div className="insideWrp">
      <HeaderMenu />
      <Loader progress={isProgress} />

      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="allCenter">
          <table className="allCenter">
            <tr>
              <td>
                <ProgressbarProfile
                  progressValue={profileCompletionPercentage}
                />
              </td>
            </tr>
          </table>
        </div>
        <div className="profilePadding10">
          <label className="procomTxt allCenter">
            {t("profileCreation.completehaving")}
          </label>
        </div>
        <div className="profilePadding10 ">
          <table className="">
            <div className="profilePadding8 "></div>
            <tr className="">
              {profileStatus && profileStatus != null
                ? Object.keys(profileStatus).map((el) => {
                  return (
                    <StatusList
                      key={Math.random()}
                      element={profileStatus[el]}
                    />
                  );
                })
                : ""}
            </tr>
          </table>
        </div>
        <table className="flex foot_term_condition">
          <tr>
            <td className="chckbox" onClick={() => setChkValue(!chkValue)}>
              <CheckBox width={100} checked={chkValue} />
            </td>
            <td className="paddingleft" onClick={() => setChkValue(!chkValue)}>
              <div className="signup_sub terms_and_coditionpro terms_and_codition_color profilePadding20">
                {t("profileCreation.ihave")}
              </div>

              <div className="signup_sub_head terms_and_coditionpro flex ">
                <div className="signup_termsColor">{t("signIn.terms_con")}</div>
                <div className="terms_and_codition_gap">{t("signIn.and")} </div>
                <div className="signup_termsColor">{t("signIn.privacy")}</div>
              </div>
            </td>
          </tr>
        </table>
        <div className="profilePadding20"></div>
        <Button
          disabled={!chkValue}
          buttonLabel={t("profileCreation.start")}
          onClick={saveTermsAndConditionToServer}
        />
      </div>
      <Footer />
    </div>
  );
};
