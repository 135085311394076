import { AddOn } from "./AddOn";
import { OrderSummary } from "./OrderSummary";
import { HasBasement } from "./hasBasement";
import { OpeningHeight } from "./openingHeight";
import { OpeningSize } from "./openingSize";
import { OpeningType } from "./openingType";
import { PersonalInfo } from "./personalInfo";
import { WallType } from "./wallType";

export const wallOpeningRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/wall_opening/size",
    component: OpeningSize,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/wall_opening/height/:stepValue",
    component: OpeningHeight,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/wall_opening/type/:stepValue",
    component: WallType,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/wall_opening/has-basement/:stepValue",
    component: HasBasement,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/wall_opening/type-of-opening/:stepValue",
    component: OpeningType,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/wall_opening/addons/:stepValue",
    component: AddOn,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/wall_opening/personal-info/:stepValue",
    component: PersonalInfo,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/wall_opening/order-summary/:stepValue",
    component: OrderSummary,
  },
];
