import IMAGES from '../../_assets/images/index'


const items = [
  { 
  urls: IMAGES.home.Carpet1,
  },
  { 
   urls: IMAGES.home.Carpet1,
   },
  
];
export default items