import React from "react";
import { useTranslation } from "react-i18next";

const EpoxyWhatIsIncluded = () => {
  const { t } = useTranslation();

  return (
    <div>
      <label>
        <strong>{t("floor.whatsIncludedInPackage")}</strong>
      </label>

      <ul>
        <li>Customer support specialist</li>
        <li>Epoxy application</li>
        <li>Trash and debris removal from job site</li>
        <li>
          1 year comprehensive warranty covering workmanship and materials*
        </li>
        <li>Materials free delivery*</li>
      </ul>
      <div className="profilePadding10"></div>
      <label>
        <strong>{t("floor.howLongTheProcessTakes")}</strong>
      </label>
      <ul>
        <li> Between 1 to 2 days</li>
      </ul>
      <div className="profilePadding40"></div>
      <span>*Only if acquired through Anyhnb</span>
    </div>
  );
};

export default EpoxyWhatIsIncluded;
