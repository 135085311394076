import { Kitchenaddons } from "./addOns";
import { KitchenCabinets } from "./kitchenCabinets";
import { KitchenSize } from "./kitchenSize";
import { KitchenRemodel } from "./remodellingDetails";
import { KitchenRequirement } from "./requirement";
import { Kitchensummary } from "./summary";
import { Kitchentypeofwork } from "./typeofService";

export const kitchenRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/kitchen/type-of-work/:stepValue",
    component: Kitchentypeofwork,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/kitchen/requirement/:stepValue",
    component: KitchenRequirement,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/kitchen/cabinets/:stepValue",
    component: KitchenCabinets,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/kitchen/size/:stepValue",
    component: KitchenSize,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/kitchen/remodeling/:stepValue",
    component: KitchenRemodel,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/kitchen/addons/:stepValue",
    component: Kitchenaddons,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/kitchen/summary/:stepValue",
    component: Kitchensummary,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/kitchen/",
    component: Kitchentypeofwork,
  },
];
