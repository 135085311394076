import React from "react";
import AddServiceLocationsForm from "../../homeComponents/headerSection/AddServiceLocationsForm";
import "../../../_styles/localStyles/home.scss";
import { useHistory } from "react-router-dom";
import IMAGES from "../../../_assets/images";
import { parseJwt } from "../../../services/utils/utilFunctions";

const AddServiceLocations = () => {
  const history = useHistory();
  const user = parseJwt(localStorage.getItem("auth_token"));
  if (!user && user?.userType === "contractor") {
    history.replace("/");
  }

  return (
    <>
      <div
        className="border-bottom border-info d-flex align-items-center px-2 px-md-5 "
        style={{ height: "90px", cursor: "pointer" }}
      >
        <div className="profile-head w-100">
          <img
            onClick={() => history.goBack()}
            className="close-icon"
            src={IMAGES.home.backIcon}
          />
        </div>
      </div>
      <div className="d-flex min-h-100vh align-items-center justify-content-center">
        <div
          className="verify-mobile-modal mt-5 px-3 px-md-3"
          style={{ maxWidth: "550px", margin: "auto", width: "95%" }}
        >
          <AddServiceLocationsForm />
        </div>
      </div>
    </>
  );
};

export default AddServiceLocations;
