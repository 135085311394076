import React from "react";
import IMAGES from "../../../_assets/images/index";
import { useTranslation } from "react-i18next";
import { getMobileAppLink } from "../../../services/api/home.service";


function Header(props) {
  const { t } = useTranslation();


  const getAppLink = (store) => {
    getMobileAppLink(store).then((res) => {
      if (res.data) {
        window.open(
          res.data,
          '_blank'
        );
      }
    })
  }
  return (

    <div
      style={{
        backgroundImage: `url(${IMAGES.home.hdContractor})`
        //,width:"50%"
        //  width:"110%"
      }}
      className="home-banner  "
    >

      <table style={{ padding: "20px" }}>
        <tr>
          <td>
            <div className="homepage-title" style={{ fontSize: "24px", }}>
              <span>{t("homePage.bannerContent")}</span>
              <span style={{ fontWeight: "normal" }}>{t("homePage.bannerText")}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <a href="#quartID" className="btn-primary quartid-btn">Get an instant quote</a>
          </td>
        </tr>
        <tr>
          <label className="mbl_cls" style={{ marginTop: "30px", display: "block" }}>
            <span style={{ marginTop: "10px", fontWeight: "normal" }}>Also coming soon in:</span>
          </label>
          <div className="banner-icon home-bnr-sec flex">
            <div onClick={() => getAppLink('appstore')}><img src={IMAGES.home.appStore} alt="apple store" /></div>
            <div onClick={() => getAppLink('playstore')}><img src={IMAGES.home.playStore} alt="google play" /></div>
          </div>
        </tr>

      </table></div>
  );
}

export default Header;
