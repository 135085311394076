import React from "react";
import "./Button.css";

export const Button = (props) => {
  const { buttonLabel, disabled, onClick, className } = props;
  return (
    <button
      {...props}
      onClick={onClick}
      disabled={disabled}
      className={className || "btn-primary"}
    >
      {buttonLabel}
    </button>
  );
};
