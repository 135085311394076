import React, { useLayoutEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import "../../_styles/globalStyles/global.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { basementStore, checkoutStore, hvacStore } from "../../_redux/actions";
import { basementModel } from "../../_redux/Models/basement.model";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const HvacOrderSummary = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 9;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [orderSummary, setOrderSummary] = useState([]);
  const [comment, setComment] = useState("");

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const payload = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.hvacPayload
      ? state.buttonReducer.hvacPayload
      : basementModel
  );
  const price = calculateTotalCost(payload.costFor); // To keep the total price in state

  /**
   * On save Comment
   */
  const saveComment = () => {
    payload.comments = comment;
    dispatch(hvacStore(payload));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    window.location = `/contractor-portfolio/${selectedContractorId}/confirm`;
  };

  useLayoutEffect(() => {
    if (payload && payload !== undefined) {
      const summary = [
        {
          heading: "Type of service",
          text:
            payload.serviceType === "replacement"
              ? "Replacement/Installation"
              : payload.serviceType === "send_someone"
              ? "Send someone to do a diagnose"
              : "Repair",
          img: IMAGES.other.green_plus,
          price:
            payload.serviceType === "send_someone"
              ? payload.costFor.service
              : 0,
        },
        {
          heading: "Replacement or new install?",
          text: `${payload.existingOrNew.replaceAll("-", " ")}`,
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading: "Requires HVAC system?",
          text: `${payload.typeOfInstallation.replaceAll("-", " ")}`,
          img: IMAGES.other.green_plus,
          price: 0,
        },

        {
          heading: "HVAC Tons",
          text: `${payload.existingSystemSize}`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.service,
        },
        {
          heading: "HVAC Unit brand",
          text: `${payload.brandNeeded}`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.material,
        },
        {
          heading: "Conditions accepted",
          text: `${payload.agreementAccepted}`,
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading:
            payload.serviceType === "repair" ? "Needs replacement" : "Add on´s",
          text: payload.addOns.map((addOn) => {
            const add = addOn.text;
            if (add !== "") {
              return (
                <span>
                  {`${add.replaceAll("_", " ")} (${addOn.count})`}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
          img: IMAGES.other.green_plus,
          price: payload.costFor.addOns,
        },
      ];

      setOrderSummary(summary);
    }
  }, [payload, t]);

  return (
    <div className="service insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="sevice_title">{t("orderSummary.mainHeading")} </div>
        <label className="sub_heading mb-3" style={{ marginTop: "7px" }}>
          {payload.serviceType === "repair"
            ? "HVAC repair estimate"
            : "HVAC installation estimate "}
        </label>
        {orderSummary.map((item, index) =>
          item.text.length > 0 ? (
            <div key={index}>
              <label
                className="floor-selection-radio"
                style={{
                  // height: "88px",
                  flexDirection: "column",
                  padding: "15px",
                  alignItems: "start",
                  marginBottom: "15px",
                }}
              >
                <div className="bold">{item.heading}</div>
                <div
                  className="flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="text_width"
                    style={{
                      textTransform: "initial",
                      width: "100%",
                      paddingRight: "25px",
                      textAlign: "left",
                    }}
                  >
                    {item.text}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      placeItems: "center",
                      width: "155px",
                    }}
                  >
                    <div style={{ paddingTop: "3px" }}>
                      <img
                        width="15"
                        height="15"
                        src={IMAGES.other.green_plus}
                        alt=""
                      />
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      {`$${formatPrice(parseFloat(item.price))}`}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          ) : null
        )}
        <table className="mb-3">
          <tr>
            <td>
              <label className="summaryDivheader1">
                {t("paintingInterior.ordercommands")}
              </label>
              <div className="commandspadding"></div>
              <textarea
                type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("paintingInterior.typemessage")}
                className="paintsummaryCommands message plcehlder_align"
              />
            </td>
          </tr>
        </table>

        <label>
          <strong>{t("floor.whatsIncludedInPackage")}</strong>
        </label>
        <br />

        {payload.serviceType !== "send_someone" ? (
          <ul>
            <li>Removal of old condenser and gas furnace</li>
            <li>Installation by licensed technicians</li>
            <li>New condenser with matching evaporator of your choice</li>
            <li>Install new evaporator coil </li>
            <li>Single stage gas furnace</li>
            <li>Furnace materials</li>
            <li>AC coil materials</li>
            <li>20 years heat exchanger warranty</li>
            <li>10 years compressor warranty</li>
            <li>10 years evaporator warranty</li>
            <li>10 years labor warranty</li>
            <li>10 years parts warranty</li>
            <li>Trash and debris removal from job site</li>
            <li>1 year Comprehensive Warranty covering workmanship</li>
            <li>Customer support specialist</li>
          </ul>
        ) : (
          <>
            <div className="profilePadding10"></div>
            <span>It includes $300 of credit to do the repair with Anyhnb</span>
          </>
        )}
        <Button
          disabled={false}
          buttonLabel={t("orderSummary.continue")}
          onClick={saveComment}
        />
        <div className="contactblue_btn mb-5 mt-4">
          <div>
            <img src={IMAGES.other.group1} alt="" />
          </div>
          <div>
            <table>
              <tr>
                <td>
                  <label className="condactNeedhelp">
                    {t("paintingInterior.doneedhelp")}
                  </label>
                </td>
              </tr>
              <tr>
                <td onClick={() => (window.location = "/contact-us")}>
                  {" "}
                  <label className="contactuslink">
                    {t("paintingInterior.contactus")}
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="paintPolygon mb-3">
          <img src={IMAGES.other.polygon} alt="" />
        </div>
      </div>
    </div>
  );
};
