import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { checkoutStore, kitchenStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { kitchenModel } from "../../_redux/Models/Kitchen.model";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
export const KitchenRequirement = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [needMaterial, setNeedMaterial] = useState("");
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const kitchenModelState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.kitchenPayload
      ? state.buttonReducer.kitchenPayload
      : kitchenModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [price, setPrice] = useState(
    calculateTotalCost(kitchenModelState.costFor)
  ); // To keep the total price in state

  const [value, setValue] = useState({ value: "" });
  const [demolitionOptions, setDemolitionOptions] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails) {
      const optionsArr = [
        {
          text: t("kitchen.needdemon"),
          value: "Yes",
          price: priceDetails.demolition.price,
        },
        {
          text: t("kitchen.dontneeddemon"),
          value: "No",
          price: 0,
        },
        {
          text: t("kitchen.notsure"),
          value: "Not sure",
          price: 0,
        },
      ];
      setDemolitionOptions(optionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, kitchenModelState.costFor, price, t]);

  const onSaveMaterial = () => {
    kitchenModelState.demolitionRequired = value;
    kitchenModelState.needMaterial = needMaterial;

    kitchenModelState.costFor.demolition =
      price - calculateTotalCost(kitchenModelState.costFor);
    dispatch(kitchenStore(kitchenModelState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    if (needMaterial === "Yes") {
      history.push(
        `/contractor-portfolio/${selectedContractorId}/kitchen/cabinets/${4}`
      );
    } else {
      history.push(
        `/contractor-portfolio/${selectedContractorId}/kitchen/remodeling/${4}`
      );
    }
  };

  const ownMaterials = [
    {
      text: t("kitchen.providematerial"),
      value: "Yes",
      price: 0,
    },
    {
      text: t("kitchen.ownmeterial"),
      value: "No",
      price: 0,
    },
  ];

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left mb-3">
          <label className="paint_header">{t("kitchen.demol")}</label>
        </div>

        {demolitionOptions.map((item, index) => (
          <div
            className={`floor-selection-radio ${
              value?.value === item?.value ? "selected" : ""
            }`}
            onClick={() => {
              const cost = parseFloat(
                calculateTotalCost(kitchenModelState.costFor)
              );
              setValue(item);
              setPrice(cost + item.price);
            }}
          >
            <div
              key={index}
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label
                className="paintdiv_txt pc_txt"
                style={{
                  width: "175px",
                }}
              >
                {item.text}{" "}
              </label>
              <div
                className="paint_arrowFloat"
                style={{
                  width: "130px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img src={IMAGES.other.green_plus} alt="" />
                  <label className="paintdiv_txt">
                    {`$${formatPrice(item.price)}`}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="paint_flex_with_left kitgrid my-4">
          <label className="paint_header flex">
            {t("kitchen.material")}
            <div className="kitpadd20" style={{ paddingTop: "5px" }}>
              {/* <img src={IMAGES.other.exclVector} alt="" /> */}
            </div>
          </label>
          {/* <label className="paint_header ">{t("kitchen.kitchenrem")}</label> */}
        </div>

        {ownMaterials.map((item, index) => (
          <div
            className={`floor-selection-radio ${
              needMaterial === item?.value ? "selected" : ""
            }`}
            onClick={() => setNeedMaterial(item.value)}
          >
            <div key={index} className="width100">
              <label className="paintdiv_txt"> {item.text} </label>
            </div>
          </div>
        ))}
        <Button
          disabled={value.value === "" || needMaterial === ""}
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => onSaveMaterial()}
        />
      </div>
    </div>
  );
};
