import React, { useState, useEffect } from "react";
import IMAGES from "../../../_assets/images/index";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

import "../../../_styles/localStyles/drawer.css";
import Loader from "../../../_components/spinner/Loader";
import { parseJwt } from "../../../services/utils/utilFunctions";
import { signupModel } from "../../../_redux/Models/auth.models";
import { continuetoNext } from "../../../_redux/actions";
import { useDispatch } from "react-redux";
// import { ChatService } from "../../../services/chat/chat.service";
import {
  getData,
  setData,
} from "../../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../../_configs/constants.config";
import { FireBase } from "../../../services/firebase/firebase.service";
import { getAllNotifications } from "../../../services/api/notifications.api.services";
import Toggle from "../../../_components/Toggle/Toggle";
import {
  GroupChannelHandler,
  GroupChannelModule,
} from "@sendbird/chat/groupChannel";
import SendbirdChat from "@sendbird/chat";
let initialChatCount = "not-set";
export const NavBar = () => {
  const [load, setLoad] = useState(false);
  const [chatDot, setChatDot] = useState(0);
  const [notificationDot, setNotificationDot] = useState(0);
  const userDetails = getData(LOCAL_STORAGE.user);
  const [firebase, setFireBase] = useState(null);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [isContractor, toggleProfile] = useState(
    userDetails?.userType === "contractor"
  );

  // const chatService = new ChatService();
  const { t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    handleBackClick();
    const firebase = new FireBase();
    setFireBase(firebase);
    const userData = getData(LOCAL_STORAGE.user);
    if (userData?._id) {
      setDot();
      setNotificaitonDots();
      setupUser();
    }

    const broadcast = new BroadcastChannel("count-channel");
    if (broadcast) {
      broadcast.onmessage = (event) => {
        if (
          event.data.type === "BACKGROUND_MESSAGE" ||
          event.data.type === "NEW_MESSAGE"
        ) {
          setDot(1);
        } else if (event.data.type === "UPDATE_DOT") {
          setNotificaitonDots(1);
        } else if (event.data.type === "NAVIGATE") {
          if (event.data.payload.data["routeParam"])
            history.push(event.data.payload.data["routeParam"]);
        }
      };
    }
    return () => {
      window.removeEventListener("popstate", handleBackClick);
    };
  }, []);

  /**
   * Handles the back click and show alert if the user is in order flow
   */
  const handleBackClick = () => {
    let mounted = true;
    if (mounted) {
    }

    return () => (mounted = false);
  };

  const setupUser = async () => {
    //   const SENDBIRD_INFO = process.env.REACT_APP_SENDBIRD_APP_ID;
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user._id) {
      let userIdInputValue = user._id;

      const sendbirdChat = await SendbirdChat.init({
        appId: process.env.REACT_APP_SENDBIRD_APP_ID,
        localCacheEnabled: false,
        modules: [new GroupChannelModule()],
      });

      await sendbirdChat.connect(userIdInputValue);
      const chatCount =
        await sendbirdChat.groupChannel.getTotalUnreadMessageCount();
      initialChatCount = chatCount;
    }
  };

  const setDot = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      let userNameInputValue = user.name;
      let userIdInputValue = user._id;

      const sendbirdChat = await SendbirdChat.init({
        appId: process.env.REACT_APP_SENDBIRD_APP_ID,
        localCacheEnabled: false,
        modules: [new GroupChannelModule()],
      });

      await sendbirdChat.connect(userIdInputValue);
      const chatCount =
        await sendbirdChat.groupChannel.getTotalUnreadMessageCount();
      setTimeout(() => {
        setDot();
      }, 60000);
      if (chatCount > initialChatCount) {
        setChatDot(chatCount);
      }
    }
  };

  const setNotificaitonDots = async () => {
    const notifications = await getAllNotifications();
    setNotificationDot(notifications.data.length);
  };

  const signUp = () => {
    history.push("/role");
  };

  const navBarHandler = async (item) => {
    if (item.text === "Home") {
      history.push("/");
    } else if (item.path === "/toggle") {
      handleProfileToggle();
    } else if (item.path === "/wallet") {
      history.push("/wallet");
    } else if (item.text === "Sign out") {
      setLoad(true);
      // chatService.removeConversationClient();
      if (firebase) firebase.deleteToken();
      localStorage.clear("user");
      setLoad(false);
      setChatDot(0);
      setNotificationDot(0);
      const element = document.getElementById("toggle");
      element.checked = false;
      window.location = "/";
    } else if (item.path === "/signup") {
      signupModel.signuserType = "contractor";
      dispatch(continuetoNext(signupModel));
      history.push("/signup");
    } else if (item.text === t("homeDrawer.profile")) {
      const user = parseJwt(localStorage.getItem("auth_token"));
      if (user && user.userType === "contractor") {
        history.push(`/contractor/profile`);
      } else if (user && user.userType === "owner") {
        history.push(`/owner/my-profile`);
      } else {
        history.push("/role");
      }
    } else {
      history.push(item.path);
    }
  };

  const drawer = [
    {
      text: t("homeDrawer.profile"),
      path: "",
      index: 1,
    },

    {
      text: t("homeDrawer.terms"),
      path: "/terms-and-condition",
      index: 8,
    },
    {
      text: t("homeDrawer.contact"),
      path: "/contact-us",
      index: 7,
    },
  ];

  if (userDetails !== null) {
    drawer.push(
      {
        text: t("homeDrawer.notifications"),
        path: "/user/notifications",
        index: 3,
      },
      {
        text: t("homeDrawer.chat"),
        path: "/user/chat",
        index: 4,
      },
      {
        text: t("homeDrawer.signout"),
        index: 10,
      }
    );
  }
  if (userDetails !== null && userDetails.userType === "owner") {
    drawer.push(
      {
        text: t("homeDrawer.home"),
        path: "/",
        index: 0,
      },
      {
        text: t("homeDrawer.project"),
        path: "/user/my-projects",
        index: 5,
      },
      {
        text: t("homeDrawer.contractor"),
        path: "/signup",
        index: 6,
      }
    );
  }
  if (userDetails !== null && userDetails.originalStatus === "contractor") {
    drawer.push({
      text: `${isContractor ? "Change to owner" : "Change to contractor"}`,
      path: "/toggle",
      index: 2,
    });
  }
  if (userDetails !== null && userDetails.userType === "contractor") {
    drawer.push({
      text: t("homeDrawer.project"),
      path: "/contractor-projects",
      index: 5,
    });
  }

  const handleProfileToggle = () => {
    let _userDetails = getData(LOCAL_STORAGE.user);
    let _isContractor = isContractor;
    if (_userDetails.userType === "contractor") {
      _userDetails.userType = "owner";
      _isContractor = false;
    } else {
      _userDetails.userType = "contractor";
      _isContractor = true;
    }
    setData("user", _userDetails);
    toggleProfile(_isContractor);
  };

  const navigateToHome = () => {
    if (userDetails && userDetails.userType === "contractor") {
      history.push("/contractor-projects");
    } else {
      history.push("/");
    }
  };

  return (
    <>
      <Loader progress={load} />
      <div className="headerMain">
        <table style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          <tr>
            <td style={{ minWidth: "105px" }} onClick={navigateToHome}>
              <label
                className="headerTitle "
                style={{ position: "relative", paddingLeft: "40px" }}
              >
                <img
                  src={IMAGES.home.logoHome}
                  height="25"
                  width="25"
                  alt=""
                  className="homeLogo"
                  style={{ position: "absolute", left: "0", top: "0px" }}
                />
                <img
                  src={IMAGES.home.logoInner}
                  height="40"
                  width="40"
                  alt=""
                  className="innerLogo"
                  style={{
                    position: "absolute",
                    left: "0",
                    height: "25px",
                    width: "25px",
                  }}
                />
                <span style={{ marginLeft: "-10px" }}>
                  {t("homePage.heading")}
                </span>
              </label>
            </td>

            <td
              style={
                {
                  // float: "right"
                }
              }
            >
              <div className="header-menu">
                <div className="header_menus header_menusHide">
                  <label>{t("homePage.Treat_Your_home")}</label>
                  <label style={{ marginLeft: "25px" }}>
                    {t("homePage.Our_Projects")}{" "}
                  </label>
                  <label style={{ marginLeft: "25px" }}>
                    {t("homePage.Contact_Us")}
                  </label>
                </div>
                <img
                  className="app-img"
                  src={IMAGES.home.user}
                  height="25"
                  width="25"
                  style={{ paddingLeft: "10px" }}
                  alt=""
                />
                <img
                  className="pc-img"
                  src={IMAGES.home.userBlue}
                  height="25"
                  width="25"
                  style={{ paddingLeft: "10px" }}
                  alt=""
                />
                <div
                  onClick={
                    userDetails === null
                      ? () => signUp()
                      : () => {
                          return;
                        }
                  }
                >
                  <label
                    className="signup_text"
                    style={{
                      marginLeft: "10px",
                      display: "block",
                      lineHeight: "27px",
                    }}
                  >
                    {" "}
                    {userDetails === null
                      ? t("homePage.Sign_Up")
                      : userDetails.name}
                  </label>
                </div>
                <div className="mobile_menu overHamburger">
                  <input id="toggle" type="checkbox"></input>
                  <label
                    htmlFor="toggle"
                    className="hamburger"
                    style={{ display: "block", height: "25px" }}
                  >
                    <img
                      className="mL10 app-img"
                      src={IMAGES.home.menu}
                      height="25"
                      width="25"
                      alt=""
                      style={{
                        cursor: "pointer",
                        // position: "absolute",
                        //filter: "invert(40%) sepia(45%) saturate(977%) hue-rotate(152deg) brightness(91%) contrast(100%)",
                      }}
                      // onClick={mobileMenuClick}
                    />
                    <img
                      className="mL10 pc-img"
                      src={IMAGES.home.menu}
                      height="25"
                      width="25"
                      alt=""
                      style={{
                        cursor: "pointer",
                        filter:
                          "invert(40%) sepia(45%) saturate(977%) hue-rotate(152deg) brightness(91%) contrast(100%)",
                      }}
                      // onClick={mobileMenuClick}
                    />
                  </label>

                  <div className="nav">
                    <div className="nav-wrapper">
                      <nav>
                        <div className="home-sectionpadd20 ">
                          <table
                            style={{ width: "100%", placeItems: "center" }}
                          >
                            <tr>
                              <td>
                                <label
                                  className="signup_page_anyhnb_logo"
                                  //   onClick={() => window.location.replace("/")}
                                >
                                  {t("signUp.mainHeading")}
                                </label>
                              </td>
                              <td
                                style={{ float: "right", paddingTop: "15px" }}
                              >
                                <label htmlFor="toggle" className="hamburger">
                                  <img
                                    id="cl"
                                    src={IMAGES.other.closeVector}
                                    alt=""
                                    width="30px"
                                    height="30px"
                                    style={{ cursor: "pointer" }}
                                    //   onClick={() => backClick()}
                                    // onClick={mobilecloseClick}
                                  />
                                </label>
                              </td>
                            </tr>
                          </table>
                          {/* home nav  */}
                          <div className="home-sectionpadd20">
                            {drawer
                              .sort((a, b) => (b.index < a.index ? 1 : -1))
                              .map((item, index) => (
                                <div
                                  key={index}
                                  className="dotPosition"
                                  onClick={() => navBarHandler(item)}
                                >
                                  <div
                                    className="homeDrawertxt"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <label
                                      htmlFor="toggle"
                                      className="hamburger"
                                    >
                                      {item.text}
                                    </label>
                                    {item.path === "/toggle" && (
                                      <Toggle
                                        props={{ isChecked: isContractor }}
                                      />
                                    )}
                                    {item.path === "/user/chat" &&
                                      chatDot > 0 && (
                                        <div className="dot overLink"></div>
                                      )}
                                    {item.path === "/user/notifications" &&
                                      notificationDot > 0 && (
                                        <div className="dot overLink"></div>
                                      )}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                  {chatDot + notificationDot > 0 ? (
                    <div className="dot overHamburger"></div>
                  ) : null}
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
