import React, { useRef, useState, useEffect } from "react";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import IMAGES from "../../_assets/images";
import Calendar from "react-calendar";
import "../../_styles/localStyles/calendar.css";
import "../../_styles/localStyles/floor.css";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { formatPrice, parseJwt } from "../../services/utils/utilFunctions";
import moment from "moment";
import { updateProjectById } from "../../services/api/owner.service";
import Loader from "../../_components/spinner/Loader";

function EditStartDate(props) {
  const { t } = useTranslation();
  const items = [
    {
      text: "Early morning",
      time: "7am - 9am",
    },
    {
      text: "Late morning",
      time: "9am - 11am",
    },
    {
      text: "Noon",
      time: "12pm - 3pm",
    },
    {
      text: "Afternoon",
      time: "3pm - 6pm",
    },
  ];

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 6;
  const dateEl = useRef();
  const dispatch = useDispatch();
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  console.log(checkoutModelState);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment(checkoutModelState.projectStartDate).toDate()
  );
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [convertedDate, setConvertedDate] = useState(
    moment(checkoutModelState.projectStartDate).format("MM/DD/YYYY")
  );
  const [calendarOpen, setCalendarOpen] = useState(false);

  const selectDate = (val) => {
    console.log(val);

    setSelectedDate(val);
    convert(val);
    setCalendarOpen(false);
  };
  const onDatePickerClick = (val) => {
    setCalendarOpen(true);
    // dateEl.current.fo
  };
  function convert(str) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    setConvertedDate([month, day, date.getFullYear()].join("/"));
  }

  const downArrowClick = (val) => {
    setOpen(true);
    // props.onSuccess();
  };

  const saveDate = () => {
    const projectId =
      props.match && props.match.params ? props.match.params.projectId : null;
    const user = parseJwt(localStorage.getItem("auth_token"));
    checkoutModelState.projectStartDate = selectedDate;
    checkoutModelState.projectStartTime = selectedTime;
    dispatch(checkoutStore(checkoutModelState));
    if (user && user._id && projectId) {
      updateProjectById(user._id, projectId, {
        tentativeStartDate: selectedDate,
        tentativeTimeRange: selectedTime,
      })
        .catch((e) => alert(e.toString()))
        .finally((_) => props.history.goBack());
    } else {
      props.history.goBack();
    }
  };
  return (
    <div>
      <HeaderMenu />
      <Loader progress={this.state.isLoading} />
      <div className="paint-home-section">
        <div className="paint_main_buttons">
          <div className="paint_main_button_left">
            <ProgressBar progressValue={10} />
            {/* <img src={IMAGES.home.stepnumberfive} alt="" /> */}
            <div>
              <label className="paint_every_flow_title">
                {/* {t("floor.initiate")}
                 */}
                Project initiate
              </label>
            </div>
          </div>
          <div className="paint_main_button_right">
            <table>
              <tr>
                <td>
                  <img src={IMAGES.other.piggy} alt="" />
                </td>
              </tr>
              {checkoutModelState.totalCost ? (
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <label className="paint_every_price_txt">
                      {checkoutModelState.totalCost
                        ? `$${formatPrice(checkoutModelState.totalCost)}`
                        : "--"}
                    </label>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </table>
          </div>
        </div>

        <div className="m-t-32">
          <div className="floor-sub-title">Start date</div>
          <div
            className="m-t-32"
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            Start date
          </div>

          <TextField
            // ref={dateEl}
            style={{ fontStyle: "italic", marginTop: "8px" }}
            variant="standard"
            placeholder="mm/dd/yyyy"
            fullWidth
            type="text"
            value={convertedDate}
            onClick={onDatePickerClick}
            InputProps={{
              style: { padding: "16px" },
              shrink: true,
              startAdornment: (
                <InputAdornment position="start">
                  <img src={IMAGES.home.calendar} alt="" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <img
                    src={IMAGES.home.downArrow}
                    alt=""
                    onClick={downArrowClick}
                  />
                </InputAdornment>
              ),
            }}
          />
          {calendarOpen ? (
            <div
              className="m-t-32"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Calendar
                // ref={dateEl}
                open={open}
                keepmounted
                onChange={(val) => selectDate(val)}
                value={selectedDate}
              />
            </div>
          ) : null}

          <div className="m-t-32">
            {items.map((item, index) => (
              <label
                key={index}
                className="floor-selection-radio"
                onClick={() => setSelectedTime(item.time)}
              >
                <img
                  className="sun-image"
                  src={index < 1 ? IMAGES.home.sun : index < 2 ? IMAGES.home.sun2 : index < 3 ? IMAGES.home.earlyNoon : IMAGES.home.lateNoon}
                  alt=""
                />
                <div className="wish-text">
                  <span>{item.text}</span>
                  <span className="wish-time">{item.time}</span>
                </div>
                <input
                  type="radio"
                  name="selectedWood"
                  value={item.text}
                  //   onChange={onChangeRadio}
                />
              </label>
            ))}
          </div>
          <div className="profilePadding20"></div>
          <Button
            disabled={false}
            buttonLabel={t("paintingHomes.continue")}
            onClick={saveDate}
          />
        </div>
      </div>
    </div>
  );
}

export default EditStartDate;
