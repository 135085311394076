import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Compressor from "compressorjs";
import { useSnackbar } from "notistack";

import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import upArrow from "./upArrow.svg";
import plus from "./plus.svg";

import "./assistantProject.css";
import { Footer } from "../../../_components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { updateAssistantDetails } from "../../../_redux/actions";
import { addAssistant } from "../../../services/api/contractor.service";
import Loader from "../../../_components/spinner/Loader";
import { updateContractorProject } from "../../../_redux/actions/contractor.action";

const AssistantProjects = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [assistant, setAssistantDetails] = useState({
    name: "",
    file: "",
  });

  const projectDetails = useSelector(
    (state) => state.contractor.projectDetails
  );

  const [isLoading, setIsLoading] = useState(false);

  const { projectId } = history.location.state;

  const changeHandler = (e) => {
    setAssistantDetails({
      ...assistant,
      [e.target.name]: e.target.value,
    });
  };

  const addNewAssistant = (path) => {
    uploadFilesToServer(assistant.file, "assistantDoc", path, resetForm);
  };

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const uploadFilesToServer = (file, field, path, callback) => {
    const postData = new FormData();
    const user = JSON.parse(localStorage.getItem("user"));
    if (file && field) {
      setIsLoading(true);
      postData.append("name", assistant.name);
      postData.append(field, file);
      addAssistant(user._id, projectId, postData)
        .then((data) => {
          if (data.status !== 200) {
            const variant = "error";
            enqueueSnackbar("Upload failed please try again", {
              variant,
            });
          } else if (callback) {
            callback(path, data);
          }
        })
        .catch((e) => {
          const variant = "error";
          enqueueSnackbar(e.toString(), { variant });
        })
        .finally((_) => {
          setIsLoading(false);
        });
    }
  };

  const resetForm = (path, data) => {
    setAssistantDetails({ name: "" });
    if (path) {
      dispatch(
        updateContractorProject({
          ...projectDetails,
          assistants: data.data.assistants,
        })
      );
      history.push(path);
    }
  };

  const handleFileUploadFor = (file) => {
    new Compressor(file, {
      quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        fileToDataUri(compressedResult).then((dataUri) => {
          fetch(dataUri)
            .then((res) => res.blob())
            .then((res) => {
              setAssistantDetails({
                ...assistant,
                file: res,
              });
            });
        });
      },
    });
  };

  return (
    <div className="assistant-projects insideWrp">
      <Loader progress={isLoading} />
      <HeaderMenu />
      <div className="paint-home-section pcWidth assistant-ctn">
        <span className="assistant-project-title">Assistants project</span>
        <div className="assistant-project-inputs">
          <label
            htmlFor="project-name"
            className="assistant-project-title project-name"
          >
            Full name*
          </label>
          <input
            type="text"
            id="project-name"
            placeholder="Enter assistant full name"
            value={assistant.name}
            name="name"
            onChange={(e) => changeHandler(e)}
          />
        </div>
        <p className="assistant-project-title assistant-id">Assistant ID</p>
        <div className="project_upload_document mobile-align-flex-centre">
          <button
            style={{ width: "95%" }}
            onClick={() =>
              document.getElementsByClassName("project-upload")[0].click()
            }
          >
            <img src={upArrow} alt="" /> Upload documents
          </button>
          <input
            type="file"
            accept="image/*"
            name="file"
            id="filefirst"
            className="project-upload"
            style={{ display: "none" }}
            onChange={(e) => handleFileUploadFor(e.target.files[0])}
          />
        </div>
        <p className="add-new-assistant" onClick={addNewAssistant}>
          <img src={plus} style={{ margin: "0 12px 0 0" }} alt="" />
          Add new assistant
        </p>
        <div className="mobile-align-flex-centre">
          <button
            className={`project-save-changes ${
              assistant.name && assistant.file
                ? "project-primary"
                : "project-primary-disable"
            }`}
            onClick={() => addNewAssistant("/contractor-project-preview")}
          >
            Save changes
          </button>
          <p
            className="project-cancel"
            onClick={() => history.push("/contractor-project-preview")}
          >
            Cancel
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AssistantProjects;
