import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/paint.css";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { Button } from "../../_components/Button/Button";
import RadioButton from "../../_components/radioButton/radioButton";
import { checkoutStore } from "../../_redux/actions";
import {
  formatPrice,
  getMaterialTaxFor,
  parseJwt,
  getFullAddress,
} from "../../services/utils/utilFunctions";
import {
  ownerRegisteredAddresses,
  createCheckoutSession,
  placeOrder,
} from "../../services/api/owner.service";
import { getContractorRegisteredAddresses } from "../../services/api/contractor.service";

class PaymentMethod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAddressId: "",
      selectedCardId: "",
      selectedServicePayload: "",
      addresses: [],
      disclaimer: false,
    };
  }

  /**
   * Collect the added addresses & cards and add them to state
   */
  componentWillMount = () => {
    this.getUserInfo();
  };
  componentDidMount = () => {
    const currentFlow = this.props.checkout.payload.selectedService;
    let servicePayload;
    switch (currentFlow) {
      case "SolidHardwood":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Engineered":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Carpet":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Tiles":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Epoxy":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Laminated":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Other":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Basement":
        servicePayload = this.props.buttonReducer.basementPayload;
        break;
      case "Wall opening":
        servicePayload = this.props.buttonReducer.wallPayload;
        break;
      case "Kitchen Remodeling":
        servicePayload = this.props.buttonReducer.kitchenPayload;
        break;
      case "deck":
        servicePayload = this.props.buttonReducer.paintingPayLoad;
        break;
      case "fence":
        servicePayload = this.props.buttonReducer.paintingPayLoad;
        break;
      case "interior":
        servicePayload = this.props.buttonReducer.paintingPayLoad;
        break;
      case "exterior":
        servicePayload = this.props.buttonReducer.paintingPayLoad;
        break;
      case "Bathroom renovation":
        servicePayload = this.props.buttonReducer.bathroomPayload;
        break;
      case "HVAC":
        servicePayload = this.props.buttonReducer.hvacPayload;
        break;
      case "Plumbing":
        servicePayload = this.props.buttonReducer.plumbingPayload;
        break;
      case "Roofing":
        servicePayload = this.props.buttonReducer.roofingPayload;
        break;
      case "Windows":
        servicePayload = this.props.buttonReducer.windowsPayload;
        break;
      case "Electric":
        servicePayload = this.props.buttonReducer.electricPayload;
        break;
      case "Tree services":
        servicePayload = this.props.buttonReducer.treePayload;
        break;
      default:
        break;
    }
    this.setState({ selectedServicePayload: servicePayload });
  };

  /**
   * User info
   */
  getUserInfo = () => {
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);
    if (userDetails.userType === "owner") {
      this.getOwnerAddresses(userDetails);
    } else if (userDetails.userType === "contractor") {
      this.getContractorAddresses(userDetails);
    }

    // this.setState({ user: userDetails }, this.getAddresses);
  };

  getOwnerAddresses = (userDetails) => {
    ownerRegisteredAddresses(userDetails._id).then((data) => {
      if (data.status === 200 && data.data && data.data?.address?.length > 0) {
        const addresses = data.data.address;
        this.setState({ addresses: addresses });
      }
    });
  };
  getContractorAddresses = (userDetails) => {
    getContractorRegisteredAddresses(userDetails._id).then((data) => {
      if (data.status === 200 && data.data && data.data?.address?.length > 0) {
        const addresses = data.data.address;
        this.setState({ addresses: addresses });
      }
    });
  };

  onChangeHandler = (event, key) => {
    let validatedState = this.validate(key, event.target.value, undefined);
    this.setState({ formFields: validatedState });
  };

  /**
   * Calls when submitting a form
   */
  onSubmitForm = (e, stripe) => {
    e.preventDefault();

    const formattedData = this.formateData();

    this.saveFormData(formattedData, stripe).then((_) => {
      // this.props.history.push(`/checkout/order-received/success`);
    });
  };

  formateData = () => {
    const currentFlow = this.props.checkout.payload.selectedService;
    let servicePayload;
    switch (currentFlow) {
      case "SolidHardwood":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Engineered":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Carpet":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Tiles":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Epoxy":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Laminated":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Other":
        servicePayload = this.props.buttonReducer.floorPayload;
        break;
      case "Basement":
        servicePayload = this.props.buttonReducer.basementPayload;
        break;
      case "Wall opening":
        servicePayload = this.props.buttonReducer.wallPayload;
        break;
      case "Kitchen Remodeling":
        servicePayload = this.props.buttonReducer.kitchenPayload;
        break;
      case "deck":
        servicePayload = this.props.buttonReducer.paintingPayLoad;
        break;
      case "fence":
        servicePayload = this.props.buttonReducer.paintingPayLoad;
        break;
      case "interior":
        servicePayload = this.props.buttonReducer.paintingPayLoad;
        break;
      case "exterior":
        servicePayload = this.props.buttonReducer.paintingPayLoad;
        break;
      case "Bathroom renovation":
        servicePayload = this.props.buttonReducer.bathroomPayload;
        break;
      case "HVAC":
        servicePayload = this.props.buttonReducer.hvacPayload;
        break;
      case "Plumbing":
        servicePayload = this.props.buttonReducer.plumbingPayload;
        break;
      case "Roofing":
        servicePayload = this.props.buttonReducer.roofingPayload;
        break;
      case "Windows":
        servicePayload = this.props.buttonReducer.windowsPayload;
        break;
      case "Electric":
        servicePayload = this.props.buttonReducer.electricPayload;
        break;
      case "Tree services":
        servicePayload = this.props.buttonReducer.treePayload;
        break;
      default:
        break;
    }
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);

    servicePayload.personalInfo.name = userDetails.name;
    servicePayload.personalInfo.email = userDetails.email;
    servicePayload.personalInfo.fullAddress = getFullAddress(
      this.state.addresses.filter(
        (address) => address._id === this.state.selectedAddressId
      )[0]
    );

    const serviceTemp = {
      id: "project-" + new Date().getTime(),
      flow: currentFlow,
      payload: servicePayload,
      projectStartDate: this.props.checkout.payload.projectStartDate,
      projectStartTime: this.props.checkout.payload.projectStartTime,
      selectedAddressId: this.state.selectedAddressId,
      selectedCardId: this.state.selectedCardId,
      selectedContractorId: this.props.checkout.payload.selectedContractorId,
    };
    const projects =
      this.props.checkout.payload && this.props.checkout.payload.projects
        ? this.props.checkout.payload.projects
        : [];
    projects.push(serviceTemp);
    const checkoutModel = this.props.checkout.payload;
    checkoutModel.projects = projects;
    checkoutModel.selectedAddressId = this.state.selectedAddressId;
    checkoutModel.selectedCardId = this.state.selectedCardId;
    this.props.checkoutStore(checkoutModel);

    return servicePayload;
  };

  saveFormData = async (servicePayload, stripe) => {
    const addresses = this.state.addresses ? this.state.addresses : [];

    const selectedAddress = addresses.find(
      (add) => add._id === this.state.selectedAddressId
    );
    servicePayload.selectedAddressId = this.state.selectedAddressId;
    const tax = getMaterialTaxFor(this.state.selectedServicePayload);
    const orderTotal = parseFloat(this.props.checkout.payload.totalCost);

    servicePayload.orderAmount = orderTotal;
    servicePayload.tax = tax;
    const totalAmount = orderTotal + tax;

    const postData = {
      number: 0,
      ownerAddress:
        selectedAddress && selectedAddress.fullAddress
          ? getFullAddress(selectedAddress)
          : "",
      contractorId:
        this.props.checkout.payload.selectedContractorId === "first-available"
          ? null
          : this.props.checkout.payload.selectedContractorId,
      tentativeStartDate: this.props.checkout.payload.projectStartDate,
      tentativeTimeRange: this.props.checkout.payload.projectStartTime,
      price: totalAmount,
      serviceDetails: {
        categoryId:
          this.props.checkout.payload.selectedServiceDetails.categoryId,
        subCategoryId: this.props.checkout.payload.selectedServiceDetails._id,
        data: servicePayload,
        address: selectedAddress,
      },
    };

    const line_items = [
      {
        quantity: 1,
        price_data: {
          currency: "usd",
          unit_amount: 9900, // need to change to orginal price
          product_data: {
            name: "Pay now",
            description:
              "Pay only $99 deposit to secure your project, the rest will be charged upon percentage of completion.",
            images: [],
          },
        },
      },
    ];

    const user = parseJwt(localStorage.getItem("auth_token"));
    if (user && user._id) {
      try {
        localStorage.setItem("orderData", JSON.stringify(postData));
        const {
          data: { sessionId },
        } = await createCheckoutSession(user._id, {
          line_items,
          customer_email: user.email,
        });
        const { error } = stripe.redirectToCheckout({ sessionId });
        if (error) console.log(error);
      } catch (error) {
        console.log("An error occured, unable to create session", error);
      }
    }
  };

  /**
   * Calls when submitting a form
   */
  onSubmitFormLater = () => {
    const servicePayload = this.formateData();
    const user = parseJwt(localStorage.getItem("auth_token"));
    const addresses = this.state.addresses ? this.state.addresses : [];

    const selectedAddress = addresses.find(
      (add) => add._id === this.state.selectedAddressId
    );
    servicePayload.selectedAddressId = this.state.selectedAddressId;
    const tax = getMaterialTaxFor(this.state.selectedServicePayload);
    const orderTotal = parseFloat(this.props.checkout.payload.totalCost);

    servicePayload.orderAmount = orderTotal;
    servicePayload.tax = tax;
    const totalAmount = orderTotal + tax;

    const postData = {
      number: 0,
      isNotReady: true,
      ownerAddress:
        selectedAddress && selectedAddress.fullAddress
          ? getFullAddress(selectedAddress)
          : "",
      contractorId:
        this.props.checkout.payload.selectedContractorId === "first-available"
          ? null
          : this.props.checkout.payload.selectedContractorId,
      tentativeStartDate: this.props.checkout.payload.projectStartDate,
      tentativeTimeRange: this.props.checkout.payload.projectStartTime,
      price: totalAmount,
      serviceDetails: {
        categoryId:
          this.props.checkout.payload.selectedServiceDetails.categoryId,
        subCategoryId: this.props.checkout.payload.selectedServiceDetails._id,
        data: servicePayload,
        address: selectedAddress,
      },
    };

    placeOrder(user._id, postData, user.userType).then(() => {
      this.props.history.push(`/checkout/order-received/later`);
    });
  };

  render = () => {
    const { t } = this.props;
    return (
      <div className="insideWrp">
        <HeaderMenu />
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className="paintPersonalinfo_div paint_flex_with_center_personal">
            <table className=" ">
              <tr>
                <td>
                  <img src={IMAGES.other.personalvector} alt="" />
                </td>
              </tr>
              <tr>
                <td>
                  <label className="checkoutRatingstxtdetails">
                    {"You are protected!"}
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="checkoutRatingstxtdetailssub">
                    {"Anyhnb will hold your funds and won't release to "}
                  </label>
                  <label className="checkoutRatingstxtdetailssub">
                    {" the contractor until your project is complete"}
                  </label>
                </td>
              </tr>
            </table>
          </div>
          <div className="otherspadding25"></div>
          <table className="checkoutTable" style={{ textAlign: "center" }}>
            <tr>
              <td width={"15%"}></td>
              <td width={"40%"} style={{ textAlign: "left" }}>
                <label className="checkoutPaytxtnormal">Total</label>
              </td>
              <td>
                <label className="checkoutPaytxtbold">{`$${formatPrice(
                  this.props.checkout.payload.totalCost
                )}`}</label>
              </td>
            </tr>
            <tr>
              <td width={"15%"}></td>
              <td width={"40%"} style={{ textAlign: "left" }}>
                <label className="checkoutPaytxtnormal">
                  Taxes<span style={{ fontSize: "12px" }}> (materials)</span>
                </label>
              </td>
              <td>
                <label className="checkoutPaytxtbold">{`$${formatPrice(
                  getMaterialTaxFor(this.state.selectedServicePayload)
                )}`}</label>
              </td>
            </tr>
            <tr>
              <td width={"15%"}></td>
              <td width={"40%"} style={{ textAlign: "left" }}>
                <label className="checkoutPaytxtnormal">Grand total</label>
              </td>
              <td>
                <label className="checkoutPaytxtbold">{`$${formatPrice(
                  parseFloat(this.props.checkout.payload.totalCost) +
                    getMaterialTaxFor(this.state.selectedServicePayload)
                )}`}</label>
              </td>
            </tr>
            <tr>
              <td width={"15%"}></td>
              <td width={"100%"} style={{ textAlign: "left" }}>
                {/* <label className="checkoutPaytxtcolor">You pay now 15%</label> */}
                <label className="checkoutPaytxtcolor">You pay now</label>
              </td>
              <td>
                {/* <label className="checkoutPaytxtcolor">{`$${formatPrice(
                  parseFloat(
                    (parseFloat(this.props.checkout.payload.totalCost) +
                      getMaterialTaxFor(this.state.selectedServicePayload)) *
                    0.15
                  )
                )}`}</label> */}
                <label className="checkoutPaytxtcolor">$99.00</label>
              </td>
            </tr>
          </table>
          <div style={{ textAlign: "center", marginTop: "25px" }}>
            <label>
              {
                "Pay only $99 deposit to secure your project, the rest will be charged upon percentage of completion."
              }
            </label>
          </div>
          <div className="paint_flex_with_left profilePadding40">
            <label className="paint_header">
              {"Select the project’s address"}
            </label>
          </div>
          <div className="otherspadding25"></div>
          {this.state.addresses && this.state.addresses.length > 0 ? (
            <div key={Math.random()}>
              {this.state.addresses.map((address) => (
                //on click radio button will activate edit btn
                <div
                  key={Math.random()}
                  onClick={() =>
                    this.setState({ selectedAddressId: address._id })
                  }
                  style={{ marginBottom: "10px" }}
                >
                  <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                    <div>
                      <img
                        src={IMAGES.other.miniHouse}
                        alt=""
                        style={{ width: "71px", height: "71px" }}
                      />
                    </div>
                    <div className="grid">
                      <label className="checkoutRatingstxtdetails">
                        {getFullAddress(address)}
                      </label>
                    </div>
                    <div className="paint_arrowFloat">
                      {" "}
                      <RadioButton
                        checked={this.state.selectedAddressId === address._id}
                        readOnly
                      />
                    </div>
                  </div>
                  {this.state.selectedAddressId === address._id ? (
                    <div className="editButton-cls">
                      <Button
                        disabled={false}
                        onClick={() =>
                          this.setState(
                            {
                              selectedAddressId:
                                this.state.addresses.filter(
                                  (address) =>
                                    address._id === this.state.selectedAddressId
                                )[0] || {},
                            },
                            () =>
                              this.props.history.push(
                                `/checkout/edit-address/${address._id}`
                              )
                          )
                        }
                        buttonLabel={"Edit address"}
                        className="btn-checkouttick"
                      />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : null}
          <Button
            disabled={false}
            onClick={() => this.props.history.push("/checkout/add-address")}
            buttonLabel={"+ Add another address"}
            className="btn-checkouttick"
          />
          <div className="otherspadding25"></div>
          {/* <div className="paint_flex_with_left profilePadding40">
            <label className="paint_header">{"Select Payment method"}</label>
          </div> */}
          {/* <div className="otherspadding25"></div>
          <div className="otherspadding25"></div> */}
          {/* <div className="">
            <div>
              <table className="paint_table100">
                {this.props.checkout &&
                  this.props.checkout.payload.cards.length > 0 ? (
                  <div key={Math.random()}>
                    {this.props.checkout.payload.cards.map((card) => (
                      <tr
                        onClick={() =>
                          this.setState({ selectedCardId: card.id })
                        }
                        className="check_radio_div_blue paintdiv_radiobtn_paddingright"
                        style={{ marginBottom: "10px" }}
                      >
                        <td>
                          <img src={IMAGES.other.visa} alt="" />
                        </td>
                        <td className="grid">
                          <label className="checkoutRatingstxtdetails">
                            {card.name}
                          </label>
                          <label className="checkPaygray">{card.number}</label>
                        </td>
                        <td className="paint_arrowFloat">
                          {" "}
                          <RadioButton
                            checked={this.state.selectedCardId === card.id}
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                    <div className="otherspadding25"></div>
                  </div>
                ) : null}
              </table>
            </div>
          </div>
          <Button
            disabled={false}
            onClick={() => this.props.history.push("/checkout/add-card")}
            buttonLabel={"+ Add another card"}
            className="btn-checkouttick"
          /> */}
          <div className="profilePadding30"></div>
          <div className="justified-txt">
            <p>
              Client is responsible to remove every item from the work area
              before the contractors arrive. This means furniture, rugs,
              decorative items, fixtures, etc. Anything left inside will be
              exposed to potential damage during the project. If client fails to
              perform this, the project might have to be re-scheduled or
              cancelled for a fee.
            </p>
          </div>
          <div className="profilePadding30"></div>
          {/* <ul style={{ textAlign: "left", marginTop: '5px' }}>
            <li style={{ fontSize: '14px' }} className="justified-txt">
              {
                "This price is an estimation based on your input, we'll assess the final price on site."
              }
            </li>
          </ul> */}
          <div style={{ display: "flex", gap: "10px" }}>
            <input
              onChange={() =>
                this.setState({ disclaimer: !this.state.disclaimer })
              }
              checked={this.state.disclaimer}
              id="orderDisclaimer"
              type={"checkbox"}
            />
            <label style={{ fontSize: "14px" }} htmlFor="orderDisclaimer">
              The pricing provided is the best estimate based on the input, but
              it might be assessed upon arrival to the property.
            </label>
          </div>
          <div className="profilePadding10"></div>
          <ElementsConsumer>
            {({ stripe }) => (
              <Button
                onClick={(e) => this.onSubmitForm(e, stripe)}
                disabled={
                  this.state.selectedAddressId === "" || !this.state.disclaimer
                }
                buttonLabel={"Confirm project"}
              />
            )}
          </ElementsConsumer>
          <div className="otherspadding25"></div>
          <Button
            disabled={this.state.selectedAddressId === ""}
            onClick={this.onSubmitFormLater}
            buttonLabel={"I'm not ready to hire, contact me"}
          />
          <Button
            disabled={false}
            onClick={() => this.props.history.push("/")}
            buttonLabel={"Cancel Order"}
            className="btn-checkouttick"
          />
          <div className="otherspadding25"></div>
          <div className="otherspadding25"></div>
          <div className="contactblue_btn ">
            <div>
              <img src={IMAGES.other.group1} alt="" />
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <label className="condactNeedhelp">
                      {t("paintingInterior.doneedhelp")}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td onClick={() => (window.location = "/contact-us")}>
                    {" "}
                    <label className="contactuslink">
                      {t("paintingInterior.contactus")}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="paintPolygon">
            <img src={IMAGES.other.polygon} alt="" />
          </div>
        </div>

        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  buttonReducer: state.buttonReducer,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(PaymentMethod));
