import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const persistConfig = {
  key: "recoil-persist",
  storage: window.sessionStorage,
};
const { persistAtom } = recoilPersist(persistConfig);

export const selectedCategory = atom({
  key: "selectedCategory",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const subCategories = atom({
  key: "subCategories",
  default: [],
});
