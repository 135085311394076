import React from "react";
import "./tick.css"
export const Tick = (props) => {
    const { width, height, paddingLeft, paddingRight, padding, paddingTop, paddingBottom } = props;
    return (
        <div style={{ width: `${width}`, height: `${height}`, paddingLeft: `${paddingLeft}`, paddingRight: `${paddingRight}`, padding: `${padding}`, paddingTop: `${paddingTop}`, paddingBottom: `${paddingBottom}` }}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                {/* <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/> */}
                <polyline class="path check" fill="none" stroke="#5FCA5D" strokeWidth="11" strokeLinecap="" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>

        </div>
    );
};