import React from "react";
import IMAGES from "../../../_assets/images/index";

export const Footer = (props) => {
  return (
    <div className="MainFooter">
      <div className="flex" style={{ marginTop: "75px" }}>
        <div className="left_div">
          <div
            className="flex"
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              fontSize: "20px",
              lineHeight: "40px",
              color: "black",
            }}
          >
            <h2
              className="heading"
              style={{
                marginLeft: "130px",
                fontSize: "30px",
                marginBottom: "10px",
              }}
            >
              Anyhnb
            </h2>
            <div className="foot-list">
              <span
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  marginLeft: "20px",
                }}
              >
                Home
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  marginLeft: "20px",
                }}
              >
                Create a profile
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  marginLeft: "20px",
                }}
              >
                Become a contractor
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  marginLeft: "20px",
                }}
              >
                Terms & conditions
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  marginLeft: "20px",
                }}
              >
                Contact Us
              </span>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div
            className="copy-text"
            style={{
              marginTop: "15px",
              fontFamily: "Poppins",
              fontSize: "12px",
              position: "absolute",
              marginLeft: "71px",
              color: "#D8D8D8",
            }}
          >
            {" "}
            <span>All rights reserved - Anyhnb </span>
            <img src={IMAGES.home.atSymbol} alt="" />
          </div>
        </div>
        <div className="right_div">
          <div style={{ margin: "0 auto", marginTop: "70px" }}>
            <img width="35" src={IMAGES.home.instagram} alt="" />
            <br />
            <br />
            <img
              width="23"
              style={{ marginLeft: "5px" }}
              src={IMAGES.home.fb}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
