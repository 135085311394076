import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { basementModel } from "../../_redux/Models/basement.model";
import { useDispatch, useSelector } from "react-redux";
import { basementStore, checkoutStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const BasementSumpPump = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 5;
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [value, setValue] = useState("");

  const basementState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.basementPayload
      ? state.buttonReducer.basementPayload
      : basementModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(calculateTotalCost(basementState.costFor)); // To keep the total price in state
  const [sumpOptions, setSumpOptions] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails) {
      const sumpOptionsArr = [
        {
          text: t(`basement.yes`),
          value: "yes",
          price: priceDetails.sumpPump.price,
        },
        {
          text: t(`basement.no`),
          value: "no",
          price: 0,
        },
      ];
      setSumpOptions(sumpOptionsArr);
    }
    return () => (mounted = false);
  }, [basementState.basementSize, checkoutModelState, t]);

  /**
   * Function to save Selected value and take user to next step
   * @returns {void}
   */
  const onSaveValue = (val, prc) => {
    basementState.requireSumpPump = val;
    basementState.costFor.sumpPump =
      prc - calculateTotalCost(basementState.costFor);
    dispatch(basementStore(basementState));
    checkoutModelState.totalCost = prc;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/basement/electrical/6`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("basement.isSumpPumpRequired")}
            </label>
          </div>
          <div className="padding10"></div>
          {sumpOptions.map((item, index) => (
            <div
              className={`floor-selection-radio ${
                value == item?.value ? "selected" : ""
              }`}
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                const total = calculateTotalCost(basementState.costFor);
                setValue(item.value);
                setPrice(total + item.price);
                onSaveValue(item?.value, total + item?.price);
              }}
            >
              <div
                key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="paintdiv_txt"
                  style={{
                    width: "70%",
                  }}
                >
                  {item.text}
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    width: "30%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img src={IMAGES.other.green_plus} alt="" />
                    <label className="paintdiv_txt">
                      {`$${formatPrice(item.price)}`}
                    </label>
                  </div>{" "}
                </div>
              </div>
            </div>
          ))}
          <label className="paint_every_price_txt">
            A sump pump is needed to pump waste and water up and out of the
            house.
          </label>
        </div>
      </div>
    </div>
  );
};
