import IMAGES from '../../_assets/images/index'

const tilesSliderItems=  [
  { 
  urls: IMAGES.home.Tiles1,
  },
  { 
   urls: IMAGES.home.Tiles1,
   },
  
];
export default tilesSliderItems