import { WindowAddons } from "./AddOns";
import { HaveWindows } from "./HaveWindows";
import { NeedOfWindows } from "./NeedofWindows";
import { WindowsOrderSummary } from "./OrderSummary";
import { WindowQuantity } from "./WindowQuantity";
import { WindowTypeofService } from "./typeOfService";

export const windowsRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/windows/type-of-service",
    component: WindowTypeofService,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/windows/why-you-need",
    component: NeedOfWindows,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/windows/have-own-windows",
    component: HaveWindows,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/windows/select-type-quantity",
    component: WindowQuantity,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/windows/add-ons",
    component: WindowAddons,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/windows/order-summary",
    component: WindowsOrderSummary,
  },
];
