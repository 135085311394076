import React from "react";
import { useTranslation } from "react-i18next";
import IMAGES from "../../../_assets/images/index";
import { Button } from "./../../../_components/Button/Button";
import BearCarousel, { BearSlideItem } from "bear-react-carousel";
import sliderImages from "./../../../_components/Slider/DreamMakerImages";
import "bear-react-carousel/dist/index.css";
import { withRouter } from "react-router-dom";

function DreamMakers(props) {
  const { t } = useTranslation();
  const getSliderImageData = (sliderImages) => {
    return sliderImages.map((sliderImages, index) => {
      return {
        key: index,
        children: (
          <BearSlideItem imageUrl={sliderImages.urls}>
            <span>{sliderImages.text}</span>
          </BearSlideItem>
        ),
      };
    });
  };

  return (
    <div className="home-section1">
      <div className="tyh grid">
        <label> {t("homePage.dreamMakerSection.heading")}</label>
        <label className="tyh1">
          {" "}
          {t("homePage.dreamMakerSection.makers")}
        </label>
      </div>
      <div className="homePaddingtop15"></div>

      <div className="home-section-dreammaker">
        <div className="dreammakerSlideSection">
          <BearCarousel
            className="BearSlider"
            key={Math.random()}
            data={getSliderImageData(sliderImages)}
            isEnableLoop
            slidesPerView={2}
            spaceBetween={0}
            isEnableNavButton
            moveTime={5900}
            aspectRatio={{ widthRatio: 16, heightRatio: 9 }}
            autoPlayTime={3000}
            isEnableAutoPlay
          />
        </div>
        <div className="showOffSection">
          <div className="showoffCardsec">
            <div className="showoffCardBanner">
              <span>
                {" "}
                <img src={IMAGES.home.offBanner} alt="" />
              </span>
            </div>
            <div className="showoffCardLabel">
              <label>Looking to upgrade your home?</label>
              <Button
                buttonLabel={"Contact us"}
                onClick={() => (window.location = "/contact-us")}
              />
            </div>
            <div className="pc-btn">
              <Button
                buttonLabel={"Contact us"}
                onClick={() => (window.location = "/contact-us")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bet_style ">
        {/* {t('homePage.dreamMakerSection.Here_comes_a_commercial_bet')} */}
        <label style={{ marginBottom: "10px" }}>
          Bringing happiness to your home!
        </label>
        <label>
          <span>Best price guaranteed</span>
          <span>Top notch quality guaranteed</span>
          <span>Simplicity guaranteed</span>
        </label>
        <label className="pc_cls" style={{ marginTop: "0px" }}>
          <span style={{ marginTop: "20px", fontWeight: "bold" }}>
            Also coming soon on:
          </span>
        </label>
        <div className="pc_cls">
          <div className="banner-icon flex">
            <div>
              <img src={IMAGES.home.appStore} alt="" />
            </div>
            <div>
              <img src={IMAGES.home.playStore} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="home-section-dream">
            <img src={family} alt=''/>
            <button>
            Let´s start your project! <HiArrowNarrowRight/>
            </button>

        </div> */}
    </div>
  );
}

export default withRouter(DreamMakers);
