import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
//import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import { TextInput } from "../../_components/TextInput/TextInput";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import CheckBox from "../../_components/CheckBox/checkBox";
import { useSelector } from "react-redux";
import { windowsModel } from "../../_redux/Models/window.model";
import { windowStore } from "../../_redux/actions";
import { useDispatch } from "react-redux";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { DropDown } from "../../_components/dropDown/dropDown";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const WindowQuantity = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.windowsPayload
      ? state.buttonReducer.windowsPayload
      : windowsModel
  );

  const windowOptions = [
    {
      id: Math.random().toString().replace(".", ""),
      name: "Single hung",
      selected: false,
      price: 0,
      sort: 1,
      key: "Single hung",
      count: 1,
      width: "",
      height: "",
    },
    {
      id: Math.random().toString().replace(".", ""),
      name: "Double hung",
      selected: false,
      price: 0,
      sort: 2,
      key: "Double hung",
      count: 1,
      width: "",
      height: "",
    },
    {
      id: Math.random().toString().replace(".", ""),
      name: "Picture window",
      selected: false,
      price: 0,
      sort: 3,
      key: "Picture window",
      count: 1,
      width: "",
      height: "",
    },
    {
      id: Math.random().toString().replace(".", ""),
      name: "Circle top",
      selected: false,
      price: 0,
      sort: 4,
      key: "Circle top",
      count: 1,
      width: "",
      height: "",
    },
    {
      id: Math.random().toString().replace(".", ""),
      name: "Palladian",
      selected: false,
      price: 0,
      sort: 5,
      key: "Palladian",
      count: 1,
      width: "",
      height: "",
    },
    {
      id: Math.random().toString().replace(".", ""),
      name: "Casement",
      selected: false,
      price: 0,
      sort: 6,
      key: "Casement",
      count: 1,
      width: "",
      height: "",
    },
    {
      id: Math.random().toString().replace(".", ""),
      name: "Bay window",
      selected: false,
      price: 0,
      sort: 7,
      key: "Bay window",
      count: 1,
      width: "",
      height: "",
    },
    {
      id: Math.random().toString().replace(".", ""),
      name: "Garden window",
      selected: false,
      price: 0,
      sort: 8,
      key: "Garden window",
      count: 1,
      width: "",
      height: "",
    },
  ];

  const [options, setOptions] = useState(windowOptions);
  const price = calculateTotalCost(state.costFor);

  const onSave = () => {
    const selectedItems = options.filter((op) => op.selected === true);
    state.windowDetails = selectedItems;
    dispatch(windowStore(state));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/windows/add-ons`
    );
  };

  const addAnotherOption = (key) => {
    const item = options.find((op) => op.key === key);
    const modifiedItem = {
      ...item,
      id: Math.random().toString().replace(".", ""),
      selected: true,
      sort: item.sort + Math.random(),
      width: "",
      height: "",
      count: 1,
    };
    const optionsTemp = [...options, modifiedItem].sort((a, b) =>
      a.sort > b.sort ? 1 : -1
    );
    setOptions(optionsTemp);
  };

  const toggleCheck = (event, index) => {
    let updatedState = [...options];
    if (!updatedState[index].selected) {
      updatedState[index].selected = event;
    } else {
      updatedState[index].selected = !event;
    }
    //calculateTotal(updatedState);
    setOptions(updatedState.sort((a, b) => (a.sort > b.sort ? 1 : -1)));
  };

  const arrowHandler = (params, e, index) => {
    let updatedState = [...options];
    if (params === "plus") {
      updatedState[index].count = updatedState[index].count + 1;
    } else if (updatedState[index].count > 1) {
      updatedState[index].count = updatedState[index].count - 1;
    }
    //calculateTotal(updatedState);
    setOptions(updatedState.sort((a, b) => (a.sort > b.sort ? 1 : -1)));
  };

  const onChangeHandler = (count, index) => {
    let updatedState = [...options];
    updatedState[index].count = count;
    setOptions(updatedState.sort((a, b) => (a.sort > b.sort ? 1 : -1)));
  };

  const addWidth = (e, index) => {
    let updatedState = [...options];
    updatedState[index].width = e.target.value;
    setOptions(updatedState.sort((a, b) => (a.sort > b.sort ? 1 : -1)));
  };

  const addHeight = (e, index) => {
    let updatedState = [...options];
    updatedState[index].height = e.target.value;
    setOptions(updatedState.sort((a, b) => (a.sort > b.sort ? 1 : -1)));
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("windows.typeAndQuantityofWindow")}
            </label>
          </div>
          <div className="padding10"></div>
          <div class="paint_flex_with_left ">
            <label class="paint_other_sub_header ">
              {t("windows.typeAndQuantityofWindowTxt")}
            </label>
          </div>
          <div className="padding10"></div>

          {options.map((item, index) => (
            <div className="paintPadding8 each_Section_wrp">
              <div
                onClick={() => toggleCheck(true, index)}
                className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright "
              >
                <div
                  // key={index}
                  className="width100 "
                  style={{ justifyContent: "space-between" }}
                >
                  <table>
                    <tr>
                      <td>
                        <label className="paintdiv_txt">{item.name}</label>
                      </td>
                      <td>
                        <div
                          className="paint_arrowFloat flex"
                          style={{ paddingTop: "10px" }}
                        >
                          <img
                            src={IMAGES.other.green_plus}
                            className="kitchenplusimg"
                            alt=""
                          />

                          <label
                            className="paintdiv_txt paddingleft8"
                            style={{ paddingLeft: "7px", paddingTop: "2px" }}
                          >
                            $0.00
                          </label>

                          <div
                            className="paint_arrowFloat"
                            style={{ paddingLeft: "7px" }}
                          >
                            <CheckBox checked={item.selected} readOnly />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              {item.selected ? (
                <div className="paintPadding8 ">
                  <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
                    <table className="paint_table100">
                      <tr>
                        <td>
                          <div
                            className="typeofTxt"
                            style={{ display: "grid", paddingTop: "0" }}
                          >
                            <DropDown
                              value={item.count || ""}
                              callback={(event) =>
                                onChangeHandler(
                                  parseInt(event.target.value),
                                  index
                                )
                              }
                              length={50}
                            />
                            {/* {userState.number} */}
                          </div>
                          {/* <label className="paintdiv_txt">
                    </label>  */}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="padding10"></div>
                  <div className="paintinput_lable textInput50">
                    <TextInput
                      className="paint_input_border"
                      type="number"
                      onChange={(e) => addWidth(e, index)}
                      placeholder={"Width"}
                    />
                  </div>
                  <div className="padding10"></div>
                  <div className="paintinput_lable textInput50">
                    <TextInput
                      className="paint_input_border"
                      type="number"
                      onChange={(e) => addHeight(e, index)}
                      placeholder={"Height"}
                    />
                  </div>
                  <div className="padding10"></div>
                  <div className="padding10"></div>
                  <div
                    onClick={() => addAnotherOption(item.key)}
                    className="AddSectionTxt"
                    style={{ textAlign: "center" }}
                  >
                    <label>{`Add another ${item.name.toLowerCase()} with different size`}</label>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
          <div className="profilePadding40"></div>
          <label className="paintdiv_txt">
            <strong style={{ marginRight: "5px" }}>IMPORTANT:</strong>
            {
              "The price of each window will be re-assesed when the contractor arrives at the location based on the type, size and quantity of each window in order to define the final quote, which might be different from current estimate."
            }
          </label>
          <div className="profilePadding40"></div>
          <Button
            //disabled={}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSave}
          />
        </div>
      </div>
    </div>
  );
};
