import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";

import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";

import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { treeModel } from "../../_redux/Models/tree.model";
import { calculateTotalCost } from "../../services/utils/utilFunctions";
import { checkoutStore, treeStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { getServicePriceDetails } from "../../services/api/category.api.service";

export const Complexity = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const stepValue = 2;

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.treePayload
      ? state.buttonReducer.treePayload
      : treeModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [option, setOption] = useState(""); // To keep the basement size in state

  useEffect(() => {
    let mounted = true;
    const selectedService = checkoutModelState.selectedServiceDetails;
    if (mounted === true && selectedService._id && selectedService.categoryId) {
      getServicePriceDetails(
        selectedService.categoryId,
        selectedService._id
      ).then((response) => {
        if (mounted && response.status === 200 && response.data) {
          console.log(response.data);
          checkoutModelState.priceDetails = response.data.details;
          dispatch(checkoutStore(checkoutModelState));
        }
      });
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, state.reasonForRoofing, state.serviceType]);

  const onSave = () => {
    state.anyElectricInstallationsNear = option;
    dispatch(treeStore(state));
    if (option === "yes") {
      props.history.push("/tree_services/electric-nearby-tree-details");
    } else {
      props.history.push("/tree_services/electric-not-nearby-tree-details");
    }
  };

  return (
    <div className="insideWrp">
      <HeaderMenu />

      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="service-banner-sec stable_class">
          <div class="banner-bg">
            <img src={IMAGES.home.treeServicesBanner} alt="" />
          </div>
          <div className="floor-title">
            <label
              className="header_path_name_show"
              style={{ textTransform: "initial" }}
            >
              {state.serviceType === "tree_remotion"
                ? "Tree removal"
                : `${state.serviceType.replace(/_/g, " ")}`}
            </label>
          </div>
          <div class="banner-title">
            <label>{t("treeService.headerLink")}</label>
          </div>
        </div>

        <div className="paint_main_buttons">
          <div className="paint_main_button_left">
            <ProgressBar progressValue={stepValue} />
            {/* <img src={IMAGES.other.stepper1} alt="" /> */}
            <div>
              <label className="paint_every_flow_title">
                {t("treeService.complexity")}
              </label>
            </div>
          </div>
          <div className="paint_main_button_right">
            <table>
              <tr>
                <td>
                  <img src={IMAGES.other.piggy} alt="" />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <label className="paint_every_price_txt">--</label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <div className="padding10"></div>
          <div className="paint_flex_with_left paddinngtop20">
            <label className="paint_header" style={{ fontSize: "19px" }}>
              {t("treeService.complexityHeading")}
            </label>
          </div>
          <div className="padding10"></div>
          <div class="paint_flex_with_left ">
            <label class="paint_other_sub_header ">
              {t("treeService.complexityText")}
            </label>
          </div>
          <div className="padding10"></div>
          <div
            // key={index}
            className="paintPadding8 "
          >
            <div
              onClick={() => {
                setOption("yes");
              }}
              className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright "
            >
              <div
                // key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">
                  Yes, there are structures nearby
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <RadioButton checked={option === "yes"} readOnly />
                </div>
              </div>
            </div>
          </div>
          <div className="padding10"></div>

          <div
            // key={index}
            className="paintPadding8 "
          >
            <div
              onClick={() => {
                setOption("no");
              }}
              className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright "
            >
              <div
                // key={index}
                className="width100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="paintdiv_txt">
                  No, the trees are not close to any structure
                </label>
                <div
                  className="paint_arrowFloat"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <RadioButton checked={option === "no"} readOnly />
                </div>
              </div>
            </div>
          </div>

          <div className="padding10"></div>

          <div className="profilePadding40"></div>
          <Button
            disabled={!option}
            buttonLabel={t("wallOpening.continueToNext")}
            onClick={onSave}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};
