import React from "react";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_components/radioButton/radioButton.css";
import { Button } from "../../../_components/Button/Button";
import RadioButton from "../../../_components/radioButton/radioButton";
import { withTranslation } from "react-i18next";
import { checkoutStore } from "../../../_redux/actions";
import { parseJwt } from "../../../services/utils/utilFunctions";
import {
  ownerProfile,
  ownerRegisteredAddresses,
  updateOwnerProfile,
} from "../../../services/api/owner.service";
import Loader from "../../../_components/spinner/Loader";
import { connect } from "react-redux";
import Compressor from "compressorjs";
import { withSnackbar } from "notistack";
import AddAddressForm from "../AddAddressForm";
import { Modal } from "@mui/material";
import axiosInstance from "../../../services/api/config/api.config";

class OwnerProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      addresses: [],
      selectedAddressId: "",
      isLoading: false,
      addAddressModal: false,
      addressDetails: null,
    };
  }

  /**
   * Collect the added addresses & cards and add them to state
   */
  componentDidMount = () => {
    //this.getUserAddresses();
    this.getUserInfo();
  };

  getUserInfo = () => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (user && user._id) {
      this.setState({ isLoading: true }, () => {
        ownerProfile(user._id)
          .then((data) => {
            if (data.data && data.data) {
              const userObj = data.data;
              this.setState({
                user: userObj,
                addresses: userObj.address,
              });
            }
          })
          .finally((_) =>
            this.setState({ isLoading: false }, () => console.log(this.state))
          );
      });
    }
  };

  getUserAddresses = () => {
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);
    if (userDetails) {
      ownerRegisteredAddresses(userDetails._id).then((data) => {
        if (
          data.status === 200 &&
          data.data &&
          data.data?.address?.length > 0
        ) {
          var addresses = data.data.address;
          this.setState({ addresses: addresses });
        }
      });
    }
  };

  setAddaddressModal = (val) => {
    this.setState({ addAddressModal: val, addressDetails: null });

    this.getUserInfo();
  };

  deleteAddress = async (id) => {
    try {
      this.setState({ isLoading: true });
      await axiosInstance.delete(
        `/owner/${this.state.user?.userId?._id}/address`,
        {
          data: {
            addressId: id,
          },
        }
      );
      this.getUserInfo();
    } catch (err) {
      alert("Failed to delete address");
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleFileUploadFor = (file, field) => {
    new Compressor(file, {
      quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        this.fileToDataUri(compressedResult).then((dataUri) => {
          fetch(dataUri)
            .then((res) => res.blob())
            .then((res) => {
              this.uploadFilesToServer(res, field, () => this.getUserInfo());
            });
        });
      },
    });
  };
  fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  uploadFilesToServer = (file, field, callback) => {
    const postData = new FormData();
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (file && field) {
      this.setState({ isLoading: true });
      postData.append(field, file);
      updateOwnerProfile(user._id, postData)
        .then((data) => {
          if (data.status !== 200) {
            const variant = "error";
            this.props.enqueueSnackbar("Upload failed please try again", {
              variant,
            });
          } else if (callback) {
            callback();
          }
        })
        .catch((e) => {
          const variant = "error";
          this.props.enqueueSnackbar(e.toString(), { variant });
        })
        .finally((_) => {
          this.setState({ isLoading: false });
        });
    }
  };

  render = () => {
    const { t } = this.props;
    return (
      <div className="insideWrp">
        <HeaderMenu />
        <Loader progress={this.state.isLoading} />
        {this.state.user ? (
          <div className="paint-home-section myProfile_wrapper pcWidth">
            <div className="profilePadding30 personal_flex_with_center">
              <div className="contactor_profile_image stable-class">
                <div className="profile_image stable-class">
                  <label className="profile_visible_image">
                    <img
                      src={
                        this.state.user.profileImage
                          ? this.state.user.profileImage
                          : IMAGES.home.hdmanSlide
                      }
                      alt=""
                    />
                  </label>

                  <label className="profile_edit_button">
                    <span>
                      <input
                        type="file"
                        accept="image/*"
                        name="file"
                        id="filefirst"
                        className="inputfile"
                        onChange={(e) =>
                          this.handleFileUploadFor(
                            e.target.files[0],
                            "profileImage"
                          )
                        }
                      />
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="otherspadding25"></div>
            <div className="paint_flex_with_left profilePadding25">
              <label className="paint_header">
                {t("profileCreation.profinfo")}
              </label>
            </div>
            <div>
              <label
                className="floor-selected"
                style={{
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <div
                  className="flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: " 100%",
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      textAlign: "left",
                      textTransform: "capitalize",
                    }}
                  >
                    <span>
                      {this.state.user.userId
                        ? this.state.user.userId.name
                        : "NA"}
                    </span>
                    <span>
                      {this.state.user.userId
                        ? this.state.user.userId.email
                        : "NA"}
                    </span>
                    <span>
                      {this.state.user.mobileNumber
                        ? this.state.user.mobileNumber
                        : "NA"}
                    </span>
                    <span>
                      {this.state.user.zipCode ? this.state.user.zipCode : "NA"}
                    </span>
                    {/* <span>12/05/2022</span> */}
                  </div>

                  <div
                    onClick={() =>
                      this.props.history.push("/user/edit-profile")
                    }
                    style={{
                      display: "flex",
                      placeItems: "center",
                      width: "50px",
                    }}
                  >
                    <div style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "bold", color: "#2ccae8" }}>
                        Edit
                      </span>
                    </div>
                  </div>
                </div>
              </label>
            </div>

            <div className="paint_flex_with_left profilePadding40">
              <label className="paint_header">{"Address"}</label>
            </div>
            <div className="otherspadding25"></div>
            {this.state.addresses && this.state.addresses.length > 0 ? (
              <div key={Math.random()}>
                {this.state.addresses.map((address) => (
                  //on click radio button will activate edit btn
                  <div
                    key={Math.random()}
                    onClick={() =>
                      this.setState({ selectedAddressId: address._id })
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    <div className="paint_radio_div_first paintdiv_radiobtn_paddingright ">
                      <div>
                        <img
                          src={IMAGES.other.miniHouse}
                          alt=""
                          style={{ width: "71px", height: "71px" }}
                        />
                      </div>
                      <div className="grid">
                        <label className="checkoutRatingstxtdetails">
                          {address.fullAddress}
                        </label>
                      </div>

                      <div className="paint_arrowFloat">
                        {" "}
                        <RadioButton
                          checked={this.state.selectedAddressId === address._id}
                          readOnly
                        />
                      </div>
                    </div>
                    {this.state.selectedAddressId === address._id ? (
                      <>
                        <div className="editButton-cls d-flex">
                          <Button
                            disabled={false}
                            onClick={() => {
                              // this.setState({ selectedAddressId: "" }, () =>
                              //   this.props.history.push(
                              //     `/user/edit-address/${address._id}`
                              //   )
                              // );
                              this.setState({
                                addressDetails: address,
                                addAddressModal: !this.state.addAddressModal,
                              });
                            }}
                            buttonLabel={t("addAddress.editAddress")}
                            className="btn-checkouttick"
                          />
                          <Button
                            className="btn-checkouttick text-danger"
                            buttonLabel="Delete"
                            onClick={() => {
                              this.deleteAddress(this.state.selectedAddressId);
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : null}
            <Button
              disabled={false}
              onClick={() => {
                this.setState({ addAddressModal: !this.state.addAddressModal });
              }}
              buttonLabel={`+ ${t("addAddress.addNew")}`}
              className="btn-checkouttick"
            />
            {/* <div className="paint_flex_with_left profilePadding40">
              <label className="paint_header">{"Payment methods"}</label>
            </div> */}
            <div className="otherspadding25"></div>
            {/* <div className="otherspadding25"></div>
            <div className="">
              <div>
                <table className="paint_table100">
                  {this.props.checkout &&
                    this.props.checkout.payload.cards.length > 0 ? (
                    <div key={Math.random()}>
                      {this.props.checkout.payload.cards.map((card) => (
                        <tr
                          onClick={() =>
                            this.setState({ selectedCardId: card.id })
                          }
                          className="check_radio_div_blue paintdiv_radiobtn_paddingright"
                          style={{ marginBottom: "10px" }}
                        >
                          <td>
                            <img src={IMAGES.other.visa} alt="" />
                          </td>
                          <td className="grid">
                            <label className="checkoutRatingstxtdetails">
                              {card.name}
                            </label>
                            <label className="checkPaygray">
                              {card.number}
                            </label>
                          </td>
                          <td className="paint_arrowFloat">
                            <RadioButton
                              checked={this.state.selectedCardId === card.id}
                              readOnly
                            />
                          </td>
                        </tr>
                      ))}
                      <div className="otherspadding25"></div>
                    </div>
                  ) : null}
                </table>
              </div>
            </div> */}
            {/* <Button
              disabled={false}
              onClick={() => this.props.history.push("/user/add-card")}
              buttonLabel={"+ Add new credit card"}
              className="btn-checkouttick"
            /> */}

            <div className="otherspadding25"></div>
            <div>
              <label
                className="floor-selected"
                style={{
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <div
                  className="flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: " 100%",
                  }}
                >
                  <div
                    style={{
                      width: "88%",
                      textAlign: "left",
                      textTransform: "capitalize",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#000000",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        paddingLeft: "5px",
                      }}
                    >
                      Terms & Conditions
                    </label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      placeItems: "center",
                      width: "50px",
                    }}
                  >
                    <div style={{ paddingLeft: "10px" }}>
                      <span
                        onClick={() =>
                          this.props.history.push("/terms-and-condition")
                        }
                        style={{ fontWeight: "bold", color: "#2ccae8" }}
                      >
                        View
                      </span>
                    </div>
                  </div>
                </div>
              </label>
            </div>

            <div className="profilePadding20"></div>
            <div className="profilePadding20"></div>
            <div className="profilePadding30"></div>
            <div className="allCenterbtn arrow_gap">
              <button
                onClick={() => {
                  localStorage.clear();
                  this.props.history.push("/");
                }}
                className="btn-checkout rd_bg"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ paddingRight: "10px" }}
                  src={IMAGES.other.logoutWhite}
                  alt=""
                />
                <span>Log out</span>
              </button>
            </div>
          </div>
        ) : null}
        <Footer />
        <Modal
          open={this.state.addAddressModal}
          onClose={() => {
            this.setAddaddressModal(false);
          }}
          style={{ overflowY: "auto" }}
          sx={{
            outline: "none",
            margin: "auto",
          }}
        >
          <div
            className="mx-2 mx-sm-auto"
            style={{
              maxWidth: "650px",
              margin: "auto",
              marginTop: "100px",
              marginBottom: "100px",
            }}
          >
            <div
              className="d-flex flex-column verify-mobile-modal bg-white position-relative"
              style={{ padding: "5%" }}
            >
              <div
                className="position-absolute"
                style={{
                  top: "20px",
                  right: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setAddaddressModal(false);
                  this.setState({ addressDetails: null });
                }}
              >
                <img src={IMAGES.home.closeIcon} />
              </div>
              <AddAddressForm
                setAddaddressModal={this.setAddaddressModal}
                addressDetails={this.state.addressDetails}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
  buttonReducer: state.buttonReducer,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(withSnackbar(OwnerProfile)));
