import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import { PaintLanding } from "./_container/paintingComponents/landing";
import HomeComponent from "./_container/homeComponents/homeComponent";

import SignIn from "./_container/profileComponents/signIn/signin";
import RecoverPassword from "./_container/profileComponents/signIn/recoverPassword.comp";
import CheckMail from "./_container/profileComponents/signIn/checkMail.comp";
import GeneratePassword from "./_container/profileComponents/signIn/generatePassword.comp";
import ChangePassword from "./_container/profileComponents/signIn/changePassword.comp";
import {
  SignupRole,
  Register,
  Validation,
  SignupProperty,
} from "./_container/profileComponents/signUp";
import { NeedFloorInstall } from "./_container/floorComponents/engineered/needFloorInstall";
import { Eyeshow } from "./_components/Tickandwrongsvg/eyeshowVector";
import { NotificationsNavigations } from "./_components/notifiactionsNavigations/notifiactionsNavigations";
import SolidHardwood from "./_container/floorComponents/solidHardwood/solidHardwood";
import CurrentFloor from "./_container/floorComponents/solidHardwood/currentFloor";
import FloorSize from "./_container/floorComponents/solidHardwood/floorSize";
import TypeWood from "./_container/floorComponents/solidHardwood/typeWood";
import AddsOn from "./_container/floorComponents/solidHardwood/addsOn";
import TypeInstallation from "./_container/floorComponents/solidHardwood/typeInstallation";
import { FloorOrderSummary } from "./_container/floorComponents/solidHardwood/floorOrderSummary";
import TypeService from "./_container/floorComponents/solidHardwood/typeService";

import { FloorSizeDetails } from "./_container/floorComponents/engineered/FloorSizeDetails";
import { CurrentTypeOfFloor } from "./_container/floorComponents/engineered/CurrentTypeOfFloor";
import { OrderSummary } from "./_container/floorComponents/engineered/OrderSummary";
import { SizeOfFloor as EngineeredFloorSize } from "./_container/floorComponents/engineered/SizeOfFloor";
import { AddOn } from "./_container/floorComponents/engineered/AddOn";
import { PersonalInfo as EngineeredPersonalInfo } from "./_container/floorComponents/engineered/personalInfo";

import { Summary } from "./_container/floorComponents/carpet/Summary";
import { SizeOfFloor } from "./_container/floorComponents/carpet/SizeOfFloor";
import { ServiceType } from "./_container/floorComponents/carpet/ServiceType";
import { InteriorTypeOfWork } from "./_container/paintingComponents/interior/typeofwork";
import { InteriorRoomSize } from "./_container/paintingComponents/interior/roomSize";
import { PickRoomColorInterior } from "./_container/paintingComponents/interior/pickcolorRoom";
import { PickHouseColorInterior } from "./_container/paintingComponents/interior/pickcolorHouse";
import { InteriorAddons } from "./_container/paintingComponents/interior/addOn";
import { InteriorOrderSummary } from "./_container/paintingComponents/interior/orderSummary";
import { Housematerial } from "./_container/paintingComponents/exterior/houseMaterial";
import { Housesizeing } from "./_container/paintingComponents/exterior/houseSize";
import { PickColorRoomExterior } from "./_container/paintingComponents/exterior/piccolorRoom";
import { ExteriorAddons } from "./_container/paintingComponents/exterior/addOns";
import { SummaryExterior } from "./_container/paintingComponents/exterior/summary";
import { Decksize } from "./_container/paintingComponents/deck/deckSize";
import { Typeoffinish } from "./_container/paintingComponents/deck/typeofFinsish";
import { Addonsdeck } from "./_container/paintingComponents/deck/addOns";
import { Summarydeck } from "./_container/paintingComponents/deck/summary";
import { Fencesize } from "./_container/paintingComponents/fence/fenceSize";
import { Typeoffinishfence } from "./_container/paintingComponents/fence/typeofFinish";
import { Summaryfence } from "./_container/paintingComponents/fence/summary";
import { Letstalk } from "./_container/paintingComponents/other/letstalk";
import { Personalinfo } from "./_container/paintingComponents/personalInfo";
import { Stepdescription } from "./_container/profileComponents/createProfile/stepDescription";
import { Profileconfirmation } from "./_container/profileComponents/createProfile/profileConfirmation";
import ScrollAction from "./_components/Scroll/ScrollAction";
import ContractorProfileInfo from "./_container/profileComponents/createProfile/profileInfo";
import { Profilecomplete } from "./_container/profileComponents/createProfile/profileComplete";
import { Homedrawer } from "./_components/HeaderMenu/drawer";
import { Kitchentypeofwork } from "./_container/kitchenComponents/typeofService";
import { KitchenRequirement } from "./_container/kitchenComponents/requirement";
import { KitchenCabinets } from "./_container/kitchenComponents/kitchenCabinets";
import { KitchenSize } from "./_container/kitchenComponents/kitchenSize";
import { KitchenRemodel } from "./_container/kitchenComponents/remodellingDetails";
import { Kitchenaddons } from "./_container/kitchenComponents/addOns";
import { Kitchensummary } from "./_container/kitchenComponents/summary";
import { Bathroomlanding } from "./_container/bathroomComponents/landing";
import { Termscondition } from "./_container/homeComponents/footerSection/terms";
import { ContactUs } from "./_container/homeComponents/footerSection/contact";

import { CarpetQuality } from "./_container/floorComponents/carpet/carpetQuality";
import { Tick } from "./_components/Tickandwrongsvg/tick";
import { Wrong } from "./_components/Tickandwrongsvg/wrong";
import { PaddingQuality } from "./_container/floorComponents/carpet/paddingQuality";
import { CurrentCarpetFloor } from "./_container/floorComponents/carpet/currentFloor";
import { Exclamation } from "./_components/Tickandwrongsvg/exclamation";
import { PersonalInfo } from "./_container/floorComponents/carpet/personalInfo";
import StartingDate from "./_container/projectinitiateComponents/startingDate";

// SolidHardWood routes
//import

// Basement Imports
import { TypeOfService as BasementTypeOfService } from "./_container/basement/TypeOfService";
import { BasementSize } from "./_container/basement/basementSize";
import { BasementHeight } from "./_container/basement/basementHeight";
import { BasementPlumbing } from "./_container/basement/basementPlumbing";
import { BasementSumpPump } from "./_container/basement/basementSumpPump";
import { BasementElectrical } from "./_container/basement/basementElectrical";
import { AddOn as BasementAddons } from "./_container/basement/AddOn";
import { PersonalInfo as BasementPersonalInfo } from "./_container/basement/personalInfo";
import { OrderSummary as BasementOrderSummary } from "./_container/basement/OrderSummary";

//Wall Open Imports
import { OpeningSize as WallOpeningSize } from "./_container/wallOpening/openingSize";
import { OpeningHeight as WallOpeningHeight } from "./_container/wallOpening/openingHeight";
import { WallType } from "./_container/wallOpening/wallType";
import { HasBasement as WallHasBasement } from "./_container/wallOpening/hasBasement";
import { OpeningType as WallOpeningType } from "./_container/wallOpening/openingType";
import { AddOn as WallAddons } from "./_container/wallOpening/AddOn";
import { PersonalInfo as WallOpeningPersonalInfo } from "./_container/wallOpening/personalInfo";
import { OrderSummary as WallOpeningOrderSummary } from "./_container/wallOpening/OrderSummary";

// Tiles Imports
import { SizeOfFloor as TileFloorSize } from "./_container/floorComponents/Tiles/SizeOfFloor";
import { FloorSizeDetails as TileFloorSizeDetails } from "./_container/floorComponents/Tiles/FloorSizeDetails";
import { CurrentTypeOfFloor as TileCurrentFloor } from "./_container/floorComponents/Tiles/CurrentTypeOfFloor";
import { AddOn as TileAddons } from "./_container/floorComponents/Tiles/AddOn";
import { PersonalInfo as TilePersonalInfo } from "./_container/floorComponents/Tiles/personalInfo";
import { TileOrderSummary } from "./_container/floorComponents/Tiles/TileOrderSummary";

// Epoxy Routes
import { SizeOfFloor as EpoxyFloorSize } from "./_container/floorComponents/Epoxy/SizeOfFloor";
import { CurrentTypeOfFloor as EpoxyCurrentFloor } from "./_container/floorComponents/Epoxy/CurrentTypeOfFloor";
import { AddOn as EpoxyAddons } from "./_container/floorComponents/Epoxy/AddOn";
import { PersonalInfo as EpoxyPersonalInfo } from "./_container/floorComponents/Epoxy/personalInfo";
import { EpoxyOrderSummary } from "./_container/floorComponents/Epoxy/EpoxyOrderSummary";

// Laminated Routes
import { SizeOfFloor as LaminatedFloorSize } from "./_container/floorComponents/Laminated/SizeOfFloor";
import { ServiceType as LaminatedServiceType } from "./_container/floorComponents/Laminated/ServiceType";
import { CurrentTypeOfFloor as LaminatedCurrentFloor } from "./_container/floorComponents/Laminated/CurrentTypeOfFloor";
import { AddOn as LaminatedAddons } from "./_container/floorComponents/Laminated/AddOn";
import { PersonalInfo as LaminatedPersonalInfo } from "./_container/floorComponents/Laminated/personalInfo";
import { LaminatedOrderSummary } from "./_container/floorComponents/Laminated/LaminatedOrderSummary";

import { Letstalkother } from "./_container/floorComponents/Other/letsTalk";

// Checkout Routes
import SelectContractor from "./_container/checkoutProcess/SelectContractor";
import CheckoutAddressDetails from "./_container/checkoutProcess/AddressDetails";
import CheckoutEditAddress from "./_container/checkoutProcess/Forms/AddEditAddress";
import CheckoutEditCard from "./_container/checkoutProcess/Forms/AddEditCard";
import PaymentMethod from "./_container/checkoutProcess/PaymentMethod";
import FormSubmitted from "./_container/checkoutProcess/FormSubmitted";

import MyProjectList from "./_container/User/MyProjects/MyProjectList";
import ViewProject from "./_container/User/MyProjects/ViewMyProject";
import ProjectDetails from "./_container/User/MyProjects/ProjectDetails";
import Portfolio from "./_container/Contractor/Portfolio";
import PortfolioDetails from "./_container/Contractor/PortfolioDetails";
import OwnerProfile from "./_container/User/OwnerProfile/OwnerProfile";
import EditStartDate from "./_container/projectinitiateComponents/editStartingDate";
import Wallet from "./_container/User/wallet";
import ContractorProfile from "./_container/User/ContractorProfile/ContractorProfile";
import { DeckPersonalInfo } from "./_container/paintingComponents/deck/personalInfo";
import { FencePersonalInfo } from "./_container/paintingComponents/fence/personalInfo";
import { HvacTypeofService } from "./_container/HVACcomponents/typeOfService";
import { PaymentSuccess } from "./_container/checkoutProcess/PaymentSuccess";

import Notifications from "./_container/User/Notifications/notifications";

//ContractorProjec

import ContractorProjectList from "./_container/Contractor/MyProjects/ContractorProjectList";
import ContractorViewProject from "./_container/Contractor/MyProjects/ContractorViewProject";

import { FireBase } from "./services/firebase/firebase.service";
import {
  addFirebaseToken,
  deleteFirebaseToken,
} from "./services/api/notifications.api.services";

//Electric project

// Bathroom imports
import { NumberOfBathrooms } from "./_container/Bathroom/NumberOfBathrooms";
import { BathroomSize } from "./_container/Bathroom/bathroomSize";
import { HaveBathroomMaterials } from "./_container/Bathroom/haveBathroomMaterials";
import { BathroomNeedDemolition } from "./_container/Bathroom/needDemolition";
import { BathroomNeedBuildupWalls } from "./_container/Bathroom/needBuildupWalls";
import { BathroomNeedShowerDoor } from "./_container/Bathroom/needShowerDoor";
import { BathroomAddOns } from "./_container/Bathroom/addOns";
import { BathroomPersonalInfo } from "./_container/Bathroom/personalInfo";
import { BathroomSummary } from "./_container/Bathroom/summary";
import { ExteriorPersonalInfo } from "./_container/paintingComponents/exterior/personalInfo";
import { SHSizeOfFloor } from "./_container/floorComponents/SolidHardwoodNew/SizeOfFloor";
import { SHHaveSolidHardwood } from "./_container/floorComponents/SolidHardwoodNew/haveSolidHardwood";
import { SHIsFinished } from "./_container/floorComponents/SolidHardwoodNew/IsFinished";
import { SHWoodDetails } from "./_container/floorComponents/SolidHardwoodNew/TypeOfWood";
import { SHTypeOfInstallation } from "./_container/floorComponents/SolidHardwoodNew/TypeOfInstallation";
import { SHPriorFloor } from "./_container/floorComponents/SolidHardwoodNew/currentFloor";
import { SHAddOn } from "./_container/floorComponents/SolidHardwoodNew/AddOn";
import { SHPersonalInfo } from "./_container/floorComponents/SolidHardwoodNew/personalInfo";
import { SHOrderSummary } from "./_container/floorComponents/SolidHardwoodNew/OrderSummary";
import AssistantProjects from "./_container/Contractor/MyProjects/AssistantProjects";
import { InteriorPersonalInfo } from "./_container/paintingComponents/interior/personalInfo";
import TentativeDate from "./_container/Contractor/MyProjects/TentativeDate";
import { ReplaceOrNewHvac } from "./_container/HVACcomponents/replaceOrNewHvac";
import { TypeOfInstallation } from "./_container/HVACcomponents/typeOfInstallation";
import { HvacSizeDetails } from "./_container/HVACcomponents/sizeDetails";
import { TypeOfHvacUnit } from "./_container/HVACcomponents/typeOfHvacUnit";
import { HvacSelectBrand } from "./_container/HVACcomponents/selectBrand";
import { HvacAddOns } from "./_container/HVACcomponents/addOns";
import { HvacOrderSummary } from "./_container/HVACcomponents/OrderSummary";
import { HvacRepairReplacement } from "./_container/HVACcomponents/repairReplacements";
import { RepairAgreement } from "./_container/HVACcomponents/repairAgreement";
import { ElectricTypeofService } from "./_container/Electric/typeOfService";
import { ElectricTypeOfInstallation } from "./_container/Electric/typeOfInstallation";
import { ElectricSelectItems } from "./_container/Electric/selectItems";
import { ElectricOrderSummary } from "./_container/Electric/OrderSummary";
import { ElectricRepairComments } from "./_container/Electric/repairComments";
import { PlumbingTypeofService } from "./_container/Plumbing/typeOfService";
import { PlumbingSelectItems } from "./_container/Plumbing/selectItems";
import { PlumbingOrderSummary } from "./_container/Plumbing/OrderSummary";

//Roofing
import { NeedOfRoofing } from "./_container/Roofing/NeedofRoofing";
import { SizeOfRoofing } from "./_container/Roofing/SizeOfRoofing";
import { HaveOwnRoof } from "./_container/Roofing/HaveOwnRoof";
import { HeightOfRoof } from "./_container/Roofing/HeightOfRoof";
import { MaterialOfRoof } from "./_container/Roofing/MaterialOfRoof";

//Windows
import { NeedOfWindows } from "./_container/windows/NeedofWindows";
import { WindowQuantity } from "./_container/windows/WindowQuantity";
import { WindowAddons } from "./_container/windows/AddOns";

//Tree
import { TreeTypeOfService } from "./_container/TreeService/TypeOfService";
import { Complexity } from "./_container/TreeService/Complexity";
import { TreeSelection } from "./_container/TreeService/TreeSelection";
import { RootsRemoval } from "./_container/TreeService/RootsRemoval";
import { RootsRemovalMachine } from "./_container/TreeService/RootsRemovalMachine";
import { PlumbingHowUrgentService } from "./_container/Plumbing/howUrgent";
import { PlumbingEmergencyComments } from "./_container/Plumbing/emergencyComments";
import { RateProject } from "./_container/User/MyProjects/RateProject";
import { EditOwnerProfile } from "./_container/User/OwnerProfile/Forms/Editprofile";
import AddEditAddress from "./_container/User/OwnerProfile/Forms/AddEditAddress";
import { RoofingTypeofService } from "./_container/Roofing/typeOfService";
import { WindowTypeofService } from "./_container/windows/typeOfService";
import { HaveWindows } from "./_container/windows/HaveWindows";
import { PitchOfRoof } from "./_container/Roofing/PitchOfRoof";
import { RoofingOrderSummary } from "./_container/Roofing/OrderSummary";
import { WindowsOrderSummary } from "./_container/windows/OrderSummary";
import { RemovalOfPlywood } from "./_container/Roofing/RemovalOfPlywood";
import { NumberOfRepairsNeeded } from "./_container/Roofing/NumberOfRepairsNeeded";
import { TreeDetailsNearElectric } from "./_container/TreeService/TreeDetailsNearElectric";
import { TreeDetailsNotNearElectrical } from "./_container/TreeService/TreeDetailsNotNearElectrical";
import { TreeOrderSummary } from "./_container/TreeService/OrderSummary";
import { TreeHeightDetails } from "./_container/TreeService/TreeHeightDetails";
import { DebrisHaulAway } from "./_container/TreeService/DebrisHaulAway";
import { InteriorPaintingRoomsAddOns } from "./_container/paintingComponents/interior/roomsAddOns";
import { TreeStumpRemoval } from "./_container/TreeService/TreeStumpRemoval";
import Rating from "./_container/User/Rating";
import { HavePaint } from "./_container/paintingComponents/HavePaint";
import { ElectricSelectInstallItems } from "./_container/Electric/selectInstallItems";

import GroupChannelTypingIndicatorSample from "./_container/User/Chat2/chats";
import SmsOtpValidator from "./_container/profileComponents/signIn/smsOtpValidator";
import Home from "./_container/homeComponents/home";
import Favourites from "./_container/Contractor/Favourites";
import MyProfile from "./_container/User/OwnerProfile/MyProfile";
import ConfirmContractor from "./_container/Contractor/ConfirmContractor";
import DeleteAccount from "./_container/User/DeleteAccount";
import AddServiceLocations from "./_container/profileComponents/signUp/add-service-locations";
import AddBankDetails from "./_container/profileComponents/signUp/add-bank-details";
import PaymentsReceived from "./_container/profileComponents/bank-details/payments-received";
import ContractorView from "./_container/Contractor/MyProjects/ContractorView";
import BankDetails from "./_container/profileComponents/bank-details/bank-details";

function App() {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }

    deleteToken();
    initFirebaseService();
  }, []);

  const initFirebaseService = async () => {
    try {
      const firebase = new FireBase();
      const { token } = await firebase.initializeFireBase();
      addFirebaseToken(token);
      // passing FCM token to the `chatClientInstance` to register for push notifications
      window.conversationsClient?.setPushRegistrationId("fcm", token);
    } catch (e) {
      console.log("notification rejected", e);
    }
  };

  const deleteToken = async function () {
    const tokenId = localStorage.getItem("tokenId");
    if (tokenId) {
      await deleteFirebaseToken(tokenId);
      localStorage.removeItem("tokenId");
    }
  };

  return (
    <Router basename={"/"}>
      <NotificationsNavigations />

      <ScrollAction>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* Signup Flow routes start */}

          <Route exact path="/role" component={SignupRole} />
          <Route exact path="/property" component={SignupProperty} />
          <Route exact path="/validation" component={Validation} />
          <Route exact path="/signup" component={Register} />
          <Route exact path="/favorites" component={Favourites} />
          <Route
            exact
            path="/service-locations"
            component={AddServiceLocations}
          />
          <Route exact path="/bank-details/edit" component={AddBankDetails} />
          <Route exact path="/add-bank-details/" component={AddBankDetails} />

          <Route exact path="/bank-details/" component={BankDetails} />
          <Route exact path="/payments-received" component={PaymentsReceived} />

          {/* Signup Flow routes end */}

          <Route exact path="/floors/solidhardwood" component={SolidHardwood} />

          {/* Solid hardwood Routes start */}
          <Route
            exact
            path="/floors/solid-hardwood/floor-size/:stepValue"
            component={SHSizeOfFloor}
          />
          <Route
            exact
            path="/floors/solid-hardwood/have-solid-hardwood/:stepValue"
            component={SHHaveSolidHardwood}
          />
          <Route
            exact
            path="/floors/solid-hardwood/solid-hardwood-finished/:stepValue"
            component={SHIsFinished}
          />
          <Route
            exact
            path="/floors/solid-hardwood/wood-details/:stepValue"
            component={SHWoodDetails}
          />
          <Route
            exact
            path="/floors/solid-hardwood/type-of-installation/:stepValue"
            component={SHTypeOfInstallation}
          />

          <Route
            exact
            path="/contractor/business-info"
            component={ContractorProfileInfo}
          />
          <Route
            exact
            path="/contractor/services-added"
            component={Stepdescription}
          />
          <Route
            exact
            path="/floors/solid-hardwood/current-floor/:stepValue"
            component={SHPriorFloor}
          />
          <Route
            exact
            path="/floors/solid-hardwood/add-ons/:stepValue"
            component={SHAddOn}
          />
          <Route
            exact
            path="/floors/solid-hardwood/personal-info/:stepValue"
            component={SHPersonalInfo}
          />
          <Route
            exact
            path="/floors/solid-hardwood/order-summary/:stepValue"
            component={SHOrderSummary}
          />

          {/* Solid hardwood end */}

          <Route
            exact
            path="/floors/solidhardwood/type-wood"
            component={TypeWood}
          />
          <Route
            exact
            path="/floors/solidhardwood/floor-size"
            component={FloorSize}
          />
          <Route
            exact
            path="/floors/solidhardwood/adds-on"
            component={AddsOn}
          />
          <Route
            exact
            path="/floors/solidhardwood/order-summary"
            component={FloorOrderSummary}
          />
          <Route
            exact
            path="/floors/solidhardwood/type-of-installation"
            component={TypeInstallation}
          />
          <Route
            exact
            path="/floors/solidhardwood/services"
            component={TypeService}
          />

          {/* <Route exact path="/floors/solidhardwood/type-of-service" component={TypeOfService} /> */}
          <Route exact path="/signin" component={SignIn} />

          <Route exact path="/signin/verify-otp" component={SmsOtpValidator} />
          <Route
            exact
            path="/signin/recover-password"
            component={RecoverPassword}
          />
          <Route exact path="/signin/check-mail" component={CheckMail} />
          <Route exact path="/reset-password" component={GeneratePassword} />
          <Route
            exact
            path="/signin/change-password"
            component={ChangePassword}
          />

          {/* Basement Routes */}
          <Route
            exact
            path="/basement/service-type"
            component={BasementTypeOfService}
          />
          <Route
            exact
            path="/basement/size/:stepValue"
            component={BasementSize}
          />
          <Route
            exact
            path="/basement/height/:stepValue"
            component={BasementHeight}
          />
          <Route
            exact
            path="/basement/plumbing/:stepValue"
            component={BasementPlumbing}
          />
          <Route
            exact
            path="/basement/sump-pump/:stepValue"
            component={BasementSumpPump}
          />
          <Route
            exact
            path="/basement/electrical/:stepValue"
            component={BasementElectrical}
          />
          <Route
            exact
            path="/basement/addons/:stepValue"
            component={BasementAddons}
          />
          <Route
            exact
            path="/basement/personal-info/:stepValue"
            component={BasementPersonalInfo}
          />
          <Route
            exact
            path="/basement/order-summary/:stepValue"
            component={BasementOrderSummary}
          />

          {/* End basement Routes */}

          {/* WallOpening Routes */}
          <Route exact path="/wall-opening/size" component={WallOpeningSize} />
          <Route
            exact
            path="/wall-opening/height/:stepValue"
            component={WallOpeningHeight}
          />
          <Route
            exact
            path="/wall-opening/type/:stepValue"
            component={WallType}
          />
          <Route
            exact
            path="/wall-opening/has-basement/:stepValue"
            component={WallHasBasement}
          />
          <Route
            exact
            path="/wall-opening/type-of-opening/:stepValue"
            component={WallOpeningType}
          />

          <Route
            exact
            path="/wall-opening/addons/:stepValue"
            component={WallAddons}
          />
          <Route
            exact
            path="/wall-opening/personal-info/:stepValue"
            component={WallOpeningPersonalInfo}
          />
          <Route
            exact
            path="/wall-opening/order-summary/:stepValue"
            component={WallOpeningOrderSummary}
          />

          {/* End basement Routes */}

          {/* Carpet Routes */}
          <Route
            exact
            path="/floors/carpet/floor-size/:stepValue"
            component={SizeOfFloor}
          />
          <Route
            exact
            path="/floors/carpet/service-type/:stepValue"
            component={ServiceType}
          />
          <Route
            exact
            path="/floors/carpet/carpet-quality/:stepValue"
            component={CarpetQuality}
          />
          <Route
            exact
            path="/floors/carpet/padding-quality/:stepValue"
            component={PaddingQuality}
          />
          <Route
            exact
            path="/floors/carpet/current-floor/:stepValue"
            component={CurrentCarpetFloor}
          />
          <Route
            exact
            path="/floors/carpet/personal-info/:stepValue"
            component={PersonalInfo}
          />
          <Route
            exact
            path="/floors/carpet/summary/:stepValue"
            component={Summary}
          />

          {/* End Carpet Routes */}

          {/* Floor Engineered Routes */}

          <Route
            exact
            path="/floors/engineered/introduce-floor-size/:stepValue"
            component={EngineeredFloorSize}
          />

          <Route
            exact
            path="/floors/engineered/current-floor-details/:stepValue"
            component={CurrentTypeOfFloor}
          />
          <Route
            exact
            path="/floors/engineered/need-floor-install/:stepValue"
            component={NeedFloorInstall}
          />

          <Route
            exact
            path="/floors/engineered/floor-size-details/:stepValue"
            component={FloorSizeDetails}
          />
          <Route
            exact
            path="/floors/engineered/floor-addons/:stepValue"
            component={AddOn}
          />
          <Route
            exact
            path="/floors/engineered/personal-info/:stepValue"
            component={EngineeredPersonalInfo}
          />
          <Route
            exact
            path="/floors/engineered/order-summary/:stepValue"
            component={OrderSummary}
          />

          {/* End Floor Engineered Routes */}

          {/* Tiles Routes */}
          <Route
            exact
            path="/floors/tiles/floor-size/:stepValue"
            component={TileFloorSize}
          />
          <Route
            exact
            path="/floors/tiles/current-floor-details/:stepValue"
            component={TileCurrentFloor}
          />
          <Route
            exact
            path="/floors/tiles/floor-size-details/:stepValue"
            component={TileFloorSizeDetails}
          />
          <Route
            exact
            path="/floors/tiles/floor-addons/:stepValue"
            component={TileAddons}
          />
          <Route
            exact
            path="/floors/tiles/personal-info/:stepValue"
            component={TilePersonalInfo}
          />
          <Route
            exact
            path="/floors/tiles/order-summary/:stepValue"
            component={TileOrderSummary}
          />

          {/* End Tiles Routes */}

          {/* Epoxy Routes */}
          <Route
            exact
            path="/floors/epoxy/floor-size/:stepValue"
            component={EpoxyFloorSize}
          />
          <Route
            exact
            path="/floors/epoxy/current-floor-details/:stepValue"
            component={EpoxyCurrentFloor}
          />
          <Route
            exact
            path="/floors/epoxy/floor-addons/:stepValue"
            component={EpoxyAddons}
          />
          <Route
            exact
            path="/floors/epoxy/personal-info/:stepValue"
            component={EpoxyPersonalInfo}
          />
          <Route
            exact
            path="/floors/epoxy/order-summary/:stepValue"
            component={EpoxyOrderSummary}
          />

          {/* End epoxy Routes */}

          {/* Laminated Routes */}
          <Route
            exact
            path="/floors/laminated/floor-size/:stepValue"
            component={LaminatedFloorSize}
          />
          <Route
            exact
            path="/floors/laminated/service-type/:stepValue"
            component={LaminatedServiceType}
          />
          <Route
            exact
            path="/floors/laminated/current-floor/:stepValue"
            component={LaminatedCurrentFloor}
          />
          <Route
            exact
            path="/floors/laminated/floor-addons/:stepValue"
            component={LaminatedAddons}
          />
          <Route
            exact
            path="/floors/laminated/personal-info/:stepValue"
            component={LaminatedPersonalInfo}
          />
          <Route
            exact
            path="/floors/laminated/order-summary/:stepValue"
            component={LaminatedOrderSummary}
          />

          {/* End epoxy Routes */}

          <Route exact path="/initiate/:stepValue" component={StartingDate} />

          {/* {paintingRoutes} */}

          <Route exact path="/painting" component={PaintLanding} />
          <Route
            exact
            path="/painting/have-paint/:nextRoute"
            component={HavePaint}
          />

          <Route
            exact
            path="/painting/interior/type-of-work/:stepValue"
            component={InteriorTypeOfWork}
          />
          <Route
            exact
            path="/painting/interior/:roomId/room-size/:stepValue"
            component={InteriorRoomSize}
          />

          <Route
            exact
            path="/painting/interior/pick-color-house/:stepValue"
            component={PickHouseColorInterior}
          />
          <Route
            exact
            path="/painting/interior/add-ons/:stepValue"
            component={InteriorAddons}
          />
          <Route
            exact
            path="/painting/interior/:roomId/paint-details/:stepValue"
            component={PickRoomColorInterior}
          />

          <Route
            exact
            path="/painting/interior/:roomId/add-ons/:stepValue"
            component={InteriorPaintingRoomsAddOns}
          />
          <Route
            exact
            path="/painting/interior/personal-info/:stepValue"
            component={InteriorPersonalInfo}
          />

          <Route
            exact
            path="/painting/interior/summary/:stepValue"
            component={InteriorOrderSummary}
          />

          {/* Exterior Routes start*/}

          <Route
            exact
            path="/painting/exterior/house-material/:stepValue"
            component={Housematerial}
          />
          <Route
            exact
            path="/painting/exterior/house-sizing/:stepValue"
            component={Housesizeing}
          />
          <Route
            exact
            path="/painting/exterior/pick-color/:stepValue"
            component={PickColorRoomExterior}
          />
          <Route
            exact
            path="/painting/exterior/add-ons/:stepValue"
            component={ExteriorAddons}
          />
          <Route
            exact
            path="/painting/exterior/personal-info/:stepValue"
            component={ExteriorPersonalInfo}
          />
          <Route
            exact
            path="/painting/exterior/summary/:stepValue"
            component={SummaryExterior}
          />

          {/* Exterior Routes End*/}

          {/* Deck Routes start*/}

          <Route
            exact
            path="/painting/deck/deck-size/:stepValue"
            component={Decksize}
          />
          <Route
            exact
            path="/painting/deck/type-of-finish"
            component={Typeoffinish}
          />
          <Route
            exact
            path="/painting/deck/personal-info/:stepValue"
            component={DeckPersonalInfo}
          />
          <Route
            exact
            path="/painting/deck/summary/:stepValue"
            component={Summarydeck}
          />

          {/* Deck Routes End*/}

          {/* Fence Routes start*/}
          <Route
            exact
            path="/painting/fence/fence-size/:stepValue"
            component={Fencesize}
          />
          <Route
            exact
            path="/painting/fence/type/:stepValue"
            component={Typeoffinishfence}
          />
          <Route
            exact
            path="/painting/fence/personal-info/:stepValue"
            component={FencePersonalInfo}
          />
          <Route
            exact
            path="/painting/fence/summary/:stepValue"
            component={Summaryfence}
          />

          {/* Fence Routes End*/}
          <Route
            exact
            path="/painting/personal-info"
            component={Personalinfo}
          />
          <Route
            exact
            path="/create-profile/services/:stepValue"
            component={Stepdescription}
          />

          <Route exact path="/owner/my-profile" component={MyProfile} />
          <Route
            exact
            path="/owner/my-profile/personal-information"
            component={OwnerProfile}
          />
          <Route exact path="/user/edit-profile" component={EditOwnerProfile} />
          <Route
            exact
            path="/user/edit-address/:id"
            component={AddEditAddress}
          />
          <Route exact path="/user/add-address" component={AddEditAddress} />
          {/* <Route exact path="/user/add-card" component={UserAddEditCard} /> */}
          <Route
            exact
            path="/contractor-profile"
            component={Profileconfirmation}
          />
          <Route
            exact
            path="/createProfile/profile-info/:stepValue"
            component={ContractorProfileInfo}
          />
          <Route
            exact
            path="/createProfile/profile-complete"
            component={Profilecomplete}
          />
          <Route exact path="/painting/other/letstalk" component={Letstalk} />
          <Route exact path="/drawer" component={Homedrawer} />
          <Route
            exact
            path="/kitchen/type-of-work/:stepValue"
            component={Kitchentypeofwork}
          />
          <Route
            exact
            path="/kitchen/requirement/:stepValue"
            component={KitchenRequirement}
          />
          <Route
            exact
            path="/kitchen/cabinets/:stepValue"
            component={KitchenCabinets}
          />
          <Route
            exact
            path="/kitchen/size/:stepValue"
            component={KitchenSize}
          />
          <Route
            exact
            path="/kitchen/remodeling/:stepValue"
            component={KitchenRemodel}
          />
          <Route
            exact
            path="/kitchen/addons/:stepValue"
            component={Kitchenaddons}
          />
          <Route
            exact
            path="/kitchen/summary/:stepValue"
            component={Kitchensummary}
          />
          <Route exact path="/bathroom" component={Bathroomlanding} />

          {/* bathroom Routes */}
          <Route
            exact
            path="/bathroom/how-many-bathrooms"
            component={NumberOfBathrooms}
          />
          <Route
            exact
            path="/bathroom/:bathroomId/size/:stepValue"
            component={BathroomSize}
          />
          <Route
            exact
            path="/bathroom/:bathroomId/materials/:stepValue"
            component={HaveBathroomMaterials}
          />
          <Route
            exact
            path="/bathroom/:bathroomId/need-demolition/:stepValue"
            component={BathroomNeedDemolition}
          />
          <Route
            exact
            path="/bathroom/:bathroomId/need-build-up-walls/:stepValue"
            component={BathroomNeedBuildupWalls}
          />
          <Route
            exact
            path="/bathroom/:bathroomId/need-shower-door/:stepValue"
            component={BathroomNeedShowerDoor}
          />
          <Route
            exact
            path="/bathroom/:bathroomId/add-ons/:stepValue"
            component={BathroomAddOns}
          />
          <Route
            exact
            path="/bathroom/add-personal-info"
            component={BathroomPersonalInfo}
          />
          <Route
            exact
            path="/bathroom/order-summary/:stepValue"
            component={BathroomSummary}
          />
          {/* End bathroom Routes */}

          {/* Checkout routes */}
          <Route
            exact
            path="/checkout/select-contractor"
            component={SelectContractor}
          />
          <Route
            exact
            path="/checkout/enter-address"
            component={CheckoutAddressDetails}
          />
          <Route
            exact
            path="/checkout/edit-address/:id"
            component={CheckoutEditAddress}
          />
          <Route
            exact
            path="/checkout/edit-card"
            component={CheckoutEditCard}
          />
          <Route
            exact
            path="/checkout/add-address"
            component={CheckoutEditAddress}
          />
          <Route exact path="/checkout/add-card" component={CheckoutEditCard} />
          <Route
            exact
            path="/checkout/payment-method"
            component={PaymentMethod}
          />

          <Route exact path="/checkout/success" component={PaymentSuccess} />

          <Route
            exact
            path="/checkout/order-received/:response"
            component={FormSubmitted}
          />

          {/* End Checkout routes */}
          <Route exact path="/user/my-projects" component={MyProjectList} />
          <Route exact path="/user/view-project/:id" component={ViewProject} />
          <Route
            exact
            path="/contractor/rate-project/:contractorId/:projectId"
            component={RateProject}
          />
          <Route
            exact
            path="/user/rate-project/:userId/:projectId"
            component={Rating}
          />
          <Route exact path="/user/rate-project/:id" component={Rating} />
          <Route
            exact
            path="/user/view-project-details/:id"
            component={ProjectDetails}
          />

          {/* <Route exact path="/user/chatNew" component={GroupChannelTypingIndicatorSample} /> */}
          <Route
            exact
            path="/user/chat"
            component={GroupChannelTypingIndicatorSample}
          />
          <Route exact path="/user/notifications" component={Notifications} />
          {/* User Routes */}

          {/* End User Routes */}

          <Route exact path="/other/letstalk" component={Letstalkother} />
          <Route exact path="/eyeshow" component={Eyeshow} />
          <Route exact path="/edit-date/:projectId" component={EditStartDate} />
          <Route exact path="/tick" component={Tick} />
          <Route
            exact
            path="/contractor-portfolio/:id/confirm"
            component={ConfirmContractor}
          />
          <Route path="/contractor-portfolio/:id" component={Portfolio} />

          <Route path="/contractor-portfolio/:id/*" component={Portfolio} />

          <Route
            exact
            path="/contractor-portfolio-details/:id"
            component={PortfolioDetails}
          />

          <Route exact path="/wrong" component={Wrong} />
          <Route exact path="/exclamation" component={Exclamation} />
          <Route exact path="/terms-and-condition" component={Termscondition} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/wallet" component={Wallet} />
          <Route exact path="/rating" component={Rating} />
          <Route
            exact
            path="/contractor/profile"
            component={ContractorProfile}
          />

          {/* HVAC Routes */}

          <Route
            exact
            path="/hvac/type-of-service"
            component={HvacTypeofService}
          />
          <Route
            exact
            path="/hvac/replace-or-new/:stepValue"
            component={ReplaceOrNewHvac}
          />
          <Route
            exact
            path="/hvac/type-of-installation/:stepValue"
            component={TypeOfInstallation}
          />
          <Route
            exact
            path="/hvac/type-of-unit/:stepValue"
            component={TypeOfHvacUnit}
          />
          <Route
            exact
            path="/hvac/size-details/:stepValue"
            component={HvacSizeDetails}
          />
          <Route
            exact
            path="/hvac/select-brand/:stepValue"
            component={HvacSelectBrand}
          />
          <Route exact path="/hvac/add-ons/:stepValue" component={HvacAddOns} />
          <Route
            exact
            path="/hvac/order-summary/:stepValue"
            component={HvacOrderSummary}
          />
          <Route
            exact
            path="/hvac/replacement-options/:stepValue"
            component={HvacRepairReplacement}
          />
          <Route
            exact
            path="/hvac/agreement/:stepValue"
            component={RepairAgreement}
          />

          {/* Electric */}
          <Route
            exact
            path="/electric/type-of-service"
            component={ElectricTypeofService}
          />
          <Route
            exact
            path="/electric/replace-or-new/:stepValue"
            component={ElectricTypeOfInstallation}
          />
          <Route
            exact
            path="/electric/select-items/:stepValue"
            component={ElectricSelectItems}
          />
          <Route
            exact
            path="/electric/select-install-items/:stepValue"
            component={ElectricSelectInstallItems}
          />
          <Route
            exact
            path="/electric/order-summary/:stepValue"
            component={ElectricOrderSummary}
          />
          <Route
            exact
            path="/electric/comment-details/:stepValue"
            component={ElectricRepairComments}
          />

          {/* End electric */}

          {/* Plumbing routes */}

          <Route
            exact
            path="/plumbing/type-of-service"
            component={PlumbingTypeofService}
          />

          <Route
            exact
            path="/plumbing/how-urgent/:stepValue"
            component={PlumbingHowUrgentService}
          />
          <Route
            exact
            path="/plumbing/comments/:stepValue"
            component={PlumbingEmergencyComments}
          />

          <Route
            exact
            path="/plumbing/select-items/:stepValue"
            component={PlumbingSelectItems}
          />
          <Route
            exact
            path="/plumbing/order-summary/:stepValue"
            component={PlumbingOrderSummary}
          />
          {/* End Plumbing routes */}

          {/* Roofing */}

          <Route
            exact
            path="/roofing/type-of-service"
            component={RoofingTypeofService}
          />
          <Route exact path="/roofing/why-you-need" component={NeedOfRoofing} />
          <Route exact path="/roofing/roofing-size" component={SizeOfRoofing} />
          <Route
            exact
            path="/roofing/number-of-repairs"
            component={NumberOfRepairsNeeded}
          />
          <Route exact path="/roofing/have-own-roof" component={HaveOwnRoof} />
          <Route
            exact
            path="/roofing/roof-Material"
            component={MaterialOfRoof}
          />
          <Route
            exact
            path="/roofing/height-of-roof"
            component={HeightOfRoof}
          />
          <Route exact path="/roofing/pitch-of-roof" component={PitchOfRoof} />
          <Route
            exact
            path="/roofing/removal-plywood"
            component={RemovalOfPlywood}
          />
          <Route
            exact
            path="/roofing/order-summary"
            component={RoofingOrderSummary}
          />

          {/* End Roofing */}

          {/* Windows */}

          <Route
            exact
            path="/windows/type-of-service"
            component={WindowTypeofService}
          />
          <Route exact path="/windows/why-you-need" component={NeedOfWindows} />
          <Route
            exact
            path="/windows/have-own-windows"
            component={HaveWindows}
          />
          <Route
            exact
            path="/windows/select-type-quantity"
            component={WindowQuantity}
          />
          <Route exact path="/windows/add-ons" component={WindowAddons} />
          <Route
            exact
            path="/windows/order-summary"
            component={WindowsOrderSummary}
          />
          {/* End Windows */}

          {/* Trees */}

          <Route
            exact
            path="/tree-service/service-type"
            component={TreeTypeOfService}
          />
          <Route exact path="/tree-service/complexity" component={Complexity} />
          <Route
            exact
            path="/tree-service/height-details"
            component={TreeHeightDetails}
          />
          <Route
            exact
            path="/tree-service/debris-haul-away"
            component={DebrisHaulAway}
          />
          <Route
            exact
            path="/tree-service/electric-nearby-tree-details"
            component={TreeDetailsNearElectric}
          />
          <Route
            exact
            path="/tree-service/electric-not-nearby-tree-details"
            component={TreeDetailsNotNearElectrical}
          />

          <Route
            exact
            path="/tree-service/tree-selection"
            component={TreeSelection}
          />
          <Route
            exact
            path="/tree-service/roots-removal"
            component={RootsRemoval}
          />
          <Route
            exact
            path="/tree-service/machinery-details"
            component={RootsRemovalMachine}
          />
          <Route
            exact
            path="/tree-service/stump-removal"
            component={TreeStumpRemoval}
          />
          <Route
            exact
            path="/tree-service/order-summary"
            component={TreeOrderSummary}
          />
          {/* End Trees */}

          {/* Contractor profile */}

          <Route exact path="/contractor-projects" component={ContractorView} />

          <Route
            exact
            path="/contractor/:contractorId/project/:projectId/tentative-date"
            component={TentativeDate}
          />
          <Route
            exact
            path="/assistant-project"
            component={AssistantProjects}
          />

          <Route
            exact
            path="/contractor-project-preview"
            component={ContractorViewProject}
          />
          <Route exact path="/delete-account" component={DeleteAccount} />
        </Switch>
      </ScrollAction>
    </Router>
  );
}
export default App;
