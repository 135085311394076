import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import CheckBox from "../../_components/CheckBox/checkBox";
import { Button } from "../../_components/Button/Button";

import { checkoutStore, kitchenStore } from "../../_redux/actions";
import { kitchenModel } from "../../_redux/Models/Kitchen.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { DropDown } from "../../_components/dropDown/dropDown";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
export const Kitchenaddons = (props) => {
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const { kitchenPayload } = useSelector((state) => state.buttonReducer);
  const isDisabled = (event) => event.some((e) => e.isChecked);
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const kitchenModelState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.kitchenPayload
      ? state.buttonReducer.kitchenPayload
      : kitchenModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [price, setPrice] = useState(
    calculateTotalCost(kitchenModelState.costFor)
  ); // To keep the total price in state
  const [addOns, setAddOns] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails && priceDetails.addOns) {
      const size = kitchenModelState.size;
      const optionsArr = priceDetails.addOns.map((op) => {
        if (
          op.key !== "tile_blacksplash_labor" &&
          op.key !== "tile_blacksplash_labor_and_materials"
        ) {
          return {
            text: t(`kitchen.${op.key}`),
            value: op.key,
            count: 1,
            isChecked: false,
            price: op.price,
          };
        } else {
          return {
            text: t(`kitchen.${op.key}`),
            value: op.key,
            count: 1,
            isChecked: false,
            price: op.options.find((o) => o.key === size).price,
          };
        }
      });
      setAddOns(optionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, kitchenModelState, t]);

  const onSave = () => {
    kitchenModelState.addOns = addOns.filter((i) => i.isChecked === true);
    kitchenModelState.costFor.addOns =
      price - calculateTotalCost(kitchenModelState.costFor);
    dispatch(kitchenStore(kitchenModelState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/kitchen/summary/${7}`
    );
  };

  function arrowHandler(params, e, index) {
    let updatedState = [...addOns];
    if (params === "plus") {
      updatedState[index].count = updatedState[index].count + 1;
    } else if (updatedState[index].count > 1) {
      updatedState[index].count = updatedState[index].count - 1;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  }

  const calculateTotal = (addOns) => {
    const total = addOns
      .filter((v) => v.isChecked === true)
      .reduce((p, c) => p + c.price * c.count, 0);
    setPrice(calculateTotalCost(kitchenModelState.costFor) + total);
  };

  const toggleCheck = (event, index) => {
    let updatedState = [...addOns];
    if (!updatedState[index].isChecked) {
      updatedState[index].isChecked = event;
    } else {
      updatedState[index].isChecked = !event;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  const onAddOnHandler = (event, index) => {
    let updatedState = [...addOns];
    updatedState[index].count = parseInt(event.target.value);
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("kitchen.addon")}</label>
        </div>
        <div className="paint_flex_with_left">
          <label className="paint_other_sub_header ">
            {t("kitchen.addser")}
          </label>
        </div>
        <div className="padding10"></div>
        {addOns &&
          addOns !== undefined &&
          addOns.map((item, index) => (
            <>
              <div
                className={`floor-selection-radio ${
                  item?.isChecked ? "selected" : ""
                }`}
                onClick={() => toggleCheck(true, index)}
              >
                <div
                  key={index}
                  className="width100 "
                  style={{ justifyContent: "space-between" }}
                >
                  <table>
                    <tr>
                      <td>
                        <label className="paintdiv_txt"> {item.text} </label>
                      </td>
                      <td>
                        <div
                          className="paint_arrowFloat flex"
                          style={{ paddingTop: "10px" }}
                        >
                          <img
                            src={IMAGES.other.green_plus}
                            className="kitchenplusimg"
                            alt=""
                          />
                          <label
                            className="paintdiv_txt paddingleft8"
                            style={{ paddingLeft: "7px", paddingTop: "2px" }}
                          >
                            {`$${formatPrice(item.count * item.price)}`}
                          </label>
                          <div
                            className="paint_arrowFloat"
                            style={{ paddingLeft: "7px" }}
                          >
                            <CheckBox checked={item.isChecked} readOnly />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              {item.isChecked ? (
                <div className="paintPadding8 mb-4">
                  <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
                    <table className="paint_table100">
                      <tr>
                        <td>
                          {" "}
                          <div
                            className="typeofTxt"
                            style={{ display: "grid", paddingTop: "0" }}
                          >
                            <DropDown
                              value={item.count}
                              callback={(event) => onAddOnHandler(event, index)}
                              length={100}
                            />

                            {/* {userState.number} */}
                          </div>
                          {/* <label className="paintdiv_txt">
      </label>  */}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ))}
        <div className="profilePadding8"></div>
        <span>* This price only includes labor</span>
        <div className="profilePadding8"></div>

        <Button
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => onSave()}
        />
      </div>
    </div>
  );
};
