import { chatEndPoints } from "../apiConstants";
import axiosInstance from "./config/api.config";

const createChannel = async (data) => {
  try {
    const response = await axiosInstance.post(
      `${chatEndPoints.createChannel}`,
      data
    );
    return response;
  } catch (error) {
    alert("Something went wrong");
    return error;
  }
};

const generateToken = async (id) => {
  try {
    const response = await axiosInstance.get(
      `chat/${id}${chatEndPoints.generatorToken}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export { createChannel, generateToken };
