import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorSolidHardwoodModel } from "../../../_redux/Models/Floor.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const SHWoodDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const [finishedOrUnfinished, setFinishedOrUnfinished] = useState("");
  const [typeOfWood, setTypeOfWood] = useState("");
  const [sizeOfWood, setSizeOfWood] = useState({
    key: "",
    price: 0,
    text: "",
  });
  const [
    needContractorFinishSolidHardwood,
    setNeedContractorFinishSolidHardwood,
  ] = useState({ price: 0, value: "" });

  // Pricing from APis
  const [woodPricing, setWoodPricing] = useState([]);
  const [woodSizePricing, setWoodSizePricing] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [finishOptions, setFinishOptions] = useState([]);
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorSolidHardwoodModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    let size = parseInt(state.floorSize);
    if (mounted === true && priceDetails) {
      const woodPricingOptions = priceDetails.wood.map((op) => {
        return {
          text: t(`solidHardwood.${op.key}`),
          key: op.key,
          price: op.price * size,
        };
      });
      setWoodPricing(woodPricingOptions);
      const woodSizePricingOptions = priceDetails.woodSize.map((op) => {
        return {
          text: t(`solidHardwood.${op.key}`),
          key: op.key,
          price: op.price * size,
        };
      });
      setWoodSizePricing(woodSizePricingOptions);

      const finishOptions = [
        {
          text: t("solidHardwood.yesPleaseFinish"),
          value: "yes",
          price:
            priceDetails.finishingService.find(
              (op) => op.key === "add_finishing_service"
            ).price * size,
        },
        {
          text: t("solidHardwood.noNoNeed"),
          value: "no",
          price: 0,
        },
      ];

      setFinishOptions(finishOptions);
    }
    return () => (mounted = false);
  }, [checkoutModelState, state.floorSize, t]);

  // To show carpet service questions
  const youLikeOption = [
    {
      text: t("solidHardwood.unfinished"),
      text2: t("solidHardwood.unfinishedStainedCoat"),
      value: "unfinished",
    },
    {
      text: t("solidHardwood.finished"),
      text2: t("solidHardwood.pre_stainedCoat"),
      value: "finished",
    },
  ];

  // To show padding options

  // To show padding options
  const woodOptions = [
    {
      text: t("solidHardwood.whiteOak"),
      value: "white_oak",
      options: woodPricing.filter((wd) => wd.key.includes("white")),
      image: IMAGES.home.lightwood,
    },
    {
      text: t("solidHardwood.redOak"),
      value: "red_oak",
      options: woodPricing.filter((wd) => wd.key.includes("read")),
      image: IMAGES.home.redoak,
    },
  ];

  const calculateTotalPrice = (woodPrice, finishingPrice) => {
    woodPrice = woodPrice ? woodPrice : sizeOfWood.price;
    finishingPrice = finishingPrice ? finishingPrice : 0;
    const total = calculateTotalCost(state.costFor);
    setPrice(total + woodPrice + finishingPrice);
  };

  /**
   * Function to save Selected Service Type and take user to next step
   * @returns {void}
   */
  const onSaveServiceType = () => {
    state.finishedOrUnfinished = finishedOrUnfinished;
    state.costFor.materialSize = sizeOfWood.price;
    state.costFor.finishing = needContractorFinishSolidHardwood.price;
    state.typeOfWood = typeOfWood;
    state.sizeOfWood = sizeOfWood;
    state.needContractorFinishSolidHardwood = needContractorFinishSolidHardwood;
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(floorStore(state));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/solid-hardwood/type-of-installation/5`
    );
  };

  return (
    <div className="insideWrp">
      {/* <CircularProgressBar/> */}
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div
            className="paint_flex_with_left"
            style={{ paddingBottom: "15px" }}
          >
            <label className="paint_header">
              {t("solidHardwood.youLikeFinishedOrUnfinishedSH")}
            </label>
          </div>
          {youLikeOption.map((item, index) => (
            <label
              key={index}
              className={`floor-selection-radio d-flex w-100 ${
                finishedOrUnfinished === item?.value ? "selected" : ""
              }`}
              style={{
                padding: "10px 30px",
                minHeight: "auto",
                marginBottom: "15px",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
              onClick={() => {
                if (item.value === "finished") {
                  setSizeOptions(woodSizePricing);
                }
                setFinishedOrUnfinished(item.value);
              }}
            >
              {item.text}
              <p
                style={{
                  display: "flex",
                  textAlign: "left",
                  fontSize: "12px",
                  margin: "0",
                  color: "#858585",
                }}
              >
                {item.text2}
              </p>{" "}
            </label>
          ))}

          {finishedOrUnfinished === "unfinished" && (
            <div>
              <div className="paint_flex_with_left padding30">
                <label className="paint_header">
                  {t("solidHardwood.typeOfWood")}
                </label>
              </div>

              <div className="padding10"></div>
              {woodOptions.map((item, index) => (
                <div
                  className={`floor-selection-radio ${
                    typeOfWood === item.value ? "selected" : ""
                  }`}
                  style={{
                    padding: "0",
                  }}
                  onClick={() => {
                    setTypeOfWood(item.value);
                    setSizeOptions(item.options);
                  }}
                >
                  <div
                    key={index}
                    className="width100 flex"
                    style={{
                      alignItems: "center",
                      minHeight: "75px",
                      position: "relative",
                    }}
                  >
                    <img
                      style={{
                        width: "60px",
                        float: "left",
                        position: "absolute",
                        left: "0",
                        top: "0",
                        height: "100%",
                      }}
                      src={item.image}
                      alt={item.text}
                    />
                    <label
                      className="paintdiv_txt"
                      style={{ paddingLeft: "80px" }}
                    >
                      {" "}
                      {item.text}{" "}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          )}

          {(finishedOrUnfinished === "finished" || typeOfWood !== "") && (
            <div>
              <div className="paint_flex_with_left mt-4">
                <label className="paint_header mb-3">
                  {t("solidHardwood.selectSizeDetails")}
                </label>
              </div>

              {sizeOptions.map((item, index) => (
                <div key={index} className="">
                  <div
                    className={`floor-selection-radio d-flex w-100 ${
                      sizeOfWood?.key === item?.key ? "selected" : ""
                    }`}
                    style={{
                      marginBottom: "15px",
                      height: "60px",
                      minHeight: "auto",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setSizeOfWood(item);
                      calculateTotalPrice(item.price);
                    }}
                  >
                    <div
                      key={index}
                      className="width100"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        className="paintdiv_txt"
                        style={{
                          width: "200px",
                        }}
                      >
                        {item.text}
                      </label>
                      <div
                        className="paint_arrowFloat"
                        style={{
                          width: "130px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img src={IMAGES.other.green_plus} alt="" />
                          <label className="paintdiv_txt">
                            {`$${formatPrice(item.price)}`}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {finishedOrUnfinished === "unfinished" && sizeOfWood !== "" && (
            <div>
              <div className="paint_flex_with_left padding30">
                <label className="paint_header">
                  {t("solidHardwood.wouldYouLikeContractorToFinish")}
                </label>
              </div>

              <div className="padding10"></div>
              {finishOptions.map((item, index) => (
                <div key={index} className="paintPadding8 ">
                  <div
                    className={`floor-selection-radio d-flex w-100 ${
                      needContractorFinishSolidHardwood?.value === item?.value
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      setNeedContractorFinishSolidHardwood(item);
                      calculateTotalPrice(sizeOfWood.price, item.price);
                    }}
                  >
                    <div
                      key={index}
                      className="width100"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="paintdiv_txt pc_txt_150">
                        {item.text}
                      </label>
                      <div
                        className="paint_arrowFloat"
                        style={{
                          width: "130px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img src={IMAGES.other.green_plus} alt="" />
                          <label className="paintdiv_txt">
                            {`$${formatPrice(item.price)}`}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="profilePadding40"></div>
        <Button
          disabled={
            finishedOrUnfinished === "" ||
            (finishedOrUnfinished === "unfinished" && typeOfWood === "") ||
            (finishedOrUnfinished === "unfinished" &&
              needContractorFinishSolidHardwood === false) ||
            (finishedOrUnfinished && sizeOfWood === "")
          }
          buttonLabel={t("paintingHomes.continue")}
          onClick={onSaveServiceType}
        />
      </div>
    </div>
  );
};
