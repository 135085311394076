import React, { useState } from "react";
import Calendar from "react-calendar";
import "../../../_styles/localStyles/calendar.css";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import moment from "moment";
import IMAGES from "../../../_assets/images";
import "./assistantProject.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateTenatativeDetails } from "../../../_redux/actions";
import { Footer } from "../../../_components/footer/Footer";
import { updateProject } from "../../../services/api/contractor.service";

const TentativeDate = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const tentativeDetails = useSelector(
    (state) => state.contractor.tentativeDetails
  );
  const [tentative, setTentative] = useState({
    startDateType: "text",
    endDateType: "text",
    startDate: tentativeDetails.startDate,
    endDate: tentativeDetails.endDate,
    time: {},
  });
  const items = [
    {
      text: "Early morning",
      time: "7am - 9am",
    },
    {
      text: "Late morning",
      time: "9am - 11am",
    },
    {
      text: "Noon",
      time: "12pm - 3pm",
    },
    {
      text: "Afternoon",
      time: "3pm - 6pm",
    },
  ];

  const [calender, toggleCalender] = useState({
    start: false,
    end: false,
  });

  const changeHandler = (e) => {
    setTentative({
      ...tentative,
      [e.target.name]: e.target.value,
    });
  };

  const saveDates = () => {
    if (tentative.startDate && tentative.endDate) {
      const contractorId =
        props.match && props.match.params
          ? props.match.params.contractorId
          : null;
      const projectId =
        props.match && props.match.params ? props.match.params.projectId : null;
      const reqBody = {
        tentativeStartDate: tentative.startDate,
        tentativeTimeRange: tentative.time.time,
        tentativeEndDate: tentative.endDate,
      };
      if (projectId && contractorId) {
        updateProject(contractorId, projectId, reqBody).then((response) => {});
      }

      dispatch(
        updateTenatativeDetails({
          startDate: tentative.startDate,
          endDate: tentative.endDate,
          time: tentative.time,
        })
      );
      history.push("/contractor-project-preview");
    }
  };
  return (
    <div className="paint-home-section insideWrp tentative-details">
      <HeaderMenu />
      <div className="pcWidth" style={{ margin: "10px 0 0 0" }}>
        <span className="assistant-project-title">Tentative start date</span>
        <div className="assistant-project-inputs">
          <label
            htmlFor="project-name"
            className="assistant-project-title project-name"
          >
            Start date
          </label>
          <input
            type="text"
            id="project-name"
            placeholder="Jan 20th,2022"
            value={
              tentative.startDate
                ? moment(tentative.startDate).format("MM-DD-YYYY")
                : ""
            }
            name="startDate"
            onFocus={() => {
              setTentative({ ...tentative, startDateType: "date" });
              toggleCalender({
                start: true,
                end: false,
              });
            }}
          />
        </div>
        <div className="assistant-project-inputs">
          <label
            htmlFor="project-name"
            className="assistant-project-title project-name"
          >
            End date
          </label>
          <input
            type="text"
            disabled={tentative.startDate ? false : true}
            id="project-name"
            placeholder="Jan 20th,2022"
            value={
              tentative.endDate
                ? moment(tentative.endDate).format("MM-DD-YYYY")
                : ""
            }
            name="endDate"
            onFocus={() => {
              setTentative({ ...tentative, endDateType: "date" });
              toggleCalender({
                start: false,
                end: true,
              });
            }}
          />
        </div>
        <div className="mobile-align-flex-centre">
          {calender.start && (
            <Calendar
              open={calender.start}
              value={tentative.startDate && new Date(tentative.startDate)}
              onChange={(val) => {
                setTentative({
                  ...tentative,
                  startDate: new Date(val),
                });
                toggleCalender({
                  start: false,
                  end: false,
                });
              }}
            />
          )}
          {calender.end && (
            <Calendar
              open={calender.end}
              value={tentative.startDate && new Date(tentative.startDate)}
              onChange={(val) => {
                setTentative({
                  ...tentative,
                  endDate: new Date(val),
                });
                toggleCalender({
                  end: false,
                  start: false,
                });
              }}
              minDate={new Date(tentative.startDate)}
            />
          )}
        </div>

        <div className="m-t-32 mobile-align-flex-centre">
          {items.map((item, index) => (
            <label
              key={index}
              className="floor-selection-radio tentative-radio-buttons"
              // onClick={() => console.log(item.time)}
            >
              <img
                className="sun-image"
                src={
                  index < 1
                    ? IMAGES.home.sun
                    : index < 2
                    ? IMAGES.home.sun2
                    : index < 3
                    ? IMAGES.home.earlyNoon
                    : IMAGES.home.lateNoon
                }
                alt=""
              />
              <div className="wish-text">
                <span>{item.text}</span>
                <span className="wish-time">{item.time}</span>
              </div>
              <input
                type="radio"
                name="time"
                value={tentative.time}
                onChange={(e) =>
                  setTentative({
                    ...tentative,
                    time: item,
                  })
                }
              />
            </label>
          ))}
        </div>
        <div className="mobile-align-flex-centre">
          <button
            className={`project-save-changes ${
              tentative.startDate && tentative.endDate
                ? "project-primary"
                : "project-primary-disable"
            }`}
            onClick={saveDates}
          >
            Save changes
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TentativeDate;
