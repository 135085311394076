import React, { useState, useLayoutEffect, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { kitchenModel } from "../../_redux/Models/Kitchen.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, kitchenStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { getServiceCategoriesFull } from "../../services/api/category.api.service";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
export const Kitchentypeofwork = (props) => {
  const [modeling, setModeling] = useState(false);

  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const saveType = (value) => {
    kitchenModel.serviceType = "Remodelling";
    dispatch(kitchenStore(kitchenModel));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/kitchen/size/${2}`
    );
  };

  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  useEffect(() => {
    let mounted = true;
    getServiceCategoriesFull().then((items) => {
      if (mounted && items.status === 200) {
        let filteredItem = items.data.filter((item) => item.name === "Kitchen");
        filteredItem = filteredItem ? filteredItem[0] : null;
        checkoutModelAs.selectedServiceDetails = filteredItem.subCategory[0];
        checkoutModelAs.selectedService = "Kitchen Remodeling";
        checkoutModelAs.totalCost = 0;
        dispatch(checkoutStore(checkoutModelAs));
      }
    });
    return () => (mounted = false);
  }, [checkoutModelAs, dispatch]);

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("kitchen.selecttype")}</label>
        </div>
        <div
          onClick={() => {
            setModeling(true);
            saveType(true);
          }}
          className={`floor-selection-radio mt-4 ${
            modeling === true ? "selected" : ""
          }`}
        >
          <label className="paintdiv_txt">{t("kitchen.remodeling")}</label>
        </div>
      </div>
    </div>
  );
};
