import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import RadioButton from "../../_components/radioButton/radioButton";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { kitchenModel } from "../../_redux/Models/Kitchen.model";
import { checkoutStore, kitchenStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { CannotContinueModal } from "../floorComponents/Common/CannotContinue.modal";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";
export const KitchenRemodel = (props) => {
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 1;

  const { t } = useTranslation();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);
  const dispatch = useDispatch();

  const kitchenModelState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.kitchenPayload
      ? state.buttonReducer.kitchenPayload
      : kitchenModel
  );

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const [price, setPrice] = useState(
    calculateTotalCost(kitchenModelState.costFor)
  ); // To keep the total price in state
  const [counterTopOptions, setCounterTopOptions] = useState([]);
  const [crownOptions, setCrownOptions] = useState([]);
  const [counterTop, setCounterTop] = useState({ value: 0, price: 0 });
  const [shape, setShape] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [crownMold, setCrownMold] = useState({ value: 0, price: 0 });

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;

    if (mounted === true && priceDetails && priceDetails.counterTops) {
      const size = kitchenModelState.size;
      const optionsArr = priceDetails.counterTops.map((op) => {
        return {
          text: t(`kitchen.${op.key}`),
          value: op.key,
          price: op.options.find((o) => o.key === size).price,
        };
      });
      setCounterTopOptions(
        optionsArr.concat([
          {
            text: t(`kitchen.need`),
            value: "No need",
            price: 0,
          },
        ])
      );
      const crownOptionsArr = [
        {
          text: t(`kitchen.y`),
          value: "Yes",
          price: priceDetails.crownMolding.options.find((o) => o.key === size)
            .price,
        },
        {
          text: t(`kitchen.n`),
          value: "No",
          price: 0,
        },
      ];
      setCrownOptions(crownOptionsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, kitchenModelState, t]);

  const onSave = () => {
    kitchenModelState.counterTop = counterTop;
    kitchenModelState.shape = shape;
    kitchenModelState.crownMolding = crownMold;
    kitchenModelState.costFor.counterTop = counterTop.price;
    kitchenModelState.costFor.crownMolding = crownMold.price;
    dispatch(kitchenStore(kitchenModelState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/kitchen/addons/${6}`
    );
  };

  const shapeOptions = [
    {
      text: t("kitchen.shapel"),
      value: "L shape",
    },
    {
      text: t("kitchen.shapec"),
      value: "U shape",
    },
    {
      text: t("kitchen.shapeG"),
      value: "G shape",
    },
    {
      text: t("kitchen.centreShape"),
      value: "Centre island",
    },
    {
      text: t("kitchen.galleryShape"),
      value: "Gallery",
    },
    {
      text: t("kitchen.singleShape"),
      value: "Single Wall",
    },
  ];
  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left paddinngtop40">
          <label className="paint_header">{t("kitchen.tops")}</label>
        </div>
        <div className="paint_flex_with_left paddinngtop20">
          <label className="paint_other_sub_header ">{t("kitchen.add")}</label>
        </div>
        <div className="padding10"></div>
        {counterTopOptions.map((item, index) => (
          <div
            className={`floor-selection-radio ${
              counterTop?.value === item?.value ? "selected" : ""
            }`}
            onClick={() => {
              setCounterTop(item);
              const cost = calculateTotalCost(kitchenModelState.costFor);
              const crownMoldPrice =
                crownMold.value !== 0 ? crownMold.price : 0;
              const total = cost + item.price + crownMoldPrice;
              setPrice(total);
            }}
          >
            <div
              key={index}
              className="width100 "
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <label
                className="paintdiv_txt"
                style={{ width: "calc(100% - 130px)" }}
              >
                {item.text}
              </label>
              <div
                className="paint_arrowFloat "
                style={{
                  width: "130px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img src={IMAGES.other.green_plus} alt="" />
                  <label className="paintdiv_txt">
                    {`$${formatPrice(item.price)}`}
                  </label>
                </div>
                <div
                  className="paint_arrowFloat"
                  style={{ paddingLeft: "7px" }}
                ></div>
              </div>
            </div>
          </div>
        ))}
        <div className="paint_flex_with_left paddinngtop30">
          <label className="paint_header">{t("kitchen.shape")}</label>
        </div>
        <div className="paddinngtop20"></div>
        {shapeOptions.map((item, index) => (
          <div
            className={`floor-selection-radio ${
              shape === item?.value ? "selected" : ""
            }`}
            onClick={() => setShape(item.value)}
          >
            <div
              key={index}
              className="width100 "
              style={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <label className="paintdiv_txt">
                {" "}
                {item.text}{" "}
                <span
                  onClick={() => setShowModal(true)}
                  className="otherspadding15"
                >
                  <strong>
                    <span className="info-i">i</span>
                  </strong>
                </span>
              </label>
              <div
                className="paint_arrowFloat "
                style={{
                  width: "130px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img src={IMAGES.other.green_plus} alt="" />
                  <label className="paintdiv_txt">{`$0.00`}</label>
                </div>
                <div
                  className="paint_arrowFloat"
                  style={{ paddingLeft: "7px" }}
                ></div>
              </div>
            </div>
          </div>
        ))}
        <div className="paint_flex_with_left paddinngtop30">
          <label className="paint_header">{t("kitchen.molding")}</label>
        </div>
        <div className="paddinngtop20"></div>
        {crownOptions.map((item, index) => (
          <div
            className={`floor-selection-radio ${
              crownMold?.value === item?.value ? "selected" : ""
            }`}
            onClick={() => {
              setCrownMold(item);
              const cost = calculateTotalCost(kitchenModelState.costFor);
              const counterTopPrice =
                counterTop.value !== 0 ? counterTop.price : 0;
              const total = cost + item.price + counterTopPrice;
              setPrice(total);
            }}
          >
            <div
              key={index}
              className="width100 "
              style={{ justifyContent: "space-between" }}
            >
              <label
                className="paintdiv_txt"
                style={{ width: "calc(100% - 130px)" }}
              >
                {item.text}
              </label>

              <div
                className="paint_arrowFloat"
                style={{
                  width: "130px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img src={IMAGES.other.green_plus} alt="" />
                  <label className="paintdiv_txt">
                    {`$${formatPrice(item.price)}`}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Button
          disabled={!counterTop.value || !shape || !crownMold.value}
          buttonLabel={t("paintingHomes.continue")}
          onClick={onSave}
        />
      </div>
      {CannotContinueModal({
        isOpen: showModal,
        contentClassName: "KitchenModelClass",
        hideContractorIcon: false,
        reason: (
          <img
            // style={{
            //   width: 215,
            //   height: 215,
            //   margin: "25px 60px 0",
            //   objectFit: "contain",
            // }}
            className="shapes-image kitchn-popupWrp"
            src={IMAGES.other.kitchenLCShapeInfo}
            alt=""
          />
        ),
        onCloseAction: () => setShowModal(false),
        onNoClick: () => setShowModal(false),
      })}
    </div>
  );
};
