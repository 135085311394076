import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { persistStore } from "redux-persist";

/* Logger only for Development*/
const logger = createLogger();
let middleware = [];

if (process.env.NODE_ENV === "development") {
  middleware = [...middleware, thunkMiddleware, logger];
} else {
  middleware = [...middleware, thunkMiddleware];
}
export const store = createStore(rootReducer, applyMiddleware(...middleware));

export const persistor = persistStore(store);

const storeAs = { store, persistor };
export default storeAs;
