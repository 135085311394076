import IMAGES from "../../_assets/images";

export const getSolidHardwoodOrderSummary = (floorState) => {
  var currentFloorArr = floorState.currentFloor ? floorState.currentFloor : [];
  let summary = [
    {
      heading: "Floor size",
      text: `${floorState.floorSize} sqft`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.size,
    },
    {
      heading: "Solid hardwood finished?",
      text: `${floorState.finishedOrUnfinished}`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading:
        "Do you have your own materials or need the contractor to provide?",
      text: `${
        floorState.haveSolidHardwood
          ? "I have my own materials"
          : "Please provide materials"
      }`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Do you need the contractor to do finishing?",
      text: `${
        floorState.needContractorFinishSolidHardwood.text
          ? floorState.needContractorFinishSolidHardwood.text
          : "NA"
      }`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.finishing,
    },
    {
      heading: "Size of the wood",
      text: `${
        floorState.sizeOfWood && floorState.sizeOfWood.text
          ? floorState.sizeOfWood.text
          : "NA"
      }`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.materialSize,
    },
    {
      heading: "Type of wood",
      text: `${floorState.typeOfWood.replace("_", " ")}`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Type of installation",
      text: `${floorState.typeOfInstallation.replace("_", " ")}`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.typeOfInstallation,
    },
    {
      heading: "Current floor removal",
      text: currentFloorArr.map((t) => {
        if (t.val > 0) {
          return (
            <span className="pc_txt_150">
              {t.key}
              {["glue", "nail"].includes(t.key) && " down"} : {t.val} sqft
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: floorState.costFor.floorRemoval,
    },
    {
      heading: "Add on´s",
      text: floorState.addOns.map((add) => (
        <span>
          {add.text}
          <br />
        </span>
      )),
      //text: `${floorPayload.addOns.toString()}`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.addOns,
    },
    {
      heading: "Elevator",
      text: `${floorState.needToUseElevator ? "Yes" : "No"}`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.elevator,
    },
  ];

  if (floorState.serviceType === "refinishing") {
    summary = [
      {
        heading: "Floor size",
        text: `${floorState.floorSize} sqft`,
        img: IMAGES.other.green_plus,
        price: floorState.costFor.size,
      },
    ];
  }
  return summary;
};

export const getEngineeredOrderSummary = (floorState) => {
  var currentFloorArr = floorState.currentFloor ? floorState.currentFloor : [];
  const summary = [
    {
      heading: "Floor size",
      text: `${floorState.floorSize} sqft`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.size,
    },
    {
      heading: "Material grade",
      text: floorState.materialQuality
        ? floorState.materialQuality.replace("_", " ")
        : "NA",
      img: IMAGES.other.green_plus,
      price: floorState.costFor.materialQuality,
    },
    {
      heading: "Current floor removal",
      text: currentFloorArr.map((t) => {
        if (t.val > 0) {
          return (
            <span>
              {t.key} : {t.val}
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: floorState.costFor.floorRemoval,
    },
    {
      heading: "Add on´s",
      text: floorState.addOns.map((add) => (
        <span>
          {add.text}
          <br />
        </span>
      )),
      //text: `${floorPayload.addOns.toString()}`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.addOns,
    },
    {
      heading: "Elevator",
      text: `${floorState.needToUseElevator ? "Yes" : "No"}`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.elevator,
    },
  ];

  return summary;
};

export const getWallOpeningOrderSummary = (payload) => {
  const summary = [
    {
      heading: "wallOpening.openingSize",
      text: `${payload.size} lnft`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.linear,
    },
    {
      heading: "wallOpening.height",
      text: `${payload.height} lnft`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.height,
    },
    {
      heading: "wallOpening.isItLoadBearingWall",
      text: `${payload.isItLoadBearingWall}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.loadBearing,
    },

    {
      heading: "wallOpening.hasBasement",
      text: `${payload.hasBasement}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.basementIncrement,
    },
    {
      heading: "wallOpening.openingType",
      text: `${payload.openingType}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.openingIncrement,
    },
    {
      heading: "Add on´s",
      text: payload.addOns.map((addOn) => {
        const add = addOn.text;
        if (add !== "") {
          return (
            <span key={Math.random()}>
              {add}
              <br />
            </span>
          );
        } else {
          return false;
        }
      }),
      img: IMAGES.other.green_plus,
      price: payload.costFor.addOns,
    },
  ];

  return summary;
};

export const getKitchenOrderSummary = (kitchenModelState) => {
  const summary = [
    {
      heading: "kitchen.size",
      text: `${
        kitchenModelState.size === "small"
          ? "Small (up to 70ft)"
          : kitchenModelState.size === "medium"
          ? "Medium (up to 140ft)"
          : "Large (more than 140ft)"
      }`,
      img: IMAGES.other.green_plus,
      price: kitchenModelState.costFor.size,
    },
    {
      heading: "kitchen.demolitionRequired",
      text: `${kitchenModelState.demolitionRequired.value}`,
      img: IMAGES.other.green_plus,
      price: kitchenModelState.costFor.demolition,
    },
    {
      heading: "kitchen.typeOfCabinets",
      text: `${kitchenModelState.typeOfCabinet}`,
      img: IMAGES.other.green_plus,
      price: kitchenModelState.costFor.cabinet,
    },

    {
      heading: "kitchen.counterTops",
      text: `${kitchenModelState.counterTop.text}`,
      img: IMAGES.other.green_plus,
      price: kitchenModelState.costFor.counterTop,
    },
    {
      heading: "kitchen.counterTopShape",
      text: `${kitchenModelState.shape}`,
      img: IMAGES.other.green_plus,
      price: 0.0,
    },
    {
      heading: "kitchen.crownMolding",
      text: `${kitchenModelState.crownMolding.value}`,
      img: IMAGES.other.green_plus,
      price: kitchenModelState.costFor.crownMolding,
    },
    {
      heading: "Add on´s",
      text: kitchenModelState.addOns.map((addOn) => {
        const add = addOn.text;
        if (add !== "") {
          return (
            <span>
              {`${add} (${addOn.count})`}
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: kitchenModelState.costFor.addOns,
    },
  ];
  return summary;
};

export const getBasementOrderSummary = (payload, t) => {
  const summary = [
    {
      heading: t("basement.basementSize"),
      text: `${payload.basementSize} sqft`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.size,
    },
    {
      heading: t("basement.height"),
      text: `${payload.basementHeight.replace("_", " ")}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.height,
    },
    {
      heading: t("basement.requiresPlumbing"),
      text:
        payload.requirePlumbing === "requires_kitchen_only_plumbing"
          ? t(" Yes require plumbing for kitchen")
          : payload.requirePlumbing === "requires_bathroom_only_plumbing"
          ? t(" Yes require plumbing for bathroom")
          : payload.requirePlumbing === "requires_kitchen_and_bathroom_plumbing"
          ? t(" Yes require plumbing for kitchen & bathroom")
          : t("No"),
      img: IMAGES.other.green_plus,
      price: payload.costFor.plumbing,
    },

    {
      heading: t("basement.sumpPumpInstallation"),
      text: `${payload.requireSumpPump}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.sumpPump,
    },
    {
      heading: t("basement.electricalInstallation"),
      text: `${payload.requireElectricalInstallation}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.electrical,
    },
    {
      heading: "Add on´s",
      text: payload.addOns.map((addOn) => {
        const add = addOn.text;
        if (add !== "") {
          return (
            <span key={Math.random()}>
              {add}
              <br />
            </span>
          );
        } else {
          return false;
        }
      }),
      img: IMAGES.other.green_plus,
      price: payload.costFor.addOns,
    },
  ];
  return summary;
};

export const getCarpetOrderSummary = (floorState) => {
  var currentFloorArr = floorState.currentFloor ? floorState.currentFloor : [];
  const summary = [
    {
      heading: "Floor size",
      text: `${floorState.floorSize} sqft`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.carpetService,
    },
    {
      heading: "Current floor removal",
      text: currentFloorArr.map((t, i) => {
        if (t.val > 0) {
          return (
            <span key={i}>
              {`${t.key} down`} : {t.val}
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: floorState.costFor.floorRemoval,
    },
    {
      heading: "Provide carpet?",
      text: floorState.needCarpet ? "Yes" : "No",
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Grade of carpet",
      text: floorState.carpetQuality.text,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.carpetGrade,
    },
    {
      heading: "Padding replacement",
      text: floorState.needPaddingReplacement ? "Yes" : "No",
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Grade of padding",
      text: floorState.paddingQuality.replace("-", " ").replace("_", " "),
      img: IMAGES.other.green_plus,
      price: floorState.costFor.padding,
    },
  ];

  return summary;
};

export const getTilesOrderSummary = (floorPayload) => {
  var currentFloorArr = floorPayload.currentFloor
    ? floorPayload.currentFloor
    : [];
  const summary = [
    {
      heading: "Size details",
      text: `${floorPayload.tileSize.replace("_", "” ")}`,
      img: IMAGES.other.green_plus,
      price: floorPayload.costFor.sizeDetails,
    },
    {
      heading: "Current floor",
      text: currentFloorArr.map((t) => {
        if (t.val > 0) {
          return (
            <span>
              {t.key} : {t.val}
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: floorPayload.costFor.floorRemoval,
    },
    {
      heading: "Floor size",
      text: `${floorPayload.floorSize} sqft`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Add on´s",
      text: floorPayload.addOns.map((add) => (
        <span>
          {add.text}
          <br />
        </span>
      )),
      //text: `${floorPayload.addOns.toString()}`,
      img: IMAGES.other.green_plus,
      price: floorPayload.costFor.addOns,
    },
    {
      heading: "Elevator",
      text: `${floorPayload.needToUseElevator ? "Yes" : "No"}`,
      img: IMAGES.other.green_plus,
      price: floorPayload.costFor.elevator,
    },
  ];
  return summary;
};

export const getLaminatedOrderSummary = (floorState, t) => {
  var currentFloorArr = floorState.currentFloor ? floorState.currentFloor : [];
  const summary = [
    {
      heading: "Floor sizing",
      text: `${floorState.floorSize} sqft`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.sizeDetails,
    },
    {
      heading: "Floor sizing",
      text: `${
        floorState.haveOwnLaminate
          ? "I have my own laminate"
          : "Please provide laminate"
      }`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Select material grade",
      text: `${floorState.tileSize.replace("_", " ")}`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.laminated,
    },
    {
      heading: "Current floor",
      text: currentFloorArr.map((tmp) => {
        if (tmp.val > 0) {
          return (
            <span>
              {t(`floor.${tmp.key}`)} : {tmp.val}
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: floorState.costFor.floorRemoval,
    },
    {
      heading: "Elevator",
      text: `${floorState.needToUseElevator ? "Yes" : "No"}`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.elevator,
    },

    {
      heading: "Add on´s",
      text: floorState.addOns.map((add) => (
        <span>
          {add.text}
          <br />
        </span>
      )),
      //text: `${floorPayload.addOns.toString()}`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.addOns,
    },
  ];

  return summary;
};

export const getEpoxyOrderSummary = (floorState, t) => {
  var currentFloorArr = floorState.currentFloor ? floorState.currentFloor : [];
  const summary = [
    {
      heading: "Floor sizing",
      text: `${floorState.floorSize} sqft`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.sizeDetails,
    },
    {
      heading: "Current floor",
      text: currentFloorArr.map((tmp) => {
        if (tmp.val > 0) {
          return (
            <span>
              {t(`floor.${tmp.key}`)} : {tmp.val}
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: floorState.costFor.floorRemoval,
    },
    {
      heading: "Elevator",
      text: `${floorState.needToUseElevator ? "Yes" : "No"}`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.elevator,
    },

    {
      heading: "Add on´s",
      text: floorState.addOns.map((add) => (
        <span>
          {add.text}
          <br />
        </span>
      )),
      //text: `${floorPayload.addOns.toString()}`,
      img: IMAGES.other.green_plus,
      price: floorState.costFor.addOns,
    },
  ];
  return summary;
};

export const getDeckOrderSummary = (payload) => {
  const summary = [
    {
      heading: "Deck size",
      text: `${payload.size} lnft`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.typeOfFinish,
    },
    {
      heading: "Deck cover/roof?",
      text: `${payload.deckCoverORroof}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.deckOrRoof,
    },
  ];
  return summary;
};

export const getFenceOrderSummary = (payload) => {
  const summary = [
    {
      heading: "Fence size",
      text: `${payload.size} lnft`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Type of finish",
      text: `${payload.typeOfFinish}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.typeOfFinish,
    },
  ];
  return summary;
};

export const getElectricOrderSummary = (payload) => {
  let summary = [
    {
      heading: "Install/Repair",
      text: `${payload.serviceType}`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Replacement or new install?",
      text: `${
        payload.existingOrNew === "installation"
          ? "New installation with wiring"
          : payload.serviceType !== "repairs"
          ? "Replacement of existing ones (no  wiring required)"
          : ""
      }`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading:
        payload.serviceType === "repair" ? "Needs replacement" : "Items Needed",
      text: payload.addOns.map((addOn, index) => {
        const add = addOn.text;
        if (add !== "") {
          return (
            <span key={index}>
              {`${add.replaceAll("_", " ")} ${
                addOn.selectedOption
                  ? `(${addOn.selectedOption.key.replaceAll("_", " ")})`
                  : ""
              } (${addOn.count}) `}
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: payload.costFor.addOns,
    },
  ];
  if (payload.serviceType === "diagnose") {
    summary = [
      {
        heading: "Install/Repair",
        text: `${payload.serviceType}`,
        img: IMAGES.other.green_plus,
        price: payload.costFor.diagnose,
      },
    ];
  }
  return summary;
};
export const getPlumbingOrderSummary = (payload) => {
  const summary = [
    {
      heading: "Type of service",
      text: `${payload.serviceType}`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading:
        payload.serviceType === "repair"
          ? "Needs replacement"
          : "What do you need to install?",
      text: payload.addOns.map((addOn, index) => {
        const add = addOn.text;
        if (add !== "") {
          return (
            <span key={index}>
              {`${add.replaceAll("_", " ")} ${
                addOn.selectedOption
                  ? `(${addOn.selectedOption.key.replaceAll("_", " ")})`
                  : ""
              } (${addOn.count}) `}
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: payload.costFor.addOns,
    },
  ];
  if (payload.serviceType === "emergency") {
    summary.push(
      {
        heading: "How urgent is it?",
        text: `${payload.howUrgent}`,
        img: IMAGES.other.green_plus,
        price: payload.costFor.service,
      },
      {
        heading: "Details/comments",
        text: `${payload.emergencyComments}`,
        img: IMAGES.other.green_plus,
        price: 0,
      }
    );
  }
  return summary;
};

export const getHvacOrderSummary = (payload) => {
  const summary = [
    {
      heading: "Type of service",
      text:
        payload.serviceType === "replacement"
          ? "Replacement/Installation"
          : payload.serviceType === "send_someone"
          ? "Send someone to do a diagnose"
          : "Repair",
      img: IMAGES.other.green_plus,
      price:
        payload.serviceType === "send_someone" ? payload.costFor.service : 0,
    },
    {
      heading: "Replacement or new install?",
      text: `${payload.existingOrNew.replaceAll("-", " ")}`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Requires HVAC system?",
      text: `${payload.typeOfInstallation.replaceAll("-", " ")}`,
      img: IMAGES.other.green_plus,
      price: 0,
    },

    {
      heading: "HVAC Tons",
      text: `${payload.existingSystemSize}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.service,
    },
    {
      heading: "HVAC Unit brand",
      text: `${payload.brandNeeded}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.material,
    },
    {
      heading: "Conditions accepted",
      text: `${payload.agreementAccepted}`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading:
        payload.serviceType === "repair" ? "Needs replacement" : "Add on´s",
      text: payload.addOns.map((addOn) => {
        const add = addOn.text;
        if (add !== "") {
          return (
            <span>
              {`${add.replaceAll("_", " ")} (${addOn.count})`}
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: payload.costFor.addOns,
    },
  ];
  return summary;
};

export const getExteriorOrderSummary = (payload) => {
  const summary = [
    {
      heading: "House size",
      text: `${payload.size} sqft`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "House exterior material",
      text: `${payload.houseMaterial.text}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.exteriorMaterial,
    },
    {
      heading: "Requires the paint?",
      text: `${payload.haveOwnPaint ? "No" : "Yes"}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.paint,
    },
    {
      heading: "Paint brand and color",
      text: (
        <div>
          {`${payload.paintDetails.brandName.brand || "N/A"}`}
          <br></br>
          {`${payload.paintDetails.colorName}`}
        </div>
      ),
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Primer coat required?",
      text: `${payload.needPrimerCoat ? "Yes" : "No"}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.primer,
    },
    {
      heading: "Type of paint",
      text: `${payload.paintType.text}`,
      img: IMAGES.other.green_plus,
      price: 0,
    },
    {
      heading: "Number of coats",
      text: `${payload.coats}`,
      img: IMAGES.other.green_plus,
      price: payload.costFor.coat,
    },
    {
      heading: "Add on´s",
      text: payload.addOns.map((addOn) => {
        const add = addOn.text;
        if (add !== "") {
          return (
            <span>
              {`${add} ${addOn.count > 1 ? `(${addOn.count})` : ""}`}
              <br />
            </span>
          );
        } else {
          return null;
        }
      }),
      img: IMAGES.other.green_plus,
      price: payload.costFor.addOns,
    },
  ];

  return summary;
};

export const getBathroomOrderSummary = (state, t) => {
  const bathrooms = state.bathroomsDetails;
  const bathroomSummary = bathrooms.map((bathroom) => {
    return [
      {
        heading: t("Bathroom.size"),
        text: `${
          bathroom.size === "small"
            ? "Small (up to 50 sqft)"
            : bathroom.size === "medium"
            ? "Medium (up to 110 sqft)"
            : bathroom.size === "large"
            ? "Large (up to 140 sqft)"
            : "Larger (more than 140 sqft)"
        }`,
        img: IMAGES.other.green_plus,
        price: bathroom.costFor.size,
      },
      {
        heading: t("Bathroom.hasMaterials"),
        text: `${
          bathroom.haveOwnMaterial ? "Has materials" : "Requires materials"
        }`,
        img: IMAGES.other.green_plus,
        price: bathroom.costFor.material,
      },
      {
        heading: t("Bathroom.requiresDemolition"),
        text: `${bathroom.requireDemolition}`,
        img: IMAGES.other.green_plus,
        price: bathroom.costFor.demolition,
      },
      {
        heading: t("Bathroom.needBuildupWalls"),
        text: `${bathroom.requireBuildUpWalls}`,
        img: IMAGES.other.green_plus,
        price: bathroom.costFor.buildUpWall,
      },
      {
        heading: t("Bathroom.showerDoor"),
        text: bathroom.showerDoorTypeNeeded
          ? t(`Bathroom.${bathroom.showerDoorTypeNeeded}`)
          : "",
        img: IMAGES.other.green_plus,
        price: bathroom.costFor.showerDoor,
      },
      {
        heading: "Add on´s",
        text: bathroom.addOns.map((addOn) => {
          const add = addOn.text;
          if (add !== "") {
            return (
              <span>
                {`${add} (${addOn.count})`}
                <br />
              </span>
            );
          } else {
            return null;
          }
        }),
        img: IMAGES.other.green_plus,
        price: bathroom.costFor.addOns,
      },
    ];
  });
  return bathroomSummary;
};

export const getRoofingOrderSummary = (payload) => {
  if (payload && payload !== undefined) {
    let summary = [
      {
        heading: "Type of service",
        text: `${payload.serviceType}`,
        img: IMAGES.other.green_plus,
        price: 0,
      },
      {
        heading: "Why do you need roofing?",
        text: `${payload.reasonForRoofing === "new_construction"
          ? "I have a new construction"
          : "I am replacing my current roof"
          }`,
        img: IMAGES.other.green_plus,
        price: 0,
      },
      {
        heading: "Size of the roofing area",
        text: `${payload.size} SQFT`,
        img: IMAGES.other.green_plus,
        price: payload.costFor.size,
      },
      {
        heading: "Do you have the roof?",
        text: `${payload.haveOwnRoof === "have-roof"
          ? "I have the roof I want to install"
          : "I need the contractor to provide the roof"
          }`,
        img: IMAGES.other.green_plus,
        price: 0,
      },
      {
        heading: "Roof material",
        text: `${payload.roofMaterial}`,
        img: IMAGES.other.green_plus,
        price: payload.costFor.roof,
      },
      {
        heading: "Height",
        text: `${payload.heightNeeded}`,
        img: IMAGES.other.green_plus,
        price: payload.costFor.height,
      },
      {
        heading: "Pitch",
        text: `${payload.pitchNeeded}`,
        img: IMAGES.other.green_plus,
        price: payload.costFor.pitch,
      },
    ];
    if (payload.serviceType === "repair") {
      summary = [
        {
          heading: "Type of service",
          text: `${payload.serviceType}`,
          img: IMAGES.other.green_plus,
          price: 0,
        },
        {
          heading: "Number of repairs",
          text: `${payload.numberOfRepairsNeeded}`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.repair,
        },
        {
          heading: "Replace plywood/s",
          text: `${payload.needReplaceOfPlywoods}`,
          img: IMAGES.other.green_plus,
          price: payload.costFor.plywoodChange,
        },
        {
          heading: "Number of plywoods to replace",
          text: `${payload.numberOfPlywoods}`,
          img: IMAGES.other.green_plus,
          price: 0,
        },
      ];
    }
    return summary;
  }
};

export const getWindowsOrderSummary = (payload) => {
  if (payload && payload !== undefined) {
    return [
      {
        heading: "Type of work",
        text: `${payload.serviceType}`,
        img: IMAGES.other.green_plus,
        price: 0,
      },
      {
        heading: "Reason to install windows",
        text: `${payload.reasonForWindow} (${payload.numberOfWindowsNeeded})`,
        img: IMAGES.other.green_plus,
        price: payload.costFor.windows,
      },
      {
        heading: "Do you have the windows?",
        text: `${payload.haveOwnWindows}`,
        img: IMAGES.other.green_plus,
        price: payload.costFor.material,
      },
      {
        heading: "Windows",
        text: payload.windowDetails.map((addOn, i) => {
          const add = addOn.name;
          if (add !== "") {
            return (
              <span key={i}>
                {`${add}(${addOn.width}x${addOn.height}) (${addOn.count})`}
                <br />
              </span>
            );
          } else {
            return null;
          }
        }),
        img: IMAGES.other.green_plus,
        price: 0,
      },
      {
        heading: "Add on´s",
        text: payload.addOns.map((addOn, i) => {
          const add = addOn.text;
          if (add !== "") {
            return (
              <span key={i}>
                {`${add} (${addOn.count})`}
                <br />
              </span>
            );
          } else {
            return null;
          }
        }),
        img: IMAGES.other.green_plus,
        price: payload.costFor.addOns,
      },
    ];
  }
};

export const getTreeOrderSummary = (payload) => {
  if (payload && payload !== undefined) {
    return [
      {
        heading: "Type of service",
        text:
          payload.serviceType === "tree_remotion"
            ? "Tree removal"
            : `${payload.serviceType.replace(/_/g, " ")}`,
        img: IMAGES.other.green_plus,
        price: 0,
      },
      {
        heading: "Nearby structure or electric installation?",
        text: payload.anyElectricInstallationsNear,
        img: IMAGES.other.green_plus,
        price: payload.costFor?.nearByStructure,
      },
      {
        heading: "Tree height details",
        text: payload.treeDetails.map((addOn, index) => {
          const add = addOn.text;
          if (add !== "") {
            return (
              <span key={index}>
                {`${add.replaceAll("_", " ")} (${addOn.count}) `}
                <br />
              </span>
            );
          } else {
            return null;
          }
        }),
        img: IMAGES.other.green_plus,
        price: payload.costFor.installation,
      },
      {
        heading: "Number of stump removals needed?",
        text: `${payload.numberOfTreesToStumpRemove}`,
        img: IMAGES.other.green_plus,
        price: 0,
      },
      {
        heading: "Tree dimension details",
        text:
          payload.length > 0 &&
          payload.treeDimensionDetails.map((addOn, index) => {
            const add = addOn.text;
            if (add !== "") {
              return (
                <span key={index}>
                  {`${add} inch (${addOn.count}) `}
                  <br />
                </span>
              );
            } else {
              return null;
            }
          }),
        img: IMAGES.other.green_plus,
        price: payload.costFor.stumpRemoval,
      },
      {
        heading: "What type of tree/s to remove?",
        text: payload.typeOfTreesToRemove.map((addOn, index) => {
          const add = addOn.text;
          if (add !== "") {
            return (
              <span key={index}>
                {`${add.replaceAll("_", " ")} (${addOn.count})`}
                <br />
              </span>
            );
          } else {
            return null;
          }
        }),
        img: IMAGES.other.green_plus,
        price: 0,
      },
      {
        heading: "Do you want your stump grinding?",
        text: payload.needStumpGrinding,
        img: IMAGES.other.green_plus,
        price: payload.costFor.stumpGrinding,
      },
      {
        heading: "Do you want the debris haul away?",
        text: payload.needDebrisHaul,
        img: IMAGES.other.green_plus,
        price: payload.costFor.debrisHaul,
      },
      {
        heading: "Any trees which cannot reach with machinery?",
        text: payload.anyTreesOutOfReach
          ? `${payload.anyTreesOutOfReach} (${payload.numberOfTreesOutOfReach})`
          : "",
        img: IMAGES.other.green_plus,
        price: payload.costFor.machinery,
      },
    ];
  }
};

export const getInteriorPaintingOrderSummary = (payload) => {
  if (payload && payload !== undefined) {
    let summary = [];
    if (payload.typeOfWork === "fullHouse") {
      summary = [
        [
          {
            heading: "Type of service",
            text: "Paint a full house",
            img: IMAGES.other.green_plus,
            price: 0,
          },
          {
            heading: "House size",
            text: `${payload.size}`,
            img: IMAGES.other.green_plus,
            price: payload.costFor.size,
          },
          {
            heading: "Requires the paint?",
            text: `${payload.haveOwnPaint ? "No" : "Yes"}`,
            img: IMAGES.other.green_plus,
            price: 0,
          },
          {
            heading: "Paint brand and color",
            text: (
              <div>
                {`${payload.paintDetails.brandName.brand || "N/A"}`}
                <br></br>
                {`${payload.paintDetails.colorName}`}
                <br />
                  {`${payload.paintDetails.paintCode}`}
              </div>
            ),
            img: IMAGES.other.green_plus,
            price: 0,
          },
          {
            heading: "Primer coat required?",
            text: `${payload.needPrimerCoat ? "Yes" : "No"}`,
            img: IMAGES.other.green_plus,
            price: payload.costFor.primer,
          },
          {
            heading: "Type of paint",
            text: `${payload.paintType.text}`,
            img: IMAGES.other.green_plus,
            price: payload.costFor.paint,
          },
          {
            heading: "Number of coats",
            text: `${payload.coats}`,
            img: IMAGES.other.green_plus,
            price: payload.costFor.coat,
          },
          {
            heading: "Add on´s",
            text: payload.addOns.map((addOn) => {
              const add = addOn.text;
              if (add !== "") {
                return (
                  <span>
                    {`${add} ${addOn.count > 1 ? `(${addOn.count})` : ""}`}
                    <br />
                  </span>
                );
              } else {
                return null;
              }
            }),
            img: IMAGES.other.green_plus,
            price: payload.costFor.addOns,
          },
        ],
      ];
    } else if (payload.typeOfWork === "rooms") {
      const rooms = payload.roomDetails;
      let sizeArr = [
        {
          key: "small_up_to_8ft",
          text: "Small room 7ft x 7ft, Up to 8ft high",
        },
        {
          key: "medium_up_to_8ft",
          text: "Medium room 12ft x 12ft, Up to 8ft high",
        },
        {
          key: "large_up_to_8ft",
          text: "Large room 16ft x 16ft, Up to 8ft high",
        },
        {
          key: "xlarge_up_to_8ft",
          text: "Larger room more than 16ft x 16ft, Up to 8ft high",
        },
        {
          key: "small_up_to_10ft",
          text: "Small room 7ft x 7ft, Up to 10ft high",
        },
        {
          key: "medium_up_to_10ft",
          text: "Medium room 12ft x 12ft, Up to 10ft high",
        },
        {
          key: "large_up_to_10ft",
          text: "Large room 16ft x 16ft, Up to 10ft high",
        },
        {
          key: "xlarge_up_to_10ft",
          text: "Larger room more than 16ft x 16ft, Up to 10ft high",
        },
        {
          key: "small_up_to_20ft",
          text: "Small room 7ft x 7ft, Up to 20ft high",
        },
        {
          key: "medium_up_to_20ft",
          text: "Medium room 12ft x 12ft, Up to 20ft high",
        },
        {
          key: "large_up_to_20ft",
          text: "Large room upto 16ft x 16ft, Up to 20ft high",
        },
        {
          key: "xlarge_up_to_20ft",
          text: "Larger room more than 16ft x 16ft, Up to 20ft high",
        },
      ];
      summary = rooms.map((room) => {
        const roomSize = sizeArr.find(
          (size) => size.key === room.roomSize + "_up_to_" + room.roomHeight
        );
        return [
          {
            heading: "Room size",
            text: `${roomSize ? roomSize.text : ""}`,
            img: IMAGES.other.green_plus,
            price: 0,
          },
          {
            heading: "Requires the paint",
            text: `${payload.haveOwnPaint ? "No" : "Yes"}`,
            img: IMAGES.other.green_plus,
            price: 0,
          },
          {
            heading: "Paint brand and color",
            text: `${room.paintDetails.brandName.brand || "N/A"} \n     
                ${room.paintDetails.colorName} \n
                ${room.paintDetails.paintCode}`,
            img: IMAGES.other.green_plus,
            price: 0,
          },
          {
            heading: "Primer coat required?",
            text: `${room.needPrimerCoat ? "Yes" : "No"}`,
            img: IMAGES.other.green_plus,
            price: room.costFor.primer,
          },
          {
            heading: "Type of paint",
            text: `${
              room.paintType.text !== undefined ? room.paintType.text : ""
            }`,
            img: IMAGES.other.green_plus,
            price: room.costFor.paint,
          },
          {
            heading: "Number of coats",
            text: `${room.coats}`,
            img: IMAGES.other.green_plus,
            price: room.costFor.coat,
          },
          {
            heading: "Add on´s",
            text: room.addOns.map((addOn, i) => {
              const add = addOn.text;
              if (add !== "") {
                return (
                  <span key={i}>
                    {`${add} ${addOn.count > 1 ? `(${addOn.count})` : ""}`}
                    <br />
                  </span>
                );
              } else {
                return null;
              }
            }),
            img: IMAGES.other.green_plus,
            price: room.costFor.addOns,
          },
        ];
      });
    }
    return summary;
  }
};
