import React, { useEffect, useState } from "react";
import { isSupported } from "firebase/messaging";
import IMAGES from "../../../_assets/images";
import { TextInput } from "../../../_components/TextInput/TextInput";
import { PasswordInput } from "../../../_components/passwordInput/PasswordInput";
import "../../../_styles/localStyles/signupsignin.css";
import { useTranslation } from "react-i18next";
import GoogleLoginButton from "../signUp/socialMediaSignup/googleLogin";
import FacebookLogin from "../signUp/socialMediaSignup/facebookLogin";
import { Footer } from "../../../_components/footer/Footer";
import { Button } from "../../../_components/Button/Button";
import { continuetoNext } from "../../../_redux/actions";
import Loader from "../../../_components/spinner/Loader";
import { useDispatch, useSelector } from "react-redux";
import { siginModel } from "../../../_redux/Models/auth.models";
import { userSignin } from "../../../services/api/signin.service";
import { useSnackbar } from "notistack";
import { Eyehide } from "../../../_components/Tickandwrongsvg/eyehideVector";
import { Eyeshow } from "../../../_components/Tickandwrongsvg/eyeshowVector";
import { parseJwt } from "../../../services/utils/utilFunctions";
import { FireBase } from "../../../services/firebase/firebase.service";
import {
  addFirebaseToken,
  deleteFirebaseToken,
} from "../../../services/api/notifications.api.services";
import EmailLogin from "./emailLogin";
import PhoneLogin from "./phoneLogin";

export default function SignIn(props) {
  const firebase = new FireBase();
  const { history } = props;
  const { t } = useTranslation();
  const [isProgress, setIsProgress] = useState(false);
  const [emailLogin, setEmailLogin] = useState(false);

  const deleteToken = async function () {
    const tokenId = localStorage.getItem("tokenId");
    if (tokenId) {
      await deleteFirebaseToken(tokenId);
      localStorage.removeItem("tokenId");
    }
  };

  const onPermission = async (permission) => {
    const browserSupport = await isSupported();
    if (!browserSupport) return;

    if (permission === "granted") {
      deleteToken();
      try {
        const token = await firebase.getFirebaseToken();
        window.conversationsClient?.setPushRegistrationId("fcm", token);
        addFirebaseToken(token);
      } catch (e) {
        console.error("firebase not granted", e);
      }
    }
  };

  const linkClick = () => {
    props.history.push("/role");
  };
  const forgetPassword = () => {
    props.history.push("/signin/recover-password");
  };

  const backClick = () => {
    history.goBack();
  };

  useEffect(() => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    if (user) {
      history.replace("/")
    }
  }, [])

  return (
    <div>
      <div className="pcWidth">
        <Loader progress={isProgress} />
        <div className="signup_center_align">
          <table>
            <tbody>
              <tr>
                <td>
                  <img
                    src={IMAGES.other.vectors}
                    alt=""
                    onClick={() => backClick()}
                  />
                </td>
                <td>
                  <label
                    className="signup_page_anyhnb_logo"
                    onClick={() => window.location.replace("/")}
                  >
                    {t("signUp.mainHeading")}
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <label className="signup_header signup_flex_with_center">
          {t("signIn.signInHeading")}
        </label>
        <div className="signup_flex_with_center">
          {" "}
          <label className="signup_sub_heading">{t("signIn.subHeading")}</label>
          <label className="signup_sign_linkbtn" onClick={() => linkClick()}>
            {t("signIn.signUp_link")}
          </label>{" "}
        </div>

        {emailLogin ? (
          <EmailLogin onPermission={onPermission} deleteToken={deleteToken} />
        ) : (
          <PhoneLogin onPermission={onPermission} deleteToken={deleteToken} />
        )}

        <div>
          <div className="signup_needHelp">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => (window.location = "/contact-us")}
            >
              {" "}
              {t("signIn.needHelp_link")}
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
