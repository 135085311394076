import React, { useEffect, useState } from "react";

import "../../_styles/localStyles/typeOfService.css";

import { useTranslation } from "react-i18next";

import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";

import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Button } from "../../_components/Button/Button";
import { wallOpeningModel } from "../../_redux/Models/wallOpening.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, wallOpeningStore } from "../../_redux/actions";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";

export const AddOn = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 6;
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [selectedAddons, setSelectedAddons] = useState([]);

  const wallOpeningState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.wallPayload
      ? state.buttonReducer.wallPayload
      : wallOpeningModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(
    calculateTotalCost(wallOpeningState.costFor)
  ); // To keep the total price in state

  const [addonOptions, setAddonOptions] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails) {
      const addOnsArr = priceDetails.addOns.map((op) => {
        return {
          text: t(`wallOpening.${op.key}`),
          value: op.key,
          price: op.price,
        };
      });
      setAddonOptions(addOnsArr);
    }
    return () => (mounted = false);
  }, [checkoutModelState, price, t]);

  /**
   * Setting the total price of the selected services
   */
  const setTotalPrice = (selected) => {
    let totalPrice = calculateTotalCost(wallOpeningState.costFor);
    let total = selected.reduce((p, c) => p + c.price, 0);
    setPrice(total + totalPrice);
  };

  /**
   * Function to save Selected add ons and take user to next step
   * @returns {void}
   */
  const onSaveAddOns = () => {
    wallOpeningState.addOns = selectedAddons;
    wallOpeningState.costFor.addOns =
      price - calculateTotalCost(wallOpeningState.costFor);
    dispatch(wallOpeningStore(wallOpeningState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/wall_opening/order-summary/7`
    );
  };

  return (
    <div className="service insideWrp">
      <div>
        <div className="paint-home-section pcWidth ">
          <div className="sevice_title">{t("wallOpening.addons")}</div>
          <label
            className="sub_heading"
            style={{ marginTop: "20px", fontSize: "14px" }}
          >
            {" "}
            {t("wallOpening.selectAddons")}
          </label>
          <div className="mt-4">
            {addonOptions.map((item, index) => (
              <label
                key={index}
                className={`floor-selection-radio ${
                  selectedAddons.some((e) => e.value === item.value)
                    ? "selected"
                    : ""
                }`}
                style={{ height: "80px" }}
              >
                <div
                  className="FloorTitle pc-width"
                  style={{ width: "120px", textAlign: "left" }}
                >
                  {item.text}
                  <br />
                  {item.value === "shoe_molding_repair" && (
                    <span style={{ fontSize: "10px", maxWidth: "300px" }}>
                      Smaller and thinner molding than quarter rounds for the
                      joints of your floors with the wall.
                    </span>
                  )}
                </div>
                <div style={{ display: "flex", placeItems: "center" }}>
                  <div style={{ paddingTop: "3px" }}>
                    <img
                      width="15"
                      height="15"
                      src={IMAGES.other.green_plus}
                      alt=""
                    />
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    {`$${formatPrice(item.price)}`}
                  </div>
                </div>
                <input
                  type="checkbox"
                  name={index}
                  value={item.text}
                  checked={selectedAddons.some((e) => e.value === item.value)}
                  onChange={() => {
                    setSelectedAddons((previousState) => {
                      let state;
                      if (previousState.some((e) => e.value === item.value)) {
                        state = previousState.filter(
                          (i) => i.value !== item.value
                        );
                      } else {
                        state = [...previousState, item];
                      }
                      if (item.value === "shoe_molding_repair") {
                        state = state.filter(
                          (s) => s.value !== "quater_rounds_repair"
                        );
                      } else if (item.value === "quater_rounds_repair") {
                        state = state.filter(
                          (s) => s.value !== "shoe_molding_repair"
                        );
                      }
                      setTotalPrice(state);
                      return state;
                    });
                  }}
                />
              </label>
            ))}
            <span style={{ margin: "18px 0", display: "block" }}>
              {t("wallOpening.needPaintinggotoPaintCatogory")}
            </span>
          </div>
          <Button
            disabled={false}
            buttonLabel={t("addOn.continue")}
            onClick={onSaveAddOns}
          />
        </div>
      </div>
    </div>
  );
};

const items = [
  {
    text: "Shoe molding repair",
    value: "shoeMolding",
    img: IMAGES.other.green_plus,
    price: "$0000.-",
  },
  {
    text: "Quarter rounds repair",
    value: "quarterRounds",
    img: IMAGES.other.green_plus,
    price: "$0000.-",
  },
  {
    text: "Baseboards repair",
    value: "baseboards",
    img: IMAGES.other.green_plus,
    price: "$0000.-",
  },
];
