import React, { useState } from "react";
import { isSupported } from "firebase/messaging";
import { TextInput } from "../../../_components/TextInput/TextInput";
import "../../../_styles/localStyles/signupsignin.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import { continuetoNext } from "../../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { siginModel } from "../../../_redux/Models/auth.models";
import { userSignin } from "../../../services/api/signin.service";
import { useSnackbar } from "notistack";
import { Eyehide } from "../../../_components/Tickandwrongsvg/eyehideVector";
import { Eyeshow } from "../../../_components/Tickandwrongsvg/eyeshowVector";
import { parseJwt } from "../../../services/utils/utilFunctions";
import { FireBase } from "../../../services/firebase/firebase.service";
import {
  addFirebaseToken,
  deleteFirebaseToken,
} from "../../../services/api/notifications.api.services";
import { useHistory } from "react-router-dom";

export default function EmailLogin({ deleteToken, onPermission, ...props }) {
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [show, setShow] = useState(false);
  const firebase = new FireBase();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isProgress, setIsProgress] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [signInState, setSignInState] = useState({
    email: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
    password: {
      valid: false,
      value: "",
      error: {
        flag: null,
        message: "",
      },
    },
  });

  const onChangeHandler = (event, key) => {
    let validatedState = validate(key, event.target.value, undefined);
    setSignInState(validatedState);
    const formValid = Object.values(validatedState).every(
      (val) => val.valid === true
    );
    if (formValid) {
      setIsButtonDisable(false);
    }
  };

  const validate = (name, value, message) => {
    const newState = { ...signInState };
    newState[name].value = value;
    var errorMessage = message === undefined ? null : message;
    var regexpEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    switch (name) {
      case "email":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Email is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (!value.match(regexpEmail)) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Please enter a valid Email";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (value.match(regexpEmail)) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      case "password":
        if (!value) {
          errorMessage =
            errorMessage !== null ? errorMessage : "Password is required";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (
          !value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)
        ) {
          errorMessage =
            errorMessage !== null ? errorMessage : " Password is incorrect";
          // : " Must contains at least 8 characters and should contain special characters and numbers";
          newState[name].error.flag = true;
          newState[name].valid = false;
          newState[name].error.message = errorMessage;
        } else if (
          value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)
        ) {
          errorMessage = errorMessage !== null ? errorMessage : "";
          newState[name].error.flag = false;
          newState[name].valid = true;
          newState[name].error.message = "";
        }
        return newState;

      default: {
        return newState;
      }
    }
  };

  const siginHandler = async () => {
    const user = {
      email: signInState.email.value,
      password: signInState.password.value,
    };
    const formValid = Object.values(signInState).every(
      (val) => val.valid === true
    );
    if (formValid) {
      setIsButtonDisable(true);
      setIsProgress(true);
      if (user != undefined) {
        var res = await userSignin(user);
        if (res.status == 200) {
          siginModel.userResponse = { ...res.data };
          dispatch(continuetoNext(siginModel));
          var userToken = parseJwt(res.data.token);
          localStorage.setItem(
            "authInfo",
            JSON.stringify({ ...res.data, ...userToken })
          );
          userToken["originalStatus"] = userToken.userType;
          localStorage.setItem(
            "user",
            JSON.stringify({ ...res.data, ...userToken })
          );
          localStorage.setItem("auth_token", res.data.token);

          try {
            Notification.requestPermission()
              .then(onPermission)
              .catch((e) => {
                console.error(e);
              });
          } catch (error) {
            if (error instanceof TypeError) {
              Notification.requestPermission(onPermission);
            } else {
              console.log("notification not supprted" + JSON.stringify(error));
            }
          }
          // // Contractors should be redirect to their project section. Not on home page
          const userDetailsParsed = JSON.parse(localStorage.getItem("user"));
          if (
            userDetailsParsed &&
            userDetailsParsed.userType === "contractor"
          ) {
            let redirectUrl = localStorage.getItem("redirectUrl");
            redirectUrl = redirectUrl ? redirectUrl : "/contractor-projects";
            localStorage.removeItem("redirectUrl");
            history.push(redirectUrl);
          } else {
            let redirectUrl = localStorage.getItem("redirectUrl");
            redirectUrl = redirectUrl ? redirectUrl : "/";
            localStorage.removeItem("redirectUrl");
            history.push(redirectUrl);
          }

          setIsProgress(false);
        } else {
          let errorMessage = "Something went wrong!";
          if (res.response && res.response.status > 200) {
            errorMessage = res.response.data.message;
          }
          setIsProgress(false);
          const variant = "error";
          enqueueSnackbar(errorMessage, { variant });
        }
        setIsProgress(false);
      }
    } else {
      Object.values(signInState).map((el, i) => {
        if (!el.valid) {
          let keyValue = Object.keys(signInState)[i];
          var errorState = validate(keyValue, el.value, undefined);
          setSignInState(errorState);
        }
      });
    }
  };

  const forgetPassword = () => {
    history.push("/signin/recover-password");
  };

  return (
    <div>
      <div className="signup_section">
        <div className="register_page_lable">
          <TextInput
            className="signup_input_border"
            label={t("signIn.email_label")}
            type="text"
            onChange={(event) => onChangeHandler(event, "email")}
            value={signInState?.email?.value}
            error={signInState?.email?.error}
            placeholder={t("signIn.email_placeholder")}
          />
        </div>
        <div className="flex register_page_lable">
          <TextInput
            onChange={(event) => onChangeHandler(event, "password")}
            value={signInState?.password?.value}
            error={signInState?.password?.error}
            type={passwordShown ? "text" : "password"}
            placeholder={t("signIn.password_placeholder")}
            label={t("signIn.password_label")}
          />

          {(() => {
            if (signInState?.password?.error.flag == true) {
              return (
                <div onClick={() => setShow(!show)}>
                  {!show ? (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyehide fill="#d9083a" />
                    </div>
                  ) : (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyeshow fill="#d9083a" />
                    </div>
                  )}
                </div>
              );
            }
            if (signInState?.password?.error.flag == false) {
              return (
                <div onClick={() => setShow(!show)}>
                  {!show ? (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyehide fill="#5fca5d" />
                    </div>
                  ) : (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyeshow fill="#5fca5d" />
                    </div>
                  )}
                </div>
              );
            } else {
              return (
                <div onClick={() => setShow(!show)}>
                  {!show ? (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyehide />
                    </div>
                  ) : (
                    <div
                      onClick={togglePasswordVisiblity}
                      className="signup_eyeIcon"
                    >
                      <Eyeshow />
                    </div>
                  )}
                </div>
              );
            }
          })()}
        </div>

        <div className="signup_flex_with_center signup_validation_not_receive_link_padding">
          <label
            className="signup_validation_not_receive_link"
            onClick={() => forgetPassword()}
          >
            {" "}
            {t("signIn.forgot_link")}
          </label>
        </div>

        <div className="padditopt10"></div>
        <div className="padditopt10"></div>

        <Button
          disabled={isButtonDisable}
          buttonLabel={t("signIn.continue")}
          onClick={siginHandler}
        />
        {/* <EyeIcon/> */}
      </div>
    </div>
  );
}
