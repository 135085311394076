import React from "react";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/profileCreate.css";
import "../../_components/radioButton/radioButton.css";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import { Buttonforward } from "../../_components/Button/buttonForward";
import { Buttontick } from "../../_components/Button/buttonTick";
import { Button } from "../../_components/Button/Button";
import { withTranslation } from "react-i18next";
import {
  getContractorRegisteredAddresses,
  getContractors,
} from "../../services/api/contractor.service";
import { checkoutStore, updateContractorPortfolio } from "../../_redux/actions";
import { connect } from "react-redux";
import {
  extractImageUrlsFromPicOfWork,
  formatPrice,
  parseJwt,
} from "../../services/utils/utilFunctions";
import BearCarousel, { BearSlideItem } from "bear-react-carousel";
import "bear-react-carousel/dist/index.css";
import {
  getOwnerAddresses,
  ownerRegisteredAddresses,
} from "../../services/api/owner.service";
import { getData } from "../../services/localStorage/localStorage.service";
import { LOCAL_STORAGE } from "../../_configs/constants.config";
class SelectContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      contractors: [],
      addresses: [],
      selectedContractorId: this.props.checkout.selectedContractorId,
    };
  }

  getSliderImageData = (contractor) => {
    const selectedCategoryId =
      this.props.checkout.payload.selectedServiceDetails.categoryId;
    let picOfWorks = contractor.picOfWork.sort((picOfWorkAs) =>
      picOfWorkAs.categoryId === selectedCategoryId ? -1 : 1
    );
    picOfWorks = extractImageUrlsFromPicOfWork(picOfWorks);
    return picOfWorks.map((img, index) => {
      return {
        key: index,
        children: (
          <BearSlideItem imageUrl={img}>
            <div>
              <div className="star ownStar">
                <table className="checkoutTable checkoutRatingsdiv Grid trsssssanformmius">
                  <tr>
                    <td className="checkoutRatings ">
                      <div className="allCenterGrid">
                        <label className="checkoutRatingstxt ">
                          {contractor.rating !== "N/A"
                            ? Math.fround(contractor.rating).toFixed(1)
                            : contractor.rating}
                        </label>
                        <label
                          className={`${
                            contractor.rating !== "N/A" ? contractor.rating : ""
                          }`}
                          style={{ fontSize: "6px" }}
                        >
                          ★★★★★
                        </label>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div className="checkoutslide-image-gradient ownSlide">
                <table className=" checkoutTable layout_content">
                  <tr>
                    <td width="73%">
                      <label className="checkOut-bottomtxt">
                        {contractor.highlight}
                      </label>
                    </td>
                    <td>
                      <span
                        onClick={() => {
                          sessionStorage.setItem("whilePlacingOrder", true);
                          this.props.history.push(
                            `/contractor-portfolio/${contractor.id}`
                          );
                        }}
                        rel="noreferrer"
                      >
                        <label className="checkOut-seeprotolotxt">
                          See portfolio
                        </label>
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
              <span></span>
            </div>
          </BearSlideItem>
        ),
      };
    });
  };

  componentDidMount = () => {
    this.getUserInfo();
    this.getContractors();
  };

  /**
   * Gets the contractor from api
   */
  getContractors = () => {
    const serviceCategoryId =
      this.props.checkout?.payload?.selectedServiceDetails?.categoryId;
    getContractors(serviceCategoryId).then((contractors) => {
      this.setState({ contractors });
    });
  };

  /**
   * User info
   */
  getUserInfo = () => {
    const auth_token = localStorage.getItem("auth_token");
    const userDetails = parseJwt(auth_token);
    if (userDetails.userType === "owner") {
      this.getOwnerAddresses(userDetails);
    } else if (userDetails.userType === "contractor") {
      this.getContractorAddresses(userDetails);
    }
  };

  getOwnerAddresses = (userDetails) => {
    ownerRegisteredAddresses(userDetails._id).then((data) => {
      if (data.status === 200 && data.data && data.data?.address?.length > 0) {
        const addresses = data.data.address;
        this.setState({ addresses: addresses });
      }
    });
  };
  getContractorAddresses = (userDetails) => {
    getContractorRegisteredAddresses(userDetails._id).then((data) => {
      if (data.status === 200 && data.data && data.data?.address?.length > 0) {
        const addresses = data.data.address;
        this.setState({ addresses: addresses });
      }
    });
  };

  /**
   * Selects the contractor and move on
   */
  onSelectContractor = () => {
    const user = getData(LOCAL_STORAGE.user);
    if (user) {
      const checkoutModel = this.props.checkout.payload;
      checkoutModel.selectedContractorId = this.state.selectedContractorId;
      checkoutModel.ownerId = user._id;
      checkoutModel.ownerName = user.name;
      checkoutModel.ownerImage = IMAGES.home.hdmanSlide;
      this.props.checkoutStore(checkoutModel);
      if (this.state.addresses.length > 0) {
        this.props.history.push(`/checkout/payment-method`);
      } else {
        this.props.history.push(`/checkout/enter-address`);
      }
    } else {
      alert("Sign in as owner and try again");
    }
  };

  render = () => {
    const { t } = this.props;

    return (
      <div className="insideWrp">
        <HeaderMenu />

        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className="paint_main_buttons">
            <div className="paint_main_button_left">
              <ProgressBar progressValue={8} />
              <div>
                <label className="paint_every_flow_title">
                  {"Select Contractor"}
                </label>
              </div>
            </div>
            <div className="paint_main_button_right">
              <table>
                <tr>
                  <td>
                    <img src={IMAGES.other.piggy} alt="" />
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <label className="paint_every_price_txt">
                      {this.props.checkout.payload.totalCost
                        ? `$${formatPrice(
                            this.props.checkout.payload.totalCost
                          )}`
                        : "--"}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div className="paint_flex_with_left profilePadding40">
            <label className="paint_header">{"Select a contractor"}</label>
          </div>
          <div className="profilePadding30"></div>

          <div className="profilePadding20"></div>
          <Buttonforward
            disabled={false}
            buttonLabel={"First available"}
            onClick={() =>
              this.setState(
                { selectedContractorId: "first-available" },
                this.onSelectContractor
              )
            }
          />
          <div className="profilePadding30"></div>

          {this.state.contractors.length > 0
            ? this.state.contractors.map((contractor) => (
                <div key={Math.random()}>
                  <BearCarousel
                    className="star_slider"
                    key={Math.random()}
                    data={this.getSliderImageData(contractor)}
                    isEnableLoop
                    isEnableNavButton
                    isEnableMouseMove
                    //isEnablePagination
                    aspectRatio={{ widthRatio: 16, heightRatio: 9 }}
                  />
                  <Buttontick
                    disabled={false}
                    buttonLabel={`${
                      this.state.selectedContractorId === contractor.id
                        ? "Contractor selected"
                        : "Select contractor"
                    }`}
                    className={`btn-checkouttick contact-btn ${
                      this.state.selectedContractorId === contractor.id
                        ? "tick-btn"
                        : ""
                    }`}
                    onClick={() => {
                      this.setState({ selectedContractorId: contractor.id });
                      const section =
                        document.querySelector("#continueButtonRef");
                      section.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                  />
                </div>
              ))
            : null}

          <div className="profilePadding30"></div>
          <Button
            id={"continueButtonRef"}
            disabled={!this.state.selectedContractorId}
            onClick={this.onSelectContractor}
            buttonLabel={"Continue"}
          />
          <div className="otherspadding25"></div>
          <div className="otherspadding25"></div>
          <div className="contactblue_btn ">
            <div>
              <img src={IMAGES.other.group1} alt="" />
            </div>
            <div>
              <table>
                <tr>
                  <td>
                    <label className="condactNeedhelp">
                      {t("paintingInterior.doneedhelp")}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td onClick={() => (window.location = "/contact-us")}>
                    {" "}
                    <label className="contactuslink">
                      {t("paintingInterior.contactus")}
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="paintPolygon">
            <img src={IMAGES.other.polygon} alt="" />
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
  updateContractorPortfolio,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(SelectContractor));
