import React, { useEffect, useState } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../_components/Button/Button";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import RadioButton from "../../_components/radioButton/radioButton";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import { useDispatch, useSelector } from "react-redux";
import {
  checkoutStore,
  plumbingStore,
  roofingStore,
} from "../../_redux/actions";
import { getServiceCategoriesFull } from "../../services/api/category.api.service";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { roofingModel } from "../../_redux/Models/roofing.model";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const RoofingTypeofService = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const price = calculateTotalCost(roofingModel.costFor); // To keep the total price in state
  const [option, setOption] = useState("");
  const [subCategories, setSubCategories] = useState([]);

  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  useEffect(() => {
    let mounted = true;
    getServiceCategoriesFull().then((items) => {
      if (mounted && items.status === 200) {
        console.log(items.data);
        let filteredItem = items.data.find((item) => item.name === "Roofing");
        setSubCategories(filteredItem.subCategory);
        checkoutModelAs.selectedService = "Roofing";
        checkoutModelAs.totalCost = 0;
        dispatch(checkoutStore(checkoutModelAs));
      }
    });
    return () => (mounted = false);
  }, [checkoutModelAs, dispatch]);

  const onSave = (val) => {
    checkoutModelAs.selectedServiceDetails = subCategories.find(
      (op) => op.key === `roofing_${val}`
    );
    roofingModel.serviceType = val;
    let link = `/contractor-portfolio/${selectedContractorId}/roofing/why-you-need`;
    if (val === "repair") {
      link = `/contractor-portfolio/${selectedContractorId}/roofing/number-of-repairs`;
    }
    dispatch(checkoutStore(checkoutModelAs));
    dispatch(roofingStore(roofingModel));
    history.push(link);
  };

  return (
    <div class="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left mb-3">
            <label className="paint_header">
              {"What type of service do you need?"}
            </label>
          </div>
          <div
            onClick={() => {
              setOption("installation");
              onSave("installation");
            }}
            className={`floor-selection-radio ${
              option === "installation" ? "selected" : ""
            }`}
          >
            <div
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Installation</label>
            </div>
          </div>
          <div className="padding10"></div>
          <div
            onClick={() => {
              setOption("repair");
              onSave("repair");
            }}
            className={`floor-selection-radio ${
              option === "repair" ? "selected" : ""
            }`}
          >
            <div
              className="width100"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label className="paintdiv_txt">Repair</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
