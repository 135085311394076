import React from "react";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import { withTranslation } from "react-i18next";
import { checkoutStore } from "../../_redux/actions";
import { connect } from "react-redux";
import BearCarousel, { BearSlideItem } from "bear-react-carousel";
import "bear-react-carousel/dist/index.css";
import { getContractor } from "../../services/api/contractor.service";
import Loader from "../../_components/spinner/Loader";

class Wallet extends React.Component {
  render = () => {
    return (
      <div className="insideWrp">
        <HeaderMenu />
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className="paint_main_buttons wallet-btn">
            <div className="paint_main_button_left">
              <table>
                <tr>
                  <td>
                    <img
                      src={IMAGES.other.money}
                      alt=""
                      style={{ width: "75px" }}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <label className="paint_every_price_txt">Total</label>
                  </td>
                </tr>
              </table>
            </div>
            <div className="paint_main_button_right">
              <div>
                <label className="paint_every_flow_title">$0000</label>
              </div>
            </div>
          </div>
          <div className="TextBox review_discription">
            <p>
              Your earnings will be transferred at the end of each project and
              your wallet will be adjusted.
            </p>
            <p>
              For doubts contact:{" "}
              <span style={{ fontWeight: "bold" }}>hello@anyhnb.com</span>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}
/* 
const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});
 */
export default connect()(withTranslation()(Wallet));
/* mapStateToProps,
  mapDispatchToProps() */
