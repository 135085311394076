import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorSolidHardwoodModel } from "../../../_redux/Models/Floor.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { removalOfFloorPopup } from "../Common/RemovalOfFloor.popup";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const SHTypeOfInstallation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 2;

  const [option, setOption] = useState("");

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorSolidHardwoodModel
  );

  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price

  const [pricingOptions, setPricingOptions] = useState([]);
  const [showRemovalFloorPopup, setShowRemovalFloorPopup] = useState(false);
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails) {
      // To show service questions
      const options = [
        {
          text: t("solidHardwood.nailDown"),
          textSub: t("solidHardwood.nailDownSub"),
          value: "nail_down",
          price:
            priceDetails.typeOfInstallation.find((el) => el.key === "nail_down")
              .price * parseInt(state.floorSize),
        },
        {
          text: t("solidHardwood.glueDown"),
          textSub: t("solidHardwood.glueDownSub"),
          value: "glue_down",
          price:
            priceDetails.typeOfInstallation.find((el) => el.key === "glue_down")
              .price * parseInt(state.floorSize),
        },
      ];
      setPricingOptions(options);
    }
    return () => (mounted = false);
  }, [checkoutModelState, state.floorSize, t]);

  /**
   * Function to save Selected Service Type and take user to next step
   * @returns {void}
   */
  const onSaveServiceType = () => {
    state.typeOfInstallation = option;
    state.costFor.typeOfInstallation =
      price - calculateTotalCost(state.costFor);
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(floorStore(state));
    setShowRemovalFloorPopup(true);
  };

  /**
   * On prior floor removal click yes
   * @returns {void}
   */
  const onPriorFloorRemovalYesClick = () => {
    state.priorFloorRemovalRequired = true;
    dispatch(floorStore(state));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/solid-hardwood/current-floor/6`
    );
  };

  /**
   * On prior floor removal click no
   * @returns {void}
   */
  const onPriorFloorRemovalNoClick = () => {
    setShowRemovalFloorPopup(false);
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/solid-hardwood/add-ons/6`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label
              className="paint_header mb-3"
              style={{ fontWeight: "bold", padding: "0" }}
            >
              {t("solidHardwood.letUsKnowHowToInstall")}
            </label>
          </div>
          {pricingOptions.map((item, index) => (
            <div
              className={`floor-selection-radio d-flex w-100 ${
                option === item.value ? "selected" : ""
              }`}
              style={{}}
              onClick={() => {
                const total = calculateTotalCost(state.costFor);
                setPrice(total + item.price);
                setOption(item.value);
                onSaveServiceType();
              }}
            >
              <div>
                <label className="paintdiv_txt">{item.text}</label>
                <p
                  style={{
                    fontSize: "12px",
                    textAlign: "left",
                    margin: "0",
                    color: "#858585",
                  }}
                >
                  {item.textSub}
                </p>
              </div>

              <div style={{ display: "flex" }}>
                <img src={IMAGES.other.green_plus} alt="" />
                <label className="paintdiv_txt">
                  {`$${formatPrice(item.price)}`}
                </label>
              </div>
            </div>
          ))}

          {/* <div className="profilePadding40"></div>
          <Button
            disabled={option === ""}
            buttonLabel={t("paintingHomes.continue")}
            onClick={onSaveServiceType}
          /> */}
        </div>
      </div>
      {removalOfFloorPopup({
        isOpen: showRemovalFloorPopup,
        onYesClick: onPriorFloorRemovalYesClick,
        onCloseAction: onPriorFloorRemovalNoClick,
        onNoClick: onPriorFloorRemovalNoClick,
      })}
    </div>
  );
};
