import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import RadioButton from "../../../_components/radioButton/radioButton";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorEngineeredModel } from "../../../_redux/Models/Floor.model";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import { removalOfFloorPopup } from "../Common/RemovalOfFloor.popup";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const FloorSizeDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue =
    props.match && props.match.params ? props.match.params.stepValue : 3;
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const [materialQuality, setMaterialQuality] = useState("");
  const floorState = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.floorPayload
      ? state.buttonReducer.floorPayload
      : floorEngineeredModel
  );
  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(calculateTotalCost(floorState.costFor)); // To keep the total price in state
  const [floorSizeOptions, setFloorSizeOptions] = useState([]);
  const [showRemovalFloorPopup, setShowRemovalFloorPopup] = useState(false);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    const floorSize = parseFloat(floorState.floorSize);
    const needEngineeredFloorInstall = floorState.needEngineeredFloorInstall;
    if (mounted === true && priceDetails && priceDetails.materialQualities) {
      const floorSizeOptions = priceDetails.materialQualities.map((op) => {
        return {
          text: t(`floor.${op.key}`),
          value: op.key,
          price: needEngineeredFloorInstall ? floorSize * op.price : 0,
        };
      });
      setFloorSizeOptions(floorSizeOptions);
    }
    return () => (mounted = false);
  }, [checkoutModelState, floorState, t]);

  /**
   * Setting the total price of the selected services
   */
  const setTotalPrice = (price) => {
    const totalP = checkoutModelState.totalCost + price;
    setPrice(totalP);
  };

  /**
   * On prior floor removal click yes
   * @returns {void}
   */
  const onPriorFloorRemovalYesClick = () => {
    floorState.priorFloorRemovalRequired = true;
    dispatch(floorStore(floorState));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/engineered/current-floor-details/4`
    );
  };

  /**
   * On prior floor removal click no
   * @returns {void}
   */
  const onPriorFloorRemovalNoClick = () => {
    setShowRemovalFloorPopup(false);
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/engineered/floor-addons/4`
    );
  };

  /**
   * Function to save Selected floor size and take user to next step
   * @returns {void}
   */
  const onSaveFloorSize = (value) => {
    floorState.materialQuality = value;
    floorState.costFor.value = price - calculateTotalCost(floorState.costFor);
    dispatch(floorStore(floorState));
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    setShowRemovalFloorPopup(true);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {t("floor.SelectMaterialGrade")}
            </label>
          </div>
          <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("floor.SelectMaterialGradeDetails")}
            </label>
          </div>
          <div className="padding10"></div>
          {floorSizeOptions.map((item, index) => (
            <div key={index} className="paintPadding8 ">
              <div
                className={`floor-selection-radio ${
                  materialQuality == item?.value ? "selected" : ""
                }`}
                onClick={() => {
                  setMaterialQuality(item.value);
                  setTotalPrice(item.price);
                  onSaveFloorSize(item?.value);
                }}
                style={{}}
              >
                <div
                  key={index}
                  className="width100"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label className="paintdiv_txt pc_txt_150">{item.text}</label>
                  <div
                    className="paint_arrowFloat"
                    style={{
                      width: "130px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src={IMAGES.other.green_plus} alt="" />
                      <label className="paintdiv_txt">
                        {`$${formatPrice(item.price)}`}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {removalOfFloorPopup({
        isOpen: showRemovalFloorPopup,
        onYesClick: onPriorFloorRemovalYesClick,
        onCloseAction: onPriorFloorRemovalNoClick,
        onNoClick: onPriorFloorRemovalNoClick,
      })}
    </div>
  );
};
