import { ElectricOrderSummary } from "./OrderSummary";
import { ElectricRepairComments } from "./repairComments";
import { ElectricSelectInstallItems } from "./selectInstallItems";
import { ElectricSelectItems } from "./selectItems";
import { ElectricTypeOfInstallation } from "./typeOfInstallation";
import { ElectricTypeofService } from "./typeOfService";

export const electricRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/electric/type-of-service",
    component: ElectricTypeofService,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/electric/replace-or-new/:stepValue",
    component: ElectricTypeOfInstallation,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/electric/select-items/:stepValue",
    component: ElectricSelectItems,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/electric/select-install-items/:stepValue",
    component: ElectricSelectInstallItems,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/electric/order-summary/:stepValue",
    component: ElectricOrderSummary,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/electric/comment-details/:stepValue",
    component: ElectricRepairComments,
  },
];
