import { atom } from "recoil";

export const contractorsList = atom({
  key: "contractorsList",
  default: [],
});

export const wishlistedList = atom({
  key: "wishlistedList",
  default: [],
});

export const wishlistedContractorsAtom = atom({
  key: "wishlistedContractorsAtom",
  default: [],
});

export const selectedContractorAtom = atom({
  key: "selectedContractorAtom",
  default: "",
});
