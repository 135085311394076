import React from "react";
import { Footer } from "../../../_components/footer/Footer";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import { getContractor } from "../../../services/api/contractor.service";

import { Button } from "../../../_components/Button/Button";
import { withTranslation } from "react-i18next";

import { checkoutStore } from "../../../_redux/actions";
import { connect } from "react-redux";
import moment from "moment";
import { createChannel } from "../../../services/api/chat.service";
import Loader from "../../../_components/spinner/Loader";
import IMAGES from "../../../_assets/images/index";
import { getOwnerProjects } from "../../../services/api/owner.service";
import { parseJwt } from "../../../services/utils/utilFunctions";
import { getprojectheader } from "../../Contractor/MyProjects/contractorUtils";

class MyProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "pending",
      isLoading: false,
      isError: false,
      projects: [],
      contractor: {},
    };
  }

  componentDidMount = () => {
    this.fetchPendingProjects();
  };

  fetchOther = () => {
    this.fetchPendingProjects();
  };

  fetchPendingProjects = () => {
    this.setState({ isLoading: true, isError: false }, () => {
      const user = parseJwt(localStorage.getItem("auth_token"));
      if (user && user._id) {
        let status = this.state.activeTab;

        getOwnerProjects(user._id, status)
          .then((data) => {
            if (data.status === 200) {
              const projects = data.data;
              console.log(projects);

              this.setState(
                {
                  projects: projects,
                  isLoading: false,
                  isError: false,
                },
                () => console.log(this.state)
              );
            }
          })
          .catch((e) => alert(e.toString()))
          .finally((_) => {
            this.setState({ isLoading: false }, () => console.log(this.state));
          });
      }
    });
  };

  /**
   * Gets the contractor from api
   */
  getContractorDetails = async (id) => {
    return getContractor(id);
  };

  startChat = (contractor, project) => {
    const data = {};
    const user = localStorage.getItem("user");
    data["ownerID"] = project.ownerId;
    data["contractorID"] = contractor.contractorId._id;
    if (project.ownerID === user._id) data["userType"] = "owner";
    if (contractor.contractorId._id === user._id)
      data["userType"] = "contractor";

    createChannel(data)
      .then((response) => {
        if (response.status !== 200) {
          console.log("Something went wrong!");
          return;
        }
        this.props.history.push({
          pathname: "/user/chat",
          search: `?sid=${response.data}`,
        });
      })
      .catch(() => {
        console.log("Something went wrong!");
      });
  };

  onChatClick = async (project) => {
    this.setState({ isLoading: true });
    const contractor = await this.getContractorDetails(project.contractorId);
    this.startChat(contractor.data, project);
  };

  getStatusText = (status) => {
    let statusText = "pending";
    if (status === "pending") statusText = "Pending acceptance";
    else if (status === "checked_in") statusText = "Checked in";
    else if (status === "confirm") statusText = "Confirmed";
    else if (status === "rejected") statusText = "Rejected";
    else if (status === "cancelled") statusText = "Cancelled";
    else if (status === "completed") statusText = "Completed project";
    return statusText;
  };

  render = () => {
    const { t } = this.props;
    console.log({ a: this.state.project });
    return (
      <div className="insideWrp">
        <Loader progress={this.state.isLoading} />
        <HeaderMenu />
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div className="allCenter">
            {/* Tabs start */}
            <div className="tabs">
              <div className="tab-header">
                <div
                  onClick={() =>
                    this.setState({ activeTab: "confirm" }, this.fetchOther)
                  }
                  className={`${
                    this.state.activeTab === "confirm" ? "active" : ""
                  } menuheadertxt`}
                >
                  Active
                </div>
                <div
                  onClick={() =>
                    this.setState(
                      { activeTab: "pending" },
                      this.fetchPendingProjects
                    )
                  }
                  className={`${
                    this.state.activeTab === "pending" ? "active" : ""
                  } menuheadertxt`}
                >
                  Pending
                </div>
                <div
                  onClick={() =>
                    this.setState({ activeTab: "completed" }, this.fetchOther)
                  }
                  className={`${
                    this.state.activeTab === "completed" ? "active" : ""
                  } menuheadertxt`}
                >
                  History
                </div>
                <div className="tab-indicator "></div>
              </div>

              <div className="tab-body">
                <div class="active"></div>
                <div></div>
                <div></div>
              </div>
            </div>
            {/* Tabs end */}
          </div>

          {/* Each project */}
          {this.state.isLoading ? (
            <div>LOADING PLEASE WAIT....</div>
          ) : this.state.isError ? (
            <div>ERROR</div>
          ) : (
            <div>
              {this.state.projects && this.state.projects.length > 0 ? (
                this.state.projects.map((project) => (
                  <div key={Math.random()}>
                    {/* <label className="header_path_name_show">
                      {moment(new Date(project.tentativeStartDate)).format(
                        "MM/DD/YYYY"
                      )}{" "}
                      •{" "}
                      <label
                        className="approvalTxt"
                        style={{ fontSize: "14px" }}
                      >
                        <span className={`project-status ${project.status}`}>
                          {this.getStatusText(project.status)}
                        </span>
                      </label> 
                    </label> */}
                    <div className="padding10"></div>
                    <div className="divborderMyproject ">
                      <div className="myproject_radio_div_first1 exteriorDivpadding  ">
                        <table>
                          <tr>
                            <td>
                              <div className="grid">
                                <label
                                  className="picColorroom1_radioHeader"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {getprojectheader(
                                    project.serviceDetails.data.flowName,
                                    project.serviceDetails.data.serviceType
                                  )}
                                </label>
                                <label className="paintdiv_txt three-lines-wrap">
                                  {project.ownerAddress}
                                </label>
                                <span className="header_path_name_show">
                                  Start date:{"  "}
                                  {moment(
                                    new Date(project.tentativeStartDate)
                                  ).format("MM/DD/YYYY")}{" "}
                                </span>
                                <label className="myprojecttxtblue">
                                  <label className="approvalTxt">
                                    <span
                                      className={`project-status ${project.status}`}
                                    >
                                      {this.getStatusText(project.status)}
                                    </span>
                                  </label>
                                </label>
                              </div>
                            </td>
                            {project.selectedContractorId !==
                              "first-available" && (
                              <td width="10%">
                                <div
                                  className="paint_arrowFloat "
                                  onClick={() => this.onChatClick(project)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                  >
                                    <path
                                      d="M12 0.5H18C21.1826 0.5 24.2348 1.76428 26.4853 4.01472C28.7357 6.26516 30 9.3174 30 12.5C30 15.6826 28.7357 18.7348 26.4853 20.9853C24.2348 23.2357 21.1826 24.5 18 24.5V29.75C10.5 26.75 0 22.25 0 12.5C0 9.3174 1.26428 6.26516 3.51472 4.01472C5.76516 1.76428 8.8174 0.5 12 0.5ZM15 21.5H18C19.1819 21.5 20.3522 21.2672 21.4442 20.8149C22.5361 20.3626 23.5282 19.6997 24.364 18.864C25.1997 18.0282 25.8626 17.0361 26.3149 15.9442C26.7672 14.8522 27 13.6819 27 12.5C27 11.3181 26.7672 10.1478 26.3149 9.05585C25.8626 7.96392 25.1997 6.97177 24.364 6.13604C23.5282 5.30031 22.5361 4.63738 21.4442 4.18508C20.3522 3.73279 19.1819 3.5 18 3.5H12C9.61305 3.5 7.32387 4.44821 5.63604 6.13604C3.94821 7.82387 3 10.1131 3 12.5C3 17.915 6.693 21.449 15 25.22V21.5Z"
                                      fill="#2CCAE8"
                                    />
                                  </svg>
                                </div>
                              </td>
                            )}
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div
                      className="divborderMyprojectbottom"
                      style={{ marginTop: "3px" }}
                    >
                      <Button
                        disabled={false}
                        buttonLabel={t("View project details")}
                        onClick={() =>
                          this.props.history.push(
                            `/user/view-project/${project._id}`
                          )
                        }
                        className="btn-checkouttick"
                      />
                    </div>
                    <div className="paddinngtop30"></div>
                  </div>
                ))
              ) : (
                <div style={{ textAlign: "center" }}>No projects found</div>
              )}
            </div>
          )}

          {/* End each project */}
        </div>
        <Footer />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  checkout: state.checkout,
});

const mapDispatchToProps = () => ({
  checkoutStore,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(withTranslation()(MyProjectList));
