import React, { useEffect, useState } from "react";
import HeaderMenu from "../../../_components/HeaderMenu/HeaderSection";
import "../../../_styles/localStyles/typeOfService.css";
import { useTranslation } from "react-i18next";
import { Button } from "../../../_components/Button/Button";
import IMAGES from "../../../_assets/images/index";
import { Footer } from "../../../_components/footer/Footer";
import { TextInput } from "../../../_components/TextInput/TextInput";
import ProgressBar from "../../../_components/progressbar/ProgressBar";
import { floorLaminatedModel } from "../../../_redux/Models/Floor.model";
import { isInteger } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { checkoutStore, floorStore } from "../../../_redux/actions";
import {
  calculateTotalCost,
  formatPrice,
} from "../../../services/utils/utilFunctions";
import { checkoutModel } from "../../../_redux/Models/checkout.model";
import { getServicePriceDetails } from "../../../services/api/category.api.service";
import { useRecoilState } from "recoil";
import { selectedContractorAtom } from "../../../recoil/atoms/contractorAtom";

export const SizeOfFloor = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const stepValue = 1;

  const [floorSize, setFloorSize] = useState(""); // To keep the floor size in state
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const checkoutModelAs = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );
  const [price, setPrice] = useState(
    calculateTotalCost(floorLaminatedModel.costFor)
  ); // To keep the total price in state
  const [sizePricing, setSizePricing] = useState({ price: 0 });

  useEffect(() => {
    let mounted = true;
    if (
      mounted &&
      checkoutModelAs.selectedServiceDetails.categoryId &&
      checkoutModelAs.selectedServiceDetails._id
    ) {
      getServicePriceDetails(
        checkoutModelAs.selectedServiceDetails.categoryId,
        checkoutModelAs.selectedServiceDetails._id
      ).then((response) => {
        if (response.status === 200 && response.data) {
          checkoutModelAs.priceDetails = response.data.details;
          setSizePricing(response.data.details.floorSize);
          dispatch(checkoutStore(checkoutModelAs));
        }
      });
    }

    return () => (mounted = false);
  }, [checkoutModelAs, dispatch]);

  /**
   * Calls when submit button is clicked
   */
  const onSubmitFloorSize = () => {
    floorLaminatedModel.floorSize = floorSize;
    floorLaminatedModel.costFor.sizeDetails = price;
    dispatch(floorStore(floorLaminatedModel));
    checkoutModelAs.totalCost = price;
    dispatch(checkoutStore(checkoutModelAs));
    props.history.push(
      `/contractor-portfolio/${selectedContractorId}/floors/laminated/service-type/2`
    );
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div>
          <div className="paint_flex_with_left">
            <label className="paint_header">
              {/* {t("laminated.introduceFloorSize")} */}
              {t("laminated.pleaseIntroduceSquareFeet")}
            </label>
          </div>
          {/* <div className="paint_flex_with_left ">
            <label className="paint_other_sub_header ">
              {t("laminated.pleaseIntroduceSquareFeet")}
            </label>
          </div> */}
          <div className="paintinput_lable mb-4">
            <TextInput
              className="paint_input_border"
              error={isInteger(floorSize)}
              type="number"
              onChange={(e) => {
                const sizeServicePrice = sizePricing.price;
                setPrice(e.target.value * sizeServicePrice);
                setFloorSize(e.target.value);
              }}
              value={floorSize}
              placeholder={t("paintingInterior.placeholder500")}
              label={t("paintingExterior.sizing")}
            />
            <div className="paint_placeholder_right">
              <label>{t("paintingInterior.sqft")}</label>
            </div>
          </div>
          {floorSize > 0 && floorSize < 150 ? (
            <span style={{ fontSize: "10px", color: "red" }}>
              Minimum size is 150 square feet
            </span>
          ) : null}
          <Button
            disabled={floorSize < 150}
            buttonLabel={t("paintingHomes.continue")}
            onClick={onSubmitFloorSize}
          />
        </div>
      </div>
    </div>
  );
};
