import React, { useEffect, useState, useContext, useRef } from "react";
import IMAGES from "../../../_assets/images/index";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isSupported } from "firebase/messaging";
import { continuetoNext, verifyOTP } from "../../../_redux/actions";
import i18n from "i18next";

import { Button } from "../../../_components/Button/Button";
import { signupConstants } from "../../../_redux/constants";
import {
  mobileEmailUserVerification,
  mobileResendOTP,
  mobileVerification,
  resendOTP,
} from "../../../services/api/signup.service";
import { signupModel } from "../../../_redux/Models/auth.models";
import Loader from "../../../_components/spinner/Loader";
import { useSnackbar } from "notistack";
import { FireBase } from "../../../services/firebase/firebase.service";
import {
  addFirebaseToken,
  deleteFirebaseToken,
} from "../../../services/api/notifications.api.services";
import axiosInstance from "../../../services/api/config/api.config";
import axios from "axios";

function Validation(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [isButtonClick, setIsButtonClick] = useState(false);
  const { user, userRegister } = useSelector((state) => state.signupReducer);
  const [isProgress, setIsProgress] = useState(false);
  var phoneFirstRef = useRef();
  var phoneSecRef = useRef();
  var phoneThirdRef = useRef();
  var phoneFourthRef = useRef();

  var mailFirstRef = useRef();
  var mailSecRef = useRef();
  var mailThirdRef = useRef();
  var mailFourthRef = useRef();

  // IF no user put back him to signup
  if (!user) {
    localStorage.removeItem("signupInfo");
    history.push("/role");
  }
  const [phoneSeconds, setPhoneSeconds] = useState(30);
  const [emailSeconds, setEmailSeconds] = useState(30);

  const { t } = useTranslation();
  const [phoneOtp, setPhoneOtp] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  const [mailOtp, setMailOtp] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  useEffect(() => {
    phoneFirstRef.current.focus();
    return () => {
      dispatch({
        type: signupConstants.SIGNUP_NEXT_CLEAR,
      });
      dispatch({
        type: signupConstants.SIGNUP_REQUEST,
      });
    };
  }, []);

  const phoneOtpOnChangeHandler = (e) => {
    const newotp = { ...phoneOtp };
    newotp[e.target.id] = e.target.value;
    if (newotp["first"].length) {
      phoneSecRef.current.focus();
    }
    if (newotp["second"].length) {
      phoneThirdRef.current.focus();
    }
    if (newotp["third"].length) {
      phoneFourthRef.current.focus();
    }
    setPhoneOtp(newotp);
  };

  const emailOtpOnChangeHandler = (e) => {
    const newotp = { ...mailOtp };
    newotp[e.target.id] = e.target.value;
    if (newotp["first"].length) {
      mailSecRef.current.focus();
    }
    if (newotp["second"].length) {
      mailThirdRef.current.focus();
    }
    if (newotp["third"].length) {
      mailFourthRef.current.focus();
    }
    setMailOtp(newotp);
  };

  const onPermission = async (permission) => {
    const browserSupport = await isSupported();
    if (!browserSupport) return;
    const firebase = new FireBase();
    if (permission === "granted") {
      deleteToken();
      try {
        const token = await firebase.getFirebaseToken();
        window.conversationsClient?.setPushRegistrationId("fcm", token);
        addFirebaseToken(token);
      } catch (e) {
        console.error("firebase not granted", e);
      }
    }
  };

  const buttonClick = async () => {
    const verify = {
      mobile: userRegister && userRegister != null ? userRegister.mobile : "",
      otp: phoneOtp.first + phoneOtp.second + phoneOtp.third + phoneOtp.fourth,
    };
    if (user !== undefined) {
      setIsProgress(true);
      verify.mobile =
        user.userVerified && user.userVerified.mobileNumber
          ? user.userVerified.mobileNumber
          : "";

      var res = await mobileVerification(verify);

      if (res.status === 200) {
        signupModel.userVerified = { ...res.data };
        await localStorage.setItem("auth_token", res.data.token);
        let token = await res.data.token;
        const userData = localStorage.getItem("signupInfo");
        if (userData) {
          localStorage.setItem("authInfo", userData);
          localStorage.setItem("user", userData);
          localStorage.removeItem("signupInfo");
        }

        setIsProgress(false);
        if (user.signuserType === "contractor") {
          history.replace("/service-locations", { signUp: true });
        } else {
          let redirectUrl = localStorage.getItem("redirectUrl");
          redirectUrl = redirectUrl ? redirectUrl : "/";
          localStorage.removeItem("redirectUrl");
          history.push(redirectUrl);
        }

        // dispatch(continuetoNext(signupModel));
      } else {
        const variant = "error";
        const errorMessage = "Invalid OTP";
        enqueueSnackbar(errorMessage, { variant });
        setIsProgress(false);
      }
    }
  };

  const deleteToken = async function () {
    const tokenId = localStorage.getItem("tokenId");
    if (tokenId) {
      await deleteFirebaseToken(tokenId);
      localStorage.removeItem("tokenId");
    }
  };

  const phoneResendOTPRequest = () => {
    const mobile =
      user.userVerified && user.userVerified.mobileNumber
        ? user.userVerified.mobileNumber
        : "";
    mobileResendOTP({ mobile }).then((response) => {
      setPhoneSeconds(30);
      const variant = "info";
      const errorMessage = "OTP sent successfully";
      enqueueSnackbar(errorMessage, { variant });
    });
  };

  const emailResendOTPRequest = () => {
    const mobile =
      user.userVerified && user.userVerified.email
        ? user.userVerified.email
        : "";
    resendOTP({ mobile }).then((response) => {
      setEmailSeconds(30);
      const variant = "info";
      const errorMessage = "OTP sent successfully";
      enqueueSnackbar(errorMessage, { variant });
    });
  };

  const arrowClick = () => {
    history.goBack();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (phoneSeconds > 0) {
        setPhoneSeconds(phoneSeconds - 1);
      }

      if (phoneSeconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [phoneSeconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (emailSeconds > 0) {
        setEmailSeconds(emailSeconds - 1);
      }

      if (emailSeconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [emailSeconds]);

  return (
    <div className="pcWidth">
      <Loader progress={isProgress} />
      <div className="signup_center_align">
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  src={IMAGES.other.vectors}
                  alt=""
                  onClick={() => arrowClick()}
                />
              </td>
              <td>
                <label
                  className="signup_page_anyhnb_logo"
                  onClick={() => window.location.replace("/")}
                >
                  {t("signUp.mainHeading")}
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="">
        <div>
          <label className="signup_header signup_flex_with_center">
            {t("Validation.phone_validation")}
          </label>
          <div className="signup_flex_with_center">
            <label className="signup_validation_header_first">
              {t("Validation.phone_check_header")}
            </label>
          </div>
          <div className="signup_flex_with_center">
            <label className="signup_validation_header_second">
              {t("Validation.activation_code")}
            </label>
          </div>

          <div className="signup_flex_with_center signup_validationbox_padding">
            <div
              className={`signup_validation_box ${isButtonClick && phoneOtp.first === "" ? "invalid" : "valid"
                } signup_validationbox_inside_padding`}
            >
              <input
                className="signup_validation_input"
                ref={phoneFirstRef}
                name="first"
                id="first"
                type="text"
                value={phoneOtp.first}
                maxLength={1}
                onChange={(e) => phoneOtpOnChangeHandler(e)}
              />
            </div>
            <div
              className={`signup_validation_box ${isButtonClick && phoneOtp.second === "" ? "invalid" : "valid"
                } signup_validationbox_inside_padding`}
            >
              <input
                className="signup_validation_input"
                type="text"
                ref={phoneSecRef}
                name="second"
                id="second"
                value={phoneOtp.second}
                maxLength={1}
                onChange={(e) => phoneOtpOnChangeHandler(e)}
              />
            </div>
            <div
              className={`signup_validation_box ${isButtonClick && phoneOtp.third === "" ? "invalid" : "valid"
                } signup_validationbox_inside_padding`}
            >
              <input
                className="signup_validation_input"
                name="third"
                id="third"
                ref={phoneThirdRef}
                type="text"
                value={phoneOtp.third}
                maxLength={1}
                onChange={(e) => phoneOtpOnChangeHandler(e)}
              />
            </div>
            <div
              className={`signup_validation_box ${isButtonClick && phoneOtp.fourth === "" ? "invalid" : "valid"
                } signup_validationbox_inside_padding`}
            >
              <input
                className="signup_validation_input"
                name="fourth"
                id="fourth"
                ref={phoneFourthRef}
                type="text"
                value={phoneOtp.fourth}
                maxLength={1}
                onChange={(e) => phoneOtpOnChangeHandler(e)}
              />
            </div>
          </div>

          <div className="signup_flex_with_center signup_validation_not_receive_link_padding">
            <button
              onClick={phoneResendOTPRequest}
              disabled={phoneSeconds !== 0}
              className="signup_validation_not_receive_link"
            >
              {t("Validation.not_receive")}
            </button>
          </div>
          <small className="signup_flex_with_center timer">
            {phoneSeconds > 0 ? `Time remaining: ${phoneSeconds}` : ""}
          </small>
        </div>
      </div>
      <div className="signup_validation_con_button_padding">
        <Button
          //disabled=={!setOtp}
          disabled={
            !phoneOtp.first ||
            !phoneOtp.second ||
            !phoneOtp.third ||
            !phoneOtp.fourth
          }
          buttonLabel={t("signIn.continue")}
          onClick={buttonClick}
        />
      </div>
    </div>
  );
}
export default Validation;
