import IMAGES from '../../_assets/images/index'

const laminatedSliderItems= [
   {   
   urls: IMAGES.home.Laminated1,
   },
   {   
   urls: IMAGES.home.Laminated2,
   },
   {   
   urls: IMAGES.home.Laminated3,
   },
   {   
   urls: IMAGES.home.Laminated4,
   },
   {   
   urls: IMAGES.home.Laminated5,
   },
];
export default laminatedSliderItems