import React, { useState, useEffect } from "react";
import HeaderMenu from "../../_components/HeaderMenu/HeaderSection";
import "../../_styles/localStyles/kitchen.css";
import "../../_components/radioButton/radioButton.css";
import { useTranslation } from "react-i18next";
import IMAGES from "../../_assets/images/index";
import { Footer } from "../../_components/footer/Footer";
import ProgressBar from "../../_components/progressbar/ProgressBar";
import CheckBox from "../../_components/CheckBox/checkBox";
import { Button } from "../../_components/Button/Button";

import { checkoutStore, windowStore } from "../../_redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { checkoutModel } from "../../_redux/Models/checkout.model";
import {
  calculateTotalCost,
  formatPrice,
} from "../../services/utils/utilFunctions";
import { windowsModel } from "../../_redux/Models/window.model";
import { DropDown } from "../../_components/dropDown/dropDown";
import { useHistory } from "react-router-dom";
import { selectedContractorAtom } from "../../recoil/atoms/contractorAtom";
import { useRecoilState } from "recoil";

export const WindowAddons = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedContractorId] = useRecoilState(selectedContractorAtom);

  const stepValue = 5;

  const checkoutModelState = useSelector((state) =>
    state.checkout && state.checkout.payload
      ? state.checkout.payload
      : checkoutModel
  );

  const state = useSelector((state) =>
    state.buttonReducer && state.buttonReducer.windowsPayload
      ? state.buttonReducer.windowsPayload
      : windowsModel
  );

  const [price, setPrice] = useState(calculateTotalCost(state.costFor)); // To keep the total price in state

  const [addOns, setAddOns] = useState([]);

  useEffect(() => {
    let mounted = true;
    const priceDetails = checkoutModelState.priceDetails;
    if (mounted === true && priceDetails && priceDetails.addOns) {
      const singleItems = [];
      const optionsArr = priceDetails.addOns.map((op) => {
        return {
          text: t(`windows.${op.key}`),
          value: op.key,
          count: 1,
          isChecked: false,
          isSingle: singleItems.includes(op.key),
          price: op.price,
        };
      });
      setAddOns(optionsArr.sort((o, p) => (p.isSingle ? 1 : -1)));
    }
    return () => (mounted = false);
  }, [checkoutModelState, dispatch, t]);

  const onSave = () => {
    const total = calculateTotalCost(state.costFor);
    state.addOns = addOns.filter((i) => i.isChecked === true);
    state.costFor.addOns = price - total;
    checkoutModelState.totalCost = price;
    dispatch(checkoutStore(checkoutModelState));
    dispatch(windowStore(state));
    history.push(
      `/contractor-portfolio/${selectedContractorId}/windows/order-summary`
    );
  };

  function arrowHandler(params, e, index) {
    let updatedState = [...addOns];
    if (params === "plus") {
      updatedState[index].count = updatedState[index].count + 1;
    } else if (updatedState[index].count > 1) {
      updatedState[index].count = updatedState[index].count - 1;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  }

  const calculateTotal = (addOns) => {
    const total = addOns
      .filter((v) => v.isChecked === true)
      .reduce((p, c) => p + c.price * c.count, 0);
    setPrice(calculateTotalCost(state.costFor) + total);
  };

  const toggleCheck = (event, index) => {
    let updatedState = [...addOns];
    if (!updatedState[index].isChecked) {
      updatedState[index].isChecked = event;
    } else {
      updatedState[index].isChecked = !event;
    }
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  const onAddOnHandler = (event, index) => {
    let updatedState = [...addOns];
    updatedState[index].count = parseInt(event.target.value);
    calculateTotal(updatedState);
    setAddOns(updatedState);
  };

  return (
    <div className="insideWrp">
      <div style={{ padding: "0px 20px 20px 20px" }}>
        <div className="paint_flex_with_left">
          <label className="paint_header">{t("kitchen.addon")}</label>
        </div>
        <div className="paint_flex_with_left">
          <label className="paint_other_sub_header ">
            Please select which other services you would like to include
          </label>
        </div>
        <div className="padding10"></div>
        {addOns &&
          addOns !== undefined &&
          addOns.map((item, index) => (
            <div className="paintPadding8 ">
              <div
                className="kitchen_radio_div_first kitchendiv_radiobtn_paddingright "
                onClick={() => toggleCheck(true, index)}
              >
                <div
                  key={index}
                  className="width100 "
                  style={{ justifyContent: "space-between" }}
                >
                  <table>
                    <tr>
                      <td>
                        <label className="paintdiv_txt"> {item.text} </label>
                      </td>
                      <td>
                        <div
                          className="paint_arrowFloat flex"
                          style={{ paddingTop: "10px" }}
                        >
                          <img
                            src={IMAGES.other.green_plus}
                            className="kitchenplusimg"
                            alt=""
                          />
                          <label
                            className="paintdiv_txt paddingleft8"
                            style={{ paddingLeft: "7px", paddingTop: "2px" }}
                          >
                            {`$${formatPrice(item.count * item.price)}`}
                          </label>
                          <div
                            className="paint_arrowFloat"
                            style={{ paddingLeft: "7px" }}
                          >
                            <CheckBox checked={item.isChecked} readOnly />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="paintPadding8 ">
                {item.isChecked && !item.isSingle ? (
                  <div className="kitch_radio_div_firstarrow kitchdiv_arrow_padding">
                    <table className="paint_table100">
                      <tr>
                        <td>
                          <div
                            className="typeofTxt"
                            style={{ display: "grid", paddingTop: "0" }}
                          >
                            <DropDown
                              value={item.count || ""}
                              callback={(event) => onAddOnHandler(event, index)}
                              length={50}
                            />
                            {/* {userState.number} */}
                          </div>
                          {/* <label className="paintdiv_txt">
                    </label>  */}
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <label className="paintdiv_txt my-3">
                {
                  "If one or more of your brickmoulds are damaged, repairing them is not included in the installation, so you would need to select the add on “Replace rotted brickmould”"
                }
              </label>
            </div>
          ))}
        <Button
          buttonLabel={t("paintingHomes.continue")}
          onClick={() => onSave()}
        />
      </div>
    </div>
  );
};
