import React, { useEffect } from "react";
import Loader from "../../_components/spinner/Loader";
import { parseJwt } from "../../services/utils/utilFunctions";
import { placeOrder } from "../../services/api/owner.service";

export const PaymentSuccess = (props) => {
  //   const search = props.location.search;
  //   const sessionId = new URLSearchParams(search).get("session_id");

  useEffect(() => {
    const user = parseJwt(localStorage.getItem("auth_token"));
    const orderData = JSON.parse(localStorage.getItem("orderData"));
    placeOrder(user._id, orderData, user.userType).then(() => {
      props.history.push(`/checkout/order-received/success`);
    });
  }, []);

  return <Loader />;
};
