import { NumberOfBathrooms } from "./NumberOfBathrooms";
import { BathroomAddOns } from "./addOns";
import { BathroomSize } from "./bathroomSize";
import { HaveBathroomMaterials } from "./haveBathroomMaterials";
import { BathroomNeedBuildupWalls } from "./needBuildupWalls";
import { BathroomNeedDemolition } from "./needDemolition";
import { BathroomNeedShowerDoor } from "./needShowerDoor";
import { BathroomPersonalInfo } from "./personalInfo";
import { BathroomSummary } from "./summary";

export const bathroomRoutes = [
  {
    exact: true,
    path: "/contractor-portfolio/:id/bathroom/how-many-bathrooms",
    component: NumberOfBathrooms,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/bathroom/:bathroomId/size/:stepValue",
    component: BathroomSize,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/bathroom/:bathroomId/materials/:stepValue",
    component: HaveBathroomMaterials,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/bathroom/:bathroomId/need-demolition/:stepValue",
    component: BathroomNeedDemolition,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/bathroom/:bathroomId/need-build-up-walls/:stepValue",
    component: BathroomNeedBuildupWalls,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/bathroom/:bathroomId/need-shower-door/:stepValue",
    component: BathroomNeedShowerDoor,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/bathroom/:bathroomId/add-ons/:stepValue",
    component: BathroomAddOns,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/bathroom/add-personal-info",
    component: BathroomPersonalInfo,
  },
  {
    exact: true,
    path: "/contractor-portfolio/:id/bathroom/order-summary/:stepValue",
    component: BathroomSummary,
  },
];
