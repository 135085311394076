import React from "react";
import "./radioButton.css";

function RadioButton(props) {
  const { value, name, checked, onClick, className } = props;
  return (
    <div>
      <div className="roles">
        <input
          {...props}
          className={className || ""}
          onChange={onClick}
          type="radio"
          name={name}
          value={value}
          id="one"
          checked={checked}
        />
      </div>
    </div>
  );
}

export default RadioButton;
