export const NO_OF_BATHROOMS = "NO_OF_BATHROOMS";
export const UPDATE_PRESENT_ROOM = "UPDATE_PRESENT_ROOM";
export const OPTIONS = "OPTIONS";
export const UPDATE_ROOM = "UPDATE_ROOM";
export const UPDATE_PAINT_DETAILS = "UPDATE_PAINT_DETAILS";
export const UPDATE_ADD_ONS = "UPDATE_ADD_ONS";
export const RESET_STATE = "RESET_STATE";
export const setNoOfBathRooms = (number) => {
  return {
    type: NO_OF_BATHROOMS,
    payload: number,
  };
};
export const presentRoomNumber = (presentRoom) => {
  return {
    type: UPDATE_PRESENT_ROOM,
    payload: presentRoom,
  };
};
export const updateAddOns = (addonArray) => {
  return {
    type: UPDATE_ADD_ONS,
    payload: addonArray,
  };
};

export const setOptions = (options) => {
  return {
    type: OPTIONS,
    payload: options,
  };
};

export const updateNoOfRooms = (room) => {
  return {
    type: UPDATE_ROOM,
    payload: room,
  };
};

export const updatePaintDetails = (paintsDetailsArray) => {
  return {
    type: UPDATE_PAINT_DETAILS,
    payload: paintsDetailsArray,
  };
};
export const resetRooms = () => {
  return {
    type: RESET_STATE,
  };
};
